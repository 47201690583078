import React, { useEffect, useRef } from "react";
import { getAllMasterJobs } from "../../slices/MasterJobs";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { swatchDeleting } from "../../slices/AlertSlice";
import { useDispatch } from "react-redux";
const DeleteSwatch = ({ jobId, resetData }) => {
  const masterJobs = useSelector(getAllMasterJobs);
  const toast = useToast();
  const ismOunted = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      masterJobs &&
      masterJobs.masterJob &&
      masterJobs.masterJob.length > 0 &&
      jobId &&
      ismOunted.current
    ) {
      ismOunted.current = false;
      addSwatch(jobId);
    }
  }, [masterJobs, jobId]);
  const addSwatch = async (jobIds) => {
    let data = {
      data: {
        Segmentation: masterJobs.masterJob,
      },
    };

    try {
      if (data != null) {
        const responce = await axios.put(
          process.env.REACT_APP_API_STRAPI + "jobs/" + jobIds,
          data
        );
        data = null;
        resetData();
        if (responce.status === 200) {
          toast({
            title: "Swatch is updated successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "center-bottom",
          });
          dispatch(
            swatchDeleting({
              isSwatchDeleting: false,
            })
          );
        }
      }
    } catch (e) {
      alert("Error on adding pallet");
      // console.log(e);
    }
  };

  return <div></div>;
};

export default DeleteSwatch;

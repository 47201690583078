import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Input,
} from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import "./EditProjectName.css";
const EditProjectName = ({ isOpen, onClose, rename, projectName }) => {
  const [project_Name, setProjectName] = useState(projectName);

  const handleCloseModal = () => {
    onClose();
  };
  const handleYes = () => {
    rename(project_Name);
  };

  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent className="rename-projec-section">
          <ModalHeader className="project-rename-title">Project Name</ModalHeader>
          <ModalCloseButton onClick={handleCloseModal} />
          <ModalBody className="rename-project">
            <FormControl>
              {/* <FormLabel>Project Name</FormLabel> */}
              <Input
                type="text"
                value={project_Name}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                borderRadius="md"
                borderColor="gray.400"
                _focus={{ borderColor: "blue.500" }}
              />
            </FormControl>
          </ModalBody>


          <ModalFooter className=" rename-popup">
            <Button className="delete-project-rename" colorScheme="blue" mr={3} onClick={handleYes}>
              Cancel 
            </Button>
            <Button className="save-project-name" variant="ghost" onClick={handleCloseModal}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditProjectName;

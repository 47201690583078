import { gql } from "@apollo/client";


export const GET_CUSTOMERVIEWJOB = gql`
  query GetAllJob($jobId: ID!) {
    jobs(filters: { id: { eq: $jobId } }) {
      data {
        id
        attributes {
          ModelVersion
          Shadows
          JobJson
          project{
            data{
              id
              attributes{
                name
                ImageBase64
                combinations{
                  data{
                    id
                    attributes{
                      Name
                      Swatches{
                        id
                        SwatchFor
                        SegmentImagePath
                        swatch{
                          data{
                            id
                            attributes{
                              name
                              image_s3
                            }
                          }
                        }
                      }
                      
                    }
                  }
                }
              }
            }
          }
        
      }
      }
      }
    }
  
`;
import { Button, Container } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import "../../component/login/login.css";
import { connect } from "react-redux";
import { login } from "./AuthSlice";
import axios from "axios";
import { LoginDetail } from "./LoginDetail";
import Label_Segment from "../Label_Segment/Label_Segment";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, addUserId } from "./AuthSlice";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import AlertAndSpinner from "../login/AlertAndSpinnerPage";
import Header from "../Header/Header";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Card,
  IconButton, // Add this import
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import GetToken from "./GetTokenHeader";
function Login({ callAlert }) {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    email: "carlos@dzinly.com",
    password: "Dzinly1!",
  });
  const [emailId, setEmailId] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [responce, SetResponce] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setLoginForm({ ...loginForm, [name]: value });
    setFormErrors(validate(loginForm));
  };

  const handleSubmit = (event, email) => {
    event.preventDefault();
    setFormErrors(validate(loginForm));
    setIsSubmited(true);
    setEmailId(email);
    setIsToken(true)
  };
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmited) {
      // callAlert(true);
      setIsloading(true);
      const data = {
        identifier: loginForm.email,
        password: loginForm.password,
      };

      const fetchData = async () => {
        setLoggedIn(true);
        try {
          const results = await axios.post(
            process.env.REACT_APP_API_STRAPI + "auth/local",
            data
          );
         
          if (results.status === 200) {
           
            if (results.data.jwt) {
              SetResponce(results.data);
              setIsloading(false);
              setUserId(results?.data?.user?.id);
              dispatch(
                loginSuccess({
                  token: results.data.jwt,
                  email: loginForm.email,

                })
              );

              dispatch(addUserId({
                userId: results?.data?.user?.id,
              }))
              document.cookie = `authToken=${results.data.jwt}`;
              afterLogin();
            }
          }
        } catch (error) {
          toast({
            title: "Error: " + error.message,
            description: "Please check your password and try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "center",
          });
          setLoggedIn(false);
          setIsSubmited(false);
          setIsloading(false);
          // navigate("/");
        }
      };
      fetchData();
    } else {
      setIsSubmited(false);
    }
  }, [formErrors, dispatch]);

  const validate = (value) => {
    const errors = {};
    if (value.email == "") {
      errors.email = "Email is Required";
    }
    if (value.password == "") {
      errors.password = "Password is required";
    }
    return errors;
  };

  const afterLogin = () => {
    setIsSubmited(true);
    // navigate("/profile");
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the password visibility state
  };
  const handleCloseLogin = (role) => {
    toast({
      title: "Login Success",
      description: "You have logged in successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "center-bottom",
      // className: "login-success-popup",
    });

    if (role === "customer") {
      navigate("/profile");
    } else if (role === "designer") {
      navigate("/profile");
    }
    setLoggedIn(false);
  };


  // close getToken

  const handleResetIsToken=()=>{
    setIsToken(false)
  }

  //handle static designer login
  const handleDesigner=()=>{
  
    setLoginForm({
      email: "deepakdesigner@dzinly.com",
      password: "Dzinly1!", 
    })
  }
  //handle static customer login
  const handleCustomer=()=>{
  
    setLoginForm({
      email: "carlos@dzinly.com",
      password: "Dzinly1!", 
    })
  }
  //handle static manufacturing  login
  const handleManufacturing =()=>{
  
    setLoginForm({
      email: "jacob@dzinly.com",
      password: "Dzinly1!", 
    })
  }

  return (
    <>
      <div className="card-name">
        <div className="user-login-from">
          <h2 className="login-title">Login</h2>
        </div>


       
        <Card style={{ marginTop: 0 }} className="Card login-section">
          <Container className="Container container-form login-details-from">



          <div class="user-section row">
              {/* designer */}

        <div class='col user-box  text-center col-3'
         onClick={handleDesigner}
        >
            <input type="radio" name="imgbackground" id="img1" class="d-none imgbgchk" value=""/>
           <label   for="img1">
            <div className="user-img">
            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/graphic_designer_5b13ac7386.png" alt="Image 1"></img>
            </div>
            <h4 className="user-detail">Designer</h4>
            <div class="tick_container">
              <div class="tick"><i class="bi bi-check2"></i></div>
            </div>
          
            </label>
        </div>
                {/* customer */}
        <div class='col user-box text-center col-3'
        onClick={handleCustomer}
        >
        <input type="radio" name="imgbackground" id="img2" class="d-none imgbgchk" value=""/>
          <label  for="img2">
          <div className="user-img">
            <img src=" https://dzinlystrapi.s3.us-east-2.amazonaws.com/people_bc29368361.png" alt="Image 2"></img>
           </div>
            <h4 className="user-detail">Customer</h4>
            <div class="tick_container">
              <div class="tick"><i class="bi bi-check2"></i></div>
            </div>
          </label>
        </div>    
                {/* Manufacturer */}

        <div class='col user-box text-center col-3'>
        <input type="radio" name="imgbackground" id="img3" class="d-none imgbgchk" value=""/>
          <label  for="img3">
          <div className="user-img">
            <img src=" https://dzinlystrapi.s3.us-east-2.amazonaws.com/setting_39b6bc686a.png" alt="Image 3"></img>
            </div>
            <h4 className="user-detail"> Manufacturer</h4>
            <div class="tick_container">
              <div class="tick"><i class="bi bi-check2"></i></div>
            </div>
          </label>
        </div>
    
      </div>



            <FormControl
              className="FormControl email-from"
              isRequired
              isInvalid={formErrors.email}
            >
              <FormLabel className="FormLabel form-email ">Email</FormLabel>
              <Input
                className="Input"
                type="email"
                value={loginForm.email}
                name="email"
                onChange={handleInput}
              />
              <FormErrorMessage className="FormErrorMessage">
                {formErrors.email}
              </FormErrorMessage>
            </FormControl>

            <FormControl className="login-fromlabel"isRequired isInvalid={formErrors.password}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"} // Toggle input type
                  value={loginForm.password}
                  name="password"
                  onChange={handleInput}
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    onClick={togglePasswordVisibility}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} // Toggle icon based on password visibility
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.password}</FormErrorMessage>
            </FormControl>
            <br />
            <Button
              onClick={(event) => handleSubmit(event, loginForm.email)}
              colorScheme="blue"
              className="Button form-btn sub-button"
              type="submit"
            >
              {" "}
              Submit{" "}
            </Button>
          </Container>
          {userId && (
            <LoginDetail
              userId={userId}
              emailId={emailId}
              resetLogin1={handleCloseLogin}
            />
          )}
          {loggedIn && <AlertAndSpinner />}

          {isToken &&<GetToken
          resetData={handleResetIsToken}
          />}
        </Card>
        <Footer />
      </div>
    </>
  );
}
export default Login;

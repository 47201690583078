import React, { useEffect, useState, useRef } from "react";
import CallApi from "../Add_Edit_Pallet/CallApi";
import UploadSegmentedImage from "../Add_Edit_Pallet/UploadSegmentedImage";
import SwatchOption from "./SwatchOption";
import ModalToAdd from "../Add_Edit_Pallet/ModalToAdd";
import { closeModals, getCloseModal } from "../slices/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
const Swatch = ({ mouseX, mouseY, selectedSegment }) => {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (mouseX && mouseY && selectedSegment) {
      setIsEdit(true);
    }
  }, [mouseX, mouseY, selectedSegment]);

  //open the Call API coponent
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch4"));
  const [palletImagePath, setpalletImagePath] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [indexs, setIndexs] = useState(null);
  const [isCallAPIopen, setIsCallAPIopen] = useState(false);
  const isMounted = useRef(true);
  const [uploadData, setUploadData] = useState(null);

  const handleCloseSwatchOption = (ind) => {
    // ;
    setIndexs(ind);
    setIsOpen(true);
  };
  useEffect(() => {
    if (
      handleSwatch &&
      handleSwatch?.palletImagePath &&
      handleSwatch?.flagDemo &&
      handleSwatch?.palletId &&
      handleSwatch?.palletName &&
      isMounted.current
    ) {
  
      setIsEdit(false);
      setpalletImagePath(handleSwatch.palletImagePath);

      setIsCallAPIopen(true);
      sessionStorage.setItem("handleSwatch", JSON.stringify(handleSwatch));
      sessionStorage.removeItem("handleSwatch4");
      isMounted.current = false;
    }
  }, [handleSwatch]);
  // close Modal
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  // reset data
  const handleResetData = (data) => {
    isMounted.current = true;
    setUploadData(data);

    setpalletImagePath(null);

    setIsCallAPIopen(false);
    // ;
  };

  // upload the datta

  const handleReset = () => {
    setUploadData(null);
  };
  const close_modal = useSelector(getCloseModal);
  useEffect(() => {
    if (close_modal) {
      handleCloseModal();

      dispatch(
        closeModals({
          closeModal: false,
        })
      );
    }
  }, [close_modal]);
  const handleCloseSwatchOption1 = () => {
    setIsEdit(false);
  };
    return (
    <div>
      {isEdit && (
        <SwatchOption
          mouseX={mouseX}
          mouseY={mouseY}
          selectedSegment={selectedSegment}
          resetSwatchOption={handleCloseSwatchOption}
          onclose={handleCloseSwatchOption1}
        />
      )}

      <ModalToAdd
        isOpen={isOpen}
        closeModal={handleCloseModal}
        indexs={indexs}
      />

      {isCallAPIopen && (
        <CallApi
          palletImagePath={palletImagePath}
          // flagDemo={flagDemo}
          // palletId={palletId}
          // palletName={palletName}
          resetData={handleResetData}
        />
      )}
      {/* {isViewPallet && (
        <SelectedPalletInfo
          isViewPallet={isViewPallet}
          closeModal={handleCloseViewPallet}
        />
      )} */}
      {uploadData != null && (
        <UploadSegmentedImage
          uploadDatas={uploadData}
          resetData={handleReset}
        />
      )}
    </div>
  );
};

export default Swatch;

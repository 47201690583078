import { createSlice } from "@reduxjs/toolkit";

const CreateProjectHeaderSlice=createSlice({

    name: "CreateProjectHeader",
    initialState: {
        isProjectCreated:false,
        isCreateProjectModalOpen:false,
        isStarteUpload:false,
        
    },
    reducers:{
        openCreatePorjectModal:(state,action)=>{
            state.isProjectCreated=action.payload.isProjectCreated
           
        },
        resetModal:(state,action)=>{
            state.isProjectCreated=false
            state.isCreateProjectModalOpen=false
            state.isStarteUpload=false
        }, 
        refreshReduxData:(state,action)=>{
            state.isStarteUpload=action.payload.isStarteUpload
        }
    
    }
})

export const {openCreatePorjectModal,refreshReduxData,resetModal}=CreateProjectHeaderSlice.actions;
export const getCreatedProject=state=>state.CreateProjectHeader;

export const getIsStartUpload=state=>state.CreateProjectHeader.isStarteUpload;
export default CreateProjectHeaderSlice.reducer;
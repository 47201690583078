
import { gql } from "@apollo/client";

export const RELOAD_JOBSDETAIL = gql`
query GetAllJob($jobId: ID!) {
  jobs(filters: { id: { eq: $jobId } }) {
    data {
      id
      attributes {
        ModelVersion
        Shadows
        Segmentation(pagination: { limit: -1 }) {
          id
          name
          area_pixel
          annotationPolygon
          confidence
          BoundingBoxFloat
          BoundingBoxInt
          perimeter
          SelectSwatchJob {
            id

            swatch {
              data {
                id
                attributes {
                  name
                   Type
                  swatch_style {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  brand {
                    data {
                      id
                      attributes {
                        name
                      }
                    }
                  }
                  image_s3
                  image {
                    data {
                      id
                      attributes {
                        name
                        url
                      }
                    }
                  }
                  swatch_style {
                    data {
                      id
                    }
                  }
                }
              }
            }
            
            SegmentImagePath
            SegmentImage {
              data {
                id
                attributes {
                  url
                }
              }
            }
            SwatchFor
            User{
              data{
                id
                attributes{
                  username
                  email
 
                }
              }
            }

          }
        }
        
        project{
          data{
            id
            attributes{
              name
              
              combinations{
                data{
                  id
                  attributes{
                    Name
                    Swatches{
                      id
                      SwatchFor
                      SegmentImagePath
                      swatch{
                        data{
                          id
                          attributes{
                            name
                            image_s3
                          }
                        }
                      }
                      
                    }
                  }
                }
                
              }
            }
          }
        }
      }
    }
  }
}
`;
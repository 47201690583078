import React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFolderData } from "../slices/FolderSlice";
import { getCustomer } from "../login/AuthSlice";

import axios from "axios";
import { addUserFolderData } from "../slices/FolderSlice";
const CreateUserFolder = ({ uploadDatas, resetChild }) => {
  const getFolderDatas = useSelector(getFolderData);
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const getCustomerDetail = useSelector(getCustomer);
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    // ;
    if (
      uploadDatas &&
      uploadDatas.multiImage != null &&
      getFolderDatas &&
      getFolderDatas?.segmentFolder &&
      getFolderDatas?.segmentFolder.length > 0 &&
      isMounted.current &&
      token
    ) {
      getFolderDatas?.segmentFolder.map((item) => {
        let labelName = uploadDatas.swatchName;
        if (item.name === labelName) {
          createFolder(item.id);
          return () => {
            isMounted.current = false;
          };
        }
      });
    } else if (
      uploadDatas &&
      uploadDatas.multiImage != null &&
      getFolderDatas &&
      getFolderDatas?.segmentFolder
    ) {
      let labelName = uploadDatas.swatchName;
      if (getFolderDatas?.segmentFolder.name === labelName) {
        createFolder(getFolderDatas?.segmentFolder.id);
        return () => {
          isMounted.current = false;
        };
      }
    }
  }, [getFolderDatas, uploadDatas,token]);

  //create folder in media Librray under prokject
  const createFolder = async (folderId) => {
    let data = {
      name: getCustomerDetail.customerId.toString(),
      parent: folderId,
    };
    const authToken = "Bearer " + token;
    try {
      const response = await axios.post(
        "https://aiadmin.dzinly.org/upload/folders/",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );
      if (response.status == 200) {
        //console.log("created user  folder", response);
        dispatch(
          addUserFolderData({
            userFolder: response.data.data,
          })
        );
        isMounted.current = true;

        resetChild(response.data.data.id);
      }
    } catch (err) {
      // alert(err.message);
      isMounted.current = true;
      resetChild();
    }
  };
  return <div></div>;
};

export default CreateUserFolder;

import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import{getProjectId} from "../../slices/ProjectSlice";
import { fabric } from "fabric";
import "../../ImageView/ImageView.css"
import {getCurrentablePalettes} from "../../slices/CurrentSelectedPalleteSlice"
import {getCanvasSize} from "../../slices/windowSizeSlice/WindowResolution.js"

const CompareLayer = () => {
    const dispatch= useDispatch()
    const canvasRef = useRef(null);
    const getAllPAtte= useSelector(getCurrentablePalettes);
    const getCanvasSizes= useSelector(getCanvasSize)
    useEffect(() => {
      if(getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null

        ){
          var canvas = new fabric.Canvas("compare-layer", {
            selectionBorderColor: 'green',
            width: getCanvasSizes.canvasWidth, // Set canvas width
              height:getCanvasSizes?.canvasHeight 
          });
        
          // setCanvas(canvas); // Remove this line
          canvasRef.current = canvas; 
          const imgGroup = new fabric.Group([], { selectable: false, name: 'imageGroup',visible: true });
          const shadowGroup = new fabric.Group([], { selectable: false, name: 'shadowGroup',visible: false });
          canvasRef.current.add(imgGroup);
          canvasRef.current.add(shadowGroup)

        }

       ;
        
        // setCanvas(canvas);
    
       }, [getCanvasSizes]); 
       const getProjectImage=useSelector(getProjectId);
       const ismountedImage = useRef(true)
       useEffect(()=>{
        //
          if(getProjectImage&&
              getProjectImage.base64Image&&
              canvasRef.current &&
              ismountedImage.current){
                
                ismountedImage.current = false;
                let image = getProjectImage?.base64Image;
          const encodedImage = "data:image/png;base64," + image;
          // Decode the base64 image
          const decodedImage = atob(encodedImage.split(",")[1]);
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedImage.length);
          for (let i = 0; i < decodedImage.length; i++) {
            uint8Array[i] = decodedImage.charCodeAt(i);
          }
          // Create a Blob from the Uint8Array
          const blob = new Blob([uint8Array], { type: "image/png" });
          // Create an object URL from the Blob
          let imageUrl = URL.createObjectURL(blob);
      
          var img = new window.Image();
          img.src = imageUrl;
          img.onload = () => {}
  //  let imageUrl=getProjectImage?.projectImage
    fabric.Image.fromURL(imageUrl, function (img) {
      img.scaleToWidth(canvasRef.current.width); // Set your desired width
      img.scaleToHeight(canvasRef.current.height);
      canvasRef.current.setBackgroundImage(imageUrl, canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
        scaleX: canvasRef.current.width / img.width,
        scaleY: canvasRef.current.height / img.height,
        width:canvasRef.current.width,
        height:canvasRef.current.height,
        originX: "left",
        originY: "top",
      
        });
       
        
       // Create a group for additional images
      
      canvasRef.current.requestRenderAll();
        
    })
  
              }
    
       },[canvasRef,getProjectImage]);

       // upadte all pate on image

       useEffect(()=>{
        if(canvasRef.current &&
            getAllPAtte&&
            getAllPAtte.currentpallate&&
            getAllPAtte.currentpallate.length>0){
                const allObjects = canvasRef.current.getObjects();
                getAllPAtte.currentpallate.map(item=>{
                
                    if(item.SegmentImagePath!=null){
                        let url=item.SegmentImagePath;
                        fabric.Image.fromURL(url, function(img){
                          var scaleX = canvasRef.current.width / img.width;
                          var scaleY = canvasRef.current.height / img.height;

                           var oImg = img.set({  scaleX: scaleX, scaleY: scaleY})
                           oImg.set({name:item.segmentName})
                           canvasRef.current.add(oImg);
                           canvasRef.current.requestRenderAll();
                        })
                    }
                })
            }
       },[getAllPAtte, canvasRef])
  return (
    <div>CompareLayer

<div className="ai-right-wrapper-body Cursor project-layer-images">
    
    <canvas  id="compare-layer" className="dzi-van"  width="1023" height="592"  ref={canvasRef}></canvas>
    </div>
    </div>
  )
}

export default CompareLayer
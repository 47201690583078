import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Center,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Select } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import "../../component/signup/SignUp.css";
import AlertAndSpinner from "../login/AlertAndSpinnerPage";
import { loginSuccess } from "../login/AuthSlice";
import { useDispatch } from "react-redux";
import Header from "../Header/Header"
import Footer from "../Footer/Footer";

import GetTokenHeader from "../login/GetTokenHeader";
function SignUp() {
  const [register, setRegister] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [images, setImages] = useState(null);
  const [role, setRole] = useState();

  const [errors, setErrors] = useState({});
  const [signUpResponce, setSignUpResponce] = useState({});
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [customerDetail, setCustomerDetail] = useState();
  const toast = useToast();
  const [isloading, setIsloading] = useState(false);
  const handleInput = (event) => {
    const { name, value } = event.target;
    setRegister({ ...register, [name]: value });
  };

  const dispatch = useDispatch();
  const handleRole = (ro) => {
    const rol = parseInt(ro, 10);

    setRole(rol);
  };

  const handleSubmit = async () => {
    setIsloading(true);
    try {
      const data = {
        username: register.email,
        email: register.email,
        password: register.password,
        confirmed: true,
        blocked: false,
        role: role,
      };

      const results = await axios.post(
        process.env.REACT_APP_API_STRAPI + "auth/local/register",
        data
      );
      if (results.data) {
        setIsloading(false);
        setSignUpResponce(results.data);
        setToken(results.data.jwt);
        sessionStorage.setItem("token", results.data.jwt);

        toast({
          title: "success",
          description: "Successfully sign up !",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "center-bottom",
        });
      }

      //changing the role as per user
      // const id = results.data.user.id;
      // if (results) {
      //   const dat = {
      //     role: role,
      //   };

      //   try {
      //     const result1 = await axios.put(
      //       process.env.REACT_APP_API_STRAPI + "users/" + id,
      //       dat
      //     );
      //   } catch (error) {}
      // }
       navigate("/login");
    } catch (error) {
      // console.log(error);
      toast({
        title: "Failed",
        description: error?.response?.data?.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "right-bottom",
      });
      setIsloading(false);
      navigate("/signup");
    }
  };

  const handleImage = (e) => {
    const imagefile = e.target.files[0];
    setImages(imagefile);
  };

  // after sign up create coolection based on role
  const[getAdminToken, setGetAdminToken]= useState(false)
  useEffect(() => {
    if (token && role) {
      setGetAdminToken(true)// get admintoken for media
      handleCollectionBasedOnRole();
    }
  }, [token]);

  const handleCollectionBasedOnRole = async () => {
    if (signUpResponce) {
      // Set the Authorization header with the JWT token
      let roleUrl;
      if (role === 7) {
        roleUrl = "Customers";
      } else if (role === 8) {
        roleUrl = "designers";
      } else {
        roleUrl = "manufacturers";
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const roleBAsed = {
        name: register.name,
        email: register.email,
        password: register.password,
        status: true,
      };
      const data = {
        data: roleBAsed,
      };
      // Make the Axios request with the provided config
      try {
        const responce = await axios.post(
          process.env.REACT_APP_API_STRAPI + roleUrl,
          data,
          config
        );
        const collectionData = responce.data;
        if (collectionData) {
          setCustomerDetail(collectionData);
          dispatch(
            loginSuccess({
              customerId: collectionData?.data.id,
              isAuthenticated: true,
              token: sessionStorage.getItem("token"),
              email: collectionData.data.attributes.email,
              customerName: collectionData.data.attributes.name,
              role: roleUrl,
              newUser:true
            })
          );
          if (roleUrl === "Customers") {
            navigate("/profile");
          } else if (roleUrl === "designers") {
            navigate("/profile");
          }
        }
      } catch (error) {
        toast({
          title: "Failed",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "center-bottom",
        });
      }
    }
  };


    // close getToken

    const handleResetIsToken=()=>{
      setGetAdminToken(false)
    }
  return (
    <>
      <div>
 
        <Header/>
        <div className="sign-up-page">
          <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>Sign Up</h1>
        </div>
        <div className="sign-up-from">
          <Container
            className="sign-from"
            style={{
              padding: "20px",
              // border: "1px solid #ccc",
              borderRadius: "8px",
            }}
            maxW="400px"
          >
            <Center>
              {/* <h2>Login</h2> */}
              {isloading ? (
                <AlertAndSpinner isloading={isloading} />
              ) : (
                <h1></h1>
              )}
            </Center>
            {/* name */}
            <FormControl isRequired isInvalid={errors.fullName}>
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                value={register.name}
                name="name"
                placeholder="Full Name"
                onChange={handleInput}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "5px",
                }}
              />
              <FormErrorMessage>{errors.fullName}</FormErrorMessage>
            </FormControl>
            {/* email */}
            <FormControl mt={4} isRequired isInvalid={errors.email}>
              <FormLabel>Email Id</FormLabel>
              <Input
                type="email"
                value={register.email}
                name="email"
                placeholder="xyz@gmail.com"
                onChange={handleInput}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "5px",
                }}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            {/* image */}
            {/* <FormControl mt={2} isRequired isInvalid={errors.image}>
        <FormLabel>Image</FormLabel>
        <input
          type="file"
          accept="image/*"
          value={register.image}
          name="image"
          onChange={handleImage}
          style={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "5px",
          }}
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>
      </FormControl> */}
            {/* password */}
            <FormControl mt={2} isRequired isInvalid={errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={register.password}
                name="password"
                onChange={handleInput}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "5px",
                }}
              />
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            {/* role */}

            <FormControl mt={2} isRequired isInvalid={errors.role}>
              <FormLabel>Role</FormLabel>
              <Select
                placeholder="Select Role"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "5px",
                }}
                onChange={(e) => handleRole(e.target.value)}
              >
                <option value="7">Customer</option>
                <option value="8">Designer</option>
                <option value="9">Manufacturer</option>
              </Select>
              <FormErrorMessage>{errors.role}</FormErrorMessage>
            </FormControl>

            <br />
            <Center>
              <Button
                onClick={handleSubmit}
                color={"white"}
                bg={"blue.500"}
                mt={4}
              >
                Submit
              </Button>
            </Center>
          </Container>
        </div>
        {/* {customerDetail && <GetAllCustomer />} */}
      </div>
      {getAdminToken &&
      <GetTokenHeader
       resetData={handleResetIsToken}
      />}
      <Footer />
    </>
  );
}

export default SignUp;

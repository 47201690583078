import React, { useEffect, useState } from 'react'
import { Tooltip, Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'

import "./Tool.css";


import { StartAnnotation, finallySaveToDB } from "../../../slices/EditSegment/EditSegment"
import { saveAnnotation, getEditAnnotation } from "../../../slices/EditSegment/EditSegment";
import {
  getStartAnnotation, getSaveDataToDB,
  getSelectedswatchStart, selectedPalletStart,
} from "../../../slices/EditSegment/EditSegment"

import { outLineMode, GetOutLineMode } from "../../../slices/gridView/MultiSelectionSegement"

import { getActiveCombo, saveCombination } from "../../../slices/combinationSwatch/CombinationSwatchSlice"

import { reloadProject } from "../../../slices/ProjectSlice"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { startCompare } from "../../../slices/compare/CompareSlice"

import {startOverlap} from "../../../slices/overlapSlice/OverLapSlice"
import {openThresold} from "../../../slices/threshold/ThresholdSlice"
import {openDoorHeigthModal} from "../../../slices/doorheight/DoorheightSlice"
const Tools = ({ canvasMode,
  canvasMode2,
  noise,
  outLine,
  compare,
  annotation,
  undo,
  reset,
  FinalLayer,
  screenShot,
  report,
  hoverLayer,
  saveDataToStrapi
}) => {

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const [isProjectLayer, setIsProjectLayer] = useState(true);
  const [isFinalLayer, setIsFinalLayer] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isNoiseReduction, setIsNoiseReduction] = useState(false);
  const [isDownloadImage, setIsDownloadImage] = useState(false);


  const [isOutLine, setIsOutLine] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showicon, setshowicon] = useState(true);
  const getSaveDataToDBs = useSelector(getSaveDataToDB)
  const getSelectedswatchStarts = useSelector(getSelectedswatchStart)

  //canvas mode
  const handleProjectLayerChange = () => {
    if (isProjectLayer) {
      setIsProjectLayer(true);
      setIsOutLine(false)
      setIsCompare(false)
    } else {
      setIsProjectLayer(false);
      setIsOutLine(false)
      setIsCompare(false)
    }


    canvasMode()

  }


  // canvas mode 2  ie edit annotation
  const handleToggleEditing = () => {
    canvasMode2()
  }

  //noie reduction
  const handleNoise = () => {
    noise()
  }


  // outilne
  const getOutLine = useSelector(GetOutLineMode);
  const handleOutline = () => {
    if (!getOutLine) {
      setIsOutLine(true)
      setIsProjectLayer(false)
      setIsCompare(false)
      dispatch(outLineMode({
        isOutLine: true
      }))
    } else {
      dispatch(outLineMode({
        isOutLine: false
      }))
      setIsOutLine(false)
      setIsProjectLayer(true)
      setIsCompare(false)
    }

  }
  // compare 
  const handleSwitchChange = () => {
    if (!isCompare) {
      setIsCompare(true)
      setIsProjectLayer(false)
      setIsOutLine(false)

    } else {
      setIsCompare(false)
      setIsProjectLayer(true)
      setIsOutLine(false)


    }
    compare()
  }

  // start Annotation
  const getStartAnnotations = useSelector(getStartAnnotation)

  const handleAnotation = () => {
    if (!getStartAnnotations) {
      dispatch(StartAnnotation({
        isStartAnnotation: true
      }))
    } else {
      dispatch(StartAnnotation({
        isStartAnnotation: false
      }))
    }

  }

  // unodo
  const handleUndo = () => {
    undo()
  }

  //reset
  const handleReset = () => {
    reset()
  }

  // final Layer
  const handleFinalLayer = () => {
    FinalLayer()
  }
  //captureScreenshot
  const captureScreenshot = () => {
    dispatch(startCompare({
      isStartCompare: true
    }))
  }

  // handleReport
  const handleReport = () => {
    report()
  }

  const handleNewDesign = () => {
    navigate("/home-page");
  }


  // save Annnottaion
  const getIsEditAnnotation = useSelector(getEditAnnotation)

  useEffect(() => {

    if (getIsEditAnnotation) {
      setEditActive(true)
    }
  }, [getIsEditAnnotation])
  const handleSaveAnnotation = () => {
    dispatch(reloadProject({
      reload: true
    }))
    if (getIsEditAnnotation) {
      setEditActive(false)
      dispatch(saveAnnotation({
        isSaveAnnotation: true,
        isEditAnnotation: false
      }))
    }
  }

  //handle hover layer

  const handleHoverLayer = () => {
    hoverLayer()
  }

  const handleShow = () => {
    setshowicon(!showicon)
  }



  // save Data to DB
  const handleSaveToDB = () => {
    dispatch(reloadProject({
      reload: true
    }))
    dispatch(selectedPalletStart(
      { isSelectPalletStart: false }
    ))
    dispatch(finallySaveToDB({
      saveData: true
    }))
  }


  // check the active combination
  const getActiveCombos = useSelector(getActiveCombo)

  const handleCombination = () => {
    dispatch(reloadProject({
      reload: true
    }))
    dispatch(saveCombination({
      IscomboActive: false,
      isSaveCombination: true
    }))
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOverLap=() => {
    dispatch(openThresold())
  }


  const handleDoorSize=()=>{
      dispatch(openDoorHeigthModal())
  }



  return (<>


    <div className="select-pallet-toolbars">
      {/* If pallet is selected  */}
      {getSelectedswatchStarts && <Tooltip placement='top' hasArrow label='Save' bg='gray.300' color='black'
      >
        <div className='image-tool-save-icon save-pallet'
          onClick={handleSaveToDB}
        >
          <img className='save-icon-image-black' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_92ea772d60.png"></img>
          <img className='save-icon-image-white' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_2_6bcdc6e7ab.png"></img>

        </div>
      </Tooltip>}

      {/* active Combination */}

      {/* {getActiveCombos && <Tooltip  placement='right' hasArrow label='Save Combination' bg='gray.300' color='black'
        >
      <div className='image-tool-save-icon save-combo' 
      onClick={handleCombination}
      >
         <i class="bi bi-back"></i>
   
       </div>
       </Tooltip>} */}



      {/* if edit Annoataion active  */}
      {getIsEditAnnotation && <Tooltip placement='right' hasArrow label='Save Annotaion' bg='gray.300' color='black'
      >
        <div className='image-tool-save-icon save-annotation-icon'
          onClick={handleSaveAnnotation}
        >
          <img className='save-icon-image-black' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_92ea772d60.png"></img>
          <img className='save-icon-image-white' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_2_6bcdc6e7ab.png"></img>

        </div>
      </Tooltip>}

      <div className='tool-show-hide-icon' onClick={handleShow}>
        <i class="bi bi-eye-fill"></i>

      </div>



      {showicon && <div className='pro-tool-wrapper-icons'>
        <div className="tool-wrapper">


          <div className="select-tool-project">

            {/* <Tooltip label="Noise Reduction" className="select-tooltip-title">
          <div className={isFinalLayer ? "active-icon" : ""}>
            <i class="bi bi-eraser" onClick={handle1}></i>
          </div>
        </Tooltip> */}




            <Tooltip
              hasArrow label='Canvas Mode' bg='gray.300' color='black'

            >
              <div className={isProjectLayer ? "active-icon" : ""}>
                <i
                  class="bi bi-intersect"
                  onClick={handleProjectLayerChange}

                ></i>
              </div>
            </Tooltip>

            <Tooltip
              hasArrow label='Noise Reduction' bg='gray.300' color='black'
            >
              <div className={isNoiseReduction ? "active-icon" : ""}>
                <i class="bi bi-eraser"
                // onClick={handleNoise}
                ></i>
              </div>
            </Tooltip>

            <Tooltip
              hasArrow label='Outlines' bg='gray.300' color='black'


            >
              <div className={isOutLine ? "active-icon" : ""}>
                <i class="bi bi-border"
                  onClick={handleOutline}
                ></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Compare' bg='gray.300' color='black'  >
              <div className={isCompare ? "active-icon" : ""}>
                <i
                  class="bi bi-chevron-bar-contract"
                  onClick={handleSwitchChange}
                ></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Full Screen' bg='gray.300' color='black' >
              <div >
                <i className="bi bi-arrows-fullscreen"
                //    onClick={handleNewDesign}
                ></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Dimention' bg='gray.300' color='black' >
              <div >
              <i class="bi bi-rulers"></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Save' bg='gray.300' color='black' >
              <div >
            <i class="bi bi-save"
                   onClick={handleSaveToDB}

                ></i>
                  
              </div>
            </Tooltip>


          </div>





          {/* annotaion icon start */}

          {/* <div className="select-tool-project"
      >
        <Tooltip hasArrow label='Annotation' bg='gray.300' color='black'
        >
          <div className={editActive ? "is-edit-active" : ""}
          >
          <i class="bi bi-bounding-box-circles" 
          onClick={handleAnotation}
          ></i>
          </div>
        </Tooltip>


             <Tooltip hasArrow label='Save Annotaion' bg='gray.300' color='black'  >
                <div className= {editActive ? "is-edit-active" : "" } 
                onClick={handleSaveAnnotation}
       >        
         <img className='save-icon-image-black' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_92ea772d60.png"></img>
         <img className='save-icon-image-white' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_2_6bcdc6e7ab.png"></img>


           
          </div>
      </Tooltip>



        <Tooltip  hasArrow label='Undo' bg='gray.300' color='black'  >
          <div className={editActive ? "is-edit-active" : ""}>
            <i class="bi bi-arrow-left-square"></i>
          </div>
        </Tooltip>

        <Tooltip hasArrow label='Reset' bg='gray.300' color='black'  >
          <div className={editActive ? "is-edit-active" : ""}>
            <i class="bi bi-arrow-repeat" ></i>
        
          </div>
        </Tooltip>



        <Tooltip >
          <div className={isCompare ? "active-icon" : ""}>
           <i className="bi bi-arrows-fullscreen" 
          ></i>
          
          </div>
        </Tooltip>
        <Tooltip >
          <div className={isCompare ? "active-icon" : ""}>
          <i className="bi bi-pentagon" ></i>
          </div>
        </Tooltip>

        <Tooltip hasArrow label='View Projects' bg='gray.300' color='black'  >
          <div className={isCompare ? "active-icon" : ""}>
            <i class="bi bi-house-gear"></i>
          </div>
        </Tooltip>



      </div> */}

          {/* annotaion icon End */}





          {/* model icon start */}
          <div className="select-tool-project">

            <Tooltip hasArrow label='Undo' bg='gray.300' color='black'  >
              <div className={editActive ? "is-edit-active" : ""}>
                <i class="bi bi-arrow-left-square"></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Save Combination' bg='gray.300' color='black' >
              <div >
                <i class="bi bi-back" onClick={handleCombination} ></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Final Layer' bg='gray.300' color='black' >
              <div className={isFinalLayer ? "active-icon" : ""}>
                <i class="bi bi-layers-fill" onClick={handleFinalLayer}></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Download Image' bg='gray.300' color='black'>
              <div className={isDownloadImage ? "active-icon" : ""}>
                <i className="bi bi-download" onClick={captureScreenshot}></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Report' bg='gray.300' color='black' >
              <div className={isCompare ? "active-icon" : ""}>
                <i class="bi bi-file-earmark-ppt" onClick={handleReport}></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Information' bg='gray.300' color='black' >
              <div onClick={onOpen} className={isCompare ? "active-icon" : ""}>
                <i class="bi bi-info-lg" ></i>
                {/* onClick={handleTesting} */}
              </div>
            </Tooltip>
            


            <Tooltip hasArrow label='Conneted Shape' bg='gray.300' color='black' >
              <div 
              onClick={handleOverLap}
              className={isCompare ? "active-icon" : ""}>
               
              <i class="bi bi-columns"></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Door height' bg='gray.300' color='black' >
              <div 
              onClick={handleDoorSize}
              className={isCompare ? "active-icon" : ""}>
               <i class="bi bi-door-closed-fill"></i>
              </div>
            </Tooltip>

          </div>

        </div>
      </div>
      }







      {/* project information start */}

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="information-section">
          <ModalHeader>Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h3 className='information-text'>Select the annotation in order by</h3>

            <div className='order-seg-section'>
              <div className='order-info-select'>
                <p>1</p>
                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_icon_c0ba19ace9.png"></img>
                <h4>Door</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>

              </div>

              <div className='order-info-select'>
                <p>2</p>
                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/window_8a0a08a47b.png'></img>
                <h4>Window</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>

              </div>

              <div className='order-info-select'>
                <p>3</p>
                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/garage_icon_c55b4bbc81.png'></img>
                <h4>Garage </h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>

              </div>

              <div className='order-info-select'>
                <p>4</p>
                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_10a1f3a707.png'></img>
                <h4>Wall</h4>
                <InputGroup size='md' className='px-1 mt-3'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>
              </div>

              <div className='order-info-select'>
                <p>5</p>
                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/roof_6629efd7d9.png'></img>
                <h4>Roof</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>
              </div>

              <div className='order-info-select'>
                <p>6</p>
                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/trim_icon_8e4b3d6eb3.png"></img>
                <h4>Trim</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>
              </div>

              <div className='order-info-select'>
                <p>7</p>
                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/light_0a545de930.png"></img>
                <h4>Light</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>
              </div>


              <div className='order-info-select'>
                <p>8</p>
                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/gutter_icon_2_158cfe7fa3.png"></img>
                <h4>Gutter</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>
              </div>


              <div className='order-info-select'>
                <p>9</p>
                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/soffit_7decaaef11.png"></img>
                <h4>Soffit</h4>
                <InputGroup size='md' className='px-1'>
                  <Input className='seg-color-input' placeholder=' Color' _placeholder={{ opacity: 1, color: 'gray.800' }}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button className="color-copy-btn" h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_1_4fb6e82bc7.png"></img> : <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/copy_2_6f73df7866.png"></img>}
                    </Button>



                  </InputRightElement>
                </InputGroup>
              </div>

            </div>

          </ModalBody>


        </ModalContent>
      </Modal>


      {/* project information end */}





    </div>
  </>


  )
}

export default Tools
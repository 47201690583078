import { createSlice } from "@reduxjs/toolkit";

const MouseEventSlice = createSlice({
  name: "mouseEvent",
  initialState: {
   isOnMouseUp:false,
   labelName:null,
   isOffMouseUP:false,
   segmentName:null,
   isBAckToPannel:false,
   selectedSegment:[]

  },
  reducers: {
    addIsOnMouseUp: (state, action) => {
      state.isOnMouseUp = action.payload.isOnMouseUp
      state.labelName=action.payload.labelName
      state.segmentName=action.payload.segmentName
      
    },
    addSelectedSegment:(state, action)=>{
      state.selectedSegment=action.payload.selectedSegment
    },
    removeSelectedSegment:(state, action)=>{
      state.selectedSegment=[]
    },
    offMouseUP:(state, action) => {
        state.isOffMouseUP=action.payload.isOffMouseUP
    },
   resetMouseEvent:(state, action) => {
    state.isOnMouseUp=false
    state.labelName=null
    state.segmentName=null
    state.selectedSegment=[]
   },
   moveToMainPannel:(state, action) => {
    state.isBAckToPannel=action.payload.isBAckToPannel
   }
  },
});

export const { addIsOnMouseUp,
  removeSelectedSegment,
  offMouseUP,resetMouseEvent,moveToMainPannel,addSelectedSegment
} = MouseEventSlice.actions;
    
export const getIsMouseClick = (state, action) => state.mouseEvent;
export const getBackToMainPannel= (state, action) => state.mouseEvent.isBAckToPannel
export const getSelectedSegments=(state, action)=>state.mouseEvent.selectedSegment
export default MouseEventSlice.reducer;

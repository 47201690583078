import React, { useEffect, useState } from "react";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { Tooltip, Button, Flex, HStack, Box } from "@chakra-ui/react";

// import { Tooltip, Box, Button } from "@chakra-ui/react";
import {
  selectAllItems,
  removeSegmentFromMAsterArray,
} from "../slices/MasterArraySlice";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentab } from "../slices/CurrentTabSlice";
import { getProjectId } from "../slices/ProjectSlice";
import homeicon from "../../assets/images/demo/home_images/image-layer.png";
import {
  updateMasterSegment,
  addMasterArray,
} from "../slices/MasterArraySlice";
import { getCurrentablePalettes } from "../slices/CurrentSelectedPalleteSlice";
import GetJob from "../HomePage/GetJob";
import { onHover, onAllHover, deleteSegment } from "../slices/AlertSlice";
import ShowEdit from "../Add_Edit_Pallet/ShowEdit";
import { samLoading, deleteLoading } from "../slices/AlertSlice";
import { addSelectedPallete } from "../slices/SelectedPalletdSlice";

import ShowSwatch from "../Tabs/ShowSwatch";
import { addSelectedPalletImag } from "../slices/TransparentPalletImgSlice";
import {
  selectAllSegments,
  removeSegmentFromSegments,
} from "../slices/AllSegmentSlice";

import { getTranspalletImage } from "../slices/TransparentPalletImgSlice";
import DeleteSegment from "../Tabs/DeleteSegment";
import DeleteSegmentAlert from "./DeleteSegmentAlert";
import UpdateDeletedSegmentToDb from "./UpdateDeletedSegmentToDb";
import CheckBoxSegement from "../Tabs/CheckBoxSegement";
import SelectedOverALLPallete from "./SelectedOverALLPallete";
import { addCurrentPallete } from "../slices/CurrentSelectedPalleteSlice";
import { addNewSelectedSwatch } from "../slices/SelectedSwatchImage";

import { addViewPalletInfo } from "../slices/ViewPalletInfoSlice";

import ShowOverAllPalletInfo from "../Tabs/ShowOverALLPalletInfo";
import { deleteMasterJob } from "../slices/MasterJobs";
import {
  openSegAfterUpload,
  getOpenSegAfterUpload,
} from "../slices/AlertSlice";

const SegmentationTab = ({ job_Id, reSetjob }) => {
  const dispatch = useDispatch();
  const [jobId, setJobId] = useState();
  const getMasterArray = useSelector(selectAllItems);
  const CurrentTabs = useSelector(getCurrentab);
  const CurrentProjects = useSelector(getProjectId);
  const [isloading, setIsloading] = useState(false);
  const getAllSegments = useSelector(selectAllSegments);
  const [isSelectedEdit, setIsSelectedEdit] = useState();
  const [isshowEdit, setIsshowEdit] = useState(false);
  const [isSelectedPallet, setIsSelectedPallet] = useState(false);
  const getTransParentImage = useSelector(getTranspalletImage);
  const [isOpen, setIsOpen] = useState(false);
  const [updatedJob, setUpdatedJob] = useState();
  const getCurrentPallate = useSelector(getCurrentablePalettes);
  // create dynamic  array base on segmentation to display selected palleted
  const [segmentStates, setSegmentStates] = useState({});

  useEffect(() => {
    if (getAllSegments && getAllSegments?.allSegmentsArray.length > 0) {
      let currentArray = [];
      getAllSegments?.allSegmentsArray.map((item) => {
        currentArray.push({
          segmentName: item.name,
          selectedPlaletUrl: "",
          s3BucketImage: "",
        });
      });
      setSegmentStates(currentArray);
      // dispatch(addCurrentPallete(currentArray));
    }
  }, [getAllSegments.allSegmentsArray]);

 

  ///create master array after getting job data
  useEffect(() => {
    if (getAllSegments && getAllSegments?.allSegmentsArray.length > 0) {
    //  createMasterArray(getAllSegments.allSegmentsArray);
    }
  }, [getAllSegments.allSegmentsArray]);
  const createMasterArray = (jobData) => {
    let allJobs = jobData;
    let masterArray = getMasterArray.master;
    let arrays = [];
    if (
      masterArray &&
      masterArray.length > 0 &&
      allJobs &&
      allJobs.length > 0
    ) {
      // setIsloading(true);
      masterArray.map((item) => {
        let segArray = [];
        allJobs.map((job) => {
          let alphabets = job.name.match(/[a-zA-Z]+/)[0];
          // let jobName = job.name.slice(0, -1);
          if (alphabets === "Garage") {
            alphabets = "GarageDoor";
          }

          if (item.name === alphabets) {
            segArray.push(job);
          }
        });

        arrays.push({
          id: item.id,
          name: item.name,
          labels: item.labels,
          icon: item.icon,
          segmentation: segArray,
          allSwatches: [],
        });
      });
    }
    sessionStorage.setItem("MasterArrays", JSON.stringify(arrays));
   dispatch(addMasterArray(arrays));
   setIsloading(true);
   dispatch(samLoading({ samLoading: false }));
  };

  // open each segment
  const [isSeg, setIsSeg] = useState(false);
  const [eachSegment, setEachSegment] = useState();
  const [showSeg, setShowSeg] = useState([]);
  const handleSegments = (name) => {
    if (name != swatchName) {
      setEachSegment(null);
      setIsCheckBox(false); // Toggle the isCheckBox state

      setIsSwatchActive(false);
    }
    setShowSeg((prev) => [...prev, name]);
    setEachSegment(name);
    // setIsSeg(!isSeg);
    if (name) {
      if (showSeg.includes(name)) {
        setIsSeg(false);
        setIsShowSegmentActive(false);

        setShowSeg([]);
      } else {
        setIsSeg(true);
        setIsShowSegmentActive(true);
      }
    }
  };

  // open SegAfter upload Swatch
  const getOpenSegAfterUploads = useSelector(getOpenSegAfterUpload);
  useEffect(() => {
    if (getOpenSegAfterUploads) {
      setIsSeg(true);
      setIsShowSegmentActive(true);
    }
  }, [getOpenSegAfterUploads]);
  useEffect(() => {
    setEachSegment(null);
  }, []);

  // on hover Ech segmentation
  const handleOnHoverSegment = (segmentName, polygon) => {
    dispatch(
      onHover({
        ishover: true,
        segmentName: segmentName,
        polygon: polygon,
      })
    );
  };

  // close hover on each segmentation
  const handleCloseHoverSegment = () => {
    dispatch(onHover({ ishover: false, segmentName: null }));
  };

  // Add pallet , edit and delete segments
  const [indexs, setIndex] = useState();
  const handleEditLabel = (labelName, label_Id, index, selectedsegement) => {
    setIsSelectedEdit(labelName);
    dispatch(addSelectedPallete({ selectedPallete: [selectedsegement] }));

    dispatch(
      addNewSelectedSwatch({
        newSelectedSwatch: selectedsegement,
      })
    );
    setIsshowEdit(true);
    setIndex(index);
    setIsSelectedPallet(true);
    dispatch(
      addSelectedPalletImag({
        segmentName: labelName,
        selectedPlaletUrl: null,
        s3BucketImage: null,
        SwatchId: null,
      })
    );
  };

  // Hover all poygon
  const handleHoverAllLabels = (names) => {
    let allPolgon = [];

    if (getMasterArray && getMasterArray?.master.length > 0) {
      getMasterArray.master.map((item) => {
        if (item.name === names) {
          item.segmentation.map((segment) => {
            let allPoly = segment.annotationPolygon
              .split(",")
              .map((value) => parseInt(value.trim(), 10));

            allPolgon.push(allPoly);
          });
        }
      });
      dispatch(
        onAllHover({
          isAllHover: true,
          segmentName: names,
          allpolygon: allPolgon,
        })
      );
    }
  };

  // remove all polygon'
  const handleRemoveAllHover = () => {
    dispatch(
      onAllHover({
        isAllHover: false,
        segmentName: null,
      })
    );
  };

  // handle delete segment
  const [deleteLabelName, setDeleteLabelName] = useState();
  const [deleteSegName, setDeleteSegName] = useState();
  const handleDeletSegment = (labelName, segName) => {
    setDeleteLabelName(labelName);
    setDeleteSegName(segName);

    setIsOpen(true);
  };

  // handle close delete segment modal
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [isDelete, setIsDelete] = useState(false);
  const [JobId, setJobIds] = useState();
  // handle updated segment
  const handleUpdatedSegment = (updatedSeg) => {
    setUpdatedJob(updatedSeg);
    setJobIds(CurrentProjects.jobId);

    setIsDelete(false);
  };

  //handle close delete segment modal after
  const [updataDetail, setUpDateDetail] = useState();
  const handleCloseModalSegmentDelete = () => {
    setIsOpen(false);
    setIsDelete(true);
    dispatch(
      removeSegmentFromMAsterArray({
        segment: deleteSegName,
        label: deleteLabelName,
      })
    );

    dispatch(
      removeSegmentFromSegments({
        label: deleteLabelName,
      })
    );
    dispatch(
      deleteSegment({
        isDeleteSegment: true,
      })
    );
    dispatch(
      deleteMasterJob({
        labelName: deleteLabelName,
      })
    );
    dispatch(
      deleteLoading({
        isDeleteSegmentLoading: true,
      })
    );
  };
  const [isSwatchActive, setIsSwatchActive] = useState(false);
  const [isShowSegmentActive, setIsShowSegmentActive] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [swatchName, setSwatchName] = useState();

  // open checkBox on segment
  const [checkLabel, setCheckLabel] = useState([]);

  const handleCheckBox = (labelName, index) => {
    if (labelName != eachSegment) {
      setEachSegment(null);
      setIsSeg(false);
      setIsShowSegmentActive(false);
    }
    setCheckLabel((prev) => [...prev, labelName]);
    setSwatchName(labelName);
    setIsSwatchActive(true);
    dispatch(openSegAfterUpload({ isOpenSegAfterUpload: false }));
    if (labelName) {
      if (checkLabel.includes(labelName)) {
        setEachSegment(labelName);
        setIsCheckBox(false); // Toggle the isCheckBox state
        setIndex(index);
        setCheckLabel([]); // Clear the checkLabel array
        setIsSwatchActive(false);
      } else {
        setEachSegment(labelName);
        setIsCheckBox(true); // Toggle the isCheckBox state
        setIndex(index);
        setIsSwatchActive(true);
      }
    }
  };

  useEffect(() => {}, [checkLabel]);

  // hanndle view Pallete
  const handleViePallete = (segName, segSwatches) => {
    // console.log(segName);
    // console.log(segSwatches);
    if (segSwatches.length > 0) {
      dispatch(
        addViewPalletInfo({
          palleteInfo: segSwatches,
          segName: segName,
        })
      );
    } else {
    }
  };

  // show overALl swatch info
  const [overSwatchName, setOverSwatchName] = useState();
  const [isShowOverPalletInfo, setIsShowOverAllPAlletInfo] = useState(false);
  const handleOverAllSwatchInfo = (segName) => {
    //setEachSegment(segName)

    if (getMasterArray.master && getMasterArray.master.length > 0) {
      getMasterArray.master.map((master) => {
        if (master.name === segName) {
          if (master.allSwatches.length > 0) {
            setOverSwatchName(segName);

            setIsShowOverAllPAlletInfo(!isShowOverPalletInfo);
          } else {
            alert("No swatch is added");
            return;
          }
        }
      });
    }
  };

  const handleCloseOverAllPAlletInfo = () => {
    setIsShowOverAllPAlletInfo(false);
  };
  return (
    <>
      {<GetJob />}
      {/* upadte segment after delete */}
      {isDelete && <DeleteSegment deleteSegment={handleUpdatedSegment} />}
      {/* Alert to delete the segment */}
      {isOpen && (
        <DeleteSegmentAlert
          isOpen={isOpen}
          onClose={handleCloseModal}
          deleteSegment={handleCloseModalSegmentDelete}
        />
      )}

      {updatedJob && (
        <UpdateDeletedSegmentToDb updatedJob={updatedJob} JobId={JobId} />
      )}

      {isloading ? (
        getMasterArray?.master?.length > 0 &&
        (getMasterArray?.master ?? []).map((items, index) => (
          <div key={items.id}>
            {items.segmentation.length > 0 && (
              <div
                className={`add-layer Labels dzi-${items.name}`}
                key={items.id}
              >
                <div className={`layer-add-item`} key={items.icon}>
                  <a className="layer-add-item-icon">
                    <img src={items.icon}></img>
                  </a>
                </div>
                <div className="layer-add-item-text">
                  {
                    <Button
                      className="labels-button"
                      key={items.id}
                      colorScheme="teal"
                      variant="outline"
                      onClick={(e) => handleSegments(e.target.value)}
                      value={items.name}
                      onMouseEnter={(event) => {
                        const targetValue = event.target.value;

                        handleHoverAllLabels(event.target.value, targetValue);
                      }}
                      onMouseLeave={handleRemoveAllHover}
                    >
                      {items.name}
                    </Button>
                  }
                </div>

                {items.name === eachSegment && (
                  <SelectedOverALLPallete eachSegment={items.name} />
                )}

                {/* segmentation-pallet start*/}

                {/*segmentation-pallet end */}
                <div className="layer-add-items ">
                  <div className="select-labels-button">
                    <Tooltip
                      label="Select segment(s)"
                      className="select-tooltip-title"
                    >
                      <Button
                        onClick={() => handleCheckBox(items.name, index)}
                        className={
                          items.name === swatchName && isSwatchActive
                            ? "select-label-active"
                            : ""
                        }
                      >
                        {" "}
                        <i
                          className="bi bi-list-check"
                          onClick={() => handleCheckBox(items.name, index)}
                          style={{
                            color: "#fff!important",
                          }}
                        ></i>
                      </Button>
                    </Tooltip>

                    <Tooltip
                      label="View Swatches"
                      className="select-tooltip-title"
                    >
                      <Button
                        onClick={() => handleOverAllSwatchInfo(items.name)}
                        className={
                          items.name === overSwatchName && isShowOverPalletInfo
                            ? "select-label-active"
                            : ""
                        }
                      >
                        {" "}
                        <i className="bi bi-images"></i>
                      </Button>
                    </Tooltip>

                    <Tooltip
                      label="Show Segments"
                      className="select-tooltip-title"
                    >
                      <Button
                        // className="chakra-button css-ez23ye"
                        className={
                          items.name === eachSegment && isShowSegmentActive
                            ? "select-label-active"
                            : ""
                        }
                        onClick={() => handleSegments(items.name, index)}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          focusable="false"
                          className="chakra-icon css-onkibi"
                          aria-hidden="true"
                        >
                          <g fill="currentColor">
                            <path d="M23.432,10.524C20.787,7.614,16.4,4.538,12,4.6,7.6,4.537,3.213,7.615.568,10.524a2.211,2.211,0,0,0,0,2.948C3.182,16.351,7.507,19.4,11.839,19.4h.308c4.347,0,8.671-3.049,11.288-5.929A2.21,2.21,0,0,0,23.432,10.524ZM7.4,12A4.6,4.6,0,1,1,12,16.6,4.6,4.6,0,0,1,7.4,12Z"></path>
                            <circle cx="12" cy="12" r="2"></circle>
                          </g>
                        </svg>
                      </Button>
                    </Tooltip>

                    <Tooltip label="GenAI" className="select-tooltip-title">
                      <Button className="ai-icon-btn ">
                        {" "}
                        {/* <i class="bi bi-microchip-ai"></i> */}
                        <img
                          className="ai-icon"
                          src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/icon_ai_4c22481b8b.png"
                        ></img>
                        <img
                          className="ai-icon-white"
                          src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/ai_icon_fcb8a57f35.png"
                        ></img>
                      </Button>
                    </Tooltip>
                  </div>
                </div>

                <div className="select-chackbox-segment">
                  {/* check Box  */}

                  {isCheckBox && items.name == eachSegment && (
                    <CheckBoxSegement
                      eachSegment={eachSegment}
                      indexs={index}
                    />
                  )}
                </div>

                {/* individual segments  */}
                {isSeg &&
                  items.segmentation.length > 0 &&
                  items.name == eachSegment &&
                  items.segmentation.map((label) => (
                    <div
                      className={`segment-div dzi-${label.name}`}
                      onMouseLeave={handleCloseHoverSegment}
                      onMouseEnter={() =>
                        handleOnHoverSegment(
                          label.name,
                          label.annotationPolygon
                        )
                      }
                    >
                      <div className="segments segements-icon" key={label.id}>
                        <div className="segments-image">
                          {getCurrentPallate &&
                          getCurrentPallate.currentpallate &&
                          getCurrentPallate.currentpallate.length > 0 ? (
                            getCurrentPallate.currentpallate.map((item) =>
                              item.segmentName === label.name ? (
                                item.s3BucketImage != null ? (
                                  <img
                                    key={item.segmentName}
                                    src={
                                      process.env
                                        .REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                                      (item.s3BucketImage || "")
                                    }
                                    alt="image"
                                  />
                                ) : (
                                  <img
                                    key="no-data"
                                    src={homeicon}
                                    width={200}
                                    className="home-images"
                                    alt="home icon"
                                  />
                                )
                              ) : null
                            )
                          ) : (
                            <img
                              key="no-data"
                              src={homeicon}
                              width={200}
                              className="home-images"
                              alt="home icon"
                            />
                          )}
                        </div>
                        <div className="segemnts-button">
                          <Button
                            value={label.name}
                            colorScheme="teal"
                            variant="outline"
                            // onMouseLeave={handleCloseHoverSegment}
                            onMouseEnter={() =>
                              handleOnHoverSegment(
                                label.name,
                                label.annotationPolygon
                              )
                            }
                            onClick={() =>
                              handleEditLabel(
                                label.name,
                                label.id,
                                index,
                                label
                              )
                            }
                          >
                            {label.name}
                          </Button>
                        </div>
                      </div>
                      <div className="palletselected">
                        <div className="segaments-pallet-icon">
                          {/* minimiz-icon */}
                          <div className="minimiz-icon">
                            <span class="bi bi-dash"></span>
                          </div>
                          {/* minimiz-icon */}
                        </div>

                        <div className="segament-pallet-images">
                          {isSelectedPallet &&
                            isSelectedEdit.localeCompare(label.name) === 0 && (
                              <ShowSwatch isSelectedEdit={isSelectedEdit} />
                            )}
                        </div>

                        {/* Modal for displaying additional content */}
                      </div>

                      <div className="Segments-action">
                        {isshowEdit && isSelectedEdit === label.name && (
                          <ShowEdit
                            selectedPallete={label.SelectSwatchJob}
                            indexs={indexs}
                            // viewPallet={() => handleViewPallet(label.name)}
                            // pallet={() => handlePallet(label.name)}
                            deleteSegment={() =>
                              handleDeletSegment(label.name, items.name)
                            }
                            searchViewPallet={() =>
                              handleViePallete(
                                label.name,
                                label.SelectSwatchJob
                              )
                            }
                            // ConfirmDelete={handle_Confirm_delete}
                          />
                        )}
                      </div>
                      {/* <div className="Show-over-Pallet-Info"> */}
                      {isShowOverPalletInfo && (
                        <ShowOverAllPalletInfo
                          overSwatchName={overSwatchName}
                          isOpen={isShowOverPalletInfo}
                          onClose={handleCloseOverAllPAlletInfo}
                        />
                      )}

                      {/* </div> */}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="grid-sec">
          <CircularProgress isIndeterminate color="green.300" />

          <div>
            <div className="card-dable">
              <div className="card-labels-dable">
                <div className="shimmerBG-dable media-dable"></div>

                <div className="shimmerBG-dable title-line-dable-1"></div>
              </div>
              <div className="p-32">
                <div className="card-labels-btn-dable">
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                </div>
              </div>
            </div>

            <div className="card-dable">
              <div className="card-labels-dable">
                <div className="shimmerBG-dable media-dable"></div>

                <div className="shimmerBG-dable title-line-dable-1"></div>
              </div>
              <div className="p-32">
                <div className="card-labels-btn-dable">
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                </div>
              </div>
            </div>

            <div className="card-dable">
              <div className="card-labels-dable">
                <div className="shimmerBG-dable media-dable"></div>

                <div className="shimmerBG-dable title-line-dable-1"></div>
              </div>
              <div className="p-32">
                <div className="card-labels-btn-dable">
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                </div>
              </div>
            </div>

            <div className="card-dable">
              <div className="card-labels-dable">
                <div className="shimmerBG-dable media-dable"></div>

                <div className="shimmerBG-dable title-line-dable-1"></div>
              </div>
              <div className="p-32">
                <div className="card-labels-btn-dable">
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                  <div className="shimmerBG-dable title-line-dable"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SegmentationTab;

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_PROJECT } from "../GraphQL/GraphQl";
import { useDispatch, useSelector } from "react-redux";
import { getProjectId, removeProjectId } from "../slices/ProjectSlice";
import { getCustomer } from "../login/AuthSlice";
import {
  removeProjectView,
  updateProjectView,
  addProjectView,
} from "../slices/ProjetcViewSlice";
const ReloadProject = ({ callReload, reset }) => {
  const getReload = useSelector(getProjectId);
  const dispatch = useDispatch();
  const [projectIds, setProjectIds] = useState();
  const customerDetails = useSelector(getCustomer);
  useEffect(() => {
    if (getReload.reload) {
      setProjectIds(customerDetails.customerId);
    }
  }, [getReload]);
  const { loading, error, data, refetch } = useQuery(GET_USER_PROJECT, {
    variables: {
      projectId: projectIds, // Pass the jobId as a variable to the query
    },
  });

  useEffect(() => {
    if (callReload) {
      handleRefetch();
    }
  }, [callReload]);
  const handleRefetch = () => {
    refetch(); // Call refetch to fetch the data again
  };
  useEffect(() => {
    if (!loading && data) {
      if (
        data &&
        data.customers &&
        data.customers.data &&
        data.customers.data[0] &&
        data.customers.data[0].attributes &&
        data.customers.data[0].attributes.projects &&
        data.customers.data[0].attributes.projects.data.length > 0
      ) {
        dispatch(
          updateProjectView(data.customers.data[0].attributes.projects.data)
        );
        // dispatch(removeProjectId());
        setProjectIds(null);
        reset();
      } else {
        alert("No Project in your account");
      }
    }
  }, [data, loading]);
  return <></>;
};

export default ReloadProject;

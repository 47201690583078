import React from 'react'
import {useEffect , useState} from "react"
const MediaSwatch = ({allSwatches}) => {

    console.log("allSwatches",allSwatches)
  return (
    <div>
 <div >

{allSwatches &&
    allSwatches.length > 0 &&
    allSwatches.map((swatch) => (
        <div className="select-pallet-image select-palllet-seg">
            <div key={swatch.id} className="pallet-img"
                
            >
                    <h6>{swatch?.name}</h6>
                <img src={swatch?.image?.url}

                ></img>
            </div>
            
        </div>

    ))

}
</div>

    </div>
  )
}

export default MediaSwatch
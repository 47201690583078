import React from 'react'
import {useState } from 'react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Portal,
  } from '@chakra-ui/react'
  import { Tooltip } from '@chakra-ui/react'
import {useSelector, useDispatch} from "react-redux"
import {selectPopeHover} from "../../slices/popHover/PopHoverSlice"
import {updateHideMasterArray} from "../../slices/MasterArraySlice"
// import DeleteSwatchAlert from "../deleteSwatch/DeleteSwatchAlert"
import DeleteSwatchAlert from "../../NewDesign/leftsection/deleteSwatch/DeleteSwatchAlert"

import {removeSwatchFromMasterArray} from "../../slices/MasterArraySlice"
import {removeSwatchFromSegments} from "../../slices/AllSegmentSlice"
import {deleteSwatchMasterJob} from "../../slices/MasterJobs"
import {updateCurrenPallate} from "../../slices/CurrentSelectedPalleteSlice"

import {deleteSegment} from "../../slices/popHover/PopHoverSlice"
import {updateHideUnHide} from "../../slices/EditSegment/EditSegment"

import {openModal} from "../../slices/uploadSwatch/UploadSwatchImage"
import{reGenerateSwatch} from "../../slices/popHover/PopHoverSlice"

import{addSelectedPallete} from "../../slices/SelectedPalletdSlice"

import {palletLoading} from "../../slices/AlertSlice"
const UniquePopHover = ({
  swatch,
  segmentName,
  masterName,
  segment
}) => {
   const dispatch= useDispatch()
   const [isDelete, setISDelete] = useState(false);
   const [masterNames, setMasterName] = useState();
   const [segName, setSegName] = useState();
   const [swatch_Id, setSwatch_Id] = useState();

    const handleuploadimage=(segmentName,masterName,swatch_id)=>{
     // 
        
      dispatch(openModal({
        isUploadStart:true,
        segmentName:segmentName,
        masterName:masterName,
        swatchId:swatch_id
    }))
  }

    const   handleHidePallet=(segName,swatchId)=>{
       // 
       let swatch_id;
       if (typeof swatchId === 'string') {
        swatch_id=parseInt(swatchId, 10);
      }else{
        swatch_id=swatchId
      }
       dispatch( selectPopeHover({
        segmentName:segName,
        ishide:true,
        isUnhide:false,
        swatchId:swatch_id  // id in integer
       }))

       // update master Array
       dispatch(updateHideMasterArray({
        labelName:masterName,
        segName:segName,
        swatchId:swatchId,  ///id in string
        hideStatus:true
       }))

       //update hude annd unHie on edit segment slice
         dispatch(updateHideUnHide({
          segName:segName,
          swatchId:swatchId,
          hideStatus:true,
          
         }))

    }
    
    const handleUnHidePallet=(segName,swatchId)=>{
       //
       let swatch_id;
       if (typeof swatchId === 'string') {
        swatch_id=parseInt(swatchId, 10);
      }else{
        swatch_id=swatchId
      }
       dispatch( selectPopeHover({
        segmentName:segName,
        ishide:false,
        isUnhide:true,
        swatchId:swatch_id  // id in integer
       }))

       // update master Array
       dispatch(updateHideMasterArray({
        labelName:masterName,
        segName:segName,
        swatchId:swatchId,  ///id in string
        hideStatus:false
       }))
        //update hude annd unHie on edit segment slice
        dispatch(updateHideUnHide({
          segName:segName,
          swatchId:swatchId,
          hideStatus:false,
          
         }))

    }

   
      // handle remove pallet 
      const handleRemovePallet=(masterName, segName,swatchid ) => {
         setISDelete(true);
         setMasterName(masterName);
         setSegName(segName);
         setSwatch_Id(swatchid);
        
      }
        //close alert modal dialog
  const handleCloseAlertModal = () => {
    setISDelete(false);
  };

  const handleDeletingSwatch=()=>{
    setISDelete(false);
    // remove from MasterArray list
    dispatch(
      removeSwatchFromMasterArray({
        masterName: masterNames,
        segName: segName,
        swatchId: swatch_Id,
      })
    );

     // remove data from allSegment Array list
     dispatch(
      removeSwatchFromSegments({
        labelName: segName,
        swatchId: swatch_Id,
      })
    );


       // remove swatch from master job list
    dispatch(
      deleteSwatchMasterJob({
        labelName: segName,
        swatchId: parseInt(swatch_Id),
      })
    );

    // remove from current Pallte Image if Swatch Id is match
    dispatch(
      updateCurrenPallate({
        labelName: segName,
        swatchId: swatch_Id,
      })
    );

    // delete from  the canavs 
    dispatch(deleteSegment({
      segmentName: segName,
      isDelete: true
    }))
  }
  const handleRegenerate=(segmentName, masterName,swatchDetail,segment)=>{
   //regenerate(segmentName, masterName,swatch_id)

   // starting loading 
   dispatch(palletLoading({
    palletLoading:true
   }))
   console.log("swatch_id",swatchDetail)
  let selectedsegement=[segment]
   // add selected segment detail
   dispatch(addSelectedPallete({ selectedPallete: selectedsegement }));

   const data={
    segmentName:segmentName,
    flagDemo: 1,
    labelName: masterName,
    palletId: parseInt(swatchDetail?.data?.id),
    palletImagePath: swatchDetail?.data?.attributes?.image_s3,
    palletName: swatchDetail?.data?.attributes?.name,
    palletType:swatchDetail.data.attributes.Type,
   }
   dispatch(reGenerateSwatch({
    reGenSwatch:data
   }))
  }
   
  return (
    <div>
        <div className="pallet-img-inf-popup">
                                          <div className="pallet-images-inf">

                                            <img
                                              src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch?.swatch?.data?.attributes.image_s3}`}
                                            />



                                          </div>
                                          <div className='pallet-select-tool-section '>
                                            <Tooltip hasArrow label='Fav' bg='gray.300' color='black'>
                                              <div className='pallet-select-tools icon-active '>
                                                        {/* active className="icon-active" */}
                                                <i class="bi bi-star"></i>
                                              </div>
                                            </Tooltip>

                                            {/* <Tooltip hasArrow label='Upload Image' bg='gray.300' color='black'>
                                              <div 
                                           onClick={()=>handleuploadimage(segmentName, masterName,swatch.swatch.data.id)} 
                                              className='pallet-select-tools'>
                                                <i class="bi bi-upload"></i>
                                              </div>
                                            </Tooltip> */}

                                            <Tooltip hasArrow label='Re-generate' bg='gray.300' color='black'>
                                              <div 
                                           onClick={()=>handleRegenerate(segmentName, masterName,swatch.swatch,segment)} 
                                              className='pallet-select-tools'>
                                               <i class="bi bi-arrow-repeat"></i>
                                              </div>
                                            </Tooltip>



                                           {/* { !swatch.isHide &&<Tooltip hasArrow label='Hide Pallet' bg='gray.300' color='black'>
                                            
                                            <div className='pallet-select-tools icon-active'
                                              onClick={()=> handleHidePallet(segmentName,swatch.swatch.data.id)}
                                              >
                                               
                                                <i class="bi bi-eye"></i>
                                              </div>
                                            </Tooltip>} */}
                                            {/* {swatch.isHide && <Tooltip  hasArrow label='Unhide Pallet' bg='gray.300' color='black' >
                                            <div className='pallet-select-tools'
                                                 
                                                 onClick={()=>handleUnHidePallet(segmentName,swatch.swatch.data.id)}
                                                  >
                                                     <i class="bi bi-eye-slash"></i>
                                              
                                                </div>

                                            </Tooltip>} */}
                                           

                                            <Tooltip hasArrow label='View Details' bg='gray.300' color='black'>
                                              <div className='pallet-select-tools'>
                                                <i class="bi bi-image"></i>
                                              </div>
                                            </Tooltip>

                                            <Tooltip hasArrow label='Remove Pallet' bg='gray.300' color='black'>
                                              <div 
                                              
                                              className='pallet-select-tools'
                                              onClick={()=>handleRemovePallet(masterName,segmentName,swatch.swatch.data.id)}
                                              >
                                                <i class="bi bi-x-lg"></i>
                                              </div>
                                            </Tooltip>

                                          </div>

                                          <div className="pallet-images-details">
                                            <h3>{segmentName}</h3>
                                            <h4>Brand : <span>Demo Brand Wall</span></h4>
                                            <h4>Label : <span>Demo Lable Wall</span></h4>
                                            <h4>Style : <span>Demo Wall Style </span></h4>
                                            <p>Model Size: 4'4" x 0'10" x 7'0"</p>
                                          </div>
                                        </div>

       {isDelete && (
        <DeleteSwatchAlert
          isOpen={isDelete}
          onClose={handleCloseAlertModal}
         deleteSwatch={handleDeletingSwatch}
        />
      )}
    </div>
  )
}

export default UniquePopHover
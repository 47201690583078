import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getEmail, loginSuccess } from "../login/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
const Customer = ({ emailid, role, resetLogin }) => {
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const login = useSelector(getCustomer);
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (emailid && isMounted.current &&token) {
      getCustomerDetail();
      return () => {
        isMounted.current = false;
      };
    }
  }, [emailid,token]);

  const getCustomerDetail = async () => {
    const authToken = "Bearer " + token;
    try {
      const response = await axios.get(
        `https://aiadmin.dzinly.org/content-manager/collection-types/api::${role}.${role}?page=1&pageSize=10&sort=name:ASC&_q=${emailid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );
      //console.log("customer details: ", response);
      // ;
      if (response.status === 200) {
        isMounted.current = true;
        // ;
        dispatch(
          loginSuccess({
            customerId: response?.data?.results[0]?.id,
            isAuthenticated: true,
            token: login.token,
            email: response?.data?.results[0]?.email,
            customerName: response?.data?.results[0]?.name,
            role: role,
            newUser:false
            
          })
        );
       // 

        let customerLogin = {
          customerId: response?.data?.results[0]?.id,
          isAuthenticated: true,
          token: login.token,
          email: response?.data?.results[0]?.email,
          customerName: response?.data?.results[0]?.name,
          role: role,
          userId:login.userId
        };
        sessionStorage.setItem("customerLogin", JSON.stringify(customerLogin));
        resetLogin();
      }
    } catch (e) {
      //console.log(e.message);
      isMounted.current = true;
      resetLogin();
    }
  };
  return <div></div>;
};

export default Customer;

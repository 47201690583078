import React, { useEffect } from "react";
import { GET_LABEL_SEGMENTS } from "../GraphQL/GraphQl";
import { useQuery } from "@apollo/client";
import { getCustomer } from "../login/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  addMasterArray,
  updateMasterSegment,
} from "../slices/MasterArraySlice";
import { addOverSwatch } from "../slices/OverAllSwatchSlice";
const Label_Segment = () => {
  const { loading, error, data } = useQuery(GET_LABEL_SEGMENTS);
  const getCustomerDetail = useSelector(getCustomer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (getCustomerDetail.isAuthenticated) {
      let segArray = [];
      let overSwatch = [];
      if (
        data &&
        data.segments &&
        data.segments.data &&
        data.segments.data.length > 0
      ) {
        data.segments.data.map((item) => {
          let allLabels = [];
          let icon;
          if (
            item.attributes &&
            item.attributes.labels &&
            item.attributes.labels.data &&
            item.attributes.labels.data.length > 0
          ) {
            item.attributes.labels.data.map((lab) => {
              allLabels.push({
                id: lab.id,
                name: lab.attributes.name,
                status: lab.attributes.status,
              });
            });
          }
          if (
            item.attributes &&
            item.attributes.icon &&
            item.attributes.icon.data &&
            item.attributes.icon.data.attributes &&
            item.attributes.icon.data.attributes.url
          
          ) {
            icon = item.attributes.icon.data.attributes.url;
          }

          segArray.push({
            id: item.id,
            name: item.attributes.name,
            labels: allLabels,
            icon: icon,
            segmentation: [],
          });
          overSwatch.push({
            name: item.attributes.name,
            allSwatch: [],
          });
        });
      }
      sessionStorage.setItem("masterArray", JSON.stringify(segArray));
      dispatch(addMasterArray(segArray));
      dispatch(addOverSwatch(overSwatch));
    }
  }, [getCustomerDetail, data]);
  return <></>;
};

export default Label_Segment;

import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Center,
} from "@chakra-ui/react";
import { Card, Container, Input, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import "../HomePage/CreateProject.css";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import UnzipFolder from "../UploadExistingProject/UnzipFolder";
import { addExistingProject } from "../slices/ExistingProject/ReverseResponse";
import CreateFolderAndProject from "../CreateProject/CreateFolder";
import UploadImage from "./UploadImage";
const UploadExistingProject = ({ isOpen, onClose }) => {
  const [projectName, setProjectName] = useState();
  const [openDiv, setOpenDiv] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = React.createRef();
  const toast = useToast();
  const dispatch = useDispatch();
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  //handle upload file
  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    setOpenDiv(false);
    // setIsLoading(true);
    setImageFile(file);
    // handleOnFileChange(file);
  };
  // show document

  // handle Drag Image
  const handleDragImage = () => {
    fileInputRef.current.click();
  // setOpenDiv(false);
  };

  // handle submit
  const [isProject, setIsProject] = useState(false);
  const [imageId, setImageId] = useState(null);
  const handleProjectSubmit = async () => {
    const formData = new FormData();
    formData.append("files", imageFile);
    try {
      const responce = await axios.post(
        "https://api.dzinly.org/api/reverse",
        formData
      );
      // ;
      if (responce.status === 200) {
        setIsLoading(false);
        dispatch(
          addExistingProject({
            apiResponse: responce.data,
          })
        );
        setIsProject(true);
      }
      //(responce);
    } catch (err) {
    
      toast({
        title: err.message,
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "center-bottom",
      });
      setIsLoading(false);
    }
  };
  // close  modal
  const HandleClose = () => {
    onClose();
  };

  const [projectId, setProjectId] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const handleCloseCreateFolder = (projectid, folderid) => {
    setIsProject(false);
    setProjectId(projectid);
    setFolderId(folderid);
  };
  // reset image
  const HandleReset = () => {
    setOpenDiv(true);
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // after close image upload
  const handleResetFolderProjectId = () => {
    setProjectId(null);
    setFolderId(null);
    // move to image view page
  };
  return (
    <div>
      {/* create project and folder */}
      {isProject && (
        <CreateFolderAndProject resetData={handleCloseCreateFolder} />
      )}

      {/* upload Main Image */}
      {folderId != null && projectId != null && (
        <UploadImage
          folderId={folderId}
          imageId={imageId}
          resetprojectFolder={handleResetFolderProjectId}
        />
      )}
      <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent className="upload-project">
          <ModalHeader className="create-project-title">
            <Text textAlign={"center"}>Upload Existing Project</Text>
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <FormControl>
              <FormLabel>Project Name</FormLabel>
              <Input
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                borderRadius="md"
                borderColor="gray.400"
                _focus={{ borderColor: "blue.500" }}
              />
            </FormControl>
            <br />

            <FormControl className="upload-button">
              <FormLabel>Upload Folder</FormLabel>

              <Input
                className="create-project-input"
                type="file"
                onChange={handleUploadFile}
                borderRadius="md"
                borderColor="gray.400"
                style={{ display: "none" }}
                _focus={{ borderColor: "blue.500" }}
                ref={fileInputRef}
              />
            </FormControl>

            {openDiv && (
              <div className="upload-button-dable" onClick={handleDragImage}>
                <div className="upload-btn">               
                  <i class="bi bi-cloud-arrow-up"></i>
                  <p>
                    Click to upload or <br></br>Darg and Drop house image here
                  </p>
                </div>
              </div>
            )}
            

            {/* Display the Document if it exists */}
            {imageFile && <UnzipFolder imageFile={imageFile} />}

            <br />
            <ModalFooter className="create-project-button">
              <>
                {imageFile && (
                  <Button
                    // Change the color scheme based on the state
                    mr={3}
                    onClick={handleProjectSubmit}
                    disabled={!imageFile}
                  >
                    Submit
                  </Button>
                )}
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={HandleClose}
                  _hover={{ bg: "red.500" }}
                >
                  Close
                </Button>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={HandleReset}
                  _hover={{ bg: "red.500" }}
                >
                  Reset
                </Button>
              </>

              {/* {loadingProject && (
                  <CircularProgress isIndeterminate color="green.300" />
                )} */}
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UploadExistingProject;

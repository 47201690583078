import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { addSegementFolderData } from "../slices/FolderSlice";
const GetChildFolderId = ({ folderId, backToUploadSegment }) => {
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (folderId && isMounted.current &&token) {
      getChilderFolderStructure(folderId);
      return () => {
        isMounted.current = false;
      };
    }
  }, [folderId,token]);
  // check the child folder to check the user and designer
  const getChilderFolderStructure = async (id) => {
    const authToken = "Bearer " + token;
    try {
      const response = await axios.get(
        `https://aiadmin.dzinly.org/upload/folders/${id}?populate=*`,
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          }
        }
      );
      //console.log("child polpulate ", response);
      //console.log("child polpulate ", response?.data?.data?.children);
      if (response.status === 200) {
        isMounted.current = true;
        dispatch(
          addSegementFolderData({
            segmentFolder: response?.data?.data?.children,
          })
        );
        backToUploadSegment(response?.data?.data?.children);
      }
    } catch (e) {
      alert("Error to getting children structure " + e.message);
    }
  };

  return <div></div>;
};

export default GetChildFolderId;

import { createSlice } from "@reduxjs/toolkit";
const DeleteSlice= createSlice({
    name: "deleteSegment",
    initialState: {
       segmentName:null,
       isDeleted:false
      },
    reducers: {
      deleteDegment:(state,action)=>{
       state.segmentName=action.payload.segmentName;
       state.isDeleted=action.payload.isDeleted
      },
      remeoveDelete: (state,action)=>{
        state.isDeleted=false
        state.segmentName=null
      }
      
    },
})

export const {
    deleteDegment,remeoveDelete

  
} = DeleteSlice.actions;

export const getDeletSegment= (state, action) =>state.deleteSegment

export default DeleteSlice.reducer;


import { gql } from "@apollo/client";

export const GET_PROJECT_BY_ID = gql`
query GetProjectByID($project_id: ID!){
    projects(
       filters: { id: { eq: $project_id } }
    ){
      data{
        id
        attributes{
          name
        ImageBase64
          ProjectImageUrl
          jobs{
            data{
              id
              attributes{
                JobJson
                JobId
              }
            }
          }
        }
      }
    }
  }`
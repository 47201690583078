import React,{useState, useRef, useEffect} from 'react'
import "./UploadImage.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
FormControl,
Input,

} from '@chakra-ui/react'
import { fabric } from "fabric";
import { useDispatch, useSelector } from 'react-redux'
import {openModal} from "../../../slices/uploadSwatch/UploadSwatchImage.js"
import{getProjectId} from "../../../slices/ProjectSlice.js";
import {getCanvasSize} from "../../../slices/windowSizeSlice/WindowResolution.js"

import {palletLoading} from "../../../slices/AlertSlice.js"
const UploadImage = ({isOpen,searchPArenFolder, segName}) => {
  const fileInputRef = React.createRef();
  const canvasRef = useRef(null);
  const dispatch= useDispatch()
  const getCanvasSizes= useSelector(getCanvasSize)
  const [isCanvas, setIsCanvas]=useState(false)
  const [scaleX, setScalex]= useState(null);
    const [scaleY, setScaley]= useState(null);
    const [isStateImage, setIsStateImage]= useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null); // To store the data URL of the thumbnail

    useEffect(()=>{
      if(isOpen){
       // setSelectedFile(null)
        setIsStateImage(true)
        setThumbnailUrl(null)
      }
     
      
    },[isOpen])

    // create canvas 
  useEffect(() => {
      
    
        
        var canvas = new fabric.Canvas("upload-swatch-Image", {
          selectionBorderColor: 'green',
         
        });
        canvasRef.current = canvas; 
        const imgGroup = new fabric.Group([], { selectable: false, name: 'imageGroup',visible: true });
        canvasRef.current.add(imgGroup);
        //canvasRef.current = canvas; 
        setIsCanvas(true)

     
    

   }, []); 


    // Add Background image on canvas
    const getProjectImage=useSelector(getProjectId);
    useEffect(()=>{
    
      
        if(isCanvas &&
          getProjectImage&&
          getProjectImage.base64Image&&
          canvasRef.current ){
            
            let image = getProjectImage?.base64Image;
      const encodedImage = "data:image/png;base64," + image;
      
      const decodedImage = atob(encodedImage.split(",")[1]);
      
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }
      
      const blob = new Blob([uint8Array], { type: "image/png" });
      
      let imageUrl = URL.createObjectURL(blob);
  
      var img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {}
//  let imageUrl=getProjectImage?.projectImage
fabric.Image.fromURL(imageUrl, function (img) {
  
  canvasRef.current?.setBackgroundImage(imageUrl, canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{

    scaleX: canvasRef.current.width / img.width,
    scaleY: canvasRef.current.height / img.height,
    width:canvasRef.current.width,
    height:canvasRef.current.height,
    originX: "left",
    originY: "top",
    stroke: 'green', // Set border color
    strokeWidth: 2 
  
    });

    setScalex(canvasRef.current.width / img.width);
    setScaley(canvasRef.current.height / img.height);
    
  
  // Create a group for additional images
  
  canvasRef.current?.requestRenderAll();
    
})

          }
      
        
  
    },[canvasRef,getProjectImage,isCanvas]);


  const handleDragImage = () => {
    fileInputRef.current.click();
  // setOpenDiv(false);
  };
  

  const handleImage = async (event) => {
    const imageFile = event.target.files[0];
    setSelectedFile(imageFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target.result;
      setIsStateImage(false)
    };

    reader.onloadend = () => {
      // When the FileReader finishes reading, set the data URL as the thumbnail URL
      setThumbnailUrl(reader.result);
    };

    // Read the contents of the selected image file as a data URL
    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };


  // close modal 
  const handelCloseModal=()=>{
    dispatch(openModal({
      isUploadStart:false,
      isStartSearch:false,
      segmentName:segName
  }))
  }

const handleUploadImage=()=>{
  dispatch(openModal({
    isUploadStart:false,
      isStartSearch:true,
      segmentName:segName
}))

dispatch(palletLoading({
  palletLoading:true
}))
searchPArenFolder(selectedFile)
}

  return (
    <div>
        <Modal  closeOnOverlayClick={false} isOpen={isOpen} onClose={handelCloseModal}>
  <ModalOverlay />
  <ModalContent className="upload-img-section" >
    <ModalHeader className='upload-create-pro-title'> Upload Pallet Image 1</ModalHeader>
    <ModalCloseButton />
    <ModalBody pb={6}>
 

    <FormControl className="upload-button">
              {/* <FormLabel>Upload Image</FormLabel> */}

              <Input
                className="create-project-input"
                type="file"
                onChange={handleImage}
                borderRadius="md"
                borderColor="gray.400"
                style={{ display: "none" }}
                _focus={{ borderColor: "blue.500" }}
                ref={fileInputRef}
              />
            </FormControl>

             <div>
            { isStateImage && <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/after_b5904e1f17.jpg'></img>}
                  
              {/* <canvas  id="upload-swatch-Image" className="dzi-van" width="452" height="307"   ref={canvasRef}></canvas> */}
                    </div>

              <div className="upload-pallet-img" onClick={handleDragImage} >
                <div className="upload-icon">     
                <div className='upload-icons'>          
                  <i class="bi bi-cloud-arrow-up"></i>
                  </div>
                  <div className='combo-text'>
                  <p>
                    Click to upload or darg and drop pallet image  here

                  </p>
                  </div>
                </div>
              </div>


              {thumbnailUrl && (<div className='upload-thumbnail-image'>
                <img 
                  src={thumbnailUrl}
                  alt="Thumbnail"
                  style={{ maxWidth: "100%", marginTop: "10px" }}
                />
                </div>
              )}



    </ModalBody>

    <ModalFooter>
    <Button className='upload-image-button-delete' onClick={handelCloseModal}>Cancel</Button>
      <Button className='upload-image-button' colorScheme='blue' mr={3}
      onClick={handleUploadImage}
      >
        Save
      </Button>

    </ModalFooter>
  </ModalContent>
     </Modal>
      
    </div>
  )
}


export default UploadImage

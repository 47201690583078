import React from 'react'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ButtonGroup,
    FormControl,FormLabel,Input,Text
  } from "@chakra-ui/react";
  import { selectAllItems } from "../slices/MasterArraySlice";
  import { useDispatch, useSelector } from 'react-redux';

  import {getEditSegmentation} from "../slices/EditSegment/EditSegment"  // reAnnotate need label NAme , masterIndex
 import {StartReAnnotation} from "../slices/EditSegment/EditSegment"
  const ReAnnotationModal = ({isOpen, onClose}) => {

    const dispatch = useDispatch();
    const getMasterArray = useSelector(selectAllItems);

     const getIndexLabelName=   useSelector(getEditSegmentation)


     // close Modal
     const handleCloseModal=()=>{
        onClose()
     }

     const handleSubmit=()=>{
      // dispatch(StartReAnnotation({
      //   isStartAnnotation:true,
        
      // }))
      onClose()
        
     }
  return (
    <>
    <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "sm", md: "md" }}
      >
        <ModalOverlay className="label-header" />
        <ModalContent
          className="Segment-header"
          bg="white"
          borderRadius="md"
          boxShadow="md"
          p={{ base: "2", md: "4" }}
        >
          <ModalHeader textAlign="center">Add Segment</ModalHeader>
          <ModalCloseButton />

          {/* sagemnts popup start */}

          <div className="add-segements-tab-section">
            <div className="add-sagments">
              <div class="segement-title">
                <h2 class="segement-text segments-text" key="select"> Select Category</h2>
              </div>
              <div
                class="chakra-tabs__tablist tab-list-segemnt css-fgp5ep "
                role="tablist"
                aria-orientation="horizontal"
              >
                {getMasterArray &&
                  getMasterArray.master &&
                  getMasterArray.master.length > 0 &&
                  getMasterArray.master.map((item, index) => (
                    <button 
                      type="button"
                      aria-disabled="false"
                      id="tabs-:r1b:--tab-0"
                      role="tab"
                      tabindex="0"
                      aria-selected="true"
                      aria-controls="tabs-:r1b:--tabpanel-0"
                      key={item.id}
                    //  className={isActive ? "active-segment " : ""} 
                      data-index="0"
                      fdprocessedid="4625"
                    //  onClick={() => handleAddSegmentName(item.name)}
                    >
                      <div >
                        <div className="recomended-box" key={item.id}>
                          <div className="recomended-images">
                            <img src={item.icon} width="25" alt="Door"></img>
                          </div>
                          <div className="recomended-text">
                            <h3 key={item.id}>{item.name}</h3>
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
              </div>
            </div>

            <div className="from-labels-title-name">
              <FormControl className="labelname-title lable-form-box">
                <FormLabel className="labels-title-tab">
                  <h2>Label Name</h2>
                </FormLabel>
                <Input
                  type="text"
                  value={getIndexLabelName?.labelName}
                  //    onChange={handleInputChange}

                  readOnly
                  borderColor="gray.400"
                  _focus={{ borderColor: "blue.500" }}
                />
              </FormControl>
            </div>

            <ModalFooter
              className="button-label-segment button-lables"
              flexDirection={{ base: "column", md: "row" }} // Flex direction based on breakpoints
              justifyContent="center" // Center buttons horizontally
            >
              <Button
                mb={{ base: "2", md: "0" }} // Responsive margin-bottom based on breakpoints
                mr={{ base: "0", md: "3" }} // Responsive margin-right based on breakpoints
                colorScheme="green"
                _hover={{ bg: "green.200" }}
                flex="1"
               onClick={handleSubmit}
              >           
                Re-Annotate Segment
              </Button>
              <Button
                colorScheme="red"
                mr={3}
                _hover={{ bg: "red.200" }}
                flex="1"
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
          {/* end */}
        </ModalContent>
      </Modal>
     
    
    </>
   
  )
}
export default ReAnnotationModal
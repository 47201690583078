import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMasterJobs } from "../slices/MasterJobs";
import { useToast } from "@chakra-ui/react";
import { getProjectId } from "../slices/ProjectSlice";
import axios from "axios";
import{getAllShadows} from "../slices/shadow/ShadowSlice"
import {selectAllItems} from "../slices/MasterArraySlice"
const AddSegmentToDB = ({ resetSegmentData }) => {
  const getMasterJobs = useSelector(getAllMasterJobs);
  const getMasterArray= useSelector(selectAllItems)
  const isMounted = useRef(true);
  const toast = useToast();
  const getprojectDetails = useSelector(getProjectId);
  const getAllShadowss = useSelector(getAllShadows)
  useEffect(() => {
    if (
      getMasterJobs &&
      getMasterJobs.masterJob &&
      getMasterJobs.masterJob.length > 0 &&
      isMounted.current &&
      getMasterArray&&
      getMasterArray.master&&
      getMasterArray.master.length>0
      
    ) {
      //
      if(getAllShadowss&&
        getAllShadowss.allShadow&&
        getAllShadowss.allShadow.length>0){
          let allShadows=getAllShadowss.allShadow;
          addSegment(getMasterJobs.masterJob,getMasterArray.master,allShadows,);
        }else{
          addSegment(getMasterJobs.masterJob,getMasterArray.master);
        }
     
      return () => {
        isMounted.current = false;
      };
    }
  }, [getMasterJobs,getAllShadowss]);

  const addSegment = async (allArray,masterJson,shadows) => {
    let jobId = getprojectDetails.jobId;
 
    let data = {
      data: {
        Segmentation: allArray,
        Shadows:shadows,
        JobJson:masterJson,
      },
    };
    try {
      const responce = await axios.put(
        process.env.REACT_APP_API_STRAPI + "jobs/" + jobId,
        data
      );
      if (responce.status === 200) {
        toast({
          title: "Segment is added successfully",

          status: "success",
          duration: 5000,
          isClosable: true,
          position: "center-bottom",
        });

        isMounted.current = true;
        resetSegmentData();
      }
    } catch (e) {}
  };
  return <div></div>;
};

export default AddSegmentToDB;

import React from 'react';
import { useState, useEffect } from'react';
import RemoveNoiseModal from "./RemoveNoiseModal";

import axios from "axios";
import {getProjectId} from "../slices/ProjectSlice";
import {useDispatch, useSelector} from "react-redux"
const Noise=({noseOpen,resetNoise,newPolygonPoints})=>{
  
    const[isopenModal, setIsOpenModal]=useState(false);
    const[isAPI, setIsAPI]=useState(false);
    const getProjectImage= useSelector(getProjectId)
    useEffect(()=>{
        if(noseOpen){
            setIsOpenModal(true)
        }
            
    },[noseOpen])



    const handleCloseModal=()=>{
        setIsOpenModal(false)
        resetNoise()
    }


    const handleNoise=()=>{
        setIsOpenModal(false)
        callNoiseAPI()

    }


  const callNoiseAPI= async( )=>{
    if(newPolygonPoints&&getProjectImage){
        let seg=newPolygonPoints?.newPolygonPoints?.flat().map(value => Math.round(value));
        const data={
            org_image: getProjectImage?.base64Image,
                prompt: "Remove Masked Image",
                segment: seg

        }

        try{
            const response= await axios.post(
                "https://api.dzinly.org/api/gen-ai",
               data
                
            )
          
            if(response.status===200){
                
                //isMounted.current=true
            }
            //resetAPI()

        }catch( err){
         
            alert("Error", err.message)
           // resetAPI()
            
        }


    }
        

    }
    const handleCloseAPI=()=>{
        setIsAPI(false)
    }
    return (
        <>

        <RemoveNoiseModal
        isopenModal={isopenModal}
        noseClose={handleCloseModal}
        removeNoise={handleNoise}
        />

       {/* { isAPI &&<RemoveNoiseAPI
       newPolygonPoints={newPolygonPoints}
       resetAPI={handleCloseAPI}
    
        />} */}
        </>
    )
}

export default Noise;
import { createSlice } from "@reduxjs/toolkit";

const MasterArraySlice = createSlice({
  name: "masterArray",
  initialState: {
    master: [],
  },
  reducers: {
    addMasterArray: (state, action) => {
      state.master = [...action.payload];
    },
    addCustomerViewMasterArray: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.master = [...action.payload];
      } else {
        // Handle the case when action.payload is not an array
        console.error("Payload is not iterable:", action.payload);
      }
    },
   
    removeMasterArray: (state,action) => {
      state.master.forEach(item=>{
        item.segmentation=[]
      })
    },

    // Add a new action for selecting an item by ID
    addNestedObjectToSegment: (state, action) => {
      const {
        segment,
        label,
        flattenedPoints,
        BoundingBoxInt,
        BoundingBoxFloat,
        area_pixel,
      } = action.payload;
  //
      const segments = state.master.find((item) => item.name === segment);

      if (segments) {
        if (segments.segmentation.length>0) {
         // segments.segmentation = []; // Initialize the array if it doesn't exist
          const existingObjectIndex = segments.segmentation.findIndex(
            (obj) => obj.name === label
          );
          if (existingObjectIndex !== -1) {
        
            segments.segmentation[existingObjectIndex].annotationPolygon = flattenedPoints;
            segments.segmentation[existingObjectIndex].area_pixel = area_pixel;
            segments.segmentation[existingObjectIndex].BoundingBoxFloat = BoundingBoxFloat;
            segments.segmentation[existingObjectIndex].BoundingBoxInt = BoundingBoxInt;
            if(segments.segmentation[existingObjectIndex].SelectSwatchJob.length>0){
              segments.segmentation[existingObjectIndex].SelectSwatchJob.map(item=>{
                item.selected=false
             })
            }
           
          } else{
            segments.segmentation.push({
              name: label,
              annotationPolygon: flattenedPoints,
              area_pixel: area_pixel,
              confidence: null,
              BoundingBoxFloat: BoundingBoxFloat,
              BoundingBoxInt: BoundingBoxInt,
              SelectSwatchJob: [],
            });

          }
        
        }
        else{
          segments.segmentation.push({
            name: label,
            annotationPolygon: flattenedPoints,
            area_pixel: area_pixel,
            confidence: null,
            BoundingBoxFloat: BoundingBoxFloat,
            BoundingBoxInt: BoundingBoxInt,
            SelectSwatchJob: [],
          });

        }
      }
    },
    removeSegmentFromMAsterArray: (state, action) => {
      const { segment, label } = action.payload;
 
      const segmentIndex = state.master.findIndex(
        (item) => item.name === segment
      );

      if (segmentIndex !== -1) {
        const segmentation = state.master[segmentIndex].segmentation;

        if (segmentation) {
          const labelIndex = segmentation.findIndex(
            (item) => item.name === label
          );

          if (labelIndex !== -1) {
            segmentation.splice(labelIndex, 1);
          }
        }
      }
    },
    addAllSwatchs: (state, action) => {
      const { labelName, allSwatch } = action.payload;
      const segment = state.master.find((item) => item.name === labelName);
      if (segment) {
        segment.allSwatches = allSwatch; // Update the allSwatches property
      }
    },
    removeSwatchFromMasterArray: (state, action) => {
      const {masterName,segName, swatchId } = action.payload;
      
      const labelIndex = state.master.findIndex(
        (item) => item.name === masterName
      );
      if (labelIndex !== -1) {
        const seg = state.master[labelIndex].segmentation;
        const segIndex = seg.findIndex((item) => item.name === segName);
        if (segIndex !== -1) {
          const swatchs = seg[segIndex].SelectSwatchJob;
          const swatchIndex = swatchs.findIndex(
            (item) => item.swatch.data.id === swatchId
          );
          if (swatchIndex !== -1) {
            state.master[labelIndex].segmentation[
              segIndex
            ].SelectSwatchJob.splice(swatchIndex, 1);
          }
        }
      }
    },

    updateMasterPolygonAnnotation: (state, action) => {
      //;
      const { labelName, boundingBoxFloat, boundingBoxInt, annotationPolygon } = action.payload;
       
      // Add null check for labelName
      if (labelName) {
        const segName = labelName.replace(/\d+$/, "");
    
        const labelIndex = state.master.findIndex((item) => item.name === segName);
    
        if (labelIndex !== -1) {
          const seg = state.master[labelIndex].segmentation;
          const segIndex = seg.findIndex((item) => item.name === labelName);
    
          if (segIndex !== -1) {
            state.master[labelIndex].segmentation[segIndex].annotationPolygon = annotationPolygon;
            if(boundingBoxInt &&boundingBoxFloat){
              state.master[labelIndex].segmentation[segIndex].BoundingBoxFloat = boundingBoxFloat;
              state.master[labelIndex].segmentation[segIndex].BoundingBoxInt = boundingBoxInt;
            }

          }
        }
      }
    },

    updateSwatchMasterArray: (state, action) => {
       
      const { labelName, segmentName, swatchId, palletImage, swatchImage, brand, label,palletStyle,palletName } = action.payload;
  
      const labelIndex = state.master.findIndex(item => item.name === labelName);
     debugger
      if (labelIndex !== -1) {
          const seg = state.master[labelIndex].segmentation;
            const overAllSwatch=state.master[labelIndex].allSwatches
          if (swatchImage && swatchImage.length > 0) {
              swatchImage.forEach(segment => {
                  const segIndex = seg.findIndex(item => item.name === segment.class_name);
                  
                  if (segIndex !== -1) {
                      const swatchs = seg[segIndex].SelectSwatchJob;
                      // check the swatch on selectedSwatchJob
                      const existingswat= swatchs.findIndex(item=>item.swatch.data.id===swatchId.toString())
                      if(existingswat===-1){
                        swatchs.push({
                          swatch: {
                              data: {
                                  id: swatchId.toString(),
                                  attributes: {
                                      name: "hello",
                                      image_s3: palletImage
                                  }
                              }
                          },
                          palletImage:palletImage,
                          SegmentImagePath: segment.output,
                          selected: true,
                          isHide: false,
                          name: seg[segIndex].name
                      });
                      }
                   

                    // make false other slectedSswatchJob

                    swatchs.map(swat=>{
                      if(swat.swatch.data.id!== swatchId.toString()){
                        swat.selected=false
                      }
                    })
                      // Check if swatchId already exists in the swatchs array
                      const existingSwatchIndex = overAllSwatch.findIndex(swatch => swatch.swatchId === swatchId.toString());
                     
                      if (overAllSwatch&&overAllSwatch.length==0){
                        overAllSwatch.push({
                          swatchId:swatchId.toString(),
                          palletName:palletName,
                          palletImage:palletImage,
                          brand:brand,
                          palletstyle:palletStyle,
                          SegmentImagePath: segment.output,
                          selected: true,
                          isHide: false,
                          name: seg[segIndex].name
                      });
                      } 
                      else  if(overAllSwatch && overAllSwatch.length>0){

                        if (existingSwatchIndex == -1) {
                  
                          if(overAllSwatch&&overAllSwatch.length>0){
                            overAllSwatch.map((swat)=>{
                               if(swat.name=== seg[segIndex].name ){
                            
                              swat.name =  seg[segIndex].name;
                              swat.selected=false;
                              
                               }

                            })
                         
                          } 
                          overAllSwatch.push({
                            swatchId:swatchId.toString(),
                            palletName:palletName,
                            palletImage:palletImage,
                            brand:brand,
                            palletstyle:palletStyle,
                            SegmentImagePath: segment.output,
                            selected: true,
                            isHide: false,
                            name: seg[segIndex].name
                        });
  
                         
                      } 
                   
                  
                      
                     
                  }
              }
          } )
    

        }
      }
    }

  ,
   
    updateHideMasterArray:(state,action)=>{
      const { labelName, segName, swatchId ,hideStatus} = action.payload;
      const labelIndex = state.master.findIndex(
        (item) => item.name === labelName
      );
      if (labelIndex !== -1) {
        const segmentation = state.master[labelIndex].segmentation;
        const segIndex = segmentation.findIndex((item) => item.name === segName);
    
        if (segIndex !== -1) {
          const swatchs = segmentation[segIndex].SelectSwatchJob;
           //
          // Set selected to false for all swatches in SelectSwatchJob
          swatchs.forEach((swatch) => {
            if(swatch.swatch.data.id===swatchId){
              swatch.isHide=hideStatus
            }
          });
        }
      }
    
    },
    updateSelectedMasterArray:(state,action)=>{
     
      const { labelName, segName, swatchId ,selected,overAllSelected} = action.payload;
      const labelIndex = state.master.findIndex(
        (item) => item.name === labelName
      );
      if (labelIndex !== -1) {
        const segmentation = state.master[labelIndex].segmentation;
        const segIndex = segmentation.findIndex((item) => item.name === segName);
    
        if (segIndex !== -1) {
          const swatchs = segmentation[segIndex].SelectSwatchJob;
          // 
          // Set selected to false for all swatches in SelectSwatchJob
          swatchs.forEach((swatch) => {
            if(swatch.swatch.data.id===swatchId){
              swatch.selected=selected
            }else{
              swatch.selected=false
            }
          });
          const overAllSwatch= state.master[labelIndex].allSwatches;
          if(overAllSwatch &&overAllSwatch.length>0){
            overAllSwatch.forEach((swatch) => {
              if(swatch.swatchId===swatchId){
                swatch.selected=overAllSelected
              }
            })

          } 
        }
      }
    
    },
    updateCustomerSelectedMasterArray:(state,action)=>{
       
      const { labelName, segName, swatchId ,selectedCustomer} = action.payload;
      const labelIndex = state.master.findIndex(
        (item) => item.name === labelName
      );
      if (labelIndex !== -1) {
        const segmentation = state.master[labelIndex].segmentation;
        const segIndex = segmentation.findIndex((item) => item.name === segName);
    
        if (segIndex !== -1) {
          const swatchs = segmentation[segIndex].SelectSwatchJob;
          const allSwatch=state.master[labelIndex].allSwatches
          // 
          // Set selected to false for all swatches in SelectSwatchJob
          if(selectedCustomer){
            swatchs.forEach((swatch) => {
              let swatch_id=swatch.swatch.data.id
              if(swatch_id===swatchId){
                swatch.selectedCustomer=selectedCustomer
              }else{
                swatch.selectedCustomer=false
              }
            });

            if(allSwatch.length>0){
              allSwatch.forEach(swatch=>{
                if(swatch.swatchId===swatchId){
                  swatch.customerSelected=selectedCustomer
                }else{
                  swatch.customerSelected=false
                }
              })
            }

          } else{
            // update swatch
            swatchs.forEach((swatch) => {
              let swatch_id=swatch.swatch.data.id
              if(swatch_id===swatchId){
                swatch.selectedCustomer=selectedCustomer
              }
            });
            //update overAll Swatch
            if(allSwatch.length>0){
              allSwatch.forEach(swatch=>{
                if(swatch.swatchId===swatchId){
                  swatch.customerSelected=selectedCustomer
                }else{
                  swatch.customerSelected=false
                }
              })
            }
          }
          
        }
      } 
    },
    updateUploadSwatchImage:(state,action)=>{
  
      const { labelName, segName, swatchId ,url} = action.payload;
       
      const labelIndex = state.master.findIndex(
        (item) => item.name === labelName
      );
      if (labelIndex !== -1) {
        const segmentation = state.master[labelIndex].segmentation;
        const segIndex = segmentation.findIndex((item) => item.name === segName);
    
        if (segIndex !== -1) {
          const swatchs = segmentation[segIndex].SelectSwatchJob;
       
          // Set selected to false for all swatches in SelectSwatchJob
          swatchs.forEach((swatch) => {
            
            if(swatch.swatch.data.id===swatchId){
              swatch.SegmentImagePath=url
              swatch.selected=true;
            }
          });
           const allSwatch = state.master[labelIndex].allSwatches;
           if(allSwatch.length>0){ 
            allSwatch.map(item=>{
              if(item.swatchId===swatchId){
                item.selected=true
              }
            })
           }
        }
      } 

    }, 
    updateSelectedSwatchOverAll:(state, action)=>{
      
      const { labelName, swatchId ,selected} = action.payload;
      const labelIndex = state.master.findIndex(
        (item) => item.name === labelName
      );
      if (labelIndex !== -1) {
        const segmentation = state.master[labelIndex].segmentation;
           segmentation.forEach(seg=>{
          let allSwatch= seg.SelectSwatchJob;
          if(allSwatch.length > 0) {
            allSwatch.forEach(swatch=>{
              if(swatch.swatch.data.id===swatchId){
                swatch.selected=selected
              }else{
                swatch.selected=false
              }
            })
          }
           })
           // allswatch make it selected true;
           const allSwatch=state.master[labelIndex].allSwatches
           if(allSwatch.length>0){
             allSwatch.forEach(swatch=>{
               if(swatch.swatchId===swatchId){
                 swatch.selected=selected
               }else{
                 swatch.selected=false
               }
             })
           }
    }
    
    
      
     
  },
    updateMasterSelectedSwatchOverAll:(state, action)=>{
      
      const { labelName, swatchId ,selected} = action.payload;
      const labelIndex = state.master.findIndex(
        (item) => item.name === labelName
      );
      
      if (labelIndex !== -1) {
        const segmentation = state.master[labelIndex].segmentation;
           segmentation.forEach(seg=>{
          let allSwatch= seg.SelectSwatchJob;
          if(allSwatch.length > 0) {
            allSwatch.forEach(swatch=>{
              if(swatch.swatch.data.id===swatchId){
                swatch.selectedCustomer=selected
              }else{
                swatch.selectedCustomer=false
              }
            })
          }
           })
           // allswatch make it selected true;
           const allSwatch=state.master[labelIndex].allSwatches
           if(allSwatch.length>0){
             allSwatch.forEach(swatch=>{
               if(swatch.swatchId===swatchId){
                 swatch.customerSelected=selected
               }else{
                 swatch.customerSelected=false
               }
             })
           }
    }
    
    
      
     
  },
  updateOverLapMasterPolygonAnnotation: (state, action) => {
    //;
    const { labelName, annotationPolygon } = action.payload;
  
    // Add null check for labelName
    if (labelName) {
      const segName = labelName.replace(/\d+$/, "");
  
      const labelIndex = state.master.findIndex((item) => item.name === segName);
  
      if (labelIndex !== -1) {
        const seg = state.master[labelIndex].segmentation;
        const segIndex = seg.findIndex((item) => item.name === labelName);
  
        if (segIndex !== -1) {
          state.master[labelIndex].segmentation[segIndex].annotationPolygon = annotationPolygon;
          
        }
      }
    }
  },
  }
});

export const {
  addMasterArray,
  addCustomerViewMasterArray,
  removeMasterArray,
  selectMasterItem,
  updateMasterSegment,
  addNestedObjectToSegment,
  removeSegmentFromMAsterArray,
  removeSwatchFromMasterArray,
  addAllSwatchs,
  updateMasterPolygonAnnotation,
  updateSwatchMasterArray,updateSelectedSwatch,
  updateHideMasterArray,
  updateSelectedMasterArray,updateCustomerSelectedMasterArray,
  updateUploadSwatchImage,
  updateSelectedSwatchOverAll,
  updateMasterSelectedSwatchOverAll,
  updateOverLapMasterPolygonAnnotation
} = MasterArraySlice.actions;
export const selectAllItems = (state) => state.masterArray;
export default MasterArraySlice.reducer;

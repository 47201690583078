
import { createSlice } from "@reduxjs/toolkit";

const WindowResolutionSlice=createSlice({
    name: "windowResolution",
    initialState: {

        canvasWidth: null,
        canvasHeight: null
    },
    reducers: {
        updateWindowResolution: (state, action) => {
            state.canvasWidth = action.payload.canvasWidth;
            state.canvasHeight = action.payload.canvasHeight;
        }
    }
})
export const {updateWindowResolution}=WindowResolutionSlice.actions
export const getCanvasSize =state => state.windowResolution
export default WindowResolutionSlice.reducer;
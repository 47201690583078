import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { addModelVersion } from "../slices/Model/ModelVersionSlices";
import { useDispatch } from "react-redux";
const ModelVersion = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getAllModelVersion();
  }, []);
  const getAllModelVersion = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_STRAPI + "models"
      );
      if (response.status === 200) {
       
        dispatch(
          addModelVersion({
            modelVersion: response?.data?.data,
          })
        );
      }
    } catch (err) {
      alert("Error on getting all model version", err.message);
    }
  };
  return <div></div>;
};

export default ModelVersion;

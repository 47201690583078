import React, { useState, useEffect } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Box,
} from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'


import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import homeicon from "../../../../assets/images/demo/home_images/image-layer.png";
import { useDispatch, useSelector } from "react-redux"
import "./EachSegment.css";
import Action from "./Actions.js";

import { addSelectedPallete } from "../../../slices/SelectedPalletdSlice";
import { addNewSelectedSwatch } from "../../../slices/SelectedSwatchImage";

import {
  removeAllPalletImages,
  addSelectedPalletImag,
  activeMouseLeave,getMouseActive
} from "../../../slices/TransparentPalletImgSlice";
import { addTabPannel } from "../../../slices/TabPannelSlice";
import ViewAction from "../ViewAction.js";
import { addViewPalletInfo } from "../../../slices/ViewPalletInfoSlice";

import { addEditSegment, reSetAnnotation } from "../../../slices/EditSegment/EditSegment";
import UploadImg from "../UploadDesignImage/UploadImage.js";
import CurrentSwatch from "../CurrentSwatch";
import {
  onHover,
  onAllHover,
  deleteSegment,
  samLoading,
  deleteLoading,
} from "../../../slices/AlertSlice";
import { addCurrentPallete,updateSelectedCurrentPallate } from "../../../slices/CurrentSelectedPalleteSlice"
import { getIsMouseClick } from "../../../slices/MouseEvent/MouseEventSlice.js"
import { compareProject, swatchDeleting } from "../../../slices/AlertSlice";

import { selectAllSegments } from "../../../slices/AllSegmentSlice.js"

import {
  addGridMultiSelect,
  updateGridMultiSelect,
} from "../../../slices/gridView/MultiSelectionSegement";

  import {comboActive} from "../../../slices/combinationSwatch/CombinationSwatchSlice.js"
  import {getCurrentablePalettes} from "../../../slices/CurrentSelectedPalleteSlice.js"
  import DummySwatch from "./DummySwatch.js"
  import PopHover from "./PopHover.js"
  import {updateSelectedMasterArray} from "../../../slices/MasterArraySlice.js"
  import Shimmer from "../Shimmer.js"
  import {deleteDegment} from "../../../slices/DeleteSlice/DeleteSlice.js"
  
  import {selectPopeHover,removePopHover} from "../../../slices/popHover/PopHoverSlice.js"
  
  import UniqueSwatches from './UniqueSwatches.js';
  import {getSelectAll,selectAllcheckBox} from "../../../slices/gridView/MultiSelectionSegement.js"
  const EachSegment = ({
    uniqueSwatch,
    masterSeg,
    masterName,
    masterIndex,
    openDeleteModal,
    openSelectSwatch,
    openViewPallet,
    noViewPallet,
    callMainCheckbox,
    moveLeftReannotation,
    selectAll,
    selectAllMasterName
  }) => {
  
     const dispatch= useDispatch()
   
     const[isViewSwatchActive, setIsViewSwatchActive]= useState(false)
     const[isReAnnotationActive, setIsReAnnotationActive]= useState(false)
     const[isEditActive, setIsEditActive]= useState(false)
 
    const[labelName, setLabelName] = useState();
    const[selectedSegName, setSelectedSegName] =useState()
    const [isProjectDetails, setIsProjectDetails] = useState(false);
    const[isSelectMainTab, setIsSelectMainTab] = useState(true);  
    const [isSelectSwatchTab, setIsSelectSwatchTab] = useState(false);
    const [indexs, setIndexs] = useState();
    const [isViewPallet, setIsViewPallet] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState();

  const [checkedItems, setCheckedItems] = useState([])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked
  const getAllCurrentPallet = useSelector(getCurrentablePalettes)

  // console.log("getAllCurrentPallet",getAllCurrentPallet)
  // checkbox  value as soon segment load

  useEffect(() => {
    setCheckedItems([])
  }, [])

 // after selecting swatch make check box false
  const getSelectAlls= useSelector(getSelectAll)

  useEffect(() => {
    
    if (masterSeg && masterSeg.length > 0 ) {
      setCheckedItems(() => {
        const initialCheckedItems = masterSeg.map(() => false);
        return initialCheckedItems;
      });
    } else if(getSelectAlls){
      setCheckedItems(() => {
        const initialCheckedItems = masterSeg.map(() => false);
        return initialCheckedItems;
      });

      dispatch(selectAllcheckBox({
        selectAll:false
      }))
    }
  }, [masterSeg,masterName,getSelectAlls]);


// select all after click on check box
useEffect(() => {
  if(selectAll &&masterSeg && masterSeg.length > 0 &&selectAllMasterName===masterName){
    setCheckedItems(() => {
      const initialCheckedItems = masterSeg.map(() => true);
      return initialCheckedItems;
    });
  
  }
},[selectAll,masterSeg,selectAllMasterName,masterName])
  //console.log("checkedItems",checkedItems)


 

  
 
  // open modal to select swatch

  const handleSelectSwatch1 = (labelName, index, selectedsegement, segName) => {
      
    let makeSegArray = [selectedsegement]
    setLabelName(segName)  // roof
    setSelectedSegName(labelName) //Rooof1
    handleSelectSwatch(labelName, index, makeSegArray, segName, selectedsegement)

    dispatch(
      addEditSegment({
        editSegment: makeSegArray,
        labelName:labelName
      })
    );
  }

  const handleSelectSwatch = (labelName, index, selectedsegement, segName, originalSegData) => {
  
    setIsProjectDetails(true);

    dispatch(addSelectedPallete({ selectedPallete: selectedsegement }));


    dispatch(
      addNewSelectedSwatch({
        newSelectedSwatch: selectedsegement,
      })
    );
    //  setIsSelectMainTab(false)
    //  setIsSelectSwatchTab(true)
    // setIsOpen(true);
    setIndexs(index);
    // console.log("gridPAge");
    dispatch(removeAllPalletImages());
    dispatch(
      addSelectedPalletImag({
        segmentName: labelName,
        selectedPlaletUrl: null,
        s3BucketImage: null,
        SwatchId: null,
      
      })
    );
    dispatch(
      addTabPannel({
        pannelTab: "Tab5",
      })
    );
    openSelectSwatch(labelName, segName, originalSegData)
  };

  //view Pallete{
  // handle view Pallete
  const handleViewPallete = (segName, segSwatches) => {
    if (segSwatches.length > 0) {
      dispatch(
        addViewPalletInfo({
          palleteInfo: segSwatches,
          segName: segName,
        })
      );

      openViewPallet()
    } else {
      noViewPallet()

    }
  };

  // close view pallete info


  //delete segemntation (open delete Alert)
  const handleDeletSegment = (labelName, segName) => {
    dispatch(deleteDegment({
      segmentName:labelName,
      isDeleted:true
    }))
    openDeleteModal(labelName, segName)
  };



  // edit segmentation
  const handleEditSegmentation = (segName, segment) => {
    //;
    dispatch(
      addEditSegment({
        editSegment: segment,
      })
    );
  };



  // on hover each segmentation
  const handleOnHoverSegment = (segmentName, polygon) => {

    dispatch(
      onHover({
        ishover: true,
        segmentName: segmentName,
        polygon: polygon,
      })
    );
  };

  // if selected palleted is clicked then mouse leave fetaure will deactive 
const[isActiveMouseLeave, setIsActiveMouseLeave]= useState(true)
  // close hover on each segmentation
  const handleCloseHoverSegment = () => {
   if(isActiveMouseLeave){
    dispatch(onHover({ ishover: false, segmentName: null }));
   }
   
  };

     // display pallet Image on main View
     const handleImageSelectedPallet = (
      transplateImage,
      s3BucketImage,
      segmentName,
      swatchId, 
      swatchSelected
    ) => {
    // 

    
      // deaactive Mouse leave Event
      dispatch(activeMouseLeave({
        isActiveMouseLeave:true
      }))
      // setIsActiveMouseLeave(false)
       dispatch(comboActive({
        IscomboActive:true
       }))
      
       //if false then add on canvas

       if(!swatchSelected){

        dispatch(updateSelectedCurrentPallate({
          segmentName: segmentName,
          s3BucketImage: s3BucketImage,
           swatchId: swatchId,
           palletImage:transplateImage
    }))
    dispatch(updateSelectedMasterArray({
      labelName:masterName,
      segName:segmentName,
      swatchId:swatchId,
      selected:true,
      isHide:false,
      overAllSelected:true
    })) 
    
    
    //if true
       } else{
       dispatch(selectPopeHover({
        segmentName:segmentName,
        ishide:true,
        swatchId:parseInt(swatchId)

       }))
       dispatch(updateSelectedMasterArray({
        labelName:masterName,
        segName:segmentName,
        swatchId:swatchId,
        selected:false,
        overAllSelected:false
        
      }))

      dispatch(updateSelectedCurrentPallate({
        segmentName: segmentName,
        s3BucketImage: null,
         swatchId: null,
         palletImage:null
  }))


       }
     
  
      dispatch(
        compareProject({
          isCompare: true,
        })
      );
    };

    // active/ deactive  mouse leave 
    const getMouseActives=useSelector(getMouseActive)
    useEffect(()=>{
      if(getMouseActives){
        setIsActiveMouseLeave(false)
      }else
       {
setIsActiveMouseLeave(true)
        }
    },[getMouseActives])


  const handleCheckboxChange = (val, index, seg, segName) => {

  //
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = [...prevCheckedItems];
      newCheckedItems[index] = val;
      return newCheckedItems;
    });

    if (val) {
      dispatch(addGridMultiSelect({
        gridMultiSelect: seg,
        labelName: masterName
      }));
    } else {
      dispatch(updateGridMultiSelect({ segName: seg.name }));
    }

  }

  // after selecting to check box
  useEffect(()=>{
    // console.log("checkedItems",checkedItems)
    // console.log("isIndeterminate",isIndeterminate)
    callMainCheckbox(checkedItems,isIndeterminate)
  },[checkedItems,isIndeterminate])
  
  const [uploadImage, setUploadimage] = useState(false);
  const  handleuploadimage = () => {
    setUploadimage(true);
  }
const handleCloseModel = () => {
  setUploadimage(false);
};


// handle reaAnnotation
const handleReAnnotation=(segName, masterName)=>{
     moveLeftReannotation(segName, masterName)
}



  return (
    <div>

          {/* unique Swatches */}
        <UniqueSwatches
        uniqueSwatch={uniqueSwatch}
        masterName={masterName}
        masterSeg={masterSeg}
      

        />

      {masterSeg &&
        masterSeg.length > 0 &&
        masterSeg.map((seg, index) =>
        (
             


               
          <div
          key={index} // // active class ="active-section"
            className={`seg-tabpanel   dzi-${seg.name} `}
          onMouseLeave={handleCloseHoverSegment}
            onMouseEnter={() =>
              handleOnHoverSegment(
                seg.name,
                seg.annotationPolygon
              )
            }

          >






            <Accordion allowMultiple>
              <AccordionItem className='accordion-seg'>

                <AccordionButton className='select-seg-dropdown'>
                  <div className='project-seg-box'>

                    <Checkbox
                      key={seg.id}
                      isChecked={checkedItems[index] || false}

                      onChange={(e) => {
                        handleCheckboxChange(e.target.checked, index, seg, seg.name);
                      }}
                    >
                    </Checkbox>
                    <span className='segements-title'

                      key={seg.name}


                    >{seg.name}</span>
                    <AccordionIcon className="dropdown-icon" />
                  </div>

                  <Action
                    key={seg.name}
                    segName={seg.name}
                    selectSwatch={() =>
                      handleSelectSwatch1(seg.name, masterIndex, seg, masterName)
                    }
                    searchViewPallete={() =>
                      handleViewPallete(seg.name, seg.SelectSwatchJob)
                    }
                    deleteSegments={() =>
                      handleDeletSegment(seg.name, masterName)
                    }
                    editSegmentation={() =>
                      handleEditSegmentation(seg.name, seg)
                    }
                    reAnnotate={()=>
                      handleReAnnotation(seg.name, masterName)}
                  />

                                
                                    </AccordionButton>
                            
                                  <AccordionPanel pb={4}>
                                     
                            <div className="project-swatch-select-boxs"
                            
                            >
                                <div className="swatch-images"
                                key={seg.id}>
                                
                                {seg.SelectSwatchJob &&
                              seg.SelectSwatchJob.length > 0
                                ? seg.SelectSwatchJob.map((swatch) => {
                                 
                                 
                                  return (
                                        <div className="select-pallet-image">
                                        <div className="pallet-img"
                                        key={swatch.id}
                                        > 
                                       
                                        <img
                                        src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch.swatch?.data?.attributes.image_s3}`}
                                        width={55}
                                        alt={
                                          swatch.swatch?.data?.attributes.name
                                        }
                                        onClick={() =>
                                          handleImageSelectedPallet(
                                            swatch?.SegmentImagePath||"",
                                            swatch?.swatch?.data?.attributes
                                              ?.image_s3,
                                            seg?.name,
                                            swatch?.swatch?.data?.id,
                                            swatch.selected
                                          )
                                         }
                                      />

                                     
                                                 { swatch.selected && 
                                                     <div className="pallet-img-checkbox"
                                                     onClick={() =>
                                                      handleImageSelectedPallet(
                                                        swatch?.SegmentImagePath||"",
                                                        swatch?.swatch?.data?.attributes
                                                          ?.image_s3,
                                                        seg?.name,
                                                        swatch?.swatch?.data?.id,
                                                        swatch.selected
                                                      )
                                                     }
                                                     >
                                                      
                                                        <img className='check-box-img' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Group_3_1_8943bd053e.png"></img>
                                                    </div>}
               
                                        
                                           {/* <div className='pallet-select-checkbox'>
                                                    <i className="bi bi-check-circle-fill"></i>
                                                            </div> */}

                                                            {}
                                        </div>
                                        <div className="pallet-action">

                          
                                <Popover trigger="hover">
                                  <PopoverTrigger>
                                    <div className="pallet-information-icon">
                                      <i class="bi bi-info">  </i>
                                    </div>
                                  </PopoverTrigger>
                                  <Portal>
                                    <PopoverContent>

                                      <PopoverBody>
                                        <PopHover
                                        swatch={swatch}
                                        segment={seg}
                                        segmentName={seg.name}
                                        uploadImage={handleuploadimage}
                                        masterName={masterName}
                                        
                                        />
                                     
                                      </PopoverBody>

                                    </PopoverContent>
                                  </Portal>
                                </Popover>




                                {/* <div className="pallet-fav">
                                                <i class="bi bi-star"></i>
                                            </div> */}
                                        </div>
                                    </div>
                                    )
                                }
                                    
                                  )
                                : 
                                ( 
                                <DummySwatch/>
                                )
                                  }
                                    


                    </div>



                    <div>
                      <UploadImg 
                      isOpen={uploadImage}
                      onClose={handleCloseModel}
                      />
                    </div>



                  </div>

                                  </AccordionPanel>
                                </AccordionItem>
                                </Accordion>


                         
                  

                        </div>
                      
                       
                         ))}
    </div>
  )
}

export default EachSegment
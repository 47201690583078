import { createSlice } from "@reduxjs/toolkit";

const DesignerJobListSlice = createSlice({
  name: "designerJob",
  initialState: {
    designerJob: [],
  },
  reducers: {
    addDesignerJob: (state, action) => {
      state.designerJob = action.payload.designerJob;
    },
    addnewDesignerJob: (state, action) => {},
    removeDesignerJobs: (state, action) => {
      state.designerJob = [];
    },
  },
});
export const { addDesignerJob, addnewDesignerJob, removeDesignerJobs } =
  DesignerJobListSlice.actions;
export const getDesignerJob = (state, action) => state.designerJob;
export default DesignerJobListSlice.reducer;

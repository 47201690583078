import React, { useState, useEffect } from "react";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedPalletImag } from "../slices/TransparentPalletImgSlice";
import { compareProject, swatchDeleting } from "../slices/AlertSlice";
import "./ShowSwatch.css";

import { CloseButton } from "@chakra-ui/react";
import DeleteSwatchAlert from "../Tabs/DeleteSwatch/DeleteSwatchAlert";

import { deleteSwatchMasterJob } from "../slices/MasterJobs";

import { removeSwatchFromSegments } from "../slices/AllSegmentSlice";

import { removeSwatchFromMasterArray } from "../slices/MasterArraySlice";
import { getProjectId } from "../slices/ProjectSlice";

import DeleteSwatch from "../Tabs/DeleteSwatch/DeleteSwatch";
import { updateCurrenPallate } from "../slices/CurrentSelectedPalleteSlice";
const ShowSwatch = ({ isSelectedEdit }) => {
  const jobData = useSelector(selectAllSegments);
  const dispatch = useDispatch();

  const getProjectDetails = useSelector(getProjectId);
  const [jobId, setJobId] = useState(null);
  useEffect(() => {
    if (isSelectedEdit) {
    }
  }, [isSelectedEdit]);
  // display pallet Image on main View
  const handleImageSelectedPallet = (
    transplateImage,
    s3BucketImage,
    segmentName,
    swatchId
  ) => {
    dispatch(
      addSelectedPalletImag({
        segmentName: segmentName,
        selectedPlaletUrl: transplateImage,
        s3BucketImage: s3BucketImage,
        SwatchId: swatchId,
      })
    );

    dispatch(
      compareProject({
        isCompare: true,
      })
    );
  };

  // handle delete modal dialog to ask yes or no
  const [isDelete, setISDelete] = useState(false);
  const [labName, setLabName] = useState();
  const [swatch_Id, setSwatch_Id] = useState();
  const handleDeleteSwatch = (segName, swatch_id) => {
    setLabName(segName);
    setSwatch_Id(swatch_id);
    setISDelete(true);
  };
  

  //close alert modal dialog
  const handleCloseAlertModal = () => {
    setISDelete(false);
  };

  // if yes then delete the swatch
  const handleDeletingSwatch = () => {
    dispatch(
      swatchDeleting({
        isSwatchDeleting: true,
      })
    );
    setISDelete(false);
    // remove swatch from master job list
    dispatch(
      deleteSwatchMasterJob({
        labelName: labName,
        swatchId: parseInt(swatch_Id),
      })
    );
    // remove data from allSegment Array list
    dispatch(
      removeSwatchFromSegments({
        labelName: labName,
        swatchId: swatch_Id,
      })
    );

    // remove from MasterArray list
    dispatch(
      removeSwatchFromMasterArray({
        labelName: labName,
        swatchId: swatch_Id,
      })
    );

    // remove from current Pallte Image if Swatch Id is match
    dispatch(
      updateCurrenPallate({
        labelName: labName,
        swatchId: swatch_Id,
      })
    );
    if (getProjectDetails && getProjectDetails.jobId)
      setJobId(parseInt(getProjectDetails.jobId));
  };

  // reset job Id
  const handleResetJobId = () => {
    setJobId(null);
  };
  return (
    <>
      {isDelete && (
        <DeleteSwatchAlert
          isOpen={isDelete}
          onClose={handleCloseAlertModal}
          deleteSwatch={handleDeletingSwatch}
        />
      )}
      {jobId != null && (
        <DeleteSwatch jobId={jobId} resetData={handleResetJobId} />
      )}
      <div className="segmentsarray-pallet-images">
        {jobData &&
          jobData.allSegmentsArray.length > 0 &&
          jobData.allSegmentsArray.map((item, index) =>
            item.name == isSelectedEdit && item.SelectSwatchJob.length > 0 // Use a ternary operator for conditional rendering
              ? item.SelectSwatchJob.map((img, index) => (
                  <div
                    className="pallet-styles select-pallet-style"
                    key={index}
                  >
                    <button
                      type="button"
                      class="chakra-button show-button css-16l57cj"
                      fdprocessedid="7xccf"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        focusable="false"
                        class="chakra-icon css-onkibi"
                        aria-hidden="true"
                      >
                        <g fill="currentColor">
                          <path d="M23.2,10.549a20.954,20.954,0,0,0-4.3-3.6l4-3.995a1,1,0,1,0-1.414-1.414l-.018.018a.737.737,0,0,1-.173.291l-19.5,19.5c-.008.007-.018.009-.026.017a1,1,0,0,0,1.631,1.088l4.146-4.146a11.26,11.26,0,0,0,4.31.939h.3c4.256,0,8.489-2.984,11.051-5.8A2.171,2.171,0,0,0,23.2,10.549ZM16.313,13.27a4.581,4.581,0,0,1-3,3.028,4.3,4.3,0,0,1-3.1-.19.253.253,0,0,1-.068-.407l5.56-5.559a.252.252,0,0,1,.407.067A4.3,4.3,0,0,1,16.313,13.27Z"></path>
                          <path d="M7.615,13.4a.244.244,0,0,0,.061-.24A4.315,4.315,0,0,1,7.5,12,4.5,4.5,0,0,1,12,7.5a4.276,4.276,0,0,1,1.16.173.244.244,0,0,0,.24-.062l1.941-1.942a.254.254,0,0,0-.1-.421A10.413,10.413,0,0,0,12,4.75C7.7,4.692,3.4,7.7.813,10.549a2.15,2.15,0,0,0-.007,2.9,21.209,21.209,0,0,0,3.438,3.03.256.256,0,0,0,.326-.029Z"></path>
                        </g>
                      </svg>
                    </button>

                    <img
                      src={
                        process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                        (img.swatch?.data?.attributes?.image_s3 || "")
                      }
                      width={55}
                      alt={item?.swatch?.data?.attributes?.name || ""}
                      key={index}
                      onClick={() =>
                        handleImageSelectedPallet(
                          img?.SegmentImage?.data?.attributes?.url || "",
                          img?.swatch?.data?.attributes?.image_s3,
                          item?.name,
                          img?.swatch?.data?.id
                        )
                      }
                    />
                    <CloseButton
                      className="close-btn"
                      onClick={() =>
                        handleDeleteSwatch(isSelectedEdit, img.swatch?.data?.id)
                      }
                    />

                    {/* <div>
                <i
                  className="bi bi-info-circle"
                  onClick={() =>
                    openModal(
                      item?.swatch,
                      item?.swatch?.data?.attributes?.image_s3
                    )
                  }
                ></i>
              </div> */}
                  </div>
                ))
              : null
          )}
      </div>
    </>
  );
};

export default ShowSwatch;

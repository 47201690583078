import { createSlice } from "@reduxjs/toolkit";

const AllSegmentArraySlice = createSlice({
  name: "allSegmentsArray",
  initialState: {
    allSegmentsArray: [],
  },
  reducers: {
    addSegment: (state, action) => {
      state.allSegmentsArray = [...action.payload];
    },

    removeSegment: (state) => {
      state.allSegmentsArray = [];
    },

    removeAllSegment: (state) => {
      state.allSegmentsArray = [];
    },
    //Add a new action for updating a segment by ID

    addNestedSegemnt: (state, action) => {
      const {
        label,
        flattenedPoints,
        BoundingBoxInt,
        BoundingBoxFloat,
        area_pixel,
      } = action.payload;

      const existingSegmentIndex = state.allSegmentsArray.findIndex(
        (item) => item.name === label
      );
      if (existingSegmentIndex !== -1) {
        // Update existing segment
        state.allSegmentsArray[existingSegmentIndex].annotationPolygon = flattenedPoints;
        state.allSegmentsArray[existingSegmentIndex].area_pixel = area_pixel;
        state.allSegmentsArray[existingSegmentIndex].BoundingBoxFloat = BoundingBoxFloat;
        state.allSegmentsArray[existingSegmentIndex].BoundingBoxInt = BoundingBoxInt;
      }else{
        const newSegment = {
          name: label,
          annotationPolygon: flattenedPoints,
          area_pixel: area_pixel,
          confidence: null,
          BoundingBoxFloat: BoundingBoxFloat,
          BoundingBoxInt: BoundingBoxInt,
          SelectSwatchJob: [],
        };
    
        state.allSegmentsArray.push(newSegment);

      }
      
    },
    removeSegmentFromSegments: (state, action) => {
      const { label } = action.payload;
      const segmentationIndex = state.allSegmentsArray.findIndex(
        (item) => item.name === label
      );

      if (segmentationIndex !== -1) {
        state.allSegmentsArray.splice(segmentationIndex, 1);
      }
    },
    removeSwatchFromSegments: (state, action) => {
      const { labelName, swatchId } = action.payload;

      const jobIndex = state.allSegmentsArray.findIndex(
        (item) => item.name === labelName
      );
      // ("jobIndex: " + jobIndex);
      if (jobIndex !== -1) {
        const seg = state.allSegmentsArray[jobIndex].SelectSwatchJob;
        const swatchIndex = seg.findIndex(
          (item) => item?.swatch?.data?.id === swatchId
        );
        // ("swatchIndex", swatchIndex);
        if (swatchIndex !== -1) {
          state.allSegmentsArray[jobIndex].SelectSwatchJob.splice(
            swatchIndex,
            1
          );
        }
      }
    },
    updatePolygonAnnotation:(state,action)=>{
      //
      const {labelName,boundingBoxFloat, boundingBoxInt,annotationPolygon} = action.payload;
      
      
      const jobIndex = state.allSegmentsArray.findIndex(
        (item) => item.name === labelName
      );
      // ("jobIndex: " + jobIndex);
      if (jobIndex!== -1) {
        state.allSegmentsArray[jobIndex].annotationPolygon = annotationPolygon;
        if(boundingBoxFloat &&boundingBoxInt){
          state.allSegmentsArray[jobIndex].BoundingBoxFloat=boundingBoxFloat;
          state.allSegmentsArray[jobIndex].BoundingBoxInt=boundingBoxInt;
        }
       
      }
    },
    updateSwatchAllSeg:(state, action)=>{
      const { segmentName, swatchId, swatchImage, palletImage } = action.payload;
     // ;
          if(swatchImage && swatchImage.length>0){
            swatchImage.forEach((segment)=>{


              const jobIndex = state.allSegmentsArray.findIndex(
                (item) => item.name === segment.class_name
              );

              if (jobIndex !== -1) {
                const seg = state.allSegmentsArray[jobIndex].SelectSwatchJob;
                const swatchIndex = seg.findIndex(
                  (item) => item?.swatch?.data?.id === swatchId
                );
                if(swatchIndex===-1){
                  seg.push({
                    swatch:{
                      data:{
                        id:swatchId,
                        attributes:{
                          image_s3:palletImage
                        }
                      }
                    },
                    SegmentImagePath:segment.output
                  })
        
                }else{

                   //seg[swatchIndex].swatch.data.attributes.image_s3=palletImage
                   seg[swatchIndex].SegmentImagePath=segment.output
                }
            }
            })
          }
     

    
    
  },
  updateSwatchUploadImage:(state,action)=>{
    const { segName, swatchId, url } = action.payload;
    //;
    const jobIndex = state.allSegmentsArray.findIndex(
      (item) => item.name === segName
    );

    if (jobIndex !== -1) {
      const seg = state.allSegmentsArray[jobIndex].SelectSwatchJob;
      seg.forEach((swatch) => {
        let swatch_id=parseInt(swatch.swatch.data.id, 10);
        if(swatch.swatch.data.id===swatchId){
          swatch.SegmentImage.data.attributes.url=url
        }
      });
  }
  },
  updateOverlapPolygonAnnotation:(state,action)=>{
    
    const {labelName,annotationPolygon} = action.payload;
    const jobIndex = state.allSegmentsArray.findIndex(
      (item) => item.name === labelName
    );
    // ("jobIndex: " + jobIndex);
    if (jobIndex!== -1) {
      state.allSegmentsArray[jobIndex].annotationPolygon = annotationPolygon;
     
    }
  },
},
});

export const {
  addSegment,
  removeSegment,
  removeAllSegment,
  selectSegment,
  updateSegment,
  addNestedSegemnt,
  removeSegmentFromSegments,
  removeSwatchFromSegments,
  updatePolygonAnnotation,updateSwatchAllSeg,
  updateSwatchUploadImage,
  updateOverlapPolygonAnnotation
} = AllSegmentArraySlice.actions;
export const selectAllSegments = (state) => state.allSegmentsArray;
export default AllSegmentArraySlice.reducer;

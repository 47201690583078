import React, { useState, useEffect, useRef } from "react";

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ButtonGroup,
  } from "@chakra-ui/react";
const RemoveNoiseModal=({isopenModal,noseClose,removeNoise})=>{


    const handleYes=()=>{
        removeNoise()
       // noseClose()
    }

    const handleNo =()=>{
        noseClose()
    }

    return (
        <>
          <Modal
    className="save-annotaions-popup"
    closeOnOverlayClick={false}
    isOpen={isopenModal}
    onClose={noseClose}
  >
    <ModalOverlay />
    <ModalContent className="save-annotaions-popup">
      <ModalHeader className="save-annotation-title">
        Remove Noise 
      </ModalHeader>
      <ModalCloseButton />

      <div className="save-annotation">
        {/* <div className="annotation-save">
          <div className="save-annotation-images">
            <img src={noimage} alt="No"></img>
          </div>
          <div className="save-annotation-btn">
            <button
              type="button"
              class="chakra-button css-1ut02yo"
              value="Window2"
              fdprocessedid="8sk786"
            >
              {" "}
              {newLabel}
            </button>
          </div>
        </div> */}

        <div className="save-annotation-icon">
          <Button className="save-annotation-icon-check">
            <i className="bi bi-list-check"></i>
          </Button>
          <Button className="save-annotation-icon-check">
            <i class="bi bi-images"></i>
          </Button>
          <Button className="save-annotation-icon-check">
            <i className="bi bi-trash"></i>
          </Button>
        </div>
      </div>

      <ModalFooter className="save-annotation-button">
        <Button
          colorScheme="blue"
          mr={3}
          onClick={() => handleYes()}
        >
          Yes
        </Button>
        <Button colorScheme="red" onClick={handleNo}>
          No
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
        </>)
  

}
export default RemoveNoiseModal
import { createSlice } from "@reduxjs/toolkit";

const ShadowSlice= createSlice({
    name: "shadow",
    initialState: {
        allShadow: [],
        updateShadowOnCanvas:[],
        shadowName:null,
        isHideShadow: false,
        isUnHideShadow: false, 
        isUpdate:false,
      },
    reducers: {
      getFromStrapi:(state,action)=>{
        state.allShadow=action.payload.allShadow
        state.isUpdate=action.payload.isUpdate
        state.updateShadowOnCanvas=action.payload.updateShadowOnCanvas
      },
      
      addShadow: (state, action) => {
        state.allShadow.push(action.payload)
      },
      addOnHoverLAyer:(state, action) => {
        state.updateShadowOnCanvas.push(action.payload)
        state.isUpdate=action.payload.isUpdate
      },
      removeShadowCanvas:(state, action) => {
        state.updateShadowOnCanvas=[]
        state.isUpdate=false
      },
      removeShadow: (state, action) => {
        state.allShadow = [];
        state.isHideShadow=false
        state.isUnHideShadow=false
        state.shadowName=null
      },
      hideShadow:(state, action)=>{
        state.isHideShadow=action.payload.isHideShadow
        state.shadowName=action.payload.shadowName
      },
      unHideShadow:(state, action)=>{
        state.isUnHideShadow=action.payload.isUnHideShadow
        state.shadowName=action.payload.shadowName
      },
      
      updateAlphaValue:(state,action)=>{
      
        const {shadowName, alphaValue}= action.payload

        const findShadowIndex= state.allShadow.findIndex((item)=>
        item.label===shadowName);
        if(findShadowIndex!==-1){
          state.allShadow[findShadowIndex].alpha_value=alphaValue
        }
      }
      
      
    },
})

export const {
  addShadow,
  addOnHoverLAyer,
  removeShadowCanvas,
  removeShadow,
  hideShadow,
  unHideShadow,
  getFromStrapi,
  updateAlphaValue
  
} = ShadowSlice.actions;

export const getAllShadows= (state, action) =>state.shadow
export const getIsAddedShadow=(state)=>state.shadow.isAddedShadow
export const updateShadow=(state)=>state.shadow
export default ShadowSlice.reducer;

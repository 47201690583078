import { gql } from "@apollo/client";

export const GET_Designer_PROJECT = gql`
  query GetCustomerData($projectId: ID!) {
    designers(
      filters: { id: { eq: $projectId } }
      sort: "createdAt:desc"
      pagination: { limit: -1 }
    ) {
      data {
        id
        attributes {
          name
          email
          projects(
            sort: "createdAt:desc" # Sort projects by project name in ascending order
          ) {
            data {
              id
              attributes {
                ProjectImageUrl
                name
                ImageBase64
                ModelVersion
                jobs {
                  data {
                    id
                  }
                }
                image {
                  data {
                    id
                    attributes {
                      name
                      formats
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;
import { gql } from "@apollo/client";

export const GET_PROJECTDATA = gql`
  query GetCustomerData {
    customers(
      filters: { id: { eq: "27" } }
      sort: "createdAt:asc"
      pagination: { limit: -1 }
    ) {
      data {
        id
        attributes {
          name
          email
          projects(
            sort: "name:asc" # Sort projects by project name in ascending order
          ) {
            data {
              id
              attributes {
                ModelVersion
                name
                ImageBase64
                jobs {
                  data {
                    id
                    attributes {
                      Segmentation(
                        sort: "name:asc" # Sort Segmentations by name in ascending order
                      ) {
                        id
                        name
                        area_pixel
                        annotationPolygon
                        confidence
                        BoundingBoxFloat
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_JOBSDETAIL = gql`
  query GetAllJob($jobId: ID!) {
    jobs(filters: { id: { eq: $jobId } }) {
      data {
        id
        attributes {
          ModelVersion
          Shadows
          Segmentation(pagination: { limit: -1 }) {
            id
            name
            area_pixel
            annotationPolygon
            confidence
            BoundingBoxFloat
            BoundingBoxInt
            perimeter
            SelectSwatchJob {
              id

              swatch {
                data {
                  id
                  attributes {
                    name
                     Type
                    swatch_style {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    brand {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    image_s3
                    image {
                      data {
                        id
                        attributes {
                          name
                          url
                        }
                      }
                    }
                    swatch_style {
                      data {
                        id
                      }
                    }
                  }
                }
              }
              
              SegmentImagePath
              SegmentImage {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              SwatchFor
              User{
                data{
                  id
                  attributes{
                    username
                    email
   
                  }
                }
              }

            }
          }
        }
      }
    }
  }
`;

export const GET_USER_PROJECT = gql`
  query GetCustomerData($projectId: ID!) {
    customers(
      filters: { id: { eq: $projectId } }
      sort: "createdAt:desc"
      pagination: { limit: -1 }
    ) {
      data {
        id
        attributes {
          name
          email
          projects(
            sort: "createdAt:desc" # Sort projects by project name in ascending order
          ) {
            data {
              id
              attributes {
                ProjectImageUrl
                name
                ImageBase64
                ModelVersion
                jobs {
                  data {
                    id
                  }
                }
                image {
                  data {
                    id
                    attributes {
                      name
                      formats
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

export const GET_LABELS_BRANDS_SWATCHSTYLE_SWATCH = gql`
  query getAll_Labels_Brand_swatchStyle_Swatch {
    labels(pagination: { limit: -1 }, sort: "name:asc") {
      data {
        id
        attributes {
          name
          status
          
          brands(sort: "name:asc") {
            data {
              id
              attributes {
                name
              }
            }
          }
          swatch_styles(sort: "name:asc") {
            data {
              id
              attributes {
                name
              }
            }
          }
          swatches(sort: "name:asc") {
            data {
              id
              attributes {
                name
                image_s3
                isRecommended
                Type
                shape{
                  data{
                    id
                    attributes{
                      Name
                      Description
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LABEL_SEGMENTS = gql`
query getLabel_Segements {
  segments(sort: "index") {
    data {
      id
      attributes {
        name
        labels {
          data {
            id
            attributes {
              name
              status
            }
          }
        }
        icon {
          data {
            id
            attributes {
              url
              
            }
          }
        }
      }
    }
  }
}
`;

export const Get_Designer_JobList = gql`
  query designJobList($designerid: ID!) {
    designers(filters: { id: { eq: $designerid } }) {
      data {
        id
        attributes {
          email
          jobs {
            data {
              id
              attributes {
                pixelUnit
                project {
                  data {
                    id
                    attributes {
                      name
                      ImageBase64
                    }
                  }
                }
                JobImage {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

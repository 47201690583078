import { createSlice } from "@reduxjs/toolkit";
import { initializeConnect } from "react-redux/es/components/connect";

const AfterDeleteSegmentSlice = createSlice({
  name: "afterDeleteSegment",
  initialState: [],
  reducers: {
    addDeleteSegment: (state, action) => {
      state.push(action.payload);
    },
    removeDeleteSegment: (state, action) => {
      return [];
    },
  },
});

export const { addDeleteSegment, removeDeleteSegment } =
  AfterDeleteSegmentSlice.actions;
export const getAfterDeleteSegment = (state, action) =>
  state.afterDeleteSegment;
export default AfterDeleteSegmentSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
const UploadImage = ({ imageId, folderId, resetprojectFolder }) => {
  const isMounted = useRef(true);
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (folderId && imageId && isMounted.current && token) {
      uploadMainImage(imageId, folderId);
      return () => {
        isMounted.current = false;
      };
    }
  }, [imageId, folderId,token]);

  const uploadMainImage = async () => {
    const formData = new FormData();
    const authToken = "Bearer " + token;
    let data = {
      name: "Image",
      folder: folderId,
    };
    formData.append("fileInfo", JSON.stringify(data));
    try {
      const response = await axios.post(
        `https://aiadmin.dzinly.org/upload?id=${imageId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authToken
          },
        }
      );
      if (response.status === 200) {
       // (response);
        isMounted.current = true;
        resetprojectFolder();
      }
    } catch (e) {
      isMounted.current = true;
    }
  };
  return <div></div>;
};

export default UploadImage;

import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

import "../../Alert/SamLoading.css";
const SwatchDeletingSpin = () => {
  return (
    <div className="select-create-project-loading">
      {/* <CircularProgress isIndeterminate color="green.300" /> */}
      <Alert status="success" variant="solid">
        {/* <AlertIcon /> */}
        <Spinner />
        <AlertTitle className="job-loading">Updating Swatches...</AlertTitle>
        <AlertDescription></AlertDescription>
      </Alert>
    </div>
  );
};

export default SwatchDeletingSpin;

import {createSlice} from "@reduxjs/toolkit"
 const ViewPalletInfoSlice= createSlice({
    name:"palletInfo",
    initialState: {
        palleteInfo:null,
        segName:null
    },
    reducers:{
        addViewPalletInfo:(state,action)=>{
           state.palleteInfo=action.payload.palleteInfo
            state.segName=action.payload.segName;
        },
        removeViewPallete:(state,action)=>{
            state.palleteInfo=null;
            state.segName=null;
        }
    }
 })

 export const {addViewPalletInfo,
    removeViewPallete
}= ViewPalletInfoSlice.actions;
export const getViewPalletesInfo= (state)=>state.palleteInfo;
export default  ViewPalletInfoSlice.reducer
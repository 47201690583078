import React, { useEffect, useRef, useState } from "react";
import JSZip from "jszip";
const UnzipFolder = ({ imageFile }) => {
  const isMounted = useRef(true);
  const [documents, setDocument] = useState();
  useEffect(() => {
    const unzipFile = async () => {
      try {
        const extractedData = await unzip(imageFile);
        // Process or save the extracted data
        //(extractedData);
        setDocument(extractedData);
      } catch (error) {
        // Handle errors, e.g., display an error message to the user
        console.error(error);
      }
    };

    if (imageFile && isMounted.current) {
      unzipFile();
    }

    return () => {
      isMounted.current = false;
    };
  }, [imageFile]);

  const unzip = async (zipFile) => {
    // ;
    const zip = new JSZip();
    let extractedData = [];

    await zip.loadAsync(zipFile);

    for (const entryName of Object.keys(zip.files)) {
      const entry = zip.files[entryName];
     // (entryName);
      extractedData.push(entryName);
    }
    //(extractedData);
    return extractedData;
  };
  return (
    <div>
      {documents &&
        documents.length > 0 &&
        documents.map((item) => <div>{item}</div>)}
    </div>
  );
};

export default UnzipFolder;

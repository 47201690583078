import { createSlice } from "@reduxjs/toolkit";
const OverAllSwatchSlice = createSlice({
  name: "overAllSwatch",
  initialState: {
    overAllSwatch: [],
  },
  reducers: {
    addOverSwatch: (state, action) => {
      state.overAllSwatch = [...action.payload];
    },
    updateOverSwatch: (state, action) => {
      const { labelName, allSwatch } = action.payload;
      const segIndex = state.overAllSwatch.findIndex(
        (item) => item.name === labelName
      );
      if (segIndex !== -1) {
        const seg = state.overAllSwatch[segIndex];
        seg.allSwatch = allSwatch;
      }
    },
  },
});

export const { addOverSwatch, updateOverSwatch } = OverAllSwatchSlice.actions;
export const getOverAllSwatch = (state, action) => state.overAllSwatch;
export default OverAllSwatchSlice.reducer;

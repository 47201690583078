import React, { useEffect, useState } from "react";
import "./Gridview.css";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { useDispatch , useSelector} from "react-redux";
import {switchCanvas} from "../slices/gridView/MultiSelectionSegement"
import{editAnnotation,getEditAnnotation,removeEditSegment} from "../slices/EditSegment/EditSegment"
const ViewAction = ({
  segName,
  selectSwatch,
  searchViewPallete,
  deleteSegments,
  editSegmentation,
  reAnnotate
}) => {
  const [segmentName, setSegmenatName] = useState(null);
  const [isOpenAction, setIsOpenAction] = useState(false);
  const getEditAnnotations=useSelector(getEditAnnotation)
  const dispatch=useDispatch()
  const handleViewAction = () => {
    setSegmenatName(segName);
    if (segmentName != segName) {
      setIsOpenAction(true);
    } else {
      setIsOpenAction(false);
    }
  };

  // handle select swatch
  const handleSelectSwatch = () => {
   
    dispatch(switchCanvas({
      isSwitchCanvas:true
    }))
    selectSwatch();
  };

  //handle view pallete
  const handleViewPallete = () => {
    searchViewPallete();
  };

  // delete segmentation
  const handleDeleteSegment = () => {
    deleteSegments();
  };
  //edit segmentation
  const handleEditSegment = () => {
    if(getEditAnnotations){
      dispatch(removeEditSegment())
    }
   dispatch( (editAnnotation({
    isEditAnnotation:true
   })))
    editSegmentation();
  };

  const resetEditAnnotation=()=>{
    dispatch(removeEditSegment())
  }

  // re Annotation
  const handleReAnnotation=()=>{
         reAnnotate()
  }

  return (
    <Accordion defaultIndex={[1]} allowMultiple>
      <AccordionItem className="view-swaches-item">
        <h4>
          <AccordionButton onClick={resetEditAnnotation}>
            <Box as="span" flex="1" textAlign="left" aria-expanded="false">
              View Actions
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h4>

        <AccordionPanel pb={4}>
          <div className="select-segment-box">
            <div className="select-project-icons" onClick={handleSelectSwatch}
            key="select-swatch">
              {/* <i className="bi bi-list-check"></i> */}
              <i class="bi bi-list-check"></i>
              <a href="#" className="swatch-action">
                
                Select Swatch
              </a>
            </div>
            <div className="select-project-icons" onClick={handleViewPallete}
            key="View-swatch"
            >
              <i class="bi bi-image"></i>
              <a href="#" className="swatch-action">
                View Swatches
              </a>
            </div>

            <div className="select-project-icons" onClick={handleEditSegment}
            key="edit-project"
            >
            <i class="bi bi-pencil-square"></i>
              <a href="#" className="swatch-action">
                Edit
              </a>
            </div>

            <div className="select-project-icons" onClick={handleReAnnotation}
            key="Re-annotation"
            >

          <i class="bi bi-bounding-box-circles"></i>

           
              <a href="#" className="swatch-action">
              Re-Annotation
              </a>
            </div>


            <div className="select-project-icons" onClick={handleDeleteSegment}
            key="delete-project"
            >
              <i class="bi bi-trash3"></i>
              <a href="#" className="swatch-action">
                Delete
              </a>
            </div>
          
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ViewAction;

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

const DeleteProjectAlert = ({ isDeleteOpen, onClose, deleteSegment }) => {
  const handleCloseModal = () => {
    onClose();
  };

  const handleDelete = () => {
    deleteSegment();
  };

  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isDeleteOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent className="project-delete">
          <ModalHeader></ModalHeader>
          <ModalCloseButton onClick={handleCloseModal} />
          <ModalBody>
            <Text fontWeight="bold" mb="1rem">
              Are you sure you want to delete?
            </Text>
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter className="delete-project-select-btn">
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              No
            </Button>

            <Button variant="ghost" onClick={handleDelete}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DeleteProjectAlert;

import axios from 'axios'
import { data } from 'jquery'
import React,{useEffect, useState} from 'react'

const GetToken = ({resetData}) => {

    useEffect(()=>{
        getTokenForHeader()
    },[])


    const getTokenForHeader=async() => {

        try{

            const response= await axios.post("https://api.dzinly.org/aiadmin-token");
            console.log("token",response)
            if(response.status===200){
                sessionStorage.setItem("token",response?.data?.data?.token)
                resetData()
            }

        }catch(e){
            console.log(e)
        }
    }
  return (
    <div></div>
  )
}

export default GetToken
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllItems } from "../slices/MasterArraySlice"; // data hold all sehgments detail sexcept the swatch
import { selectAllSegments } from "../slices/AllSegmentSlice"; // data old the swatch of particular segments
import "../ImageView/ImageView.css";
import { Stage, Layer, Image } from "react-konva";
import { getProjectId } from "../slices/ProjectSlice";
import axios from "axios";
import SamLoading from "../Alert/SamLoading";
import "./FinalLayer.css";
import AddToDB from "./AddToDB";
const FinalLayer = () => {
  const allSegmentArray = useSelector(selectAllSegments);
  const [isloading, setIsLoading] = useState(true);
  const imageRef = React.useRef(null);
  const [size, setSize] = useState({});
  const scale = 2;
  const [position, setPosition] = useState([0, 0]);
  const findAllPoly = () => {
    let arrayMaster = [];
    if (allSegmentArray && allSegmentArray.allSegmentsArray.length > 0) {
      allSegmentArray.allSegmentsArray.map((item) => {
        const arrayOfNumbers = item.annotationPolygon
          .split(",")
          .map((str) => parseInt(str, 10));

        arrayMaster.push({
          [item.name]: arrayOfNumbers,
        });
      });
    }

    //console.log(arrayMaster);
    return arrayMaster;
    //  setPolygonArrayInt(arrayMaster);
  };
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) {
      setIsLoading(true);
      getFinalLayer();

      return () => {
        isMounted.current = false;
      };
    }
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const getBase64Image = useSelector(getProjectId);
  const [encodedImage, setEncodedImage] = useState(null);
  const getFinalLayer = async () => {
    let allpoly = findAllPoly();
    let data = {
      masterImage: getBase64Image.base64Image,
      segmentationIntValue: allpoly,
    };
    try {
      const response = await axios.post(
        "https://api.dzinly.org/api/" + "final-layer",
        data
      );
      if (response.status === 200) {
        //console.log(response);
        handleBase64Image(response?.data?.encoded_image);
        setEncodedImage(response?.data?.encoded_image);
      }
    } catch (e) {
      alert("Error in final layer", e.message);
      setIsLoading(false);
    }
  };

  const handleBase64Image = (image) => {
    if (image) {
      const encodedImage = image;
      const tets = "data:image/png;base64," + encodedImage;
      // Decode the base64 image
      const decodedImage = atob(tets.split(",")[1]);
      // Convert the decoded data to a Uint8Array
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "image/png" });

      // Create an object URL from the Blob
      const imageUrl = URL.createObjectURL(blob);
      var img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {
        imageRef.current = img;
      };
      setSelectedImage(img);
      setIsLoading(false);
    }
  };
  // reset encode Image
  const handleResetEncodeImage = () => {
    setEncodedImage(null);
  };
  return (
    <>
      {isloading ? (
        <div>
          <SamLoading />
        </div>
      ) : (
        <div>
          <div className="ai-right-wrapper-body  Cursor final-layer-images">
            <div>
              <h3>Final Layer</h3>
              <Stage
                id="testStageID"
                width={size.width || 1023}
                height={size.height || 592}
                // onMouseMove={handleMouseMove}
                // onMouseDown={handleMouseDown}
                // Set the background of the Stage to transparent
                style={{ background: "transparent" }}
              >
                <Layer>
                  <div className="canvas-images">
                    {selectedImage && (
                      <div>
                        <Image
                          ref={imageRef}
                          image={selectedImage}
                          width={size.width || 1023}
                          height={size.height || 592}
                          x={0}
                          y={0}
                          crop={{
                            x: position.x - size.width / scale / 2, // Use size.width
                            y: position.y - size.height / scale / 2, // Use size.height
                            width: size.width / scale, // Use size.width
                            height: size.height / scale, // Use size.height
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Layer>
              </Stage>
            </div>
          </div>
        </div>
      )}
      {encodedImage != null && (
        <AddToDB
          encodedImage={encodedImage}
          resetEncodeImage={handleResetEncodeImage}
        />
      )}
    </>
  );
};

export default FinalLayer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedPallete: [],
  encodeImage: null,
  swatchId: null,
  uploadSwatchId: null,
  swatchName: null,
  reloadJobId: null,
  palletUrl: null,
};
const SelectedPalletSlice = createSlice({
  name: "selectedSwatch",
  initialState,
  reducers: {
    addSelectedSwatch: (state, action) => {
      state.swatchId = action.payload.swatchId;
      state.encodeImage = action.payload.encodeImage;
      state.uploadSwatchId = action.payload.uploadSwatchId;
      state.swatchName = action.payload.swatchName;
      state.reloadJobId = action.payload.reloadJobId;
      state.palletUrl = action.payload.palletUrl;
      state.selectedPallete = action.payload.selectedPallete;
    },
    removeSelectedSwatch: (state, action) => {
      return null;
    },
    addSelectedPallete: (state, action) => {
      state.selectedPallete = action.payload.selectedPallete;
    },
    removeSelectedPallete: (state, action) => {
      state.selectedPallete = [];
      state.encodeImage = null;
      state.swatchId = null;
      state.uploadSwatchId = null;
      state.swatchName = null;
      state.reloadJobId = null;
      state.palletUrl = null;
    },
  },
});
export const {
  addSelectedSwatch,
  removeSelectedSwatch,
  addSelectedPallete,
  removeSelectedPallete,
} = SelectedPalletSlice.actions;
export const selectedSwatch = (state) => state.selectedSwatch;
export const selectedPalletdDetails = (state) =>
  state.selectedSwatch.selectedPallete;

export default SelectedPalletSlice.reducer;

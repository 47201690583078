import React from "react";
import { Card, Container, Input, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import "../HomePage/CreateProject.css";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import "./Createproject.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Center,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { getCustomer } from "../login/AuthSlice";
import { addProjectImage1 } from "../slices/CreateProjectImageSlice";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { reLoadProject } from "../slices/AlertSlice";
import { makeAllImageNull } from "../slices/AlertSlice";
import { nullSegmentValue } from "../slices/LoadingSlice";
import { removeCurrentPallete } from "../slices/CurrentSelectedPalleteSlice";
import CreateFolder from "./CreateFolder";
import { addProjectImageId } from "../slices/ProjectSlice";
import { addModelVersion } from "../slices/Model/ModelSlice";
import { getModelVersionstrapi } from "../slices/Model/ModelVersionSlices";
import {addProjectImageURL} from "../slices/ProjectSlice"
import { palletLoading ,samLoading} from "../slices/AlertSlice";
import {projectCreated} from "../slices/ProjectSlice"

import {updateUser} from "../login/AuthSlice";
import {refreshReduxData} from "../slices/CreateProjectHeaderSlice/CreateProjectHeaderSlice"
import {addProjectName_Id} from "../slices/addProject/AddProjectSlice"

function CreateProject({
  isOpen,
  onClose,
  sendImage,
  sendNewImage,
  sendCloseValue,
  closeFromHome,
  isHome,
}) {
  const [projectName, setProjectName] = useState("New Project");
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadImageId, setUploadImageId] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState(""); // To store the data URL of the thumbnail
  const [colorChanged, setColorChanged] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [isproject, setIsproject] = useState(true);
  const [modelImage, SetModelImage] = useState([]);
  const getCustomerDetail = useSelector(getCustomer);
  const [project_id, setProject_id] = useState();
  const dispatch = useDispatch();
  const [encodeImage, setBase64Image] = useState();
  const [bothValuesAvailable, setBothValuesAvailable] = useState(false);
  const fileInputRef = React.createRef();
  const [openDiv, setOpenDiv] = useState(true);
  const navigate = useNavigate();
  const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
  const [isOPenCreateSegFolder, setIsOpenCreateSegFolder] = useState(false);
  const getModelVersions = useSelector(getModelVersionstrapi);
  const [modelId, setModelId] = useState();
  const [modelversion, setModelversion] = useState();
  let token= sessionStorage.getItem('token');
  // console.log(getModelVersions);
  //open modal and creat folders

  useEffect(() => {
    setIsproject(true);
  }, [isOpen]);

  // update project name
  const handleProjectName = (event) => {
    const project = event.target.value;
    setProjectName(project);
  };

  //upload Image
  const [imageFile, setImageFile] = useState(null);
  const handleImage = async (event) => {
    //
    localStorage.removeItem("imageFile");
    setIsOpenCreateFolder(true); // create folder modal open
    setOpenDiv(false);
    setIsLoading(true);
    const imageFile = event.target.files[0];
    
    setSelectedFile(imageFile);
    SetModelImage(imageFile);
    setImageFile(imageFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target.result;
      localStorage.setItem('imageFile', (dataURL));
      dispatch(addProjectImage1({ image:dataURL  }));
    };

    reader.onloadend = () => {
      // When the FileReader finishes reading, set the data URL as the thumbnail URL
      setThumbnailUrl(reader.result);
    };

    // Read the contents of the selected image file as a data URL
    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  
  };

  // close create folder modal
  const [project_Image, setproject_image]= useState()
  const [projectId, setProjectId] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const handleCloseCreateFolder = (projectid, folderid) => {
    setIsOpenCreateFolder(false);
    setProjectId(projectid);
    setProject_id(projectid)
    setIsLoading(false)
   // setIsOpenCreateSegFolder(true);
    setFolderId(folderid);
    //uploadImage(projectid, folderid);
   // sessionStorage.setItem("FolderId", folderid);
   checkBothValues();
        setIsSubmitButtonEnabled(true);
   dispatch(addProjectImage1({
     image: selectedFile,
    projectId: projectid}));

   
  };      



  // after creating folder upload the image
  const uploadImage = async (project_id, folder_id) => {
    const formData = new FormData();
    formData.append("files", selectedFile); // the pic
    formData.append(
      "fileInfo",
      JSON.stringify({ name: selectedFile.name, folder: folder_id.toString() })
    );
    // formData.append("folder", projectName);
    const authToken = "Bearer " + token;
    try {
      const response = await axios.post(
        "https://aiadmin.dzinly.org/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
   
            Authorization: authToken 
          },
        }
      );


     
      setIsLoading(false);
      if (response && response.data.length > 0) {
        setproject_image(response?.data[0].formats.large.url)
        sessionStorage.setItem("projectImage", response?.data[0].formats.large.url)
        dispatch(addProjectImageURL({
          projectImage:response?.data[0].formats.large.url
        }))
        //console.log("imageUpload", response)
        setUploadImageId(response?.data[0].id);
        dispatch(
          addProjectImageId({
            imageId: response?.data[0].id,
          })
        );
        checkBothValues();
        setIsSubmitButtonEnabled(true); // Enable the submit button
      }
    } catch (e) {
       console.log("Error in upload", e);
      toast({
        title: e.message,

        status: "error",
        duration: 1000,
        isClosable: true,
        position: "center-bottom",
      });
      setIsLoading(false);
    }
  };
  // pass to AWS
  const [loadingProject, setLoadingProject] = useState(false);
  const handleProjectSubmit = async () => {
    //remove all freom redux
    dispatch(samLoading({ samLoading: true }));
    dispatch(refreshReduxData({
      isStarteUpload:true
    }))
    //dispatch(palletLoading({ palletLoading: true }));
    dispatch(
      addModelVersion({
        modelVersion:"M1",
        modelApi:"object-detectation-api" ,
        modelId: 1,
      })
    );
    if (isHome) {
      dispatch(
        nullSegmentValue({
          deleteSegmentValue: true,
        })
      );
    }
    dispatch(removeCurrentPallete());
    dispatch(
      makeAllImageNull({
        isAllSegImage: false,
      })
    );
    dispatch(projectCreated({
      isProjectCreated:true
    }))
    setLoadingProject(true);
    // Check if imageFile is null or not
    if (imageFile === null && modelId === null) {
      // Show an error toast message if imageFile is null
      toast({
        title: "Image Not Selected",
        description: "Please select an image before submitting.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "center-bottom",
      });
      return; // Don't proceed if imageFile is null
    }

    // Set the project name, defaulting to "New Project" if it's empty
    let project_name = projectName === "" ? "New Project" : projectName;

    // Create a project object with necessary data
    let rolebased=getCustomerDetail.role
    const project = {
      name: project_name,
      Status: true,
      [rolebased]: getCustomerDetail.customerId,
      Stage: "Reviewing",
      image: uploadImageId,
      model: modelId,
      ModelVersion: modelversion,
      
    };

    // Create a data object to send in the API request
    const data = {
      data: project,
    };

    try {
      // Make a POST request to the API to create the project
      const response = await axios.put(
        process.env.REACT_APP_API_STRAPI + "projects/" + projectId,
        data
      );


      // new project into redux
      dispatch(addProjectName_Id({
        projectName: project_name,
        projectId: projectId,
      }))
      // Extract the project data from the API response
      setModelId(null);
      setModelversion(null);
      if (response.status === 200) {
      
        const project = response.data.data;
        sessionStorage.removeItem("CreateProject");
        sessionStorage.setItem("CreateProject", JSON.stringify(project));
        dispatch(updateUser())
        navigate("/projectdraft/" + project.id);
        setLoadingProject(false);
        setOpenDiv(true);
        dispatch(
          reLoadProject({
            reLoadProject: true,
          })
        );
        if (isHome) {
          closeFromHome();
        }
      }
    } catch (error) {
      // console.error("project", error);
      toast({
        title: "Error: " + error.message,
        description: "Please check your network",
        status: "error",
        duration: 3,
        isClosable: true,
        position: "center-bottom",
      });
      setLoadingProject(false);
    }
  };
  const [hideValue, setHideValue] = useState(true);
  const HandleClose = () => {
    setOpenDiv(true);
    setThumbnailUrl(null);
    setProjectName(null);

    onClose();
  };

  // Function to check if both values are available and set the state accordingly
  const checkBothValues = () => {
    if (project_id !== null) {
      setBothValuesAvailable(true);
    }
  };

  // handle Drag Image
  const handleDragImage = () => {

 
    fileInputRef.current.click();
    // setOpenDiv(false);
  };

  // reset the image
  const HandleReset = () => {
    setOpenDiv(true);
    setThumbnailUrl("");
    setSelectedFile(null)
    SetModelImage(null)
    setImageFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // close create segment folder API
  const handleCloseCreateSegFolder = () => {
    setIsOpenCreateSegFolder(false);
    setFolderId(null);
    setProjectId(null);
  };

  const [selectedModel, setSelectedModel] = useState("");
  // handle check box for model version
  const handleModelChange = (value, id, modelName) => {

    // Handle checkbox value
    setModelId(id);
    setModelversion(modelName);
    // console.log("Checkbox value:", value);
    setSelectedModel(value);
    // ;
    dispatch(
      addModelVersion({
        modelVersion: modelName,
        modelApi: value,
        modelId: id,
      })
    );
  };

  return (
    <>
      {isOpenCreateFolder && (
        <CreateFolder resetData={handleCloseCreateFolder} />
      )}
      {/* {isOPenCreateSegFolder && folderId != null && (
        <CreateSegmentFolder
          folderId={folderId}
          resetData2={handleCloseCreateSegFolder}
        />
      )} */}
      {isproject && hideValue && (
        <Modal
          closeOnOverlayClick={false}
          blockScrollOnMount={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent className="create-project-popup">
            <ModalHeader className="create-project-title">
              <Text textAlign={"center"}>Create New Project</Text>
            </ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              <FormControl>
                <FormLabel>Project Name</FormLabel>
                <Input
                  type="text"
                  value={projectName}
                  onChange={handleProjectName}
                  borderRadius="md"
                  borderColor="gray.400"
                  _focus={{ borderColor: "blue.500" }}
                />
              </FormControl>
              <br />

              <FormControl className="upload-button">
                <FormLabel>Upload Image</FormLabel>

                <Input
                  className="create-project-input"
                  type="file"
                  onChange={handleImage}
                  borderRadius="md"
                  borderColor="gray.400"
                  style={{ display: "none" }}
                  _focus={{ borderColor: "blue.500" }}
                  ref={fileInputRef}
                />
              </FormControl>

              {openDiv && (
                <div className="upload-img-button-box" onClick={handleDragImage}>
                  <div className="upload-btn">
                    {/* <i class="bi bi-cloud-upload"></i> */}
                    {/* <i class="bi bi-cloud-upload-fill"></i> */}
                    <i class="bi bi-cloud-arrow-up"></i>
                    <p>
                      Click to upload or <br></br>Darg and Drop house image here
                    </p>
                  </div>
                </div>
              )}

              {/* Display the thumbnail if it exists */}
              {thumbnailUrl && (
                <img
                  src={thumbnailUrl}
                  alt="Thumbnail"
                  style={{ maxWidth: "100%", marginTop: "10px" }}
                />
              )}
              <br />

              {/* <div className="modal-button">
                <h3>Choose Model</h3>
                <RadioGroup value={selectedModel}>
                  <div className="radio">
                    {getModelVersions &&
                      getModelVersions.modelVersion &&
                      getModelVersions.modelVersion.length > 0 &&
                      getModelVersions.modelVersion.map((model) => (
                        <div key={model?.id}>
                          <button
                            className="modal-btn active-model-button"
                            key={model?.id}
                          >
                            <Radio
                              value={model?.attributes?.Api}
                              onClick={(e) =>
                                handleModelChange(
                                  model?.attributes?.Api,
                                  model?.id,
                                  model?.attributes?.Name
                                )
                              }
                            >
                              {model?.attributes?.Name}
                            </Radio>
                          </button>
                        </div>
                      ))}
                  </div>

                 
                </RadioGroup>
              </div> */}
              <ModalFooter className="create-project-button">
                {isLoading ? (
                  <Button isLoading loadingText="Uploading...">
                    Uploading
                  </Button>
                ) : (
                  <>
                    {isSubmitButtonEnabled && (
                      <Button
                        // Change the color scheme based on the state
                        mr={3}
                        onClick={handleProjectSubmit}
                        disabled={!imageFile || !thumbnailUrl}
                      >
                        Submit
                      </Button>
                    )}
                    <Button
                      className="close-btn-handle-project"
                      colorScheme="red"
                      mr={3}
                      onClick={HandleClose}
                      _hover={{ bg: "red.500" }}
                    >
                      Close
                    </Button>
                    <Button
                      className="reset-btn-handle-project"
                      colorScheme="red"
                      mr={3}
                      onClick={HandleReset}
                      _hover={{ bg: "red.500" }}
                    >
                      Reset
                    </Button>
                  </>
                )}
                {loadingProject && (
                  <CircularProgress isIndeterminate color="green.300" />
                )}
              </ModalFooter>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default CreateProject;

import React, { useEffect, useState } from "react";
import "./Footer.css";
const Footer=()=>{
    return(
        <div className="dzinly-footer">


     <div className="footer container">
        <div className="footer-section">
          <div className="footer-logo">
            <img src="https://dzinly.com/img/logo.svg"></img>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
          </div>

          <div className="footer-contact">
            <h2>Contact</h2>
            <div className="contact">
              <i class="bi bi-envelope"></i>
              <p className="contact-title">
                <a href="#"> hello@dzinly.com</a>
              </p>
            </div>

            <div className="contact">
            <i class="bi bi-phone-flip"></i>
              <p className="contact-title">
                <a href="#"> (555) 555-1234</a>
              </p>
            </div>

            <div></div>
          </div>

          <div className="footer-column-tree">
            <h2>Office</h2>
            <p>
            
            Nulla consequat massa quis enim. <br></br>Ut a nisl id ante tempus 32525, USA


            </p>
            <div className="social-media-icon">
              <i class="bi bi-instagram"></i>
              <i class="bi bi-twitter"></i>
              <i class="bi bi-facebook"></i>
              <i class="bi bi-linkedin"></i>
            </div>
          </div>
        </div> 
        </div>

      <div className="copyright">
        <p>© 2024 Dzinly Ai | All rights reserved</p>
      </div>
     
          
        </div>
    )
}
export default Footer;
import { createSlice } from "@reduxjs/toolkit";
const ModelSlice = createSlice({
  name: "model",
  initialState: {
    modelVersion: null,
    modelApi: null,
    modelId: null,
  },
  reducers: {
    addModelVersion: (state, action) => {
      state.modelVersion = action.payload.modelVersion;
      state.modelApi = action.payload.modelApi;
      state.modelId = action.payload.modelId;
    },
  },
});

export const { addModelVersion } = ModelSlice.actions;
export const getModelVersion = (state, action) => state.model;
export default ModelSlice.reducer;

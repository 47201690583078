import React from "react";
import "../Tabs/SegmentationTabStyle.css";
import homeicon from "../../assets/images/demo/home_images/image-layer.png";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { getViewPalletesInfo } from "../slices/ViewPalletInfoSlice";
import "./SelectedPalletInfo.css";
import { Tooltip } from '@chakra-ui/react'
import {removeViewPallete} from "../slices/ViewPalletInfoSlice"
const SelectedPalletInfo = ({
  isViewPallet,
  closeModal,
  segmentName,
  brand,
  style,
  pallet,
  palletImage,
}) => {
  const getAllPalllet = useSelector(getViewPalletesInfo);
  // console.log(getAllPalllet)
  const dispatch=useDispatch()
  const handleCloseModal = () => {
    dispatch(removeViewPallete())
    closeModal();
  };
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isViewPallet}
        onRequestClose={handleCloseModal}
        contentLabel="Additional Content Modal"
        style={{
          content: {
            width: "500px", // Set the width
            height: "300", // Set the height
            margin: "auto", // Center the modal horizontally
            border: "1px solid #ccc",
            bordrRadius: "6px",
            // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Set a fully opaque overlay color
          },
        }}
      >
        {/* Add your paragraph or content here */}
        <div className="close-icon-popup">
          <p className="pallet-brand-select-title">
            Pallet Details applied on {getAllPalllet.segName}
          </p>
          <i class="bi bi-x close-button-popup" onClick={closeModal}></i>
        </div>
        {getAllPalllet &&
          getAllPalllet.palleteInfo &&
          getAllPalllet.palleteInfo.length > 0 &&
          getAllPalllet.palleteInfo.map((item) => (
            <div>
              <div className="pallets-box">

                <div className="view-pallet-img">
                  <div className="pallet-name">
                    <h2 className="pallet-title-name"> Image</h2>
                  </div>

                  <div className="pallet-text">
                    <img
                      src={
                        process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                        item?.swatch?.data?.attributes?.image_s3
                      }
                      width={55}
                    ></img>
                  </div>
                </div>


                <div className="pallet-info">
                  <div className="pallet-name">
                    <h2 className="pallet-title-name">Pallet Information</h2>
                  
                  </div>

                  <div className="pallet-text pallet-info-title">
                    <h4>Name : <span>{item?.swatch?.data?.attributes?.name}</span></h4>
                    <h4>Brand : <span> {
                      item?.swatch?.data?.attributes?.brand?.data?.attributes
                        ?.name
                    }</span></h4>

                    <h4>Style : <span> {
                      item?.swatch?.data?.attributes?.swatch_style?.data
                        .attributes.name
                    }</span></h4>
                  </div>
                </div>


                {/* <div className="pallet-seleced">
                  <div className="pallet-name">
                    <h2 className="pallet-title-name"> Name</h2>
                  </div>

                  <div className="pallet-text">
                    <h5>{getAllPalllet.segName}</h5>
                  </div>
                </div> */}




                {/* <div className="pallet-seleced">
                  <div className="pallet-name">
                    <h2 className="pallet-title-name">Brand</h2>
                  </div>

                  <div className="pallet-text">
                    <h5>
                      {
                        item?.swatch?.data?.attributes?.brand?.data?.attributes
                          ?.name
                      }
                    </h5>
                  </div>
                </div> */}
                {/* 
                <div className="pallet-seleced">
                  <div className="pallet-name">
                    <h2 className="pallet-title-name">Style</h2>
                  </div>

                  <div className="pallet-text">
                    <h5>
                      {
                        item?.swatch?.data?.attributes?.swatch_style?.data
                          .attributes.name
                      }
                    </h5>
                  </div>
                </div> */}





                <div className="pallet-actions ">
                  <div className="pallet-name">
                    <h2 className="pallet-title-name">Action</h2>
                  </div>


                  <div className="pallet-icons">
                  <Tooltip  label='Delete' bg='gray.300' color='black'>
                  <i class="bi bi-trash"></i> 
                  </Tooltip>
                  <Tooltip  label='Hide/Show' bg='gray.300' color='black'>
                    <i class="bi bi-eye"></i>
                    </Tooltip>
                    <Tooltip label='Upload' bg='gray.300' color='black'>
                    <i class="bi bi-file-earmark-arrow-up"></i>
                    </Tooltip>
                  </div>
                </div>


              </div>
            </div>
          ))}

        {/* pallet-size */}

        <div>
          <div className="size-pallet">
            <div className="pallet-size-box">
              <h2 className="pallet-size-title">Pallet Size</h2>
            </div>

            <div className="pallet-size-text">
              <h3>12 x 15 pt</h3>
            </div>
          </div>

          <div className="size-pallet">
            <div className="pallet-size-box">
              <h2 className="pallet-size-title">Applied Area</h2>
            </div>

            <div className="pallet-size-text">
              <h3>1300 sq ft</h3>
            </div>
          </div>

          <div className="size-pallet">
            <div className="pallet-size-box">
              <h2 className="pallet-size-title"> Cost Calculation </h2>
            </div>

            <div className="pallet-size-text">
              <h3>1300 x 12x15</h3>
            </div>
          </div>

          <div className="size-pallet Qty">
            <div className="pallet-size-box">
              <h2 className="pallet-size-title"> Quantity</h2>
            </div>

            <div className="pallet-size-text">
              <h3>1</h3>
            </div>
          </div>
        </div>
        {/* pallet-size */}

        {/* You can add more content or components here */}
        {/* Close button */}
        {/* <button onClick={closeModal}>Close</button> */}
      </Modal>
    </>
  );
};

export default SelectedPalletInfo;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   isOpenDoorModal: false,

}

const DoorHeightSlice = createSlice({
    name: "doorHeightModal",
    initialState: initialState,
    reducers: {
      openDoorHeigthModal: (state, action) => {
        state.isOpenDoorModal=true;
      },
      closeDoorHeigthModal: (state, action) => {
        state.isOpenDoorModal=false;
      },
      
   
    }
    });

    export const { openDoorHeigthModal, closeDoorHeigthModal} = DoorHeightSlice.actions;

    export const getDoorheightModal = (state) => state.doorHeightModal.isOpenDoorModal;
    export default DoorHeightSlice.reducer;
import React, { useEffect, useRef , useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { fabric } from "fabric";
import { getEditSegmentation } from "../../slices/EditSegment/EditSegment";
import "./EditNewAnnonation.css";
import {getProjectId} from "../../slices/ProjectSlice"

//import { image } from "html2canvas/dist/types/css/types/image";
import {Button} from "@chakra-ui/react";
import {updateJobArray} from "../../slices/MasterJobs";
import { updatePolygonAnnotation} from "../../slices/AllSegmentSlice"
import {updateMasterPolygonAnnotation} from "../../slices/MasterArraySlice"
import {getSaveAnnotation,reSetAnnotation} from "../../slices/EditSegment/EditSegment"

import {getCanvasSize} from "../../slices/windowSizeSlice/WindowResolution.js"
const NewEditAnnotation = ({moveToImageView}) => {
  const getEditSeg = useSelector(getEditSegmentation);
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [points, setPoints] = useState([])
  const getProjectImage= useSelector(getProjectId)
  const[updatePoint, setUpdatePoint]= useState(true)
  const [selectedImage, setSelectdImage]= useState()
  const [size, setSize] = useState({});
  const[coordinatXY, setCoordinatXY]=useState();
  const [labelName, setLabelName]= useState(null)
  const [scaleX, setScalex]= useState(null);
  const [scaleY, setScaley]= useState(null);
  const isMountCanvasBackground= useRef(true)
  const getCanvasSizes= useSelector(getCanvasSize)
  const [isCanvas, setIsCanvas]=useState(false)
     const [polygonPoints, setPolygonPoints] = useState(null)
     const dispatch= useDispatch();
    
     const imageRef = React.useRef(null);
     // create canvas
     useEffect(() => {
      //
      if(getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null

        ){
          if(getProjectImage?.base64Image){
            var canvas = new fabric.Canvas("edit-annotation",{
              selectionBorderColor: 'green',
              width: getCanvasSizes.canvasWidth, // Set canvas width
            height:getCanvasSizes?.canvasHeight 
             });
             canvasRef.current = canvas; 
            setCanvas(canvas);
            setPoints(null)
            setPolygon(null)
            setIsCanvas(true)
            isMountCanvasBackground.current=true
           // canvasRef.current.on('mouse:over', handleMouseOver);
           // canvasRef.current.on('mouse:out', handleMouseOut);
           // canvasRef.current.on('mouse:down', handleMouseDown);
            return () => {
              // Remove event listeners on component unmount
            //  canvas.off('mouse:over', handleMouseOver);
             // canvas.off('mouse:out', handleMouseOut);
             // canvas.off('mouse:down', handleMouseDown);
            };
    
          }

        }
     
       
    }, [getCanvasSizes,getProjectImage]); 


     const handleMouseOver = (event) => {

     }
    

     const handleMouseOut = (event) => {
      //console.log("pointer mouse out", event.target)
     }


     const handleMouseDown = (event) => {
      console.log("pointer mouse down", event.target);
    
      var pointer = canvasRef.current.getPointer(event.e);
    
      var objects = canvasRef.current.getObjects();
      let addPoints=[]
      objects.forEach(obj => {
        if (obj.points) {
          obj.points.forEach((point, index) => {
            addPoints.push(point)
            // Check the next point to create a line segment
            const nextPoint = obj.points[(index + 1) % obj.points.length];
    
            // Check if the clicked point lies on the line segment
            if (isPointOnLine(pointer, point, nextPoint)) {
              console.log(`Clicked on the line between Point ${index + 1} and Point ${index + 2}`);
              // Calculate the midpoint between the clicked point and the next point
          const newPoint = {
            x: (point.x + nextPoint.x) / 2,
            y: (point.y + nextPoint.y) / 2
          };

          // Add the new point to the polygon's points array
          obj.points.splice(index + 1, 0, newPoint);

          // Update the polygon's points
          obj.set({ points: obj.points });

          // Recalculate the coordinates and render the polygon
          obj.setCoords();
          canvasRef.current.requestRenderAllBound();

          console.log('Added new point to the polygon:', newPoint);
        
            }
          });
        }
      });
      
        
    }
    
    // Function to check if a point lies on a line segment
const isPointOnLine = (point, lineStart, lineEnd) => {
  // Calculate the distance from the point to the line segment
  const distance = Math.abs(
    (lineEnd.y - lineStart.y) * point.x - 
    (lineEnd.x - lineStart.x) * point.y + 
    lineEnd.x * lineStart.y - 
    lineEnd.y * lineStart.x
  ) / Math.sqrt(
    Math.pow(lineEnd.y - lineStart.y, 2) + 
    Math.pow(lineEnd.x - lineStart.x, 2)
  );

  // Define a tolerance for the distance (e.g., 5 pixels)
  const tolerance = 5;

  // Check if the distance is within the tolerance
  return distance <= tolerance;
}


// add pooints 
const addPointInPolygon=()=>{

}

    useEffect(() => {
     // 
      if (canvasRef.current &&
         getProjectImage?.base64Image &&
         isMountCanvasBackground.current &&
         isCanvas) {
          isMountCanvasBackground.current = false;
        let image = getProjectImage?.base64Image;
        const encodedImage = "data:image/png;base64," + image;
        // Decode the base64 image
        const decodedImage = atob(encodedImage.split(",")[1]);
        // Convert the decoded data to a Uint8Array
        const uint8Array = new Uint8Array(decodedImage.length);
        for (let i = 0; i < decodedImage.length; i++) {
          uint8Array[i] = decodedImage.charCodeAt(i);
        }
        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "image/png" });
        // Create an object URL from the Blob
        let imageUrl = URL.createObjectURL(blob);
    
        var img = new window.Image();
        img.src = imageUrl;
        img.onload = () => {

          fabric.Image.fromURL(imageUrl, function (img) {
          
            canvasRef.current?.setBackgroundImage(imageUrl,canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
              scaleX: canvasRef.current?.width / img?.width,
              scaleY: canvasRef.current?.height / img?.height,
              width:canvasRef.current?.width,
              height:canvasRef.current?.height,
              originX: "left",
              originY: "top",
              // stroke: 'green', // Set border color
              // strokeWidth: 2 
             
            
              });
              setScalex(canvasRef?.current?.width / img?.width);
              setScaley(canvasRef?.current?.height / img?.height);
          //     console.log("img.width: " ,+img.width)
          // console.log("img.height: " ,+img.height)
          // console.log("canvasRef.current.width",canvasRef.current.width)
          // console.log("canvasRef.current.height",canvasRef.current.height)
            canvasRef.current?.requestRenderAll();
            })

          
        };
      }
    }, [canvasRef, getProjectImage,isCanvas]);
    
    
  // create polygon
    useEffect(() => {
      //
      let allPoly;
      let coordinate;
      if (isCanvas &&
        scaleX!=null &&
        scaleY!=null&&
        canvasRef.current&&
        getEditSeg &&
        getEditSeg?.isEditAnnotation&&
         getEditSeg?.editSegment && 
         getEditSeg.editSegment.length > 0) {
        setLabelName(getEditSeg?.editSegment[0]?.name)
        allPoly = getEditSeg?.editSegment[0]?.annotationPolygon
        ?.split(",")
        ?.map((value) => parseInt(value.trim(), 10));

      // console.log('allPoly', allPoly);
      coordinate=getEditSeg?.editSegment[0]?.BoundingBoxInt
      ?.split(",")
        ?.map((value) => parseInt(value.trim(), 10));
        setCoordinatXY(coordinate)
      let point = [];
      let i;
      for ( i = 0; i < allPoly.length; i += 2) {
        const x = allPoly[i]*scaleX;
        const y = allPoly[i + 1]*scaleY;
        point.push({ x, y });
      }
      setPoints(point)
      setUpdatePoint(true)
      setPolygon(null);
      let obj=canvasRef.current?.getObjects();

     // canvasRef.current?.remove(polygon);
      }
    
    }, [getEditSeg, canvasRef,isCanvas,scaleX,scaleY]);


    
  useEffect(() => {
   
    if(isCanvas&&
      points &&
       points.length > 0 &&
       updatePoint &&
       canvasRef.current) {

      
     
    var polygon = new fabric.Polygon(points, {
      left: coordinatXY[0]*scaleX,
      top: coordinatXY[1]*scaleY,
      fill: "transparent",
      strokeWidth: 2,
      stroke: "rgb(7 239 253)",
      scaleX: 1,
      scaleY: 1,
      objectCaching: false,
      transparentCorners: false,
      cornerColor: "red",
      cornerStyle: "circle",
      selectable:false
    
    });
    setPolygon(polygon)
    canvasRef.current.viewportTransform = [1, 0, 0, 1, 0, 0];
    canvasRef.current.add(polygon);
    setUpdatePoint(false)
  }
    
  }, [points, canvasRef,isCanvas]);

 
// calling edit function
  useEffect(()=>{
    if(canvasRef.current&&polygon &&isCanvas){
      // console.log("canvas", canvas)
      Edit()
    }
  },[canvasRef, polygon,isCanvas])

  const polygonPositionHandler = function (dim, finalMatrix, fabricObject) {
    
    // 
    const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
    const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
  
    const transformedPoint = fabric.util.transformPoint(
      { x, y },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    );
  
    return transformedPoint;
  };
  
  



  const Edit = () => {
    let objects = canvasRef.current.getObjects();
  
  //
    if (objects.length > 0) {
      let poly = objects[0];
      canvasRef.current.setActiveObject(poly);
      poly.edit = !poly.edit;
       //
      if (poly.edit) {
        const lastControl = poly.points.length - 1;
        poly.lockMovementX = true;
        poly.lockMovementY = true;
        poly.cornerStyle = "circle";
        poly.cornerColor = "rgb(255 1 154)";
        poly.cornerSize = 7;
        poly.cornerStrokeWidth = 3;
        poly.cornerStrokeColor = "rgb(7 239 253)";
        poly.controls = poly.points.reduce((acc, point, index) => {
          acc[`p${index}`] = new fabric.Control({
            cursor: 'pointer',
            positionHandler: polygonPositionHandler.bind({ pointIndex: index }),
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler).bind({ pointIndex: index }),
            actionName: "modifyPolygon",
            
            
          });
          return acc;
        }, {});
      } else {
        poly.cornerColor = "black";
        poly.cornerStyle = "rect";
        poly.controls = fabric.Object.prototype.controls;
      }
      poly.hasBorders = !poly.edit;
      canvasRef.current.requestRenderAll();
    }
  };
  

  // Define anchorWrapper function
  const anchorWrapper = function (anchorIndex, fn) {
    //
  //  console.log("anchorWrapper anchorIndex", anchorIndex)
  //  console.log("anchorWrapper fn", fn)
    return function (eventData, transform, x, y) {
      const fabricObject = transform.target;
      const absolutePoint = fabric.util.transformPoint(
        {
          x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
          y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
        },
        fabricObject.calcTransformMatrix()
      );
  
      const actionPerformed = fn(eventData, transform, x, y);
      const newDim = fabricObject._setPositionDimensions({});
      const polygonBaseSize = getObjectSizeWithStroke(fabricObject);
      const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
      const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
  
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    };
  };

  // Define actionHandler function
  const actionHandler = function (eventData, transform, x, y) {
   
    let transfor=transform
  
    const polygon = transfor.target;
    // console.log("polygon",polygon)
    let corner=transfor.corner
    const currentControl = parseInt(corner.match(/\d+/)[0], 10);
    // console.log("currentControl",polygon.controls[corner])
    const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), "center", "center");
    const polygonBaseSize = getObjectSizeWithStroke(polygon);
    //console.log("polygonBaseSize",polygonBaseSize)
    const size = polygon._getTransformedDimensions(0, 0);
    //console.log("size",size)
    const finalPointPosition = {
      x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
      y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
    };
  
   
    polygon.points[currentControl] = finalPointPosition;
   
   
  
    // Update React state for points
    setPoints([...polygon.points]);
   // Force a render after updating points
  polygon.setCoords();
  canvasRef.current.renderAll();
  
    return true;
  };  
  

  function getObjectSizeWithStroke(object) {
    var stroke = new fabric.Point(
        object.strokeUniform ? 1 / object.scaleX : 1, 
        object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

// save Annottaion
const getIsSaveAnnottaion= useSelector(getSaveAnnotation);
useEffect(()=>{
  if(getIsSaveAnnottaion &&scaleX!=null && scaleY!=null){
    //dispatch(reSetAnnotation())
    handleSaveAnnotation()
  }

},[getIsSaveAnnottaion,scaleX,scaleY])

const handleSaveAnnotation=()=>{
  //
  //console.log(points)
  const flattenedPoints = points.flatMap(point =>[ Math.floor(point.x/scaleX), Math.floor(point.y/scaleY) ]);

const flattenedPointsString = flattenedPoints.join(', ');


const minX = Math.min(...points.map(point => point.x));
const maxX = Math.max(...points.map(point => point.x));
const minY = Math.min(...points.map(point => point.y));
const maxY = Math.max(...points.map(point => point.y));

const newMinX = minX/scaleX;
const newMaxX = maxX/scaleX;
const newMinY = minY/scaleY;
const newMaxY = maxY/scaleY;

const BoundingBoxFloat=[newMinX,newMinY, newMaxX,newMaxY].toString();
const BoundingBoxInt=[
  Math.floor(newMinX ),
    Math.floor(newMinY ),
    Math.floor(newMaxX ),
    Math.floor(newMaxY),].toString()
   
    dispatch(updateJobArray({
      labelName:labelName,
       boundingBoxFloat:BoundingBoxFloat,
        boundingBoxInt:BoundingBoxInt,
        annotationPolygon:flattenedPoints.toString()
    }))

    //update in allsegment array
    dispatch( updatePolygonAnnotation({
      labelName:labelName,
       boundingBoxFloat:BoundingBoxFloat,
        boundingBoxInt:BoundingBoxInt,
        annotationPolygon:flattenedPoints.toString()
    }))

    // update polygon im master Array
    dispatch(updateMasterPolygonAnnotation({
      labelName:labelName,
       boundingBoxFloat:BoundingBoxFloat,
        boundingBoxInt:BoundingBoxInt,
        annotationPolygon:flattenedPoints.toString()
    }))

    dispatch(reSetAnnotation({
      labelName: labelName,
      polygon:polygon

    }))
    moveToImageView()  
}

//Bearer Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNzA5OTgxNTk0LCJleHAiOjE3MTI1NzM1OTR9.vZ28npP8-Y7R4krftoL_02Y9aYXm7ETV1HCboxV3glk



  return (
    <div className="ai-right-wrapper-body Cursor project-layer-images annotation-label-img">

      <h2 className="project-label-title">Edit {labelName}</h2>
    
      <div className="canvas-wrapper prodect-edit-annotation">
      <canvas className="edit-annotation"  id="edit-annotation"   ref={canvasRef}></canvas>
      </div>
    
      </div>
    
 
  );
};

export default NewEditAnnotation;

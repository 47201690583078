import React from 'react'
import "./Combination.css";
import { Tooltip } from '@chakra-ui/react'
import {GetCombinationSwatch} from "../slices/combinationSwatch/CombinationSwatchSlice"
import { useEffect, useState } from 'react';
import CallApi from "../Add_Edit_Pallet/CallApi"
import { useDispatch, useSelector } from 'react-redux';
import {selectAllItems} from "../slices/MasterArraySlice";
import { addSelectedPallete } from "../slices/SelectedPalletdSlice";

const Combination = () => {

  const dispatch = useDispatch();
  const getAllCombination= useSelector(GetCombinationSwatch)
  const getAllMaster= useSelector(selectAllItems)

  const[palletImagePath, setpalletImagePath]=useState()
 // (getAllMaster)

  const handleClickSwatch = (Swatches) => {
    let path;
    Swatches.map(item=>{
      getAllMaster?.master?.map(seg=>{
        if(item.getAllMaster===seg.Name){
         // allSegments(seg.segmentation,item?.swatch?.data?.attributes?.image_s3)
        }
      })
     
    })
   
  }

  const allSegments=(allSeg,url)=>{
    dispatch(addSelectedPallete({ selectedPallete: allSeg }));
setpalletImagePath(url)
  }
  return (
    <div className='pallet-group-section'>

      {getAllCombination &&
       getAllCombination?.combinationSwatch&&
       getAllCombination?.combinationSwatch.length>0&&
       getAllCombination?.combinationSwatch.map((item,index)=>{
        return(
          <div className="pallet-group-left" key={index}>
          <h5 className='combo-title'>{item?.attributes?.Name}</h5>
          <img src={item?.attributes?.Image?.data?.attributes?.formats?.thumbnail?.url} alt="no Image" className='group-img'></img>
          <Tooltip className="pallet-tooltip" label="Apply Pallet">
          <i class="bi bi-arrow-up-right-square"
          onClick={handleClickSwatch(item?.attributes?.Swatches)}
          ></i>
          </Tooltip>
          </div>)
       })

      }
     

      <CallApi
       palletImagePath={palletImagePath}
       //resetData={handleResetData}
      />

    </div>
  )
}

export default Combination;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_JOBSDETAIL } from "../GraphQL/GraphQl";

import { useQuery,useApolloClient } from "@apollo/client";
import { addSegment, selectAllSegments } from "../slices/AllSegmentSlice";
import { getProjectId } from "../slices/ProjectSlice";
import { addMasterJob } from "../slices/MasterJobs";
import { addModelVersion } from "../slices/Model/ModelSlice";
import { addJobrArray } from "../slices/JobArratSlice";
import {initialcurrentPallete}from "../slices/CurrentSelectedPalleteSlice"
import { samLoading, deleteLoading } from "../slices/AlertSlice";
import {selectAllItems, addMasterArray,} from "../slices/MasterArraySlice"
import {getCustomer} from "../login/AuthSlice.js"
import {addShadow,getFromStrapi} from "../slices/shadow/ShadowSlice.js"
const GetJob = ({ jobId, resetJobId }) => {

  const getCustomerDetails= useSelector(getCustomer)
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState();
  const CurrentProjects = useSelector(getProjectId);
  const [job_id, setJob_id] = useState();
  
  useEffect(() => {
    if (CurrentProjects.jobId) {
      setJob_id(CurrentProjects.jobId);
      refetch();
    }
  }, [CurrentProjects]);


  const { loading, error,data,refetch } = useQuery(GET_JOBSDETAIL, {
    variables: {
      jobId: job_id,
    },
    fetchPolicy: 'network-only',
  });
  

  const apolloClient = useApolloClient();

  const [jobData, setJobData] = useState();

  useEffect(() => {
    if (data && !loading) {
      
      dispatch(
        addModelVersion({
          modelVersion: data.jobs.data[0].attributes.ModelVersion,
        })
      );
      if( data.jobs.data[0].attributes.Shadows!=null){
        dispatch(getFromStrapi({
          allShadow: data.jobs.data[0].attributes.Shadows,
          isUpdate:true,
          updateShadowOnCanvas:data.jobs.data[0].attributes.Shadows
        }))
      }
      
      //dispatch(addSegment(data.jobs.data[0].attributes.Segmentation));
      setJobs(data.jobs.data[0].attributes.Segmentation);
      sortByAreaOfPixels(data.jobs.data[0].attributes.Segmentation)
      // console.log(data.jobs.data[0].attributes.Segmentation);
      createMasterArray(data.jobs.data[0].attributes.Segmentation)
    }
  }, [data, loading]);

  // arrange the all jobs data based on area of pixels
const sortByAreaOfPixels = (allsegments) => {
  const sortedJobs = allsegments.slice().sort((a, b) => {
    return parseInt(b.perimeter) - parseInt(a.perimeter);
  });

  dispatch(addSegment(sortedJobs));
};

  useEffect(() => {
    if (jobs && jobs.length > 0) {
      let allArray = [];
      let swatchs = [];
      let currentPallet=[]
      jobs.map((item) => {
        let swatchs = [];

        if (item.SelectSwatchJob
           && item.SelectSwatchJob.length > 0 
            ) {
          item.SelectSwatchJob.map((prevSwatch) => {


            let satchId = parseInt(prevSwatch?.swatch?.data?.id, 10);
            let userId = parseInt(prevSwatch?.User?.data?.id, 10);

            swatchs.push({
              swatch: satchId,
              SegmentImagePath:prevSwatch?.SegmentImagePath,
              SegmentImage: prevSwatch?.SegmentImage?.data?.id,
              checked:false,
              SwatchFor: prevSwatch?.SwatchFor,
              User: userId,
              isHide:false
              
            });
          });
        } else {
          // Handle the case where item.SelectSwatchJob is undefined or falsy
          swatchs = [];
        }
        allArray.push({
          name: item.name,
          area_pixel: item.area_pixel,
          annotationPolygon: item.annotationPolygon,
          confidence: item.confidence,
          BoundingBoxFloat: item.BoundingBoxFloat,
          BoundingBoxInt: item.BoundingBoxInt,
          perimeter:item.perimeter,
          SelectSwatchJob: swatchs
         
        });
        currentPallet.push({
          segmentName: item.name,
           swatchId: null,
           SegmentImagePath:null,
           checked:false
          }
        )
      });
      dispatch(initialcurrentPallete({
        currentpallate: currentPallet
      }))
      dispatch(addMasterJob(allArray));
      dispatch(addJobrArray(allArray));
    }
  }, [jobs]);

  // creater master Aray after getting Job
  const getMasterArray = useSelector(selectAllItems);
  const createMasterArray = (jobData) => {
   
    let allJobs = jobData;
    let masterArray = getMasterArray.master;
    let arrays = [];
  
    if (masterArray && masterArray.length > 0 && allJobs && allJobs.length > 0) {
      masterArray.forEach((item) => {
        let segArray = [];
        allJobs.forEach((job) => {
          let alphabets = job.name.match(/[a-zA-Z]+/)[0];
          if (alphabets === "Garage") {
            alphabets = "GarageDoor";
          }
          if (item.name === alphabets) {
            let userSwatch = job?.SelectSwatchJob.filter((swatch) => {
              return swatch?.User?.data?.attributes?.email === getCustomerDetails.email;
            }).map((swatch) => ({
              ...swatch,
              selected: false, 
              selectedCustomer:false,
              isHide :false// Add selected property with the value false
            }));
  
            segArray.push({
              id: job.id,
              name: job.name,
              area_pixel: job.area_pixel,
              annotationPolygon: job.annotationPolygon,
              confidence: job.confidence,
              BoundingBoxFloat: job.BoundingBoxFloat,
              BoundingBoxInt: job.BoundingBoxInt,
              perimeter:job.perimeter,
              SelectSwatchJob: userSwatch,
            });
          }
        });
  
        arrays.push({
          id: item.id,
          name: item.name,
          labels: item.labels,
          icon: item.icon,
          segmentation: segArray,
          allSwatches: [],
        });
      });
    }
  
    sessionStorage.setItem("MasterArrays", JSON.stringify(arrays));
    getUniqueSwatch(arrays,masterArray)
   

   
  };

  const getUniqueSwatch = (allArrays,masterArray) => {
    allArrays.forEach((item) => {
      let allSwatches = [];
      item.segmentation.forEach((seg) => {
        seg.SelectSwatchJob.forEach((swatch) => {
          let existingSwatch = allSwatches.find((existing) => existing.swatchId === swatch.swatch.data.id);
          if (!existingSwatch) {
            allSwatches.push({
              swatchId: swatch.swatch.data.id,
              palletName:swatch.swatch.data.attributes.name,
              palletImage: swatch.swatch.data.attributes?.image_s3,
              brand:      swatch?.swatch?.data?.attributes?.brand?.data?.attributes?.name,
              palletstyle: swatch?.swatch?.data?.attributes?.swatch_style?.data?.attributes?.name,
              label:item?.labels[0]?.name,
               selected:false,
               customerSelected:false,
               name:""
               
            });
          }
        });
      });
      item.allSwatches = allSwatches;
    });
    console.log("unique Array", allArrays);
    if(allArrays && allArrays.length === 0){
      dispatch(addMasterArray(masterArray));
      dispatch(samLoading({ samLoading: false }));
    }else{
      dispatch(addMasterArray(allArrays));
      dispatch(samLoading({ samLoading: false })); 
    }
  };
  
  
  
  return <div></div>;
};

export default GetJob;

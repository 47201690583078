import "./App.css";
import { Container } from "@chakra-ui/react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../src/component/Header/Header";
import Footer from "../src/component/Footer/Footer";
import { useNavigate } from "react-router-dom";
function App() {
  return (
    <>
     {/* <Header /> */}
        
      <Outlet />
      {/* <Footer /> */}
      
    </>
  );
}

export default App;

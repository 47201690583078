import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSegment: [], // Change selectedSegment to an array
};

const BreadScrumbSlice = createSlice({
  name: "breadCrumbs",
  initialState,
  reducers: {
    addBreadCrumb: (state, action) => {
      state.selectedSegment.push(action.payload);
    },
    removeBreadCrumb: (state, action) => {
      const index = state.selectedSegment.findIndex(
        (breadcrumb) => breadcrumb.segment === action.payload.segment
      );
      if (index !== -1) {
        state.selectedSegment.splice(index, 1);
      }
    },
    // updateBreadCrumb: (state, action) => {
    //   const { segment, updatedBreadcrumb } = action.payload;
    //   const index = state.selectedSegment.findIndex(
    //     (breadcrumb) => breadcrumb.segment === segment
    //   );
    //   if (index !== -1) {
    //     state.selectedSegment[index] = {
    //       ...state.selectedSegment[index],
    //       ...updatedBreadcrumb,
    //     };
    //   }
    // },
    selectBreadCrumb: (state, action) => {
      state.selectedSegment.forEach((segment, index) => {
        segment.selected = index === action.payload;
      });
    },
    getAllBreadCrumbs: (state) => {
      // This action returns all the values in selectedSegment
      return state.selectedSegment;
    },
  },
});

export const {
  addBreadCrumb,
  removeBreadCrumb,
  updateBreadCrumb,
  selectBreadCrumb,
  getAllBreadCrumbs,
} = BreadScrumbSlice.actions;

export default BreadScrumbSlice.reducer;

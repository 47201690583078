import React ,{useEffect, useState, }from 'react'
import  ReAnnotationModal from "./ReAnnotationModal";
import { useDispatch, useSelector } from 'react-redux';
 const ReAnnotation = ({isOpenReAnnote,resetReAnnotation}) => {
    const dispatch = useDispatch();
    const[isOpen, setIsOpen]= useState(false);

    useEffect(()=>{

        if(isOpenReAnnote){
            setIsOpen(true)
        }
    },[isOpenReAnnote])

// close Modal 
const handleCloseModal=()=>{
    setIsOpen(false)
    resetReAnnotation()
}

  return (
    <>



<ReAnnotationModal
isOpen={isOpen}
onClose={handleCloseModal}
/>
    </>
   
  )
}

export default ReAnnotation;

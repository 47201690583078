import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import{getProjectId} from "../../slices/ProjectSlice";
import { fabric } from "fabric";
import "../../ImageView/ImageView.css"
import "./CustomerRight.css"
import {getPalletLoading} from "../../slices/AlertSlice"
import {getCustomerViewPallet,getCustomerCurrentPallet} from "../../slices/CustomerSlice/customerViewpalletSlice"
import SamLoading from "../../Alert/SamLoading";
import {getCanvasSize} from "../../slices/windowSizeSlice/WindowResolution.js"
import CustomerTools from './CustomerTools.js';
const CustomerRight = () => {
    const dispatch= useDispatch()
    const canvasRef = useRef(null);

    const getProjectImage=useSelector(getProjectId);
  const getLoading = useSelector(getPalletLoading)
  const getCanvasSizes= useSelector(getCanvasSize)
    
    useEffect(() => {
      if(getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null

        ){
          var canvas = new fabric.Canvas("customer-layer", {
            selectionBorderColor: 'green',
            width: getCanvasSizes.canvasWidth, // Set canvas width
              height:getCanvasSizes?.canvasHeight 
          });
        
          // setCanvas(canvas); // Remove this line
          canvasRef.current = canvas; 
          const imgGroup = new fabric.Group([], { selectable: false, name: 'imageGroup',visible: true });
          canvasRef.current.add(imgGroup);

        }
      
       

    
       }, [getCanvasSizes]); 

     

       useEffect(()=>{
   
          if(getProjectImage&&
              getProjectImage.base64Image&&
              canvasRef.current){
  
                let image = getProjectImage?.base64Image;
          const encodedImage = "data:image/png;base64," + image;
          // Decode the base64 image
          const decodedImage = atob(encodedImage.split(",")[1]);
          // Convert the decoded data to a Uint8Array
          const uint8Array = new Uint8Array(decodedImage.length);
          for (let i = 0; i < decodedImage.length; i++) {
            uint8Array[i] = decodedImage.charCodeAt(i);
          }
          // Create a Blob from the Uint8Array
          const blob = new Blob([uint8Array], { type: "image/png" });
          // Create an object URL from the Blob
          let imageUrl = URL.createObjectURL(blob);
      
          var img = new window.Image();
          img.src = imageUrl;
          img.onload = () => {}
    fabric.Image.fromURL(imageUrl, function (img) {
      img.scaleToWidth(canvasRef.current.width); // Set your desired width
      img.scaleToHeight(canvasRef.current.height);
      canvasRef.current.setBackgroundImage(imageUrl, canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
        scaleX: canvasRef.current.width / img.width,
        scaleY: canvasRef.current.height / img.height,
        width:canvasRef.current.width,
        height:canvasRef.current.height,
        originX: "left",
        originY: "top",
      
        });
      
      canvasRef.current.requestRenderAll();
        
    })
  
              }
  
              
            
       },[canvasRef,getProjectImage]);


       // images onn canvs
       const getCustomerViewPallets=useSelector(getCustomerViewPallet)
       const getCustomerCrrentPallete=useSelector(getCustomerCurrentPallet)
      //  useEffect(()=>{
      //   //
      //   if( canvasRef.current&&
      //     getCustomerCrrentPallete &&
      //     getCustomerCrrentPallete.length>0

      //       ) {
              
      //         //segmentName

      //         getCustomerCrrentPallete.map(item=>{
      //           let url=item.SegmentImagePath
      //           fabric.Image.fromURL(url, function(img){
      //             var scaleX = canvasRef.current.width / img.width;
      //             var scaleY = canvasRef.current.height / img.height;
      //             var oImg = img.set({ scaleX: scaleX, scaleY: scaleY})
      //             oImg.set({
      //               name: item.segmentName,
      //               swatchId: parseInt(item?.swatchId, 10),
      //               visible: true,
      //             });
      //             canvasRef.current.add(oImg);
      //             canvasRef.current.requestRenderAllBound();
      //           })
      //         })

      //   }
      
      //  },[getCustomerCrrentPallete, canvasRef])
       useEffect(()=>{
        //
        if( canvasRef.current&&
          canvasRef.current._objects &&
          canvasRef.current._objects.length > 0&&
          getCustomerCrrentPallete &&
          getCustomerCrrentPallete.length>0

            ) {
              
              const allObjects = canvasRef.current.getObjects();

              getCustomerCrrentPallete.forEach(item=>{

                if (item.SegmentImagePath != null && item.segmentName != null) {
                  let existingImage = false;
                  for (let i = 0; i < allObjects.length; i++) {
                    if(allObjects[i].name === item.segmentName){

                      let url=item.SegmentImagePath
                      fabric.Image.fromURL(url, function(img){
                        var scaleX = canvasRef.current.width / img.width;
                        var scaleY = canvasRef.current.height / img.height;
                        var oImg = img.set({ scaleX: scaleX, scaleY: scaleY})
                        oImg.set({
                          name: item.segmentName,
                          swatchId: parseInt(item?.swatchId, 10),
                          visible: true,
                        });
                        canvasRef.current.remove(allObjects[i]);
                        canvasRef.current.add(oImg);
                        canvasRef.current.requestRenderAllBound();
                      });
                      existingImage = true;
                      break;
                    }
                    
                  }
                  if (!existingImage) {
                    let url=item.SegmentImagePath
                    fabric.Image.fromURL(url, function(img){
                      var scaleX = canvasRef.current.width / img.width;
                      var scaleY = canvasRef.current.height / img.height;
                      var oImg = img.set({ scaleX: scaleX, scaleY: scaleY})
                      oImg.set({
                        name: item.segmentName,
                        swatchId: parseInt(item?.swatchId, 10),
                        visible: true,
                      });
                      canvasRef.current.add(oImg);
                      canvasRef.current.requestRenderAllBound();
                    });
                  }
                }
                else if(item.SegmentImagePath === null && item.segmentName != null){
                  const existingImage = allObjects.find((obj) => obj.name === item.segmentName);
                  if (existingImage) {
                    canvasRef.current.remove(existingImage);
                    canvasRef.current.requestRenderAll();
                  }
                }
              })
        }

       },[getCustomerCrrentPallete, canvasRef])
  return (
    <div className='customer-project-img '>

    <div className="ai-right-wrapper-body Cursor  project-layer-images">
   
    <canvas  id="customer-layer" className="dzi-van"  width="1023" height="592"  ref={canvasRef}></canvas>
    </div>
    {getLoading &&<SamLoading/>}

    <div className='select-toolbar-section'>
    
    <CustomerTools 
    // compare={handlecompare}
    
    // canvasMode={changeCanvasMode}
    // report={handleReport}
    // screenShot={captureScreenshot}
    />
    </div>

    </div>
  )
}

export default CustomerRight
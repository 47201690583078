import React, { useState, useRef, useEffect } from "react";
import "./Leftsection.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";

import AddSegment from "../addSegments/AddSegment";

import { Checkbox, CheckboxGroup } from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import {
  getCloseModal,
  closeModals,
  alertVisible,
} from "../../slices/AlertSlice";
import {
  selectAllItems,
  removeSegmentFromMAsterArray,
} from "../../slices/MasterArraySlice";

import {
  removeAllPalletImages,
  addSelectedPalletImag,
} from "../../slices/TransparentPalletImgSlice";

import { addTabPannel } from "../../slices/TabPannelSlice";
import ModalToAdd from "../../Add_Edit_Pallet/ModalToAdd";
import SelectSegment from "./SelectSwatchTabs/SelectSegment";
import SelectSwatch from "./SelectSwatchTabs/SelectSwatch/SelectSwatch";

import SelectedPalletModelAPI from "../CallModelApi/SelectedPalletModelAPI";
import UploadSegmentedImage from "../../Add_Edit_Pallet/UploadSegmentedImage";

import CallApi from "../../Add_Edit_Pallet/CallApi";

import { addViewPalletInfo } from "../../slices/ViewPalletInfoSlice";
import SelectedPalletInfo from "../../Tabs/SelectedPalletInfo";
import { getPalletLoading } from "../../slices/AlertSlice";

import {
  onHover,
  onAllHover,
  deleteSegment,
  samLoading,
  deleteLoading,
} from "../../slices/AlertSlice";

import MediaLibrary from "./SelectSwatchTabs/media/MediaLibrary";
import DeleteSegmentAlert from "../../Tabs/DeleteSegmentAlert";

import {
  selectAllSegments,
  removeSegmentFromSegments,
} from "../../slices/AllSegmentSlice";

import { deleteMasterJob } from "../../slices/MasterJobs";
import DeleteSegment from "../../Tabs/DeleteSegment";
import UpdateDeletedSegmentToDb from "../../Tabs/UpdateDeletedSegmentToDb";

import { getProjectId } from "../../slices/ProjectSlice";
import {
  addGridMultiSelect,
  updateGridMultiSelect,
} from "../../slices/gridView/MultiSelectionSegement";

import SelectPallet from "../leftsection/SelectSwatchTabs/SelectPallet";
import EachSegment from "./EachSegment/EachSegment";
import { getIsMouseClick } from "../../slices/MouseEvent/MouseEventSlice";
import { removeGridMultiSelect } from "../../slices/gridView/MultiSelectionSegement";
import {
  editAnnotation,
  removeEditSegment,
} from "../../slices/EditSegment/EditSegment";
import { addSelectedPallete } from "../../slices/SelectedPalletdSlice";

import {
  getStartAnnotation,
  getSaveDataToDB,
  getSelectedswatchStart,
  selectedPalletStart,
} from "../../slices/EditSegment/EditSegment";

import ComboForm from "./Combination/ComboForm";
import {
  getSaveCombination,
  removeCombinationSwatch,
} from "../../slices/combinationSwatch/CombinationSwatchSlice";
import GetAllCombination from "../../CombinationSwatch/GetALlCombination";
import {
  removeRenderCombination,
  GetCombinationSwatch,
  getShowCombo,
  showComboTab,
} from "../../slices/combinationSwatch/CombinationSwatchSlice";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import {getSwitchHoverLayer} from "../../slices/gridView/MultiSelectionSegement"

 import Shadows from "./SelectSwatchTabs/shadow/Shadows"
import Shimmer from "./Shimmer";
import {getBackToMainPannel, moveToMainPannel} from "../../slices/MouseEvent/MouseEventSlice"
import {resetEditAnnotation} from "../../slices/EditSegment/EditSegment"

import {removeViewPallete} from "../../slices/ViewPalletInfoSlice"
import UploadImage from "./UploadDesignImage/UploadImage"

import { updateMasterJob } from "../../slices/MasterJobs";
import {getCustomer} from "../../login/AuthSlice"

import {updateSwatchAllSeg} from '../../slices/AllSegmentSlice' 
import {updateSwatchMasterArray} from "../../slices/MasterArraySlice"
import { palletLoading } from "../../slices/AlertSlice";
import { compareProject } from "../../slices/AlertSlice";
import {addCurrentPallete} from "../../slices/CurrentSelectedPalleteSlice"

import {getPopHover,resetReGenerateSwatch} from "../../slices/popHover/PopHoverSlice"
import {selectAllcheckBox} from "../../slices/gridView/MultiSelectionSegement"

import {OpenShareLink} from "../../slices/shareLinkSlice/ShareLinkSlice"

import {getTabIndex,addTabIndex} from "../../slices/mediaLibrary/MediaLibrarySlice"

import {deleteCurrentPallet} from "../../slices/CurrentSelectedPalleteSlice"

const Leftsection = () => {

  const [segmentName, setSegmenatName] = useState(null);
  const [isOpenAction, setIsOpenAction] = useState(false);
  const dispatch = useDispatch();
  const itemRef = useRef({});
  const [checkedItems, setCheckedItems] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [activeCombo, setActiveCombo] = useState(false);

  const HoverRef =useRef(null);
   const [labelName, setLabelName] = useState();
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch5"));
  const [palletImagePath, setpalletImagePath] = useState();
  const [flagDemo, setflagDemo] = useState();
  const [palletId, setpalletId] = useState();
  const [palletName, setpalletName] = useState();
  const [isSelectSegmentTab, setIsSelectSegmentTab] = useState(false);
  const [isSelectSwatchTab, setIsSelectSwatchTab] = useState(false);
  const [isSelectMainTab, setIsSelectMainTab] = useState(true);
  const [isopen, setIsopen] = useState(false);
  const [isCallAPIopen, setIsCallAPIopen] = useState(false);
  const isMounted = useRef(true);
  const uploadDatas = JSON.parse(sessionStorage.getItem("uploadData"));
  const [uploadData, setUploadData] = useState(null);
  const [isViewPallet, setIsViewPallet] = useState(false);
  const [isAddSegments, setIsAddSegments] = useState(false);

  const getMasterAray = useSelector(selectAllItems);
  const [isOpen, setIsOpen] = useState(false);
  const [indexs, setIndexs] = useState();
  const [isOpenDelete, setIsOpenDelete] = useState();
  const [isProjectDetails, setIsProjectDetails] = useState(false);
  const [showMedia, setshowMedia] = useState(false);
  const [selectedSegName, setSelectedSegName] = useState();
  const [isMEdiaLibrary, setIsMediaLibrary] = useState(false);
  const getAllSegments = useSelector(selectAllSegments);
  const [showCombo, setshowCombo] = useState(false);
  const [showComboForm, setshowComboForm] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const[isgetCombo, setIsgetCombo]= useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0);



  

  const getStartAnnotations = useSelector(getStartAnnotation);
// user Id
const getUserDetail= useSelector(getCustomer)
  useEffect(() => {
    setshowCombo(false);
    setshowComboForm(false);
  }, [getUserDetail]);

  // add segment 
  const handleAddSegment = () => {
    if(isAddSegments){
      setIsAddSegments(false);
      setIsSelectMainTab(true);
      setIsAddSegmentactive(false);
      setIsSelectSwatchTab(false)
      setIsMediaLibrary(false);
      setShowShadow(false);
    
    }else{
      setIsAddSegments(true);
      setIsAddSegmentactive(true);
      setIsSelectMainTab(false);
      setIsSelectSwatchTab(false)
      setIsMediaLibrary(false);
      setShowShadow(false);
      setIsShadowActive(false)
    }
   

    dispatch(removeEditSegment());
  };

  

  // call API coponent
 

  
  // initially if no data is available the loading is active

  // after selecting label Name  show label
  const handleLabellName = (label_name) => {
    //console.log("label_name", label_name);
    setIsSelectSegmentTab(false);
    setIsSelectSwatchTab(true);
    setLabelName(label_name);
  };

  // handle show tab pannel
  const getSwitchHoverLayers=useSelector(getSwitchHoverLayer)
  useEffect(()=>{
    if(getSwitchHoverLayers){
      handlePannel()
    }
  },[getSwitchHoverLayers])
  

  // on click master Label Name
  const handlePannel = () => {
    setIsMediaLibrary(false)
    setISMediaActive(false)
    setIsShareActive(false)
    dispatch(resetEditAnnotation())
    setIsShadowActive(false)
    setselectSeg(false)
    dispatch(removeGridMultiSelect());  // reset multi select grid
    dispatch(editAnnotation({ isEditAnnotation: false }));
    dispatch(removeRenderCombination());
    dispatch(removeCombinationSwatch());
    setIsSelectMainTab(true);
    setIsSelectSegmentTab(false);
    setIsSelectSwatchTab(false);
    setIsAddSegments(false);
    setshowCombo(false);
    setshowComboForm(false);
setShowShadow(false)
    setCheckedItems([]);
   
  };

  //after  upload the data reset data

  const handleReset = () => {
    setUploadData(null);

    dispatch(removeGridMultiSelect())
  };
 const getPopHovers= useSelector(getPopHover)
 // regenerate 
  useEffect(()=>{
    
    if(getPopHovers &&
      getPopHovers.reGenSwatch &&
      getPopHovers.reGenSwatch.palletImagePath &&
      getPopHovers.reGenSwatch.palletId 

      ){
        handleswatchAction(getPopHovers.reGenSwatch)
    }
  },[getPopHovers])
  const [swatchData, setSwatchData] = useState();
  const handleswatchAction = (data) => {
    
   console.log("Left section", data);
    setIsSelectMainTab(true);
    setIsSelectSegmentTab(false);
    setIsSelectSwatchTab(false);
    //callAPi(data)
    setSwatchData(data);
    setIsCallAPIopen(true);
    setpalletImagePath(data.palletImagePath);
    setIsShadowActive(false)
   // dispatch(resetReGenerateSwatch())
  };


  // after delete switch to main tab
  const handleAfterDelete=()=>{
    setIsSelectMainTab(true);
    setIsSelectSegmentTab(false);
    setIsSelectSwatchTab(false);
    setIsShadowActive(false)
  }

  // reset data call API component
  const handleResetData = (data) => {
    
   
      if(data){
        setUploadData(data);

         // remove grid slice 
    dispatch(removeGridMultiSelect())
    //update master Job
    dispatch(
      updateMasterJob({
       // masterName:data.masterName,
        swatch: data.swatchId,
        segmentImage: data.multiImage,
        userId:getUserDetail.userId
        
      })
    );


    // update all segment data
    dispatch(updateSwatchAllSeg({
      segmentName:data.segmentName,
      swatchId: data.swatchId,
      palletImage:data.palletUrl,
      swatchImage:data.multiImage

    }))

    //update in master Array
    dispatch(updateSwatchMasterArray({
      labelName:data.masterName,
       segmentName:data.swatchName,
       swatchId:data.swatchId,
       palletImage:data.palletUrl,
       swatchImage:data.multiImage,
       brand:data.brand,
       label:data.label,
       palletStyle:data.palletStyle,
       palletName:data.palletName
      
    }))
    //update current pallet 

    dispatch(addCurrentPallete({
      swatchId:  data.swatchId,
      palletImage:data.palletUrl,
      swatchImage:data.multiImage,
    }))
    dispatch(palletLoading({ palletLoading: false }));
    dispatch(
      compareProject({
        isCompare: true,
      })
    );


    // activate sava button 
    dispatch(selectedPalletStart(
      {
        isSelectPalletStart:true 
      }
    ))
      }
    
    setSwatchData(null);
    setpalletImagePath(null);
    setflagDemo(null);
    setpalletId(null);
    setpalletName(null);
    setIsCallAPIopen(false);


   
    
  };

  // Hover all poygon
  const handleHoverAllLabels = (names) => {
    console.log("hover name", names);
    let allPolgon = [];
    let polyName = [];
    if (getMasterAray && getMasterAray?.master.length > 0) {
      getMasterAray.master.map((item) => {
        if (item.name === names) {
          item.segmentation.map((segment) => {
            let allPoly = segment.annotationPolygon
              .split(",")
              .map((value) => parseInt(value.trim(), 10));

            polyName.push(segment.name);
            allPolgon.push(allPoly);
          });
        }
      });
      dispatch(
        onAllHover({
          isAllHover: true,
          segmentName: names,
          allpolygon: allPolgon,
          allPolygonNames: polyName,
        })
      );
    }
  };
  // remove all polygon'
  const handleRemoveAllHover = () => {
    dispatch(
      onAllHover({
        isAllHover: false,
        segmentName: null,
      })
    );
  };
  // handle view pallet
  const handleViewPallet = () => {
    setIsViewPallet(true);
  };

  const handleCloseViewPallet = () => {
    dispatch(removeViewPallete())
    setIsViewPallet(false);
  };

  const handleNoViewPallet = () => {
    alert("No swatch is added");
  };

  // show media library
  const [isMediaActive, setISMediaActive]= useState(false) 
  const [isShareActive, setIsShareActive]= useState(false) 
  const handleshowMedia = () => {
    
    setISMediaActive(true)
    setIsShareActive(false)
      setIsMediaLibrary(true);
      setIsSelectMainTab(false);
      setIsSelectSwatchTab(false);
      setIsShadowActive(false)
      setselectSeg(false)
      setIsSelectSegmentTab(false);
      setIsAddSegments(false);
      setshowCombo(false)
      setshowComboForm(false)
      
      
      
    
  };
  const [deleteLabelName, setDeleteLabelName] = useState();
  const [deleteSegName, setDeleteSegName] = useState();

  const handleOpenDeleteModal = (labelName, segName) => {
    setIsOpenDelete(true);
    setDeleteLabelName(labelName);
    setDeleteSegName(segName);
  };

  // handle close delete segment modal
  const handleDeleteCloseModal = () => {
    setIsOpenDelete(false);
  };
  const [isDelete, setIsDelete] = useState(false);

  //delete segemntation if yes
  const handleCloseModalSegmentDelete = () => {

    setIsOpenDelete(false);
    setIsDelete(true);
    dispatch(
      removeSegmentFromMAsterArray({
        segment: deleteSegName,
        label: deleteLabelName,
      })
    );

    dispatch(
      removeSegmentFromSegments({
        label: deleteLabelName,
      })
    );
    dispatch(
      deleteSegment({
        isDeleteSegment: true,
      })
    );
    dispatch(
      deleteMasterJob({
        labelName: deleteLabelName,
      })
    );
    dispatch(
      deleteLoading({
        isDeleteSegmentLoading: true,
      })
    );
    // delete current pallete slice
     dispatch(deleteCurrentPallet({
      labelName: deleteLabelName,
     }))
  };

  // handle updated segment
  const [JobId, setJobIds] = useState();
  const [updatedJob, setUpdatedJob] = useState();
  const CurrentProjects = useSelector(getProjectId);
  const[ projectId, setProjectIds ] =useState(CurrentProjects.projectId)
  const handleUpdatedSegment = (updatedSeg) => {
    setUpdatedJob(updatedSeg);
    setJobIds(CurrentProjects.jobId);

    setIsDelete(false);
  };

 

  // after selecting multi select
  const handleOpenSelectSwatch = (selectseg, labelName) => {
    setIsSelectMainTab(false);
    setIsSelectSwatchTab(true);
    setLabelName(labelName);
  };

  //display segemnt name on select swatch
  const [selectedSegData, setSelectedSegmentData] = useState();
  const handleDynamicSegmentName = (segName, label_Name, selectedSeg) => {
   
    setSelectedSegName(segName);
    setIsSelectMainTab(false);
    setIsSelectSwatchTab(true);
    setLabelName(label_Name);
    setSelectedSegmentData(selectedSeg);
    setShowShadow(false)
  };

  // on mOuse Click
  const getIsMouseClicks = useSelector(getIsMouseClick);

  useEffect(() => {
    if (
      getIsMouseClicks &&
      getIsMouseClicks.isOnMouseUp &&
      getIsMouseClicks.labelName
    ) {
      setLabelName(getIsMouseClicks?.labelName);
      setSelectedSegName(getIsMouseClicks?.segmentName);
      setIsSelectSwatchTab(true);
      setIsSelectMainTab(false);
      addSelectedSegmentToRedux(
        getIsMouseClicks?.labelName,
        getIsMouseClicks?.segmentName
      );
      setShowShadow(false)
    }
  }, [getIsMouseClicks]);

  //console.log("allSegmentsArray", getAllSegments);
  const addSelectedSegmentToRedux = (labelName, segName) => {
    if (
      getAllSegments &&
      getAllSegments.allSegmentsArray &&
      getAllSegments.allSegmentsArray.length > 0
    ) {
      dispatch(
        alertVisible({
          isAlertVisible: false,
        })
      );
      getAllSegments.allSegmentsArray.map((item) => {
        if (item.name === segName) {
          //
          //console.log(item);
          dispatch(addSelectedPallete({ selectedPallete: [item] }));
          dispatch(removeAllPalletImages());
          dispatch(
            addSelectedPalletImag({
              segmentName: segName,
              selectedPlaletUrl: null,
              s3BucketImage: null,
              SwatchId: null,
            })
          );
          dispatch(
            addTabPannel({
              pannelTab: "Tab5",
            })
          );
        }
      });
    } else {
      dispatch(
        alertVisible({
          isAlertVisible: true,
        })
      );
    }
  };

  //close Add Segment tab
  const handleCloseAddSegment = () => {
    setIsSelectMainTab(true);
    setIsSelectSegmentTab(false);
    setIsAddSegments(false);
    setShowShadow(false)
  };

  //show combo

  const getShowCombos = useSelector(getShowCombo);

  useEffect(() => {
    if (getShowCombos) {
      // setActiveCombo(true)
      setshowCombo(true);
      setIsSelectMainTab(false);
      setshowComboForm(false);
      setIsSelectSwatchTab(false);
      setShowShadow(false)
    } else if (!getShowCombos) {
      setActiveCombo(false);
      setshowCombo(false);
      // setIsSelectMainTab(true)
    }
  }, [getShowCombos]);

  // show combination form
  const getSaveCombinations = useSelector(getSaveCombination);

  useEffect(() => {
    if (getSaveCombinations) {
      setIsSelectMainTab(false);
      setIsSelectSegmentTab(false);
      setIsMediaLibrary(false);
      setshowComboForm(true);
      setIsSelectSwatchTab(false);
      setShowShadow(false)
    }
  }, [getSaveCombinations]);

  // set projectid for combo
  const [combinationProject, setCombinationProject] = useState();
  useEffect(() => {
    if (CurrentProjects && CurrentProjects.projectId) {
      let project = parseInt(CurrentProjects.projectId, 10);
      setCombinationProject(project);
    }
  }, [CurrentProjects]);

  // after save combination switch to main tab
  const GetSaveCombination = useSelector(GetCombinationSwatch);
  useEffect(() => {
    if (GetSaveCombination && GetSaveCombination.isRenderComninationFinish) {
      setIsSelectMainTab(true);
      setIsSelectSegmentTab(false);
      setIsMediaLibrary(false);
      setshowComboForm(false);
      setIsSelectSwatchTab(false);
      setShowShadow(false)

      setIsgetCombo(true)// calling get all combinations graphql query
    }
    
  }, [GetSaveCombination]);

 const getLoading = useSelector(getPalletLoading);

  // const [getLoading , setGetLoading]= useState(true)
  // const [getLoading, setGetloading]=useState(true)
  
  const [selectSeg, setselectSeg] = useState(false);

  // handle main check box
  const handleCheckbox=(selectedCheck, intermediate)=>{

setCheckedItems(selectedCheck)

if(intermediate){
  setIsIndeterminate(intermediate)
  setselectSeg(true);
} 


  }


 const [selectAll, setSelectAll]= useState(false)
 const[selectAllMasterName, setSelectAllMasterName]= useState(null)
  const handleSelectall=(val,allseg, masterName) => {
    console.log("val",allseg)
    if(allseg.length>0){
      allseg.forEach(item=>{
        dispatch(addGridMultiSelect({
          gridMultiSelect: item,
          labelName: masterName
        }));
      })
    }
    
    
    setSelectAll(true)
    setSelectAllMasterName(masterName)
    setIsIndeterminate(false)
    setselectSeg(true)
  }

  
  const handleSwatchmodel=(allSegData,labelNAme) => {
    

    //make it uncheck all checkbox 
    dispatch(selectAllcheckBox({
      selectAll:true
    }))
    setSelectAll(false)
    setSelectAllMasterName(null)
    setIsSelectSwatchTab(true)
    setIsSelectMainTab(false)
    setShowShadow(false)
    setLabelName(labelNAme)
    setSelectedSegmentData(allSegData)
    setselectSeg(false)
  }


  // handle Reaanottaion open A ddSegments
  const[reSegName, setReSegName]=useState();
  const[reMasterName, setReMasterName]=useState();

  const handleReAnnotation=(segName, masterName)=>{
    setIsSelectMainTab(false)
    setIsSelectSegmentTab(false)
    setIsMediaLibrary(false)
    setshowComboForm(false)
    setIsSelectSwatchTab(false)
    setIsAddSegments(true)
    setShowShadow(false)
  setReSegName(segName)
  setReMasterName(masterName)
  }
 

  //handle reset projectids and getAllCombination
const handleResetComboData=()=>{
  setCombinationProject(null)
  setIsgetCombo(false)
}

// handleShow Shadow

const [isShadowactive, setIsShadowActive]= useState(false)
const [isAddSegmentactive, setIsAddSegmentactive]= useState(false)
const handleShowShadow=()=>{
  setISMediaActive(false)
  setIsShareActive(true)
    setIsShadowActive(true)
    setShowShadow(true)
    setIsSelectMainTab(false)
      setIsSelectSegmentTab(false)
      setIsMediaLibrary(false)
      setshowComboForm(false)
      setIsSelectSwatchTab(false)
      setIsAddSegments(false)
      setIsAddSegmentactive(false)

}

const handleBackFromSelectSwatch=useSelector(getBackToMainPannel)
useEffect(()=>{
  if(handleBackFromSelectSwatch){
    setIsSelectMainTab(true)
    setIsSelectSegmentTab(false)
      setIsMediaLibrary(false)
      setshowComboForm(false)
      setIsSelectSwatchTab(false)
      setIsAddSegments(false)
      dispatch(moveToMainPannel({
        isBAckToPannel:false  
    }))
  }
},[handleBackFromSelectSwatch])

const handleAllCheckBox=()=>{

}

// share link Pop up
const handleShareLink=()=>{
  dispatch(OpenShareLink({
    openShareLinkModal:true
  }))
}

// change tab basd on media library click
 const getTabIndexs= useSelector(getTabIndex);
 
 useEffect(()=>{
  
  if(getTabIndexs.istabIndex){
    setOpenTab(getTabIndexs.tabIndex)
   

  }
 },[getTabIndexs])

 const [openTab, setOpenTab]= useState(null)
 // initialze the tab index
 useEffect(()=>{
  setOpenTab(0)
 },[])
 useEffect(()=>{
  console.log("opentab",openTab)
  if(getTabIndexs.istabIndex){
  
    setISMediaActive(false)
    setIsShareActive(false)
      setIsShadowActive(false) 
      setShowShadow(false)
      setIsSelectMainTab(true)
        setIsSelectSegmentTab(false)
        setIsMediaLibrary(false)
        setshowComboForm(false)
        setIsSelectSwatchTab(false)
        setIsAddSegments(false)
        setIsAddSegmentactive(false)
        dispatch(addTabIndex({
          tabIndex:null,
          istabIndex:false
        }))

  }  
 },[openTab,getTabIndexs])

  return (
    <div>
      {!getLoading ? (
        <Tabs
          className="product-layer"
          variant="soft-rounded"
          align="left"
          orientation="vertical"
          // index={openTab}
          >
            
          <div className="sidebar-section">
            <div className="sidebar-hide-icon">
              <i class="bi bi-text-indent-right"></i>             
            </div>

            <TabList className="seg-tab tab-labels"
            
            >
              {getMasterAray &&
                getMasterAray.master &&
                getMasterAray.master.length > 0 &&
                getMasterAray.master.map((item) => {
                  if (item.name !== "Shadow" &&item.segmentation.length>0) {
                  return (
                   
                    (
                      <Tab
                        className={`segments-grid dzi-${item.id}`}
                        onMouseOver={(event) => {
                          const targetValue = event.target.value;
                          handleHoverAllLabels(event.target.value, targetValue);
                        }}
                        onMouseLeave={handleRemoveAllHover}
                        onClick={handlePannel}
                        value={item.name}>
                          <div
                            onMouseOver={(event) => {
                              const targetValue = event.target.value;
                              handleHoverAllLabels(event.target.value, targetValue);
                            }}
                            onMouseLeave={handleRemoveAllHover}
                            onClick={handlePannel}
                            value={item.name}
                          
                          >

                          <img className="seg-image" src={item.icon}
                         onMouseEnter={(event) => {
                          const targetValue = event.target.value;
                          handleHoverAllLabels(event.target.value, targetValue);
                        }}
                        onMouseLeave={handleRemoveAllHover}
                        onClick={handlePannel}
                        value={item.name}
                          ></img>
                        <div className="label-title-outer seg-window-title"
                         onMouseEnter={(event) => {
                          const targetValue = event.target.value;
                          handleHoverAllLabels(event.target.value, targetValue);
                        }}
                        onMouseLeave={handleRemoveAllHover}
                        onClick={handlePannel}
                        value={item.name}
                        >
                          <h2 className="label-title" key={item.name}
                         
                          >
                            {item.name}
                          </h2>
                        </div>
                            </div>
                        
                      </Tab>
                     
                    )
                  )} else{
                    return null
                  }
                })}
            </TabList>
          </div>
          <TabPanels className="seg-contant-tabpanel">
            {getMasterAray &&
              getMasterAray.master &&
              getMasterAray.master.length > 0 &&
              getMasterAray.master.map((master, masterIndex) => {
                return (
                  master.segmentation &&
                  Array.isArray(master.segmentation) &&
                  master.segmentation.length > 0 && (
                    <TabPanel>
                     <>
                        <div className=" select-checkbox-seg" key="media">
                          <div className="project-image-select-btn">
                            <div className="select-checkbox-icons">
                              <Checkbox
                                className="all-select-checkbox"
                                isChecked={checkedItems}
                                isIndeterminate={isIndeterminate}
                                onChange={(e) =>
                                   handleSelectall(e.target.checked,master.segmentation, master.name)
                                }
                                // onClick={handleAllCheckBox}
                                key="main-key"></Checkbox>
                            </div>

                            {/* show Shadow */}
                            <div
                              
                              className={`view-shadow-icon ${isShadowactive ? "active-icon" : ""}`}
                               onClick={handleShowShadow}
                              >
                              <Tooltip
                                hasArrow
                                label="View Shadows"
                                placement="bottom"
                                bg="gray.300"
                                color="black">
                           <i class="bi bi-back"></i>
                              </Tooltip>
                            </div>
                            <div
                             className={`select-pallets-button ${isAddSegmentactive?"active-icon":""}`}
                              onClick={handleAddSegment}
                              >
                              <Tooltip
                                hasArrow
                                label="Add Segments"
                                placement="bottom"
                                bg="gray.300"
                                color="black">

                                <i class="bi bi-bounding-box-circles"></i>
                              </Tooltip>
                            </div>

                            <div
                              className="select-pallets-button "
                              onClick={handleshowMedia}>
                              <Tooltip
                                hasArrow
                                label="Media Library"
                                placement="bottom"
                                bg="gray.300"
                                color="black">
                                  <div className={`${isMediaActive?"icon-active":""}`}>
                                  <i class="bi bi-collection"></i>
                                  </div>
                               
                              </Tooltip>
                            </div>

                            <div
                              className="select-pallets-button "
                              >
                              <Tooltip
                                hasArrow
                                label="Share"
                                placement="bottom"
                                bg="gray.300"
                                color="black">

                                  <div className={`${isShareActive?"icon-active":""}`} 
                                  onClick={handleShareLink}>
                                  <i  class="bi bi-share"></i>
                                  </div>
                               
                              </Tooltip>
                            </div>



                          </div>
                        </div>

                        {/* Select Palletbutton*/}

                        <div className="select-pallets-button">
                          <SelectPallet 
                          isOpen={selectSeg} 
                          openModalselectSwatch={handleSwatchmodel}
                          />
                        </div>

                        {isSelectMainTab &&  (
                          <div key="main-tab">
                            <div>
                              <EachSegment
                              uniqueSwatch={master.allSwatches}
                                masterSeg={master.segmentation}
                                masterIndex={masterIndex}
                                masterName={master.name}
                                openSelectSwatch={handleDynamicSegmentName}
                                openDeleteModal={handleOpenDeleteModal}
                                openViewPallet={handleViewPallet}
                                noViewPallet={handleNoViewPallet}
                                callMainCheckbox={handleCheckbox}
                                moveLeftReannotation={handleReAnnotation}
                                selectAll={selectAll}
                                selectAllMasterName={selectAllMasterName}
                             />
                            </div>
                          </div>
                        )}

                        {/* select segment */}
                        {isSelectSegmentTab && (
                          <TabPanel>
                            <SelectSegment labelName={handleLabellName} />
                          </TabPanel>
                        )}

                        {/*  select swatch*/}
                        {isSelectSwatchTab && (
                          <TabPanel>
                            <SelectSwatch
                              labelName={labelName}
                              moveToLeftSection={handleswatchAction}
                              selectedSegName={selectedSegName}
                              selectedSegmentData={selectedSegData}
                              moveLeftReannotation2={handleReAnnotation}
                              moveToMainTabs={handleAfterDelete}
                            />
                          </TabPanel>
                        )}

                        {/* media Librray */}

                        {isMEdiaLibrary && (
                          <TabPanel>
                            <MediaLibrary />
                          </TabPanel>
                        )}

                        {isAddSegments && (
                          <TabPanel>
                            <AddSegment
                              moveToLeftSection1={handleCloseAddSegment}
                              reSegName={reSegName}
                              reMasterName={reMasterName}
                           />
                          </TabPanel>
                        )}

                        {showComboForm && (
                          <TabPanel>
                            <ComboForm />
                          </TabPanel>
                        )}

                        {/* shadows */}
                        {showShadow &&
                        <TabPanel>
                          <Shadows/>
                        </TabPanel>
                        
                        }
                      </>
                    </TabPanel>
                  ) 
                  
                  
                  )}
                
                  
               
              )}
              
          </TabPanels>
        </Tabs>
      ) : (
        <Tabs>
          <TabList className="shimmer-tablist">
            <Shimmer/>
           
          </TabList>
        </Tabs>
      )}

      {/* {getLoading && (
        
      ) } */}
      {isCallAPIopen && (
        <SelectedPalletModelAPI
          palletImagePath={palletImagePath}
          swatchData={swatchData}
          resetData={handleResetData}
        />
      )}

          {/* create folder structute in media library  */}
      {/* {uploadData != null && (
        <UploadSegmentedImage
          uploadDatas={uploadData}
          resetData={handleReset}
        />
      )} */}

      {/* view Pallets information */}
      {isViewPallet && (
        <SelectedPalletInfo
          isViewPallet={isViewPallet}
          closeModal={handleCloseViewPallet}
        />
      )}

      {/* Alert to delete the segment */}
      {isOpenDelete && (
        <DeleteSegmentAlert
          isOpen={isOpenDelete}
          onClose={handleDeleteCloseModal}
          deleteSegment={handleCloseModalSegmentDelete}
        />
      )}
      {isDelete && <DeleteSegment deleteSegment={handleUpdatedSegment} />}
      {updatedJob && (
        <UpdateDeletedSegmentToDb updatedJob={updatedJob} JobId={JobId} />
      )}



    </div>



  );
};

export default Leftsection;
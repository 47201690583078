import { createSlice } from "@reduxjs/toolkit";
// const initialState = {
//   currentpallate: [
//     {
//       segmentName: "Window1",
//       s3BucketImage: null,
//       swatchId: null,
//     },
//]


const CurrentSelectedPalleteSlice = createSlice({
  name: "currentpallate",
  // initialState: initialState,
  initialState:{
    currentpallate:[]
  },

  reducers: {
    initialcurrentPallete:(state,action)=>{
    
      state.currentpallate=[...action.payload.currentpallate]

    },
    
    addCurrentPallete: (state, action) => {
      const {  swatchId,palletImage,swatchImage} = action.payload;
      if(swatchImage && swatchImage.length>0){
        swatchImage.forEach((segment)=>{
          const jobIndex = state.currentpallate.findIndex(
            (item) => item.segmentName === segment.class_name
          );
          if (jobIndex!== -1) {
            state.currentpallate[jobIndex].swatchId = swatchId;
            state.currentpallate[jobIndex].SegmentImagePath = segment.output;
          }
        
        });
      }
     
    },

    removeCurrentPallete: (state, action) => {
      state.currentpallate = [];
    },
    // delete and add new
    updateCurrenPallate: (state, action) => {
      const { labelName, swatchId,SegmentImagePath } = action.payload;
      const segIndex = state.currentpallate.findIndex(
        (item) => item.segmentName === labelName
      );
      if (segIndex >= 0) { 

          state.currentpallate[segIndex].swatchId = null;
          state.currentpallate[segIndex].s3BucketImage = null;
          state.currentpallate[segIndex].SegmentImagePath = null;
        
      } else{
        state.currentpallate.push({
          segmentName: labelName,
          swatchId: null,
          SegmentImagePath: null,
          checked:false
        });
      }
    },
    updateSelectedCurrentPallate:(state,action)=>{
      const { segmentName, swatchId,palletImage,checked } = action.payload;
//
      const segIndex = state.currentpallate.findIndex(
        (item) => item.segmentName === segmentName
      );
      if (segIndex >=0) { 
          state.currentpallate[segIndex].swatchId = swatchId
          state.currentpallate[segIndex].checked =checked
          state.currentpallate[segIndex].SegmentImagePath = palletImage
        
      } 
    },
    deleteCurrentPallet:(state,action)=>{
      const { labelName } = action.payload;
      const segIndex = state.currentpallate.findIndex(
        (item) => item.segmentName === labelName
      );
      if (segIndex >= 0) {
        state.currentpallate.splice(segIndex, 1);
      }
    }
   
   
  },
});

export const {
  addCurrentPallete,
  updateSelectedCurrentPallate,
  removeCurrentPallete,
  initialcurrentPallete,
  updateCurrenPallate,
  deleteCurrentPallet
} = CurrentSelectedPalleteSlice.actions;

export const getCurrentablePalettes = (state, action) => state.currentpallate;
export default CurrentSelectedPalleteSlice.reducer;

import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectId } from "../slices/ProjectSlice";
import { addFolderData } from "../slices/FolderSlice";
import axios from "axios";
import {getOpenModal } from "../slices/uploadSwatch/UploadSwatchImage"
const GetParentfolder = ({ backSegmentation, isOpenFolder,backToUploadHome }) => {
  const getProject = useSelector(getProjectId);
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const getOpenModals= useSelector(getOpenModal)
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (
      isOpenFolder &&
      getProject &&
      getProject.projectId != null &&
      isMounted.current &&
      token
    ) {
      //console.log("projectId", getProject.projectId);
      parentFolderId(getProject.projectId);
      return () => {
        isMounted.current = false;
      };
    }
  }, [getProject.projectId, isOpenFolder,token]);

  const parentFolderId = async (projectIds) => {
    const authToken = "Bearer " + token;
    try {
      const response = await axios.get(
        `https://aiadmin.dzinly.org/upload/folders/1?populate=*`,
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );
     // console.log("get Parent folder", response);
      if (response.status === 200) {
        response?.data?.data?.children.map((item) => {
          if (item.name === projectIds.toString()) {
            GetFolderStructure(item.id, projectIds);
          } else {
            return;
          }
        });
      }
    } catch (e) {
      alert("Error in getting parent folder Id: " + e.message);
    }
  };

  const GetFolderStructure = async (folderId, projectId) => {
    const authToken = "Bearer " + token;
    
    try {
      const response = await axios.get(
        `https://aiadmin.dzinly.org/upload/folders/${folderId}?populate=*`,
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );
     // console.log("get Parent folder", response);

      if (response.status === 200) {
        dispatch(
          addFolderData({
            folderData: response.data.data,
            projectId: projectId,
          })
        );

        isMounted.current = true;
       
        if(getOpenModals.isStartSearch ){
          backToUploadHome(response?.data?.data?.id);
        }else{
          backSegmentation(response?.data?.data?.id);
        }
      }
    } catch (e) {
      //console.error("Error in getting parent folder:", e);
      // alert("Error in getting parent folder: " + e.message);
      isMounted.current = true;
      // if(getOpenModals.isStartSearch ){
      //   backToUploadHome();
      // }else{
      //   backSegmentation();
      // }
    }
  };
  return <div></div>;
};

export default GetParentfolder;

import React ,{useState, useEffect}from 'react'
import axios from 'axios';
import { Button, Container, Input } from '@chakra-ui/react'
import {useSelector} from "react-redux"
import "./ComboForm.css";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import {getCurrentablePalettes} from "../../../slices/CurrentSelectedPalleteSlice";

  import{getProjectId} from "../../../slices/ProjectSlice";
  import {aferSaveCombination,showComboTab,addMasterCombo} from "../../../slices/combinationSwatch/CombinationSwatchSlice"
  import { useDispatch} from "react-redux"
  import {palletLoading} from "../../../slices/AlertSlice.js"

  import GetAllCombination from "../../../CombinationSwatch/GetALlCombination.js"
  const ComboForm = () => {
  const dispatch = useDispatch();
    const[comboName, setComboName]=useState()
    const [input, setInput] = useState('')
    const [allSwatches, setallSwatches] = useState()
 const getProjectDetails= useSelector(getProjectId)
 const[projectIds, setProjectIds]= useState()
 const[combinationProject, setCombinationProject]= useState(null)
 const[isgetCombo, setIsgetCombo]= useState(false)

  const handleInputChange = (e) => setComboName(e.target.value)

  const isError = input === ''

  // save combination to DB
  const getAllCurrentPallet= useSelector(getCurrentablePalettes)

  useEffect(()=>{
    
    if(getAllCurrentPallet &&
      getAllCurrentPallet.currentpallate&&
      getAllCurrentPallet.currentpallate.length>0&&
      getProjectDetails&&
      getProjectDetails.projectId){
        let currentPallet=[]
        getAllCurrentPallet.currentpallate.map(item=>{

          
           if(item.swatchId!=null&& item.SegmentImagePath!=null){
              let data={
                swatch: parseInt(item.swatchId, 10),
                SwatchFor:item.segmentName,
                SegmentImagePath:item.SegmentImagePath,
                Status:"false"
              }
              currentPallet.push(data)
            }
        })
        setallSwatches(currentPallet)
    }

  },[getAllCurrentPallet,getProjectDetails])
  const handleSaveCombination=()=>{
    dispatch(palletLoading({ palletLoading: true }));
    callCombinationAPI()
  }

  const callCombinationAPI=async()=>{
    let procjectId=parseInt(getProjectDetails?.projectId, 10);
   
   let callAPI= {
    data: {
      Name:comboName,
      Swatches:allSwatches,
      project:procjectId,
      hide:false
      }
   }
     try{

      const response= await axios.post("https://aiadmin.dzinly.org/api/combinations",
      callAPI,
      
      )
  
   if(response.status===200){
    let id=response?.data?.data?.id
    setCombinationProject(getProjectDetails?.projectId)
    dispatch(palletLoading({ palletLoading: false }));
    //callProjectAPI(id)
    dispatch(aferSaveCombination({
      isRenderComninationFinish:true
    }))
   }
     }catch(error){
      
       dispatch(palletLoading({ palletLoading: false }));
       dispatch(aferSaveCombination({
        isRenderComninationFinish:true
      }))
     }
  }



const callProjectAPI= async(id)=>{
  let procjectId=parseInt(getProjectDetails?.projectId, 10);
 
  let data= {
    data:{
      combinations:id
    }
   }
   try{
   const response= await axios.put("https://aiadmin.dzinly.org/api/projects/"+procjectId,
     data
   )

   if(response.status===200){
     // 
    dispatch(palletLoading({ palletLoading: false }));
    setIsgetCombo(true)
    setProjectIds(procjectId)
    dispatch(aferSaveCombination({
      isRenderComninationFinish:true
    }))

   }
   
   }
catch(error){
  console.log("error", error)
}
}


//handle reset projectids
const handleResetData=()=>{
  setCombinationProject(null)
  setIsgetCombo(false)
}

  return (
    <>
    <Container className='combo-from-section'>
     <FormControl isInvalid={isError}>
      <FormLabel className='combo-title'>Combination Name</FormLabel>
      <Input className='combination-title' type='combination' value={comboName} onChange={handleInputChange} />
      {!isError ? (
        <FormHelperText className='combo-from-text'>
          Enter the Combination name
        </FormHelperText>
      ) : (
        <FormErrorMessage className='combo-textarea'>Combination name  is required.</FormErrorMessage>
      )}
    </FormControl>
<div className='button-seg'>
    <button type="button" class="chakra-button save-icon"
    onClick={handleSaveCombination}
    > <img className="combo-save-icon"src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/diskette_3_3c91ad38df.png"></img>
    Save</button>
    <button type="button" class="chakra-button"><i class="bi bi-x-lg"></i> Discard</button>
    </div>
   
  {  isgetCombo && <GetAllCombination
  combinationProject={combinationProject}
  resetData={handleResetData}
  />}
    </Container>
   
 
    </>
   
  )
}

export default ComboForm
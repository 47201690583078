import React from "react"
import {useState, useEffect} from "react"
import { GET_ALL_COMBINATION } from "../GraphQL/CombinationSwatch";
import { useQuery } from "@apollo/client";
import {addCombinationSwatch} from"../slices/combinationSwatch/CombinationSwatchSlice"
import {useDispatch} from "react-redux";
import {palletLoading} from "../slices/AlertSlice"
const GetAllCombination = ({combinationProject,resetData}) => {
 
    const dispatch = useDispatch();
    const[projectId, setProjectId] = useState()
    useEffect(() => {
     //
      if (combinationProject) {
        //
        setProjectId(combinationProject)
        refetch();
      }
    }, [combinationProject]);
    const { loading, error, data,refetch } = useQuery(GET_ALL_COMBINATION, {
      variables: {
        projectId: projectId,
      }, 
      fetchPolicy: 'network-only',
      });

  
      useEffect(() => {
        if (data &&!loading) {
         // console.log(data?.projects?.data[0].attributes?.combinations?.data);
        //  dispatch(palletLoading({ palletLoading: false }));
         sessionStorage.setItem("allCombos",JSON.stringify(data?.projects?.data[0].attributes?.combinations?.data))
            dispatch(addCombinationSwatch(
               {combinationSwatch:data?.projects?.data[0].attributes?.combinations?.data}
            ))

            resetData()
        }
      },[data, loading])

    return(
        <>
        </>
    )
}
export default GetAllCombination;


import React, { useRef, useState } from 'react';
import { Input, FormLabel, Select } from "@chakra-ui/react";
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Textarea,
    Box,
    Text
} from '@chakra-ui/react'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react'

import "./SegmentationFrom.css"
import SelectSwatchJob from "./SelectSwatchJob";

const SegmentationFrom = () => {

    const [isSelectSwatchOpen, setisSelectSwatchJob] = useState(false);

    const handleSelectSwatchJob = () => {
        setisSelectSwatchJob(true);
    }

    const [entries, setEntries] = useState([{}]);

    const handleAddEntry = () => {
        setEntries([...entries, {}]);
    };

    return (
        <div className='container'>
        <div className="seg-from ">

{entries.map((_, index) => (
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem className='accordion-item-seg '>
                
                        <AccordionButton className='acc-seg-btn'>
                            <div className='dropdown-icons-seg'>
                        <AccordionIcon />
                        </div>

                          
                            <div className='action-icon-segjob'>
                            <i className="bi bi-trash3-fill"></i>
                            <i className="bi bi-grip-vertical"></i>  
                            </div>
                            
                        </AccordionButton>
              
                    <AccordionPanel pb={4} style={{backgroundColor:"#f9f9f9"}}>
                    <div className="row from-segmation">
                    <div className="col-12">
                        <FormLabel>Name</FormLabel>
                        <Input className="input-seg" />
                    </div>

                    <div mt={4} className="col-6 mt-2" >
                        <FormLabel>Area_Pixel</FormLabel>
                        <Input className="input-seg" />
                    </div>
                    <div mt={4} className="col-6 mt-2">
                        <FormLabel>Area_sqft</FormLabel>
                        <Input className="input-seg" />
                    </div>

                    <div mt={4} className="col-6 mt-2">
                        <FormLabel>Dimension</FormLabel>
                        <Input className="input-seg" />
                    </div>

                    <div mt={4} className="col-6 mt-2">
                        <FormLabel>AnnotationPolygon</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} />
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Segment</FormLabel>
                        <Select className="input-seg" >
                            <option>Bay Window</option>
                            <option>Door</option>
                            <option>GarageDoor</option>
                            <option>Gutter</option>
                            <option>Light</option>
                            <option>Number</option>
                            <option>Roof</option>
                            <option>Shadows</option>
                            <option>Soffit</option>
                        </Select>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Confidence</FormLabel>
                        <NumberInput d precision={2} step={0.2}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>


                    <div classname="col-12" style={{ marginTop: "12px" }}>

                        <FormLabel>SelectSwatchJob <span>(0)</span></FormLabel>

                        {!isSelectSwatchOpen &&
                            <Box
                                className='box-primary'
                                border="1px solid #cbd5e0"
                                borderRadius="md"
                                padding="20px"
                                textAlign="center"
                                onClick={handleSelectSwatchJob}
                            >
                                <i class="bi bi-plus-circle-dotted"></i>
                                <Text mt={2} color="gray.500" style={{ fontSize: "12px", fontWeight: "400", color: "rgb(102, 102, 135)" }}>No entry yet. Click on the button below to add one.</Text>

                            </Box>}

                    </div>

                    {isSelectSwatchOpen &&
                        <SelectSwatchJob
                        />}


                    <div mt={4} className="col-6 mt-2">
                        <FormLabel>AnnotationArray</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} />
                    </div>

                    <div mt={4} className="col-6 mt-2">
                        <FormLabel>BoundingBoxFloat</FormLabel>
                        <Input className="input-seg" />
                    </div>


                    <div mt={4} className="col-12 mt-2">
                        <FormLabel>AnnotationJSON</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} />
                    </div>


                    <div mt={4} className="col-6 mt-2">
                        <FormLabel>BoundingBoxInt</FormLabel>
                        <Input className="input-seg" />
                    </div>

                    <div mt={4} className="col-6 mt-2">
                        <FormLabel> Perimeter</FormLabel>
                        <Input className="input-seg" />
                    </div>

                </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

          
                
            ))};


            <div className="col-12">
                <div >
                    <button type="button" class="chakra-button create-new-btn add-entry-btn css-ez23ye" fdprocessedid="9lqqi" onClick={handleAddEntry}>
                        <i class="bi bi-plus"></i>
                        Create new entry
                    </button>
                </div>
            </div>
        </div>
        </div>
    );
};

export default SegmentationFrom;

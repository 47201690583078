import { createSlice } from "@reduxjs/toolkit";

const EditSegmentSlice = createSlice({
  name: "editSegment",
  initialState: {
    editSegment: [],
    isEditAnnotation: false,
    isSaveAnnotation: false,
    isReAnnoatable:false,
    labelName:null,
    masterIndex:null,
    isStartAnnotation:false,
    segName:null,
    polygon:null,
    isStartAnnotation:false,
    isAdddataToDB:false,
    saveData:false,
    isSelectPalletStart:false,
    isOutLine:false,
   isAddSegmentsStart:false,
   isSaveAddSegment:false,
   isAddFinsihSegments:false,
   isShowAddedSegments:false,
   isUndoStart:false,
   isDiscard:false

  },
  reducers: {
    addEditSegment: (state, action) => {
      state.editSegment = [action.payload.editSegment]
      state.labelName= action.payload.labelName
    },
    removeEditSegment: (state, action) => {
      //
      state.editSegment = [];
      state.isEditAnnotation = false;
      state.isSaveAnnotation = false;
      state.isReAnnoatable = false;
      state.labelName=null;
      state.masterIndex=null;
      state.isStartAnnotation=false;
      state.segName=null;
      state.polygon=null;
      state.isAdddataToDB=false;
      state.saveData=false;
      state.isSelectPalletStart=false
      state.isOutLine=false
      state.isAddSegmentsStart=false
      state.isSaveAddSegment=false
      state.isAddFinsihSegments=false
      state.isShowAddedSegments=false

    },
    updateSelectedOnClickSwallet:(state,action)=>{
      const{segName, swatchId,selected}= action.payload;
      const index = state.editSegment[0].findIndex(
        (item) => item.name === segName
      );
      if(index!==-1){
        state.editSegment[0][index].SelectSwatchJob.forEach((swatch)=>{
          if(swatch.swatch.data.id===swatchId){
            swatch.selected=selected
          }
        })
      }

    },
    editAnnotation: (state, action) => {
      state.isEditAnnotation = action.payload.isEditAnnotation;
    
    },
    resetEditAnnotation:(state, action)=>{
      state.editSegment=[]
      state.labelName=null
    },
    updateTickOnEdit:(state, action)=>{
      const {segName, swatchId,isSelected}= action.payload;
    
      const index = state.editSegment[0].findIndex(
        (item) => item.name === segName
      );
      if (index!== -1) {
      let  allswatch= state.editSegment[0][index].SelectSwatchJob 
      if(allswatch.length>0){
        allswatch.forEach((swatch)=>{
          if(swatch.swatch.data.id===swatchId){
            swatch.selected=isSelected
          }else{
            swatch.selected=false
          }
        })
      }
      }
    },
    updateHideUnHide: (state, action) => {
      //
      const { segName, swatchId, hideStatus } = action.payload;
      if (state.editSegment[0]) { // Check if state.editSegment[0] is not undefined or null
        const index = state.editSegment[0].findIndex(
          (item) => item.name === segName
        );
        if (index !== -1 && index != undefined) {
          let allswatch = state.editSegment[0][index].SelectSwatchJob;
          if (allswatch.length > 0) {
            allswatch.forEach((swatch) => {
              if (swatch.swatch.data.id === swatchId) {
                swatch.isHide = hideStatus;
              }
            });
          }
        }
      }
    },
    
    saveAnnotation: (state, action) => {
      state.isSaveAnnotation = action.payload.isSaveAnnotation;
      state.isEditAnnotation = action.payload.isEditAnnotation;
    },
   
    reAnnotation:(state, action)=>{
      state.isReAnnoatable = action.payload.isReAnnoatable
    },
    reSetAnnotation:(state, action)=>{
      state.labelName=action.payload.labelName
      state.masterIndex=action.payload.masterIndex
      state.isReAnnoatable=action.payload.isReAnnoatable
      state.segName=action.payload.segName
      state.polygon=action.payload.polygon
      
    },
    StartReAnnotation:(state,action)=>{
      state.isStartAnnotation = action.payload.isStartAnnotation
    },
    StartAnnotation:(state,action)=>{
      state.isStartAnnotation = action.payload.isStartAnnotation
    },
  SavaDataToDb:(state,action)=>{
    state.isAdddataToDB=action.payload.isAdddataToDB

  },
  selectedPalletStart:(state,action)=>{
    state.isSelectPalletStart=action.payload.isSelectPalletStart
  },
  finallySaveToDB:(state,action)=>{
    state.saveData=action.payload.saveData
  },
  ShowOutLine:(state,action)=>{
    state.isOutLine=action.payload.isOutLine
  },
  addStartSegment:(state,action)=>{
    state.isAddSegmentsStart= action.payload.isAddSegmentsStart
    state.segName=action.payload.segName
    state.labelName=action.payload.labelName  
},
  saveSegment:(state,action)=>{
    state.isSaveAddSegment=action.payload.isSaveAddSegment
    state.isAddSegmentsStart= action.payload.isAddSegmentsStart
  },
  finishAddSegment:(state,action)=>{
    state.isAddFinsihSegments=action.payload.isAddFinsihSegments
    state.isSaveAddSegment= action.payload.isSaveAddSegments
  },
  showAddedSegments:(state,action)=>{
    state.isShowAddedSegments=action.payload.isShowAddedSegments
  
  },
  addSegDetail:(state,action)=>{  
    state.segName=action.payload.segName
    state.labelName=action.payload.labelName
  }, 
  startUndo:(state,action)=>{
    state.isUndoStart=action.payload.isUndoStart
  },
  discardAnnotation:(state,action)=>{
    state.isDiscard=action.payload.isDiscard
    state.isAddSegmentsStart=action.payload.isAddSegmentsStart
  }
}
});

export const { addEditSegment, 
  removeEditSegment,
  saveAnnotation,
  updateSelectedOnClickSwallet,
  editAnnotation,
  resetEditAnnotation,
  updateTickOnEdit,
  updateHideUnHide,
  reAnnotation ,
  reSetAnnotation,
  StartReAnnotation,
  StartAnnotation,
  SavaDataToDb,
  finallySaveToDB,
  selectedPalletStart,
  ShowOutLine,
  addStartSegment,saveSegment,
  finishAddSegment,
  showAddedSegments,addSegDetail,startUndo,
  discardAnnotation
} = EditSegmentSlice.actions;
export const getEditSegmentation = (state, action) => state.editSegmentation;
export const getEditAnnotation = (state, action) => state.editSegmentation.isEditAnnotation;
export const getSaveAnnotation = (state, action) => state.editSegmentation.isSaveAnnotation;
export const getReAnnotation = (state, action) => state.editSegmentation.isReAnnoatable;
export const getStartAnnotation = (state, action) => state.editSegmentation.isStartAnnotation
export const getSaveDataToDB = (state, action) => state.editSegmentation.isAdddataToDB
export const getFinalSave=(state, action) => state.editSegmentation.saveData
export const getSelectedswatchStart=(state, action) => state.editSegmentation.isSelectPalletStart
export const getShowOutLine=(state)=>state.editSegmentation.isOutLine
export const getStartAddSegment=(state)=>state.editSegmentation.isAddSegmentsStart
export const getSaveAddSegment=(state)=>state.editSegmentation.isSaveAddSegment
export const getFinishAddSegment=(state)=>state.editSegmentation.isAddFinsihSegments

export const getShowAddedSegment=(state)=>state.editSegmentation.isShowAddedSegments

export const getStartUndo=(state)=>state.editSegmentation.isUndoStart

export const getISDiscard= (state)=>state.editSegmentation.isDiscard
export default EditSegmentSlice.reducer;

import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_LABELS_BRANDS_SWATCHSTYLE_SWATCH } from "../GraphQL/GraphQl";
import { useDispatch } from "react-redux";
import { addLabel_Brand } from "../slices/Label_Brand_Swatch";
const All_Labels_Brands_SwatchStyle = () => {
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(
    GET_LABELS_BRANDS_SWATCHSTYLE_SWATCH
  );

  useEffect(() => {
    if ((data, !loading)) {
      sessionStorage.setItem(
        "Brands_Swatch",
        JSON.stringify(data?.labels?.data)
      );
      dispatch(addLabel_Brand(data?.labels?.data));
    }
  }, [data, loading]);
  return <div></div>;
};

export default All_Labels_Brands_SwatchStyle;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentTab: 0,
};
const currentTabSlices = createSlice({
  name: "currentTab",
  initialState: initialState,
  reducers: {
    addCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});
export const { addCurrentTab } = currentTabSlices.actions;
export const getCurrentab = (state, action) => state.currentTab;
export default currentTabSlices.reducer;

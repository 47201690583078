
import { createSlice } from "@reduxjs/toolkit";

const uploadSwatchImageSlice=createSlice({
    name: "uploadSwatchImage",
    initialState: {
        isUploadStart:false,
        isUploadStop:false,
        segmentName:null,
        isStartSearch:false,
        masterName:null,
       
        swatchId:null
       
    },
    reducers: {
        openModal:(state,action)=>{
            state.isUploadStart=action.payload.isUploadStart
            state.segmentName=action.payload.segmentName
            state.isStartSearch=action.payload.isStartSearch
            state.masterName=action.payload.masterName
            state.swatchId= action.payload.swatchId
        },
        closeModal:(state,action)=>{
            state.isUploadStart=false
            state.isUploadStop=false
            state.segmentName=null
            state.isStartSearch= false
            state.masterName=null
            state.swatchId=null
        },
      
    }
})

export const {openModal,closeModal}=uploadSwatchImageSlice.actions;


export const getOpenModal=state => state.uploadSwatchImage;

export default uploadSwatchImageSlice.reducer;
import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { getProjectId } from "../slices/ProjectSlice";
const AddToDB = ({ encodedImage, resetEncodeImage }) => {
  const getprojectDetails = useSelector(getProjectId);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current && encodedImage) {
      addFinalLayer();
      return () => {
        isMounted.current = false;
      };
    }
  }, [encodedImage]);
  const addFinalLayer = async () => {
    let jobId = getprojectDetails.jobId;
    let data = {
      data: {
        FinalLayer: encodedImage,
      },
    };
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_STRAPI + "jobs/" + jobId,
        data
      );
      if (response.status === 200) {
        resetEncodeImage();
        isMounted.current = true;
      }
    } catch (err) {
      alert("Error on adding Final Layer", err.message);
      isMounted.current = true;
      resetEncodeImage();
    }
  };
  return <div></div>;
};

export default AddToDB;

import React from "react";
import Label_Segment from "../Label_Segment/Label_Segment";
import LabelAndBrand from "../All_Labels_Brands_SwatchStyle/All_Labels_Brands_SwatchStyle";
import { getCustomer } from "../login/AuthSlice";
import { useSelector } from "react-redux";
import DesignerProfile from "./DesignerProfile";
const Designer = () => {
  const loginDetails = useSelector(getCustomer);

  return (
    <div>
      {loginDetails.customerId ? <DesignerProfile /> : ""}
      {loginDetails.isAuthenticated && (
        <div>
          <Label_Segment />
          <LabelAndBrand />
        </div>
      )}
    </div>
  );
};

export default Designer;

import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Checkbox,
} from '@chakra-ui/react';
import "./MediaSegmentsFrom.css";

const MediaSegmentsFrom = () => {
    const [checkedItems, setCheckedItems] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (id) => {
        setCheckedItems(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleSelectAllChange = () => {
        const newCheckedItems = {};
        tableData.forEach(row => {
            newCheckedItems[row.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
    };

    const tableData = [
        { id: 1, media: "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png", updatedAt: "Tuesday, November 28, 2023 at 12:19 PM", state: "Published" },
        { id: 2, media: "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png", updatedAt: "Tuesday, November 28, 2023 at 12:19 PM", state: "Published" },
        { id: 3, media: "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png", updatedAt: "Tuesday, November 28, 2023 at 12:19 PM", state: "Published" }
    ];

    return (

        <div className="createproject-pages ">
        <div className="Container" style={{backgroundColor:"#f7f8f3"}}>
          <div className="crete-project-page ">
            <div className='media-section'>
                <div className='media-title'>
              <h1> Media Library </h1>
              </div>
              <div className='media-create-btn'>
                 <Button className='create-new-btn'> <i class="bi bi-plus"></i> Create new entry</Button> 
                 </div>
              </div>
            
          </div>
         
          <div className='create-project'>
            <TableContainer className='seg-table-container'>
                <Table variant='simple' className='table-container'>
                    <Thead className='table-head'>
                        <Tr>
                            <Th>
                                <Checkbox
                                    isChecked={selectAll}
                                    onChange={handleSelectAllChange}
                                />
                            </Th>
                            <Th>ID</Th>
                            <Th>MEDIA</Th>
                            <Th>UPDATED AT</Th>
                            <Th>STATE</Th>
                            <Th>ACTION</Th>
                        </Tr>
                    </Thead>
                    <Tbody className='table-body'>
                        {tableData.map(row => (
                            <Tr key={row.id}>
                                <Td>
                                    <Checkbox
                                        isChecked={checkedItems[row.id] || false}
                                        onChange={() => handleCheckboxChange(row.id)}
                                    />
                                </Td>
                                <Td>{row.id}</Td>
                                <Td>
                                    <div className='media-img'>
                                        <img src={row.media} alt="media" />
                                    </div>
                                </Td>
                                <Td>{row.updatedAt}</Td>
                                <Td><Button className='btn-media'>{row.state}</Button></Td>
                                <Td>
                                    <div className='d-flex action'>
                                        <div className='action-icon-table'><i className="bi bi-pencil-square"></i></div>
                                        <div className='action-icon-table'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                            </svg>
                                        </div>
                                        <div className='action-icon-table trash-icon-media'><i className="bi bi-trash3"></i></div>
                                    </div>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </div>
          
        </div>
      </div>

       
    );
};

export default MediaSegmentsFrom;

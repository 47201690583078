import { createSlice } from "@reduxjs/toolkit";

const getAllCustomerSlice = createSlice({
  name: "getAllCustomer",
  initialState: {
    getAllCustomer: [],
  },
  reducers: {
    addAllCustomer: (state, action) => {
      state.getAllCustomer = [...action.payload];
    },
  },
});

export const { addAllCustomer } = getAllCustomerSlice.actions;

export const getAllCustomer = (state) => state.getAllCustomer;
export default getAllCustomerSlice.reducer;

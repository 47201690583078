import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Input,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { selectAllItems } from "../slices/MasterArraySlice";
import "./AddSgmentForm.css";
import { useSelector, useDispatch } from "react-redux";

import { addNestedObjectToSegment } from "../slices/MasterArraySlice";
import {StartAnnotation} from "../slices/EditSegment/EditSegment"
const AddSgmentForm = ({ isOpenEdit, isClose, addSegment }) => {
  const dispatch = useDispatch();
  const getMasterArray = useSelector(selectAllItems);
  const [segment, setSegment] = useState("");
  const [label, setLabel] = useState("");
  const [isActive, setIsActive] = useState(false);
  
  const handleAddSegmentName = (segName) => {
    setIsActive(true);
    setSegment(segName);
    let masterArray = getMasterArray.master;
    if (masterArray && masterArray.length > 0) {
      masterArray.forEach((item) => {
        // Using forEach for clarity
        if (item.name === segName) {
          let segArray = item.segmentation;
          if (segArray && segArray.length > 0) {
            let arraLength = segArray.length;
            let name = segArray[arraLength - 1].name;
            const match = name.match(/\d+/);
            if (match) {
              const number = parseInt(match[0], 10);
              const labelName = segName + (number + 1); // Increment the number
              setLabel(labelName);
            } else {
              const labelName = segName + "1"; // If there's no previous number, start with 1
              setLabel(labelName);
            }
          } else {
            const labelName = segName + "1"; // If segArray is empty, start with 1
            setLabel(labelName);
          }
        }
      });
    }
  };

  const handleCloseModal = () => {
    isClose();
  };

  const handleSubmit = () => {
    dispatch(StartAnnotation({
      isStartAnnotation:true
    }))
    
  };

  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isOpenEdit}
        onClose={isClose}
        size={{ base: "sm", md: "md" }}
      >
        <ModalOverlay className="label-header" />
        <ModalContent
          className="Segment-header"
          bg="white"
          borderRadius="md"
          boxShadow="md"
          p={{ base: "2", md: "4" }}
        >
          <ModalHeader textAlign="center">Add Segment</ModalHeader>
          <ModalCloseButton />

          {/* sagemnts popup start */}

          <div className="add-segements-tab-section">
            <div className="add-sagments">
              <div class="segement-title">
                <h2 class="segement-text segments-text"> Select Category</h2>
              </div>
              <div
                class="chakra-tabs__tablist tab-list-segemnt css-fgp5ep "
                role="tablist"
                aria-orientation="horizontal"
              >
                {getMasterArray &&
                  getMasterArray.master &&
                  getMasterArray.master.length > 0 &&
                  getMasterArray.master.map((item, index) => (
                    <button 
                    key={item.id}
                      type="button"
                      aria-disabled="false"
                      id={`tabs-${index}--tab-0`}
                      role="tab"
                      tabindex="0"
                      aria-selected="true"
                      aria-controls={`tabs-${index}--tabpanel-0`} 
                      className={isActive ? "active-segment " : ""} 
                      data-index="0"
                      fdprocessedid="4625"
                      onClick={handleAddSegmentName(item.name)}
                    >
                      <div >
                        <div className="recomended-box" key={item.id}>
                          <div className="recomended-images">
                            <img src={item.icon} width="25" alt="Door"></img>
                          </div>
                          <div className="recomended-text">
                            <h3 key={item.id}>{item.name}</h3>
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
              </div>
            </div>

            <div className="from-labels-title-name">
              <FormControl className="labelname-title lable-form-box">
                <FormLabel className="labels-title-tab">
                  <h2>Label Name</h2>
                </FormLabel>
                <Input
                  type="text"
                  value={label}
                  //    onChange={handleInputChange}

                  readOnly
                  borderColor="gray.400"
                  _focus={{ borderColor: "blue.500" }}
                />
              </FormControl>
            </div>

            <ModalFooter
              className="button-label-segment button-lables"
              flexDirection={{ base: "column", md: "row" }} // Flex direction based on breakpoints
              justifyContent="center" // Center buttons horizontally
            >
              <Button
                mb={{ base: "2", md: "0" }} // Responsive margin-bottom based on breakpoints
                mr={{ base: "0", md: "3" }} // Responsive margin-right based on breakpoints
                colorScheme="green"
                _hover={{ bg: "green.200" }}
                flex="1"
                onClick={handleSubmit}
              >
                Mark Segment
              </Button>
              <Button
                colorScheme="red"
                mr={3}
                _hover={{ bg: "red.200" }}
                flex="1"
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
          {/* end */}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddSgmentForm;

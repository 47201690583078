import React, { useRef, useState } from 'react';
import { Input, FormLabel, Select } from "@chakra-ui/react";
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Textarea,
    Box,
    Text
} from '@chakra-ui/react'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import "./SegmentationFrom.css";

const ParametersFrom = () => {
    const [isSelectSwatchOpen, setisSelectSwatchJob] = useState(false);

    const handleSelectSwatchJob = () => {
        setisSelectSwatchJob(true);
    }

    const [entries, setEntries] = useState([{}]);

    const handleAddEntry = () => {
        setEntries([...entries, {}]);
    };

    return (
        <div className='container'>
            <div className="seg-from" style={{backgroundColor:"#f7f7f9"}}>

                <div className="row" style={{margin:"8px 0 25px 0"}}>

                    <div className="col-4 mt-2">
                        <FormLabel>ImageHeight</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-4 mt-2">
                        <FormLabel>ImageWidth</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-4 mt-2">
                        <FormLabel>Tolerance</FormLabel>
                        <NumberInput d precision={2} step={0.2} style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <p style={{ fontWeight: "400", fontSize: "12px" }}>min. 0.5 / max. 5 characters</p>
                    </div>



                    <div className="col-4 ">
                        <FormLabel>Threshold</FormLabel>
                        <NumberInput d precision={2} step={0.2} style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <p style={{ fontWeight: "400", fontSize: "12px" }}>min. 0 / max. 1 character</p>
                    </div>

                    <div className="col-4 ">
                        <FormLabel>Beta</FormLabel>
                        <NumberInput d precision={2} step={0.2} style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-4 ">
                        <FormLabel>Rows</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-4 ">
                        <FormLabel>Columns</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default ParametersFrom

import { createSlice } from "@reduxjs/toolkit";

const PopHoverSlice = createSlice({
    name: "popHover",
    initialState: {
      segmentName: null,
      swatchId: null,
      ishide:false,
      isUnhide:false,
      isDelete:false,
      reGenSwatch:[]
      
      
    },
    reducers:{
        selectPopeHover:(state,action)=>{
            state.segmentName=action.payload.segmentName
            state.ishide=action.payload.ishide
            state.isUnhide=action.payload.isUnhide
            state.swatchId=action.payload.swatchId
        },
         removePopHover:(state, action)=>{
            state.ishide=false
            state.isUnhide=false
            state.segmentName=null
            state.swatchId=null
            state.isDelete=null
         },
         deleteSegment:(state,action)=>{
            state.segmentName=action.payload.segmentName
            state.isDelete=action.payload.isDelete
         },
         reGenerateSwatch:(state,action)=>{
            state.reGenSwatch=action.payload.reGenSwatch
         },
         resetReGenerateSwatch:(state,action)=>{
            state.reGenSwatch=[]
         }
    },
    });


export const { selectPopeHover ,
    removePopHover,deleteSegment,
    reGenerateSwatch,
    resetReGenerateSwatch} = PopHoverSlice.actions;
export const getPopHover= (state, action) =>state.popHover
export default PopHoverSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
// import { Tooltip, Box, Button } from "@chakra-ui/react";
import axios from "axios";
import Select from "react-select";
import { Stage, Layer, Image, FastLayer } from "react-konva";
import { Line, Label, Text, Tag } from "react-konva";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import "../ImageView/ImageView.css";
import "../NewProjectView/NewProjectView.css";
import { Tooltip, Flex, HStack, Box, h1 } from "@chakra-ui/react";
import Report from "./Report.js";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
// import noimage from "public/no-prodect-img.png";
import noimage from "../../assets/no-prodect-img.png";
import PolygonAnnotation from "../../pages/PolygonAnnotation.js";
import Progress from "../../pages/Progress.js";

import "../../assets/styles/simplebar.css";
import "../../pages/Editor.scss";
import "./ImageView.css";

import { useToast } from "@chakra-ui/react";


import Alert from "../ImageView/Alert";

import { useDispatch } from "react-redux";
import { removeMasterArray, addMasterArray } from "../slices/MasterArraySlice"; // Import your slice

import NewImageView from "../ProjectLayer/ProjectLayer.js";

import AddSegement from "./AddSegement.js";
import { selectAllSegments, updateSegment } from "../slices/AllSegmentSlice.js";
import { getJobDetail } from "../slices/JobSlice.js";
import Leftsection from "../LeftSection/LeftSection.js";
import {
  getPreSegment,
  addValue,
} from "../slices/ClosePreSegmentImageSlice.js";
import { getProjectId } from "../slices/ProjectSlice.js";
import DeleteSegmentSpin from "../ImageView/DeleteSegmentSpin.js";
import { getHover, getAllPolygons } from "../slices/AlertSlice.js";
import { getCreateProject } from "../slices/CreateProjectImageSlice.js";
import SamLoading from "../Alert/SamLoading.js";
import SegredateSamData from "../SegredateSamData/SegredateSamData.js";
import { getSamLoading, samLoading } from "../slices/AlertSlice.js";
import AddSgmentForm from "../AddSegemnt/AddSgmentForm.js";
import { addNestedSegemnt } from "../slices/AllSegmentSlice.js";
import { addNestedObjectToSegment } from "../slices/MasterArraySlice.js";
import {
  getTranspalletImage,
  getAllTransparentImages,
} from "../slices/TransparentPalletImgSlice.js";
import { getCompareProject, getSwatchDeleting } from "../slices/AlertSlice.js";
import { getDeleteLoadings, compareProject } from "../slices/AlertSlice";
import DeletingLoading from "../Tabs/DeletingLoading";
import { removeProjectImage } from "../slices/CreateProjectImageSlice.js";
import ReloadAllProject from "./ReloadAllProject.js";

import BlankPage from "../ImageView/BlankPage.js";

import { addNewMasterJob } from "../slices/MasterJobs.js";

import SwatchDeletingSpin from "../Tabs/DeleteSwatch/SwatchDeletingSpin.js";

import { nullSegmentValue } from "../slices/LoadingSlice.js";
import { useNavigate } from "react-router-dom";
import GetParentfolder from "../Folders/GetParentfolder.js";
import FinalLayer from "../FinalLayer/FinalLayer.js";
import AddSegmentToDB from "./AddSegmentToDB.js";
import Model from "../Model/ModelMenu.js";
import { getModelVersion } from "../slices/Model/ModelSlice.js";
import { addModelVersion } from "../slices/Model/ModelSlice.js";

import Testing from "../ImageView/Testing.js";

import OutLine from "./OutLine";

import EditAnnonation from "../ProjectLayer/EditAnnotation/EditNewAnnonation.js";

import { getEditSegmentation } from "../slices/EditSegment/EditSegment.js";
import Konva from "konva";
 import Noise from "../Noise/Noise.js"
 import Slider from "../ImageView/slider/Slider.js";
import {getAllMasterJobs} from "../slices/MasterJobs.js"
 import Tools from "../ImageView/Tools/Tools.js";
 import {switchCanvas,getSwitchCanvas} from "../slices/gridView/MultiSelectionSegement"
import {getEditAnnotation,removeEditSegment,getSaveAnnotation} from "../slices/EditSegment/EditSegment.js"
 
import ReAnnotation from "../ReAnnotation/ReAnnotation"
import Header from "../Header/Header.js"

import HoverLayer from "../ProjectLayer/HoverLayer/HoverLayer.js";
import { TrustedAdvisor } from "aws-sdk";
function ImageView() {
  const toast = useToast();
  const navigate = useNavigate();
  // recieve id from Demo Click event
  const location = useLocation();
  const getModelVersions = useSelector(getModelVersion);
  const DemoId = location?.state;
  const modalImage = useSelector((state) => state.projectImage.projectImage);
  sessionStorage.setItem("demoId", DemoId);
  const allSegmenTData = useSelector(selectAllSegments);
  const [isPositionFixed, setIsPositionFixed] = useState(false);
  const getJobDetails = useSelector(getJobDetail);
  const getTransparent = useSelector(getTranspalletImage);
  const getallTransparent = useSelector(getAllTransparentImages);
  const getCompareProjects = useSelector(getCompareProject);
  const getDeleteLoading = useSelector(getDeleteLoadings);
  const [startAnnotation, setStartAnnotation] = useState(false);

  const [isReport, setisReport] = useState(false);

  useEffect(() => {
    if (getCompareProjects) {
      setIsCompare(getCompareProjects);
    }
  }, [getCompareProjects]);

  useEffect(() => {
    setIsChecked(false);
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 200 && !isPositionFixed) {
        setIsPositionFixed(true);
      } else if (window.scrollY <= 200 && isPositionFixed) {
        setIsPositionFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPositionFixed]);

  const headerClass = isPositionFixed
    ? "navbar fixed-top header-border"
    : "navbar";
  const [getTargetData, setGetTargetData] = useState("");
  //For Project Start
  const [selectedImage, setSelectedImage] = useState(null);
  const imageRef = React.useRef(null);

  const [isImage, setIsImage] = useState(false);
  const uploadImageRef = useRef(null);
  const [removeUploadImages, setRemoveUploadImage] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const closeSegment = useSelector(getPreSegment);
  //For Project END

  //For Labels Start

  //For Labels END

  const [polygon, setPolygon] = useState(true);
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [points, setPoints] = useState([]);
  const [polygonSize, setPolygonSize] = useState([]);
  const [position, setPosition] = useState([0, 0]);
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [lines, setLines] = useState([]);
  const [size, setSize] = useState({});

  const [numAnnotations, setNumAnnotations] = useState(0);
  const [newAnnotations, setNewAnnotations] = useState([]);

  const [isAnnotationMode, setIsAnnotationMode] = useState(false); // Set to true by default
  const [showPopup, setshowPopup] = useState(false);

  const [isImageView, setIsImageView] = useState(false);

  const PalleteimageRef = React.useRef(null);
  const [PalleteimageSize, setPalleteimageSize] = useState({
    width: 0,
    height: 0,
    palletannotatedAspectRatio: 0,
  });
  const [palletPosition, setpalletPosition] = useState({
    left: 0,
    top: 0,
    height: 0,
    width: 0,
  });
  const [multiPolygon, setmultiPolygon] = useState([]);

  // Get the location object using useLocation()

  const [samDataFromAPI, setSamDataFromAPI] = useState([]);
  const [polygonSets1, setPolygonSets1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isSamLoading, setIsSamLoading] = useState(false);
  const [segmentIndex, setSegementIndex] = useState(null);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isJob, setIsJob] = useState(false);

  //After Save to DataBAse PolyGon are Exttracted and set to polygonAnnotation
  const [newPolygon, setNewPolygon] = useState([]);
  const [newSegmentName, setNewSegmentName] = useState([]);
  const [jobs, setJobs] = useState([]);

  const isAlertVisible = useSelector((state) => state.alerts.isAlertVisible);
  const[isHoverLayer, setIsHoverLayer]=useState(true)
  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };

  //drawing begins when mousedown event fires.
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [hoveredKey, setHoveredKey] = useState(null);
  const [newPolygonPoints, setNewPolygonPoint] = useState([]);
  const [hoverPolygonPoints, sethoverPolygonPoints] = useState(null);
  const [nameOfHover, setNameofHover] = useState([]);

  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const scale = 2; // Adjust the zoom level as needed
  // const magnifierSize = 150;
  


 
  const[noseOpen, setNoseOpen]= useState(false)

  const handleMouseDown = (e) => {
    
    if (!isAnnotationMode || isPolyComplete) return;
    
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && Array.isArray(points) && points.length >= 3) {
      setPolyComplete(true);
      setIsDivVisible(true);
      setNewPolygonPoint(points);
      setPolygonSize([...polygonSize]);
      if(isNoise){
        setNoseOpen(true) // open noise modal
    
      }
      else{
        setshowPopup(true);  // open add segment modal
      }
     
   
    } else {
      const newPoints = Array.isArray(points)
        ? [...points, mousePos]
        : [mousePos];
      setPoints(newPoints);
   
    }
  };
  // setting all new point and pass to label Componnet
  useEffect(() => {
    if (newPolygonPoints != null) {
      // save to the database
    }

    sessionStorage.setItem("newPolygon", newPolygonPoints);
  }, [newPolygonPoints]);

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setMagnifierPosition(mousePos);
    setPosition(mousePos);
  };
  useEffect(() => {
    if (hoverPolygonPoints) {
      setPoints(hoverPolygonPoints);
      setPolyComplete(true);
    }
  }, [hoverPolygonPoints]);

  // Handle mouse over start point when doing polygon annotation
  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };

  // Handle mouse out from start point during polygon annotations
  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };
  const [isEditing, setIsEditing] = useState(false);
  // Handle points while dragging
  const handlePointDragMove = (e) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
  };

  const handleGroupDragEnd = (e) => {
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === "polygon") {
      let result = [];
      let copyPoints = [...points];
      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      );
      e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result);

      saveAnnotation();
      setPolyComplete(false);
      setPoints([]);
    }
  };
  const dispatch = useDispatch();
  //logic for popup and segment addition
  const [newSegmentData, setNewSegmentData] = useState();
  const [segmentName, setSegmentName] = useState("");
  const [labelName, setLabelName] = useState("");
  const [Value, setValue] = useState(false);
  const [yesValue, setYesValue] = useState(false);
  const [isAddSegment, setISAddSegment] = useState(false);
  const [isUpdateSegment, setIsUpdateSegment] = useState(false);
  const handleYes = (segmentName, labelName, flattenedPoints) => {
    // (flattenedPoints);

   
    convertToBoundingBox(flattenedPoints, segmentName, labelName);

    // Handle "Yes" action here, e.g., save the annotation
    saveAnnotation(); // Save the annotation
    setValue(true);
    setYesValue(true);
    setshowPopup(false); // Close the modal
  };

  /// calculate the bounding for the segmentation
  function convertToBoundingBox(flattenedPoints, segmentName, labelName) {
    const boundingBoxes = {};
    let BoundingBoxInt = [];
    let BoundingBoxFloat = [];
    const points = flattenedPoints;

    // Find the minimum and maximum coordinates to form the bounding box
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < points.length; i += 2) {
      const x = points[i];
      const y = points[i + 1];
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }

    // Calculate the area of the bounding box
    const width = maxX - minX;
    const height = maxY - minY;
    const area = width * height;
    BoundingBoxFloat.push(minX, minY, maxX, maxY);
    BoundingBoxInt.push(
      Math.floor(minX),
      Math.floor(minY),
      Math.floor(maxX),
      Math.floor(maxY)
    );

    let area_pixel = area;

    if (BoundingBoxFloat.length > 0 && BoundingBoxInt.length > 0) {
      dispatch(
        addNestedObjectToSegment({
          segment: segmentName,
          label: labelName,
          flattenedPoints: flattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
        })
      );
      dispatch(
        addNewMasterJob({
          label: labelName,
          flattenedPoints: flattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
        })
      );
      dispatch(
        addNestedSegemnt({
          label: labelName,
          flattenedPoints: flattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
        })
      );
      setIsProjectLayer(false)
      setIsImageView(false)
      setIsEditAnNonation(false)
      setISAddSegment(true);
      dispatch(removeEditSegment())
    }
  }

  const handleNo = () => {
    // Handle "No" action here, e.g., discard the annotation
    setIsAnnotationMode(false); // Discard the annotation
    setshowPopup(false); // Close the modal

    setPoints([]);
  };

  //Save Annotation
  const saveAnnotation = (e) => {
    setLines([...lines, flattenedPoints]);
    setNewAnnotations((prevAnnotations) => [...prevAnnotations]);
    setNumAnnotations(numAnnotations + 1);
    setIsAnnotationMode(false);

    reset();
  };

  //Set coordinates example [123, 321, 456, 654, 789, 987]
  //[x, y] format
  const [annotation, setAnnotation] = useState();
  const [isSegModal, setIsSegModal] = useState(false);
  const [isOpenSaveAnnotation, setIsOpenSaveAnnotation] = useState(false);
  useEffect(() => {
    setFlattenedPoints(
      (points ?? [])
        .concat(isPolyComplete ? [] : position ?? [])
        .reduce((a, b) => a.concat(b), [])
    );
    setAnnotation(flattenedPoints);
    setIsSegModal(true);
  }, [points, isPolyComplete, position]);

  //Undo Annotation
  const undo = () => {
    if (points != null && points.length > 0) {
      setLines(lines.slice(0, -1));
      polygonSize.pop();
      setPoints(points.slice(0, -1));
      setPolyComplete(false);
      setPosition(points[points.length - 1]);
    }
  };

  //Reset Annotation
  const reset = () => {
    setPoints([]);
    setPolyComplete(false);
  };

  const [Project_id, setProject_id] = useState(null);
  const [originalImage, setOriginalImage] = useState(false);

  const handleUploadImage = (img1) => {
    sessionStorage.removeItem("image");
    sessionStorage.removeItem("DemoJson");

    if (img1 && img1 instanceof Blob) {
      const image = img1;
      const showImage = URL?.createObjectURL(image);
      const img = new window.Image();

      img.src = showImage;
      img.onload = () => {
        setSelectedImage(img);
        imageRef.current = img;
        dispatch(removeProjectImage());
      };
      sessionStorage.removeItem("samData");
      sessionStorage.removeItem("demo");
    }
  };

  // const handleImageLoad = (image) => {
  //   setSelectedImage(image.target);
  //   setSize({ width: image.target.width, height: image.target.height });
  // };

  //  show segment when segement call from Label component
  const handleShowSegement = (segmentIndex) => {
    const index = segmentIndex;
    if (index) {
      setSegementIndex(index);
    }
  };

  // get value from child label component
  useEffect(() => {
    if (segmentIndex) {
      setIsDivVisible(true);
      const selectedPolygonIndex = segmentIndex;
      const selectedPolygon = polygonSets1[selectedPolygonIndex] ?? []; // Provide a default empty array if polygonSets1 is undefined
      setPoints(selectedPolygon);
      setPolyComplete(true);
      var s = document.getElementById("selectBox");
      document.getElementById("selectTag").selected = false;
      s.style.position = "fixed";
      s.style.marginLeft = "1360px";
      s.style.marginTop = "100px";
      s.style.display = "block";
    }
  }, [segmentIndex]);

  //Demo Image

  // if demo data is upload then remove the upload image value;
  const removeUploadImage = () => {
    setRemoveUploadImage(true);
  };

  // handle create project Component
  const [isOpen, setIsOpen] = useState(false);
  const [isProject, setIsProject] = useState(false);
  const handleCreateProject = () => {
    setIsProject(false);
    setIsOpen(true);
  };

  useEffect(() => {
    setPolyComplete(true);
    setFlattenedPoints(multiPolygon);
  }, [multiPolygon]);

  const [decodedImage, setDecodedImage] = useState();
  const handleSendData = () => {
    const decodedImageDataUrl = sessionStorage.getItem("decodesImage");

    if (decodedImageDataUrl) {
      // Create an object URL from the Blob

      const image = new window.Image(); // Use 'window.Image' to create an HTMLImageElement
      image.onload = () => {
        setDecodedImage(image);
      };
      image.src = decodedImageDataUrl;
    }
  };
  useEffect(() => { }, [decodedImage]);

  useEffect(() => {
    sessionStorage.removeItem("decodesImage");
  }, []);

  //hovr demo segement

  const [isHover, setISHover] = useState(true);

  // Function to activate annotation mode
  const activateAnnotationMode = () => {
    setIsAnnotationMode(true);
  };

  // Function to deactivate annotation mode
  const deactivateAnnotationMode = () => {
    setIsAnnotationMode(false);
  };

  //  allhover from segmented
  const getAllPolygon = useSelector(getAllPolygons);
  const [isMultipleHover, setISMultipleHover] = useState(false);

  useEffect(() => {
    if (getAllPolygon.isAllHover && getAllPolygon.allpolygon) {
      handleAllSegmentHover(getAllPolygon.allpolygon);
    } else {
      handleRemoveAllHover();
    }
  }, [getAllPolygon.isAllHover]);

  const handleAllSegmentHover = (allPolygon) => {
    setISMultipleHover(true);
    setIsDivVisible(false);
    setmultiPolygon(allPolygon);
  };

  // remove all hover
  const handleRemoveAllHover = () => {
    sessionStorage.removeItem("multiPoly");
    setISMultipleHover(false); // Reset the state when hover leaves
    setPoints(null);
    setPolyComplete(false);
  };

  const handleCloseEditModal = () => {
    setIsOpenEdit(false);
  };

  // display selected image

  // remove older segment Image
  useEffect(() => {
    if (closeSegment) {
      dispatch(addValue({ closeSegment: false }));
      setSelectedImage(null);
    }
  }, [closeSegment]);
  const handleSendValue = (data) => {
    setGetTargetData(data);
  };
  //showing values

  const handleSendingValues = (segment, label) => {
    setSegmentName(segment);
    setLabelName(label);
  };

  // handle compare by use of switch
  const [isChecked, setIsChecked] = useState(false);
  const [projectNameValue, setProjectNameValue] = useState("");
  // Function to handle the change event when the switch is toggled
  const handleSwitchChange = () => {
    setIsCompare(!isCompare);
    dispatch(
      compareProject({
        isCompare: !isCompare,
      })
    );
  };

  // handleCallAlert
  const handleCallAlert = () => { };
  const handleCallToImage = (projectName) => {
    setProjectNameValue(projectName);
  };
  const HandleSendImageBool = (ImageDispatch) => {
    setSelectedImage(false);
  };

  const downloadLinkRef = useRef(null);

  const captureScreenshot = () => {
    // Get the div element you want to capture
    const elementToCapture = document.querySelector(".ai-right-wrapper-body");
    const randomNum = Math.floor(Math.random() * 1000) + 1;
    const fileName = `${projectNameValue}_${randomNum}.png`;

    // Use html2canvas to capture the content of the div
    html2canvas(elementToCapture).then((canvas) => {
      // Convert the canvas to an image data URL
      const imageDataURL = canvas.toDataURL("image/png");

      // Create a hidden download link for the image
      const downloadLink = document.createElement("a");
      downloadLink.href = imageDataURL;
      downloadLink.download = fileName;
      downloadLink.style.display = "none"; // Hide the link

      // Add the link to the document and trigger a click event to download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up: Remove the download link and canvas from the DOM
      document.body.removeChild(downloadLink);
      canvas.remove();
    });
  };

  
  // change the projectView 2
  const handleSwitchProjectView = () => {
    setIsImageView(!isImageView);
  };
  // update the project Image on image view
  const getProject = useSelector(getProjectId);
  useEffect(() => {
    // ;
    if (getProject.projectName) {
      handleDemoImage(getProject.base64Image);
      setProjectNameValue(getProject.projectName);
    }
    // else if (getProject.base64Image == null) {
    //   setSelectedImage(null);
    // }
  }, [getProject]);

  const handleDemoImage = (image) => {
    if (image) {
      const encodedImage = image;
      const tets = "data:image/png;base64," + encodedImage;
      // Decode the base64 image
      const decodedImage = atob(tets.split(",")[1]);
      // Convert the decoded data to a Uint8Array
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "image/png" });

      // Create an object URL from the Blob
      const imageUrl = URL.createObjectURL(blob);
      var img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {
        imageRef.current = img;
      };
      setSelectedImage(img);
    }
  };

  const getHoverEffect = useSelector(getHover);

  useEffect(() => {
    if (getHoverEffect.ishover && getHoverEffect.polygon) {
      if (typeof getHoverEffect.polygon === "string") {
        let segName = getHoverEffect.segmentName;
        const values = getHoverEffect.polygon
          .split(",")
          .map((value) => parseInt(value.trim(), 10));

        handleHoverDemoSegment(values);
      }
    } else {
      handleHoverCloseSegment();
    }
  }, [getHoverEffect.ishover && getHoverEffect.polygon]);

  // hover on each segement
  const handleHoverDemoSegment = (polygon) => {
    setISHover(true);
    setPoints(polygon);
    setPolyComplete(true);
  };

  //hover close each segement
  const handleHoverCloseSegment = () => {
    setPoints(null);
    setPolyComplete(false);
  };

  // display image on create project
  const samload = useSelector(getSamLoading);
  const getCreateProjectImage = useSelector(getCreateProject);

  useEffect(() => {
    if (getCreateProjectImage.image) {
      handleUploadImage(getCreateProjectImage.image);

      handleImageToTrain(getCreateProjectImage.image);
    }
  }, [getCreateProjectImage]);

  // open add segment modal
  const ismounted = useRef(true);
  const handleImageToTrain = async (uploadedImage) => {
    if (uploadedImage) {
      let apiPath;
      if (
        getModelVersions.modelVersion &&
        getModelVersions.modelApi &&
        getModelVersions.modelId
      ) {
        apiPath = getModelVersions.modelApi;
      } else {
        apiPath = "object-detectation-api";
        dispatch(
          addModelVersion({
            modelVersion: "M1",
            modelApi: "object-detectation-api",
          })
        );
      }
    //  dispatch(samLoading({ samLoading: true }));
      let formdataImage = new FormData();
      formdataImage.append("image", uploadedImage);
      if (formdataImage != null && ismounted.current) {
        ismounted.current = false;

        try {
          const modelData = await axios.post(
            `https://api.dzinly.org/${apiPath}`,
            formdataImage
          );
          formdataImage = null;
          if (modelData.status === 200) {
            ismounted.current = true;
            setIsSamLoading(false);
            sessionStorage.setItem("samData", JSON.stringify(modelData.data));
            setSamDataFromAPI(modelData.data);
            dispatchEvent(
              nullSegmentValue({
                deleteSegmentValue: false,
              })
            );
          }
        } catch (error) {
         
          // toast({
          //   title: "Error: " + error.message,
          //   // description: "Error to call  Model API ",
          //   status: "success",
          //   duration: 5000,
          //   isClosable: true,
          //   position: "center-bottom",
          // });
          setIsSamLoading(false);
          // Handle any errors that occurred during the API request
        }
      }
    }
  };

  const handleresetsamDataFromAPI = () => {
    setSamDataFromAPI(null);
  };





  const handleAnotation = () => {
    setStartAnnotation(!startAnnotation);
    handleAddNewSegment();
  };

  

  // Add new segment
  const [segment_data, setSegment_data] = useState([]);
  const handleAddNewSegment = () => {
    setIsOpenEdit(true);
    activateAnnotationMode(true);
  };
  const [newSegment, setNewSegment] = useState();
  const [newLabel, setNewLabel] = useState();
  const handelAddSegmentFrom = (segName, labelName) => {
    setNewSegment(segName);
    setNewLabel(labelName);
    setIsOpenEdit(false);
    setIsProjectLayer(false);
    setIsImageView(true);
    setIsFinalLayer(false);
  };

  const handleLog = () => {
    const socket = new WebSocket("ws://127.0.0.1:8000/log");

    socket.onmessage = (event) => {
      // Handle log messages received from the server
      // Close the WebSocket connection
      socket.close();
    };
  };

  const [isCompare, setIsCompare] = useState(false);

  // swatch deleteing
  const swatchDeleting = useSelector(getSwatchDeleting);
  const [isProjectLayer, setIsProjectLayer] = useState(false);
  const [isFinalLayer, setIsFinalLayer] = useState(false);
  const [projectLayerBackGround, setProjectLayerBackGround] = useState(false);
  // handle project Layer page
  const handleFinalLayer = () => {
    if (!isFinalLayer) {
     
      setIsProjectLayer(false);
      setIsImageView(false);
      setIsFinalLayer(true);
    } else {
     
      setIsProjectLayer(false);
      setIsImageView(true);
      setIsFinalLayer(false);
    }
  };


  // open project layer annd canvas mode 

 

  // close the AddSegmentToDb
  const handleResetSegmentData = () => {
    setISAddSegment(false);
  };

  // compare
  // handle testing
  const [isTesting, setIsTesting] = useState(false);
  const handleTesting = () => {
    setIsTesting(true);
    setIsProject(false);
    setIsImageView(false);
    setIsFinalLayer(false);
  };

  const handleReport = () => {
    setisReport(true);
  };

  const HandleCloseReport = () => {
    setisReport(false);
  };

  //handle outline to hover all segements
  const [isOutLine, setIsOutLine] = useState(false);
  const handleOutline = () => {
    ;
    setIsOutLine(!isOutLine);
  };
  const handleProjectLayerChange = () => {
    setIsProjectLayer(false);
    setIsImageView(false);
    setIsFinalLayer(false);
    setIsTesting(false); 
    setIsEditAnNonation(false);
  };

  // open canvas mode 2
  const [isEditAnnonation, setIsEditAnNonation] = useState(false);
  const handleToggleEditing = () => {
    
    setIsEditAnNonation(!isEditAnnonation);
    setIsImageView(!isImageView)
    setIsFinalLayer(false);
    setIsTesting(false);
    setIsProjectLayer(false);
  };

  // Edit segement from grid View
  var shapesLayer = new Konva.Layer();
  const getEditAnnoation = useSelector(getEditAnnotation);
  //(getEditSeg);
  useEffect(() => {
    if (getEditAnnoation) {
      
      setIsEditAnNonation(true);
      setIsImageView(false)
      setIsProjectLayer(false)
      setIsHoverLayer(false)
      
    }
  }, [getEditAnnoation]);

  const getSaveAnnotations= useSelector(getSaveAnnotation)


  useEffect(()=>{
    if(getSaveAnnotations){
      setIsEditAnNonation(false);
      
      setIsHoverLayer(true)
      dispatch(removeEditSegment())
    }
  },[getSaveAnnotations])


  const handleCallAPI=()=>{
    setIsEditAnNonation(false);
      setIsImageView(false)
      setIsProjectLayer(false)
      setIsHoverLayer(true)
  }
const getAllMsterArray= useSelector(getAllMasterJobs);

  const CallToSaveEditAnnotation=async()=>{
    if(getAllMsterArray &&
      getAllMsterArray.masterJob &&
      getAllMsterArray.masterJob.length>0 ){

 let jobId = getProject.jobId;
 let data=getAllMsterArray.masterJob
       try{
        const response = await axios.put(
          process.env.REACT_APP_API_STRAPI + "jobs/" + jobId,
          data
        );
       

       }catch(error){
        console.log("error on call API", error)
       }
   


      }

   
  }

  
  const[isNoise, setIsNoise]=useState(false)

  // noise 
  const handleNoise=() =>{
    setIsHoverLayer(false)
    setIsProjectLayer(false);
    setIsImageView(true);
    setIsNoise(true);
    setIsAnnotationMode(true)
  }
  //open noise component
  const handleNoiseRemove=()=>{
    setIsAnnotationMode(true);
    setIsNoise(true)
   
    // handleMouseDown()
  
  }

  const handleCloseNoise=()=>{
    
    setPoints(null);
    setNewPolygonPoint(null)
    setPolygonSize([]);
    setIsAnnotationMode(false);
    setNoseOpen(false)
  
  }

  // hide and Show side bar
  const [isSideBar, setIsSideBar]=useState(true);
  const handleHideLeftSideBar=()=>{
    setIsSideBar(false);
  }

  const handleShowLeftBar=( )=>{
    setIsSideBar(true)
  }

  const handleNewDesign=()=>{
    navigate("/home-page");
}

// change when gridView on select swatch change the imageView 
const getSwitchImageView=useSelector(getSwitchCanvas);

useEffect(() => {
  if(getSwitchImageView){
    setIsImageView(false);
    setIsProjectLayer(false);
    setIsFinalLayer(false);
    setIsTesting(false);
    setIsEditAnNonation(false);
    setIsHoverLayer(true)
  }
 dispatch(switchCanvas({
  isSwitchCanvas: false
 }))
  
},[getSwitchImageView])

const getEditSegmentations=useSelector(getEditSegmentation)

// open Reaanottaion Modal
const[isOpenReAnnote, setIsOpenReAnnotion]= useState(false)
useEffect(()=>{
  if(getEditSegmentations &&
    getEditSegmentations?.isReAnnoatable 
    ){
      
    setIsEditAnNonation(false);
    setIsImageView(true);
    setIsOpenReAnnotion(true)
   
  } 
},[getEditSegmentations])

// reset Re Annotation Modal
const handleResetReannotation=() => {
  setIsOpenReAnnotion(false)
}

// Start Re Annottaion
const [reAnnotate,setReAnnotate]= useState(false)
useEffect (()=>{
  if(getEditSegmentations &&
     getEditSegmentations.isStartAnnotation&&
     getEditSegmentations.labelName&&
     getEditSegmentations?.segName&&
     getEditSegmentations?.polygon
    
    ){
      // const values =   getEditSegmentations?.polygon
      //     .split(",")
      //     .map((value) => parseInt(value.trim(), 10));

      //   handleHoverDemoSegment(values);
      setIsOpenReAnnotion(false)
      setStartAnnotation(!startAnnotation);
      activateAnnotationMode(true);
      setReAnnotate(true)
      setNewLabel(getEditSegmentations?.labelName)
      setNewSegment(getEditSegmentations?.segName);
      setIsImageView(true)
      setIsProjectLayer(false);
      setIsEditAnNonation(false)
  }
},[getEditSegmentations])


//handle hover layer
const handleHoverLayer=() => {
  setIsImageView(false);
  setIsProjectLayer(false);
  setIsFinalLayer(false);
setIsHoverLayer(true)
  setIsEditAnNonation(false);
}
  return (
    <>
    <Header/>
     <div className="ai-wrapper">
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/konva@8.3.1/konva.min.js"></script>
      </Helmet>
      <div className="ai-left-sidebar-section"> 
      { isSideBar &&<Leftsection />}
  
      {/* <div className="ai-left-sidebar bg-light">
      
      
      </div> */}
       <Slider
          hide={handleHideLeftSideBar}
          show={handleShowLeftBar}
          />


      </div>
         
          {/* sidebar icon start */}
         

      <div className="ai-right-wrapper Cursor project-layer-images">
        <h2> Project View</h2>

        <Modal
          className="save-annotaions-popup"
          closeOnOverlayClick={false}
          isOpen={showPopup}
          onClose={() => setshowPopup(false)}
        >
          <ModalOverlay />
          <ModalContent className="save-annotaions-popup">
            <ModalHeader className="save-annotation-title">
              Save Annotation
            </ModalHeader>
            <ModalCloseButton />

            <div className="save-annotation">
              <div className="annotation-save">
                <div className="save-annotation-images">
                  <img src={noimage} alt="No"></img>
                </div>
                <div className="save-annotation-btn">
                  <button
                    type="button"
                    class="chakra-button css-1ut02yo"
                    value="Window2"
                    fdprocessedid="8sk786"
                  >
                    {" "}
                    {newLabel}
                  </button>
                </div>
              </div>

              <div className="save-annotation-icon">
                <Button className="save-annotation-icon-check">
                  <i className="bi bi-list-check"></i>
                </Button>
                <Button className="save-annotation-icon-check">
                  <i class="bi bi-images"></i>
                </Button>
                <Button className="save-annotation-icon-check">
                  <i className="bi bi-trash"></i>
                </Button>
              </div>
            </div>

            <ModalFooter className="save-annotation-button">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => handleYes(newSegment, newLabel, flattenedPoints)}
              >
                Yes
              </Button>
              <Button colorScheme="red" onClick={handleNo}>
                No
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>







        <Tools
        canvasMode={handleProjectLayerChange}
        //canvasMode2={handleToggleEditing}
        noise={handleNoise}
        outLine={handleOutline}
        compare={handleSwitchChange}
        annotation={handleAnotation}
        undo={undo}
        reset={reset}
        FinalLayer={handleFinalLayer}
        screenShot={captureScreenshot}
        report={handleReport}
        hoverLayer={handleHoverLayer}
        />



        {/* polygon Annoation */}

        {isImageView && (
          <div className="ai-right-wrapper-body  Cursor">
            {polygon &&
              (selectedImage ? (
                <div>
                    
                


                  <Stage
                    id="testStageID"
                    width={size.width || 1023}
                    height={size.height || 592}
                    onMouseMove={handleMouseMove}
                    onMouseDown={handleMouseDown}
                    // Set the background of the Stage to transparent
                    style={{ background: "transparent" }}
                  >
                    <Layer>
                      <div className="canvas-images">
                        {selectedImage && (
                          <div>
                            <Image
                              ref={imageRef}
                              image={selectedImage}
                              width={size.width || 1023}
                              height={size.height || 592}
                              x={0}
                              y={0}
                              crop={{
                                x: position.x - size.width / scale / 2, // Use size.width
                                y: position.y - size.height / scale / 2, // Use size.height
                                width: size.width / scale, // Use size.width
                                height: size.height / scale, // Use size.height
                              }}
                            />
                          </div>
                        )}

                        {
                          <>
                            {/* <RoofPallet />
                            <Wall_pallet />
                            <GarageDoor />
                            <Door /> */}
                            {/* <DoorsPallet /> */}
                            {/* <WindowPallet />

                            <TrimPallet />

                            <Gutter />

                            <Light /> */}
                          </>
                        }
                      </div>

                      {/* Existing code for polygons and lines */}

                      {isHover && (
                        <PolygonAnnotation
                          points={points}
                          flattenedPoints={flattenedPoints}
                          handlePointDragMove={handlePointDragMove}
                          handleGroupDragEnd={handleGroupDragEnd}
                          handleMouseOverStartPoint={handleMouseOverStartPoint}
                          handleMouseOutStartPoint={handleMouseOutStartPoint}
                          isFinished={isPolyComplete}
                          getTargetData={getTargetData}
                        />
                      )}

                      {/* Iterate through multiPolygons and render each multipolygon */}

                      {isMultipleHover &&
                        multiPolygon.map((polygon, index) => (
                          <PolygonAnnotation
                            key={index}
                            points={polygon}
                            flattenedPoints={polygon} // Adjust this if needed
                            handlePointDragMove={handlePointDragMove}
                            handleGroupDragEnd={handleGroupDragEnd}
                            handleMouseOverStartPoint={
                              handleMouseOverStartPoint
                            }
                            handleMouseOutStartPoint={handleMouseOutStartPoint}
                            shouldDisplay={true}
                            isFinished={true} // Set to true assuming all multipolygons are complete
                          />
                        ))}
                    </Layer>
                  </Stage>
                </div>
              ) : (
                <></>
              ))}
          </div>
        )}

        {/* canvas Mode */}
        {isProjectLayer && <NewImageView />}   
        {isFinalLayer && <FinalLayer />}
        {isTesting && <Testing />}
        {isEditAnnonation && <EditAnnonation
        moveToImageView={handleCallAPI}
        />}
        {isHoverLayer&&< HoverLayer/>}
        <div className="area-select-btn"></div>

        {popupPosition && hoverPolygonPoints && (
          <div
            className="area-segment-popup"
            style={{
              display: popupPosition ? "block" : "none",
              position: "absolute",
              top: popupPosition?.y + 100 + "px",
              left: popupPosition?.x + 260 + "px",
              backgroundColor: "#fff", // Set your preferred background color
              border: "1px solid #fff", // Add a border for styling
              padding: "10px", // Add some padding for spacing
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a shadow for depth
            }}
          >
            <p className="area-segment">{nameOfHover}</p>
          </div>
        )}

        {/* // For Project start */}

        <div style={{ marginTop: -500, marginLeft: 400 }}>
          {isLoading && <Progress />}{" "}
        </div>
        {/* // For Project End */}
      </div>
      {/* Right side bar div END */}
      <div
        id="selectBox"
        className="select-box-labels"
        style={{ display: isDivVisible ? "block" : "none" }}
      ></div>

      <Alert />
      <AddSegement isUpdateSegment={isUpdateSegment} />
      <DeleteSegmentSpin />
      {samload && <SamLoading />}
      {/* {<SamLoading/>} */}

      {
        <SegredateSamData
          samDataFromAPI={samDataFromAPI}
          resetsamDataFromAPI={handleresetsamDataFromAPI}
        />
      }

      {isOpenEdit && (
        <AddSgmentForm
          isOpenEdit={isOpenEdit}
          isClose={handleCloseEditModal}
          addSegment={handelAddSegmentFrom}
        />
      )}
      {/* { <DeletingLoading /> } */}
      {getDeleteLoading ? <DeletingLoading /> : ""}
      <ReloadAllProject />
      <BlankPage />
      {swatchDeleting && <SwatchDeletingSpin />}

      {isAddSegment && (
        <AddSegmentToDB resetSegmentData={handleResetSegmentData} />
      )}

      <Report isOpen={isReport} onClose={HandleCloseReport} />

      <OutLine isOutLine={isOutLine} />

      {/* handle Noise  */}
         <Noise 
         
         noseOpen={noseOpen}
         resetNoise={handleCloseNoise}
         newPolygonPoints={newPolygonPoints}
         />

    {/* ReAnnotation */}
    <ReAnnotation
    isOpenReAnnote={isOpenReAnnote}
    resetReAnnotation={handleResetReannotation}
    />
    </div>
    </>
   
  );
}
export default ImageView;

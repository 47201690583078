import React, { useState, useEffect } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'



import { Tooltip } from '@chakra-ui/react'
import "./CustomerEachSegment.css";


import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  Input,
  FormLabel,
} from '@chakra-ui/react'
import CustomerAction from "./CustomerAction";
import { addCustomerViewPallet ,updateCustomerCurrentPallete} from "../../slices/CustomerSlice/customerViewpalletSlice"
import { useDispatch, useSelector } from 'react-redux';
// import {selectPopeHover} from "../../slices/popHover/PopHoverSlice"
import {selectPopeHover} from "../../slices/popHover/PopHoverSlice";
import {updateCustomerSelectedMasterArray} from "../../slices/MasterArraySlice"
//import UniqueSwatches from '../../NewDesign/leftsection/EachSegment/UniqueSwatches';
import CustomerUniqueSwatch from './CustomerUniqueSwatch';
const CustomerEachSegment = ({
  uniqueSwatch,
  masterSeg,
  segmentName,
  masterName,
  masterIndex,
  openDeleteModal,
  openSelectSwatch,
  openViewPallet,
  noViewPallet
}) => {

 
  const dispatch = useDispatch()
  // display pallet Image on main View
  const handleImageSelectedPallet = (swatch, segName) => {


    if(!swatch.selectedCustomer){
      dispatch(updateCustomerCurrentPallete({
        segName:segName,
         swatchId:swatch.swatch.data.id,
         palletImage:swatch?.SegmentImagePath
       }))
    
      //   dispatch(addCustomerViewPallet({
      //     customerViewPallet: swatch
      //   }))
      //    // update master Array
      //     let swatch_id;
      //   if (typeof swatch?.swatch?.data?.id === 'string') {
      //    swatch_id=parseInt(swatch.swatch.data.id, 10);
      //  }else{
      //    swatch_id=swatch.swatch.data.id
      //  }
         dispatch(updateCustomerSelectedMasterArray({
          labelName:masterName,
          segName: segName,
          swatchId:swatch?.swatch?.data?.id,  ///id in string
          selectedCustomer:true
         }))
    } else if(swatch.selectedCustomer){

      dispatch(updateCustomerCurrentPallete({
        segName:segName,
         swatchId:swatch.swatch.data.id,
         palletImage:null
       }))

       //update in master Array
        dispatch(updateCustomerSelectedMasterArray({
          labelName:masterName,
          segName: segName,
          swatchId:swatch?.swatch?.data?.id,  ///id in string
          selectedCustomer:false
         }))
    }

  

  };


  const [thumbnailUrl, setThumbnailUrl] = useState(""); // To store the data URL of the thumbnail

  //  const [showthumbnailUrl, setshowThumbnailUrl] = useState(false); // To store the data URL of the thumbnail
  

  const handleImage = async (event) => {
    const imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target.result;
    
    };

    reader.onloadend = () => {
      // When the FileReader finishes reading, set the data URL as the thumbnail URL
      setThumbnailUrl(reader.result);
    };

    // Read the contents of the selected image file as a data URL
    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };


  const fileInputRef = React.createRef();

  const handleDragImage = () => {
    fileInputRef.current.click();
  // setOpenDiv(false);
  };

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleHidePallet=(segName,swatchId)=>{
    // 
    let swatch_id;
    if (typeof swatchId === 'string') {
     swatch_id=parseInt(swatchId, 10);
   }else{
     swatch_id=swatchId
   }
    dispatch( selectPopeHover({
     segmentName:segName,
     ishide:true,
     isUnhide:false,
     swatchId:swatch_id  // id in integer
    }))

   

 }


  return (
    <>

          {/* unique Swatches */}
          <CustomerUniqueSwatch
        uniqueSwatch={uniqueSwatch}
        masterName={masterName}
        masterSeg={masterSeg}

        />

      {masterSeg &&
        masterSeg.length > 0 &&
        masterSeg.map((seg, index) =>
        (

          <div
            // active class ="active-section"
            className={`seg-tabpanel   dzi-${seg.name} `}
          //  onMouseLeave={handleCloseHoverSegment}
          // onMouseEnter={() =>
          //   handleOnHoverSegment(
          //     seg.name,
          //     seg.annotationPolygon
          //   )
          // }

          >
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem className='accordion-seg'>

                <AccordionButton className='select-seg-dropdown'>
                  <div className='project-seg-box customer-seg-box'>
                  
                      {/* <i class="bi bi-eye-slash"></i> */}

                      <span className='segements-title'
                        key={seg.name}
                      >{seg.name}</span>

                    <AccordionIcon className="dropdown-icon" />
                  </div>




                </AccordionButton>

                <AccordionPanel pb={4}>

                  <div className="project-swatch-select-boxs">
                    <div className="project-swatch-img">

                      {seg.SelectSwatchJob &&
                        seg.SelectSwatchJob.length > 0
                        ? seg.SelectSwatchJob.map((swatch) => {

                          return (
                            <div className="select-pallet-image">

                             {  <div className="pallet-img"
                                key={swatch.id} 
                                onClick={()=>handleHidePallet(segmentName,swatch.swatch.data.id)}
                              >

                                <img
                                  src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch.swatch?.data?.attributes.image_s3}`}
                                  width={55}
                                  alt={
                                    swatch.swatch?.data?.attributes.name
                                  }
                                  onClick={() =>
                                    handleImageSelectedPallet(
                                      swatch, seg.name
                                    )
                                  }
                                />


                                {swatch.selectedCustomer &&<div className=" pallet-checkbox">
                                  <img className="check-box-img" src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/Group_3_1_8943bd053e.png'></img>
                                </div>}



                                { }
                              </div>}
                              <div className="pallet-action">


                                <Popover trigger="hover" >
                                  <PopoverTrigger>
                                    <div className="pallet-information-icon">
                                      <i class="bi bi-info">  </i>
                                    </div>
                                  </PopoverTrigger>
                                  <Portal>
                                    <PopoverContent>

                                      <PopoverBody>
                                        <div className="pallet-img-inf-popup">
                                          <div className="pallet-images-inf">

                                            <img
                                              src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch.swatch?.data?.attributes.image_s3}`}
                                            />



                                            {/* <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_4_dcd9443ee3.png"></img> */}
                                          </div>

                                          <div className='pallet-select-tool-section '>
                                            <Tooltip hasArrow label='Fav' bg='gray.300' color='black'>
                                              <div className='pallet-select-tools icon-active'>                                
                                              {/* active className="icon-active" */}

                                                <i class="bi bi-star"></i>
                                              </div>
                                            </Tooltip>

                                            <Tooltip hasArrow label='Upload Image' bg='gray.300' color='black'>
                                              <div onClick={onOpen}    className='pallet-select-tools '>
                                                <i class="bi bi-upload"></i>
                                              </div>
                                            </Tooltip>

                                           

                                            <Tooltip hasArrow label='Hide Pallet' bg='gray.300' color='black'>
                                              <div className='pallet-select-tools '>
                                                <i class="bi bi-eye-slash"></i>
                                              </div>
                                            </Tooltip>

                                            <Tooltip hasArrow label='View Details' bg='gray.300' color='black'>
                                              <div className='pallet-select-tools '>
                                                <i class="bi bi-image"></i>
                                              </div>
                                            </Tooltip>

                                            <Tooltip hasArrow label='Remove Pallet' bg='gray.300' color='black'>
                                              <div className='pallet-select-tools'>
                                              <i class="bi bi-x-lg"></i>                                    </div>
                                            </Tooltip>


                                          </div>

                                          <div className="pallet-images-details">
                                            <h3>{seg.name}</h3>
                                            <h4>Brand : <span>Demo Brand Wall</span></h4>
                                            <h4>Label : <span>Demo Lable Wall</span></h4>
                                            <h4>Style : <span>Demo Wall Style </span></h4>
                                            <p>Model Size: 4'4" x 0'10" x 7'0"</p>
                                          </div>
                                        </div>
                                      </PopoverBody>

                                    </PopoverContent>
                                  </Portal>
                                </Popover>




                                {/* <div className="pallet-fav">
                                                <i class="bi bi-star"></i>
                                            </div> */}
                              </div>
                            </div>
                          )
                        }

                        )
                        :
                        (
                          <>
                            {/* <span className='no-pallet-select-swatch'>No Pallet selected. To select Pallets,<br></br> click <i class="bi bi-list-check"
                            ></i> </span> */}

                              <span className='no-pallet-select-swatch'>No Pallet Assigned.</span>
                            {/* <img
                              key="no-data1"
                              src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_4_4897613ec9.png"
                              width={100}

                              className="home-images"
                              alt="home icon"
                            /> */}

                            {/* <img
                              key="no-data2"
                              src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_4_4897613ec9.png"
                              width={100}

                              className="home-images"
                              alt="home icon"
                            /> */}

                            {/* <img
                              key="no-data3"
                              src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_4_4897613ec9.png"
                              width={100}

                              className="home-images"
                              alt="home icon"
                            /> */}

                          </>)
                      }



                    </div>





                    {/* popup upload start */}
             

                    <Modal   closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                      <ModalOverlay  className="upload-pallet-img-section"/>
                      <ModalContent className="upload-img-section" >
                        <ModalHeader className='upload-img-title'> Upload Pallet Image </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                    

                        <FormControl className="upload-button">
                                  {/* <FormLabel>Upload Image</FormLabel> */}

                                  <Input
                                    className="create-project-input"
                                    type="file"
                                    onChange={handleImage}
                                    borderRadius="md"
                                    borderColor="gray.400"
                                    style={{ display: "none" }}
                                    _focus={{ borderColor: "blue.500" }}
                                    ref={fileInputRef}
                                  />
                                </FormControl>

                                <div>
                                  <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/after_b5904e1f17.jpg'></img>
                                        </div>

                                  <div className="upload-pallet-img" onClick={handleDragImage} >
                                    <div className="upload-icon">     
                                    <div className='upload-icons'>          
                                      <i class="bi bi-cloud-arrow-up"></i>
                                      </div>
                                      <div className='combo-text'>
                                      <p>
                                        Click  to upload or darg and drop pallet image  here

                                      </p>
                                      </div>
                                    </div>
                                  </div>


                                  {thumbnailUrl && (<div className='upload-thumbnail-image'>
                                    <img 
                                      src={thumbnailUrl}
                                      alt="Thumbnail"
                                      style={{ maxWidth: "100%", marginTop: "10px" }}
                                    />
                                    </div>
                                  )}



                        </ModalBody>

                        <ModalFooter>
                        <Button className='upload-image-button-delete' onClick={onClose}>Cancel</Button>
                          <Button className='upload-image-button' colorScheme='blue' mr={3}>
                            Save
                          </Button>

                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  {/* popup upload end */}



                  </div>

                </AccordionPanel>
              </AccordionItem>
            </Accordion>












          </div>


        ))}

    </>

  )
}

export default CustomerEachSegment
import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { loadingAlert } from "../slices/LoadingSlice";
import { deleteProjectView, renameProject } from "../slices/ProjetcViewSlice";
const DeleteAPI = ({ projectId, resetProjectId }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const isMounted = useRef(true);
  useEffect(() => {
    if (projectId && isMounted.current) {
      handleDelete();
      return () => {
        isMounted.current = false;
      };
    }
  }, [projectId]);

  const handleDelete = async () => {
    dispatch(
      loadingAlert({
        isLoading: true,
      })
    );
    try {
      const response = await axios.delete(
        process.env.REACT_APP_API_STRAPI + "projects/" + projectId
      );
      // console.log(response);
      if (response.status === 200) {
        isMounted.current = true;
        toast({
          title: "Project deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "center-bottom",
        });
        dispatch(
          deleteProjectView({
            projectId: projectId,
          })
        );
        dispatch(
          loadingAlert({
            isLoading: false,
          })
        );
        resetProjectId();
      }
      //   setProjectId(null);
      //   setCustomerId(getCustomerDetail.customerId);
    } catch (error) {
      toast({
        title: "Error: " + error.message,

        status: "error",
        duration: 5000,
        isClosable: true,
        position: "center-bottom",
      });
    }
    resetProjectId();
  };
  return <div></div>;
};

export default DeleteAPI;

import React, { useEffect, useState } from 'react'
import GetProjectById from "./GetProjectById"
import GetScreenResolution  from "./GetScreenResolution"
import All_Labels_Brands_SwatchStyle from "../All_Labels_Brands_SwatchStyle/All_Labels_Brands_SwatchStyle"
import { useDispatch } from 'react-redux'
import {palletLoading} from "../slices/AlertSlice"
const ProjectUrlHome = () => {
    const dispatch= useDispatch()
    const [projectId, setProjectId]= useState(null)
    const currentUrl = window.location.href;
    useEffect(()=>{
    if(currentUrl){
      dispatch(palletLoading({ palletLoading: false }));
        const match =currentUrl.match(/\/(\d+)$/);
        console.log('url',match )
        setProjectId(match[1])
    }
       
    },[currentUrl])

    const handleResetProjectId=()=>{
        setProjectId(null)
    }
  return (
    <div>

      {/* get all brand */}
      <All_Labels_Brands_SwatchStyle/>

      {/* get user project id */}
       {projectId!=null && 
       <GetProjectById
       projectId={projectId}
       resetProjectId={handleResetProjectId}
       />}


        {/* get user screen resolution */}
       <GetScreenResolution/>
    </div>
  )
}

export default ProjectUrlHome
import React, { useEffect } from "react";
import {
  CircularProgress,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { getUpdateSegment as selectUpdateSegment } from "../slices/UpdateSegmentSlice"; // Rename the selector
import { useSelector } from "react-redux";

const DeleteSegmentSpin = () => {
  const updateSegment = useSelector(selectUpdateSegment); // Use the renamed selector

  useEffect(() => {}, [updateSegment]);
  return (
    <div>
      {updateSegment && (
        <div>
          <CircularProgress isIndeterminate color="green.300" />
          <Alert status="success" variant="solid">
            <AlertIcon />
            <AlertTitle>Delete is in progress ...</AlertTitle>
            <AlertDescription></AlertDescription>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default DeleteSegmentSpin;

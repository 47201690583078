import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  labelId: null,
  isLabel: false,
  brandId: null,
  isBrand: false,
  swatchStyle: null,
  isSwatchStyle: false,
  swatches: null,
};

const Label_Brand_Swatch_Slice = createSlice({
  name: "addPallet",
  initialState,
  reducers: {
    addPallet: (state, action) => {
      state.labelId = action.payload.labelId;
      state.isLabel = action.payload.isLabel;
      state.brandId = action.payload.brandId;
      state.isBrand = action.payload.isBrand;
      state.swatchStyle = action.payload.swatchStyle;
      state.isSwatchStyle = action.payload.isSwatchStyle;
      state.swatches = action.payload.swatches;
    },
  },
});
export const { addPallet } = Label_Brand_Swatch_Slice.actions;
export const getAddedPallet = (state, action) => state.addPallet;
export default Label_Brand_Swatch_Slice.reducer;

import React, { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { Spinner } from '@chakra-ui/react'
import "./Alert.css"
import { getPalletLoading } from "../slices/AlertSlice";
import { useSelector } from "react-redux";
const AlertInfo = () => {
  const isAlertVisible = useSelector(getPalletLoading);
  return (
    
    <div className="alert-popup select-pallet-loading">
      
      {isAlertVisible && (
       
        // <Alert status="success" variant="solid" >
        //   <Spinner />
        //   {/* <AlertIcon /> */}
        //   <AlertTitle>Selected Pallet is Loading ...</AlertTitle>
        //   <AlertDescription></AlertDescription>
        // </Alert>
        <>
        
              <div class="boxes">
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

        </>
        
      )}
    </div>
  );

};

export default AlertInfo;

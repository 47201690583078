import React, { useEffect, useState } from "react";
import { Login } from "../GraphQL/GraphQl";
import useFetch from "../Hook/useFetch";
import axios from "axios";
import { addProjectId } from "../slices/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, getEmail, loginSuccess } from "../login/AuthSlice";
import { useToast } from "@chakra-ui/react";
import { getAllCustomer } from "../slices/GetAllCustomer";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Customer from "./Customer";
export const LoginDetail = ({ emailId, userId, resetLogin1 }) => {
  const dispatch = useDispatch();
  // const email = useSelector(getEmail);
  const login = useSelector(getCustomer);
  const toast = useToast();
  const customers = useSelector(getAllCustomer);
  const navigate = useNavigate();
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (emailId && userId &&token) {
      
      getUserId();
    }
  }, [emailId, userId,token]);
  const [emailid, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const getUserId = async () => {
   
    const authToken = "Bearer " + token;
    try {
      const response = await axios.get(
        `https://aiadmin.dzinly.org/content-manager/collection-types/plugin::users-permissions.user?page=1&pageSize=10&sort=username:ASC&_q=${userId}`,
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );

      if (response.status === 200) {
        setEmail(response.data?.results[0]?.email);
        setRole(response.data?.results[0]?.role.type);
      }
    } catch (e) {
      alert("Error: " + e.message);
    }
  };

  // call function when the user as customer
  const handleCloseLogin = () => {
    resetLogin1(role);
    setEmail(null);
    setRole(null);
  };
  return (
    <>
      {emailid != null && role != null && (
        <Customer emailid={emailid} role={role} resetLogin={handleCloseLogin} />
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import "../../assets/styles/simplebar.css";
import "../../assets/sass/main-ai.css";
import { Link, Navigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, loginSuccess, logoutSuccess } from "../login/AuthSlice";
import { removeAllSegment } from "../slices/AllSegmentSlice";
import {
  removeProjectView,
  updateProjectView,
  addProjectView,
} from "../slices/ProjetcViewSlice";
import { removeJobs } from "../slices/JobSlice";
import { removeDeleteSegment } from "../slices/AfterDeleteSegmentSlice";
import {
  palletLoading,
  closeModals,
  onAllHover,
  swatchDeleting,
  deleteLoading,
} from "../slices/AlertSlice";
import { removeProjectId, getProjectId } from "../slices/ProjectSlice";
import ReloadProject from "../ReloadProject/ReloadProject";
import CreateProject from "../CreateProject/CreateProject";
import { useToast } from "@chakra-ui/react";
import { removeSelectedPallete } from "../slices/SelectedPalletdSlice";
import {
  getReLoadProject,
  reLoadProject,
  samLoading,
} from "../slices/AlertSlice";
import { removeMasterArray } from "../slices/MasterArraySlice";
import {
  removeTramsparentImage,
  removeAllPalletImages,
} from "../slices/TransparentPalletImgSlice";
import ReloadProjects from "./ReloadProjects";

import { removeCurrentPallete } from "../slices/CurrentSelectedPalleteSlice";
import NewOrExistingCreateProject from "../HomePage/NewOrExistingCreateProject";
import UploadExistingProject from "../UploadExistingProject/UploadExistingProject";
import { useNavigate } from "react-router-dom";

import { removeFolder } from "../slices/FolderSlice";
import { removeDesignerJobs } from "../slices/Designer/DesignerJobListSlice";
import { removeGridMultiSelect } from "../slices/gridView/MultiSelectionSegement";

import { reSetAnnotation } from "../slices/EditSegment/EditSegment"
import { removeEditSegment } from "../slices/EditSegment/EditSegment"

import { resetMouseEvent } from "../slices/MouseEvent/MouseEventSlice"

import { removeCombinationSwatch, resetComboValue } from "../slices/combinationSwatch/CombinationSwatchSlice"
function Header() {
  const dispatch = useDispatch();
  const [token, setToken] = useState(false);
  const customerDeatails = useSelector(getCustomer);
  const getReload = useSelector(getProjectId);
  const [isOpen, setIsOpen] = useState(false);
  const getReloadProjectS = useSelector(getReLoadProject);
  const toast = useToast();
  const [isProfile, setIsProfile] = useState(false);
  const [projectIds, setProjectIds] = useState();
  const navigate = useNavigate();
  // handle SignOut
  const handleSignOut = () => {

    setIsProfile(false);
    dispatch(logoutSuccess());
    handleRemoveReduxStore();
    sessionStorage.removeItem("customerLogin");
    document.cookie = `authToken=`;
    dispatch(removeProjectView());
  };
  // remove master Array and AllSegmentArray
  const handleRemoveReduxStore = () => {
    dispatch(resetComboValue())
    dispatch(removeCombinationSwatch())
    dispatch(removeGridMultiSelect());
    dispatch(removeTramsparentImage());
    dispatch(removeAllPalletImages());
    dispatch(removeSelectedPallete());
    dispatch(removeAllSegment());
    // dispatch(removeProjectView());
    // dispatch(alertVisible(false));
    dispatch(removeProjectId());

    dispatch(removeEditSegment()) // reset the edit annottaion
    dispatch(removeJobs());
    dispatch(removeDeleteSegment());
    dispatch(palletLoading({ palletLoading: false }));
    dispatch(removeMasterArray());
    dispatch(removeCurrentPallete());
    dispatch(removeFolder());
    dispatch(removeDesignerJobs());
    dispatch(
      swatchDeleting({
        isSwatchDeleting: false,
      })
    );
    dispatch(
      deleteLoading({
        isDeleteSegmentLoading: false,
      })
    );
    sessionStorage.removeItem("CreateProject");
    dispatch(
      onAllHover({
        isAllHover: false,
        segmentName: null,
        allpolygon: [],
      })
    );

    dispatch(removeEditSegment())// edit segemnetation
    dispatch(resetMouseEvent())    // reset mouse event slice 
    if (getReloadProjectS) {
      setProjectIds(customerDeatails.customerId);
      dispatch(removeProjectView());
      dispatch(
        reLoadProject({
          reLoadProject: false,
        })
      );
    }
  };

  const handleHomePage = () => {
    // handleRemoveReduxStore();
  };
  const [callReload, SetReload] = useState(false);
  const handleHomeClick = (e) => {
    setIsProfile(false);
    // e.preventDefault();
    SetReload(true);
    dispatch(removeSelectedPallete());
    handleRemoveReduxStore();
    dispatch(
      onAllHover({
        isAllHover: false,
        segmentName: null,
        allpolygon: [],
      })
    );
  };

  const handleResetReload = () => {
    SetReload(false);
  };

  const [isNewModal, setIsNewModal] = useState(false);
  // handle create project
  const [isHome, setIsHome] = useState(false);
  const handleCreateProject = () => {

    if (customerDeatails.isAuthenticated) {
      setIsNewModal(true);

    } else {
      toast({
        title: "Please Login with Credentials",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "center-bottom",
      });
    }
  };

  // open create project form
  const hanldeNewProject = () => {
    setIsOpen(true);
    setIsNewModal(false);
    setIsHome(true);
  };
  // close newor Existing Modal
  const handleCloseNewModal = () => {
    setIsNewModal(false);
  };

  const handleCloseCreateProjectModal = () => {
    setIsNewModal(false);
  };

  // reset The poject
  const handelResetProjectId = () => {
    setProjectIds(null);
  };

  // open sign out and dashboard

  const handleProfile = () => {
    setIsProfile(!isProfile);
  };

  const [isUploadExisting, setIsUploadExisting] = useState(false);
  // upoad existing folder  modal
  const handleUploadExisting = () => {
    setIsNewModal(false);
    setIsUploadExisting(true);
  };
  const handleCloseUploadExistingModal = () => {
    setIsUploadExisting(false);
  };

  // mobile view
  const [openMobile, setOpenMobile] = useState(false);
  const handleMobileView = () => {
    setOpenMobile(true);
  };

  const handleMobileViewClose = () => {
    setOpenMobile(false);
  };

  return (
    <div className="header-section">
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top header-border header-menu-section">
        <div className="header-dzinly">
          <div className="logo">
            <Link
              to="/profile"
              className="p-0"
              onClick={handleHomePage}
            >
              {/* <img width="160" src={logo} alt="Logo"/> */}
              <img width="250" src={logo} alt="Logo" />
            </Link>
          </div>

        

          {openMobile && !customerDeatails.isAuthenticated && (
            <>
            <div className="customer-details-menu">
              <li>
                <Link to="/login" onClick={handleMobileViewClose}>
                  Login 
                </Link>
              </li>
              <li>
                <Link to="/signup" onClick={handleMobileViewClose}>
                  Sign Up
                </Link>
              </li>
              </div>
            </>
          )}


          <div
            className="collapse  navbar-content nav-menu"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center menu-bar-header">


              <li className="nav-create-project create-project-btn-header">
                <span className="create-project-icon">
                  <button onClick={handleCreateProject}>
                    Create Project &nbsp;
                    <i class="bi bi-cloud-arrow-up"></i>
                  </button>
                </span>
              </li>
              </ul>
          </div>
              {!customerDeatails.isAuthenticated ? (
                <>
                <div className="menubar-title">
                  <ul className="d-flex menu-top-bar">
                  <li className="nav-item me-2  menu-icon">

                    <Link to="/login">Login </Link>
                  </li>
                  <li className="nav-item me-2 menu-icon">
                    <Link to="/signup">Sign Up</Link>
                  </li>
                  </ul>
                  </div>

                </>
              ) : (
                <>
                 


                  <div class="dzinly-ai-dropdown project-user-title">
                    <button class="dzinly-designer-title">
                      &nbsp;{customerDeatails.customerName}
                      <i class="bi bi-caret-down-fill"></i>
                    </button>
                    <div class="dzinly-details-title nav-create-project-dropdown">

                      <Link
                        to="/profile" onClick={(e) => handleHomeClick(e)}

                      >
                        Dashboard
                      </Link>



                      <Link to="/login"
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </Link>

                    </div>
                  </div>



                </>
              )}
        

          <div className="cre-project-btn toggle-btn">
            <Button
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className="navbar-toggler-icon"
                onClick={handleMobileView}
              ></span>
            </Button>
          </div>

          
        </div>
      </nav>
      {getReload.reload && (
        <ReloadProject callReload={callReload} reset={handleResetReload} />
      )}
      {isNewModal && (
        <CreateProject
          isOpen={isNewModal}
          onClose={handleCloseCreateProjectModal}
        />
      )}
      {projectIds && (
        <ReloadProjects
          projectIds={projectIds}
          resetProjectId={handelResetProjectId}
        />
      )}
      {/* {isNewModal && (
        <NewOrExistingCreateProject
          isNewModal={isNewModal}
          onCloseModal={handleCloseNewModal}
          newProject={hanldeNewProject}
          UploadExisting={handleUploadExisting}
        />
      )} */}
      {isUploadExisting && (
        <UploadExistingProject
          isOpen={isUploadExisting}
          onClose={handleCloseUploadExistingModal}
        />
      )}
    </div>
    // </div>
  );
}

export default Header;

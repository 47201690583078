import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openShareLinkModal: false,
   
}
const shareLinkSlice = createSlice({
    name: "shareLink",
    initialState: initialState,
    reducers: {
        OpenShareLink:(state, action)=>{
            state.openShareLinkModal = action.payload.openShareLinkModal;
        }
    }
})

export const { OpenShareLink } = shareLinkSlice.actions;
export const getShareLink = (state) => state.shareLink.openShareLinkModal;
export default shareLinkSlice.reducer;
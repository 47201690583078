import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {
  Button,
  FormLabel,
  HStack,
  Input,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { getDoorheightModal, closeDoorHeigthModal } from "../../../slices/doorheight/DoorheightSlice"
import "./DoorHeight.css"
const DoorHeight = () => {
  const getDoorheightModals = useSelector(getDoorheightModal)
  const dispatch = useDispatch();
  const [doorHeight, setDoorHeight] = useState(6)
  const handleCancel = () => {
    dispatch(closeDoorHeigthModal())
  }

  const handleOk = () => {
    dispatch(closeDoorHeigthModal())
  }


  return (
    <div>
      <Modal
        isOpen={getDoorheightModals} onClose={handleCancel}
      >
        <ModalOverlay />
        <ModalContent className='model-content'>
          <ModalHeader className=' chakra-model-title'>Door Height</ModalHeader>

          <ModalCloseButton />
          <ModalBody style={{margin:"0 auto" }}>
            <div style={{  marginTop:"14px" }}>
              <FormLabel className='select-height'> Select the height of door</FormLabel>
              <HStack maxW='300px'>
                <Button
                 className='numberbutton'
                  onClick={() => {
                    const val = (doorHeight + 0.1).toFixed(2)
                    setDoorHeight(parseFloat(val));
                  }}
                >+</Button>
                <Input

                  type="number"
                  value={doorHeight}
                  onChange={(e) => {
                    const val = parseFloat(e.target.value);
                    if (!isNaN(val)) { // Check if the parsed value is a valid number
                      setDoorHeight(val);
                    }
                  }}
                />
                <Button
                 className='numberbutton'
                  onClick={() => {
                    const val = (doorHeight - 0.1).toFixed(2)
                    setDoorHeight(parseFloat(val));
                  }}
                >-</Button>
              </HStack>
            </div>
          </ModalBody>

          <ModalFooter className='footer-btn'>
          <Button className=''
              onClick={handleCancel}
            >Cancel</Button>

            <Button
              onClick={handleOk}
            >Save</Button>
          </ModalFooter>

        </ModalContent>
      </Modal>
    </div>
  )
}

export default DoorHeight
import React, { useEffect, useState } from "react";
import "../Add_Edit_Pallet/ModalToAddStyle.css";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import { useSelector , useDispatch} from "react-redux";
import {selectAllItems} from "../slices/MasterArraySlice"
import { addSelectedPallete } from "../slices/SelectedPalletdSlice";

import { addNewSelectedSwatch } from "../slices/SelectedSwatchImage";
import { addSelectedPalletImag } from "../slices/TransparentPalletImgSlice";
const SegementArea = ({findIndexValue}) => {
  const [segementName, setSegementName] = useState("");
  const [segment, setSegment] = useState([]);
  const allSegmentation = useSelector(selectAllSegments);
 const masterArray= useSelector(selectAllItems)
 const dispatch=useDispatch()
  useEffect(() => {
    if (allSegmentation) {
      const newSeg = [...allSegmentation.allSegmentsArray]; // Create a copy of the array
      newSeg.sort(customSort);
      setSegment(newSeg);
    }
  }, [allSegmentation]);

  // Custom sorting function
  function customSort(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  // find the index 

const handleSegement=(SegName, allSegData)=>{

 // dispatch(addSelectedPallete({ selectedPallete: [allSegData] }));

    dispatch(
      addNewSelectedSwatch({
        newSelectedSwatch: allSegData,
      })
    );
    dispatch(
      addSelectedPalletImag({
        segmentName: SegName,
        selectedPlaletUrl: null,
        s3BucketImage: null,
        SwatchId: null,
      })
    );
  let labelName=SegName.match(/[a-zA-Z]/g).join('');
  let index;
  if(masterArray && masterArray.master&&masterArray.master.length>0 ){
     index= masterArray.master.findIndex(item=>
      item.name===labelName)
    
  }
 findIndexValue(index)

}
  return (
    <>
      {segment?.map((data, index) => {
        const isActive = data.name === segementName;
        const buttonStyle = isActive
          ? {
              color: "black",
              backgroundColor: "white",
            }
          : {};

        return (
          <div className="Area-category-box" key={index}>
            <button
              className="Area-button"
             onClick={()=>handleSegement(data.name, data)}
              style={buttonStyle}
            >
              {data.name}
            </button>
          </div>
        );
      })}
    </>
  );
};

export default SegementArea;

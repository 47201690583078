
import React from 'react'
import "./BlankPage.css"
const BlankPage=()=>{
    return(
        <div className='demo-images'>
            {/* <h2>asdasd</h2> */}

            {/* <div>
                <img src="https://thumbor.forbes.com/thumbor/fit-in/x/https://www.forbes.com/home-improvement/wp-content/uploads/2022/07/download-23.jpg"></img>
            </div> */}
        </div>
    )
}
export default BlankPage


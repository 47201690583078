import React from 'react'
import { Input,InputGroup,InputRightElement } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Button
} from '@chakra-ui/react'
import "./ShareLink.css";
import { useEffect , useState} from 'react';
import{getProjectId} from "../../slices/ProjectSlice";
import {useSelector} from "react-redux"
const ShareLink = ({ isOpen, onClose }) => {
  const [show, setShow] = React.useState(false)

  const [url, setUrl]= useState(null)

    const projectDetails= useSelector(getProjectId)
     useEffect(()=>{
        if(projectDetails &&
            projectDetails.jobId){
                let id=parseInt(projectDetails.jobId)
                setUrl(`https://www.dzinly.org/customerView/${id}`)
            }
     },[projectDetails])


     const handleLink=()=>{
      setShow(!show)
        if (url) {
            // Copy the URL to the clipboard
            navigator.clipboard.writeText(url)
              .then(() => {
                // Success message or action
                console.log('URL copied to clipboard');
              })
              .catch((error) => {
                // Error handling if copying fails
                console.error('Failed to copy URL to clipboard:', error);
              });
          }

     }
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent  className="share-model">
          <ModalHeader className='model-title'>Share</ModalHeader>
          <ModalCloseButton onClose={onClose} />
          <ModalBody >

       <div className=' share-model-section justify-content-center mt-3'>
            <div className='social-icon'>
              <i class="bi bi-facebook"></i>
              <p className='share-title'>WhatsApp</p>
            </div>
            <div className='social-icon'>
              <i class="bi bi-whatsapp"></i>
              <p className='share-title'>Facebook</p>
            </div>
            <div className='social-icon'>
              <i class="bi bi-twitter"></i>
              <p className='share-title'>Twitter</p>
            </div>
            
            <div className='social-icon'>
                <i class="bi bi-pinterest"></i>
              <p className='share-title'>Pinterest</p>
            </div>

            <div className='social-icon'>
              <i class="bi bi-envelope"></i>
              <p className='share-title'>Email</p>
            </div>
            </div>

           <div className='mb-5 mt-2'>
            <InputGroup size='md'>
      <Input
        pr='4.5rem'
        placeholder='Share Link'
        value={url}
      />
      <InputRightElement width='4.5rem'>

        <Button  className={`copy-btn ${show ? 'copied' : ''}`} h='1.75rem' size='sm' onClick={handleLink}
       
        
        style={{ backgroundColor: show ? '#2f855a' : '#293849', color: '#fff' }}>
          {show ? 'Copied' : 'Copy'}
        </Button>

      </InputRightElement>
           </InputGroup>
           </div>
          </ModalBody>
    
        </ModalContent>
      </Modal>

    </div>
  )
}

export default ShareLink
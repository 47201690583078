import React from "react";
import {useEffect, useState} from "react"
import "./Home.css";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header"
import { parse } from "graphql";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
 const getLoginDetail= JSON.parse(sessionStorage.getItem("customerLogin"))
  useEffect(()=>{
    if(getLoginDetail &&getLoginDetail.customerId!=null){
      navigate("/profile")
    }
  },[getLoginDetail])
  return (
    <div>
      <Header/>
      <div className="home-page">
        <div className="homepage-section container">
          <div className="homepage-icon">
            {" "}
            <i class="bi bi-buildings"></i>
          </div>
          <h1 className="home-page-title">Welcome to Home Page</h1>
        </div>

        <div className="ball"></div>
        <div className="ball1"></div>
        <div className="ball2"></div>
        <div className="ball3"></div>
        <div className="ball4"></div>
        <div className="ball4"></div>
        <div className="ball5"></div>
        <div className="ball6"></div>
        <div className="ball7"></div>
        <div className="ball8"></div>
        <div className="ball9"></div>
        <div className="ball10"></div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

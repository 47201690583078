import React, { useState } from "react";
import Login from "../login/Login";
import Header from "../../component/Header/Header"
const LoginHome = () => {
  const [isloading, setIsLoading] = useState(false);

  return (
    <>
    <Header/>
      <Login />
      {/* <GetAllCustomer /> */}
    </>
  );
};

export default LoginHome;

import React from 'react'
import {useState, useEffect} from "react"
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import "./Compare.css";
import{useSelector} from "react-redux"
import {getProjectId} from "../../slices/ProjectSlice";
import CompareLayer from "./CompareLayer"
import OriginalImageCanvas from "./OriginalImageCanvas"
const Compare = () => {
  const [project_image, setProjectImage]= useState(null)
  const getProjectImage= useSelector(getProjectId);
  useEffect(()=>{
    if(getProjectImage&&
      getProjectImage.projectImage){
       
          setProjectImage( getProjectImage.projectImage)
        

    }

  },[getProjectImage])


  
  return (
    <div className='compare-image'>
      <ImgComparisonSlider className='comapare-img' hover="hover">
    

        <figure slot="first" class="before">
          <OriginalImageCanvas/>
 {/* { project_image&&  <img width="100%" src={project_image}></img>} */}
    <figcaption>Before</figcaption>
  </figure>
  <figure slot="second" class="after">
    {/* <img width="100%" src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/before_7e38401ca2.jpg"></img>
     */}
    < CompareLayer/>
    <figcaption>After</figcaption>
  </figure>
    </ImgComparisonSlider>


    </div>
  )
}

export default Compare


import React, { useEffect, useState } from "react";
import { Stage, Layer, Image, Group } from "react-konva";
import masterImage from "../demo/home_images/master1.png";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import Konva from "konva";
import { useSelector } from "react-redux";
import { getProjectId } from "../slices/ProjectSlice";
const Testing = () => {
  const [isAnnotationMode, setIsAnnotationMode] = useState(false); // Set to true by default
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [points, setPoints] = useState([]);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [newPolygonPoints, setNewPolygonPoint] = useState([]);
  const [polygonSize, setPolygonSize] = useState([]);
  const [showPopup, setshowPopup] = useState(false);
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState([0, 0]);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  var shapesLayer = new Konva.Layer();
  var tooltipLayer = new Konva.Layer();
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const getProject = useSelector(getProjectId);
  useEffect(() => {
    testStage();
  }, []);

  useEffect(() => {
    if (getProject.projectName) {
      handleDemoImage(getProject.base64Image);
      // setProjectNameValue(getProject.projectName);
    } else if (getProject.base64Image == null) {
      setSelectedImage(null);
    }
  }, [getProject]);
  const handleDemoImage = (image) => {
    if (image) {
      const encodedImage = image;
      const tets = "data:image/png;base64," + encodedImage;
      // Decode the base64 image
      const decodedImage = atob(tets.split(",")[1]);
      // Convert the decoded data to a Uint8Array
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "image/png" });

      // Create an object URL from the Blob
      const imageUrl = URL.createObjectURL(blob);
      var img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {
        // imageRef.current = img;
      };
      setSelectedImage(img);
      testStage(imageUrl);
    }
  };
  const testStage = (img) => {
    // Create an image object
    const imageObj1 = new window.Image();
    imageObj1.onload = function () {
      // Create a Konva.Stage and Konva.Layer
      const stage = window.__stage; // Assuming you have a global reference to the stage

      if (!stage) {
        console.error("Stage not found");
        return;
      }

      const layer = new Konva.Layer();

      // Create a Konva.Image
      const darthVaderImg = new Konva.Image({
        image: imageObj1,
        width: 1095,
        height: 595,
        draggable: true,
      });

      // Create a Konva.Group
      const darthVaderGroup = new Konva.Group({
        x: 50,
        y: 50,
        draggable: true,
      });

      // Add the Konva.Image to Konva.Group and Konva.Group to Konva.Layer
      darthVaderGroup.add(darthVaderImg);
      layer.add(darthVaderGroup);

      // Add the Konva.Layer to Konva.Stage
      stage.add(layer);
    };

    // Set the source of the image
    imageObj1.src = img;
  };
  const [selectedSegment, setSelectedSegment] = useState(null);
  const getAllSegmentation = useSelector(selectAllSegments);
  const handleMouseDown = (e) => {
    if (!isAnnotationMode || isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && Array.isArray(points) && points.length >= 3) {
      setPolyComplete(true);
      setIsDivVisible(true);
      setNewPolygonPoint(points);
      setPolygonSize([...polygonSize]);
      setshowPopup(true);
    } else {
      const newPoints = Array.isArray(points)
        ? [...points, mousePos]
        : [mousePos];
      setPoints(newPoints);
    }
  };
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    // ("mousePos =>", mousePos);
    // const { x, y } = stage.getPointerPosition();
    handlestage(e);
    setMagnifierPosition(mousePos);

    setPosition(mousePos);
  };
  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  const handlestage = (e) => {
    if (!e) return; // Add this check to handle cases where the event is undefined

    var stage = e.target.getStage();
    stage.add(shapesLayer);
    stage.add(tooltipLayer);
    // stage.add(ImageLayer);

    stage.on("mouseover", function (evt) {
      var shape = evt.target;
      if (shape) {
        shape.opacity(1.1);
      }
    });
    stage.on("mouseout", function (evt) {
      var shape = evt.target;
      if (shape) {
        shape.opacity(0);
        tooltip.hide();
      }
    });
    stage.on("mousemove", function (evt) {
      var shape = evt.target;
      if (shape) {
        var mousePos = stage.getPointerPosition();
        var x = mousePos.x;
        var y = mousePos.y - 5;
        updateTooltip(tooltip, x, y, shape.attrs.key);
      }
    });
  };

  var tooltip = new Konva.Label({
    opacity: 1,
    visible: false,
    listening: false,
  });

  tooltip.add(
    new Konva.Tag({
      fill: "#FCE205	",
      pointerDirection: "down",
      pointerWidth: 8,
      pointerHeight: 8,
      // lineJoin: "round",
      // shadowColor: "black",
      // shadowBlur: 10,
      // shadowOffsetX: 10,
      // shadowOffsetY: 10,
      // shadowOpacity: 0.5,
    })
  );

  function updateTooltip(tooltip, x, y, text) {
    // tooltip.getText().text(text);
    // ("text", text);
    // ("tooltip", tooltip);
    // ("x", x);
    // ("y", y);

    tooltip.position({
      x: x,
      y: y,
    });
    tooltip.show();
  }
  if (
    getAllSegmentation &&
    getAllSegmentation.allSegmentsArray &&
    getAllSegmentation.allSegmentsArray.length > 0
  ) {
    const group = new Konva.Group();
    getAllSegmentation.allSegmentsArray.map((item) => {
      let key = item.name;
      let pointss = item.annotationPolygon
        .split(",")
        .map((value) => parseInt(value.trim(), 10));
      var shape = new Konva.Line({
        points: pointss,
        fill: "#ff000061",
        opacity: 0,
        closed: true,
        stroke: "#28dfe5", // Border color
        strokeWidth: 3,
        // custom attr
        key: key,
      });

      shape.on("click", (e) => {
        if (selectedSegment === key) {
          setIsShowEdit(false);
          setSelectedSegment(null);
          setMouseX(null);
          setMouseY(null);
        } else {
          setSelectedSegment(key);
          setIsShowEdit(true);
          const stage = e.target.getStage();
          const mousePos = stage.getPointerPosition();
          setMouseX(mousePos.x);
          setMouseY(mousePos.y);
        }
      });
      group.add(shape);
    });
    shapesLayer.add(group);
  }

  return (
    <>
      <div className="ai-right-wrapper-body  Cursor project-layer-images">
        <h2> Testing Mode</h2>
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          ref={(node) => (window.__stage = node)}
          onMouseMove={handleMouseMove}
          onMouseDown={(e) => {
            handleMouseDown(e);
            handlestage(e); // Call handlestage with the event
          }}
        >
          <Layer>
            {/* You can add other Konva components here if needed */}
          </Layer>
        </Stage>
      </div>
    </>
  );
};

export default Testing;

import React, { useState, useEffect, useRef } from "react";
import "../Add_Edit_Pallet/ModalToAddStyle.css";
import SegmentArea from "../Add_Edit_Pallet/SegementArea";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { selectAllItems } from "../slices/MasterArraySlice";
import { useSelector, useDispatch } from "react-redux";
import ShowBrand from "../Add_Edit_Pallet/ShowBrand";
import { addPallet } from "../slices/Label_Brand_swatch_Slice";
import { getCloseModal } from "../slices/AlertSlice";
import SwatchStyle from "../Add_Edit_Pallet/SwatchStyle";

import {
  getLoadingBrandStyle,
  stopLoadingBrandStyles,
} from "../slices/LoadingSlice";
const ModalToAdd = ({ isOpen, closeModal, indexs }) => {
  const getMasterArray = useSelector(selectAllItems);
  const [activeTabIndex, setActiveTabIndex] = useState(indexs);
  const [openBrand, setOpenBrand] = useState(false);
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [labelName, setLabelName] = useState();
  const [labelId, setLabelId] = useState();

  const dispatch = useDispatch();
  //close div
  const onClose = () => {
    closeModal();
  };
  const ismounted = useRef(true);
  useEffect(() => {
    // Set openBrand and isBrandSelected to false when the tab changes
    setOpenBrand(false);
    setIsBrandSelected(false);
    setActiveTabIndex(indexs);
  }, [indexs]);

  // Search Brand

  const handleLabelToSearchBrand = (labelName, labelId) => {
    setOpenBrand(true);
    setIsBrandSelected(false);
    dispatch(
      addPallet({
        labelId: labelId,
        isLabel: true,
      })
    );
  };

  // handleSwatch style
  const handleSwatchStyle = () => {
    setIsBrandSelected(true);
  };

  // on Click on Tab cloase the brand
  const handleBrandToClose = () => {
    setIsBrandSelected(false);
    setOpenBrand(false);
  };

  // close Modal
  const handleCloseModal = () => {
    closeModal();
  };

  // set index from segmentArea componenet
  const handleIndexValue = (indexx) => {
    setOpenBrand(false);
    setIsBrandSelected(false);
    setActiveTabIndex(indexx);
  };

  const stopShowBrandStyle = useSelector(getLoadingBrandStyle);
  // stop showing brand and Tyle after reselectation
  useEffect(() => {
    if (stopShowBrandStyle) {
      setOpenBrand(false);
      setIsBrandSelected(false);
    }

    dispatch(
      stopLoadingBrandStyles({
        isLoadingBrandStyles: false,
      })
    );
  }, [stopShowBrandStyle]);
  return (
    <div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="popup-width" />
        <ModalContent className="brand-header">
          <ModalHeader className="barnd-header-title">
            Selection Summary{" "}
          </ModalHeader>
          <ModalCloseButton
            className="brand-close-button"
            onClick={handleCloseModal}
          />

          <ModalBody>
            <div>
              <Tabs
                className="tab-segments"
                isManual
                variant="enclosed"
                index={activeTabIndex} // Set the active tab index
                onChange={(index) => setActiveTabIndex(index)}
              >
                {/* area section start */}

                <div className="labels-Area">
                  <div className="labels-Area-title">
                    <h2>Area</h2>
                  </div>
                  {/* segment Area */}

                  <SegmentArea findIndexValue={handleIndexValue} />
                </div>

                {/* area section end */}

                {/* segement-Area-tablist */}

                <div className="segement-Area-tablist">
                  <div>
                    <div className="segement-title">
                      <h2 className="segement-text"> SEGMENTS</h2>
                    </div>

                    <TabList className="tab-list-segemnt">
                      {getMasterArray.master.map((item, itemIndex) => (
                        <Tab key={item.id}>
                          <div
                            className="recomended-box"
                            onClick={handleBrandToClose}
                          >
                            <div className="recomended-images">
                              <img src={item.icon} width={25} alt={item.name} />
                            </div>
                            <div className="recomended-text">
                              <h3 key={item.id}>{item.name}</h3>
                            </div>
                          </div>
                        </Tab>
                      ))}
                    </TabList>
                  </div>

                  <div className="lebels-list">
                    {/* <div className="labels-title-tab">
                      <h2> LABELS</h2>
                    </div> */}
                    <TabPanels className="labels-tabpanel">
                      {getMasterArray.master &&
                        getMasterArray.master.length > 0 &&
                        getMasterArray.master.map((item, itemIndex) => (
                          <TabPanel className="labels-tab-box" key={itemIndex}>
                            <div className="labels">
                              <div className="labels-title-tab">
                                <h2> LABELS</h2>
                              </div>

                              <div className="labels-item">
                                {item.labels.map((label, index) => {
                                  return (
                                    <>
                                      <div className="labels-box">
                                        <div className="label-title-category">
                                          <h3
                                            key={label.id}
                                            onClick={() =>
                                              handleLabelToSearchBrand(
                                                label.name,
                                                label.id
                                              )
                                            }
                                          >
                                            {label.name}
                                            <i class="bi bi-arrow-right"></i>
                                          </h3>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            {/* </div> */}
                            {/* brand */}
                            <div className="brand">
                              {openBrand && (
                                <ShowBrand
                                  labelName={labelName}
                                  labelId={labelId}
                                  showSwatchStyle={handleSwatchStyle}
                                />
                              )}
                            </div>

                            <div className="style-box-outer">
                              {/* style */}
                              {isBrandSelected && <SwatchStyle />}
                            </div>
                          </TabPanel>
                        ))}
                    </TabPanels>
                    {/* </div> */}

                    {/* display brands */}
                  </div>
                </div>
              </Tabs>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalToAdd;

import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { addSelectedSwatch } from "../slices/SelectedPalletdSlice";
import {
  selectedSwatch,
  removeSelectedSwatch,
} from "../slices/SelectedPalletdSlice";
import { useSelector, useDispatch } from "react-redux";
import AddSwatchIntoJob from "../Add_Edit_Pallet/AddSwatchIntoJob";
import {
  getTranspalletImage,
  addSelectedPalletImag,
} from "../slices/TransparentPalletImgSlice";
import MultiUpload from "./MultiUpload";
import ReloadJob from "./ReloadJob";
import GetFolderStructure from "../Folders/GetParentfolder";
import { getFolderData } from "../slices/FolderSlice";

import { getCustomer } from "../login/AuthSlice";
import GetChildFolderId from "../Folders/GetChildFolderId";

import ChildStructure from "../Folders/ChildStructure";
import CreateUserFolder from "../Folders/CreateUserFolder";
import { getProjectId } from "../slices/ProjectSlice";
import CreateSegmentFolder from "../Folders/CreateSegmentFolder";

import {removeGridMultiSelect} from "../slices/gridView/MultiSelectionSegement"
const UploadSegmentedImage = ({ uploadDatas, resetData1 }) => {
  const selectedPallet = useSelector(selectedSwatch);
  const getSelectedSwatch = useSelector(getTranspalletImage);
  const [palletId, setPalletId] = useState();
  // console.log(selectedPallet.swatchId);
  const dispatch = useDispatch();
  const [newUpload, setNewUpload] = useState();
  const [multiNewUpload, setMultiNewUpload] = useState();
  const [job_Id, setJob_id] = useState();

  const [isJob, setISJob] = useState(false);
  const isMounted = useRef(true);
  const [isOpenFolder, setIsOpenFolder] = useState(false);
  const getFolderDatas = useSelector(getFolderData);
  const getCustomerId = useSelector(getCustomer);
  const [isCreatedSegmnetFolders, setIsCreatedSegmnetFold] = useState(false);
  const [paretFolderId, setParentFolderId] = useState();
  const [segName, setSegName] = useState();
  // const getProject = useSelector(getProjectId);
  useEffect(() => {
    if (uploadDatas && uploadDatas.multiImage != null) {
      setIsOpenFolder(true);
      setUpdateRedux(false);
    }
  }, [uploadDatas]);
  const isMounted2 = useRef(true);
  const [updateRedux, setUpdateRedux] = useState(false);
  const [isOpenGetChild, setIsOpenGetChild] = useState();
  const [folderId, setFolderId] = useState(null);

  const handleCloseFolder = (folderid) => {
    isMounted.current = true;
    setIsOpenFolder(false);
    // setFolderId(folderid);
    // setIsOpenGetChild(true);

    setUpdateRedux(true);
    // checkSegmentFolder();
  };

  // check segemnt folder
  useEffect(() => {
    if (uploadDatas && uploadDatas.multiImage != null && updateRedux) {
      //check the segment folder is present or not
      if (
        getFolderDatas &&
        getFolderDatas.folderData &&
        getFolderDatas.folderData.children &&
        getFolderDatas.folderData.children.length > 0
      ) {
        getFolderDatas.folderData.children.map((item) => {
          if (item.name === uploadDatas.swatchName) {
            //check the user folder
            setIsChildStructure(true);
            setChildId(item.id);
            setIsCreatedSegmnetFold(false);
            setUpdateRedux(false);
           
          } else {
            setIsCreatedSegmnetFold(true);
            setParentFolderId(getFolderDatas.folderData.id);
            setSegName(uploadDatas.swatchName);
            setUpdateRedux(false);
          }
        });
      } else if (
        getFolderDatas &&
        getFolderDatas.folderData &&
        getFolderDatas.folderData.children &&
        getFolderDatas.folderData.children.length === 0 &&
        updateRedux
      ) {
        // create the segment folder
        setIsCreatedSegmnetFold(true);
        setParentFolderId(getFolderDatas.folderData.id);
        setSegName(uploadDatas.swatchName);
       
        setUpdateRedux(false);
      }
      // setIsOpenFolder(true);
      // return () => {
      //   isMounted.current = false;
      // };
    }
  }, [getFolderDatas, updateRedux]);

  // const checkSegmentFolder = () => {
  //   ;

  // };
  const handleCloseSegmentFolder = () => {
    setIsCreatedSegmnetFold(false);
    setParentFolderId(null);
    setSegName(null);
    // create userFolder
    setIsOpenCreateFolder(true);
  };

  // close get child folder
  const handleClosechildFolder = (childData) => {
    setIsOpenGetChild(false);
    setFolderId(null);
    checksegnemtFolder(childData); // calling function
  
  };

  //check the door , window folder and find child Structure
  const [isChildStructure, setIsChildStructure] = useState(false);
  const [childId, setChildId] = useState(null);
  const checksegnemtFolder = (childData) => {
    if (childData.length > 0) {
      childData.map((child) => {
        let labelName = uploadDatas.swatchName;
        if (child.name === labelName) {
          setChildId(child.id);
          setIsChildStructure(true);
        }
      });
    }
  };

  const handleCloseChildStructure = (childStructure) => {
    setIsChildStructure(false);
    setIsCreatedSegmnetFold(false);
    checkUserFolder(childStructure); // calling
  };

  //check the user folder
  const [userFolderId, setUseFolderId] = useState(null);
  const checkUserFolder = (childData) => {
    // ;
    if (childData.length > 0) {
      childData.map((item) => {
        if (item.name === getCustomerId.customerId.toString()) {
          // if found folder
          setMultiNewUpload(uploadDatas);
          setUseFolderId(item.id);
        } else {
          //  not found the user
          createFolder();
        }
      });
    } else {
      //no user
      createFolder();
    }
  };
  const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
  const [isAddJob, setIsAddJob] = useState(false);
  const handleMultiNewUpload = () => {
    setMultiNewUpload(null);
    setIsAddJob(true);
    setUseFolderId(null);
    isMounted.current = true;
    dispatch(removeGridMultiSelect())
  };

  // calling create folder page
  const createFolder = () => {
    // ;
    setIsOpenCreateFolder(true);
  };

  const handleClosechildStructureFolder = (userId) => {
    setMultiNewUpload(uploadDatas);
    setUseFolderId(userId);
    setIsOpenCreateFolder(false);
  };
  // useEffect(() => {
  //   ;

  //   if (
  //     uploadDatas &&
  //     uploadDatas.multiImage != null &&
  //     isMounted.current &&
  //     getFolderDatas &&
  //     getFolderDatas &&
  //     getFolderDatas?.foldeerData &&
  //     getFolderDatas?.foldeerData.children
  //   ) {
  //     if (getFolderDatas?.foldeerData.children.length > 0) {
  //       // if already exist then
  //       getFolderDatas?.folderData?.children.map((child) => {
  //         let labelName = uploadDatas.swatchName;
  //         if (child.name === labelName) {
  //           // check the user or designer
  //           console.log("Customer Id", getCustomerId);
  //           getChilderFolderStructure(child.id);
  //         }
  //       });
  //       console.log(getFolderDatas);
  //       setIsOpenFolder(true);
  //       setMultiNewUpload(uploadDatas);
  //     }

  //     return () => {
  //       isMounted.current = false;
  //     };
  //   }
  // }, [uploadDatas]);

  // reset pallet
  const handleResetPalette = () => {
    setPalletId(null);
    setNewPalletId(null);
  };

  const [newPalletId, setNewPalletId] = useState();
  // upload multi image upload

  const handelReset = (jobId) => {
    setJob_id(jobId);
    setIsAddJob(false);
  };
  const handleResetJob = () => {
    setJob_id(null);
  };

  return (
    <div>
      {/* get Parent folder structure */}
      {isOpenFolder && (
        <GetFolderStructure
          isOpenFolder={isOpenFolder}
          backSegmentation={handleCloseFolder}
        />
      )}

      {/* get child folder Id */}
      {isOpenGetChild && folderId != null && (
        <GetChildFolderId
          folderId={folderId}
          backToUploadSegment={handleClosechildFolder}
        />
      )}

      {/* check the child folder */}
      {isChildStructure && (
        <ChildStructure
          childId={childId}
          resetChild={handleCloseChildStructure}
        />
      )}

      {/* create child folder */}
      {isOpenCreateFolder && (
        <CreateUserFolder
          uploadDatas={uploadDatas}
          resetChild={handleClosechildStructureFolder}
        />
      )}

      {/* create segment folder */}
      {isCreatedSegmnetFolders && (
        <CreateSegmentFolder
          paretFolderId={paretFolderId}
          segName={segName}
          resetData2={handleCloseSegmentFolder}
        />
      )}

      {multiNewUpload && (
        <MultiUpload
          userFolderId={userFolderId}
          multiNewUpload={multiNewUpload}
          resetData2={handleMultiNewUpload}
        />
      )}
      {/* {isAddJob && <AddSwatchIntoJob reSetPallet={handelReset} />}
      {job_Id && <ReloadJob job_Id={job_Id} reSetjob={handleResetJob} />} */}
    </div>
  );
};

export default UploadSegmentedImage;

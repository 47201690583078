import { createSlice } from "@reduxjs/toolkit";

const Label_Brand_Slices = createSlice({
  name: "Label_Brand",
  initialState: {
    allArrays: [],
  },
  reducers: {
    addLabel_Brand: (state, action) => {
      state.allArrays = [...action.payload];
    },
  },
});
export const { addLabel_Brand } = Label_Brand_Slices.actions;
export const getAllLabels = (state, action) => state.Label_Brand;
export default Label_Brand_Slices.reducer;

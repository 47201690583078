import { createSlice } from "@reduxjs/toolkit";
import { findAllByDisplayValue } from "@testing-library/react";

const initialState = {
  isLoading: false,
  deleteSegmentValue: false,
  isLoadingBrandStyles: false,
};
const LoadingSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    loadingAlert: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    nullSegmentValue: (state, action) => {
      state.deleteSegmentValue = action.payload.deleteSegmentValue;
    },
    stopLoadingBrandStyles: (state, action) => {
      state.isLoadingBrandStyles = action.payload.isLoadingBrandStyles;
    },
    resetLoading:(state,action)=>{
      state.isLoading=false
      state.deleteSegmentValue=false
      state.isLoadingBrandStyles=false
    }
  },
});

export const { loadingAlert, nullSegmentValue, 
  stopLoadingBrandStyles,resetLoading } =
  LoadingSlice.actions;
export const getLoading = (state) => state.loading.isLoading;
export const getLoadingBrandStyle = (state) =>
  state.loading.isLoadingBrandStyles;
export const getNullSegmentValue = (state, action) =>
  state.loading.deleteSegmentValue;
export default LoadingSlice.reducer;

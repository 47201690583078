import React from 'react'
import "./EachSegment.css";
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@chakra-ui/react'
// import {switchCanvas} from "../../slices/gridView/MultiSelectionSegement"
import {switchCanvas} from "../../../slices/gridView/MultiSelectionSegement"
import{editAnnotation,getEditAnnotation,removeEditSegment} from "../../../slices/EditSegment/EditSegment"
import {getViewPalletesInfo} from "../../../slices/ViewPalletInfoSlice"
const Actions = (

    { segName,
        selectSwatch,
        searchViewPallete,
        deleteSegments,
        editSegmentation,
        reAnnotate
    }
) => {
    const dispatch=useDispatch();
  const [segmentName, setSegmenatName] = useState(null);
  const [isOpenAction, setIsOpenAction] = useState(false);
  const getEditAnnotations=useSelector(getEditAnnotation)
  const[isViewSwatchActive, setIsViewSwatchActive]= useState(false)
    const[isReAnnotationActive, setIsReAnnotationActive]= useState(false)
    const[isEditActive, setIsEditActive]= useState(false)
    


     // handle select swatch
  const handleSelectSwatch = () => {
   
    dispatch(switchCanvas({
      isSwitchCanvas:true
    }))
    selectSwatch();
  };


   //handle view pallete
   const handleViewPallete = (segName) => {
    setSegmenatName(segName)
    searchViewPallete();
  };

// update view pallet active
const getViewPalletesInfos= useSelector(getViewPalletesInfo)
useEffect(()=>{
  if(getViewPalletesInfos.segName===segmentName){
  setIsViewSwatchActive(true)
  }else{
  setIsViewSwatchActive(false)
  }
},[getViewPalletesInfos])

  // delete segmentation
  const handleDeleteSegment = (segName) => {
    deleteSegments();
  };

   //edit segmentation
   const handleEditSegment = (segName) => {
    setSegmenatName(segName)
    if(getEditAnnotations){
      dispatch(removeEditSegment())
    }
   dispatch( (editAnnotation({
    isEditAnnotation:true
   })))

   
    editSegmentation();
  };

  // control isEditActive 
  useEffect(()=>{
    if(getEditAnnotations){
     setIsEditActive(true)
    }else{
      setIsEditActive(false)
    }
  },[getEditAnnotations])

  const resetEditAnnotation=()=>{
    dispatch(removeEditSegment())
  }

  // re Annotation
  const handleReAnnotation=()=>{
         reAnnotate()
  }
  return (
 
                    <div className='segment-select-tool-section'>
                                   <Tooltip hasArrow label='Select Swatch' bg='gray.300' color='black'>
                                   <div className='segment-select-tool-icons' 
                                     onClick={handleSelectSwatch}
                                   >
                                   <i class="bi bi-list-check"></i>
                                     </div>
                                     
                                     </Tooltip>


                                     <Tooltip hasArrow label='View Swatches' bg='gray.300' color='black'>
                                    { segName===segmentName &&isViewSwatchActive?
                                    (<div className={`segment-select-tool-icons ${isViewSwatchActive?"icon-active":""}`}
                                        onClick={()=>handleViewPallete(segName)}
                                     >                  
                                       <i class="bi bi-image"></i>
                                     </div>):(
                                      <div
                                      className="segment-select-tool-icons"
                                      onClick={()=>handleViewPallete(segName)}
                                      >
                                        <i class="bi bi-image"></i>
                                      </div>
                                     )}
                                     </Tooltip>
                                     <Tooltip hasArrow label='Edit' bg='gray.300' color='black'>
                                    
                                     { segName===segmentName  && isEditActive?(
                                       <div className={`segment-select-tool-icons ${isEditActive?"icon-active":""}`}
                                       onClick={()=>handleEditSegment(segName)}
                                       >                  
                                       <i class="bi bi-pencil-square"></i>
                                       </div>
                                     ):(
                                      <div className='segment-select-tool-icons'
                                      onClick={()=>handleEditSegment(segName)}
                                      >                  
                                      <i class="bi bi-pencil-square"></i>
                                      </div>
                                     )} 
                                    
                                    
                                     </Tooltip>

                                     <Tooltip hasArrow label='Re-Annotation' bg='gray.300' color='black'>
                                     <div className='segment-select-tool-icons'
                                     onClick={handleReAnnotation}
                                     >                  
                                     <i class="bi bi-bounding-box-circles"></i>
                                     </div>
                                     </Tooltip>


                                     <Tooltip hasArrow label='Delete' bg='gray.300' color='black'>
                                     <div className='segment-select-tool-icons' 
                                     onClick={()=>handleDeleteSegment(segName)}>                  
                                   <i class="bi bi-trash3"></i>
                                     </div>
                                     </Tooltip>
                     </div>
 
  )
}

export default Actions

import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {selectAllSegments} from "../../slices/AllSegmentSlice"
import axios from 'axios';
import {finishOverLaps} from "../../slices/overlapSlice/OverLapSlice"
import {updateOverlapPolygonAnnotation} from "../../slices/AllSegmentSlice"
import {updateOverLapMasterPolygonAnnotation} from "../../slices/MasterArraySlice"
import {updateOverLapJobArray} from "../../slices/MasterJobs"

const Overlap = () => {

    const getAllSegments= useSelector(selectAllSegments)
     const dispatch= useDispatch()
   
     useEffect(() => {
        console.log("getAllSegments", getAllSegments);
        if (getAllSegments && getAllSegments.allSegmentsArray && getAllSegments.allSegmentsArray.length > 0) {
            const mergedObject = getAllSegments.allSegmentsArray.reduce((accumulator, item) => {
                if (typeof item.annotationPolygon === "string") {
                    const arrayOfNumbers = item.annotationPolygon.split(",").map((str) => parseInt(str, 10));
                    accumulator[item.name] = arrayOfNumbers;
                }
                return accumulator;
            }, {});
    
            console.log("mergedObject", mergedObject);
            //handelOverLap(mergedObject);
        }
    }, [getAllSegments]);
    


    const handelOverLap=async(overLap)=>{

        const data={
            segmentationInt:overLap

        }

        try{
            const responce= await axios.post(
                "https://api.dzinly.org/line-overlap" ,
                data
            )

          
            console.log("overlap response: " + JSON.stringify(responce.data))
            let responceValue= responce.data;
            for (let key in responceValue) {
                dispatch(updateOverlapPolygonAnnotation({
                    labelName:key,
                    annotationPolygon:responceValue[key].toString(),
                }))

                dispatch(updateOverLapMasterPolygonAnnotation({
                    labelName:key,
                    annotationPolygon:responceValue[key].toString(),
                }))
                dispatch(updateOverLapJobArray({
                    labelName:key,
                    annotationPolygon:responceValue[key].toString(),
                }))
                console.log(`Key`,key);
                console.log(`Value: `,responceValue[key].toString()); // Convert array to string for better display
                console.log('---------------------------------');
              }
            
            dispatch(finishOverLaps())
        }
        catch(error){
  console.log("error in overlap",error)
  dispatch(finishOverLaps())
        }

    }
  return (
    <div>Overlap</div>
  )
}

export default Overlap
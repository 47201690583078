import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Get_Designer_JobList } from "../GraphQL/GraphQl";
import { useQuery } from "@apollo/client";
import { addDesignerJob } from "../slices/Designer/DesignerJobListSlice";
import "./ShowJobList.css";
import { useDispatch, useSelector } from "react-redux";
const DesignerJobs = ({ designerId }) => {
  const [designerid, setDesignerid] = useState();
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (designerId && isMounted.current) {
      setDesignerid(designerId);
      return () => {
        isMounted.current = false;
      };
    }
  }, [designerId]);

  const { loading, error, data } = useQuery(Get_Designer_JobList, {
    variables: {
      designerid: designerid,
    },
  });

  useEffect(() => {
    if (data && !loading) {
      //  console.log(data?.designers?.data[0]?.attributes?.jobs?.data);

      dispatch(
        addDesignerJob({
          designerJob: data?.designers?.data[0]?.attributes?.jobs?.data,
        })
      );
    }
  }, [data, loading]);

  return;
  <div>
    <div className="project-loading-div-1">
      <div className="project-loading-div designer-shimmer-title">
        <div class="create-project-shimmer designer-job-shimmer project-card">
          <div class="shimmerBG title-line designer-job-shimmer-title"></div>
          <div class="shimmerBG media"></div>
        </div>

        <div class="create-project-shimmer designer-job-shimmer">
          <div class="shimmerBG title-line designer-job-shimmer-title"></div>
          <div class="shimmerBG media"></div>
        </div>

        <div class="create-project-shimmer designer-job-shimmer">
          <div class="shimmerBG title-line designer-job-shimmer-title"></div>
          <div class="shimmerBG media"></div>
        </div>
      </div>

      {/* <div className="project-card-dable-1">

      <div class="create-project-shimmer  project-card-simmer">
     <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

      <div class="create-project-shimmer  project-card-simmer">
     <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

      <div class="create-project-shimmer  project-card-simmer">
     <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

        <div class="create-project-shimmer  project-card-simmer">
          <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
       </div>
      </div> */}

      {/* <div class="create-project-shimmer ">
        <div class="shimmerBG media"></div>
        <div class="p-32">
          <div class="shimmerBG title-line"></div>

          
         


         
        </div>
      </div> */}

      {/* <Alert status="success" variant="solid">
        <AlertIcon />
        <AlertTitle>Project is Loading ...</AlertTitle>
        <AlertDescription></AlertDescription>
      </Alert> */}
    </div>
  </div>;
};

export default DesignerJobs;

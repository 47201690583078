import React, { useState, useEffect } from "react";
import { getAllLabels } from "../slices/Label_Brand_Swatch";
import { useSelector, useDispatch } from "react-redux";
import brandicon from "../../assets/images/brandicon/brand-icon.png";
import { getAddedPallet, addPallet } from "../slices/Label_Brand_swatch_Slice";
const ShowBrand = ({ labelName, labelId, showSwatchStyle }) => {
  const getLabel_brand = useSelector(getAllLabels);
  const [brands, setBrands] = useState();
  const dispatch = useDispatch();
  const getLabelId = useSelector(getAddedPallet);

  useEffect(() => {
    if (getLabelId.isLabel) {
      if (getLabel_brand && getLabel_brand.allArrays.length > 0) {
        getLabel_brand.allArrays.map((item) => {
          if (item.id === getLabelId.labelId) {
            if (item.attributes.brands.data.length > 0) {
              setBrands(item.attributes.brands.data);
              dispatch(
                addPallet({
                  labelId: getLabelId.labelId,
                  isLabel: false,
                  brandId: null,
                  isBrand: false,
                })
              );
            } else {
              alert("No Brands available");
              dispatch(
                addPallet({
                  labelId: null,
                  isLabel: false,
                  brandId: null,
                  isBrand: false,
                })
              );
            }
          }
        });
      }
    }
  }, [getLabelId.isLabel]); // Empty dependency array, runs once

  // handle search to swatches style
  const handleSearchSwatchStyle = (brandId, brandName) => {
    dispatch(
      addPallet({
        labelId: getLabelId.labelId,
        isLabel: false,
        brandId: brandId,
        isBrand: true,
        swatchStyle: null,
        isSwatchStyle: false,
        swatches: null,
      })
    );

    showSwatchStyle();
  };

  return (
    <div>
      <div className="title-brand">
        <h3>BRANDS</h3>
      </div>

      {brands &&
        brands.length > 0 &&
        brands.map((item) => (
          <div
            className="pallets-row-item"
            onClick={() =>
              handleSearchSwatchStyle(item.id, item.attributes.name)
            }
          >
            <div className="brands-box" key={item.id}>
              <img src={brandicon} width={50} alt={item.attributes.name} />
              <div className="barand-title">
                <h3>{item.attributes.name}</h3>
              </div>
            </div>

            <br />
          </div>
        ))}
    </div>
  );
};

export default ShowBrand;

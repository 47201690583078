import React, { useState, useEffect } from "react";
import "../../assets/styles/simplebar.css";
import "../../App.css";
import "./MainTabStyle.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Flex,
  HStack,
  Box,
  ButtonGroup,
  Center,
} from "@chakra-ui/react";
import { GET_USER_PROJECT } from "../GraphQL/GraphQl";
import { getCustomer } from "../login/AuthSlice";

import ProjectPannel from "./ProjectPannel";
import { concat, useQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { addProjectView } from "../slices/ProjetcViewSlice";
import SegmentationTab from "../Tabs/SegmentationTab";
import { getCurrentab } from "../slices/CurrentTabSlice";
import { addCurrentTab } from "../slices/CurrentTabSlice";
import { addSelectedPalletImag } from "../slices/TransparentPalletImgSlice";
import { addProjectId, getProjectId } from "../slices/ProjectSlice";
import { compareProject } from "../slices/AlertSlice";
import SegmentAndLabel from "../InlineTab/SegmentAndLabel";
import Gridview from "../Gridview/Gridview";
import Combination from "../Combination/Combination";
const MainTab = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [project_id, setProject_id] = useState(27);
  const customerDetail = useSelector(getCustomer);
  const [projectIds, setProjectIds] = useState();
  const dispatch = useDispatch();
  const currentTabs = useSelector(getCurrentab);
  const getProjectDetails = useSelector(getProjectId);
  // const getTab = useSelector(getCurrentab);
  const { loading, error, data, refetch } = useQuery(GET_USER_PROJECT, {
    variables: {
      projectId: projectIds, // Pass the jobId as a variable to the query
    },
  });

  const [reloadProject, setReloadProject] = useState(false);
  const [displayProject, setDisplayProject] = useState(false);
  const [displayGrid, setDisplayGrid] = useState(true);
  const [displaySeg, setDisplayseg] = useState(false);

  const handleProject = () => {
    setDisplayProject(true);
    setDisplayseg(false);
    setDisplayGrid(false);
    setReloadProject(false);
    dispatch(addCurrentTab(2));
    dispatch(
      addSelectedPalletImag({
        segmentName: null,
        selectedPlaletUrl: null,
      })
    );
    // dispatch(
    //   addProjectId({
    //     projectId: null,
    //     // projectName: null,
    //     jobId: null,
    //     reload: false,
    //     base64Image: null,
    //     image: null,
    //   })
    // );
    dispatch(
      compareProject({
        isCompare: false,
      })
    );
    setCurrentTab(2);
  };

  // reload project
  useEffect(() => {
    if (reloadProject && customerDetail.isAuthenticated) {
      setProjectIds(customerDetail.customerId);
    }
  }, [reloadProject]);

  useEffect(() => {
    if (!loading && data) {
      if (
        data &&
        data.customers &&
        data.customers.data &&
        data.customers.data[0] &&
        data.customers.data[0].attributes &&
        data.customers.data[0].attributes.projects &&
        data.customers.data[0].attributes.projects.data.length > 0
      ) {
        dispatch(
          addProjectView(data.customers.data[0].attributes.projects.data)
        );
      } else {
        alert("No Project in your account");
      }
    }
  }, [data, loading]);

  // after selecting job tab changes
  // active the tab after selecting project
  useEffect(() => {
    if (currentTabs.currentTab === 1) {
      setCurrentTab(currentTabs.currentTab);
      setDisplayGrid(true);
      setDisplayProject(false);
      setDisplayseg(false);
      dispatch(addCurrentTab(0));
    }
  }, [currentTabs]);

  // Inline Tab s
  // const hanldeInlineTab = () => {
  //   setCurrentTab(3);
  // };

  //segmentation Tab
  const handleSegment = () => {
    setCurrentTab(1);
    setDisplayseg(true);
    setDisplayGrid(false);
    setDisplayProject(false);
  };

  const handlegrid = () => {
    setCurrentTab(0);
    setDisplayGrid(true);
    setDisplayProject(false);
    setDisplayseg(false);
  };

  const [isCoompare, setIsCompare] = useState(false);
  return (
    <>
      <>
        <div className="job-titles">
          <i class="bi bi-house-door"></i>
          <h1>{getProjectDetails.projectName}</h1>
        </div>

        <div className="ai-left-wrapper add-layer-product">
          <div className="ai-sidebar-body product-layer scrollbar-globle data-simplebar">
            {/* <h5 className="mb-3">PRODUCT LAYERS</h5> */}
            <Tabs
              variant="enclosed"
              index={currentTab}
              //   onChange={handleTabSelect}
            >
              
              <TabList>
                

                <Tab
                  variant="enclosed"
                  className="segments-1 segement-content-icon"
                  onClick={handlegrid}
                >
                  <div className={displayGrid ? "active-tab" : ""}>
                    {" "}
                    <i class="bi bi-layout-sidebar"></i>
                    {displayGrid && (
                      <span className="segment-tab-title">SEGMENTS</span>
                    )}
                  </div>
                </Tab>

                <Tab
                  className="segments-1 segement-content-icon segment-tab-list"
                  onClick={() => handleSegment()}
                >
                  <div className={displaySeg ? "active-tab" : ""}>
                    <i class="bi bi-ui-checks-grid"></i>
                    {displaySeg && (
                      <span className="segment-tab-title">SEGMENTS</span>
                    )}
                  </div>
                </Tab>  


                <Tab
                  variant="enclosed"
                  className="segments-1 segment-project-section segement-content-icon "
                  onClick={handleProject}
                >
                  <div className={displayProject ? "active-tab" : ""}>
                    <i class="bi bi-back"></i>
                    {displayProject && (
                      <span className="segment-tab-title">Combo</span>
                    )}
                  </div>
                </Tab>

              </TabList>

              <TabPanels>
                {/* first panel start  from Demo Component*/}
                
                {/* grid View */}
                <TabPanel>
                  <Gridview></Gridview>
                </TabPanel>

                <TabPanel>
                  {/* dynamic labels  from API */}
                  <SegmentationTab />
                </TabPanel>

                <TabPanel>
                  <Combination />
                </TabPanel>

              </TabPanels>
            </Tabs>
          </div>
        </div>
      </>
    </>
  );
};

export default MainTab;

import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { loadingAlert } from "../../slices/LoadingSlice";
import { useToast } from "@chakra-ui/react";
import {
  deleteProjectView,
  renameProject,
} from "../../slices/ProjetcViewSlice";
const RenameToDB = ({ projectId, projectName, reSetOption }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const isMounted = useRef(true);
  useEffect(() => {
    if (isMounted.current && projectId && projectName) {
      handleRename();
    }
  }, [projectId, projectName]);
  const handleRename = async () => {
    dispatch(
      loadingAlert({
        isLoading: true,
      })
    );
    // setIsRenameForm(false);
    // setIsRename(false);
    let projectdata = {
      name: projectName,
    };
    let data = {
      data: projectdata,
    };

    try {
      const response = await axios.put(
        process.env.REACT_APP_API_STRAPI + "projects/" + projectId,
        data
      );
      
      if (response.status === 200) {
        //
        isMounted.current = true;
        dispatch(
          renameProject({
            projectId: projectId,
            projectName: projectName,
          })
        );
        // setIsReload(true);
        toast({
          title: "Renamed Project Name",
          // description: "You have logged in successfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "center-bottom",
        });
        dispatch(
          loadingAlert({
            isLoading: false,
          })
        );
        reSetOption();
      }
    } catch (e) {
     
      alert(e.message);
    }
  };
  return <div></div>;
};

export default RenameToDB;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { selectedPalletdDetails } from "../slices/SelectedPalletdSlice";
import { getProjectId } from "../slices/ProjectSlice";
import UploadSegmentedImage from "./UploadSegmentedImage";
import { useDispatch, useSelector } from "react-redux";
import { palletLoading } from "../slices/AlertSlice";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import { getTabPannel } from "../slices/TabPannelSlice";
import { getCallApi, openSegAfterUpload } from "../slices/AlertSlice";
const CallApi = ({
  palletImagePath,
  flagDemo,
  palletId,
  palletName,
  resetData,
}) => {
  const toast = useToast();
  const selectedPallateDetails = useSelector(selectedPalletdDetails);
  //console.log(selectedPallateDetails)
  const dispatch = useDispatch();
  const getBase64Image = useSelector(getProjectId);
  const [uploadData, setUploadData] = useState(null);

  const [palletIds, setpalletId] = useState();

  const allSegmentArray = useSelector(selectAllSegments);
  // const [allPolygonArrayInt, setPolygonArrayInt] = useState();
  const getCallApis = useSelector(getCallApi);

  const findAllPoly = () => {
    let arrayMaster = [];
    if (allSegmentArray && allSegmentArray.allSegmentsArray.length > 0) {
      allSegmentArray.allSegmentsArray.forEach((item) => {
        const keyAsString = item.name; // Use item.name as the key
        if (typeof item.annotationPolygon === "string") {
          const arrayOfNumbers = item.annotationPolygon
            .split(",")
            .map((str) => parseInt(str, 10));

          arrayMaster.push({
            [item.name]: arrayOfNumbers,
          });
        }
      });
    }
    return arrayMaster;
    //  setPolygonArrayInt(arrayMaster);
  };
  // const [allowAPI, setAllowAPI] = useState(false);
  const [apiCallData, setApiCallData] = useState(null);
  const isAPICallData = useRef(true);
  const isMounted = useRef(true);
  const isMounted3 = useRef(true);
  const isMountApi= useRef(true);
  const getTabPannels = useSelector(getTabPannel);
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch1"));

  //Testing

  useEffect(() => {
     //;
    
    if (
      handleSwatch &&
      handleSwatch?.palletImagePath &&
      handleSwatch?.flagDemo &&
      handleSwatch?.palletId &&
      handleSwatch?.palletName &&
      isMounted.current &&
      selectedPallateDetails&&
      selectedPallateDetails.length > 0 &&
      palletImagePath
    ) {
     // 
      isMounted.current = false;
      const data = {
        palletImagePath: handleSwatch.palletImagePath,
        flagDemo: handleSwatch.flagDemo,
        palletId: handleSwatch.palletId,
        palletName: handleSwatch.palletName,
        // Add other properties as needed
      };
      isMounted.current=false
      multiCAllApIs(data);
      sessionStorage.removeItem("handleSwatch");

      return () => {
       // console.log("clean up", isMounted.current);
        isMounted.current = false;
      };
    }
  }, [palletImagePath,handleSwatch,selectedPallateDetails]);
  // handle reset data
  const handleReset = () => {
    setUploadData(null);
  };
  const [newPoly, setNewPloy] = useState();

  const multiCAllApIs = (apiCallData) => {
    isMounted.current=false
    // ;
    const allPolygonArrayInt = findAllPoly();
    const { palletImagePath, flagDemo, palletId, palletName } = apiCallData;
   

    const urlpallet =
      flagDemo == 1
        ? process.env.REACT_APP_S3_DZINLYLIVE_PATH_DEMO + palletImagePath
        : process.env.REACT_APP_S3_DZINLYLIVE_PATH + palletImagePath;
    let palletIds = palletId;
    let palletImage = palletImagePath;


    let poly;
    let allAreas;
    let segmentName;
    let itemName;
    let area;
    let base64 = getBase64Image.base64Image;
    let intstringValue;
    let floatstringValue;
    let segmentationIntValue;
    let boundingboxFloatValue;
    let boundingboxIntValue;
    let allowAPI = true;
    setApiCallData(null);
   // console.log("multiCAllApIs 3");
    if (
      selectedPallateDetails &&
      selectedPallateDetails.length > 0 &&
      base64 != null
    ) {
      selectedPallateDetails.map((item) => {
        segmentName = item.name.match(/[a-zA-Z]/g).join("");

        intstringValue = item.BoundingBoxInt.split(",").map(parseFloat);
        floatstringValue = item.BoundingBoxFloat.split(",").map(parseFloat);

        area = parseFloat(item.area_pixel);
        itemName = item.name;
        poly = item.annotationPolygon
          .split(",")
          .map((str) => parseInt(str, 10));

        // classsName
        allAreas = {
          ...allAreas,
          [item.name]: area,
        };

        //segmentationIntValue
        segmentationIntValue = {
          ...segmentationIntValue,
          [item.name]: poly,
        };

        //boundingboxFloatValue
        boundingboxFloatValue = {
          ...boundingboxFloatValue,
          [item.name]: floatstringValue,
        };
        //boundingboxIntValue
        boundingboxIntValue = {
          ...boundingboxIntValue,
          [item.name]: intstringValue,
        };
      });
     ;

      const data = {
        masterImage: base64,
        swatchUrl: urlpallet,
        className: [allAreas],
      };
      setApiCallData(null);

      let apiPath = "";

      if (segmentName === "Window") {
        apiPath = "replace-window-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = [segmentationIntValue];
      
      }
      if (segmentName === "Door") {
        apiPath = "replace-door-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = [segmentationIntValue];
    
      }
      if (segmentName === "GarageDoor" || segmentName === "Garage") {
        apiPath = "replace-garage-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = [segmentationIntValue];
      }
      if (segmentName === "Roof") {
        apiPath = "replace-roof-swatch";
        data.segmentationIntValue = allPolygonArrayInt;
      }
      if (segmentName === "Wall") {
        apiPath = "replace-wall-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = allPolygonArrayInt;
        data.alpha_value= 0.8;
      }
      if (segmentName === "Light") {
        apiPath = "replace-light-swatch";
      }
      if (segmentName === "Gutter") {
        apiPath = "replace-gutter-swatch";
        data.segmentationIntValue = [segmentationIntValue];
      }
      if (segmentName === "Trim") {
        apiPath = "replace-trim-swatch";
        data.segmentationIntValue = [segmentationIntValue];
      }

      if (
        apiPath != "" &&
        data != null &&
        segmentName &&
        palletImage &&
        palletIds
      ) {
        setApiCallData(null);
        //console.log("multiCAllApIs 7", isMounted.current);
        replaceSwatchAPI(apiPath, data, segmentName, palletImage, palletIds);
        isMounted.current = false;
       // console.log("multiCAllApIs 9", isMounted.current);
      }
    }
  };

  const [apiCalled, setApiCalled] = useState(false);
  
  const replaceSwatchAPI = async (
    apiPath,
    data,
    segmentName,
    palletImage,
    palletIds
  ) => {
    //
    if (apiPath !== "" && data &&isMountApi.current) {
      isMounted.current = false;
      isMountApi.current= false;
      try {
       
        isMounted3.current = false;
        setApiCalled(true);

        const response = await axios.post(
          "https://api.dzinly.org/api/" + apiPath,
          data
        );
        apiPath = "";
        data = null;
        //console.log("multiCAllApIs 10");
        //logger Started
        // Establish a WebSocket connection to the FastAPI server
       // const socket = new WebSocket("wss://api.dzinly.org/log");

        // WebSocket connection has been established
        // socket.onopen = (event) => {
        //   socket.send(JSON.stringify(data));
        // };

        // socket.onmessage = (event) => {
        //   // Received a message from the server
        //   const loggerData = JSON.parse(event.data);
        // };

        //logger ended
        apiPath = "";
       
        const val = response?.data;
        const value = val?.encoded_image;
        // console.log(response);
        
        if (response.status === 200) {
          isMounted.current = true;
           isMountApi.current = true;

          let uploadSegmentation = {
            swatchId: palletIds,
            multiImage: response.data,
            swatchName: segmentName,
            reloadJobId: null,
            palletUrl: palletImage,
          };
          setApiCalled(false);

          dispatch(
            openSegAfterUpload({
              isOpenSegAfterUpload: true,
            })
          );
          sessionStorage.setItem(
            "uploadData",
            JSON.stringify(uploadSegmentation)
          );

          //call back to showEdit page
          resetData(uploadSegmentation);
        }

      //  socket.onclose = (event) => {
          
      //  };
      } catch (error) {
        isMounted.current = true;
        toast({
          title: "Error: " + error.message,
          // description: "Please check your connection and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "center-bottom",
        });
        dispatch(
          palletLoading({
            palletLoading: false,
          })
        );
      } finally {
        isMounted.current = true;
      }
    }
  };

  return (
    <div>
      {/* {uploadData != null && (
        <UploadSegmentedImage uploadData={uploadData} resetData={handleReset} />
      )} */}
    </div>
  );
};

export default CallApi;

import React from "react";
import { Provider, useDispatch } from "react-redux";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { getCustomer } from "./component/login/AuthSlice";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginHome from "./component/login/LoginHome";
import HomePage from "../src/component/HomePage/homePage";
import store from "../src/component/store/Store";
import Projectview from "../src/component/ImageView/ImageView";
import Signup from "../src/component/signup/SignUp";
import AnimatePresence from '@chakra-ui/transition';

import ProjectLayer from "../src/component/ProjectLayer/ProjectLayer";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import Home from "../src/component/HomePage/Home";
import DesignerProfile from "./component/Designer/Designer";
import NewDesign from "./component/NewDesign/NewDesignHomePage"
import MediaLibrary from "./component/Media/MediaSegmentsFrom"
import CustomerView  from "./component/CutomerView/CustomerView"
import FindJob from "../src/component/Designer/FindJob";
import GenAiTest from "./component/GenAiTest/GenAiTest";
import JobView from "./component/Job/JobSeg";

const container = document.getElementById("root");
const root = createRoot(container);
const client = new ApolloClient({
  uri: "https://aiadmin.dzinly.org/graphql",
  cache: new InMemoryCache(),
});

// Define a functional component to access useSelector
function AppRouter() {
  const customerDetails = useSelector(getCustomer);
  const isAuthenticated = customerDetails?.isAuthenticated;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="login" element={<LoginHome />} />
          <Route path="signup" element={<Signup />} />
          <Route index element={<Home />} />
          <Route
            path="projectview/*"
           
            element={<Projectview />}
          />
          <Route
            path="projectdraft/*"
            
            element={<NewDesign />}
          />
            <Route
            path="job/*"
            
            element={<JobView />}
          />
            <Route
            path="medialibrary"
            
            element={<MediaLibrary />}
          />

          <Route path="profile" element={<HomePage />} />
          <Route path="designer" element={<DesignerProfile />} />
          <Route path="findjob" element={<FindJob />} />
          <Route path="customerView/*" element={<CustomerView />} />
          <Route path="genai/" element={<GenAiTest />} />

          {/* <Route path="newprojectview" element={<NewProjectView />} /> */}
          {/* <Route path="testing" element={<Testing />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}
root.render(
  <div>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <ChakraProvider>
        <ApolloProvider client={client}>
          <AppRouter /> {/* Render the functional component here */}
        </ApolloProvider>
      </ChakraProvider>
      {/* </React.StrictMode> */}
    </Provider>
  </div>,
  container
);

reportWebVitals();

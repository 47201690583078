import React, { useEffect, useState, useRef } from "react";
import { getCurrentablePalettes } from "../slices/CurrentSelectedPalleteSlice";
import { useDispatch, useSelector } from "react-redux";
import homeicon from "../../assets/images/demo/home_images/image-layer.png";
const ShowCurrentSwatch = ({ labelName }) => {
  const dispatch = useDispatch();
  const getCurrentPallate = useSelector(getCurrentablePalettes);
  return (
    <div className="segments-image">
      {getCurrentPallate &&
      getCurrentPallate.currentpallate &&
      getCurrentPallate.currentpallate.length > 0 ? (
        getCurrentPallate.currentpallate.map((item) =>
          item.segmentName === labelName ? (
            item.s3BucketImage != null ? (
              <img
                key={item.segmentName}
                src={
                  process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                  (item.s3BucketImage || "")
                }
                alt="image"
              />
            ) : (
              <img
                key="no-data"
                src={homeicon}
                width={200}
                className="home-images"
                alt="home icon"
              />
            )
          ) : null
        )
      ) : (
        <img
          key="no-data"
          src={homeicon}
          width={200}
          className="home-images"
          alt="home icon"
        />
      )}
    </div>
  );
};

export default ShowCurrentSwatch;

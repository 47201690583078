import { createSlice } from "@reduxjs/toolkit";


const CustomerViewPalletSlicer = createSlice({
    name: "customerViewPallet",
    initialState: {
        customerViewPallet: [],
        customerMaster:[],
        customerCurrentPallet:[],
      },
    reducers: {
      addCustomerViewPallet: (state, action) => {
        state.customerViewPallet.push(action.payload.customerViewPallet);
      },
      removeCustomerViewPallet: (state, action) => {
        state.customerViewPallet = [];
        state.customerMaster=[]
      },
      addCustomerMaster: (state, action) => {
        state.customerMaster = action.payload.customerMaster;
        resetDataSwatchSelected()
      },
      resetDataSwatchSelected:(state,action)=>{
        state.customerMaster.forEach((item)=>{
          if(item.segmentation.length > 0){
            item.segmentation.forEach((seg)=>{
              seg.SelectSwatchJob.forEach((swatch)=>{
                swatch.selected=false;
                swatch.isHide=false;
                
              })
            })
          }
       
        });

      },
      updateCustomerSelectedMasterArray:(state,action)=>{
        //
         const { labelName, segName, swatchId ,selected} = action.payload;
         const labelIndex = state.customerMaster.findIndex(
           (item) => item.name === labelName
         );
         if (labelIndex !== -1) {
           const segmentation = state.customerMaster[labelIndex].segmentation;
           const segIndex = segmentation.findIndex((item) => item.name === segName);
       
           if (segIndex !== -1) {
             const swatchs = segmentation[segIndex].SelectSwatchJob;
             // 
             // Set selected to false for all swatches in SelectSwatchJob
             swatchs.forEach((swatch) => {
               if(swatch.swatch.data.id===swatchId){
                 swatch.selected=selected
               }else{
                 swatch.selected=false
               }
             });
           }
         }
       
       },
      //  create cutsomer current pallet array
         addCustomerCurrentPallet:(state,action)=>{
           state.customerCurrentPallet=action.payload.customerCurrentPallet
         },
         resetCustomerPallet:(state,action)=>{
           state.customerCurrentPallet=[]
         },
         updateCustomerCurrentPallete: (state, action) => {
          const { segName, swatchId,palletImage} = action.payload;
            
          const segIndex = state.customerCurrentPallet.findIndex(
            (item) => item.segmentName === segName
          );
          if(segIndex!==-1){
            state.customerCurrentPallet[segIndex].swatchId = swatchId
            state.customerCurrentPallet[segIndex].SegmentImagePath = palletImage
            state.customerCurrentPallet[segIndex].checked=true
          }
           
        },

    },
});

export const { addCustomerViewPallet, 
    removeCustomerViewPallet ,
    addCustomerMaster,
    resetDataSwatchSelected,
    updateCustomerSelectedMasterArray,
    addCustomerCurrentPallet,
    updateCustomerCurrentPallete,
    resetCustomerPallet} = CustomerViewPalletSlicer.actions;
    export const getCustomerViewPallet = (state) => state.customerViewPallet;
  export const getCustomerMaster = (state) => state.customerViewPallet.customerMaster;

   export const getCustomerCurrentPallet = (state) => state.customerViewPallet.customerCurrentPallet
  export default CustomerViewPalletSlicer.reducer;
import React, { useState, useEffect } from "react";
import "./NewOrExistingCreateProject.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Button,
  Center,
} from "@chakra-ui/react";
const NewOrExistingCreateProject = ({
  isNewModal,
  onCloseModal,
  newProject,
  UploadExisting,
}) => {
  // handle New Project
  const handleNewProject = () => {
    newProject();
  };

  // handle upload existing project
  const handleUploadExistingProject = () => {
    UploadExisting();
  };
  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isNewModal}
        onClose={onCloseModal}
      >
        <ModalOverlay />
        <ModalContent className="create-new-project-section">
          <ModalHeader  className="create-new-project-title"style={{ textAlign: "Center" }}>
            Create Project
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}></ModalBody>

          <ModalFooter className="project-create-box">
            <div className="new-create-project "  onClick={handleNewProject} >
              <div className="new-create-project-icon">
                {/* <i class="bi bi-house-add-fill"></i> */}
                <i class="bi bi-house-add-fill"></i>
              </div>
              <div className="new-create-project-button">
                <Button
                  className="new-create-project-title"
                  colorScheme="blue"
                  mr={3}
                  
                >
                  Create New Project
                </Button>
              </div>
            </div>
            <div className="create-existing-project" onClick={handleUploadExistingProject}>
              <div className="new-create-project-icon">
                <i class="bi bi-house-gear-fill"></i>
              </div>
              <div className="create-existing-button">
                <Button
                  className="create-existing-project-title"
                  colorScheme="blue"
                  mr={3}
                  
                >
                  Upload Existing Project
                </Button>
              </div>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default NewOrExistingCreateProject;

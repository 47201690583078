import { createSlice } from "@reduxjs/toolkit";

const CompareSlice= createSlice({
    name: "compare",
    initialState: {
       
        isStartCompare:false
      },
    reducers: {
      startCompare:(state,action)=>{
        state.isStartCompare=action.payload.isStartCompare
   
      }
      
      
      
      
    },
})

export const {
    startCompare
  
} = CompareSlice.actions;

export const getStartCompare= (state, action) =>state.compare

export default CompareSlice.reducer;

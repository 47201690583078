import { createSlice } from "@reduxjs/toolkit";

const ReverseResponse = createSlice({
  name: "responce",
  initialState: {
    apiResponse: [],
  },
  reducers: {
    addExistingProject: (state, action) => {
      state.apiResponse = action.payload.apiResponse;
    },
    removeExtistingProject: (state, action) => {
      state.apiResponse = [];
    },
  },
});

export const { addExistingProject, removeExtistingProject } =
  ReverseResponse.actions;
export const getReverseResponse = (state, action) => state.responce;
export default ReverseResponse.reducer;

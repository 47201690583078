import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { deleteLoading } from "../slices/AlertSlice";
import { useDispatch } from "react-redux";
const UpdateDeletedSegmentToDb = ({ updatedJob, JobId }) => {
  const [jobs, setJobs] = useState();
  const [Job_Id, setJobId] = useState();
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    if (JobId != null && updatedJob) {
      setJobs(updatedJob);
      setJobId(JobId);
    }
  }, [updatedJob, JobId]);

  useEffect(() => {
    const updateJobs = async () => {
      if (Job_Id != null && updatedJob != null) {
        let data = {
          data: {
            Segmentation: jobs,
          },
        };
        try {
          const jobData = await axios.put(
            process.env.REACT_APP_API_STRAPI + "jobs/" + Job_Id,
            data
          );
          if (jobData.status === 200) {
            toast({
              title: "Job is updated ",
              description: "successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "center-bottom",
            });
            dispatch(
              deleteLoading({
                isDeleteSegmentLoading: false,
              })
            );
          }
          setJobId(null);
          setJobs(null);
          data = null;
        } catch (error) {
          toast({
            title: error.message,
            description: "Something went wrong please try after sometime",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "center-bottom",
          });
        }
      }
    };
    updateJobs();
  }, [Job_Id, jobs]);

  return <div></div>;
};

export default UpdateDeletedSegmentToDb;

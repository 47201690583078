import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  Box,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ButtonGroup,
} from "@chakra-ui/react";
import axios from "axios";
import "../../App.css";
import { Button } from "@chakra-ui/react";
import { memo } from "react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/react";
import "../Add_Edit_Pallet/ModalToAddStyle.css";
import { getAddedPallet, addPallet } from "../slices/Label_Brand_swatch_Slice";
import { getAllLabels } from "../slices/Label_Brand_Swatch";
import { useSelector, useDispatch } from "react-redux";
import Swatches from "../Add_Edit_Pallet/Swatches";
const SwatchStyle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const getBrandId = useSelector(getAddedPallet);
  const getLabel_brand = useSelector(getAllLabels);
  const [swatch_Style, setSwatch_Styles] = useState();

  useEffect(() => {
    if (getBrandId.isBrand) {
      setIsLoading(false);
      if (getLabel_brand && getLabel_brand.allArrays.length > 0) {
        getLabel_brand.allArrays.map((item) => {
          if (item.id === getBrandId.labelId) {
            let swatchStyle = item.attributes.swatch_styles.data;
            if (item.attributes.swatch_styles.data.length > 0) {
              setSwatch_Styles(swatchStyle);

              setIsLoading(true);
            } else {
              setSwatch_Styles(null);
            }
          }
        });
      }
    }
  }, [getBrandId.isBrand]);

  // get Swatched and Image
  const handleSwatches = (swatchStyleId, swatchStyleName) => {
    dispatch(
      addPallet({
        labelId: getBrandId.labelId,
        isLabel: false,
        brandId: getBrandId.brandId,
        isBrand: false,
        swatchStyle: swatchStyleId,
        isSwatchStyle: true,
        swatches: null,
      })
    );
  };
  return (
    <>
      <div className="style-pallet-box">
        <div className="styles">
          <h2> STYLES</h2>
        </div>
      </div>
      {isLoading ? (
        swatch_Style &&
        swatch_Style.length > 0 &&
        swatch_Style.map((item) => (
          <Accordion className="band-accordion" allowToggle={true}>
            <AccordionItem
            //   key={item}
            >
              <h2>
                <AccordionButton
                  className="pallet-accordion-text"
                  key={item.id}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    onClick={() =>
                      handleSwatches(item.id, item.attributes.name)
                    }
                  >
                    {item.attributes.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="displayStyle">
                {/* Display the relevant information or components here */}
                <Swatches />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
    </>
  );
};

export default SwatchStyle;

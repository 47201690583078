import { gql } from "@apollo/client";


export const GET_ALL_COMBINATION = gql`
query getCombination($projectId: ID!){
  projects(
    filters: { id: { eq: $projectId } }
    sort: "createdAt:desc"
    pagination: { limit: -1 }
  ){
    data{
      id
      attributes{
        name
        combinations( sort: "createdAt:desc"){
          data{
            id
            attributes{
              Name
              hide
              Swatches{
                id
                swatch{
                  data{
                    attributes{
                      name
                      image_s3
                    }
                  }
                }
                SwatchFor
                SegmentImagePath
                Status
              }
            }
          }
        }
      }
    }
  }
}

  `;
import { createSlice } from "@reduxjs/toolkit";

const JobArraySlice = createSlice({
  name: "jobArray",
  initialState: {
    jobArray: [],
  },
  reducers: {
    addJobrArray: (state, action) => {
      state.jobArray = [...action.payload];
    },

    removeJobArray: (state) => {
      state.jobArray = [];
    },
    
  },
});

export const { addJobrArray, removeJobArray, } = JobArraySlice.actions;
export const getJobArray = (state) => state.jobArray;
export default JobArraySlice.reducer;

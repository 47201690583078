import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobId: null,
  openTab: false,
  base64: null,
};
const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    addJob_Id: (state, action) => {
      state.jobId = action.payload.jobId;
      state.openTab = action.payload.openTab;
      state.base64 = action.payload.base64;
    },
    removeJobs: (state, action) => {
      return initialState;
    },
  },
});

export const { addJob_Id, removeJobs } = jobSlice.actions;
export const getJobDetail = (state, action) => state.job;
export default jobSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import { getDesignerJob } from "../slices/Designer/DesignerJobListSlice";
import { useDispatch, useSelector } from "react-redux";
import masterImage from "../../assets/images/demo/home_images/no-image-.jpg";
import { Button } from "@chakra-ui/react";
import { addProjectId } from "../slices/ProjectSlice";
import { useNavigate } from "react-router-dom";
import "./ShowJobList.css";
const ShowJobList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDesignerJobs = useSelector(getDesignerJob);
  //console.log("designer Job", getDesignerJobs);

  const [jobId, setJobId] = useState(null);
  const handleProject = (jobId, projectName, projectId, base64Image) => {
    dispatch(
      addProjectId({
        projectId: projectId,
        projectName: projectName,
        jobId: jobId,
        reload: false,
        base64Image: base64Image,
      })
    );
    navigate("/projectview/" + jobId);
  };
  return (
    <div className="designer-project-section">
      {getDesignerJobs &&
      getDesignerJobs.designerJob &&
      getDesignerJobs.designerJob.length > 0 ? (
        getDesignerJobs.designerJob.map((item) => (
          <div className="card designer-card" key={item.id}>
            <div
              className="card-content"
              //    onClick={() =>
              //           handleOptions(image?.attributes?.name, image?.id)
              //         }
            >
              {/* {/ Use Chakra UI Button instead of the HTML button /} */}
              {/* <Link to="/list-of-projects"> */}
              <div className="job-content">
                <Button
                  variant="solid"
                  colorScheme="blue"
                  size="md"
                  borderRadius="md"
                  key={item?.attributes?.project?.data?.id}
                >
                  <span
                    className="create-project-icon"
                    onClick={() =>
                      handleProject(
                        item?.id,
                        item?.attributes?.project?.data?.attributes?.name,

                        item?.attributes?.project?.data?.id,
                        item?.attributes?.project?.data?.attributes?.ImageBase64
                      )
                    }
                  >
                    {item?.attributes?.project?.data?.attributes?.name}
                  </span>
                </Button>

                <div className="project-details-icon">
                  <i
                    class="bi bi-three-dots"
                    // onClick={() =>
                    //   handleOptions(image?.attributes?.name, image?.id)
                    // }
                  ></i>
                </div>
              </div>
              {/* <br /> */}
              {/* {isOptions &&
                projectName === image.attributes.name &&
                projectId === image.id && (
                  <DeleteProject
                    projectId={image?.id}
                    projectName={image.attributes.name}
                    closeOption={handleCloseOption}
                    reSetOption={handleResetOption}
                  />
                )} */}

              {/* <OptionsEdit /> */}
            </div>

            {item &&
            item?.attributes &&
            item?.attributes?.JobImage &&
            item?.attributes?.JobImage?.data &&
            item?.attributes?.JobImage?.data.attributes?.formats?.thumbnail
              .url ? (
              <div
                className="media-images"
                onClick={() =>
                  handleProject(
                    item?.id,
                    item?.attributes?.project?.data?.attributes?.name,

                    item?.attributes?.project?.data?.id,
                    item?.attributes?.project?.data?.attributes?.ImageBase64
                  )
                }
              >
                <img
                  class="shimmerBG media"
                  src={
                    item?.attributes?.JobImage?.data.attributes?.formats
                      ?.thumbnail.url
                  }
                  alt="image"
                  key={item?.attributes?.JobImage?.data.attributes?.id}
                />
              </div>
            ) : (
              <img
                className="master-images-1"
                src={masterImage}
                alt="masterImage"
              />
            )}
          </div>
        ))
      ) : (


        <div className="designer-project-shimmer">
          <div className="project-loading-div-1">
            <div  className="project-loading-div" >


              <div class="create-project-shimmer designer-job-shimmer project-card">
                <div class="shimmerBG title-line designer-job-shimmer-title"></div>
                <div class="shimmerBG media"></div>
              </div>

              <div class="create-project-shimmer designer-job-shimmer">
                <div class="shimmerBG title-line designer-job-shimmer-title"></div>
                <div class="shimmerBG media"></div>
              </div>

              {/* <div class="create-project-shimmer designer-job-shimmer">
            <div class="shimmerBG title-line designer-job-shimmer-title"></div>
            <div class="shimmerBG media"></div>
            </div> */}
            </div>

            {/* <div className="project-card-dable-1">

            <div class="create-project-shimmer  project-card-simmer">
            <div class="shimmerBG title-line"></div>
            <div class="shimmerBG media"></div>    

            </div>

            <div class="create-project-shimmer  project-card-simmer">
            <div class="shimmerBG title-line"></div>
            <div class="shimmerBG media"></div>    

            </div>

            <div class="create-project-shimmer  project-card-simmer">
              <div class="shimmerBG title-line"></div>
            <div class="shimmerBG media"></div>    

            </div>
            </div>  */}

            {/* <div class="create-project-shimmer ">
 <div class="shimmerBG media"></div>
 <div class="p-32">
   <div class="shimmerBG title-line"></div>

   
  


  
 </div>
</div>







{/* <Alert status="success" variant="solid">
 <AlertIcon />
 <AlertTitle>Project is Loading ...</AlertTitle>
 <AlertDescription></AlertDescription>
</Alert> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowJobList;

import React, { useEffect, useState } from "react";
import CreateprojectImage from "../../assets/images/demo/home_images/creact-project-images.png";
import { Button } from "@chakra-ui/react";
import "../HomePage/CreateProject.css";
import pImage from "../../assets/images/demo/home_images/master1.png";
import { getCustomer, loginSuccess, logoutSuccess } from "../login/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import ProjectList from "../HomePage/ListOfProject";
import CreateProjectForm from "../CreateProject/CreateProject";
import { useToast } from "@chakra-ui/react";
import { addProjectId } from "../slices/ProjectSlice";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import NewOrExistingCreateProject from "./NewOrExistingCreateProject";
import { makeAllImageNull } from "../slices/AlertSlice";

import UploadExistingProject from "../UploadExistingProject/UploadExistingProject";

import Loading from "../Loading/Loading";
import { getLoading } from "../slices/LoadingSlice";
import {updateWindowResolution} from "../slices/windowSizeSlice/WindowResolution"
const CreateProject = () => {
  const CustomerDetail = useSelector(getCustomer);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const loginSucess = JSON.parse(sessionStorage.getItem("customerLogin"));

  useEffect(()=>{
    
    const innerWidth =window.screen.width;
     const innerHeigth=window.screen.height;
     console.log("innerHeigth",innerHeigth)
     console.log("innerWidth",innerWidth)
     let width;
     let height;
     if(innerWidth===1920 && innerHeigth===1080){
       width = 1425
       height=825
     }
     else if(innerWidth===1600 && innerHeigth===900){
       width = 1166
       height=675
     }
     else if(innerWidth=== 1440 && innerHeigth===900){
       width = 1123
       height=650
     }
     else if(innerWidth=== 1366 && innerHeigth===768){
       width = 950
       height=550
     }
     else if(innerWidth=== 1023 && innerHeigth===768){
       width = 840
       height=486
     } else{
      width=1123
      height=650
     }
   
     dispatch(updateWindowResolution({
       canvasWidth:width,
       canvasHeight:height
     }))
     const data={
       canvasWidth:width,
       canvasHeight:height
     }
     sessionStorage.setItem("canvasResolution",JSON.stringify(data))
   
     },[])
  useEffect(() => {
    if (
      loginSucess &&
      loginSucess.email &&
      loginSucess.customerName &&
      loginSucess.token &&
      loginSucess.isAuthenticated &&
      loginSucess.role
    ) {
      dispatch(
        loginSuccess({
          customerId: loginSucess.customerId,
          isAuthenticated: true,
          token: loginSucess.token,
          email: loginSucess.email,
          customerName: loginSucess.customerName,
          role: loginSucess.role,
          userId:loginSucess.userId,
          newUser:false,
        })
      );
    }
  }, []);

  const [isNewModal, setIsNewModal] = useState(false);
  const handleCreateProject = () => {
    if (CustomerDetail.isAuthenticated) {
      dispatch(
        addProjectId({
          projectId: null,
          projectName: null,
          jobId: null,
          reload: false,
          base64Image: null,
        })
      );

       setIsOpen(true);

      // setIsNewModal(true);
    } else {
      toast({
        title: "Login with credentials",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "center-bottom",
      });
    }
  };

  // close newor Existing Modal
  const handleCloseNewModal = () => {
    setIsNewModal(false);
  };

  // open create project form
  const hanldeNewProject = () => {
    setIsOpen(true);
    setIsNewModal(false);
    dispatch(
      makeAllImageNull({
        isAllSegImage: true,
      })
    );
  };
  // close the create project Modal

  const handleCloseCreateProjectModal = () => {
    setIsOpen(false);
  };

  const handleListOfProject = () => {
    if (!CustomerDetail.isAuthenticated) {
      toast({
        title: "Please Login with Credentials",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "center-bottom",
      });
    }
  };
  const [isUploadExisting, setIsUploadExisting] = useState(false);
  // upoad existing modal

  const handleUploadExisting = () => {
    setIsNewModal(false);
    setIsUploadExisting(true);
  };
  const handleCloseUploadExistingModal = () => {
    setIsUploadExisting(false);
  };
  const getLoadingAlert = useSelector(getLoading);
  //console.log(getLoadingAlert);

  // get screen resolution to fix canvas size



  

  return (
    <>
      <div className="createproject-pages ">
        <div className="Container">
          <div className="crete-project-page">
            {CustomerDetail.customerName ? (
              <h1> Welcome {CustomerDetail.customerName}</h1>
            ) : (
              ""
            )}
          </div>

          <div
            className="create-project "
            style={{ display: "flex", flexWrap: "wrap" }}
          >
      <div className="card create-project-images">
              <i class="bi bi-house-add-fill"></i>
              <div className="card-content">
                <Button
                  onClick={handleCreateProject} 
                  // onClick={handleNewProject} 
                  variant="solid"
                  colorScheme="green"
                  size="md"
                  borderRadius="md"
                >
                  <span className="create-project-icon">Create Project</span>
                </Button>
              </div>
            </div> 





           






            {/* project List */}
            {CustomerDetail.newUser ? (
              <div className="card one">
                <img src={pImage} alt="Image 1" />
                <div className="card-content">
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    size="md"
                    borderRadius="md"
                  >
                    <span
                      className="create-project-icon1"
                     // onClick={handleListOfProject}
                    >
                     No Projects
                    </span>
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            ) : (
              <ProjectList />
            )}
          </div>
        </div>
      </div>

      <CreateProjectForm
        isOpen={isOpen}
        onClose={handleCloseCreateProjectModal}
      />
      {isNewModal && (
        <NewOrExistingCreateProject
          isNewModal={isNewModal}
          onCloseModal={handleCloseNewModal}
          newProject={hanldeNewProject}
          UploadExisting={handleUploadExisting}
        />
      )}
      {isUploadExisting && (
        <UploadExistingProject
          isOpen={isUploadExisting}
          onClose={handleCloseUploadExistingModal}
        />
      )}
      {/* {<Loading />} */}
      {getLoadingAlert && <Loading />}

      <Footer />
    </>
  );
};

export default CreateProject;

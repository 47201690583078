import { createSlice } from "@reduxjs/toolkit";

const AllSwatchesSlice = createSlice({
  name: "allSwatches",
  initialState: {
    allSwatches: [],
  },
  reducers: {
    addAllSwatches: (state, action) => {
      state.allSwatches = action.payload;
    },

    removeAllSwatches: (state) => {
      state.allSwatches = [];
    },
    
  },
});

export const { addAllSwatches, removeAllSwatches, } = AllSwatchesSlice.actions;
export const getAllSwatches= (state) => state.allSwatches.allSwatches;
export default AllSwatchesSlice.reducer;
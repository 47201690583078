import React from "react";
import { useEffect, useRef, useState } from "react";
import { Tooltip, Box, Button } from "@chakra-ui/react";

import { removeAllPalletImages } from "../slices/TransparentPalletImgSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectAllItems } from "../slices/MasterArraySlice";
import { addSelectedPallete } from "../slices/SelectedPalletdSlice";
import { addNewSelectedSwatch } from "../slices/SelectedSwatchImage";
import { addTabPannel } from "../slices/TabPannelSlice";
import "./ProjectLayer.css";
import { CloseButton } from '@chakra-ui/react'






const SwatchOption = ({
  mouseX,
  mouseY,
  selectedSegment,
  resetSwatchOption,
  onclose,
  deleteSegment,
}) => {
  const style = {
    position: "absolute",
    top: `${mouseY + 100}px`,
    left: `${mouseX + 200}px`,
    // Add additional styling as needed
  };
  const masterArray = useSelector(selectAllItems);


  const handleDeleteSegment = () => {
    deleteSegment();
  };

  const dispatch = useDispatch();
  // open Modal to add Pallet
  const handleAddPallet = () => {
    dispatch(removeAllPalletImages());
    if (masterArray && masterArray.master && masterArray.master.length > 0) {
      masterArray.master.map((item, index) => {
        let labelName = item.name;
        let label = selectedSegment.match(/[a-zA-Z]+/)[0];
        dispatch(
          addTabPannel({
            pannelTab: "Tab4",
          })
        );
        if (labelName === label) {
          //   setIndexs(index);
          item.segmentation.map((segment) => {
            if (selectedSegment === segment.name) {
              dispatch(addSelectedPallete({ selectedPallete: [segment] }));

              dispatch(
                addNewSelectedSwatch({
                  newSelectedSwatch: segment,
                })
              );
              resetSwatchOption(index);
            }
          });
        }
      });
    }
  };

  const handelClose=()=>{
    onclose()
  }

  return (
    <div>
      <div className="segemnt-popup-sections" style={style}>
      
        <Box  
          className="segment-pallet-select-popup"
          style={{
            display: "inline-block",
            gap: 1,
            border: "1px solid #6d455a",
          }}          
        >
          <CloseButton className="close-button-segment-popup" size='sm'  onClick={handelClose}/>

          <h3>{selectedSegment}</h3>
          <Tooltip label="Select Swatch">
            <Button
              className="select-popup-segment"
              variant="outline"
              onClick={handleAddPallet}
              colorScheme="green" // Add colorScheme to set the button color
              size="sm" // Add size to set the button size
            >
              <i class="bi bi-list-check"></i>
            </Button>
          </Tooltip>

          <Tooltip label="View Swatches">
            <Button
              className="select-popup-segment"
              variant="outline"
              //   onClick={handlViewPallet}
              colorScheme="green" // Add colorScheme to set the button color
              size="sm" // Add size to set the button size
            >
              <i class="bi bi-images"></i>
            </Button>
          </Tooltip>

          <Tooltip label="Delete" className="text-images">
            <Button
              className="select-popup-segment"
              variant="outline"
              onClick={handleDeleteSegment}
              //   onClick={handleDeleteSegment}
              colorScheme="purple" // Add colorScheme to set the button color
              size="sm" // Add size to set the button size
            >
              <i class="bi bi-trash"></i>
            </Button>
          </Tooltip>

          <Tooltip label="Information">
            <Button
              className="select-popup-segment"
              variant="outline"
              //   onClick={handlViewPallet}
              colorScheme="green" // Add colorScheme to set the button color
              size="sm" // Add size to set the button size
            >
              <i class="bi bi-info-lg"></i>
            </Button>
          </Tooltip>
        </Box>
      </div>
    </div>
  );
};

export default SwatchOption;

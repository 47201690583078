import React ,{useEffect} from 'react'
import {updateWindowResolution} from "../slices/windowSizeSlice/WindowResolution"
import { useDispatch } from 'react-redux'
const GetScreenResolution = () => {
  const dispatch= useDispatch()
    useEffect(()=>{
    
        const innerWidth =window.screen.width;
         const innerHeigth=window.screen.height;
       
         let width;
         let height;
         if(innerWidth===1920 && innerHeigth===1080){
           width = 1425
           height=825
         }
         else if(innerWidth===1600 && innerHeigth===900){
           width = 1166
           height=675
         }
         else if(innerWidth=== 1440 && innerHeigth===900){
           width = 1123
           height=650
         }
         else if(innerWidth=== 1366 && innerHeigth===768){
           width = 950
           height=550
         }
         else if(innerWidth=== 1023 && innerHeigth===768){
           width = 840
           height=486
         } else{
          width=1123
          height=650
         }
       
         dispatch(updateWindowResolution({
           canvasWidth:width,
           canvasHeight:height
         }))
         const data={
           canvasWidth:width,
           canvasHeight:height
         }
         sessionStorage.setItem("canvasResolution",JSON.stringify(data))
       
         },[]) 
  return (
    <div></div>
  )
}

export default GetScreenResolution
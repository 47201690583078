import React, { useEffect, useRef, useState } from "react";
import { selectAllItems, addAllSwatchs } from "../slices/MasterArraySlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addTransparentImage } from "../slices/TransparentPalletImgSlice";
import { compareProject } from "../slices/AlertSlice";
import {
  updateOverSwatch,
  getOverAllSwatch,
} from "../slices/OverAllSwatchSlice";
const SelectedOverALLPallete = ({ eachSegment }) => {
  const getMasterArray = useSelector(selectAllItems);
  const dispatch = useDispatch();
  const ismounted = useRef(true);
  const getAllOverAllSwatch = useSelector(getOverAllSwatch);
  // console.log(getAllOverAllSwatch.overAllSwatch);
  useEffect(() => {
    if (eachSegment && ismounted.current) {
      if (
        getMasterArray &&
        getMasterArray.master &&
        getMasterArray.master.length > 0
      ) {
        ismounted.current = false;
        let allSwatchsArray = [];
        getMasterArray.master.forEach((item) => {
          if (item.name === eachSegment) {
            if (item.segmentation) {
              item.segmentation.forEach((seg) => {
                if (seg.SelectSwatchJob && seg.SelectSwatchJob.length > 0) {
                  seg.SelectSwatchJob.forEach((swatch) => {
                    const id = swatch?.swatch?.data?.id;
                    if (id && !allSwatchsArray.some((s) => s.id === id)) {
                      allSwatchsArray.push({
                        id,
                        swatchImage: swatch?.swatch?.data?.attributes?.image_s3,
                        attributes: swatch?.swatch?.data,
                      });
                    }
                  });
                }
              });
            }
          }
        });
        // console.log(allSwatchsArray);
        // dispatch(
        //   addAllSwatchs({
        //     labelName: eachSegment,
        //     allSwatch: allSwatchsArray,
        //   })
        // );
        dispatch(
          updateOverSwatch({
            labelName: eachSegment,
            allSwatch: allSwatchsArray,
          })
        );
        ismounted.current = true;
      }
    }
  }, [eachSegment]);

  // handle selected swatch pallete images
  const handleSelectedSwatchImage = (id, swatchImage) => {
    let displayImage = [];
    if (
      getMasterArray &&
      getMasterArray.master &&
      getMasterArray.master.length > 0
    ) {
      getMasterArray.master.map((item) => {
        if (item.name === eachSegment) {
          if (item.segmentation && item.segmentation.length > 0) {
            item.segmentation.map((items) => {
              if (items.SelectSwatchJob && items.SelectSwatchJob.length > 0) {
                items.SelectSwatchJob.map((seg) => {
                  if (seg?.swatch?.data?.id === id) {
                    displayImage.push({
                      labelName: items.name,
                      transparentImage:
                        seg?.SegmentImage?.data?.attributes?.url,
                      swatchImage: swatchImage,
                      swatchId: id,
                    });
                  }
                });
              }
            });
          }
        }
      });
    }

    dispatch(
      addTransparentImage({
        transpatentImage: displayImage,
      })
    );

    dispatch(
      compareProject({
        isCompare: true,
      })
    );
  };

  return (
    <div>
      {/* {getMasterArray &&
        getMasterArray.master &&
        getMasterArray.master.map(
          (item) =>
            item.name === eachSegment &&
            item.allSwatches &&
            item.allSwatches.length > 0 &&
            item.allSwatches.map((swatch) => (
              <div key={swatch.id} className="segmentation-pallet">
                <div className="segments-pallet-select">
                  <img
                    className="segments-pallet-select-img"
                    src={
                      process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                      (swatch.swatchImage || "")
                    }
                    alt={`Swatch ${swatch.id}`}
                    onClick={() =>
                      handleSelectedSwatchImage(
                        swatch.id,
                        item.segmentation,
                        swatch.swatchImage
                      )
                    }
                  ></img>
                </div>
              </div>
            ))
        )} */}
      {getAllOverAllSwatch &&
        getAllOverAllSwatch.overAllSwatch &&
        getAllOverAllSwatch.overAllSwatch.length > 0 &&
        getAllOverAllSwatch.overAllSwatch.map((item) => {
          if (item.name === eachSegment && item.allSwatch) {
            return item.allSwatch.map((swatch) => (
              <div key={swatch.id} className="segmentation-pallet">
                <div className="segments-pallet-select">
                  <img
                    className="segments-pallet-select-img"
                    src={
                      process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                      (swatch.swatchImage || "")
                    }
                    alt={`Swatch ${swatch.id}`}
                    onClick={() =>
                      handleSelectedSwatchImage(
                        swatch.id,
                        // item.segmentation,
                        swatch.swatchImage
                      )
                    }
                  />
                </div>
              </div>
            ));
          } else {
            return null; // or render a fallback component or message
          }
        })}
    </div>
  );
};

export default SelectedOverALLPallete;

import React from 'react'
import { useState, useEffect } from "react"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,

} from '@chakra-ui/react'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, Box
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedSwatchOverAll } from "../../../slices/MasterArraySlice"
import { updateSelectedCurrentPallate } from "../../../slices/CurrentSelectedPalleteSlice"
import UniquePopHover from "./UniquePopHover.js"
import {selectAllItems} from "../../../slices/MasterArraySlice.js"
const UniqueSwatches = ({ uniqueSwatch, masterName, masterSeg, }) => {
  const dispatch = useDispatch()
  const masterArray =useSelector(selectAllItems)

  const [overAllSwatch,setOverAllSwatch]= useState()

  useEffect(()=>{

    if(masterArray &&masterArray.master && masterArray.master.length>0 && masterName){
      
           const segmaster= masterArray.master.find(item=>item.name===masterName);
          
             const overAllSwat=segmaster.allSwatches
             if(overAllSwat && overAllSwat.length>0){
              setOverAllSwatch(overAllSwat)
             }

    }
  },[masterName,masterArray])
// useEffect(()=>{
//   const allawatch=[];
//   if(masterSeg && masterSeg.length>0){
//     masterSeg.map((item)=>{
//       const allswatch=item.SelectSwatchJob
//       if( allswatch&&allswatch.length===0){
//          allswatch.map(swat=>{
//             if(allawatch && allawatch.length==0){
//               allswatch.push({
//                 swatchId:swat?.swatch?.data?.id,
//                 palletImage:swat?.swatch?.data?.attributes?.image_s3,
//                 palletName:swat?.swatch?.data?.attributes?.name,
//                 brand:
//                 palletstyle:"no data",
//                 label:"no data",
//                 customerSelected:false,
//                 selected:swat?.selected
//               })
//             }
//          })
//       }
//     })
//   }
// },[masterSeg])

  const handleoverAllSwatch = (swatchId, masterName, allSegments, isSelected) => {

    // update master Array
    if (!isSelected) {
      dispatch(updateSelectedSwatchOverAll({
        labelName: masterName,
        swatchId: swatchId,
        selected: true

      }))

      // update curren pallet
      if (allSegments && allSegments.length > 0) {
        allSegments.forEach(seg => {
          let allSwatch = seg.SelectSwatchJob;
          if (allSwatch.length > 0) {
            allSwatch.forEach(swatch => {
              if (swatch.swatch.data.id === swatchId) {
                dispatch(updateSelectedCurrentPallate({
                  segmentName: seg.name,
                  swatchId: swatchId,
                  palletImage: swatch.SegmentImagePath,
                  checked: true
                }))
              }
            })
          }
        })
      }
    } else {
      dispatch(updateSelectedSwatchOverAll({
        labelName: masterName,
        swatchId: swatchId,
        selected: false

      }))

      // update curren pallet
      if (allSegments && allSegments.length > 0) {
        allSegments.forEach(seg => {
          let allSwatch = seg.SelectSwatchJob;
          if (allSwatch.length > 0) {
            allSwatch.forEach(swatch => {
              if (swatch.swatch.data.id === swatchId) {
                dispatch(updateSelectedCurrentPallate({
                  segmentName: seg.name,
                  swatchId: null,
                  palletImage: null,
                  checked: false,
                  // s3BucketImage:null,
                }))
              }
            })
          }
        })
      }

    }

  }
  return (
    <div>
      <Accordion className="mt-1 mb-1" defaultIndex={[0]} allowMultiple>
        <AccordionItem className='card-section'>

          <AccordionButton>
            <Box className="project-card-title" as="span" flex='1' textAlign='left'>
              Selected swatches
            </Box>
            <AccordionIcon />
          </AccordionButton>


          <AccordionPanel className="card-pallet-img" pb={4}>
            <div className='select-pallet-prodect'>
              {overAllSwatch &&
                overAllSwatch.length > 0 ? (
                overAllSwatch.map((seg, index) =>
                
                  (
                  <div className='se-pallet-box' key={index}
                  >
                    <img
                      src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${seg.palletImage}`}
                      alt={`Swatch ${index}`}


                      onClick={() => { handleoverAllSwatch(seg.swatchId, masterName, masterSeg, seg.selected) }}
                    />
                    {seg.selected &&
                      <div className="sel-pallet-checkbox-img">
                        <img className='check-box-img' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Group_3_1_8943bd053e.png"></img>
                      </div>
                    }


                    <div className="pallet-act">
                      <Popover trigger="hover">
                        <PopoverTrigger>
                          <div className="pallet-information-icon">
                            <i class="bi bi-info">  </i>
                          </div>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent>

                            <PopoverBody>
                              <UniquePopHover
                                swatch={seg}
                                segment={seg}
                                segmentName={seg.name}
                                // uploadImage={handleuploadimage}
                                masterName={masterName}

                              />
                           
                            </PopoverBody>

                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </div>


                  </div>
                ))
              ) : (<>
                <div className=' no-pallet-img'>
                  <img
                    key="no-data1"
                    src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/no_image_2_c09c5031e6.png"
                    width={100}

                    className="home-images"
                    alt="home icon"
                  />
                </div>

              </>)
              }
            </div>
          </AccordionPanel>
        </AccordionItem>

      </Accordion>
    </div>
  )
}

export default UniqueSwatches


{/* <div className='select-pallet-prodect'>

<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_stone_1_69fa762f6b.jpg"></img>
</div>
<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Wall_1_c3b8cd2fed.png"></img>
</div>
<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_img_1_22baad481e.jpg"></img>
</div>
<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Wall_3_636523f646.jpg"></img>
</div>


</div> */}
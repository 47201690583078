import { configureStore } from "@reduxjs/toolkit";
import imagesReducer from "../slices/ImageSlices";
import selectedImageReducer from "../slices/SelectedImageSlice";
import masterReducer from "../slices/MasterArraySlice";
// import authReducer from "../login/Reducer";
import allSegmentReducer from "../slices/AllSegmentSlice";
import breadCrumbReducer from "../slices/BreadScrumbSlice";
import authReducer from "../login/AuthSlice";
import alertReducer from "../slices/AlertSlice";
import projectImageReducer from "../slices/CreateProjectImageSlice";
import projectIdReducer from "../slices/ProjectSlice";
import projectViewReducer from "../slices/ProjetcViewSlice";
import selectedSwatchReducer from "../slices/SelectedPalletdSlice";
import reloadJobReducer from "../slices/ReloadJobSlice";
import UpdateSegmentReducer from "../slices/UpdateSegmentSlice";
import ClosePreSegmentImageReducer from "../slices/ClosePreSegmentImageSlice";
import JobReducer from "../slices/JobSlice";
import AfterDeleteSegmentReducer from "../slices/AfterDeleteSegmentSlice";
import CurrentTabReducer from "../slices/CurrentTabSlice";
import JobArrayReducer from "../slices/JobArratSlice";
import Label_BrandREducer from "../slices/Label_Brand_Swatch";
import AddPalletReducer from "../slices/Label_Brand_swatch_Slice";
import transparentPalletImageReducer from "../slices/TransparentPalletImgSlice";

import CurrentSelectedPalleteReducer from "../slices/CurrentSelectedPalleteSlice";
import GetAllCustomerReducer from "../slices/GetAllCustomer";

import SelectedSwatchImageSliceReducer from "../slices/SelectedSwatchImage";

import MasterJobSliceReducer from "../slices/MasterJobs";
import ViewPalletInfoSliceReducer from "../slices/ViewPalletInfoSlice";
import OverAllSwatchSliceReducer from "../slices/OverAllSwatchSlice";

import LoadingSliceReducer from "../slices/LoadingSlice";
import ExistingUploadSliceReducer from "../slices/ExistingUploadSlice";

import TabPannelSliceReducer from "../slices/TabPannelSlice";
import FolderReducer from "../slices/FolderSlice";

import ReverseResponseReducer from "../slices/ExistingProject/ReverseResponse";
import DesignerJobListReducer from "../slices/Designer/DesignerJobListSlice";

import ModelSliceReducer from "../slices/Model/ModelSlice";
import ModelVersionReducer from "../slices/Model/ModelVersionSlices";

import EditSegmentSlice from "../slices/EditSegment/EditSegment";
import MultiSelectionSegement from "../slices/gridView/MultiSelectionSegement";

import CombinationSwatchSlice from "../slices/combinationSwatch/CombinationSwatchSlice";
import allSwatchesSlices from "../slices/AllSwatches/AllSwatches"

import MouseEventSlice from "../slices/MouseEvent/MouseEventSlice";

import customerViewpalletSlice from "../slices/CustomerSlice/customerViewpalletSlice";

import PopHoverSlice from "../slices/popHover/PopHoverSlice"; 

import ShadowSlice from "../slices/shadow/ShadowSlice"
import CompareSlice from"../slices/compare/CompareSlice"

import DeleteSlice from "../slices/DeleteSlice/DeleteSlice";


import uploadSwatchImageSlice from "../slices/uploadSwatch/UploadSwatchImage";
import windowResolutionSlice from "../slices/windowSizeSlice/WindowResolution"

import CreateProjectHeaderSlice from "../slices/CreateProjectHeaderSlice/CreateProjectHeaderSlice"
import AddProjectSlice from "../slices/addProject/AddProjectSlice";

import ShareLinkReducer from "../slices/shareLinkSlice/ShareLinkSlice"

import MediaLibraryReducer from "../slices/mediaLibrary/MediaLibrarySlice"

import OverlapReducer from "../slices/overlapSlice/OverLapSlice"

import ThresholdReducer from "../slices/threshold/ThresholdSlice"

import DoorHeightSlice  from "../slices/doorheight/DoorheightSlice"
export default configureStore({


  reducer: {
    auth: authReducer,
    images: imagesReducer,
    selectedImage: selectedImageReducer,
    masterArray: masterReducer,
    allSegmentsArray: allSegmentReducer,
    breadCrumbs: breadCrumbReducer,
    alerts: alertReducer,
    projectImage: projectImageReducer,
    projectId: projectIdReducer,
    projectView: projectViewReducer,
    selectedSwatch: selectedSwatchReducer,
    reloadJob: reloadJobReducer,
    updateSegment: UpdateSegmentReducer,
    preSegmentImage: ClosePreSegmentImageReducer,
    job: JobReducer,
    afterDeleteSegment: AfterDeleteSegmentReducer,
    currentTab: CurrentTabReducer,
    Label_Brand: Label_BrandREducer,
    addPallet: AddPalletReducer,
    jobArray: JobArrayReducer,
    transparentPalletImage: transparentPalletImageReducer,
    getAllCustomer: GetAllCustomerReducer,
    currentpallate: CurrentSelectedPalleteReducer,
    selectedSwatchImage: SelectedSwatchImageSliceReducer,
    masterJob: MasterJobSliceReducer,
    palleteInfo: ViewPalletInfoSliceReducer,
    overAllSwatch: OverAllSwatchSliceReducer,
    loading: LoadingSliceReducer,
    uploadExisting: ExistingUploadSliceReducer,
    tabPannel: TabPannelSliceReducer,
    folder: FolderReducer,
    responce: ReverseResponseReducer,
    designerJob: DesignerJobListReducer,
    model: ModelSliceReducer,
    modelVersion: ModelVersionReducer,
    editSegmentation: EditSegmentSlice,
    gridMultiSelect: MultiSelectionSegement, // grid view multi select pallet
    combinationSwatch:CombinationSwatchSlice,
    allSwatches:allSwatchesSlices,
    mouseEvent:MouseEventSlice,
    customerViewPallet:customerViewpalletSlice, // customer view pallet slice
    popHover:PopHoverSlice, // tp look after all swatch pallets
    shadow:ShadowSlice, // add shadow slice
    compare:CompareSlice ,// comapre image
    deleteSegment:DeleteSlice,

    uploadSwatchImage:uploadSwatchImageSlice,  // upload swatch image slice 
    windowResolution:windowResolutionSlice, // update screen resolution
 
    CreateProjectHeader:CreateProjectHeaderSlice ,// create project header
    addProject:AddProjectSlice, // add new project on slice
    shareLink:ShareLinkReducer,
    mediaLibrary:MediaLibraryReducer,  // media library to change tab index on segmentation
 
    overlap:OverlapReducer,
    threshold:ThresholdReducer, 

    doorHeightModal:DoorHeightSlice,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),

  
});

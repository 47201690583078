import React, { useState, useEffect } from "react";
import { Checkbox } from "@chakra-ui/react";
import {
  addGridMultiSelect,
  updateGridMultiSelect,
} from "../slices/gridView/MultiSelectionSegement";
import { useDispatch } from "react-redux";

const SegmentCheckBox = ({
  selectedSeg,
  checkBoxIndex,
  onCheckboxChange,
  selectedAll,
}) => {
  const [isChecked, setIsChecked] = useState([]);
  const dispatch = useDispatch();

  // allselected is true
  useEffect(() => {
    if (selectedAll && selectedAll.length > 0) {
      selectedAll.map((item, index) => {
        setIsChecked({ [index]: true });
      });
    }
  }, [selectedAll]);
  useEffect(() => {
    setIsChecked({ [checkBoxIndex]: false });
  }, [selectedSeg, checkBoxIndex]);

  const handleCheckboxChange = (val) => {
    //
    setIsChecked({ [checkBoxIndex]: val });
    //console.log(isChecked);
    if (val) {
      dispatch(addGridMultiSelect({ gridMultiSelect: selectedSeg }));
    } else {
      dispatch(updateGridMultiSelect({ segName: selectedSeg.name }));
    }

    onCheckboxChange(val, checkBoxIndex);
  };

  return (
    <div>
      <Checkbox
        key={checkBoxIndex}
        className="checkbox-segment"
        checked={isChecked[checkBoxIndex]}
        onChange={(e) => {
          handleCheckboxChange(e.target.checked);
        }}
      ></Checkbox>
    </div>
  );
};

export default SegmentCheckBox;

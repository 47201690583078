import React ,{useEffect, useState}from 'react'
import CustomerRight from "./RightSection/CustomerRight"
import {getRenderCombination,getShowCombo} from "../slices/combinationSwatch/CombinationSwatchSlice.js"
import {useSelector, useDispatch} from "react-redux" 
import CombinationLayer from "../ProjectLayer/CombinationLayer/CombinationLayer"
const CustomerImageSection = () => {

  // open combination layer
const getCombinationstart= useSelector(getShowCombo)
const[isCombinationLayer, setIsCombinationLayer]= useState(false)
const[isCustomerRight, setIsCustomerRight]= useState(true)


useEffect(()=>{
  if(getCombinationstart 

   ){
    setIsCustomerRight(false)
     setIsCombinationLayer(true)
     
   }else{
     
     setIsCombinationLayer(false)
     setIsCustomerRight(true)
   }
},[getCombinationstart])
  return (
    <>


{isCustomerRight && <CustomerRight/>}

{isCombinationLayer &&
        <CombinationLayer/>}
    </>
  
    
  )
}

export default CustomerImageSection

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLabelName: null,
  selectedSwatchId: null,
  selectedSwatchImage: null,
  newSelectedSwatch: [],
  showSwactches: [],
};

const SelectedSwatchImageSlice = createSlice({
  name: "selectedSwatchImage",
  initialState: initialState,
  reducers: {
    addSelectedSwatch: (state, action) => {
      state.selectedLabelName = action.payload.selectedLabelName;
      state.selectedSwatchId = action.payload.selectedSwatchId;
      state.selectedSwatchImage = action.payload.selectedSwatchImage; // Corrected the property name
    },
    addNewSelectedSwatch: (state, action) => {
      state.newSelectedSwatch = action.payload.newSelectedSwatch;
      state.showSwactches = [];
    },
    addShowSwatch: (state, action) => {
      state.showSwactches = action.payload.showSwactches;
    },
    removeNewSelectedSwatch:(state, action)=>{
      state.newSelectedSwatch=[]
    }
  },
});

export const { addSelectedSwatch, 
  addNewSelectedSwatch, addShowSwatch,removeNewSelectedSwatch } =
  SelectedSwatchImageSlice.actions;
export const getSelectedSwatchImage = (state) => state.selectedSwatchImage;
export const getAllShowSwatches = (state) =>
  state.selectedSwatchImage.showSwactches;

export default SelectedSwatchImageSlice.reducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSegment, getIsDeleteSegment } from "../slices/AlertSlice";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import { getProjectId } from "../slices/ProjectSlice";
const DeleteSegment = ({ deleteSegment }) => {
  const isDeleteSeg = useSelector(getIsDeleteSegment);
  const getAllSegments = useSelector(selectAllSegments);
  const getProgectDetails = useSelector(getProjectId);
  const [updatedJob, setUpdatedJob] = useState();
  useEffect(() => {
    let allArray = [];
    if (isDeleteSeg) {
      if (
        getAllSegments &&
        getAllSegments.allSegmentsArray &&
        getAllSegments.allSegmentsArray.length > 0
      ) {
        getAllSegments.allSegmentsArray.map((item) => {
          let swatchArray = [];
          item.SelectSwatchJob.map((seg) => {
            swatchArray.push({
              swatch: seg?.swatch?.data?.id,
              SegmentImage: seg?.SegmentImage?.data?.id,
            });
          });
          allArray.push({
            name: item.name,
            area_pixel: item.area_pixel,
            annotationPolygon: item.annotationPolygon,
            confidence: item.confidence,
            BoundingBoxFloat: item.BoundingBoxFloat,
            BoundingBoxInt: item.BoundingBoxInt,
            SelectSwatchJob: swatchArray,
          });
        });
      }

      setUpdatedJob(allArray);
      deleteSegment(allArray);
    }
  }, [isDeleteSeg]);
  return <div></div>;
};

export default DeleteSegment;

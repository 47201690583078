import { createSlice } from "@reduxjs/toolkit";

const MasterJobSlice = createSlice({
  initialState: {
    masterJob: [],
  },
  name: "masterJob",
  reducers: {
    addMasterJob: (state, action) => {
      state.masterJob = [...action.payload];
    },
    updateMasterJob: (state, action) => {
      const { swatch, segmentImage ,userId} = action.payload;
            
      if (segmentImage && segmentImage.length > 0) {
        segmentImage.forEach((segment) => {
          const seg = state.masterJob.findIndex(
            (item) => item.name === segment.class_name
          );
          // (seg);
          if (seg>=0) {
            // Swatch doesn't exist, add it
           
           let selectedSeg=state.masterJob[seg].SelectSwatchJob;
           if(selectedSeg.length >0){
            state.masterJob[seg].SelectSwatchJob.map(item=>{
              
              if(item.swatch===swatch){
                item.SegmentImagePath=segment.output;
                item.User=userId
              }
            })
           }else{
             state.masterJob[seg].SelectSwatchJob.push({
               swatch:swatch,
               User: userId,
               SwatchFor:segment.class_name,
               SegmentImagePath:segment.output
             });
           }
            // seg.SelectSwatchJob.push({
            //   swatch: parseInt(swatch, 10),
            //   User: userId,
            //   SwatchFor:segment.class_name,
            //   SegmentImagePath:segment.output
            // });
          }
        });
      }
    },


    addNewMasterJob: (state, action) => {
      const {
        label,
        flattenedPoints,
        BoundingBoxInt,
        BoundingBoxFloat,
        area_pixel,
      } = action.payload;
      const existingJobIndex = state.masterJob.findIndex((job) => job.name === label);
      
      if (existingJobIndex !== -1) {
        // Update existing job
        // state.masterJob[existingJobIndex] = {
        //   name: label,
        //   annotationPolygon: flattenedPoints,
        //   BoundingBoxFloat: BoundingBoxFloat,
        //   BoundingBoxInt: BoundingBoxInt,
        //   area_pixel: area_pixel,
        //   SelectSwatchJob: [],
        // };
        state.masterJob[existingJobIndex].annotationPolygon = flattenedPoints;
        state.masterJob[existingJobIndex].BoundingBoxFloat = BoundingBoxFloat;
        state.masterJob[existingJobIndex].BoundingBoxInt = BoundingBoxInt;
        state.masterJob[existingJobIndex].area_pixel = area_pixel;
      }else{
      state.masterJob.push({
        name: label,
        annotationPolygon: flattenedPoints,
        BoundingBoxFloat: BoundingBoxFloat,
        BoundingBoxInt: BoundingBoxInt,
        area_pixel: area_pixel,
        SelectSwatchJob: [],
      });
    }
    },
    deleteMasterJob: (state, action) => {
      const { labelName } = action.payload;
      const jobIndex = state.masterJob.findIndex(
        (item) => item.name == labelName
      );
      if (jobIndex !== -1) {
        state.masterJob.splice(jobIndex, 1);
      }
    },
    deleteSwatchMasterJob: (state, action) => {
      const { labelName, swatchId } = action.payload;

      const jobIndex = state.masterJob.findIndex(
        (item) => item.name === labelName
      );
      // ("JobIndex: " + jobIndex);
      if (jobIndex !== -1) {
        const seg = state.masterJob[jobIndex].SelectSwatchJob;
        const swatchIndex = seg.findIndex((item) => item.swatch === swatchId);
        // ("SwatchIndex: " + swatchIndex);
        if (swatchIndex !== -1) {
          state.masterJob[jobIndex].SelectSwatchJob.splice(swatchIndex, 1);
        }
      }
    },
    updateJobArray: (state, action) => {  
      //

      
       const {labelName, boundingBoxFloat, boundingBoxInt,annotationPolygon}=action.payload;
       const jobIndex = state.masterJob.findIndex(
        (item) => item.name === labelName
      );
      if (jobIndex !== -1) {
        if(boundingBoxFloat &&boundingBoxInt){
          state.masterJob[jobIndex].boundingBoxFloat=boundingBoxFloat;
          state.masterJob[jobIndex].boundingBoxInt=boundingBoxInt;
        }
       
        state.masterJob[jobIndex].annotationPolygon=annotationPolygon;
      }
    },
    updateSwatchImage:(state, action)=>{
      //
      const {labelName, swatchId, segmentimageId}=action.payload;
      const jobIndex = state.masterJob.findIndex(
        (item) => item.name === labelName
      );
      if (jobIndex !== -1) {
        let swatch_id=parseInt(swatchId, 10);
        const seg = state.masterJob[jobIndex].SelectSwatchJob;
        const swatchIndex = seg.findIndex((item) => item.swatch === swatch_id);
        if (swatchIndex !== -1) {
          state.masterJob[jobIndex].SelectSwatchJob[swatchIndex].SegmentImagePath=segmentimageId;
        }
        
      }
    },
    updateOverLapJobArray: (state, action) => {  
      //
       const {labelName, annotationPolygon}=action.payload;
       const jobIndex = state.masterJob.findIndex(
        (item) => item.name === labelName
      );
      if (jobIndex !== -1) {
      
        state.masterJob[jobIndex].annotationPolygon=annotationPolygon;
      }
    },
  },
});

export const {
  addMasterJob,
  updateMasterJob,
  addNewMasterJob,
  deleteMasterJob,
  deleteSwatchMasterJob,
  updateJobArray,
  updateSwatchImage,
  updateOverLapJobArray
} = MasterJobSlice.actions;
export const getAllMasterJobs = (state) => state.masterJob;
export default MasterJobSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  segmentName: null,
  selectedPlaletUrl: null,
  s3BucketImage: null,
  SwatchId: null,
  transpatentImage: [],
  isActiveMouseLeave:false
 

};

const TransparenPalletImageSlice = createSlice({
  name: "transparentPalletImage",
  initialState,
  reducers: {
    addSelectedPalletImag: (state, action) => {
      state.selectedPlaletUrl = action.payload.selectedPlaletUrl; // Use action.payload
      state.segmentName = action.payload.segmentName; // Use action.payload
      state.s3BucketImage = action.payload.s3BucketImage;
      state.SwatchId = action.payload.SwatchId;
     
     
    },
    activeMouseLeave:(state, action)=>{
state.isActiveMouseLeave = action.payload.isActiveMouseLeave;
    },
    
    removeSelectedPalletImag: (state) => {
      state.selectedPlaletUrl = null; // Set to null to clear the selectedPlaletUrl
      state.segmentName = null; // Set to null to clear the segmentName
    },
    addTransparentImage: (state, action) => {
      state.transpatentImage = action.payload.transpatentImage;
    },
    removeTramsparentImage: (state, action) => {
      state.transpatentImage = []; // Set to null to clear the
    },
    removeAllPalletImages:(state, action) => {
      state.segmentName=null;
      state.selectedPlaletUrl= null;
      state.s3BucketImage=null;
      state.SwatchId=null;
      state.transpatentImage=[];
      state.isActiveMouseLeave=false;
     
     
    }
,  },
});

export const {
  addSelectedPalletImag,
  removeAllPalletImages,
  removeSelectedPalletImag,
  updateSwatchImage,
  addTransparentImage,
  removeTramsparentImage,
  activeMouseLeave
} = TransparenPalletImageSlice.actions;
export const getTranspalletImage = (state) => state.transparentPalletImage;
export const getAllTransparentImages = (state) =>
  state.transparentPalletImage.transpatentImage;

  export const getMouseActive = (state) => state.transparentPalletImage.isActiveMouseLeave;
export default TransparenPalletImageSlice.reducer;

import React from 'react'
import {useEffect, useState} from "react"
import Axios from "axios"
import { useDispatch } from "react-redux";
import {addAllSwatches} from "../slices/AllSwatches/AllSwatches"
const GetAllSwatches = () => {
  let token= sessionStorage.getItem('token');
    const dispatch = useDispatch()
    useEffect(()=>{
      if(token){
        getAllSwatches()
      }
        
    },[token]);

    const getAllSwatches=async() => {
      const authToken = "Bearer " + token;
        const response = await Axios.get(
            `https://aiadmin.dzinly.org/content-manager/collection-types/api::pallet.pallet?page=1&pageSize=100&sort=updatedAt:DESC`,
            {
              headers: {
                // "Content-Type": "application/json",
                Authorization: authToken
                // Other headers if needed
              },
            }
          );
            //  console.log("getAllSwatches", response);
             if (response.status === 200) {
              sessionStorage.setItem("allSwatch", JSON.stringify(response?.data?.results))
                dispatch(addAllSwatches({
                    allSwatches: response?.data?.results
                }))
                
             }
    
    }
  return (
    <div></div>
  )
}

export default GetAllSwatches
import React from 'react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'

export const Progress = () => {


  return (
    <>
    <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl'
/>
<h3>Image processing</h3>
        
    </>
  )
}
export default Progress;
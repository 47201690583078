
import React from "react";
import {useState, useEffect} from "react"
import { Tooltip } from '@chakra-ui/react'
import "./addSegments.css";
import {selectAllItems} from "../../slices/MasterArraySlice";
import {useSelector, useDispatch} from "react-redux"
import {addStartSegment,
  getSaveAddSegment,finishAddSegment,
  addSegDetail} from "../../slices/EditSegment/EditSegment"

  import {startUndo,discardAnnotation} from "../../slices/EditSegment/EditSegment"
  import {getAllShadows} from "../../slices/shadow/ShadowSlice"
  const AddSegment = ({moveToLeftSection1,
    reSegName,
    reMasterName}) => {
 const dispatch= useDispatch()
const [isStartAddSegment, setIsStartAddSegment]= useState(false)
const [isSelectLabelName, setIsSelectLabelName]= useState(true)
const getMasterArray= useSelector(selectAllItems);
const [label, setLabel] = useState();
const [segment, setSegment] = useState();
const [showMarkButton, setShowMarkButton]= useState(true);
const [showSaveButton, setShowSaveButton]= useState(false);
const [isshowUndo, setIsshowUndo]= useState(false);
 const getAllShadowss= useSelector(getAllShadows);
const handleLabelSeg = (segName) => {
   
  setIsSelectLabelName(false);
  setIsStartAddSegment(true);
  setSegment(segName);

 if(segName==="Shadow"){
     findOutShadowName(segName)
 } else{
  findOutSegName(segName)
 }
  
};
  // if not shadow

  const findOutSegName=(segName)=>{
    const foundItem = getMasterArray.master.find((item) => item.name === segName);

    if (foundItem) {
      const segArray = foundItem.segmentation || [];
      const arraLength = segArray.length;
      const name = arraLength > 0 ? segArray[arraLength - 1].name : null;
  
      
      if (name) {
        const match = name.match(/\d+/);
        if (match) {
          const number = parseInt(match[0], 10);
          const labelName = segName + (number + 1);
          setLabel(labelName);
        } else {
          const labelName = segName + "1";
          setLabel(labelName);
        }
      } else {
        const labelName = segName + "1";
        setLabel(labelName);
      }
    }
  }

  
const findOutShadowName=(segName)=>{
  let shadowSegLength= getAllShadowss?.allShadow.length;
  const labelName = segName + (shadowSegLength + 1);
          setLabel(labelName);
}
// discard
const handleDiscard=()=>{
  setIsSelectLabelName(true)
  setIsStartAddSegment(false)
  setLabel("")
  setSegment("")
  setShowSaveButton(false)
   setShowMarkButton(true)
   dispatch(discardAnnotation({
    isDiscard:true,
    isAddSegmentsStart:false
  }))
}

const handleMarkSegment=()=>{
  setIsshowUndo(true)
 dispatch(addStartSegment({
  isAddSegmentsStart:true,
  labelName:label,
  segName:segment
 }))


}

// after mark segemnt
const getSaveAddSegments= useSelector(getSaveAddSegment)
const[isReAnnotationActive, setIsReAnnotationActive]= useState(true)
useEffect(()=>{

  if(getSaveAddSegments){
   setShowSaveButton(true)
   setShowMarkButton(false)
  //  dispatch(addSegDetail({
  //   labelName:label,
  //   segName:segment
  //  }))
  }
},[getSaveAddSegments]); 


const handleSaveSegment=()=>{
  dispatch(finishAddSegment({
    isAddFinsihSegments:true,
    isSaveAddSegment:false
  }))
  setIsshowUndo(false)
  moveToLeftSection1()
}

// reAnnoattaion
useEffect(()=>{
if(reSegName&&reMasterName){
  setLabel(reSegName);
  setSegment(reMasterName)
  setIsSelectLabelName(false)
  setIsStartAddSegment(true)
}

},[reMasterName,reSegName])

// undo
const handleUdo=()=>{
  dispatch(startUndo({
    isUndoStart:true
   }))
}

  return (
    <div>

      
  {isStartAddSegment && <div className="label-seg-name">
     
     
        <div class="seg-label-name">
        <h2 className='labels-title-names'>{label}</h2>
        </div>


        <div className='project-seg-actions'>

                       <Tooltip hasArrow label='Select Swatch' bg='gray.300' color='black'>
                            <div className='segment-select-tool-icons'>
                                <i class="bi bi-list-check"></i>
                            </div>
                            </Tooltip>

                            <Tooltip hasArrow label='View Swatches' bg='gray.300' color='black'>
                            <div className='segment-select-tool-icons'>
                                <i class="bi bi-image"></i>
                            </div>
                            </Tooltip>

                            <Tooltip hasArrow label='Edit' bg='gray.300' color='black'>
                            <div className='segment-select-tool-icons'>
                                <i class="bi bi-pencil-square"></i>
                            </div>
                            </Tooltip>

                             <Tooltip hasArrow label='Re-Annotation' bg='gray.300' color='black'>
                            <div className={`segment-select-tool-icons ${isReAnnotationActive?"icon-active":""}`}>
                                <i class="bi bi-bounding-box-circles"></i>
                            </div>
                            </Tooltip>
                            <Tooltip hasArrow label='Delete' bg='gray.300' color='black'>
                            <div className='segment-select-tool-icons'>
                                <i class="bi bi-trash3"></i>
                            </div>
                            </Tooltip>
         </div>


         
          <div className="button-seg">
   {  showMarkButton &&      <button 
           onClick={handleMarkSegment}
           type="button" class="chakra-button"> <i class="bi bi-pen"></i> Mark Segment</button>}
          
          
          { showSaveButton&&<button 
           onClick={handleSaveSegment}
           type="button" class="chakra-button"> <i class="bi bi-pen"></i> Save Segment</button>}
          
            <button type="button" class="chakra-button"
            onClick={handleDiscard}
            ><i class="bi bi-x-lg"></i> Discard</button> 
            
          
           
          </div>
      
       
    
    </div>}

      { isSelectLabelName && <div>
        <h2 className='select-category-title'>Select Category</h2>
        <div className="project-segments-img">

          {getMasterArray &&
              getMasterArray.master &&
              getMasterArray.master.length > 0 &&
              
              getMasterArray.master.map((item, index) => {
                return(
                  item.icon &&
                  (
                    <div className="project-seg-images "
                    key={item.id}
                    onClick={() => handleLabelSeg(item.name)}
          >
            <div className="seg-contant"  key={item.name}
             
            >
              <h2
             
              >{item.name}</h2>
            </div>
            <div className="segament-img ">
              <img src={item.icon}></img>
            </div>
          </div>

                ))
              })}


        
        </div>

      </div>}




 
    </div>
  )
}

export default AddSegment

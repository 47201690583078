import React, { useState, useEffect, useRef } from "react";
import { Tooltip, Box, Button } from "@chakra-ui/react";
import ModalToAdd from "./ModalToAdd";
import { closeModals, getCloseModal } from "../slices/AlertSlice";
import { useDispatch, useSelector } from "react-redux";
import SelectedPalletInfo from "../Tabs/SelectedPalletInfo";
import CallApi from "./CallApi";
import { removeAllPalletImages } from "../slices/TransparentPalletImgSlice";
import UploadSegmentedImage from "../Add_Edit_Pallet/UploadSegmentedImage";

import { addTabPannel } from "../slices/TabPannelSlice";
const ShowEdit = ({
  indexs,
  deleteSegment,
  searchViewPallet,
  selectedPallete,
  editSegmentation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  // open Modal to add Pallet
  const handleAddPallet = () => {
    setIsOpen(true);
    dispatch(removeAllPalletImages());

    dispatch(
      addTabPannel({
        pannelTab: "Tab1",
      })
    );
  };

  // close Modal
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const close_modal = useSelector(getCloseModal);
  useEffect(() => {
    if (close_modal) {
      handleCloseModal();

      dispatch(
        closeModals({
          closeModal: false,
        })
      );
    }
  }, [close_modal]);

  // upload data

  const [palletImagePath, setpalletImagePath] = useState();
  const [flagDemo, setflagDemo] = useState();
  const [palletId, setpalletId] = useState();
  const [palletName, setpalletName] = useState();
  const [isopen, setIsopen] = useState(false);
  const [isCallAPIopen, setIsCallAPIopen] = useState(false);
  const isMounted = useRef(true);
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch1"));
  const [uploadData, setUploadData] = useState(null);
  useEffect(() => {
    if (
      handleSwatch &&
      handleSwatch?.palletImagePath &&
      handleSwatch?.flagDemo &&
      handleSwatch?.palletId &&
      handleSwatch?.palletName &&
      isMounted.current
    ) {
     
      setpalletImagePath(handleSwatch.palletImagePath);
      // setflagDemo(handleSwatch.flagDemo);
      // setpalletId(handleSwatch.palletId);
      // setpalletName(handleSwatch.palletName);

      setIsCallAPIopen(true);
      sessionStorage.setItem("handleSwatch", JSON.stringify(handleSwatch));
      sessionStorage.removeItem("handleSwatch1");
      isMounted.current = false;
    }
  }, [handleSwatch]);

  // reset data
  const handleResetData = (data) => {
    setUploadData(data);

    setpalletImagePath(null);
    setflagDemo(null);
    setpalletId(null);
    setpalletName(null);
    setIsCallAPIopen(false);
    isMounted.current = true;
  };

  // view selected palleted info
  const [isViewPallet, setIsViewPallet] = useState(false);
  const handlViewPallet = () => {
    if (selectedPallete.length > 0) {
      setIsViewPallet(true);
      searchViewPallet();
    } else {
      alert("No swatch is added");
    }
  };

  const handleEditSegment = () => {
    editSegmentation();
  };

  const handleCloseViewPallet = () => {
    setIsViewPallet(false);
  };

  //  delete segement
  const handleDeleteSegment = () => {
    deleteSegment();
  };

  // upload the datta

  const handleReset = () => {
    setUploadData(null);
  };
  return (
    <div>
      <Box
        style={{
          display: "inline-block",
          gap: 10,
          border: "2px solid #000",
          padding: 5,
        }}
      >
        <Tooltip label="Select Swatch">
          <Button className="view-actions-icons"
            variant="outline"
            onClick={handleAddPallet}
            colorScheme="green" // Add colorScheme to set the button color
            size="sm" // Add size to set the button size
          >
            <i class="bi bi-list-check"></i>
          </Button>
        </Tooltip>

        <Tooltip label="View Swatches">
          <Button className="view-actions-icons"
            variant="outline"
            onClick={handlViewPallet}
            colorScheme="green" // Add colorScheme to set the button color
            size="sm" // Add size to set the button size
          >
            <i class="bi bi-images"></i>
          </Button>
        </Tooltip>

       

        <Tooltip label="Edit" className="text-images">
          <Button className="view-actions-icons"
            variant="outline"
            onClick={handleEditSegment}
            colorScheme="purple" // Add colorScheme to set the button color
            size="sm" // Add size to set the button size
          >
             <i class="bi bi-pencil-square"></i>
          </Button>
        </Tooltip>

        <Tooltip label="Delete" className="text-images">
          <Button className="view-actions-icons"
            variant="outline"
            onClick={handleDeleteSegment}
            colorScheme="purple" // Add colorScheme to set the button color
            size="sm" // Add size to set the button size
          >
            <i class="bi bi-trash"></i>
          </Button>
        </Tooltip>


     
        <Tooltip label="Information">
          <Button className="view-actions-icons"
            variant="outline"
            onClick={handlViewPallet}
            colorScheme="green" // Add colorScheme to set the button color
            size="sm" // Add size to set the button size
          > 
            <i class="bi bi-info-lg"></i>
          </Button>
        </Tooltip>
      </Box>


      <ModalToAdd
        isOpen={isOpen}
        closeModal={handleCloseModal}
        indexs={indexs}
      />

      {/* {isCallAPIopen && (
        <CallApi
          palletImagePath={palletImagePath}
          // flagDemo={flagDemo}
          // palletId={palletId}
          // palletName={palletName}
          resetData={handleResetData}
        />
      )} */}
      {isViewPallet && (
        <SelectedPalletInfo
          isViewPallet={isViewPallet}
          closeModal={handleCloseViewPallet}
        />
      )}
      {uploadData != null && (
        <UploadSegmentedImage
          uploadDatas={uploadData}
          resetData={handleReset}
        />
      )}
    </div>
  );
};

export default ShowEdit;

import React, { useState } from 'react';
import { Slider, SliderTrack,SliderMark, SliderFilledTrack, SliderThumb, Tooltip,Box } from '@chakra-ui/react';
import {hideShadow,unHideShadow,updateAlphaValue} from "../../../../slices/shadow/ShadowSlice"
import {useDispatch, useSelector} from "react-redux"
import "./SliderShadow.css";
const SliderShadow = ({shadowName}) => {
  const dispatch= useDispatch()
  const [ishide, setIsHide] = useState(true);
  const [sliderValue, setSliderValue] = useState(30);
  const [isHighlighted, setIsHighlighted] = useState(true);
  const [opacityValue , setOpacityValue] = useState(0.5);
  
 
  const handleSliderChange = (value) => {
    
    setOpacityValue(value/100);
    dispatch(updateAlphaValue({
      shadowName:shadowName,
    alphaValue:value/100
  }))
  
    // Do any other actions based on the slider value if needed
  };
  
  const labelStyles = {
    mt: '2',
    ml: '0px',
    fontSize: '13px',
    fontweight:"bold",
  }


 

  return (
    <div>
      <div className='shad'>
                  <div  className="shadow-btn"
                   style={{
                    background: isHighlighted ? 'black' : 'your-default-background-color',
                    opacity: isHighlighted ? opacityValue : 1,
                  }}

                  >
                    
                  </div>
                  </div>

      <Slider aria-label='slider-ex-6' className='slider-tract-section' defaultValue={30}  
       onChange={(val) => handleSliderChange(val)}
      >
         <SliderMark  value={0} {...labelStyles}>
            0
         </SliderMark>
        <SliderMark value={25} {...labelStyles}>
          0.25
        </SliderMark>
        <SliderMark value={50} {...labelStyles}>
          0.50
        </SliderMark>
        <SliderMark value={75} {...labelStyles}>
          0.75
        </SliderMark>
        <SliderMark value={100} {...labelStyles}>
          1
        </SliderMark>

        <SliderMark
          value={opacityValue}
          textAlign='center'
          bg='blue.500'
          color='white'
          mt='-10'
          ml='10px'
          w='12'
        >
          {opacityValue}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      </div>
    // </div>
  );
};

export default SliderShadow;

import React, { useEffect } from "react";
import { selectAllItems } from "../slices/MasterArraySlice";
import { useSelector } from "react-redux";
import "./ShowOverALLPalletInfo.css";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
const ShowOverALLPalletInfo = ({ overSwatchName, isOpen, onClose }) => {
  const getMasterArray = useSelector(selectAllItems);

  return (
    <div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="pallet-select-sagment-popup">
          <ModalCloseButton />
          <ModalBody pb={6}>
            <TableContainer>
              <Table variant="striped" colorScheme="teal">
                <Thead>
                  <Tr className="all-pallets">
                    <Th>Name</Th>
                    <Th>Brand</Th>
                    <Th>Style</Th>
                    <Th>Pallet</Th>
                    <Th>Pallet Name</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {overSwatchName &&
                    getMasterArray &&
                    getMasterArray.master &&
                    getMasterArray.master.length &&
                    getMasterArray.master.map(
                      (item) =>
                        item.name === overSwatchName &&
                        item.allSwatches &&
                        item.allSwatches.length > 0 &&
                        item.allSwatches.map((swatch) => (
                          <Tr className="pallets-select-names">
                            <Td className="pallets-select-item">
                              {item?.name}
                            </Td>
                            <Td className="pallets-select-item ">
                              {
                                swatch?.attributes?.attributes?.brand?.data
                                  ?.attributes.name
                              }
                            </Td>
                            {/* {swatch?.attributes?.attributes?.swatch_style?.data?.attributes?.name} */}
                            <Td className="pallets-select-item style-pallet-1">
                              {
                                swatch?.attributes?.attributes?.swatch_style
                                  ?.data?.attributes?.name
                              }
                            </Td>
                            <Td className="pallets-select-item">
                              {" "}
                              <img
                                src={
                                  process.env
                                    .REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                                  swatch?.attributes?.attributes?.image_s3
                                }
                                width={55}
                              ></img>
                            </Td>
                            <Tr className="pallet-name-table">
                              {swatch?.attributes?.attributes?.name}
                            </Tr>
                            <Tr className="pallet-name-table pallet-segemnt-buttons">
                            {/* <i class="bi bi-trash"></i> */}
                            <div className="pallet-segemnt-buttons">
                            <i class="bi bi-trash-fill"></i>
                            {/* Delete                            */}
                             </div>
                            <div className="pallet-segemnt-button-project">
                            <i class="bi bi-eye-fill"></i>
                            {/* <p>Hide/Show</p> */}
                            {/* <i class="bi bi-eye-slash-fill"></i> */}
                            </div>
                            {/* <i class="bi bi-eye"></i>
                            <i class="bi bi-eye-slash"></i> */}
                            </Tr>

                          </Tr>
                        ))
                    )}
                </Tbody>
              </Table>
            </TableContainer>
            <div className="pallet-select-table">
              <div className="size-pallet">
                <div className="pallet-size-box">
                  <h2 className="pallet-size-title">Pallet Size</h2>
                </div>

                <div className="pallet-size-text">
                  <h3>12 x 15 pt</h3>
                </div>
              </div>

              <div className="size-pallet">
                <div className="pallet-size-box">
                  <h2 className="pallet-size-title">Applied Area</h2>
                </div>

                <div className="pallet-size-text">
                  <h3>1300 sq ft</h3>
                </div>
              </div>

              <div className="size-pallet">
                <div className="pallet-size-box">
                  <h2 className="pallet-size-title"> Cost Calculation </h2>
                </div>

                <div className="pallet-size-text">
                  <h3>1300 x 12x15</h3>
                </div>
              </div>

              <div className="size-pallet Qty">
                <div className="pallet-size-box">
                  <h2 className="pallet-size-title"> Quantity</h2>
                </div>

                <div className="pallet-size-text">
                  <h3>1</h3>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ShowOverALLPalletInfo;

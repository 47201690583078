import { createSlice } from "@reduxjs/toolkit";
const ExistingUploadSlice = createSlice({
  name: "uploadExisting",
  initialState: {
    uploadExisting: [],
  },
  reducers: {
    addUploadExisting: (state, action) => {
      state.uploadExisting = [...action.payload];
    },
  },
});

export const { addUploadExisting } = ExistingUploadSlice.actions;
export const getExtingUploads = (state, action) => state.uploadExisting;
export default ExistingUploadSlice.reducer;

import React, { useState, useRef, useEffect } from "react";
import "./Gridview.css";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";

import ViewAction from "./ViewAction";
import ModalToAdd from "../Add_Edit_Pallet/ModalToAdd";
import CallApi from "../Add_Edit_Pallet/CallApi";
import { getCloseModal, closeModals } from "../slices/AlertSlice";
import UploadSegmentedImage from "../Add_Edit_Pallet/UploadSegmentedImage";
import {
  onHover,
  onAllHover,
  deleteSegment,
  samLoading,
  deleteLoading,
} from "../slices/AlertSlice";

import { addTabPannel } from "../slices/TabPannelSlice";
import {
  removeAllPalletImages,
  addSelectedPalletImag,
} from "../slices/TransparentPalletImgSlice";
import { addNewSelectedSwatch } from "../slices/SelectedSwatchImage";
import { addSelectedPallete } from "../slices/SelectedPalletdSlice";

import { compareProject, swatchDeleting } from "../slices/AlertSlice";
import ShowCurrentSwatch from "./ShowCurrentSwatch";
import SelectedPalletInfo from "../Tabs/SelectedPalletInfo";

import { addViewPalletInfo } from "../slices/ViewPalletInfoSlice";
import DeleteSegmentAlert from "../Tabs/DeleteSegmentAlert";
import { deleteMasterJob } from "../slices/MasterJobs";
import {
  selectAllItems,
  removeSegmentFromMAsterArray,
} from "../slices/MasterArraySlice";
import {
  selectAllSegments,
  removeSegmentFromSegments,
} from "../slices/AllSegmentSlice";

import DeleteSegment from "../Tabs/DeleteSegment";

import { getProjectId } from "../slices/ProjectSlice";

import UpdateDeletedSegmentToDb from "../Tabs/UpdateDeletedSegmentToDb";
import ProjectDetail from "./ProjectDetail";
import SegmentCheckBox from "./SegmentCheckBox";
import { addEditSegment ,reSetAnnotation} from "../slices/EditSegment/EditSegment";

import {
  removeGridMultiSelect,
  addGridMultiSelect,
  updateMultiSelect
} from "../slices/gridView/MultiSelectionSegement";
import MultiSelectPalletGrid from "./MultiSelectPalletGrid";
import {addCurrentPallete} from "../slices/CurrentSelectedPalleteSlice"

const Gridview = () => {
  const dispatch = useDispatch();

  const getMasterAray = useSelector(selectAllItems);
  const [isOpen, setIsOpen] = useState(false);
  const [indexs, setIndexs] = useState();
  const [isOpenDelete, setIsOpenDelete] = useState();
  const [isProjectDetails, setIsProjectDetails] = useState(false);
  // open modal to select swatch
  const handleSelectSwatch1=(labelName, index, selectedsegement)=>{
    let makeSegArray=[selectedsegement]
    handleSelectSwatch(labelName, index, makeSegArray)

  }
  const handleSelectSwatch = (labelName, index, selectedsegement) => {
    //
    setIsProjectDetails(true);
  
      dispatch(addSelectedPallete({ selectedPallete: selectedsegement }));
    

    dispatch(
      addNewSelectedSwatch({
        newSelectedSwatch: selectedsegement,
      })
    );
    setIsOpen(true);
    setIndexs(index);
   // console.log("gridPAge");
    dispatch(removeAllPalletImages());
    dispatch(
      addSelectedPalletImag({
        segmentName: labelName,
        selectedPlaletUrl: null,
        s3BucketImage: null,
        SwatchId: null,
      })
    );
    dispatch(
      addTabPannel({
        pannelTab: "Tab3",
      })
    );
  };

  // call API coponent
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch3"));
  const [palletImagePath, setpalletImagePath] = useState();
  const [flagDemo, setflagDemo] = useState();
  const [palletId, setpalletId] = useState();
  const [palletName, setpalletName] = useState();

  const [isopen, setIsopen] = useState(false);
  const [isCallAPIopen, setIsCallAPIopen] = useState(false);
  const isMounted = useRef(true);
  const uploadDatas = JSON.parse(sessionStorage.getItem("uploadData"));
  const [uploadData, setUploadData] = useState(null);
  const [isViewPallet, setIsViewPallet] = useState(false);

  useEffect(() => {
    
    if (
      handleSwatch &&
      handleSwatch?.palletImagePath &&
      handleSwatch?.flagDemo &&
      handleSwatch?.palletId &&
      handleSwatch?.palletName &&
      isMounted.current
    ) {
      //
      //console.log("ShowEdit called");
      setpalletImagePath(handleSwatch.palletImagePath);
      //console.log("grid view",handleSwatch.palletImagePath)

      setIsCallAPIopen(true);
      sessionStorage.setItem("handleSwatch", JSON.stringify(handleSwatch));
      sessionStorage.removeItem("handleSwatch3");
      isMounted.current = false;
    }
  }, [handleSwatch]);

  // reset data
  const handleResetData = (data) => {
    setUploadData(data);

    setpalletImagePath(null);
    setflagDemo(null);
    setpalletId(null);
    setpalletName(null);
    setIsCallAPIopen(false);
    isMounted.current = true;
  };

  // upload the datta

  const handleReset = () => {
    setUploadData(null);
  };
  // after selection swatch close the ModalToAdd
  const close_modal = useSelector(getCloseModal);
  useEffect(() => {
    if (close_modal) {
      handleCloseModal();

      dispatch(
        closeModals({
          closeModal: false,
        })
      );
    }
  }, [close_modal]);

  // close Modal
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  // on hover each segmentation
  const handleOnHoverSegment = (segmentName, polygon) => {
   
    dispatch(
      onHover({
        ishover: true,
        segmentName: segmentName,
        polygon: polygon,
      })
    );
  };

  // close hover on each segmentation
  const handleCloseHoverSegment = () => {
    dispatch(onHover({ ishover: false, segmentName: null }));
  };

  // display pallet Image on main View
  const handleImageSelectedPallet = (
    transplateImage,
    s3BucketImage,
    segmentName,
    swatchId
  ) => {
    
    dispatch(
      addSelectedPalletImag({
        segmentName: segmentName,
        selectedPlaletUrl: transplateImage,
        s3BucketImage: s3BucketImage,
        SwatchId: swatchId,
      })
    );
    dispatch(addCurrentPallete({
      segmentName: segmentName,
          s3BucketImage: s3BucketImage,
           swatchId: swatchId,
           palletImage:transplateImage
    }))

    dispatch(
      compareProject({
        isCompare: true,
      })
    );
  };
  // handle view Pallete
  const handleViewPallete = (segName, segSwatches) => {
    if (segSwatches.length > 0) {
      dispatch(
        addViewPalletInfo({
          palleteInfo: segSwatches,
          segName: segName,
        })
      );
      setIsViewPallet(true);
    } else {
      alert("No swatch is added");
    }
  };

  // close view pallete info
  const handleCloseViewPallet = () => {
    setIsViewPallet(false);
  };

  const [deleteLabelName, setDeleteLabelName] = useState();
  const [deleteSegName, setDeleteSegName] = useState();
  //delete segemntation (open delete Alert)
  const handleDeletSegment = (labelName, segName) => {
    setDeleteLabelName(labelName);
    setDeleteSegName(segName);

    setIsOpenDelete(true);
  };

  // handle close delete segment modal
  const handleDeleteCloseModal = () => {
    setIsOpenDelete(false);
  };
  const [isDelete, setIsDelete] = useState(false);
  //delete segemntation if yes
  const handleCloseModalSegmentDelete = () => {
    setIsOpenDelete(false);
    setIsDelete(true);
    dispatch(
      removeSegmentFromMAsterArray({
        segment: deleteSegName,
        label: deleteLabelName,
      })
    );

    dispatch(
      removeSegmentFromSegments({
        label: deleteLabelName,
      })
    );
    dispatch(
      deleteSegment({
        isDeleteSegment: true,
      })
    );
    dispatch(
      deleteMasterJob({
        labelName: deleteLabelName,
      })
    );
    dispatch(
      deleteLoading({
        isDeleteSegmentLoading: true,
      })
    );
  };
  // handle updated segment
  const [JobId, setJobIds] = useState();
  const [updatedJob, setUpdatedJob] = useState();
  const CurrentProjects = useSelector(getProjectId);
  const handleUpdatedSegment = (updatedSeg) => {
    setUpdatedJob(updatedSeg);
    setJobIds(CurrentProjects.jobId);

    setIsDelete(false);
  };

  // Hover all poygon
  const handleHoverAllLabels = (names) => {
    let allPolgon = [];
     let polyName=[]
    if (getMasterAray && getMasterAray?.master.length > 0) {
      getMasterAray.master.map((item) => {
        if (item.name === names) {
          item.segmentation.map((segment) => {
           let allPoly = segment.annotationPolygon
              .split(",")
              .map((value) => parseInt(value.trim(), 10));

              polyName.push(segment.name);
              allPolgon.push(allPoly)
          });
        }
      });
      dispatch(
        onAllHover({
          isAllHover: true,
          segmentName: names,
          allpolygon: allPolgon,
          allPolygonNames:polyName
        })
      );
    }
  };

  // remove all polygon'
  const handleRemoveAllHover = () => {
    dispatch(
      onAllHover({
        isAllHover: false,
        segmentName: null,
      })
    );
  };

  const handleEditSegmentation = (segName, segment) => {
   // ;
    dispatch(
      addEditSegment({
        editSegment: segment,
      })
    );
  };

  //handle tab
  const handleTab = (indexs) => {
    //
    dispatch(removeGridMultiSelect());
  
  };

  const [isChecked, setIsChecked] = useState(false);
  const [selectedAll, setAllSelected] = useState();
  const handleSelectAllSegment = (allSeg) => {
    
    dispatch(removeGridMultiSelect())
    dispatch(updateMultiSelect({ gridMultiSelect: allSeg }));
    setAllSelected(allSeg);
  };

  const handleUncheckedSelectAllSegment = () => {
    dispatch(removeGridMultiSelect());
    setAllSelected(null);
    setIsSelectPallet(false);
  };
  const [checkedItems, setCheckedItems] = useState([false, false]);
  const [checkBoxes, setCheckBoxed] = useState([]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [isSelectPallet, setIsSelectPallet] = useState(false);
  const handlecheckBox = (val, index) => {
    setCheckBoxed({ [index]: val });
    setIsSelectPallet(true);
  };

  // multi select pallet selection to open modal
  const handeOpenModalAfterSelect = (label, labelIndex, seg) => {
    handleSelectSwatch(label, labelIndex, seg);
  };

  //after change of tab checkboc get uncheckable
  const handelResetCheckbox=()=>{

  }

  // ReAnnotation
  const[isOpenReAnnote, setIsOpenReAnnotion]= useState(false)
  const handleReAnnotation=(labelName, masterIndex, segName, polygon)=>{
     dispatch(reSetAnnotation({
      labelName: labelName,
      masterIndex: masterIndex,
      isReAnnoatable:true,
      segName:segName,
      polygon:polygon
     }))
  }
  return (
    <>
      <div>
        {/* <div><Checkbox defaultChecked></Checkbox></div> */}
        <Tabs className="tablist-segments" variant="enclosed">
          <TabList className="labels-tablist">
            {getMasterAray &&
              getMasterAray.master &&
              getMasterAray.master.length > 0 &&
              getMasterAray.master.map(
                (item,) =>
                  item.segmentation &&
                  item.segmentation.length > 0 && (
                    <Tab
                      key={item.id}
                      className={`lables-item-title  dzi-${item.name}`}
                      value={item.name}
                      onMouseEnter={(event) => {
                        const targetValue = event.target.value;
                        handleHoverAllLabels(event.target.value, targetValue);
                      }}
                      onMouseLeave={handleRemoveAllHover}
                      onClick={handleTab }
                      onChange={handelResetCheckbox}
                    >
                      <img src={item.icon}
                      key={item.icon}
                     
                      ></img>
                      <p
                      key={item.name}
                      >{item.name}</p>
                    </Tab>
                  )
              )}
          </TabList>

          <TabPanels>
            {getMasterAray &&
              getMasterAray.master &&
              getMasterAray.master.length > 0 &&
              getMasterAray.master.map(
                (master, masterIndex) =>
                  master.segmentation &&
                  Array.isArray(master.segmentation) &&
                  master.segmentation.length > 0 && (
                    <TabPanel  key={master.id}>
                      <div className="checkbox-seg">
                      <Checkbox
                        // masterIndex={masterIndex}
                        // masterLabelName={master.name}
                        key="AllSelect"
                        className="all-checkbox"
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => {
                          setCheckedItems([e.target.checked, e.target.checked]);

                          if (e.target.checked) {
                            handleSelectAllSegment(master.segmentation);
                          } else {
                            handleUncheckedSelectAllSegment();
                          }
                        }}
                      >
                        {" "}
                        Select All
                      </Checkbox>
                   
                      {/* select pallet button fro multi select pallet */}
                      <MultiSelectPalletGrid
                        masterIndex={masterIndex}
                        masterLabelName={master.name}
                        openModal={handeOpenModalAfterSelect}
                      />
                       </div>
                      {master.segmentation.map((seg, index) => (
                        <div
                          key={seg.id}
                          className={`labels-section  dzi-${seg.name}`}
                          onMouseLeave={handleCloseHoverSegment}
                          onMouseEnter={() =>
                            handleOnHoverSegment(
                              seg.name,
                              seg.annotationPolygon
                            )
                          }
                        >
                          <div className="segment-images-tab">
                            {/* check Box
                             */}
                            <SegmentCheckBox
                              selectedSeg={seg}
                              checkBoxIndex={index}
                              onCheckboxChange={handlecheckBox}
                              selectedAll={selectedAll}
                            />
                            <div className="seg-grid-wrapper">
                            <ShowCurrentSwatch  labelName={seg.name} />
                            <div className="segments-title-name"> 
                            <h4 className="seg-title-names" key={seg.name}>
                              {seg.name}
                            </h4>                            
                            </div>
                            </div>

                          </div>
                          <div className="segments-array-pallet-images">
                            <div className="select-pallet-style sagment-pallet-select-images">
                              {seg.SelectSwatchJob &&
                              seg.SelectSwatchJob.length > 0
                                ? seg.SelectSwatchJob.map((swatch) => (
                                    <div
                                      className="arrea-swatch-pallet-img"
                                      key={swatch.id}
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        focusable="false"
                                        class="chakra-icon css-onkibi"
                                        aria-hidden="true"
                                      >
                                        <g fill="currentColor">
                                          <path d="M23.2,10.549a20.954,20.954,0,0,0-4.3-3.6l4-3.995a1,1,0,1,0-1.414-1.414l-.018.018a.737.737,0,0,1-.173.291l-19.5,19.5c-.008.007-.018.009-.026.017a1,1,0,0,0,1.631,1.088l4.146-4.146a11.26,11.26,0,0,0,4.31.939h.3c4.256,0,8.489-2.984,11.051-5.8A2.171,2.171,0,0,0,23.2,10.549ZM16.313,13.27a4.581,4.581,0,0,1-3,3.028,4.3,4.3,0,0,1-3.1-.19.253.253,0,0,1-.068-.407l5.56-5.559a.252.252,0,0,1,.407.067A4.3,4.3,0,0,1,16.313,13.27Z"></path>
                                          <path d="M7.615,13.4a.244.244,0,0,0,.061-.24A4.315,4.315,0,0,1,7.5,12,4.5,4.5,0,0,1,12,7.5a4.276,4.276,0,0,1,1.16.173.244.244,0,0,0,.24-.062l1.941-1.942a.254.254,0,0,0-.1-.421A10.413,10.413,0,0,0,12,4.75C7.7,4.692,3.4,7.7.813,10.549a2.15,2.15,0,0,0-.007,2.9,21.209,21.209,0,0,0,3.438,3.03.256.256,0,0,0,.326-.029Z"></path>
                                        </g>
                                      </svg>
                                      <img
                                        src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch.swatch?.data?.attributes.image_s3}`}
                                        width={55}
                                        alt={
                                          swatch.swatch?.data?.attributes.name
                                        }
                                        onClick={() =>
                                          handleImageSelectedPallet(
                                            swatch?.SegmentImage?.data
                                              ?.attributes?.url || "",
                                            swatch?.swatch?.data?.attributes
                                              ?.image_s3,
                                            seg?.name,
                                            swatch?.swatch?.data?.id
                                          )
                                        }
                                      />
                                    </div>
                                  ))
                                : //
                                  null}
                            </div>
                          </div>
                          <ViewAction
                            segName={seg.name}
                            selectSwatch={() =>
                              handleSelectSwatch1(seg.name, masterIndex, seg)
                            }
                            searchViewPallete={() =>
                              handleViewPallete(seg.name, seg.SelectSwatchJob)
                            }
                            deleteSegments={() =>
                              handleDeletSegment(seg.name, seg.SelectSwatchJob)
                            }
                            editSegmentation={() =>
                              handleEditSegmentation(seg.name, seg)
                            }
                            reAnnotate={()=>handleReAnnotation(seg.name, masterIndex, master.name, seg.annotationPolygon)}
                          />
                        </div>
                      ))}
                    </TabPanel>
                  )
              )}
          </TabPanels>
        </Tabs>
      </div>

      <ModalToAdd
        isOpen={isOpen}
        closeModal={handleCloseModal}
        indexs={indexs}
      />

      {/* {isCallAPIopen && (
        <CallApi
          palletImagePath={palletImagePath}
          // flagDemo={flagDemo}
          // palletId={palletId}
          // palletName={palletName}
          resetData={handleResetData}
        />
      )} */}
      {uploadData != null && (
        <UploadSegmentedImage
          uploadDatas={uploadData}
          resetData={handleReset}
        />
      )}
      {isViewPallet && (
        <SelectedPalletInfo
          isViewPallet={isViewPallet}
          closeModal={handleCloseViewPallet}
        />
      )}
      {/* Alert to delete the segment */}
      {isOpenDelete && (
        <DeleteSegmentAlert
          isOpen={isOpenDelete}
          onClose={handleDeleteCloseModal}
          deleteSegment={handleCloseModalSegmentDelete}
        />
      )}
      {isDelete && <DeleteSegment deleteSegment={handleUpdatedSegment} />}
      {updatedJob && (
        <UpdateDeletedSegmentToDb updatedJob={updatedJob} JobId={JobId} />
      )}
      {isProjectDetails && <ProjectDetail />}

    
    </>
  );
};

export default Gridview;

import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getGridMultiSelect } from "../slices/gridView/MultiSelectionSegement";
import {removeGridMultiSelect} from "../slices/gridView/MultiSelectionSegement"
const MultiSelectPalletGrid = ({ openModal, masterIndex, masterLabelName }) => {
  const dispatch = useDispatch();
  const getSelectedPallet = useSelector(getGridMultiSelect);

  const [isSelectPAllet, setIsSelectPallet] = useState(false);
  const [labelName, setLabelName] = useState();
  // show and hide button
  const [segments, setSegments] = useState();
  useEffect(() => {
    //console.log(getSelectedPallet.gridMultiSelect);
    if (
      getSelectedPallet &&
      getSelectedPallet.gridMultiSelect &&
      getSelectedPallet.gridMultiSelect.length > 0
    ) {
      setIsSelectPallet(true);
      setSegments(getSelectedPallet.gridMultiSelect);
     // dispatch(removeGridMultiSelect())
    } else {
      setIsSelectPallet(false);
      setSegments(null);
    }
   
  }, [getSelectedPallet]);
  const handleMultiSelectPallet = () => {
    openModal(masterLabelName, masterIndex, segments);
  };
  return (
    <div>
      {isSelectPAllet && (
        <Button
          className="select-pallets-btn"
          onClick={handleMultiSelectPallet}
        >
          Select Pallet
        </Button>
      )}
    </div>
  );
};

export default MultiSelectPalletGrid;

import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { fabric } from "fabric";
import {getRenderCombination,GetCombinationSwatch} from "../../slices/combinationSwatch/CombinationSwatchSlice"
import {getCanvasSize} from "../../slices/windowSizeSlice/WindowResolution.js"
import {getProjectId} from "../../slices/ProjectSlice"
import "./CombinationLayer.css";
const CombinationLayer = () => {
    const dispatch= useDispatch()
    const canvasRef = useRef(null);
    const getCanvasSizes= useSelector(getCanvasSize)
    
    useEffect(() => {
      if(getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null

        ){

          var canvas = new fabric.Canvas("combination-layer", {
            selectionBorderColor: 'green',
            width: getCanvasSizes.canvasWidth, // Set canvas width
              height:getCanvasSizes?.canvasHeight 
          });
        
          // setCanvas(canvas); // Remove this line
          canvasRef.current = canvas; 
          // const imgGroup = new fabric.Group([], { selectable: false, name: 'imageGroup',visible: true });
          // canvasRef.current.add(imgGroup);
        }

    
       }, [getCanvasSizes]); 
// set BackGround Image 
const getProjectImage=useSelector(getProjectId);
useEffect(()=>{
    //
      if(getProjectImage&&
          getProjectImage.base64Image&&
          canvasRef.current){

            let image = getProjectImage?.base64Image;
      const encodedImage = "data:image/png;base64," + image;
      // Decode the base64 image
      const decodedImage = atob(encodedImage.split(",")[1]);
      // Convert the decoded data to a Uint8Array
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }
      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "image/png" });
      // Create an object URL from the Blob
      let imageUrl = URL.createObjectURL(blob);
  
      var img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {}
//  let imageUrl=getProjectImage?.projectImage
fabric.Image.fromURL(imageUrl, function (img) {
  canvasRef.current.setBackgroundImage(imageUrl, canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
  
    width:canvasRef.current.width,
    height:canvasRef.current.height,
    originX: "left",
    originY: "top",
  
    });
   
    
  
  canvasRef.current.requestRenderAll();
    
})

          }

   
   },[canvasRef,getProjectImage]);

    // render All Combo 
    const getAllCombo= useSelector(GetCombinationSwatch)
  
    useEffect(()=>{
      
      if(canvasRef.current &&
        getAllCombo &&
        getAllCombo.combinationSwatch&&
        getAllCombo.combinationSwatch.length>0
        ){ 
          getAllCombo.combinationSwatch.map(item=>{
            const imageGroup = new fabric.Group([], {
              name:item.attributes.Name,
              selectable: false,
              lockMovementX: true,
              lockMovementY: true,
              hasRotatingPoint: false,
              hasBorders: false,
              hasControls: false,
              visible:false,
            }); 
            if(item.attributes.Swatches&&
              item.attributes.Swatches.length>0){
              item.attributes.Swatches.map(swatch=>{
                let url=swatch.SegmentImagePath;
                fabric.Image.fromURL(url, function(img){
                  var oImg = img.set({ left: 0, top: 0}).scale(1);
                  oImg.set({
                      name:swatch.SwatchFor,
                      lockMovementX:true,
                       lockMovementY:true,
                       hasRotatingPoint:false,
                       hasBorders:false,
                       hasControls:false,
                     
                  })
                  imageGroup.addWithUpdate(oImg);
                  // canvasRef.current.add(oImg);
                })
              })
            }
            canvasRef.current.add(imageGroup);
                  canvasRef.current.requestRenderAllBound();

          })


        }
    },[canvasRef,getAllCombo]);

       const getRenderCombinations= useSelector(getRenderCombination)
      

     useEffect(()=>{
        if(getRenderCombinations &&
            getRenderCombinations.renderCombination&&
            getRenderCombinations.isRenderComninationStart &&
            canvasRef.current
          
            ){
           let allobjects = canvasRef.current.getObjects();
               if(allobjects.length>0){
                 allobjects.map(item=>{
                   if(item.name===getRenderCombinations.renderCombination.attributes.Name){
                    item.set('visible', true);
                   }else{
                    item.set('visible', false);
                   }
                   canvasRef.current.requestRenderAllBound();
                 })
               }
            }
     },[ canvasRef,getRenderCombinations])

  

     

  return (
    <div>

<div className="ai-right-wrapper-body Cursor project-layer-image combi-layer-sec">
   <h3 className='combo-layer-title'>Combination layer</h3>
   <canvas  id="combination-layer" className="dzi-van"  width="1023" height="592"  ref={canvasRef}></canvas>
   </div>
</div>
  )
}

export default CombinationLayer
import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import {
    Button,
    FormLabel,
    HStack,
    Input,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { closeThresold, addThreshold } from "../../../slices/threshold/ThresholdSlice";
import {selectAllSegments} from "../../../slices/AllSegmentSlice"
import axios from 'axios';
import {updatePolygonAnnotation} from "../../../slices/AllSegmentSlice"
import {updateMasterPolygonAnnotation} from "../../../slices/MasterArraySlice"
import {updateJobArray} from "../../../slices/MasterJobs"
import {palletLoading} from "../../../slices/AlertSlice"

const Threshold = ({isOpen}) => {
    const dispatch = useDispatch();

    // State variables to store threshold values
    const [distValue, setDistValue] = useState(1.5);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
     const selectAllSegment= useSelector(selectAllSegments)
     const [segmentationint, setsegmentationInt]= useState([])
    useEffect(()=>{
        console.log("selectAllSegment",selectAllSegment)
        if(selectAllSegment &&
            selectAllSegment.allSegmentsArray &&
            selectAllSegment.allSegmentsArray.length > 0){
                const mergedObject = selectAllSegment.allSegmentsArray.reduce((accumulator, item) => {
                    if (typeof item.annotationPolygon === "string") {
                        const arrayOfNumbers = item.annotationPolygon.split(",").map((str) => parseInt(str, 10));
                        accumulator[item.name] = arrayOfNumbers;
                    }
                    return accumulator;
                }, {});
        
                console.log("mergedObject", mergedObject);
                
                setsegmentationInt(mergedObject)
        }
    },[selectAllSegment])

    // Event handlers to update state when input values change
    const handleDistChange = (e) => {
         if(e.target.value<=10)
        setDistValue(parseFloat(e.target.value).toFixed(1))};
    const handleMinChange = (e) => setMinValue(parseFloat(e.target.value).toFixed(1));
    const handleMaxChange = (e) => setMaxValue(parseFloat(e.target.value).toFixed(1));


    // Function to handle form submission
    const handleSubmitThreshold = () => {
        
           dispatch(palletLoading({
            palletLoading: true,
           }))
        const data={
            segmentationInt:segmentationint,
            threshold_dist:parseFloat(distValue),
            threshold_min:parseFloat(minValue),
            threshold_max:parseFloat(maxValue),
    
        }
        thresholdApi(data)
    };

    const thresholdApi=async(data)=>{

        try{
            const response= await axios.post("https://api.dzinly.org/line-overlap", data);
            console.log("response",response)
            if(response.status===200){
                updatePolygon(response.data)
               
            }
           
        }
        catch(e){
            console.log("Error")
            handleCancel()
            dispatch(palletLoading({
                palletLoading: false,
               }))
        }
    }

    // Function to handle cancel action
    const handleCancel = () => {
        dispatch(closeThresold());
    };

   const updatePolygon =(response)=>{

    for (const key in response) {
        if (Object.hasOwnProperty.call(response, key)) {
          const value = response[key];
          console.log(`${key}: ${value}`);
          // update all segments
          dispatch( updatePolygonAnnotation({
            labelName:key,
              annotationPolygon:value.toString()
          }))

          // upadte masterArrays

          dispatch(updateMasterPolygonAnnotation({
            labelName:key,
            annotationPolygon:value.toString()
          }))

          // update masterJob
           dispatch(updateJobArray({
            labelName:key,
            annotationPolygon:value.toString()
           }))

        }
      
    }
    dispatch(palletLoading({
        palletLoading: false,
       }))
    handleCancel();

    

   }

    return (
        <div style={{ marginLeft: 800 }}>
            <div>
            <Modal isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent className='model-content'>
          <ModalHeader className='chakra-model-title'>Void Fillup</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ margin:"0 auto", marginTop:"14px"   }}>
          <div >
                    <FormLabel className='select-height'>Threshold_Dist</FormLabel>
                    <HStack maxW='300px'>
                    <Button
                         className='numberbutton'
                        onClick={() => {
                            
                            const val =(parseFloat(distValue) - 0.1).toFixed(2);
                        setDistValue(val)}}>-</Button>
                  
                        <Input
                       className='Dist-value-input'
                            type="number"
                            value={distValue}
                            onChange={(e)=>{
                                const val = parseFloat(e.target.value);
                                if (!isNaN(val)) { // Check if the parsed value is a valid number
                                    setDistValue(val);
                            }
                                
                                }}
                        />
                          <Button 
                   className='numberbutton'
                    onClick={() => { 
                         const val =(parseFloat(distValue) + 0.1).toFixed(2);
                        setDistValue(val) }}>+</Button>
                      
                    </HStack>
                </div>
                <div style={{ marginRight: '20px' }}>
                    <FormLabel className='select-height'>Threshold_Max</FormLabel>
                    <HStack maxW='300px'>
                    <Button 
                     className='numberbutton'
                    onClick={() => { 
                        const val =(parseFloat(maxValue) -0.1).toFixed(2);
                        setMaxValue(val)
                       
                         }}>-</Button>
                        <Input
                        className='max-value-input'
                            type="number"
                            value={maxValue}
                            onChange={(e)=>{
                                const val = parseFloat(e.target.value);
                                if (!isNaN(val)) { // Check if the parsed value is a valid number
                                    setMaxValue(val);
                            }
                            }}
                        />
                           <Button 
                    className='numberbutton'
                    onClick={() => { 
                        const val =(parseFloat(maxValue) + 0.1).toFixed(2);
                        setMaxValue(val)
                       
                        }}>+</Button>
                       
                    </HStack>
                </div>
                <div>
                    <FormLabel className='select-height'>Threshold_Min</FormLabel>
                    <HStack maxW='300px'>
                    <Button 
                      className='numberbutton'
                    onClick={() => { 
                        const val =(parseFloat(minValue) -0.1).toFixed(2);
                        setMinValue(val)
                         }}>-</Button>
                        <Input
                          className='min-value-input'
                            type="number"
                            value={minValue}
                            onChange={(e)=>{
                                const val = parseFloat(e.target.value);
                                if (!isNaN(val)) { // Check if the parsed value is a valid number
                                    setMinValue(val);
                            }
                            }}
                        />
                            <Button 
                   
                   className='numberbutton'
                 onClick={() => { 
                     const val =(parseFloat(minValue) +0.1).toFixed(2);
                     setMinValue(val)
                     
                      }}>+</Button>
                       
                    </HStack>
                </div>
                
           
          </ModalBody>

          <ModalFooter className='footer-btn'>
          <Button onClick={handleCancel} >Cancel</Button>
          <Button onClick={handleSubmitThreshold} >Submit</Button>
               
            
          </ModalFooter>
        </ModalContent>
      </Modal>
               
            </div>
        </div>
    );
};

export default Threshold;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_CUSTOMERVIEWJOB } from "../../GraphQL/CustomerViewGraphQL";
import { useQuery,useApolloClient } from "@apollo/client";
import { palletLoading} from "../../slices/AlertSlice";
import {selectAllItems, addMasterArray,addCustomerViewMasterArray} from "../../slices/MasterArraySlice"
import {addProjectId } from "../../slices/ProjectSlice"
import {addCombinationSwatch} from "../../slices/combinationSwatch/CombinationSwatchSlice"
const GetCustomerJobs = ({jobId,resetData}) => {
    const dispatch = useDispatch();
    const [job_id, setJob_id] = useState();
    useEffect(() => {
        if (jobId!=null) {
          setJob_id(jobId);
       ;
        }
      }, [jobId]);

      const { loading, error,data,refetch } = useQuery(GET_CUSTOMERVIEWJOB, {
        variables: {
          jobId: job_id,
        },
        fetchPolicy: 'network-only',
      });


      useEffect(() => {
        if (data && !loading) {
          
          console.log(data?.jobs)
          if(data?.jobs?.data[0].attributes.JobJson &&
            data?.jobs?.data[0].attributes.project.data.attributes.ImageBase64){
            dispatch(addCustomerViewMasterArray(data?.jobs?.data[0].attributes.JobJson));

            dispatch(addProjectId({
                projectId: data?.jobs?.data[0].attributes.project.data.id,
                projectName: data?.jobs?.data[0].attributes.project.data.attributes.name,
                jobId: data?.jobs?.data[0].id,
                base64Image:data?.jobs?.data[0].attributes.project.data.attributes.ImageBase64 ,
              
            }))
            
            console.log("combination",data?.jobs?.data[0].attributes.project?.data.attributes?.combinations?.data)
            dispatch(addCombinationSwatch(
              {combinationSwatch:data?.jobs?.data[0].attributes.project?.data.attributes?.combinations?.data}
           ))
          }
          
          
          dispatch(palletLoading({
            palletLoading: false
        }))
        resetData()
        }else{
           
        }
      }, [data, loading]);
  return (
    <div>


    </div>
  )
}

export default GetCustomerJobs
import React, { useState, useRef, useEffect } from "react";
import "../../NewDesign/leftsection/Leftsection.css";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Button } from "@chakra-ui/react";
import { Tooltip } from '@chakra-ui/react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Portal,
  } from '@chakra-ui/react'
  import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
  } from "@chakra-ui/react";
  import { useSelector, useDispatch } from "react-redux";
  import {
    selectAllItems,
    removeSegmentFromMAsterArray,
  } from "../../slices/MasterArraySlice";
  import CustomerEachSegment from "./CustomerEachSegment.js"
  import {getCustomerMaster,resetDataSwatchSelected} from "../../slices/CustomerSlice/customerViewpalletSlice.js"
  const CustomerLeft = () => {
    const dispatch = useDispatch();
   const getMasterArays = useSelector(selectAllItems);
    //const getMasterArays = useSelector(getCustomerMaster);
    //console.log("getMasterArays",getMasterArays)

   
  return (

    <>
   

     <Tabs
      className="product-layer"
      variant="soft-rounded"
      align="left"
      orientation="vertical">
     
     <div className="sidebar-section">
          <div className="sidebar-hide-icon">
            <i class="bi bi-text-indent-right"></i>
            {/* <i class="bi bi-text-indent-left"></i> */}
          </div>

          <TabList className="seg-tab tab-labels">

            {
              getMasterArays &&
              getMasterArays.master.length > 0 &&
              getMasterArays.master.map(item => {
                return (item.segmentation &&
                  item.segmentation.length > 0 && (
                    <Tab 
                    className={`segments-grid dzi-${item.name}`}
                    //   onMouseOver={(event) => {
                    //     const targetValue = event.target.value;
                    //     handleHoverAllLabels(event.target.value, targetValue);
                    //   }}
                    //   onMouseLeave={handleRemoveAllHover}
                    //   onClick={handlePannel}
                    //   value={item.name}
                    >
                      <img className="seg-image" src={item.icon}></img>
                      <div className="label-title-outer seg-window-title">
                        <h2 className="label-title" key={item.name}>{item.name}</h2>
                      </div>
                    </Tab>
                    
                    ) 
                 
                  )

              })
            }



          </TabList>
        </div>
        <TabPanels className="seg-contant-tabpanel">


                    {
                    getMasterArays &&
                    getMasterArays.master.length > 0 &&
                    getMasterArays.master.map(
                        (master, masterIndex) => {
                        return (
                            master.segmentation &&
                            Array.isArray(master.segmentation) &&
                            master.segmentation.length > 0 &&

                            (

                            (<TabPanel>
                                <>
                            {/* onClick={handleshowCombo} */}
{/* 
                                <div className=" select-checkbox-seg" key="media">

                        <div className="project-image-select-btn">

                    <div className={`select-pallets-button combo-icon`}
                     key="combo">
                    <Tooltip hasArrow label='Combo' placement='bottom' bg='gray.300' color='black'>
                    <i class="bi bi-back"></i>
                  </Tooltip>
                    </div>
                    </div>
                              </div> */}



                    <div className="select-pallets-button"
                    >
                    {/* <SelectPallet
                    openModal={selectSeg}
                    /> */}
                    </div>



                            
                                    <div key="main-tab">
                                    <div>
                                        <CustomerEachSegment
                                        uniqueSwatch={master.allSwatches}
                                        masterSeg={master.segmentation}
                                        masterIndex={masterIndex}
                                        masterName={master.name}
                                        // openSelectSwatch={handleDynamicSegmentName}
                                        // openDeleteModal={handleOpenDeleteModal}
                                        // openViewPallet={handleViewPallet}
                                        // noViewPallet={handleNoViewPallet}
                                        />
                                    </div>
                                    </div>
                            
                                


                               


                  
                            {/* show all combination combination  */}

                            {/* {showCombo&&
                            <TabPanel>
                            <SelectCombo/>
                            </TabPanel>
                            } 
                             */}
                           
                        
                                </>
                            </TabPanel>)

                            )
                        ) }
                        )}

                         


      </TabPanels>

     </Tabs>
    </>
   
  )
}

export default CustomerLeft
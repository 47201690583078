import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

const DeleteSegmentAlert = ({ isOpen, onClose, deleteSegment }) => {
  const handleCloseModal = () => {
    onClose();
  };

  
  const handleDelete = () => {
    deleteSegment();
  };
  return (
    <div>
      <Modal closeOnOverlayClick={false} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="delete-segment-popup">
          <ModalHeader></ModalHeader>
          <ModalCloseButton onClick={handleCloseModal} />
          <ModalBody >
            <Text fontweight="bold"  style={{fontSize:"16px", fontFamily:" Poppins, sans-serif", fontWeight:"500"}}>
              Are you sure you want to delete segment?
            </Text>
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter className="popup-select-segment">
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              No
            </Button>
            <Button variant="ghost" onClick={handleDelete}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DeleteSegmentAlert;

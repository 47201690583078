import React ,{useState, useEffect}from 'react'

const ShowPalleteCheck = ({selectedSegmentData,allswatch}) => {

const[isselected,setIsSelected]= useState(false)
const[selectedSwatchId,setSelectedSwatchId]= useState(null)
  useEffect(()=>{
    if(selectedSegmentData &&
      selectedSegmentData.SelectSwatchJob&&
      
      selectedSegmentData.SelectSwatchJob.length > 0 ){
        selectedSegmentData.SelectSwatchJob.map((segItem) => {

          let segSwatchId;
            let swatchId = allswatch.id.toString();
            if(typeof segItem.swatch.data.id!="string"){
              segSwatchId=segItem.swatch.data.id.toString();
           }else{
             segSwatchId=segItem.swatch.data.id
           }

           if (segItem.swatch && segItem.swatch.data && segItem.swatch.data.id&&segSwatchId === swatchId) {
            setIsSelected(true);
            setSelectedSwatchId(segItem.swatch.data.id);
            
            
           }
        })

      
    }
  },[selectedSegmentData,allswatch])

  // return (
  //   <div>
  //     {selectedSegmentData &&
  //     selectedSegmentData.SelectSwatchJob&&
      
  //     selectedSegmentData.SelectSwatchJob.length > 0 &&
  //     selectedSegmentData.SelectSwatchJob.map((segItem) => 
  //       {
  //         let segSwatchId;
  //           let swatchId = allswatch.id.toString();
  //           if(typeof segItem.swatch.data.id!="string"){
  //              segSwatchId=segItem.swatch.data.id.toString();
  //           }else{
  //             segSwatchId=segItem.swatch.data.id
  //           }
  //           if (segItem.swatch && segItem.swatch.data && segItem.swatch.data.id&&segSwatchId === swatchId) {
  //               return (
  //                   <div className='pallet-select-checkbox'>
  //                       <i className="bi bi-check-circle-fill"></i>
  //                   </div>
  //               );
  //           }
  //           return null;
       
  //       })
  //     }
        
  //   </div>
  // )

  return(
    <>
    {isselected &&
     <div className='pallet-select-checkbox'>
                           <i className="bi bi-check-circle-fill"></i>
                        </div>
    }
    </>
  )
}

export default ShowPalleteCheck

                                                         
import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addSegementFolderData } from "../slices/FolderSlice";
const CreateSegmentFolder = ({ paretFolderId, segName, resetData2 }) => {
  const ismounted = useRef(true);
  const dispatch = useDispatch();
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (paretFolderId && segName && ismounted.current && token) {
      createSegFolder(paretFolderId, segName);
      return () => {
        ismounted.current = false;
      };
    }
  }, [paretFolderId, segName,token]);
  const createSegFolder = async (folderId, segmentName) => {
    // ;
    let data = {
      name: segmentName,
      parent: folderId,
    };
    const authToken = "Bearer " + token;
    try {
      const response = await axios.post(
        "https://aiadmin.dzinly.org/upload/folders/",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );
      if (response.status === 200) {
       // console.log("created Segment folder", response);
        ismounted.current = true;
        dispatch(
          addSegementFolderData({
            segmentFolder: response?.data?.data,
          })
        );
        resetData2(response?.data?.data?.id);
      }
    } catch (e) {
      ismounted.current = true;
    }
  };
  return <div></div>;
};

export default CreateSegmentFolder;

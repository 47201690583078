import React, { useState, useEffect, useRef } from "react";
import { getAddedPallet } from "../slices/Label_Brand_swatch_Slice";
import { getAllLabels } from "../slices/Label_Brand_Swatch";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import "./Swatches.css";
import { compareProject } from "../slices/AlertSlice";

import { palletLoading, closeModals } from "../slices/AlertSlice";

import { getSelectedSwatchImage } from "../slices/SelectedSwatchImage";
import { addSelectedPalletImag } from "../slices/TransparentPalletImgSlice";
import {
  addShowSwatch,
  getAllShowSwatches,
} from "../slices/SelectedSwatchImage";
import { stopLoadingBrandStyles } from "../slices/LoadingSlice";
import { getTabPannel } from "../slices/TabPannelSlice";
const Swatches = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const getSwatchStyles = useSelector(getAddedPallet);
  const getLabel_brand = useSelector(getAllLabels);
  const [swatches, setSwatches] = useState([]);
  const ShowSwatchToSelect = useSelector(getAllShowSwatches);
  const ismOunted2 = useRef(true);
  const getTabPannels = useSelector(getTabPannel);
  useEffect(() => {
    if (getSwatchStyles.isSwatchStyle && ismOunted2.current) {
      ismOunted2.current = false;
      setIsLoading(false);
      if (getLabel_brand && getLabel_brand.allArrays.length > 0) {
        getLabel_brand.allArrays.map((item) => {
          if (item.id === getSwatchStyles.labelId) {
            let swatchStyle = item.attributes.swatches.data;
            if (item.attributes.swatches.data.length > 0) {
              const initializedSwatches = swatchStyle.map((swatch) => ({
                ...swatch,
                selected: false, // Set the default value, you can adjust this as needed
              }));
              setSwatches(initializedSwatches);
              // dispatch(
              //   addShowSwatch({
              //     showSwactches: initializedSwatches,
              //   })
              // );
              setIsLoading(true);
            } else {
              setSwatches([]);
              setIsLoading(true);
            }
          }
        });
      }
    }
  }, [getSwatchStyles.isSwatchStyle]);

  // handle swatches
  const [uploadData, setUploadData] = useState();
  const handleSwatch = (
    palletImagePath,
    flagDemo,
    palletId,
    palletName,
    isCheckBoxSelected
  ) => {
   // ;
    ismOunted2.current = true;
    isMounted.current = true;
    if (isCheckBoxSelected) {
      dispatch(
        addSelectedPalletImag({
          segmentName: segName,
          selectedPlaletUrl: selectedSwatchUrl,
          s3BucketImage: palletImagePath,
          SwatchId: palletId,
        })
      );
    } else {
      let data = {
        palletImagePath: palletImagePath,
        flagDemo: flagDemo,
        palletId: palletId,
        palletName: palletName,
      };
      dispatch(palletLoading({ palletLoading: true }));
      // console.log("swatch page");
      // sessionStorage.removeItem("handleSwatch");
      if (getTabPannels === "Tab1") {
        sessionStorage.setItem("handleSwatch1", JSON.stringify(data));
      } else if (getTabPannels === "Tab2") {
        sessionStorage.setItem("handleSwatch2", JSON.stringify(data));
      } else if (getTabPannels === "Tab3") {
        sessionStorage.setItem("handleSwatch3", JSON.stringify(data));
      } else if (getTabPannels === "Tab4") {
        sessionStorage.setItem("handleSwatch4", JSON.stringify(data));
      }
    }

    dispatch(closeModals({ closeModal: true }));

    dispatch(
      compareProject({
        isCompare: true,
      })
    );

    dispatch(
      stopLoadingBrandStyles({
        isLoadingBrandStyles: true,
      })
    );
  };

  // function apply checkBox
  const getAllSelectedSwatchs = useSelector(getSelectedSwatchImage);
  const [selectedSwatchUrl, setSelectedSwatchUrl] = useState();
  const [segName, setSegName] = useState();
  const isMounted = useRef(true);
  useEffect(() => {
    if (
      getAllSelectedSwatchs &&
      getAllSelectedSwatchs.newSelectedSwatch &&
      getAllSelectedSwatchs.newSelectedSwatch.SelectSwatchJob &&
      getAllSelectedSwatchs.newSelectedSwatch.SelectSwatchJob.length > 0 &&
      isMounted.current
    ) {
      isMounted.current = false;
      const updatedSwatches = swatches.map((swatch) => {
        let isSelected = false;
        let selectedSwatch = null;

        getAllSelectedSwatchs.newSelectedSwatch.SelectSwatchJob.some((item) => {
          if (swatch.id === item?.swatch?.data?.id) {
            isSelected = true;
            selectedSwatch = item?.SegmentImage?.data?.attributes?.url;
            setSelectedSwatchUrl(item?.SegmentImage?.data?.attributes?.url);
            setSegName(getAllSelectedSwatchs?.newSelectedSwatch.name);
          }
        });

        return {
          ...swatch,
          selected: isSelected,
          selectedSwatchUrl: selectedSwatch,
        };
      });

      setSwatches(updatedSwatches);

      // Dispatch the updated swatches to Redux
      dispatch(addShowSwatch({ showSwactches: updatedSwatches }));
    } else {
      dispatch(addShowSwatch({ showSwactches: swatches }));
      isMounted.current = false;
    }
  }, [swatches]);

  return (
    <>
      {isLoading ? (
        ShowSwatchToSelect &&
        ShowSwatchToSelect?.length > 0 &&
        ShowSwatchToSelect.map((items, index) => (
          <div key={items.id} className="pallet-box">
            <div className="pallet-box-style">
              {/* checkbox images start*/}

              {items.selected && (
                // <
                //   Checkbox
                //   isChecked={items.selected}
                //   className="checkbox-pallet-select"
                // ></Checkbox>
                <i class="bi bi-check"></i>
              )}
              {/* checkbox images end */}

              {items.attributes.isRecommended ? (
                <img
                  className="brand-images-pallet is-domo-class"
                  src={
                    process.env.REACT_APP_S3_DZINLYLIVE_PATH_DEMO +
                    items.attributes.image_s3
                  }
                  alt="something"
                  key={items.id}
                  width="70px"
                  style={{ margin: 5 }}
                  onClick={() =>
                    handleSwatch(
                      items.attributes.image_s3,
                      1,
                      items.id,
                      items.attributes.name,
                      items.selected
                    )
                  }
                />
              ) : (
                <img
                  className="brand-images-pallet"
                  src={
                    process.env.REACT_APP_S3_DZINLYLIVE_PATH +
                    items.attributes.image_s3
                  }
                  alt="something1233"
                  key={items.id}
                  width="70px"
                  style={{ margin: 5 }}
                  onClick={() =>
                    handleSwatch(
                      items.attributes.image_s3,
                      0,
                      items.id,
                      items.attributes.name
                    )
                  }
                />
              )}
              <h6>{items.attributes.name}</h6>
            </div>
          </div>
        ))
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
    </>
  );
};

export default Swatches;

import React, { useEffect, useState, useRef } from "react";
import { getCustomer, loginSuccess, logoutSuccess } from "../login/AuthSlice";
import pImage from "../../assets/images/demo/home_images/master1.png";
import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import DesignerJob from "../Designer/DesignerJobs";
import ShowJobList from "../Designer/ShowJobList";
import "./ShowJobList.css";
const DesignerProfile = () => {
  const CustomerDetail = useSelector(getCustomer);

  const handleFindJob = () => {
    window.open("/findjob", "_blank");
  };
  return (
    <div>
      <div className="createproject-pages create-project-page ">
        <div className="Container">
          <div className="crete-project-page crete-project-designer-page">
            {CustomerDetail.customerName ? (
              <h1> Welcome  {CustomerDetail.customerName}</h1>
            ) : (
              ""
            )}
          </div>

          <div
            className="create-project conatiner create-project-designer-page"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="card create-project-images"
             onClick={handleFindJob} >
              {/* <i class="bi bi-house-add-fill"></i> */}
              <i class="bi bi-search"></i>
              <div className="card-content">
                <Button
                  onClick={handleFindJob}
                  variant="solid"
                  colorScheme="green"
                  size="md"
                  borderRadius="md"
                >
                  <span className="create-project-icon">Find Jobs</span>
                </Button>
              </div>
            </div>

            {/* project List */}
            {!CustomerDetail.isAuthenticated ? (
              <div className="card one">
                <img src={pImage} alt="Image 1" />
                <div className="card-content">
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    size="md"
                    borderRadius="md"
                  >
                    <span
                      className="create-project-icon1"
                      //   onClick={handleListOfProject}
                    >
                      Job List
                    </span>
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            ) : (
              <>
                <DesignerJob designerId={CustomerDetail.customerId} />
                <ShowJobList />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignerProfile;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectId: null,
  projectName: null,
  jobId: null,
  reload: false,
  base64Image: null,
  image: null,
  imageId: null,
  projectImage:null,
  isProjectCreated:false,
  isStartQuery:false,
};

const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    addProjectId: (state, action) => {
      // ;
      state.projectId = action.payload.projectId;
      state.projectName = action.payload.projectName;
      state.jobId = action.payload.jobId;
      state.reload = action.payload.reload;
      state.base64Image = action.payload.base64Image;
      state.projectImage = action.payload.projectImage;
    },
    removeProjectId: (state) => {
      // ;
      state.projectId = null;
      state.projectName = null;
      state.jobId = null;
      state.reload = false;
      state.base64Image = null;
      state.image = null;
      state.imageId = null; // Reset projectId to its initial state
      state.projectImage=null
    },

    addProjectImage: (state, action) => {
      state.image = action.payload.image;
    },
    addProjectImageId: (state, action) => {
      state.imageId = action.payload.imageId;
    },
    addProjectImageURL: (state, action) => {
      state.projectImage = action.payload.projectImage;
    }
    ,projectCreated:(state, action) => {
      state.isProjectCreated = action.payload.isProjectCreated
    }, 
    reloadProject:(state, action) => {
      state.reload = action.payload.reload
    },
    startJobQuery:(state,action) => {
      state.isStartQuery = action.payload.isStartQuery
    }
  },
});

export const {
  addProjectId,
  removeProjectId,
  addProjectImageId,
  addProjectImage,addProjectImageURL,
  projectCreated,
  reloadProject,
  startJobQuery
} = projectSlice.actions;
export const getProjectId = (state, action) => state.projectId;
export const getProjectImage = (state, action) => state.projectId;
export const getProjectCreated= (state, action) => state.projectId.isProjectCreated
export const getStartJobQuery = (state, action) => state.projectId.isStartQuery
export default projectSlice.reducer;

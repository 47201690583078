import React, { useEffect } from "react";
import { GET_Designer_PROJECT} from "../GraphQL/DesignerGraphQL";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { addProjectView, getAllProject } from "../slices/ProjetcViewSlice";

const LoadDesignerProject = ({ projectIds, callLoad }) => {
  const dispatch = useDispatch();
  const { loading, error, data, refetch } = useQuery(GET_Designer_PROJECT, {
    variables: {
      projectId: projectIds,
    },
    fetchPolicy: "no-cache",
  });
  const getAllProjectView = useSelector(getAllProject);

  useEffect(() => {
    if (loading) {
      callLoad(loading);
    } else if (data) {
       
      if (
        data.designers &&
        data.designers.data &&
        data.designers.data[0] &&
        data.designers.data[0].attributes &&
        data.designers.data[0].attributes.projects &&
        data.designers.data[0].attributes.projects.data.length > 0
      ) {
        if (getAllProjectView && getAllProjectView.length > 0) {
        } else {
          dispatch(
            addProjectView(data.designers?.data[0].attributes.projects.data)
          );
        }

        callLoad(loading);
      } else {
        alert("No Project in your account");
        callLoad(loading);
      }
    }
  }, [data, loading]);

  return <div></div>;
};

export default LoadDesignerProject;

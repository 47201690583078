import { createSlice } from "@reduxjs/toolkit";

const ModelVersionSlice = createSlice({
  name: "modelVersion",
  initialState: {
    modelVersion: [],
  },
  reducers: {
    addModelVersion: (state, action) => {
      state.modelVersion = action.payload.modelVersion;
    },
  },
});

export const { addModelVersion } = ModelVersionSlice.actions;
export const getModelVersionstrapi = (state) => state.modelVersion; // Fixed typo in state property name
export default ModelVersionSlice.reducer;

import React from "react"
import {useState, useEffect} from "react"
import "../imageView.scss"

const Slider =({hide,show})=>{

const [isHide, setIsHide]= useState(true)
    const handleHideSidebar=()=>{
        setIsHide(!isHide)
        hide()
    }

    const handleShowSideBar=()=>{
        setIsHide(!isHide)
        show()
    }
    return (
        <>
        

                        {/* sidebar icon  right */}
                        {isHide &&<div className="sidebar-icon"
                        onClick={handleHideSidebar}
                        >
                          <i class="bi bi-chevron-double-left" ></i>
                        </div> }

                      {/* sidebar icon right end*/}

                        {/* sidebar icon left  */}
                        {!isHide&&<div className="sidebar-icon-right"
                        onClick={handleShowSideBar}
                        >
                        <i class="bi bi-chevron-double-right"></i>
                        </div> }
                      { /* sidebar icon left end */}

                  {/* sidebar icon end */}
      {/* Right side bar div START */}
        </>
    )

}


export default Slider;
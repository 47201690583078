import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   isOpenThresold: false,
   threshold_dist:0,
   threshold_max:0,
   threshold_min:0,

}

const ThresholdSlice = createSlice({
    name: "threshold",
    initialState: initialState,
    reducers: {
      openThresold: (state, action) => {
        state.isOpenThresold=true;
      },
      closeThresold: (state, action) => {
        state.isOpenThresold=false;
      },
      addThresold: (state, action) =>{
        const {min, max, dist}= action.payload;
        state.threshold_dist=dist;
        state.threshold_max=max;
            state.threshold_min=min;
            

      },
    //   resetThresold: (state, action) =>{
    //     state.threshold_dist=0;
    //     state.threshold_max=0;
    //     state.threshold_min=0;
    //     state.isOpenThresold=false;
    //   }
    }
    });

    export const { openThresold, closeThresold,resetThresold,addThresold } = ThresholdSlice.actions;

    export const getThreshold = (state) => state.threshold.isOpenThresold;
    export default ThresholdSlice.reducer;
import React from 'react'

const DummySwatch = () => {
  return (
 

    <>
                                <span className='no-pallet-select-swatch'>No Pallet selected. To select Pallets,<br></br> click <i class="bi bi-list-check"
                               
                                ></i> </span>
                                <img
                                    key="no-data1"
                                    src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_4_4897613ec9.png"
                                    width={100}
                                   
                                    className="home-images"
                                    alt="home icon"
                                  />

                                  <img
                                    key="no-data2"
                                    src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_4_4897613ec9.png"
                                    width={100}
                                   
                                    className="home-images"
                                    alt="home icon"
                                  />

                                  <img
                                    key="no-data3"
                                    src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_4_4897613ec9.png"
                                    width={100}
                                   
                                    className="home-images"
                                    alt="home icon"
                                  />
                                  
                                </>
  )
}

export default DummySwatch
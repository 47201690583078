import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  closeSegment: false,
};
const ClosePreSegmentImageSlice = createSlice({
  name: "closeSegment",
  initialState,
  reducers: {
    addValue: (state, action) => {
      state.closeSegment = action.payload.closeSegment;
    },
  },
});
export const { addValue } = ClosePreSegmentImageSlice.actions;
export const getPreSegment = (state) => state.preSegmentImage.closeSegment;
export default ClosePreSegmentImageSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAlertVisible: false,
  ishover: false,
  segmentName: [],
  samLoading: false,
  reload: false,
  closeModal: false,
  selectedPalletedISloading: false,
  polygon: null,
  isCompare: false,
  allpolygon: [],
  allPolygonNames:[],
  isAllHover: false,
  isDeleteSegmentLoading: false,
  isCheckBox: false,
  reLoadProject: false,
  isCallApi: false,
  isDeleteOptions: false,
  isDeleteSegment: false,
  isOpenSegAfterUpload: false,
  isAllSegImage: false,
  isSwatchDeleting: false,
};

const alertSlice = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {
    alertVisible: (state, action) => {
      state.isAlertVisible = action.payload.isAlertVisible;
    },
    onHover: (state, action) => {
      state.ishover = action.payload.ishover;
      state.segmentName = action.payload.segmentName;
      state.polygon = action.payload.polygon;
    },
    onAllHover: (state, action) => {
      state.isAllHover = action.payload.isAllHover;
      state.segmentName = action.payload.segmentName;
      state.allpolygon = action.payload.allpolygon;
      state.allPolygonNames= action.payload.allPolygonNames;
    },
    samLoading: (state, action) => {
      state.samLoading = action.payload.samLoading;
    },
    reLoadProject: (state, action) => {
      state.reLoadProject = action.payload.reLoadProject;
    },
    closeModals: (state, action) => {
      state.closeModal = action.payload.closeModal;
    },
    palletLoading: (state, action) => {
      state.palletLoading = action.payload.palletLoading;
    },
    compareProject: (state, action) => {
      state.isCompare = action.payload.isCompare;
    },
    deleteSegment: (state, action) => {
      state.isDeleteSegment = action.payload.isDeleteSegment;
    },
    deleteLoading: (state, action) => {
      state.isDeleteSegmentLoading = action.payload.isDeleteSegmentLoading;
    },
    addCheckbox: (state, action) => {
      state.isCheckBox = action.payload.isCheckBox;
    },
    callApi: (state, action) => {
      state.isCallApi = action.payload.isCallApi;
    },
    deleteOption: (state, action) => {
      state.isDeleteOptions = action.payload.isDeleteOptions;
    },
    openSegAfterUpload: (state, action) => {
      state.isOpenSegAfterUpload = action.payload.isOpenSegAfterUpload;
    },
    makeAllImageNull: (state, action) => {
      state.isAllSegImage = action.payload.isAllSegImage;
    },
    swatchDeleting: (state, action) => {
      state.isSwatchDeleting = action.payload.isSwatchDeleting;
    },
    resetAllAlert:(state, action) =>{
          state.isAlertVisible=false
          state.ishover=false
          state.segmentName=[]
          state.samLoading=false
          state.reload=false
          state.closeModal=false
          state.selectedPalletedISloading=false
          state.polygon=null
          state.isCompare=false
          state.allpolygon=[]
          state.allPolygonNames=[]
          state.isAllHover=false
          state.isDeleteSegmentLoading=false
          state.isCheckBox=false
          state.reLoadProject=false
          state.isCallApi=false
          state.isDeleteOptions=false
          state.isDeleteSegment=false
          state.isOpenSegAfterUpload=false
          state.isAllSegImage=false
          state.isSwatchDeleting=false
    }
  },
});

export const {
  alertVisible,
  deleteOption,
  openSegAfterUpload,
  addCheckbox,
  reLoadProject,
  onHover,
  samLoading,
  closeModals,
  palletLoading,
  compareProject,
  onAllHover,
  deleteSegment,
  deleteLoading,
  callApi,
  makeAllImageNull,
  swatchDeleting,
  loadingAlert,
  resetAllAlert
} = alertSlice.actions;
export const getHover = (state) => state.alerts;
export const getSamLoading = (state) => state.alerts.samLoading;
export const getReLoadProject = (state) => state.alerts.reLoadProject;
export const getCloseModal = (state) => state.alerts.closeModal;
export const getPalletLoading = (state) => state.alerts.palletLoading;
export const getCompareProject = (state) => state.alerts.isCompare;
export const getAllPolygons = (state) => state.alerts;
export const getIsDeleteSegment = (state) => state.alerts.isDeleteSegment;
export const getDeleteLoadings = (state) => state.alerts.isDeleteSegmentLoading;
export const getCheckBox = (state) => state.alerts.isCheckBox;
export const getCallApi = (state) => state.alerts.isCallApi;
export const getIsDeleteOptions = (state) => state.alerts.isDeleteOptions;
export const getOpenSegAfterUpload = (state) =>
  state.alerts.isOpenSegAfterUpload;
export const getAllSegImages = (state) => state.alerts.isAllSegImage;
export const getSwatchDeleting = (state) => state.alerts.isSwatchDeleting;

export default alertSlice.reducer;

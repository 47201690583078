import React ,{useState, useEffect, useRef}from "react";
import "./NewDesignHomePage.css";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Leftsection from "../NewDesign/leftsection/Leftsection";
import SegmentationTab from "../../component/Tabs/SegmentationTab";
import { Tooltip } from '@chakra-ui/react'
import CreateProject from "../NewDesign/rightsection/header/CreateProject"
import UserAccount from "../NewDesign/rightsection/header/UserAccount"
import EditAnnonation from "../ProjectLayer/EditAnnotation/EditNewAnnonation"
import ImageSection from "./rightsection/ImageSection";
import EditImageSection from "../ProjectLayer/EditAnnotation/EditNewAnnonation"
import ReloadAllProject from "../ImageView/ReloadAllProject.js";
import CallAIModel from "./CallModelApi/CallAIModel.js";
import {getProjectCreated} from "../slices/ProjectSlice.js"
import { useSelector } from "react-redux";
import EditProjectName from "../HomePage/RenameProject/EditProjectName.js";
import{getProjectId} from "../slices/ProjectSlice"

import { useNavigate } from "react-router-dom";

import CreateProjectImage from "./CreateProjectImage"
import {useDispatch} from "react-redux"
import GetAllCombination from "../CombinationSwatch/GetALlCombination.js"
import SelectCombo from "../NewDesign/leftsection/Combination/SelectCombo.js"
import ComboForm from "../NewDesign/leftsection/Combination/ComboForm.js"

import {getComboProjectId,showComboTab} from "../slices/combinationSwatch/CombinationSwatchSlice.js"
import {switchToHoverLayer} from "../slices/gridView/MultiSelectionSegement.js"
import {selectAllItems} from "../slices/MasterArraySlice"
import {addCustomerMaster} from "../slices/CustomerSlice/customerViewpalletSlice"
import UploadImageApi from "./leftsection/UploadDesignImage/UploadImageApi.js";
import ProjectUrlHome from "../SearchProjectUrl/ProjectUrlHome.js"
import {getCustomer} from "../login/AuthSlice.js"
const NewDesignHomePage = () => {
   const dispatch= useDispatch()
  const navigate= useNavigate()
  const [isMainImageSection, setIsMainImageSection]= useState(true)
  const [isEditImageSection, setIsEditImageSection]= useState(true)
  const [isProjectCreated, setIsProjectCreated]= useState(false)
   const [isprojectImage, setIsprojectImage]= useState(false)
   const [isImageSection, setIsImageSection]= useState(false)
   const [combinationProject, setCombinationProject]= useState(null)
   const [jobId, setJobId]= useState(null)
   const getProjectCreate= useSelector(getProjectCreated)
   const getProjectDetails= useSelector(getComboProjectId)
   const getProjectName= useSelector(getProjectId)
   const [isgetCombo, setIsgetCombo]= useState(true);
    const getMasterArray= useSelector(selectAllItems)

    const getCustomers= useSelector(getCustomer)
    console.log("getCustomers",getCustomers)
   useEffect (()=>{
  
    if(getProjectName){
      setIsgetCombo(false)
     // let id=parseInt(getProjectName.projectId, 10);
      setCombinationProject(getProjectName.projectId)
       let id=parseInt(getProjectName.jobId, 10);
      setJobId(id)
    }
   },[getProjectName])
  
   useEffect(()=>{

    //;
    if(getProjectCreate){
      setIsProjectCreated(true)
      setIsImageSection(false)
      setIsprojectImage(true)
   
    } else if(!getProjectCreate){
 
      setIsProjectCreated(false)
      setIsImageSection(true)
      setIsprojectImage(false)
    }
  },[getProjectCreate])

  const handleprofile=()=>{
    navigate("/profile")
  }

    // rename project name modal open
    const [isRenameForm, setIsRenameForm] = useState(false);
    const handleEditProjectName = () => {
      setIsRenameForm(true);
    };



    const handleRenameFormModal = () => {
      setIsRenameForm(false);

    };

    const [isRename, setIsRename] = useState(false);
    const handleRename = (newName) => {
      setProjectName(newName);
      setIsRename(true);
    };

    const [projectName, setProjectName] = useState();
    const handleOptions = (name, id) => {

      setProjectName(name);

    };
  
    const [showCombo, setshowCombo] = useState(false);
    const [showComboForm, setshowComboForm] = useState(false);
    // move to customer page
    const handleCustomerView=() =>{
    // dispatch ( addCustomerMaster({
    //   customerMaster:getMasterArray.master
    // }))
      navigate("/customerView/"+jobId)
    }

    const [activeCombo, setActiveCombo]= useState(false)
    const handleshowCombo=() =>{

        setshowCombo(true)
        setActiveCombo(true);
        dispatch(showComboTab({
          isShowCombo:true
        }))

    }
 const handleResetData=()=>{
  setCombinationProject(null)
 }
  
   // swicth to hover layer
   const handleHoverLayer=()=>{
     dispatch(switchToHoverLayer({
      ishoverLayer:true
     }))
     dispatch(showComboTab({
      isShowCombo:false
     }))
   }
    

  return (
    <div>
      {/* search the project with id */}
     { getCustomers &&
     getCustomers.customerId===null && 
     <ProjectUrlHome/>}


        {/* reload project */}
           <ReloadAllProject />


          {isProjectCreated&&
           <CallAIModel/>}
       <div className="header-section">
       
          <div className="header-dzinly">
            <div className="left-header-section">
            <div className="dzinly-ai-logo" onClick={handleprofile}>
              <img   src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Frame_71_5487bd07bf.png" ></img>
          
            </div>

           



            </div>

             
            <div className="right-header-section">

            <div className="project-ai-title">
              <h3>
                {getProjectName?.projectName}
              <svg onClick={handleEditProjectName}
                  class="svg-inline--fa fa-edit l-btn-icon"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="edit"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg="">
                  <path
                    fill="currentColor"
                    d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144V432c0 44.2 35.8 80 80 80H368c44.2 0 80-35.8 80-80V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"></path>
                </svg> 

{/* <i class="bi bi-pencil-square"></i> */}
              </h3>

              {isRenameForm && (
            <EditProjectName
             isOpen={isRenameForm}
          onClose={handleRenameFormModal}
          rename={handleRename}
          projectName={projectName}
        />
      )}


            </div>
              


        <div className="create-pro-section ">
              <div class="create-project cre-pro-btn">
                 <CreateProject/>
              </div>

              <div class="user-dropdown">
              <UserAccount/>
              </div>
              </div>
            </div>
          </div>
      
      </div>


      <div className="pro-layer-section row">
        <div className="project-left-section col-12 col-md-3">
          <Tabs  className="left-sec-tab" >
            <TabList className="project-list-title">
              <Tab
               onClick={handleHoverLayer}
              >SEGMENTS</Tab>

              <Tooltip hasArrow label='OLD SEGMENT' bg='gray.300' color='black'> 
              <Tab className="old-segment-section"><i class="bi bi-grid"></i> </Tab>
              </Tooltip>

              <Tooltip hasArrow label='CUSTOMER VIEW' bg='gray.300' color='black'> 
              <Tab className="customers-tab"
                 onClick={handleCustomerView}><i class="bi bi-card-heading"></i></Tab>
            </Tooltip>
          

            <Tooltip hasArrow label='COMBO' bg='gray.300' color='black'> 
              <Tab className={`customers-tab ${activeCombo ? 'icon-active' : ''}`}
              onClick={handleshowCombo}

              > <i class="bi bi-columns-gap list-icon"></i> </Tab>
            </Tooltip>

              {/* <Tab>COMBO</Tab> */}
            </TabList>

            <TabPanels className="left-sidebar-area">
              <TabPanel>
                <Leftsection />
              </TabPanel>

              <TabPanel>
                <SegmentationTab />
              </TabPanel>

              <TabPanel>
              <SelectCombo/>
              </TabPanel> 
  {/* show all combination combination  */}
  <TabPanel>
              {showCombo&&
                  <TabPanel>
                    <SelectCombo/>
                  </TabPanel>
                  } 

 {/* combination form */}
              {showComboForm &&
                    <TabPanel>
                       <ComboForm/>
                  </TabPanel>                   
                  }
                  </TabPanel>

            </TabPanels>
          </Tabs>
        </div>


       
        <div className="right-section-img col-12 col-md-9">
     {isImageSection && <ImageSection/>}
     {isprojectImage &&
        <CreateProjectImage/>}

{combinationProject!=null &&<GetAllCombination
      combinationProject={combinationProject}
      resetData={handleResetData}
      />}
        </div>
      </div>




     
    </div>
  );
};
export default NewDesignHomePage;

import React, { useEffect , useState} from 'react'
import "../NewDesign/NewDesignHomePage.css";
import CreateProjectImage from "../NewDesign/rightsection/header/CreateProject"
import UserAccount from "../NewDesign/rightsection/header/UserAccount"
import CreateProject from "../NewDesign/rightsection/header/CreateProject"
import { Tabs, TabList, TabPanels, Tab, TabPanel ,Tooltip,Link} from "@chakra-ui/react";
import "./CustomerView.css";
import CustomerLeft from "../CutomerView/LeftSection/CustomerLeft"

import CustomerImageSection from "../CutomerView/CustomerImageSection"
import ReloadAllProject from "../ImageView/ReloadAllProject.js";
import { useNavigate } from "react-router-dom";
import {getProjectId} from "../slices/ProjectSlice.js";
import { useSelector } from "react-redux";
import {selectAllItems} from "../slices/MasterArraySlice"
import GetCustomerJobs from "./getAllJobs/GetCustomerJobs.js"
import {palletLoading} from "../slices/AlertSlice.js"
import {useDispatch}from "react-redux"

import {updateWindowResolution} from "../slices/windowSizeSlice/WindowResolution.js"
import {getCustomer} from "../login/AuthSlice.js";
import CustomerCurrentPallete from './customerCurrentPallete/CustomerCurrentPallete.js';
import SelectCombo from "../NewDesign/leftsection/Combination/SelectCombo.js"
import {getComboProjectId,showComboTab} from "../slices/combinationSwatch/CombinationSwatchSlice.js"
const CustomerView = () => {
  const dispatch= useDispatch()
    const navigator = new useNavigate()
   const getProjectDetails = useSelector(getProjectId)
   const getMasterArray= useSelector(selectAllItems)
    const handleMoveTodesignerPage=() => {
     navigator(`/projectdraft/${getProjectDetails.jobId}`)

    }
    const url = window.location.href;
   const [jobId, setJobId]= useState(null)
   const[isStartQuery, setIsStartQuery] = useState(false)
   // find the resolution of screen
   useEffect(()=>{
    
    const innerWidth =window.screen.width;
     const innerHeigth=window.screen.height;
     console.log("innerHeigth",innerHeigth)
     console.log("innerWidth",innerWidth)
     let width;
     let height;
     if(innerWidth===1920 && innerHeigth===1080){
       width = 1425
       height=825
     }
     else if(innerWidth===1600 && innerHeigth===900){
       width = 1166
       height=675
     }
     else if(innerWidth=== 1440 && innerHeigth===900){
       width = 1123
       height=650
     }
     else if(innerWidth=== 1366 && innerHeigth===768){
       width = 950
       height=550
     }
     else if(innerWidth=== 1023 && innerHeigth===768){
       width = 840
       height=486
     } 
     
     else if(innerWidth=== 768 && innerHeigth===1123){
      width = 750
      height=435
    }else{
      width=1123
      height=650
     }
   
     dispatch(updateWindowResolution({
       canvasWidth:width,
       canvasHeight:height
     }))
     const data={
       canvasWidth:width,
       canvasHeight:height
     }
     sessionStorage.setItem("canvasResolution",JSON.stringify(data))
   
     },[])
   
   useEffect(()=>{
      console.log("url",url)
      if(url &&
        getMasterArray &&
        getMasterArray?.master&&
        getMasterArray?.master?.length===0){
              
          dispatch(palletLoading({
            palletLoading: true
        }))
       // Split the URL by "/"){
        const segments = url.split("/"); // Split the URL by "/"
        const job_id= segments[segments.length - 1]; // Get the last segment
        console.log(job_id);
        setJobId(job_id)
        setIsStartQuery(true)
      }
    },[url,getMasterArray])

   
    const handleResettJobId=() => {
      setIsStartQuery(false)
      setJobId(null)
    }

    // do not  show back button
  const[showBack, setShowBack]= useState(false)
  const [showCombo, setshowCombo] = useState(false);
  const [showComboForm, setshowComboForm] = useState(false);
    const getCustomers= useSelector(getCustomer)
    console.log("getCustomers", getCustomers)
    useEffect(()=>{
      if(getCustomers && 
        getCustomers.customerId===null &&
        getCustomers.email===null 
      ){
        setShowBack(false)
      }else{
        setShowBack(true)
      }
    },[getCustomers])

    // handle combo
    const [activeCombo, setActiveCombo]= useState(false)
    const handleshowCombo=() =>{

        setshowCombo(true)
        setActiveCombo(true);
        dispatch(showComboTab({
          isShowCombo:true
        }))

    }
  return (
    <div>
      <ReloadAllProject />

      {/* get all master Array */}
  { isStartQuery &&  
   <GetCustomerJobs
      jobId={jobId}
      resetData={handleResettJobId}
  
  />}


{/* create current pallet Array  */}

<CustomerCurrentPallete/>

<div className="header-section">
       
       <div className="header-dzinly">
         <div className="left-header-section">
         <div className="dzinly-ai-logo">
           <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Frame_71_5487bd07bf.png" 
        //    onClick={handleprofile}
           ></img>
         </div>

        



         </div>

          
         <div className="right-header-section">

         <div className="project-ai-title">
           <h3>
             {/* {getProjectDetails?.projectName} */}
           <svg
            // onClick={handleEditProjectName}
               class="svg-inline--fa fa-edit l-btn-icon"
               aria-hidden="true"
               focusable="false"
               data-prefix="fal"
               data-icon="edit"
               role="img"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512"
               data-fa-i2svg="">
               <path
                 fill="currentColor"
                 d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144V432c0 44.2 35.8 80 80 80H368c44.2 0 80-35.8 80-80V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"></path>
             </svg> 

{/* <i class="bi bi-pencil-square"></i> */}
           </h3>

 


         </div>
           


     <div className="create-pro-section">
           <div class="create-project cre-pro-button">
              <CreateProject/>
           </div>

           <div class="user-dropdown">
          <UserAccount/>
           </div>
           </div>
         </div>
       </div>
   
   </div>
   <div className="project-layer-section row">
        <div className="left-section col-md-3 col-12">
          <Tabs className="left-sec-tab">
            <TabList className="project-list-title ">
              <Tab>SEGMENTS</Tab>
              {/* <Tab >SEGMENTS</Tab> */}
           


              {/* combo */}
              <Tooltip hasArrow label='COMBO' bg='gray.300' color='black'> 
              <Tab className={`customers-tab ${activeCombo ? 'icon-active' : ''}`}
              onClick={handleshowCombo}

              > <i class="bi bi-columns-gap list-icon"></i> </Tab>
            </Tooltip>
             {showBack &&<div className=" back-user"
               
               >     <Tooltip hasArrow label='Back' bg='gray.300' color='black'> 
               <div   className='cust-back-icon' onClick={handleMoveTodesignerPage}>
                   <i class="bi bi-arrow-left-square"></i>
                   </div>
                   </Tooltip>

                   <Link to="/customerView" target="_blank">
                 <div  className='cust-right-arrow'>              
                    <i class="bi bi-arrow-up-right-square"></i>
                </div>
                </Link>

               </div>}

               


       




              {/* <Tab>COMBO</Tab> */}

             
            </TabList>

            <TabPanels className="left-sidebar-area">
              <TabPanel>
           <CustomerLeft/>
              </TabPanel>

              {/* combo */}
              <TabPanel>
              <SelectCombo/>
              </TabPanel>
             

              <TabPanel>
                {/* <SegmentationTab /> */}
              </TabPanel>
{/* 
              <TabPanel>
                <Combination/>
              </TabPanel> */}


            </TabPanels>
          </Tabs>
        </div>


       
        <div className="right-section right-img-section col-md-9 col-12">
     <CustomerImageSection/>
     


        </div>
      </div>
    </div>
  )
}

export default CustomerView
import React from "react";
import { useEffect, useState, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

import "./Report.css";
import { useDispatch, useSelector } from "react-redux";
import { selectAllItems } from "../slices/MasterArraySlice";
import { getProjectId } from "../slices/ProjectSlice";
import { getAllMasterJobs } from "../slices/MasterJobs";
const Report = ({ isOpen, onClose }) => {
  const pdfRef = useRef();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const getALLMasterArray = useSelector(selectAllItems);
  const getProjectDetaiils = useSelector(getProjectId);

  useEffect(() => {
    if (getProjectDetaiils.base64Image) {
      handleImage(getProjectDetaiils.base64Image);
    }
  }, [getProjectDetaiils]);
  const handleImage = (image) => {
    if (image) {
      const encodedImage = image;
      const tets = "data:image/png;base64," + encodedImage;
      const imageUrl = tets;
      setSelectedImage(imageUrl);
    }
  };

  // const handleDownloadPDF = () => {
  //   const content = pdfRef.current; // Access the ref of your component

  //   // Set options for PDF generation
  //   const options = {
  //     margin: 10,
  //     filename: "design_report.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //   };

  //   // Generate PDF
  //   html2pdf()
  //     .from(content)
  //     .set(options)
  //     .outputPdf()
  //     .then((pdf) => {
  //       // Save or open the generated PDF as needed
  //       pdf.save();
  //     });
  // };

  const handleDownloadPDF = async () => {
    const input = document.getElementById("divIdToPrint");
    if (!input) {
      console.error("Element with ID 'divIdToPrint' not found");
      return;
    }

    const pdf = new jsPDF();
    // Set up the page
    // Set up the page
    pdf.setProperties({
      format: "a4", // Set page size, e.g., "letter", "a4", etc.
      orientation: "landscape", // Set page orientation, "portrait" or "landscape"
      unit: "mm", // Set measurement unit, e.g., "mm", "in", "pt"
      top: 10,
      bottom: 10,
      left: 10,
      right: 10,
    });
    pdf.html(input, { callback: (doc) => doc.save("report.pdf") });
  };
  return (
    <div ref={pdfRef}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="report-model">
          <ModalHeader className="dzi-logo"> <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Frame_71_5487bd07bf.png"></img></ModalHeader>
          {/* <ModalHeader className="report-title">DESIGN REPORT</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody id="divIdToPrint" style={{ width: "100%" }}>
            <div className="project-details-pallet">
              <div className="pro-title">
                <h5 className="project-title-name">
                  {getProjectDetaiils?.projectName}
                </h5>
              </div>

              <div className="project-details">
                <h5>12-02-2023</h5>
                <h5 className="customer-name">Vijendra</h5>
              </div>
            </div>

            <div className="project-img-section">
              <img src={selectedImage}></img>

              <p className="image-content">Colors shown may vary due to printer resolution.
                Dzinly and/or its service providers may have intellectual property or other rights regarding the design in this image, but do not have and are not claiming intellectual property rights in the underlying materials used to create
                the image, some of which are referenced on this page.</p>
            </div>

            {/* <div>
         
              <div className="report-pallet-details">
                    <h2>Door</h2>
                <div className="report-pallet-image">
              
                  <div className="pallet-report-section">
               
                  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/window_6_915e062395.png"></img>
                  <h4>Door 1</h4>
                  <h5>Stone</h5>
                  <h5>Estate Gary</h5>
                  </div>
                  
                  <div className="pallet-report-section">
                  
                  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/window_6_915e062395.png"></img>
                  </div>
                  </div>
               
                </div>
            </div> */}

            <div className="report-pallet-section">
              {getALLMasterArray &&
                getALLMasterArray.master &&
                getALLMasterArray.master.length > 0 &&
                getALLMasterArray.master.map((item, index) => (



                  <div className="project-report-pallet report-pallet-section"
                    key={index}
                  >
                    {/* door */}
                    <h3 className="segment-name-report"
                      key={item.name}
                    >{item.name}</h3>
                    
                    <div className="seg-select-section">
                      {item.segmentation &&
                        item.segmentation.length > 0 &&
                        item.segmentation.map((seg, ind) => (
                          <div className="seg-select-box"
                            key={ind}>
                            {seg.SelectSwatchJob &&
                              seg.SelectSwatchJob.length > 0 ?
                             ( seg.SelectSwatchJob.map((swatch) => (
                                <>
                                  <h5 key={seg.name}>{seg.name}</h5>
                                  <div className="report-pallet-img door-images">
                                    <img

                                      key={swatch.swatch?.data?.attributes.id}
                                      src={
                                        process.env
                                          .REACT_APP_S3_DZINLYLIVE_PATH_PALLET +
                                        (swatch.swatch?.data?.attributes
                                          ?.image_s3 || "")
                                      }
                                    ></img>


                                  </div>
                                  <div className="report-pallet-title">
                                    <h4  
                                    key={swatch?.swatch?.data?.attributes
                                      ?.swatch_style?.data?.attributes?.name}
                                    >
                                      {" "}
                                      {
                                        swatch?.swatch?.data?.attributes
                                          ?.swatch_style?.data?.attributes?.name
                                      }
                                    </h4>
                                  </div>
                                </>
                              )) ):(   <p className="seg-pallet-text">No Pallet Selected</p>)
                            }
                          </div>
                        ))}
                    </div>
                    
                 


                  </div>
                ))}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleDownloadPDF}>
              Download as PDF
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};


export default Report;

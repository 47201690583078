import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllItems } from "../slices/MasterArraySlice";
import { onAllHover } from "../slices/AlertSlice";
const OutLine = ({ isOutLine }) => {
  const getMasterArray = useSelector(selectAllItems);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOutLine) {
      if (getMasterArray && getMasterArray?.master.length > 0) {
        handleOutline();
      }
    } else {
      handleClose();
    }
  }, [getMasterArray, isOutLine]);
  const handleOutline = () => {
    let allPolgon = [];

    if (getMasterArray && getMasterArray?.master.length > 0) {
      getMasterArray.master.map((item) => {
        item.segmentation.map((segment) => {
          let allPoly = segment.annotationPolygon
            .split(",")
            .map((value) => parseInt(value.trim(), 10));

          allPolgon.push(allPoly);
        });
      });
      dispatch(
        onAllHover({
          isAllHover: true,
          allpolygon: allPolgon,
        })
      );
    }
  };

  const handleClose = () => {
    dispatch(
      onAllHover({
        isAllHover: false,
        allpolygon: null,
      })
    );
  };
  return <div></div>;
};

export default OutLine;

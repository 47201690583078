import React ,{useState, useEffect}from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllSegments } from '../../../../slices/AllSegmentSlice';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from '@chakra-ui/react'
import {
  removeAllPalletImages,
  addSelectedPalletImag,
  activeMouseLeave,getMouseActive
} from "../../../../slices/TransparentPalletImgSlice";
import { updateSelectedCurrentPallate } from "../../../../slices/CurrentSelectedPalleteSlice"
import {comboActive} from "../../../../slices/combinationSwatch/CombinationSwatchSlice.js"
import {updateSelectedMasterArray} from "../../../../slices/MasterArraySlice.js"
import { compareProject, swatchDeleting } from "../../../../slices/AlertSlice";
import {getEditSegmentation,updateTickOnEdit,updateSelectedOnClickSwallet} from "../../../../slices/EditSegment/EditSegment.js"
 import PopHover from "../../EachSegment/PopHover.js"
 import {selectPopeHover,removePopHover} from "../../../../slices/popHover/PopHoverSlice.js"
const ShowSwatch = ({ 
  selectedSegName,
  selectedSegmentData,
  labelName
 }) => {
  const dispatch = useDispatch(selectAllSegments);
  const allSegments = useSelector(selectAllSegments);
  const getEditSegmentations= useSelector(getEditSegmentation)
  const [selectedSeg, setSelectedSeg]= useState(null)
  const [masterName, setMasterName]= useState(null)
  const [TotalSeg, setTotalSeg]=useState(0)
 useEffect (()=>{

 
  if(getEditSegmentations.editSegment&&
    getEditSegmentations.editSegment[0] &&
    getEditSegmentations.editSegment[0].length===1

    ){
      getEditSegmentations.editSegment[0].map(item=>{
        setSelectedSeg(item)
      })
      
    }else if(getEditSegmentations.editSegment&&
      getEditSegmentations.editSegment[0] &&
      getEditSegmentations.editSegment[0].length>1&&
      getEditSegmentations.labelName
      ){
      setSelectedSeg(null)
     
      setMasterName(getEditSegmentations.labelName)
      setTotalSeg(getEditSegmentations.editSegment[0].length)
    }
 },[getEditSegmentations])

 

  useEffect(()=>{
   
  },[selectedSeg])


     // display pallet Image on main View
     const handleImageSelectedPallet = (
      transplateImage,
      s3BucketImage,
      segmentName,
      swatchId, 
      swatchSelected
    ) => {
     

    
      // deaactive Mouse leave Event
      dispatch(activeMouseLeave({
        isActiveMouseLeave:true
      }))
      // setIsActiveMouseLeave(false)
       dispatch(comboActive({
        IscomboActive:true
       }))
      
       //if false then add on canvas

       if(!swatchSelected){

        dispatch(updateSelectedCurrentPallate({
          segmentName: segmentName,
          s3BucketImage: s3BucketImage,
           swatchId: swatchId,
           palletImage:transplateImage
    }))
    dispatch(updateSelectedMasterArray({
      labelName:labelName,
      segName:segmentName,
      swatchId:swatchId,
      selected:true,
      isHide:false
    })) 
    
    // also update edit slice 
    
    dispatch(updateSelectedOnClickSwallet({
      segName:segmentName,
      swatchId:swatchId,
      selected:true,
    }))
    //if true
       } else{
       dispatch(selectPopeHover({
        segmentName:segmentName,
        ishide:true,
        swatchId:parseInt(swatchId)

       }))
       dispatch(updateSelectedMasterArray({
        labelName:masterName,
        segName:segmentName,
        swatchId:swatchId,
        selected:false,
        
      }))

      dispatch(updateSelectedCurrentPallate({
        segmentName: segmentName,
        s3BucketImage: null,
         swatchId: null,
         palletImage:null
  }))


       }
     
  
      dispatch(
        compareProject({
          isCompare: true,
        })
      );
    };

    // pop hover
    const [uploadImage, setUploadimage] = useState(false);
    const  handleuploadimage = () => {
      setUploadimage(true);
    }
  const handleCloseModel = () => {
    setUploadimage(false);
  }

  return (

    <React.Fragment>

      {selectedSeg &&
       selectedSeg.SelectSwatchJob&&
       selectedSeg.SelectSwatchJob.length > 0 &&
       selectedSeg.SelectSwatchJob.map((item) => {
        //
          
      
            return  (
              <div className="select-pallet-image" key={item.id}>
                <div className="pallet-img">
                  <img
                    src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${item?.swatch?.data?.attributes.image_s3}`}
                    width={55}
                    alt={item.swatch?.data?.attributes.name}
                    onClick={() =>
                      handleImageSelectedPallet(
                        item?.SegmentImagePath||"",
                        item?.swatch?.data?.attributes
                          ?.image_s3,
                          selectedSeg?.name,
                        item?.swatch?.data?.id,
                        item.selected
                      )
                     }
                  />  { item.selected && 
                    <div className="pallet-img-checkbox">
                     
                       <img className='check-box-img' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Group_3_1_8943bd053e.png"></img>
                   </div>}
                </div>
                <div className="pallet-action">

{/* trigger="hover"  */}
<Popover trigger="hover"   >
  <PopoverTrigger>
    <div className="pallet-information-icon">
      <i class="bi bi-info">  </i>
    </div>
  </PopoverTrigger>
  <Portal>
    <PopoverContent>

      <PopoverBody>
        <PopHover
        swatch={item}
       segmentName={selectedSeg.name}
        uploadImage={handleuploadimage}
        masterName={masterName}
        segment={selectedSeg}
        />
        {/* <div className="pallet-img-inf-popup">
          <div className="pallet-images-inf">

            <img
              src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch.swatch?.data?.attributes.image_s3}`}
            />


          </div>
          <div className='pallet-select-tool-section active-icon'>
            <Tooltip hasArrow label='Fav' bg='gray.300' color='black'>
              <div className='pallet-select-tools icon-active'>
                <i class="bi bi-star"></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Upload Image' bg='gray.300' color='black'>
              <div onClick={handleuploadimage} className='pallet-select-tools'>
                <i class="bi bi-upload"></i>
              </div>
            </Tooltip>



            <Tooltip hasArrow label='Hide Pallet' bg='gray.300' color='black'>
              <div className='pallet-select-tools icon-active'>
                <i class="bi bi-eye-slash"></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='View Details' bg='gray.300' color='black'>
              <div className='pallet-select-tools'>
                <i class="bi bi-image"></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Remove Pallet' bg='gray.300' color='black'>
              <div className='pallet-select-tools'>
                <i class="bi bi-x-lg"></i>
              </div>
            </Tooltip>

          </div>

          <div className="pallet-images-details">
            <h3>{seg.name}</h3>
            <h4>Brand : <span>Demo Brand Wall</span></h4>
            <h4>Label : <span>Demo Lable Wall</span></h4>
            <h4>Style : <span>Demo Wall Style </span></h4>
            <p>Model Size: 4'4" x 0'10" x 7'0"</p>
          </div>
        </div> */}
      </PopoverBody>

    </PopoverContent>
  </Portal>
</Popover>




{/* <div className="pallet-fav">
                <i class="bi bi-star"></i>
            </div> */}
        </div>

              </div>
            );
        
        
        })}
    </React.Fragment>

  );
};

export default ShowSwatch;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { getCustomer } from "../login/AuthSlice";
import { useSelector } from "react-redux";
import { addFolderData } from "../slices/FolderSlice";
import { useDispatch } from "react-redux";
const CreateFolder = ({ resetData }) => {
  const [project_id, setProject_id] = useState();
  const getCustomerDetail = useSelector(getCustomer);
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (isMounted.current && token) {
      createProject();
      return () => {
        isMounted.current = false;
      };
    }
  }, [token]);
  // create folder
  const createProject = async () => {
     ;
    let project_name = "New Project";
    let rolebased= getCustomerDetail.role
    const project = {
      name: project_name,
      Status: false,
      [rolebased] : getCustomerDetail.customerId,
      Stage: "Reviewing",
      // image: uploadImageId,
    };
        //
    // Create a data object to send in the API request
    const data = {
      data: project,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_STRAPI + "projects",
        data
      );
      if (response.status === 200) {
        const project = response.data.data;
        let projectId = project.id;
       // console.log("create project create folder" , project);
        setProject_id(projectId);
       // createFolder(projectId);
       resetData(projectId);
      }
    } catch (e) {
     // console.log("create foider", e)
      alert(e.message);
      resetData();
    }
  };

  //create folder in media Librray under prokject
  const createFolder = async (projectId) => {
    //
    let data = {
      name: projectId.toString(),
      parent: 1,
    };
    const authToken = "Bearer " + token;
    try {
      const response = await axios.post(
        "https://aiadmin.dzinly.org/upload/folders/",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
          },
        }
      );
      if (response.status === 200) {
        
        dispatch(
          addFolderData({
            folderData: response.data.data,
            projectId: projectId,
          })
        );
        isMounted.current = true;

        resetData(projectId, response.data.data.id);
      }
    } catch (err) {
      alert(err.message);
      isMounted.current = true;
      resetData();
    }
  };

  return <div></div>;
};

export default CreateFolder;

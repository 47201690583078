import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, loginSuccess } from "../login/AuthSlice";
import { getAllProject } from "../slices/ProjetcViewSlice";
import { selectAllItems, addMasterArray } from "../slices/MasterArraySlice";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import { addLabel_Brand } from "../slices/Label_Brand_Swatch";
import { addProjectId } from "../slices/ProjectSlice";
import { addCurrentTab } from "../slices/CurrentTabSlice";
import ReloadJob from "../SegredateSamData/ReloadJobs";
import ModelVersion from "../Model/ModelVersion";
import {addCombinationSwatch,GetCombinationSwatch} from "../slices/combinationSwatch/CombinationSwatchSlice"
import {getAllSwatches,addAllSwatches} from "../slices/AllSwatches/AllSwatches"

import {updateWindowResolution} from "../slices/windowSizeSlice/WindowResolution"
const ReloadAllProject = () => {
  const dispatch = useDispatch();
  const customerDetails = useSelector(getCustomer);
  const getProjectView = useSelector(getAllProject);
  const getMasterArray = useSelector(selectAllItems);
  const getAllSegments = useSelector(selectAllSegments);
  const getAllSwatchess = useSelector(getAllSwatches);
 
  const getAllombos= useSelector(GetCombinationSwatch)
  //("getAllSwatchess",getAllSwatchess)
  const [JobId, setJobId] = useState();
  useEffect(() => {
   
    if (
      customerDetails?.customerId === null &&
      getProjectView &&
      getProjectView.length === 0 &&
      getMasterArray?.master &&
      getMasterArray?.master.length === 0 &&
      getAllSegments?.allSegmentsArray &&
      getAllSegments?.allSegmentsArray.length === 0&&
      getAllSwatchess&&
      getAllombos
      
    ) {
      let customer = JSON.parse(sessionStorage.getItem("customerLogin")|| "{}");
      let reloadData = JSON.parse(sessionStorage.getItem("ReloadData")|| "{}");
      let segArray = JSON.parse(sessionStorage.getItem("masterArray")|| "{}");
      let allswatch= JSON.parse(sessionStorage.getItem("allSwatch")|| "{}");
       let allCombos= JSON.parse(sessionStorage.getItem("allCombos")|| "{}");
      if (segArray!=null) {
        dispatch(addMasterArray(segArray));
      }
      let brand_swatch = JSON.parse(sessionStorage.getItem("Brands_Swatch")|| "{}");
      if(brand_swatch!=null){
        dispatch(addLabel_Brand(brand_swatch));
      }
      
      if (reloadData!=null) {
        dispatch(
          addProjectId({
            projectId: reloadData.projectId,
            projectName: reloadData.projectName,
            jobId: reloadData.jobId,
            reload: true,
            base64Image: reloadData.base64Image,
            projectImage:reloadData.projectImage,
          })
        );
        setJobId(reloadData.jobId);
        dispatch(addCurrentTab(0));
      }

      if (customer && customer?.customerId != null) {
        dispatch(
          loginSuccess({
            customerId: customer.customerId,
            isAuthenticated: true,
            token: customer.token,
            email: customer.email,
            customerName: customer.customerName,
            role: customer.role,
            userId:customer.userId,
            newUser:false,
          })
        );
        dispatch(addAllSwatches({
          allSwatches:allswatch
        }))
        dispatch(addCombinationSwatch(
          {combinationSwatch:allCombos}

        ))

 //
      let canvasScreen= JSON.parse(sessionStorage.getItem('canvasResolution')|| "{}")
      if(canvasScreen &&canvasScreen?.canvasWidth!=null && canvasScreen?.canvasHeight!=null){
        dispatch(
          updateWindowResolution({
            canvasWidth: canvasScreen.canvasWidth,
            canvasHeight: canvasScreen.canvasHeight
          })
        )
      }
      }
    }

    }, [customerDetails, getProjectView, getMasterArray, getAllSegments,getAllSwatchess]);

  const handleResetData = () => {
    setJobId(null);
  };

  return (
    <>
      {JobId && <ReloadJob resetData={handleResetData} JobId={JobId} />}
      <ModelVersion />
    </>
  );
};

export default ReloadAllProject;

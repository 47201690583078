import { createSlice } from "@reduxjs/toolkit";

const ProjectViewSlice = createSlice({
  name: "projectView",
  initialState: [],
  reducers: {
    addProjectView: (state, action) => {
      return [...action.payload]; // Use the spread operator to update the state array
    },

    removeProjectView: (state, action) => {
      return [];
    },
    updateProjectView: (state, action) => {
      if (state.length > 0) {
        state[0] = action.payload; // Replace the first object with an empty object
      }
    },

    deleteProjectView: (state, action) => {
      const { projectId } = action.payload;
      const indexToDelete = state.findIndex(
        (projectView) => projectView.id === projectId
      );
      // (indexToDelete);
      if (indexToDelete !== -1) {
        state.splice(indexToDelete, 1);
      }
    },
    renameProject: (state, action) => {
      const { projectId, projectName } = action.payload;
      const project = state.find((item) => item.id === projectId);
      if (project) {
        project.attributes.name = projectName;
      }
    },
  },
});
export const {
  addProjectView,
  renameProject,
  removeProjectView,
  updateProjectView,
  deleteProjectView,
} = ProjectViewSlice.actions;
export const getAllProject = (state, action) => state.projectView;
export default ProjectViewSlice.reducer;

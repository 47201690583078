import React,{useState, useEffect} from 'react'
import UploadImage from './UploadImage'
import {useDispatch, useSelector} from "react-redux"
import {getOpenModal } from "../../../slices/uploadSwatch/UploadSwatchImage"
import axios from 'axios';
import {updateUploadSwatchImage}  from "../../../slices/MasterArraySlice"
import {updateCurrenPallate,updateSelectedCurrentPallate} from "../../../slices/CurrentSelectedPalleteSlice";
import {palletLoading} from "../../../slices/AlertSlice"
import {updateSwatchImage} from "../../../slices/MasterJobs"

const UploadHomePage = () => {

  const dispatch = useDispatch();
    const [isOpenParentFolder, setIsOpenParentFolder] = useState(false);
    const getOpenModals= useSelector(getOpenModal)
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    
    const[segName, setSegName]= useState(null)
    const[masterName, setMasterName]= useState(null)
    const[swatch_id, setSwatch_id]= useState(null)
    //open get parenr folder
    
    useEffect(()=>{
        if(getOpenModals.isUploadStart && getOpenModals.segmentName){
           
            setIsOpenModal(true) 
            setSegName(getOpenModals.segmentName)
            setSwatch_id(getOpenModals.swatchId)
            setMasterName(getOpenModals.masterName)
           
        }else if(!getOpenModals.isUploadStart){
            setIsOpenModal(false) 

        }
    },[getOpenModals])




    const handleUploadImage= async(imageFile)=>{
      
      
      const formData = new FormData();
    formData.append("image", imageFile); // the pic
      try{
  const response = await axios.post("https://api.dzinly.org/upload-s3",
  formData  
)

if(response.data.message==="Image uploaded successfully"){
  //upadte in master Array 
  dispatch(updateUploadSwatchImage({
    labelName:masterName,
     segName:segName,
      swatchId :swatch_id,
      url:response?.data?.s3_url
  }))


  // upadte in current pallet image

  dispatch(updateSelectedCurrentPallate({
    segmentName:segName,
    swatchId:swatch_id,
    palletImage:response?.data?.s3_url,
    checked:true
  }))

  // update master master job
     dispatch(updateSwatchImage({
      labelName:segName,
      swatchId:swatch_id,
      segmentimageId:response?.data?.s3_url
     }))  

 dispatch(palletLoading({
  palletLoading:false
 }))
} else{
  alert("Something went wrong", response.data?.message)
  dispatch(palletLoading({
    palletLoading:false
   }))
}
console.log("uploafimage response", response)
      }catch(error){
        console.log("Error",error);
        alert("Something went wrong ", error.message)
        dispatch(palletLoading({
          palletLoading:false
         }))
      }

    }
    




  return (
    <div>

        <UploadImage
        isOpen={isOpenModal}
        searchPArenFolder={handleUploadImage}
        segName={segName}
        />
 


    </div>
  )
}

export default UploadHomePage
import React, { useState, useEffect } from "react";
import {
  updateMasterSegment,
  addMasterArray,
} from "../slices/MasterArraySlice";
import { addSegment, selectAllSegments } from "../slices/AllSegmentSlice";
import { selectAllItems } from "../slices/MasterArraySlice";
import { useDispatch, useSelector } from "react-redux";
import { GET_JOBSDETAIL } from "../GraphQL/GraphQl";
import { RELOAD_JOBSDETAIL } from "../GraphQL/ReloadJobsGraphQl";
import { useQuery } from "@apollo/client";
import { samLoading } from "../slices/AlertSlice";
import { addModelVersion } from "../slices/Model/ModelSlice";
import {addCombinationSwatch} from "../slices/combinationSwatch/CombinationSwatchSlice"
import {getCustomer} from "../login/AuthSlice.js"
const ReloadJobs = ({ JobId, resetData }) => {
  const dispatch = useDispatch();
  const getMasterArray = useSelector(selectAllItems);
  const [isloading, setIsloading] = useState(false);


  const getCustomerDetails= useSelector(getCustomer)
  const { loading, error, data ,refetch} = useQuery(RELOAD_JOBSDETAIL, {
    variables: {
      jobId: JobId,
    },
    fetchPolicy: "no-cache", // Force a network request
  });
console.log("reload job", data)
  useEffect(() => {

    if (data && !loading && JobId != null) {
      
      // dispatch(addSegment(data.jobs.data[0].attributes.Segmentation));
      dispatch(addCombinationSwatch(
        {combinationSwatch:data?.jobs?.data[0]?.attributes?.project?.data?.attributes?.combinations?.data}

      ))

      sortByAreaOfPixels(data.jobs.data[0].attributes.Segmentation)
     
      createMasterArray(data.jobs.data[0].attributes.Segmentation)
      // let allJobs = data.jobs.data[0].attributes.Segmentation;
      // let masterArray = getMasterArray.master;
      // let arrays = [];
      // if (
      //   masterArray &&
      //   masterArray.length > 0 &&
      //   allJobs &&
      //   allJobs.length > 0
      // ) {
      //   // setIsloading(true);
      //   masterArray.map((item) => {
      //     let segArray = [];
      //     allJobs.map((job) => {
      //       let jobName = job.name.slice(0, -1);
      //       if (jobName === "Garage") {
      //         jobName = "GarageDoor";
      //       }

      //       if (item.name.startsWith(jobName)) {
      //         // ;
      //         segArray.push(job);
      //       }
      //     });

      //     arrays.push({
      //       id: item.id,
      //       name: item.name,
      //       labels: item.labels,
      //       icon: item.icon,
      //       segmentation: segArray,
      //     });
      //   });
      // }

      dispatch(
        addModelVersion({
          modelVersion: data.jobs.data[0].attributes.ModelVersion,
        })
      );
      // dispatch(addMasterArray(arrays));
      setIsloading(true);
      dispatch(samLoading({ samLoading: false }));
      resetData();
    }
  }, [data, loading]);


  const sortByAreaOfPixels = (allsegments) => {
    const sortedJobs = allsegments.slice().sort((a, b) => {
      return parseInt(b.perimeter) - parseInt(a.perimeter);
    });
  
    dispatch(addSegment(sortedJobs));
  };

  const createMasterArray = (jobData) => {
   
    let allJobs = jobData;
    let masterArray = getMasterArray.master;
    let arrays = [];
  
    if (masterArray && masterArray.length > 0 && allJobs && allJobs.length > 0) {
      masterArray.forEach((item) => {
        let segArray = [];
        allJobs.forEach((job) => {
          let alphabets = job.name.match(/[a-zA-Z]+/)[0];
          if (alphabets === "Garage") {
            alphabets = "GarageDoor";
          }
          if (item.name === alphabets) {
            let userSwatch = job?.SelectSwatchJob.filter((swatch) => {
              return swatch?.User?.data?.attributes?.email === getCustomerDetails.email;
            }).map((swatch) => ({
              ...swatch,
              selected: false, 
              selectedCustomer:false,
              isHide :false// Add selected property with the value false
            }));
  
            segArray.push({
              id: job.id,
              name: job.name,
              area_pixel: job.area_pixel,
              annotationPolygon: job.annotationPolygon,
              confidence: job.confidence,
              BoundingBoxFloat: job.BoundingBoxFloat,
              BoundingBoxInt: job.BoundingBoxInt,
              perimeter:job.perimeter,
              SelectSwatchJob: userSwatch,
            });
          }
        });
  
        arrays.push({
          id: item.id,
          name: item.name,
          labels: item.labels,
          icon: item.icon,
          segmentation: segArray,
          allSwatches: [],
        });
      });
    }
  
    sessionStorage.setItem("MasterArrays", JSON.stringify(arrays));
    getUniqueSwatch(arrays,masterArray)
   

   
  };

  const getUniqueSwatch = (allArrays,masterArray) => {
    allArrays.forEach((item) => {
      let allSwatches = [];
      item.segmentation.forEach((seg) => {
        seg.SelectSwatchJob.forEach((swatch) => {
          let existingSwatch = allSwatches.find((existing) => existing.swatchId === swatch.swatch.data.id);
          if (!existingSwatch) {
            allSwatches.push({
              swatchId: swatch.swatch.data.id,
              palletName:swatch.swatch.data.attributes.name,
              palletImage: swatch.swatch.data.attributes?.image_s3,
              brand:      swatch?.swatch?.data?.attributes?.brand?.data?.attributes?.name,
              palletstyle: swatch?.swatch?.data?.attributes?.swatch_style?.data?.attributes?.name,
              label:item?.labels[0]?.name,
               selected:false,
               customerSelected:false,
               
            });
          }
        });
      });
      item.allSwatches = allSwatches;
    });
    console.log("unique Array", allArrays);
    if(allArrays && allArrays.length === 0){
      dispatch(addMasterArray(masterArray));
      dispatch(samLoading({ samLoading: false }));
    }else{
      dispatch(addMasterArray(allArrays));
      dispatch(samLoading({ samLoading: false })); 
    }
  };
  return <div></div>;
};

export default ReloadJobs;


import React from "react";
import {useState, useEffect} from "react"
import { Tooltip } from '@chakra-ui/react'
import {selectAllItems} from "../../../../slices/MasterArraySlice";
import {useSelector, useDispatch} from "react-redux"

  import { getAllSwatches } from "../../../../slices/AllSwatches/AllSwatches"
import MediaSwatch from "./MediaSwatch"
  const MediaSegments = () => {
 const dispatch= useDispatch()
const [isStartAddSegment, setIsStartAddSegment]= useState(false)
const [isSelectLabelName, setIsSelectLabelName]= useState(true)

const [label, setLabel] = useState();
const [segment, setSegment] = useState();
const [showMarkButton, setShowMarkButton]= useState(true);
const [showSaveButton, setShowSaveButton]= useState(false);
const getMasterArray = useSelector(selectAllItems);
const getSwatches = useSelector(getAllSwatches)
const [allSwatches, setAllSwatches] = useState()
const handleLabelSeg = (segment) => {
  
console.log("allSwatches",getSwatches )
if (getSwatches &&
  getSwatches.allSwatches &&
  getSwatches.allSwatches.length > 0 &&
  getMasterArray &&
  getMasterArray.master &&
  getMasterArray.master.length > 0 
) {
  // 
  let swatches = [];
  let labels = [];
  let brands = [];
  let styles = [];
  let uniqueBrands = [];
  let uniqueStyles = [];
  if(segment.labels && segment.labels.length>0){
    segment.labels.map(label=>{
      getSwatches.allSwatches.map((swatch) => {
        if (label && label.name != null && swatch.label && swatch.label.name === label.name) {
          swatches.push(swatch);
        }
      })
      setAllSwatches(swatches)
    })
  }

 
  
}
};







  return (
    <div>

      
  

      { isSelectLabelName && <div>
        {/* <h2 className='select-category-title'>Select Category</h2> */}
        <div className="project-segments-img">

          {getMasterArray &&
              getMasterArray.master &&
              getMasterArray.master.length > 0 &&  
              getMasterArray.master.map((item, index) => {
                return(
                  item.icon &&  
                  (
                    <div className="project-seg-images "
                    key={item.id}
                    onClick={() => handleLabelSeg(item)}
          >
            <div className="seg-contant"  key={item.name}      
            >
              <h2   
              >{item.name}</h2>
            </div>
            <div className="segament-img ">
              <img src={item.icon}></img>
            </div>
          </div>
                ))
              })}
        </div>

      </div>}

      
      <MediaSwatch
      allSwatches={allSwatches}
      />
    </div>
  )
}

export default MediaSegments

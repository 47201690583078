import React from 'react'
import "./Leftsection.css"
const Shimmer = () => {
  return (
    <div className='shimmer-left-section'>

            <div class="card-two">
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
              <div class="shimmerBG-left-section seg-images-prodect"></div>
            </div>

            <div class="card-right-section-one">
              <div class="pallet-checkbox-shimmer-one">
                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-one end-checkbox-right-se"></div>
                  <div class="shimmerBG-one title-line-one end-two"></div>
                  <div class="shimmerBG-one title-line-one end-two"></div>
                </div>

                <div>
                  <div class="shimmerBG-one content-line-one "></div>
                </div>
              </div>
              <div class="pallet-section-shimmer-one">
                <div class="shimmerBG-one line-shimmer-one"></div>

                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-1 end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                </div>
                <div class="shimmerBG-one content-line-one "></div>
                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-1 end-three"></div>
                  <div class="shimmerBG-one title-line-one end-three"></div>
                  <div class="shimmerBG-one title-line-one end-three"></div>
                </div>

                <div>
                  <div class="shimmerBG-one content-line-one "></div>
                </div>
              </div>

              

              <div class="pallet-section-shimmer-one shimmer-dable-section">
                <div class="shimmerBG-one line-shimmer-one"></div>

                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-1 end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                </div>
                <div class="shimmerBG-one content-line-one "></div>
                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-1 end-three"></div>
                  <div class="shimmerBG-one title-line-one end-three"></div>
                  <div class="shimmerBG-one title-line-one end-three"></div>
                </div>
                <div>
                  <div class="shimmerBG-one content-line-one "></div>
                </div>
              </div>

                   <div class="pallet-section-shimmer-one shimmer-dable-section">
                <div class="shimmerBG-one line-shimmer-one"></div>

                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-1 end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                  <div class="shimmerBG-one title-line-one end-one"></div>
                </div>
                <div class="shimmerBG-one content-line-one "></div>
                <div class="right-section-shimmer-one">
                  <div class="shimmerBG-one title-line-one right-section-shi-1 end-three"></div>
                  <div class="shimmerBG-one title-line-one end-three"></div>
                  <div class="shimmerBG-one title-line-one end-three"></div>
                </div>
                <div>
                  <div class="shimmerBG-one content-line-one "></div>
                </div>
              </div>

            </div>
    </div>
  )
}

export default Shimmer
import React from "react";
import {
  // Alert,
  // AlertIcon,
  // AlertTitle,
  // AlertDescription,
} from "@chakra-ui/react";
import "./ProjectAlert.css";
const ProjectAlert  = () => {
  return (

    
    <div className="project-loading-div-1">


       <div  className="project-loading-div" >
          <div class="create-project-shimmer  project-card">
               <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

      <div class="create-project-shimmer ">
      <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>
         
      
      </div>


      <div class="create-project-shimmer ">
      <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>

      
      </div>

      </div>



      {/* <div className="project-card-dable-1">

      <div class="create-project-shimmer  project-card-simmer">
     <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

      <div class="create-project-shimmer  project-card-simmer">
     <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

      <div class="create-project-shimmer  project-card-simmer">
     <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
      </div>

        <div class="create-project-shimmer  project-card-simmer">
          <div class="shimmerBG title-line"></div>
        <div class="shimmerBG media"></div>    
   
       </div>
      </div> */}



      {/* <div class="create-project-shimmer ">
        <div class="shimmerBG media"></div>
        <div class="p-32">
          <div class="shimmerBG title-line"></div>

          
         


         
        </div>
      </div> */}



      
  


      {/* <Alert status="success" variant="solid">
        <AlertIcon />
        <AlertTitle>Project is Loading ...</AlertTitle>
        <AlertDescription></AlertDescription>
      </Alert> */}
    </div>

         


    
  );
};


export default ProjectAlert;

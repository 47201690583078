// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerId: null,
  token: null,
  isAuthenticated: false,
  email: null,
  customerName: null,
  role: null,
  userId:null,
  newUser:false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.token = action.payload.token;
      state.customerId = action.payload.customerId;
      state.email = action.payload.email;
      state.customerName = action.payload.customerName;
      state.role = action.payload.role;
      state.userId=action.payload.userId;
      state.newUser=action.payload.newUser;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.customerId = null;
      state.email = null;
      state.role = null;
      state.userId=null
      state.newUser=false
    },
    addUserId:(state, action)=>{
      state.userId=action.payload.userId
    }, updateUser:(state, action)=>{
      state.newUser=false
    }
  },
});

export const { loginSuccess, logoutSuccess,addUserId ,updateUser} = authSlice.actions;
export const getAuthenticated = (state, action) => state.auth.isAuthenticated;
export const getEmail = (state) => state.auth.email;
export const getCustomer = (state) => state.auth;

export default authSlice.reducer;

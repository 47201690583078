import React ,{useState, useEffect, useRef}from 'react'
import "../../NewDesignHomePage.css";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, loginSuccess, logoutSuccess } from "../../../login/AuthSlice";

import { removeAllSegment } from "../../../slices/AllSegmentSlice";

import {
  removeProjectView,
  updateProjectView,
  addProjectView,
} from "../../../slices/ProjetcViewSlice";
import { removeJobs } from "../../../slices/JobSlice";
import { removeDeleteSegment } from "../../../slices/AfterDeleteSegmentSlice";
import {
  palletLoading,
  closeModals,
  onAllHover,
  swatchDeleting,
  deleteLoading,
} from "../../../slices/AlertSlice";
import { removeProjectId, getProjectId } from "../../../slices/ProjectSlice";
import ReloadProject from "../../../ReloadProject/ReloadProject";
// import CreateProject from "../CreateProject/CreateProject";
import CreateProject from "../../../CreateProject/CreateProject";
import { useToast } from "@chakra-ui/react";
import { removeSelectedPallete } from "../../../slices/SelectedPalletdSlice";
import {
  getReLoadProject,
  reLoadProject,
  samLoading,
  resetAllAlert
} from "../../../slices/AlertSlice";
import { removeMasterArray } from "../../../slices/MasterArraySlice";
import {
  removeTramsparentImage,
  removeAllPalletImages,
} from "../../../slices/TransparentPalletImgSlice";
import ReloadProjects from "../../../Header/ReloadProjects";

import { removeCurrentPallete } from "../../../slices/CurrentSelectedPalleteSlice";
import NewOrExistingCreateProject from "../../../HomePage/NewOrExistingCreateProject";
import UploadExistingProject from "../../../UploadExistingProject/UploadExistingProject";
import { useNavigate } from "react-router-dom";

import { removeFolder } from "../../../slices/FolderSlice";
import { removeDesignerJobs } from "../../../slices/Designer/DesignerJobListSlice";
import { removeGridMultiSelect } from "../../../slices/gridView/MultiSelectionSegement";
 
import {reSetAnnotation} from "../../../slices/EditSegment/EditSegment"
import {removeEditSegment} from "../../../slices/EditSegment/EditSegment"
import {resetAllProjectImage} from "../../../slices/CreateProjectImageSlice"
import {removeCombinationSwatch,resetComboValue} from "../../../slices/combinationSwatch/CombinationSwatchSlice"

import {resetMouseEvent} from "../../../slices/MouseEvent/MouseEventSlice"
import {removeShadow} from "../../../slices/shadow/ShadowSlice"

import {closeModal} from "../../../slices/uploadSwatch/UploadSwatchImage"

import { refreshReduxData,getIsStartUpload,resetModal} from "../../../slices/CreateProjectHeaderSlice/CreateProjectHeaderSlice"
import {removeCustomerViewPallet} from "../../../slices/CustomerSlice/customerViewpalletSlice"

import {resetLoading} from "../../../slices/LoadingSlice"
import {resetCustomerPallet} from "../../../slices/CustomerSlice/customerViewpalletSlice"
const UserAccount = () => {
    const getReloadProjectS = useSelector(getReLoadProject);
    const dispatch= useDispatch();
    const [projectIds, setProjectIds] = useState();
    const customerDeatails = useSelector(getCustomer);
    const handleHomeClick = (e) => {
       // setIsProfile(false);
        // e.preventDefault();
        //SetReload(true);
        dispatch(removeSelectedPallete());
        handleRemoveReduxStore();
        dispatch(
          onAllHover({
            isAllHover: false,
            segmentName: null,
            allpolygon: [],
          })
        );
        sessionStorage.removeItem("masterArray")
        sessionStorage.removeItem("MasterArrays")
        sessionStorage.removeItem("allCombos")
      };


      const handleSignOut = () => {
        //setIsProfile(false);
        dispatch(logoutSuccess());
        handleRemoveReduxStore();
        sessionStorage.removeItem("customerLogin");
        document.cookie = `authToken=`;
        dispatch(removeProjectView());
      };

       // remove master Array and AllSegmentArray
       const handleRemoveReduxStore = () => {
        dispatch(resetCustomerPallet())// reset the custome current pallate
        dispatch(resetLoading())
        dispatch(resetModal())  // reset create project header slice 
       dispatch(closeModal())
        dispatch(removeShadow()) // remove all shadow 
        dispatch(resetComboValue())
        dispatch(removeCombinationSwatch())
        dispatch(removeGridMultiSelect());
        dispatch(removeTramsparentImage());
        dispatch(removeAllPalletImages());
        dispatch(removeSelectedPallete());
        dispatch(removeAllSegment());
        // dispatch(removeProjectView());
        // dispatch(alertVisible(false));
        dispatch(removeProjectId());
        dispatch(removeCustomerViewPallet()) // remove customer View Pallet
      dispatch(removeEditSegment()) // reset the edit annottaion
        dispatch(removeJobs());
        dispatch(removeDeleteSegment());
        dispatch(palletLoading({ palletLoading: false }));
        dispatch(removeMasterArray());
        dispatch(removeCurrentPallete());
        dispatch(removeFolder());
        dispatch(removeDesignerJobs());
        dispatch(
          swatchDeleting({
            isSwatchDeleting: false,
          })
        );
        dispatch(
          deleteLoading({
            isDeleteSegmentLoading: false,
          })
        );
        sessionStorage.removeItem("CreateProject");
        dispatch(
          onAllHover({
            isAllHover: false,
            segmentName: null,
            allpolygon: [],
          })
        );
    dispatch(resetAllAlert())  // reset all alerts

        dispatch(removeEditSegment())// edit segemnetation
        dispatch(resetMouseEvent())    // reset mouse event slice 
        if (getReloadProjectS) {
          setProjectIds(customerDeatails.customerId);
          dispatch(removeProjectView());
          dispatch(
            reLoadProject({
              reLoadProject: false,
            })
          );
        }
      };
     

      // refresh add redux store after creating ptoject from header
      const getIsStartUploads= useSelector(getIsStartUpload)
      useEffect(()=>{
        if(getIsStartUploads){
          handleRemoveReduxStore()
          dispatch(refreshReduxData({
            isStarteUpload:false
          }))
        }

      },[getIsStartUploads])

  return (

    
           <div class="dzinly-ai-dropdown">
                <button class="dzinly-designer-title">
                {customerDeatails.customerName}
                  <i class="bi bi-caret-down-fill"></i>
                </button>
                <div class="dzinly-details-title nav-create-project-dropdown">
                  
                   <Link 
                            to="/profile"
                            onClick={(e) => handleHomeClick(e)}
                          >
                            Dashboard
                          </Link>

                          <Link 
                            to="/profile"
                            onClick={(e) => handleHomeClick(e)}
                          >
                           Profile
                          </Link>

                          <Link to="/login" 
                          onClick={handleSignOut}
                          >
                            Sign Out
                          </Link>
                          
                </div>
              </div>

  )
}

export default UserAccount

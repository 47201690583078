import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pannelTab: "",
};

const TabPannelSlice = createSlice({
  name: "tabPannel",
  initialState: initialState,
  reducers: {
    addTabPannel: (state, action) => {
      state.pannelTab = action.payload.pannelTab;
    },
    removeTabPannel: (state, action) => {
      state.pannelTab = "";
    },
  },
});

export const { addTabPannel, removeTabPannel } = TabPannelSlice.actions;
export const getTabPannel = (state) => state.tabPannel.pannelTab;
export default TabPannelSlice.reducer;

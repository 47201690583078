import React from "react";
import { Input, FormLabel, Select } from "@chakra-ui/react";
import "./SelectSwatchJob.css";
const SelectSwatchJob = () => {
  return (
    <div className="select-swatch-job-from">
      <div className="row">
        <div className="col-6 mt-2">
          <FormLabel>Swatch</FormLabel>
          <Select className="input-primary" placeholder="Project">
            <option>United Arab Emirates</option>
            <option>Nigeria</option>
          </Select>
        </div>

        <div mt={4} className="col-6 mt-2">
          <FormLabel>User</FormLabel>
          <Select className="input-primary" placeholder="Project">
            <option>United Arab Emirates</option>
            <option>Nigeria</option>
          </Select>
          {/* <Input className="input-swatch" /> */}
        </div>
      </div>
    </div>
  );
};

export default SelectSwatchJob;

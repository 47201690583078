    import React, { useState, useEffect } from 'react'
    import { Tab, TabPanel, Tabs, TabList, TabPanels, } from '@chakra-ui/react'
    import { Input, InputGroup, Stack, InputLeftElement, InputRightElement } from '@chakra-ui/react'

    import { IconButton, } from '@chakra-ui/react'
    import {
        Popover,
        PopoverTrigger,
        PopoverContent,
        PopoverHeader,
        PopoverBody,
        PopoverFooter,
        PopoverArrow,
        PopoverCloseButton,
        PopoverAnchor,
        Portal,
    } from '@chakra-ui/react';
    import {
        Accordion,
        AccordionItem,
        AccordionButton,
        AccordionPanel,
        AccordionIcon,
        Box,
    } from "@chakra-ui/react";
    import { Tooltip } from '@chakra-ui/react'
    import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
    import {
        selectAllItems,
        removeSegmentFromMAsterArray,
    } from "../../../../slices/MasterArraySlice";
    import { useDispatch, useSelector } from 'react-redux';
    import { Label } from 'react-konva';
    import {
        addShowSwatch,
        getAllShowSwatches,
    } from "../../../../slices/SelectedSwatchImage";
    import { getAllLabels } from "../../../../slices/Label_Brand_Swatch";
    import { getAllSwatches } from "../../../../slices/AllSwatches/AllSwatches"
    import { addSelectedPalletImag } from "../../../../slices/TransparentPalletImgSlice";
    import { palletLoading, closeModals } from "../../../../slices/AlertSlice";
    import { getTabPannel } from "../../../../slices/TabPannelSlice";
    import "./SelectSwatch.css";
    import { compareProject } from "../../../../slices/AlertSlice";
    import styled from '@emotion/styled';
    import Actions from '../../SelectSwatchTabs/SelectSwatch/SelectSwatchAction.js';

    import { addIsOnMouseUp } from "../../../../slices/MouseEvent/MouseEventSlice"

    import ShowSwatch from './ShowSwatch';
    import {addEditSegment} from "../../../../slices/EditSegment/EditSegment"
    import {comboActive} from "../../../../slices/combinationSwatch/CombinationSwatchSlice"

    import{moveToMainPannel} from "../../../../slices/MouseEvent/MouseEventSlice" 
    import ShowPalleteCheck from './ShowPalleteCheck';
    import {deleteDegment} from "../../../../slices/DeleteSlice/DeleteSlice"


    import DeleteSegmentAlert from "../../../../Tabs/DeleteSegmentAlert";
    import {
        selectAllSegments,
        removeSegmentFromSegments,
    } from "../../../../slices/AllSegmentSlice";
    import {
        deleteSegment,
        
        deleteLoading,
    } from "../../../../slices/AlertSlice";
    import { deleteMasterJob } from "../../../../slices/MasterJobs";
    import DeleteSegment from "../../../../Tabs/DeleteSegment";
    
    import {getProjectId} from "../../../../slices/ProjectSlice"
    
    import UpdateDeletedSegmentToDb from "../../../../Tabs/UpdateDeletedSegmentToDb";

    import {getSelectedSegments,removeSelectedSegment} from "../../../../slices/MouseEvent/MouseEventSlice"
    import {getEditSegmentation,resetEditAnnotation} from "../../../../slices/EditSegment/EditSegment.js"
    import {getGridMultiSelect} from "../../../../slices/gridView/MultiSelectionSegement.js"
    const SelectSwatch = ({ 
        labelName,
        moveToLeftSection, 
        selectedSegName,
        selectedSegmentData ,
        moveLeftReannotation2,
        moveToMainTabs
        }) => {
        const dispatch = useDispatch();
        const getMasterArray = useSelector(selectAllItems);
        const getSwatches = useSelector(getAllSwatches)
        const [allSwatches, setAllSwatches] = useState()
        const getTabPannels = useSelector(getTabPannel);
        const [allLabels, setAllLabels] = useState([])
        const [allBrands, setAllBrands] = useState([])
        const [allStyles, setAllStyles] = useState([])
        const [selectedSegmentName, setSelectedSegmentName] = useState(null)
        
        const[selectedSegmentDatas, setSelectedSegmentDatas]= useState(null)


         useEffect(()=>{
            if(allSwatches){
                console.log("allSwatches",allSwatches)
            }
         },[allSwatches])

        // update the segname 
        useEffect(()=>{
            if(selectedSegName){
                setSelectedSegmentName(selectedSegName)
            }
        },[selectedSegName])
        useEffect(()=>{
        
            if(selectedSegmentData!=undefined){
                
                setSelectedSegmentDatas(selectedSegmentData)
            
            }
        },[selectedSegmentData])

    
    // on mouse click hover layer
    const  getSelectedSegment=useSelector(getSelectedSegments)
        
    useEffect(()=>{
            
            if(getSelectedSegment&&
                getSelectedSegment.name
                ){
                    
                    setMasterName(null)
                    setTotalSeg(0)
                    setSelectedSegmentName(getSelectedSegment.name)
                setSelectedSegmentDatas(getSelectedSegment)
                dispatch(removeSelectedSegment())
        
        }},[getSelectedSegment])

        //  get all swatch based on labels
        useEffect(() => {
            if (getSwatches &&
                getSwatches.allSwatches &&
                getSwatches.allSwatches.length > 0 &&
                getMasterArray &&
                getMasterArray.master &&
                getMasterArray.master.length > 0 &&
                labelName != null
            ) {
                // 
                let swatches = [];
                let labels = [];
                let brands = [];
                let styles = [];
                let uniqueBrands = [];
                let uniqueStyles = [];
                getSwatches.allSwatches.map((swatch) => {
                    getMasterArray.master.map((master) => {
                        if (master.name === labelName) {

                            master.labels.map((label) => {
                                labels.push(label)
                                if (label?.name === swatch?.label?.name) {

                                    swatches.push(swatch);
                                    if (!uniqueBrands.includes(swatch?.brand?.name)) {
                                        if (swatch?.brand?.name !== undefined) {
                                            brands.push(swatch?.brand?.name);
                                            uniqueBrands.push(swatch?.brand?.name);
                                        }

                                    }

                                    if (!uniqueStyles.includes(swatch?.swarch_style?.name)) {
                                        if (swatch?.swatch_style?.name !== undefined) {
                                            styles.push(swatch?.swatch_style?.name);
                                            uniqueStyles.push(swatch?.swatch_style?.name);
                                        }
                                    }
                                }
                            })
                        }
                    })
                })
                setAllSwatches(swatches)
                setAllLabels(labels)
                setAllBrands(brands)
                setAllStyles(styles)
            }


        }, [getSwatches, getMasterArray, labelName])



        useEffect(()=>{
            console.log("allSwatches",allSwatches)
        },[allSwatches])
    const[selectedSwatchId,setSelectedSwatchId]= useState(null)
    const handleSelectedSwatchId=(swatchId) =>{
        setSelectedSwatchId(swatchId)
    }

        // after selecting swatch
        const handleSwatch = (palletImagePath, palletId, palletName, 
            swatch_id, type, shape,brand,label,palletStyle,pallet_name) => {
              
                debugger
            setTotalSeg(0)
            setMasterName(null)
            setSelectedSegmentDatas(null)
            dispatch(resetEditAnnotation())
            dispatch(comboActive({
                IscomboActive:true
            }))
        
            console.log("selectedSegmentData",selectedSegmentData)
            // checking selectedSegentData is an Array
            if(Array.isArray(selectedSegmentData)){
                let ddd= selectedSegName  // door1
                    let data = {
                        labelName: labelName,  // door
                        flagDemo: 1,
                        palletImagePath: palletImagePath,
                        palletId: palletId,
                        palletName: palletName,
                        palletType:type,
                        palletShape:shape!=null?shape.Name:"Rectangle",
                        brand:brand,
                        label:label,
                        palletStyle:palletStyle,
                        pallet_name:pallet_name,
                    
                    };
                    dispatch(palletLoading({ palletLoading: true }));
            
                    dispatch(addIsOnMouseUp({
                        isOnMouseUp: false,
                        labelName: null
                    }))
            
                    dispatch(
                        compareProject({
                            isCompare: true,
                        })
                    );
                    moveToLeftSection(data)
            }else{

                if(selectedSegmentData && selectedSegmentData.SelectSwatchJob &&
                    selectedSegmentData.SelectSwatchJob.length > 0){
                        selectedSegmentData.SelectSwatchJob.map((seg) => {
                            let swatchid= parseInt(seg.swatch?.data.id)
                            if(swatchid===swatch_id){
                                console.log("swatch is present")
                                alert("Swatch is already selected")
                            }else{
        
                                
                                let ddd= selectedSegName  // door1
                                let data = {
                                    labelName: labelName,  // door
                                    flagDemo: 1,
                                    palletImagePath: palletImagePath,
                                    palletId: palletId,
                                    palletName: palletName,
                                    palletType:type,
                                    palletShape:shape!=null?shape.Name:"Rectangle",
                                    brand:brand,
                                    label:label,
                                    palletStyle:palletStyle,
                                    pallet_name:pallet_name,
                                }
                                dispatch(palletLoading({ palletLoading: true }));
                        
                                dispatch(addIsOnMouseUp({
                                    isOnMouseUp: false,
                                    labelName: null
                                }))
                        
                                dispatch(
                                    compareProject({
                                        isCompare: true,
                                    })
                                );
                                moveToLeftSection(data)
                            }
                        })
                    } else if (selectedSegmentData?.SelectSwatchJob.length ===0){ 
                        let ddd= selectedSegName  // door1
                        let data = {
                            labelName: labelName,  // door
                            flagDemo: 1,
                            palletImagePath: palletImagePath,
                            palletId: palletId,
                            palletName: palletName,
                            palletType:type,
                            palletShape:shape!=null?shape.Name:"Rectangle",
                            brand:brand,
                            label:label,
                            palletStyle:palletStyle,
                            pallet_name:pallet_name,
                        }
                        dispatch(palletLoading({ palletLoading: true }));
                
                        dispatch(addIsOnMouseUp({
                            isOnMouseUp: false,
                            labelName: null
                        }))
                
                        dispatch(
                            compareProject({
                                isCompare: true,
                            })
                        );
                        moveToLeftSection(data)
                    }
            }
            

                
        


        };

    

        const [dropdown, setdropdown] = useState(false);

        const [actiondropdown, setactiondropdown] = useState(true);
        const handledropdown = () => {
            setactiondropdown(!actiondropdown)
        }

        const [labelsdropdown, setlabelsdropdown] = useState(true);
        const handlelabels = () => {
            setlabelsdropdown(!labelsdropdown)
        }



        // edit segmentation
    const handleEditSegmentation = (selectedSegmentData) => {
        
        
        dispatch(
        addEditSegment({
            editSegment: selectedSegmentData,
        })
        );
        }


        // select swatch
        const handleSelectSwatch1=()=>{
            alert("please select swatch")
        }

        // view palletes
        const handleViewPallete=()=>{
        let selectedseg=  selectedSegmentDatas;
            if(selectedseg &&
                selectedseg.SelectedSwatch){
                    // dispatch(
                    //     addViewPalletInfo({
                    //       palleteInfo: segSwatches,
                    //       segName: segName,
                    //     })
                    //   );
                }
        }

        // handle ReAnnotation
        const handleReAnnotation=()=>{
            moveLeftReannotation2(selectedSegName,labelName)
        

        }
        // handle delete
        const [isDelete, setIsDelete] = useState(false);
        const [deleteLabelName, setDeleteLabelName] = useState();
        const [deleteSegName, setDeleteSegName] = useState();
        const [isOpenDelete, setIsOpenDelete] = useState();
        const handleDeletSegment=() => {
            dispatch(deleteDegment({
                segmentName:selectedSegName,
                isDeleted:true
            }))
            setIsOpenDelete(true);
            setDeleteLabelName(labelName);
            setDeleteSegName(selectedSegName);
        }

        // backTo mainPannel
        const handleBackToMainPannel=() => {
            dispatch(resetEditAnnotation())
            dispatch(moveToMainPannel({
                isBAckToPannel:true  
            }))
        }

        // handle close delete segment modal
    const handleDeleteCloseModal = () => {
        setIsOpenDelete(false);
    };

        //delete segemntation if yes
        const handleCloseModalSegmentDelete = () => {
        
            setIsOpenDelete(false);
            setIsDelete(true);
            dispatch(
            removeSegmentFromMAsterArray({
                segment: deleteLabelName,
                label: deleteSegName
            })
            );
        
            dispatch(
            removeSegmentFromSegments({
                label: deleteSegName,
            })
            );
            dispatch(
            deleteSegment({
                isDeleteSegment: true,
            })
            );
            dispatch(
            deleteMasterJob({
                labelName: deleteSegName,
            })
            );
            dispatch(
            deleteLoading({
                isDeleteSegmentLoading: true,
            })
            );
            moveToMainTabs()
        };

            // handle updated segment
    const [JobId, setJobIds] = useState();
    const [updatedJob, setUpdatedJob] = useState();
    const CurrentProjects = useSelector(getProjectId);
    const[ projectId, setProjectIds ] =useState(CurrentProjects.projectId)
    const handleUpdatedSegment = (updatedSeg) => {
        setUpdatedJob(updatedSeg);
        setJobIds(CurrentProjects.jobId);

        setIsDelete(false);
    };

    // if multi seg ment selected 
    const [masterName, setMasterName]= useState(null)
    const [TotalSeg, setTotalSeg]=useState(0)
    const getGridMultiSelects= useSelector(getGridMultiSelect)

    useEffect (()=>{

        if(getGridMultiSelects &&
            getGridMultiSelects.gridMultiSelect &&
            getGridMultiSelects.gridMultiSelect.length>1 &&
            getGridMultiSelects.labelName

            ){
                setTotalSeg(getGridMultiSelects.gridMultiSelect.length)
                setMasterName(getGridMultiSelects.labelName)
                setSelectedSegmentName(null)
            }else{
                setTotalSeg(0)
                setMasterName(null)
            }
        
    },[getGridMultiSelects])
        return (
            <div>


                <TabPanel className="select-swatch-section">




                    <div className='segment-select-tool-section seg-select-tool-section-da'>

                        <div className='select-seg-title-name'>
                            <img src="https://cdn-icons-png.flaticon.com/512/2722/2722991.png" onClick={handleBackToMainPannel}></img>
                            {/* <i class="bi bi-chevron-left" onClick={handleBackToMainPannel} ></i> */}


                            { TotalSeg === 0 && masterName === null ? <h2>{selectedSegmentName}</h2> :<h2>{masterName}+{TotalSeg}</h2> }


                            <div className='dropdown-icon-outline'>
                                <svg onClick={handledropdown} viewBox="0 0 24 24" focusable="false" class="chakra-icon chakra-accordion__icon css-186l2rg" aria-hidden="true"><path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>                           
                            </div>

                        </div>
            



            
                        {/* <div className='project-seg-actions'> */}
                        <Actions
                        editSegmentation={() =>
                            handleEditSegmentation(selectedSegmentDatas)
                        }


                        selectSwatch={() =>
                            handleSelectSwatch1()
                        }
                        searchViewPallete={() =>
                            handleViewPallete()
                        }
                        deleteSegments={() =>
                            handleDeletSegment()
                        }
                        reAnnotate={()=>
                            handleReAnnotation()}
                        />
            {/* show all segment Swatch */}
                        {actiondropdown &&
                        <div className='seg-swatch-img'>
                        <ShowSwatch
                        selectedSegName={selectedSegName}
                        selectedSegmentData={selectedSegmentDatas}
                        labelName={labelName} />   
                    
                        </div>             
                            }
                    </div>



                        <Tabs className='seg-labels-section'>
                            <span as="span" flex='1' textAlign='left'>
                                <TabList className='tab-pane'>
                                    <Tab className='seg-select-title-names'>Labels</Tab>
                                    <Tab className='seg-select-title-names'>Brands</Tab>
                                    <Tab className='seg-select-title-names'>Styles</Tab>
                                    <div className='seg-labels-dropdown'>
                                    <svg onClick={handlelabels} viewBox="0 0 24 24" focusable="false" class="chakra-icon chakra-accordion__icon css-186l2rg" aria-hidden="true"><path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
                                    </div>
                                </TabList>
                            </span>
                            {/* label-accordion-tabs */}
                            {labelsdropdown && <TabPanels className='label-accordion-tabpanel'>
                                <TabPanel className='tab-select-seg-content'>
                                    {
                                        getMasterArray &&
                                        getMasterArray.master &&
                                        getMasterArray.master.length > 0 &&
                                        getMasterArray.master.map((item) =>
                                            item.name === labelName ? (
                                                <div className="seg-label-section-dropdown">
                                                    {item.labels.map((label) => (
                                                        <div className="seg-label">
                                                            {/* active-label */}
                                                            <div className="seg-label-title ">
                                                                <h3>{label.name}</h3>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null
                                        )
                                    }
                                </TabPanel>
                                <TabPanel>
                                    <div className="seg-brand-button">
                                        {allBrands &&
                                            allBrands.length > 0 &&
                                            allBrands.map((brand) => (
                                                <div className="brand-title active">
                                                    <h3>{brand}</h3>
                                                </div>
                                            ))}
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="seg-brand-button">
                                        {allStyles &&
                                            allStyles.length > 0 &&
                                            allStyles.map((style) => (
                                                <div className="brand-title brand-btn-title active">
                                                    <h3>{style}</h3>
                                                </div>
                                            ))}
                                    </div>
                                </TabPanel>
                            </TabPanels> }
                        </Tabs>

                
                
                    <div className='select-seg-brand-popup'>
                        <div spacing={4} className='search-bar-section'>
                            <InputGroup className='pallet-search-bar'>

                                <Input className='serch-bar-input' placeholder='Pallet Name here' />
                                <InputRightElement >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
                                </InputRightElement>
                            </InputGroup>
                        </div>
                        <div className="seg-brand-icon">
                            <Popover>
                                <PopoverTrigger>
                                    <div className="brand-icon">
                                        <i class="bi bi-funnel"></i>
                                    </div>
                                </PopoverTrigger>
                                <div>
                                    <PopoverContent>

                                        <PopoverCloseButton />
                                        <PopoverBody>
                                            <div className="seg-Label-Contant">
                                                <h3 className="select-swatch-title" >Label</h3>
                                                <div className="seg-label">
                                                    {
                                                        getMasterArray &&
                                                        getMasterArray.master &&
                                                        getMasterArray.master.length > 0 &&
                                                        getMasterArray.master.map((item) =>
                                                            item.name === labelName ? (
                                                                <div >
                                                                    {item.labels.map((label) => (
                                                                        // active-label
                                                                        <div className="seg-label-title ">
                                                                            <h3>{label.name}</h3>
                                                                        </div>
                                                                    ))}
                                                                </div>) : null)}



                                                </div>

                                            </div>

                                            <div className="brand-section">
                                                <h3 className="segments-brand-title-name">Brands</h3>

                                                <div className="seg-brand-button">
                                                    {allBrands &&
                                                        allBrands.length > 0 &&
                                                        allBrands.map((brand) => (
                                                            <div className="brand-title active">
                                                                <h3>{brand}</h3>
                                                            </div>
                                                        ))}



                                                </div>
                                            </div>


                                            {/* style */}
                                            <div className="brand-section">
                                                <h3 className="segments-brand-title-name">Swatch style</h3>

                                                <div className="seg-brand-button">
                                                    {allStyles &&
                                                        allStyles.length > 0 &&
                                                        allStyles.map((style) => (
                                                            <div className="brand-title active">
                                                                <h3>{style}</h3>
                                                            </div>
                                                        ))}



                                                </div>
                                            </div>
                                        </PopoverBody>

                                    </PopoverContent>
                                </div>



                            </Popover>
                        </div>



                    </div>
                    {/* show all label based  */}

                    <div className="project-swatch-select-boxs">
                        <div className="swatch-images">

                            <div >

                                {allSwatches &&
                                    allSwatches.length > 0 &&
                                    allSwatches.map((swatch) => (
                                        <div className="select-pallet-image select-palllet-seg">
                                            <div key={swatch.id} className="pallet-img"
                                                onClick={() => handleSwatch(swatch?.image_s3, swatch?.id, 
                                                    swatch?.name,swatch?.id,swatch?.Type, swatch?.shape,
                                                    swatch.brand.name,swatch.label.name,swatch.swatch_style.name,swatch.name)}
                                            >
                                                {/* Tick marks  */}
                                                <ShowPalleteCheck
                                                selectedSegmentData={selectedSegmentDatas}
                                                allswatch={swatch}

                                                />
                                                
                                                {/* <div className='pallet-select-checkbox'>
                                                        <i class="bi bi-check-circle-fill"></i>
                                                    </div> */}
                                                <img src={swatch?.image?.url}

                                                ></img>
                                            </div>
                                            <div className="pallet-action">
                                                <Popover trigger="hover" >
                                                    <PopoverTrigger>
                                                        <div className="pallet-information-icon">
                                                            <i class="bi bi-info">  </i>
                                                        </div>
                                                    </PopoverTrigger>
                                                    <Portal>
                                                        <PopoverContent>

                                                            <PopoverBody>
                                                                <div className="pallet-img-inf-popup">
                                                                    <div className="pallet-images-inf "
                                                                        key={swatch?.image?.url}>
                                                                        <img src={swatch?.image?.url}></img>
                                                                    </div>

                                                                    <div className="pallet-images-details"
                                                                        key={swatch.name}
                                                                    >
                                                                        <h3>{swatch?.name}</h3>
                                                                        <h4>Brand : <span>Demo Brand Wall 1</span></h4>
                                                                        <h4>Label : <span>Demo Lable Wall</span></h4>
                                                                        <h4>Style : <span>Demo Wall Style </span></h4>
                                                                        <p>Model Size: 4'4" x 0'10" x 7'0"</p>
                                                                    </div>
                                                                </div>
                                                            </PopoverBody>

                                                        </PopoverContent>
                                                    </Portal>
                                                </Popover>
                                                <div className="pallet-fav">
                                                    <i class="bi bi-star"></i>
                                                </div>
                                            </div>
                                        </div>

                                    ))

                                }
                            </div>


                        </div>
                    </div>







                </TabPanel>

                {isOpenDelete && (
            <DeleteSegmentAlert
            isOpen={isOpenDelete}
            onClose={handleDeleteCloseModal}
            deleteSegment={handleCloseModalSegmentDelete}
            />
        )}
        {isDelete && <DeleteSegment deleteSegment={handleUpdatedSegment} />}
        
        {updatedJob && (
            <UpdateDeletedSegmentToDb updatedJob={updatedJob} JobId={JobId} />
        )}
            </div>
        )
    }

    export default SelectSwatch



import React, { useState, useEffect } from "react";
import useFetch from "../Hook/useFetch";
import { useQuery, gql, useMutation } from "@apollo/client";
import { UPDATE_JOB } from "../GraphQL/GraphQl";
import { selectAllItems } from "../slices/MasterArraySlice";
import { useSelector } from "react-redux";
import axios from "axios";
const AddSegement = ({ isUpdateSegment }) => {
  const getALLMasterArrays = useSelector(selectAllItems);

  const [updateJob, setUpdateJob] = useState(false);
  const [getJobId, setGetJobId] = useState();

  let segmentArray = [];
  useEffect(() => {
    if (isUpdateSegment) {
      setUpdateJob(true);
    }
  }, [isUpdateSegment]);

  useEffect(() => {
    let jobId = localStorage.getItem("JobId");
    if (updateJob) {
      if (getALLMasterArrays && getALLMasterArrays.length > 0) {
        getALLMasterArrays[0].map((item) => {
          if (item && item.segementArray) {
            item.segementArray.map((seg) => {
              segmentArray.push(seg);
            });
          }
        });
      }

      updateJobs(segmentArray, jobId);
    }
  }, [getALLMasterArrays, updateJob]);

  const updateJobs = async (segmentArray, jobId) => {
    let updateJob = [];
    segmentArray.map((item) => {
      let swatchs = [];
      if (item.SelectSwatchJob && item.SelectSwatchJob.length > 0) {
        item.SelectSwatchJob.map((swatch) => {
          swatchs.push({
            swatch: swatch?.swatch?.data?.id,
            SegmentImage: swatch?.SegmentImage?.data?.id,
          });
        });
      }
      let sss = item.annotationPolygon;
      updateJob.push({
        name: item.name,
        confidence: item.confidence,
        area_pixel: item.area_pixel,
        annotationPolygon: sss.toString(),
        BoundingBoxFloat: item.BoundingBoxFloat,
        BoundingBoxInt: item.BoundingBoxInt,
        SelectSwatchJob: swatchs,
      });
    });
    // (updateJob);
    let data1 = {
      data: {
        Segmentation: updateJob,
      },
    };
    try {
      const responce = await axios.put(
        process.env.REACT_APP_API_STRAPI + "jobs/" + jobId,
        data1
      );

      // (responce);
      if (responce.statusCode === 200) {
        setUpdateJob(false);
      }
    } catch (e) {}
  };
  return <div></div>;
};

export default AddSegement;

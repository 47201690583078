import React, { useState, useEffect } from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import "./DeletingLoading.css";
import { Spinner } from "@chakra-ui/react";

const DeletingLoading = () => {
  return (
    <div className="uplaod-loding">
      <Alert mt={15}>
        {/* <AlertIcon /> */}
        <Spinner />
        Updating segments...
      </Alert>
    </div>
  );
};

export default DeletingLoading;

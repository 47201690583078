import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { fabric } from "fabric";
import {getProjectId} from "../../slices/ProjectSlice"
import{selectAllSegments} from "../../slices/AllSegmentSlice"
import {getCanvasSize} from "../../slices/windowSizeSlice/WindowResolution.js"
const OutLineLayer = () => {
    const dispatch= useDispatch()
    const canvasRef = useRef(null);
    const getCanvasSizes= useSelector(getCanvasSize)
    const [scaleX, setScalex]= useState(null);
    const [scaleY, setScaley]= useState(null);
    useEffect(() => {
      if(getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null

        ){
          var canvas = new fabric.Canvas("outline-layer", {
            selectionBorderColor: 'green',
            width: getCanvasSizes.canvasWidth, // Set canvas width
          height:getCanvasSizes?.canvasHeight 
          });
       
          canvasRef.current = canvas; 

        }
 
    
       }, []);

       const getProjectImage=useSelector(getProjectId);
useEffect(()=>{
    //
      if(getProjectImage&&
          getProjectImage.base64Image&&
          canvasRef.current){

            let image = getProjectImage?.base64Image;
      const encodedImage = "data:image/png;base64," + image;
      // Decode the base64 image
      const decodedImage = atob(encodedImage.split(",")[1]);
      // Convert the decoded data to a Uint8Array
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }
      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "image/png" });
      // Create an object URL from the Blob
      let imageUrl = URL.createObjectURL(blob);
  
      var img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {}
//  let imageUrl=getProjectImage?.projectImage
fabric.Image.fromURL(imageUrl, function (img) {
  canvasRef.current.setBackgroundImage(imageUrl, canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
    scaleX: canvasRef.current?.width / img?.width,
    scaleY: canvasRef.current?.height / img?.height,
    width:canvasRef.current.width,
    height:canvasRef.current.height,
    originX: "left",
    originY: "top",
  
    });
    setScalex(canvasRef.current.width / img.width);
    setScaley(canvasRef.current.height / img.height);
    
  
  canvasRef.current.requestRenderAll();
    
})

          }

   
   },[canvasRef,getProjectImage]);


   const getAllPolygon=useSelector(selectAllSegments)
   useEffect(()=>{
    if(canvasRef.current &&
      getAllPolygon&&
          getAllPolygon.allSegmentsArray&&
          getAllPolygon.allSegmentsArray.length>0
      ){
       
        collectPoints()
      } else if( getAllPolygon?.allSegmentsArray&&
        getAllPolygon?.allSegmentsArray.length===0){
        //   dispatch(palletLoading({ palletLoading: true }));
        }
   },[canvasRef.current,getAllPolygon])

   const collectPoints=()=>{
    if(getAllPolygon&&
      getAllPolygon.allSegmentsArray&&
      getAllPolygon.allSegmentsArray.length>0){
    
    getAllPolygon.allSegmentsArray.map(item=>{
  
        let polyName=item.name;
        let allPoly = item?.annotationPolygon
         .split(",")
         .map((value) => parseInt(value.trim(), 10));
           
         let point = [];
       let i;
       for ( i = 0; i < allPoly.length; i += 2) {
        const x = allPoly[i]*scaleX;
        const y = allPoly[i + 1]*scaleY;
         point.push({ x, y });
       }
        
       let coordinate=item.BoundingBoxInt
       .split(",")
         .map((value) => parseInt(value.trim(), 10));
       
         makePolygon(point,coordinate,polyName)
     
   
    })

      } 
      
    }

    const makePolygon=(point,coordinate, polyName)=>{
        if(point&&
          point.length>0&&
          coordinate&&
          polyName
          ){

          

            
          let polygon = new fabric.Polygon(point, {
            name:polyName,
          
            left: coordinate[0]*scaleX,
            top: coordinate[1]*scaleY ,
            fill:"transparent",
          
            originX: "left",
            originY: "top",
            hasRotatingPoint:false,
            hasBorders:false,
            hasControls:false,
            stroke: "rgb(7 239 253)",
            strokeWidth: 2,
            opacity:"1",
       
            lockMovementX:true,
            lockMovementY:true,
            // shadow:shadow

           
          });
         
          const group = new fabric.Group([polygon], {
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            hasRotatingPoint: false,
            hasBorders: false,
            hasControls: false,
            name: polyName,
          });
      
          canvasRef.current.add(group);
          
        
          canvasRef.current.requestRenderAll();

         
          
        }
      }
       
  return (
    <div className="ai-right-wrapper-body  Cursor project-layer-images">
    <h3 className='combo-layer-title'>Outline layer</h3>
    <canvas  id="outline-layer" className="dzi-van"  width="1023" height="592"  ref={canvasRef}></canvas>
    </div>
  )
}

export default OutLineLayer
import React,{useState, useEffect} from 'react'
import "./ImageSection.css";
import { Tooltip } from '@chakra-ui/react'
import Tools from  "./tools/Tools";
import HoverLayer from "../../ProjectLayer/HoverLayer/HoverLayer";
import { useSelector, useDispatch } from 'react-redux';
import EditAnnonation from "../../ProjectLayer/EditAnnotation/EditNewAnnonation"

import {getEditAnnotation,
  removeEditSegment,
  getSaveAnnotation,
  getISDiscard,
  discardAnnotation} from "../../slices/EditSegment/EditSegment.js"
 
import ReAnnotationPage from "../../ReAnnotation/ReAnnotationPage"
import {getStartAnnotation,SavaDataToDb,getStartAddSegment,getShowAddedSegment} from "../../slices/EditSegment/EditSegment.js"

import {samLoading} from "../../slices/AlertSlice.js" 
import Compareicon from "../../NewDesign/compareImage/Compare.js";
import CombinationLayer from "../../ProjectLayer/CombinationLayer/CombinationLayer"
import {getRenderCombination,getShowCombo} from "../../slices/combinationSwatch/CombinationSwatchSlice.js"
 
import Report from "../../ImageView/Report.js";
import html2canvas from "html2canvas";
import {getSwitchHoverLayer,switchToHoverLayer} from "../../slices/gridView/MultiSelectionSegement.js"

import OutLineLayer from "../../ProjectLayer/outlineLayer/OutLineLayer.js"

import {GetOutLineMode} from "../../slices/gridView/MultiSelectionSegement"

import Threshold  from "../rightsection/tools/Threshold"
import {getThreshold} from "../../slices/threshold/ThresholdSlice.js"

const ImageSection = () => {

  const [isHoverLayer, setIsHoverLayer]= useState(true)
  const [isEditAnnonation, setIsEditAnNonation] = useState(false);
  const [isReAnnotation, setIsReAnnotation] = useState(false)
  const [iscompareicon, setIscompareicon] = useState(false)
  const[isCombinationLayer, setIsCombinationLayer]= useState(false)
  const[isOutlineLayer, setIsOutlineLayer]= useState(false)
  const dispatch= useDispatch()

  const handleCallAPI=()=>{
    setIsEditAnNonation(false);
      setIsHoverLayer(true)
  }


  // edit Annoatation
  const getEditAnnoation = useSelector(getEditAnnotation);
  useEffect(() => {
     //
    if (getEditAnnoation) {
    
      setIsEditAnNonation(true)
      setIsHoverLayer(false)
      setIsReAnnotation(false)
      setIscompareicon(false)
      setIsOutlineLayer(false)
      setIsCombinationLayer(false)
      
    }else if(!getEditAnnoation){
      handleCloseEditAnnonation()
    }
  }, [getEditAnnoation]);

  const handleCloseEditAnnonation=()=>{
    setIsEditAnNonation(false);
      setIsHoverLayer(true)
  }

  // after save Annottaion clicked yes
  const getSaveAnnotations= useSelector(getSaveAnnotation)


  useEffect(()=>{
    if(getSaveAnnotations){
      setIsEditAnNonation(false);
      setIsReAnnotation(false)
      setIsHoverLayer(true)
      dispatch(removeEditSegment())
      // dispatch(samLoading({
      //   samLoading:true
      // }))
      // dispatch(SavaDataToDb({
      //   isAdddataToDB: true
      // }))
    }
  },[getSaveAnnotations])

  // start Annotation on adding new segment
const getStartAnnotations =useSelector(getStartAddSegment)
useEffect(()=>{

  if(getStartAnnotations){
    setIsEditAnNonation(false);
      setIsReAnnotation(true)
      setIsHoverLayer(false)
      setIsCombinationLayer(false)
      setIsOutlineLayer(false)
      setIscompareicon(false)
  }
},[getStartAnnotations]) 


// after discrad button click 
const getISDiscards= useSelector(getISDiscard)
useEffect(()=>{
  if(getISDiscards){
    setIsEditAnNonation(false);
    setIsReAnnotation(false)
    setIsHoverLayer(true)

    dispatch(discardAnnotation({
      isDiscard:false
    }))
  }
},[getISDiscards])

// after click save segment 

const getShowAddedSegments= useSelector(getShowAddedSegment)

useEffect(()=>{

  if(getShowAddedSegments){
    setIsEditAnNonation(false);
      setIsReAnnotation(false)
      setIsHoverLayer(true)
  }
},[getShowAddedSegments]) 




const handlecompare =()=>{
  if(!iscompareicon){
    setIscompareicon(true)
  setIsHoverLayer(false)
  setIsCombinationLayer(false)
  setIsOutlineLayer(false)
  setIsCombinationLayer(false)
  } else{

    setIscompareicon(false)
  setIsHoverLayer(true)
  setIsCombinationLayer(false)
  setIsOutlineLayer(false)
  setIsCombinationLayer(false)
  }
 

}

// close ReAnnotation page
const handleCloseReAnnotationPage=()=>{
  setIsReAnnotation(false);
  setIsHoverLayer(true)
  dispatch(removeEditSegment())
}

const changeCanvasMode=()=>{
  if(!isHoverLayer){
    setIsHoverLayer(true)
    setIsReAnnotation(false)
    setIsEditAnNonation(false)
    setIscompareicon(false)
    setIsCombinationLayer(false)
    setIsOutlineLayer(false)
  }
  
}

// open combination layer
const getCombinationstart= useSelector(getShowCombo)


useEffect(()=>{
   if(getCombinationstart 

    ){
      setIsHoverLayer(false);
      setIsCombinationLayer(true)
      setIsEditAnNonation(false);
      setIsReAnnotation(false);
      setIsOutlineLayer(false)
      setIscompareicon(false)
    }else{
      setIsHoverLayer(true);
      setIsCombinationLayer(false)
    }
},[getCombinationstart])

const [isReport, setisReport] = useState(false);
const handleReport = () => {
  setisReport(true);
};


const HandleCloseReport = () => {
  setisReport(false);
};

const [projectNameValue, setProjectNameValue] = useState("");
const handleCallToImage = (projectName) => {
  setProjectNameValue(projectName);
};

const captureScreenshot = () => {
  
  // Get the div element you want to capture
  const elementToCapture = document.querySelector(".ai-right-wrapper-body");
  const randomNum = Math.floor(Math.random() * 1000) + 1;
  const fileName = `${projectNameValue}_${randomNum}.png`;

  // Use html2canvas to capture the content of the div
  html2canvas(elementToCapture).then((canvas) => {
    // Convert the canvas to an image data URL
    const imageDataURL = canvas.toDataURL("image/png");

    // Create a hidden download link for the image
    const downloadLink = document.createElement("a");
    downloadLink.href = imageDataURL;
    downloadLink.download = fileName;
    downloadLink.style.display = "none"; // Hide the link

    // Add the link to the document and trigger a click event to download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up: Remove the download link and canvas from the DOM
    document.body.removeChild(downloadLink);
    canvas.remove();
  });
};


 // swicth to hover layer after selcting segment tabs
 const getSwitchHoverLayers= useSelector(getSwitchHoverLayer)
 useEffect(()=>{
   if(getSwitchHoverLayers){
     setIsHoverLayer(true)
     setIsCombinationLayer(false)
     setIsEditAnNonation(false);
     setIsOutlineLayer(false)
     dispatch(switchToHoverLayer({
      ishoverLayer:false
     }))
   }
 },[getSwitchHoverLayers])

 //active/ eactive  outline layer 
 const getOutLine=useSelector(GetOutLineMode);

useEffect(()=>{
  if(getOutLine){
    setIsOutlineLayer(true)
    setIsHoverLayer(false)
    setIsCombinationLayer(false)
    setIsEditAnNonation(false);
    setIscompareicon(false)
  }else{
    setIsHoverLayer(true)
    setIsCombinationLayer(false)
    setIsEditAnNonation(false);
    setIsOutlineLayer(false)
  }
},[getOutLine])


const getThresholds= useSelector(getThreshold)
   console.log("getThresholds",getThresholds)
  return (
    <div className='right-project-layer-img'>
      <div className='project-image'>
      {" "}
          {isHoverLayer&&< HoverLayer/>}

        {isEditAnnonation && <EditAnnonation
        moveToImageView={handleCloseEditAnnonation}
        />}


        {isReAnnotation &&
        <ReAnnotationPage
        moveToHoverLayer={handleCloseReAnnotationPage}
        />}
       {iscompareicon && <Compareicon/>}

       {isCombinationLayer &&
        <CombinationLayer/>}
       {isOutlineLayer &&
        <OutLineLayer/>}

      </div>

    


      <div className='select-toolbar-section'>
    
    <Tools 
    compare={handlecompare}
    
    canvasMode={changeCanvasMode}
    report={handleReport}
    screenShot={captureScreenshot}
    />

   { isReport &&<Report isOpen={isReport} onClose={HandleCloseReport} />}
       
 


      </div>
    </div>



  )
}

export default ImageSection;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  updateSegment: false,
};

const UpdateSegmentSlice = createSlice({
  name: "updateSegment",
  initialState,
  reducers: {
    addUpdateSegment: (state, action) => {
      state.updateSegment = action.payload.updateSegment;
    },
  },
});

export const { addUpdateSegment } = UpdateSegmentSlice.actions;
export const getUpdateSegment = (state) => state.updateSegment.updateSegment;
export default UpdateSegmentSlice.reducer;

import React from "react";
import MainTab from "../Tabs/MainTab";
const LeftSection = () => {
  return (
    <>
      <MainTab />
    </>
  );
};

export default LeftSection;

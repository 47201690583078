import React, { useEffect, useState } from "react";
import axios from "axios";
import { addCurrentTab } from "../slices/CurrentTabSlice";
import { addProjectId, getProjectImage,projectCreated } from "../slices/ProjectSlice";
import { useSelector, useDispatch } from "react-redux";
import { addJobrArray } from "../slices/JobArratSlice";
import ReloadJobs from "./ReloadJobs";
import { nullSegmentValue } from "../slices/LoadingSlice";
import { getModelVersion } from "../slices/Model/ModelSlice";
import {addProjectUrl} from "../slices/addProject/AddProjectSlice"

import {addJobDetails} from "../slices/addProject/AddProjectSlice" 
import {getNewProjectDetails} from "../slices/addProject/AddProjectSlice"
const SegredateSamData = ({ samDataFromAPI, resetsamDataFromAPI }) => {
  const [jobData, setJobData] = useState();
  const [base64image, setBase64Image] = useState();
  const [isJob, setIsJob] = useState(true);
  const [samData, setsamData] = useState();
  const dispatch = useDispatch();
  const getProjectImages = useSelector(getProjectImage);
  const getModelVersions = useSelector(getModelVersion);
  // console.log("getProjectImages", getProjectImages);
  // console.log("getModerlVersions", getModelVersions);
  const [projectID, setProjectID]= useState(null)
  const [projectName, setProjectName]= useState(null)
  const getNewProjectDetail= useSelector(getNewProjectDetails)
  useEffect(() => {
    
    if (samDataFromAPI!=null &&
      getNewProjectDetail &&
      getNewProjectDetail?.projectId!=null &&
      getNewProjectDetail?.projectName!=null
      ) {
      setsamData(samDataFromAPI);
      setProjectID(getNewProjectDetail?.projectId)
      setProjectName(getNewProjectDetail?.projectName)
    }
  }, [samDataFromAPI,getNewProjectDetail]);

  useEffect(() => {
    if (samData != null) {
      handleSamData();
      addProjectImage();
      
    }
  }, [samData]);

  // upload project image 

  const addProjectImage = async () => {
    //let projectDetails = JSON.parse(sessionStorage.getItem("CreateProject"));
      //add new project details
      dispatch(addProjectUrl({
        projectImageUrl:samData.ImageUrl
      
      }))
    try {
      let data = {
        data: {
          ImageBase64: samData.EncodedImage,
          ProjectImageUrl:samData.ImageUrl
        },
      };
      const saveBase64 = await axios.put(
        process.env.REACT_APP_API_STRAPI + "projects/" + projectID,
        data
      );
    } catch (error) {}
  };

  // add new job to DB
  const handleSamData = () => {
    
    let areaOfLabelsBbox;
    let labelsConfidence;
    let segmentationClass;
    let bboxWithoutFloat;
    let labelsBbox;
    let polyPerimeter;
    let segmentation = [];

    if (samData) {
      areaOfLabelsBbox = samData.AreaOfLabelsBbox || {};
      labelsConfidence = samData.LabelsConfidence || {};
      segmentationClass = samData.Segmentation_Class || {};
      bboxWithoutFloat = samData.bboxwithoutFloat || {};
      labelsBbox = samData.LabelsBbox || {};
      polyPerimeter =samData.PerimeterOfLabelsBbox|| {};

      segmentation = [];
      for (let key in labelsConfidence) {
        if (
          labelsConfidence.hasOwnProperty(key) &&
          areaOfLabelsBbox.hasOwnProperty(key) &&
          bboxWithoutFloat.hasOwnProperty(key) &&
          segmentationClass.hasOwnProperty(key) &&
          labelsBbox.hasOwnProperty(key)&&
          polyPerimeter.hasOwnProperty(key) 
        ) {
          const name = key;
          const confidence = labelsConfidence[key];
          const area_pixel = areaOfLabelsBbox[key];
          const BoundingBoxInt = bboxWithoutFloat[key];
          const annotationPolygon = segmentationClass[key];
          const BoundingBoxFloat = labelsBbox[key];
          const perimeter=polyPerimeter[key]

          segmentation.push({
            name: name,
            area_pixel: area_pixel.toString(),
            BoundingBoxInt: BoundingBoxInt.toString(),
            confidence: confidence,
            annotationPolygon: annotationPolygon.toString(),
            BoundingBoxFloat: BoundingBoxFloat.toString(),
            perimeter:perimeter.toString()
          });
        }
      }

      setsamData(null);
      resetsamDataFromAPI();
      dispatch(addJobrArray(segmentation));
      setJobData(segmentation);
      setIsJob(false);
      AddJobToDB(segmentation, samData.EncodedImage);

      
      
    }
  };
  const [JobId, setJobId] = useState();
  useEffect(() => {
    if (jobData) {
      console.log("job data")
    }
  }, [jobData]);

  const AddJobToDB = async (jobs, base64image) => {
   console.log("AddJobToDB called"); // Add this line
    try {
       ;
      let project_Image= sessionStorage.getItem("projectImage");
     // let projectDetails = JSON.parse(sessionStorage.getItem("CreateProject"));
      if (projectID) {
        ;
        let projectdata = {
          project: projectID,
          JobView: "Front",
          JobType: "Design",
          Segmentation: jobs,
          JobImage: getProjectImages.imageId,
          FinalLayerJson: JSON.stringify(samDataFromAPI),
          model: getModelVersions.modelId,
          ModelVersion: getModelVersions.modelVersion,
        };
        let data = {
          data: projectdata,
        };
        if (jobs.length > 0 && data != null) {
          // Check if the JobId is not set to prevent duplicate calls
          if (!JobId) {
            const jobData = await axios.post(
              process.env.REACT_APP_API_STRAPI + "jobs",
              data
            );
            data = null;

            if (jobData.status === 200) {
             // add jobDetails
             dispatch(addJobDetails({
              data:{

                  id:jobData.data.data.id
                
              }
             }))

              let jobId = jobData.data.data.id;
              let projectDetail = {
                projectId: projectID,
                base64Image: base64image,
                projectName: projectName,
                jobId: jobData.data.data.id,
                reload: true,
              };
              sessionStorage.setItem(
                "ProjectDetails",
                JSON.stringify(projectDetail)
              );
              
                dispatch(projectCreated({
                  isProjectCreated:false
                }))

              dispatch(
                addProjectId({
                  projectId: projectID,
                  base64Image: base64image,
                  projectName: projectName,
                  jobId: jobData.data.data.id,
                  reload: true,
                  projectImage:project_Image

                })
              );
              dispatch(
                nullSegmentValue({
                  deleteSegmentValue: true,
                })
              );
              dispatch(addCurrentTab(0));
              setJobId(jobId);
              //sessionStorage.removeItem("CreateProject");
            }
          }
        }
      }
    } catch (e) {
      alert(e.message);
    }
  };

  // reset the job Id
  const handleResetData = () => {
    setJobId(null);
  };

  return (
    <>
      <ReloadJobs JobId={JobId} resetData={handleResetData} />
    </>
  );
};

export default SegredateSamData;

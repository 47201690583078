import React from 'react'
import {useEffect, useState} from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Checkbox
  } from '@chakra-ui/react'
import {useDispatch, useSelector} from "react-redux";
import {getAllShadows} from "../../../../slices/shadow/ShadowSlice"
import SliderShadow from "./SliderShadow"
import {hideShadow} from "../../../../slices/shadow/ShadowSlice"
import "./SliderShadow.css";
const Shadows = () => {
    const dispatch= useDispatch();
    const [isHighlighted, setIsHighlighted] = useState(true);
    const [opacityValue , setOpacityValue] = useState(0.5);
    const getAllShadowss= useSelector(getAllShadows)
   

    const handleOpacity=(val)=>{
      setOpacityValue(val)
    }
  return (
    <div>
    {getAllShadowss &&
     getAllShadowss.allShadow && 
     getAllShadowss.allShadow.length > 0 ? (
      <Accordion className='seg-shadow' allowMultiple>
        {getAllShadowss.allShadow.map((item) => (
          <AccordionItem key={item.label} className="accordion-seg shadow-seg-box">
            <AccordionButton className="select-seg-dropdown"
          
            >
              <div className="project-seg-box seg-shaw-box"
            
             >
              <div className='seg-shaw-title'>  <span className="segements-title">{item.label}</span>
              </div>

                <div class="dzinly-ai-dropdown seg-shaw-detail"
               
                >
                  <div className='shadow-info'>
                <i class="bi bi-three-dots"></i>
                </div>
                  <div class="seg-show-drop">
                     <h4 className='delete-btn'>   Delete </h4>
                    </div>
                    </div>


                <AccordionIcon className="dropdown-icon" />
              </div>
            </AccordionButton>
            <AccordionPanel className='shadow-content-box' pb={4}>
              <div className="project-swatch-select-boxs shadow-content">
                <div className="seg-shaw-track-content">
         
                  <SliderShadow
                  shadowName={item.label}
                 
                  />
                  
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    ) : (
      <>
     <div className='no-shadow-section'>
    <img  className="no-shadow-image"  src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_gallery_1_ab2f499bd3.png'></img>
    <h2 className='no-shadow-title'>
      No Shadow Available
      </h2>
      </div>
      </>
    )}
  </div>
  )
}

export default Shadows
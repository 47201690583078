import React from 'react'
import {useEffect, useState} from "react";
import {useSelector} from "react-redux"
import { getProjectId } from "../slices/ProjectSlice";
import{getCreateProject} from "../slices/CreateProjectImageSlice"
import "../ImageView/ImageView.css"
import "./CreateProjectImage.css";
import Alert from "../ImageView/Alert";
import SamLoading from "../Alert/SamLoading"
;
import {getSamLoading} from "../slices/AlertSlice"
const CreateProjectImage = () => {
  const imageFile = localStorage.getItem('imageFile');
    const[samload, setSamLoading]= useState(false)
    const getCreateProjectImage = useSelector(getCreateProject);
 
    const [selectedImage, setSelectedImage]= useState()
    const stopLoading= useSelector(getSamLoading)
    useEffect(() => {
        //
        if (imageFile) {
          const base64String = localStorage.getItem('imageFile');
          setSelectedImage(base64String);
          //setSelectedImage(imageFile)
          
        
        }
      }, [imageFile]);

      //stop loading 
      useEffect(()=>{
        if(!stopLoading){
          setSamLoading(false)
        }else if(stopLoading){
          setSamLoading(true)
        }
      },[stopLoading])

    
  return (
    <div className="ai-right-wrapper-body Cursor  project-layer-images create-pro-layer">
        
       { selectedImage&&<img  className='Create-pro-img'  src={selectedImage} alt='no image selected'/>}
        {/* <Alert /> */}
        {samload && <SamLoading />}
    </div>
  )
}

export default CreateProjectImage
import React, { useEffect } from 'react';

const GenAiTest = () => {
  useEffect(() => {
    // Load the web component script when the component mounts
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://gradio.s3-us-west-2.amazonaws.com/4.26.0/gradio.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <gradio-app src="https://coderforchange-genaihouse.hf.space"></gradio-app>
    </div>
  );
};

export default GenAiTest;

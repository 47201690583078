import React from "react";
import { CircularProgress, Alert, AlertIcon } from "@chakra-ui/react";
import "./AlertAndSpinnerPage.css";
import { Spinner } from '@chakra-ui/react'
const AlertAndSpinnerPage = () => {
  return (
    <div>
      <div className="login-progress-alert">
        <CircularProgress isIndeterminate color="green.300" />
      
        <Alert className="login-progress-popup" mt={4}>  <Spinner /> Login is in progress ....</Alert>
      </div>
    </div>
  );
};

export default AlertAndSpinnerPage;

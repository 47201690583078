import React ,{useEffect, useState}from 'react'
import { Tooltip, Box, Button } from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import{saveAnnotation,getEditAnnotation} from "../../slices/EditSegment/EditSegment"
import {outLineMode,GetOutLineMode} from "../../slices/gridView/MultiSelectionSegement"
const Tools = ({canvasMode,
                canvasMode2,
                noise,
                outLine,
                compare,
                annotation,
                undo,
                reset,
                FinalLayer,
                screenShot,
                report,
                hoverLayer
            }) => {

    const [isProjectLayer, setIsProjectLayer] = useState(true);
  const [isFinalLayer, setIsFinalLayer] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isOutLine, setIsOutLine] = useState(false);
  const[editActive, setEditActive] = useState(false);
  const getOutLine= useSelector(GetOutLineMode)
  const navigate = useNavigate();
  const dispatch=useDispatch()

            //canvas mode
            const handleProjectLayerChange=() => {
                
                setIsProjectLayer(!isProjectLayer);
                setIsCompare(false)
                canvasMode()
    
            }


            // canvas mode 2  ie edit annotation
            const handleToggleEditing=()=>{
                canvasMode2()
            }

            //noie reduction
            const handleNoise=()=>{
                noise()
            }


            // outilne
            const handleOutline=()=>{ 
              //
                if(!getOutLine){
                  dispatch(outLineMode({
                    isOutLine:true
                  }))

                }else{
                  dispatch(outLineMode({
                    isOutLine:false
                  }))

                }
            
            }
            // compare 
            const handleSwitchChange=()=>{
                compare()
            }

            // start Annotation

            const handleAnotation=()=>{
                annotation()
            }

            // unodo
            const handleUndo=()=>{
                undo()
            }

            //reset
            const handleReset=()=>{
                reset()
            }

            // final Layer
            const handleFinalLayer=()=>{
                FinalLayer()
            }
            //captureScreenshot
            const captureScreenshot=()=>{
                screenShot()
            }

            // handleReport
            const handleReport=()=>{
                report()
            }

            const handleNewDesign=()=>{
                 navigate("/projectdraft");
            }

            // save Annnottaion
            const getIsEditAnnotation=useSelector(getEditAnnotation)
            
            useEffect(()=>{

              if(getIsEditAnnotation){
                setEditActive(true)
              }
            },[getIsEditAnnotation])
            const handleSaveAnnotation=()=>{
              if(getIsEditAnnotation){
                setEditActive(false)
                dispatch(saveAnnotation({
                  isSaveAnnotation:true,
                  isEditAnnotation:false
                }))
              } else{
                
              }
            }

            //handle hover layer

            // const handleHoverLayer=()=>{
            //   hoverLayer()
            // }

 return (<>


    <div className="demo-select-icon demo-select-icons">

        
    <div className="tool-wrapper">
      <div className="select-icon annotation-select-icons">

      


      <Tooltip
          label="Canvas Mode"
          // className={isProjectLayer ? ".active-icon" : ""}
          className="select-tooltip-title"
        >
          <div className={isProjectLayer ? "active-icon" : ""}>
            <i
              class="bi bi-intersect"
             onClick={handleProjectLayerChange}
            ></i>
          </div>
        </Tooltip>

        {/* <Tooltip label="Canvas Mode2" className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
           
            <i class="bi bi-exclude" 
            onClick={handleHoverLayer}
            
            ></i>
           
          </div>
        </Tooltip> */}


        <Tooltip label="Noise Reduction" className="select-tooltip-title">
          <div className={isFinalLayer ? "active-icon" : ""}>
            <i class="bi bi-eraser" 
            onClick={handleNoise}
            ></i>
          </div>
        </Tooltip>

        <Tooltip
          label="Outlines"
          className="select-tooltip-title outline-title"
        >
          <div className={isOutLine ? "active-icon" : ""}>
            <i class="bi bi-border"
            onClick={handleOutline}
             ></i>
          </div>
          {/* <img src=""></img> */}
        </Tooltip>

        <Tooltip label="Compare" className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
            <i
              class="bi bi-chevron-bar-contract"
              onClick={handleSwitchChange}
            ></i>
          </div>
        </Tooltip>


      </div>


      {/* annotaion icon start */}

      <div className="select-icon select-annotaion-icon-project">
        <Tooltip label="Annotation" className="select-tooltip-title">
          <div className={editActive ? "is-edit-active" : ""}>
          <i class="bi bi-bounding-box-circles" onClick={handleAnotation}></i>
          </div>
        </Tooltip>


             <Tooltip label="Save Annotaion" className="select-tooltip-title">
                <div className={editActive ? "is-edit-active" : ""} 
      onClick={handleSaveAnnotation}>
          <i class="bi bi-save" ></i>


           
          </div>
      </Tooltip>



        <Tooltip label="Undo" className="select-tooltip-title">
          <div className={editActive ? "is-edit-active" : ""}>
            <i class="bi bi-arrow-left-square" onClick={handleUndo}></i>
          </div>
        </Tooltip>

        <Tooltip label="Reset" className="select-tooltip-title">
          <div className={editActive ? "is-edit-active" : ""}>
            <i class="bi bi-arrow-repeat" onClick={handleReset}></i>
          </div>
        </Tooltip>
{/* 
        <Tooltip  className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
          <i  className="bi bi-pencil-square"           
            ></i>
          </div>
        </Tooltip> */}


        <Tooltip  className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
           <i className="bi bi-arrows-fullscreen" 
               onClick={handleNewDesign}
          ></i>
          
          </div>
        </Tooltip>
        <Tooltip  className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
          <i className="bi bi-pentagon" ></i>
          </div>
        </Tooltip>

        <Tooltip label="View Projects" className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
            <i class="bi bi-house-gear"></i>
          </div>
        </Tooltip>



      </div>

      {/* annotaion icon End */}

       {/* model icon start */}
      <div className="select-icon select-project-icon">


       <Tooltip label="Model" className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
       
          </div>
        </Tooltip>

        <Tooltip label="Final Layer" className="select-tooltip-title">
          <div className={isFinalLayer ? "active-icon" : ""}>
            <i class="bi bi-layers-fill" onClick={handleFinalLayer}></i>
          </div>
        </Tooltip>

        <Tooltip label="Download Image" className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
            <i className="bi bi-download" onClick={captureScreenshot}></i>
          </div>
        </Tooltip>

        <Tooltip label="Report" className="select-tooltip-title1">
          <div className={isCompare ? "active-icon" : ""}>
            <i class="bi bi-file-earmark-ppt" onClick={handleReport}></i>
          </div>
        </Tooltip>

        <Tooltip label="Information" className="select-tooltip-title">
          <div className={isCompare ? "active-icon" : ""}>
            <i class="bi bi-info-lg" ></i>
            {/* onClick={handleTesting} */}
          </div>
        </Tooltip>

      </div>
       {/* model icon end */}
    </div>
  </div>
  </>
  

  )
}

export default Tools
import { createSlice } from "@reduxjs/toolkit";

const MediaLibrarySlice = createSlice({
    name: "mediaLibrary",
    initialState: {
        tabIndex: null,
        istabIndex:false
      },
      reducers:{
        addTabIndex:(state,action)=>{
          state.istabIndex=action.payload.istabIndex
            state.tabIndex=action.payload.tabIndex
        }
      }
    })

    export const { addTabIndex } = MediaLibrarySlice.actions;

    export const getTabIndex=state => state.mediaLibrary;

    export default MediaLibrarySlice.reducer
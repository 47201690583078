import React from 'react'
import "../../NewDesignHomePage.css";

import {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';

import {openCreatePorjectModal} from "../../../slices/CreateProjectHeaderSlice/CreateProjectHeaderSlice"
const CreateProject = () => {

  const[isProjectCreated, setIsprojectCreated]= useState(false)


   const dispatch= useDispatch()
  const handleProjectCreated=()=>{
    dispatch(openCreatePorjectModal({
      isProjectCreated:true,

    }))
    
  }
  return (

   
 <>
 <button class="create-project-btn"  onClick={handleProjectCreated}>
                  Create Project
                  <i class="bi bi-cloud-arrow-up"></i>
                </button>


 </>


  )
}

export default CreateProject
import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import{getProjectId} from "../../slices/ProjectSlice";
import { fabric } from "fabric";
import "../../ImageView/ImageView.css"
import{selectAllSegments} from "../../slices/AllSegmentSlice"
import "./HoverLayer.css";
import { getHover, getAllPolygons } from "../../slices/AlertSlice";

import {getCurrentablePalettes} from "../../slices/CurrentSelectedPalleteSlice"
import Alert from "../../ImageView/Alert";
import SamLoading from "../../Alert/SamLoading"
import { getSamLoading, samLoading } from "../../slices/AlertSlice.js";
import AddSegmentToDB from '../../AddSegmentToDb/AddSegmentToDB.js';
import {getSaveDataToDB,SavaDataToDb,getEditSegmentation} from "../../slices/EditSegment/EditSegment.js"

import {addIsOnMouseUp,addSelectedSegment} from "../../slices/MouseEvent/MouseEventSlice.js"

import {getFinalSave,finallySaveToDB} from "../../slices/EditSegment/EditSegment.js"

import AddSwatchIntoJob from "../../Add_Edit_Pallet/AddSwatchIntoJob"
import ReloadJob from "../../Add_Edit_Pallet/ReloadJob.js"
import { palletLoading } from "../../slices/AlertSlice";
import {getTranspalletImage,removeAllPalletImages} from "../../slices/TransparentPalletImgSlice.js"
import {getPopHover,removePopHover} from "../../slices/popHover/PopHoverSlice.js"

import {getAllShadows,updateShadow,removeShadowCanvas} from "../../slices/shadow/ShadowSlice.js"
import html2canvas from "html2canvas";
import {getStartCompare,startCompare} from "../../slices/compare/CompareSlice.js"

import {getDeletSegment,remeoveDelete} from "../../slices/DeleteSlice/DeleteSlice.js"
import {addEditSegment,} from "../../slices/EditSegment/EditSegment.js"
import {GetCombinationSwatch }from "../../slices/combinationSwatch/CombinationSwatchSlice.js"
import GetAllCombination from "../../CombinationSwatch/GetALlCombination.js";

import {getCanvasSize} from "../../slices/windowSizeSlice/WindowResolution.js"
import UploadImagePage from "../../NewDesign/leftsection/UploadDesignImage/UploadHomePage.js"
import {getOpenModal} from "../../slices/uploadSwatch/UploadSwatchImage.js"
import CreateProjectModal from '../../CreateProject/CreateProject';
import {getCreatedProject,openCreatePorjectModal} from "../../slices/CreateProjectHeaderSlice/CreateProjectHeaderSlice.js"
import {getGridMultiSelect} from "../../slices/gridView/MultiSelectionSegement.js"

import ShareLink from "../../NewDesign/shareLink/ShareLink.js"
import {getThreshold} from "../../slices/threshold/ThresholdSlice.js";
import {getShareLink, OpenShareLink} from "../../slices/shareLinkSlice/ShareLinkSlice"
import Overlap from './Overlap.js';

import {getOverLaps} from "../../slices/overlapSlice/OverLapSlice.js"
import Threshold from '../../NewDesign/rightsection/tools/Threshold.js';
import {getDoorheightModal} from "../../slices/doorheight/DoorheightSlice.js"
import DoorHeight from "../../NewDesign/rightsection/tools/DoorHeight.js"
const HoverLayer = () => {
    const dispatch= useDispatch()
    const canvasRef = useRef(null);
    const [projectId, setProjectId]= useState()
    const getThresholds= useSelector(getThreshold)
  const [polygons, setPolygon] = useState([]);
  const [points, setPoints] = useState([])
    const getProjectImage=useSelector(getProjectId);
    const getAllPolygon=useSelector(selectAllSegments)
    const [scaleX, setScalex]= useState(null);
    const [scaleY, setScaley]= useState(null);
    const [hoveredPolygon, setHoveredPolygon] = useState(null);
    
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });    

     const [isPolygonArray, setIsPolygonArray]= useState(false)
     const [imageGroup,setImageGroup]= useState()
     const samload = useSelector(getSamLoading);
     const [isProjectCreated, setIsProjectCreated]= useState(false)
     const [projectNameValue, setProjectNameValue] = useState(null);
     const ismountedImage = useRef(true)
     const [isAddJob, setIsAddJob] = useState(false);
     const getCanvasSizes= useSelector(getCanvasSize)
     const [isCanvas, setIsCanvas]=useState(false)
     const [loading, setLoading]= useState(false)
     const getGridMultiSelects= useSelector(getGridMultiSelect)  // after selecting polygon should display on layer
      const getDoorheightModals= useSelector(getDoorheightModal)
   
   // update the loading 
   useEffect(()=>{
    if(samload){
      setLoading(true)
    }else{
      setLoading(false)
    }
   },[samload])
   

     useEffect(() => {
      
      if(getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null

        ){
          
          var canvas = new fabric.Canvas("hover-layer", {
            selectionBorderColor: 'green',
            width: getCanvasSizes.canvasWidth, // Set canvas width
            height:getCanvasSizes?.canvasHeight 
          });
          console.log("window.width", window.innerWidth)
          // setCanvas(canvas); // Remove this line
          canvasRef.current = canvas; 
          const imgGroup = new fabric.Group([], { selectable: false, name: 'imageGroup',visible: true  , erasable: 'deep' });
          const shadowGroup = new fabric.Group([], { selectable: false, name: 'shadowGroup',visible: false });
          canvasRef.current.add(imgGroup);
          canvasRef.current.add(shadowGroup);
          setImageGroup(imgGroup);
          // setCanvas(canvas);
           setPoints(null)
           setPolygon(null)
           setIsCanvas(true)
           // Add event listeners for mouseover and mouseout
        canvasRef.current.on('mouse:over', handleMouseOver);
        canvasRef.current.on('mouse:out', handleMouseOut);
        canvasRef.current.on('mouse:up', handleMouseUp);
        ismountedImage.current=true
        return () => {
          // Remove event listeners on component unmount
          canvas.off('mouse:over', handleMouseOver);
          canvas.off('mouse:out', handleMouseOut);
          canvas.off('mouse:up', handleMouseUp);
        };

        }
      

     }, [getCanvasSizes]); 

     // add image to canvas
 

        useEffect(()=>{
          //
          if(isCanvas){
            if(getProjectImage&&
              getProjectImage.base64Image&&
              canvasRef.current &&
              ismountedImage.current){
                setProjectNameValue(getProjectImage?.projectName)
                
                setProjectId(getProjectImage?.projectId)
                ismountedImage.current = false;
                let image = getProjectImage?.base64Image;
          const encodedImage = "data:image/png;base64," + image;
          
          const decodedImage = atob(encodedImage.split(",")[1]);
          
          const uint8Array = new Uint8Array(decodedImage.length);
          for (let i = 0; i < decodedImage.length; i++) {
            uint8Array[i] = decodedImage.charCodeAt(i);
          }
          
          const blob = new Blob([uint8Array], { type: "image/png" });
          
          let imageUrl = URL.createObjectURL(blob);
      
          var img = new window.Image();
          img.src = imageUrl;
          img.onload = () => {}
    //  let imageUrl=getProjectImage?.projectImage
    fabric.Image.fromURL(imageUrl, function (img) {
      img.scaleToWidth(canvasRef.current.width); // Set your desired width
      img.scaleToHeight(canvasRef.current.height);
      canvasRef.current?.setBackgroundImage(imageUrl, canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
        scaleX: canvasRef.current.width / img.width,
        scaleY: canvasRef.current.height / img.height,
        width:canvasRef.current.width,
        height:canvasRef.current.height,
        originX: "left",
        originY: "top",
        
      
        });

        setScalex(canvasRef.current.width / img.width);
        setScaley(canvasRef.current.height / img.height);
        console.log("img.width: " ,+img.width)
        console.log("img.height: " ,+img.height)
        console.log("canvasRef.current.width",canvasRef.current.width)
        console.log("canvasRef.current.height",canvasRef.current.height)
        console.log("sclale x",canvasRef.current.width / img.width)
    
          console.log("sclale y",canvasRef.current.height / img.height)
      // Create a group for additional images
      
      canvasRef.current?.requestRenderAll();
        
    })

              }
          }
            
      
        },[canvasRef,getProjectImage,isCanvas]);

  const isMounted= useRef(true)

 useEffect(()=>{
  if(isCanvas &&scaleX!=null&&scaleY!=null){
    if(canvasRef.current &&
      getAllPolygon&&
          getAllPolygon.allSegmentsArray&&
          getAllPolygon.allSegmentsArray.length>0&&
          isMounted.current
      ){
        
        dispatch(palletLoading({ palletLoading: false }));
        collectPoints()
      } else if( getAllPolygon?.allSegmentsArray&&
        getAllPolygon?.allSegmentsArray.length===0){
          dispatch(palletLoading({ palletLoading: true }));
        }
  }
  
 },[canvasRef.current,getAllPolygon,isCanvas,scaleX,scaleY])
     

 const collectPoints=()=>{
  
      if(getAllPolygon&&
        getAllPolygon.allSegmentsArray&&
        getAllPolygon.allSegmentsArray.length>0){
        isMounted.current=false
     
        let pol=[]
      getAllPolygon.allSegmentsArray.map(item=>{
    
          let polyName=item.name;
          let allPoly = item?.annotationPolygon
           .split(",")
           .map((value) => parseInt(value.trim(), 10));
             
           let point = [];
         let i;
         for ( i = 0; i < allPoly.length; i += 2) {
           const x = allPoly[i]*scaleX;
           const y = allPoly[i + 1]*scaleY;
           point.push({ x, y });
         }
          
         let coordinate=item.BoundingBoxInt
         .split(",")
           .map((value) => parseInt(value.trim(), 10));
          
           makePolygon(point,coordinate,polyName)
       
     
      })

        } 
        
      }

      const makePolygon=(point,coordinate, polyName)=>{
        if(point&&
          point.length>0&&
          coordinate&&
          polyName
          ){

            let text = new fabric.Text(polyName, {
              left: coordinate[0]*scaleX,
              top: coordinate[1]*scaleY ,
              fontFamily: 'Arial',
              fontSize: 18,
              fill: 'yellow',
              selectable: false, // Make the text unselectable
              visible: false, // Initially hide the text
             
            });
         
            var shadow = new fabric.Shadow({
              color: "black",
              blur: 10,
              offsetX: 10,
              offsetY: 10,
            });
            
          let polygon = new fabric.Polygon(point, {
            name:polyName,
            text: text,
            left:coordinate[0]*scaleX,
            top:coordinate[1]*scaleY,
            fill:"transparent",
          
            originX: "left",
            originY: "top",
            hasRotatingPoint:false,
            hasBorders:false,
            hasControls:false,
            stroke: "rgb(7 239 253)",
            strokeWidth: 2,
            opacity:"0",
       
            lockMovementX:true,
            lockMovementY:true,
            // shadow:shadow,
            isSelected:false,

           
          });
          const checkbox= new fabric.Rect({
            left: coordinate[0]-17,
            top: coordinate[1]+5,
            width: 10,
            height: 10,
            fill: 'transparent',
           stroke: 'black',
            selectable: false, // Make the text unselectable
            visible: false, // Initially hide the text
            name: polyName
          })
          const group = new fabric.Group([polygon, text,checkbox], {
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            hasRotatingPoint: false,
            hasBorders: false,
            hasControls: false,
            name: polyName,
            subTargetCheck:true,
          });
      
          canvasRef.current.add(group);
          let poly={
            polygon:polygon,
            points:point
          }
          setPolygon((prev) => ({
            ...prev,
            [polyName]: poly // Use square brackets to set the key dynamically
          }));
         // canvasRef.current.add(polygon,text);
          canvasRef.current.requestRenderAll();

         
          
        }
      }

 
      const handleMouseOver = (event) => {

        
        let pointer = canvasRef.current.getPointer(event.e);
        let x = pointer.x;
        let y = pointer.y;
      
        const allObjects = canvasRef.current.getObjects();
          // console.log("hover on canvas", event.target)
          console.log("hover on canvas",canvasRef)
          // console.log("hover on hovered target", event.target._hoveredTargets)
        
        // Find the index of the hovered object in the array
        const zIndex = allObjects.indexOf(event.target);
     
       
        if (event.target != null && event.target.name != null && event.target.name!="imageGroup") {
          // console.log("hover _hoveredTarget", event.target._hoveredTarget())
          const polygonName = event.target.name;
          
          let currentObject= event.target._objects;

          if (polygonName != null && 
            polygonName!="imageGroup"&& 
            polygonName!="shadowGroup"&&
            canvasRef &&
            currentObject &&
            currentObject.length>0)  {
           
             
           
           currentObject[0].set('opacity', 10);

           currentObject[1].set('visible', true);
           currentObject[2].set('visible', true);
           console.log("current object",currentObject)
           canvasRef?.current?.renderAndResetBound();
           console.log("all object", canvasRef.current)
            if (canvasRef.current) {
          
              canvasRef.current.requestRenderAllBound();
            
            } else {
              console.error('Canvas renderAll method not available');
            }
          }
        }
      };
      
    
      const handleMouseOut = (event) => {
        let pointer = canvasRef.current.getPointer(event.e);
        let x = pointer.x;
        let y = pointer.y;
        console.log("pointer mouse out", event.target)
        // Hide points on mouseout
        if (event.target != null && event.target.name != null && event.target.name!="imageGroup") {
          let polygonName = event.target.name;
          let currentObject= event.target._objects;
          if (polygonName != null && 
            canvasRef&&
            currentObject &&
            currentObject.length>0
            )  {
              currentObject[0].set('opacity', 0);
              currentObject[1].set('visible', false);
              currentObject[2].set('visible', false);
            // console.log("polygonName",polygonName)
            if (canvasRef.current) {
             // console.log("canvasRef",canvasRef )
              canvasRef.current.requestRenderAllBound();
            } else {
              console.error('Canvas renderAll method not available');
            }
          }
        }
      };
    

      //hover ALL segments
      const getAllPolygonHover = useSelector(getAllPolygons);
    useEffect(() => {
      if(isCanvas){
        if (getAllPolygonHover.isAllHover && getAllPolygonHover.allPolygonNames) {
          handleAllSegmentHover(getAllPolygonHover.allPolygonNames);
        } else {
          handleRemoveAllHover();
        }
      }
      
    }, [getAllPolygonHover.isAllHover,canvasRef,isCanvas]);

  
     const handleAllSegmentHover=(allpoly) => {
   //console.log("hover", allpoly)
   if(allpoly && 
    allpoly.length > 0 &&
    canvasRef.current._objects &&
    canvasRef.current._objects.length>0){
    allpoly.map(poly=>{
      //console.log("poly",poly)
      canvasRef.current._objects.map(obj=>{
        if(obj.name===poly){
          let currentObject=obj._objects;
          if(currentObject && currentObject.length>0){
            currentObject[0].set('opacity', 1);
            currentObject[1].set('visible', true);
            currentObject[2].set('visible', true);
            canvasRef.current.requestRenderAllBound();
          }
    
        }
      }
      )
    })
    
     }}

     const handleRemoveAllHover = () => {
     // console.log("stopHover", canvasRef.current._objects);
      if (
        canvasRef.current &&
        canvasRef.current._objects &&
        canvasRef.current._objects.length > 0
      ) {
        canvasRef.current._objects.forEach(obj => {
          let currentObject=obj._objects;
          if(currentObject && 
            currentObject.length>0 &&
            obj.name!="imageGroup" &&
            obj.name!="shadowGroup" &&
            !currentObject[1].isSelected
            ){
            currentObject[0].set('opacity', 0);
            currentObject[1].set('visible', false);
            currentObject[2].set('visible', false);
            canvasRef.current.requestRenderAllBound();
          }
        });
      }
    };
    

    // hover on each segment
    const getHoverEffect = useSelector(getHover);
    useEffect(() => {
      if (getHoverEffect.ishover ) {
        
        let labelName=[getHoverEffect.segmentName]
        handleAllSegmentHover(labelName)
        
      
      } else {
        handleRemoveAllHover()

      }
       
    }, [getHoverEffect.ishover && getHoverEffect.segmentName,getGridMultiSelects]);


 


  // render the image on canvas
  const getTranspalletImages= useSelector(getTranspalletImage)
  
// useEffect(() =>{
//   //

//   if(getTranspalletImages&&
//     getTranspalletImages.selectedPlaletUrl != null&&
//     getTranspalletImages.segmentName !=null&&
//     getTranspalletImages.s3BucketImage !=null&&
  
//     canvasRef.current&&
//     canvasRef.current._objects&&
//       canvasRef.current._objects.length>0
//     ){
//       console.log("render image")
//       const allObjects = canvasRef.current.getObjects();
//       let url=getTranspalletImages.selectedPlaletUrl;
//         fabric.Image.fromURL(url, function(img){
//           var oImg = img.set({ left: 0, top: 0}).scale(1);
//           oImg.set({
//             name:getTranspalletImages.segmentName,
//             swatchId:parseInt(getTranspalletImages?.SwatchId,10),
//             visible:true})
        
//           imageGroup.addWithUpdate(oImg);
//           canvasRef.current.requestRenderAllBound();
//         })

//          dispatch(removeAllPalletImages())
      
//     }
// },[getTranspalletImages,canvasRef])

// render each pallet image on canvas
const currentPalletImage= useSelector(getCurrentablePalettes)
//console.log("currentPalletImage",currentPalletImage.currentpallate)
useEffect(() => {
  if(isCanvas){
    if (
      currentPalletImage &&
      currentPalletImage.currentpallate &&
      currentPalletImage.currentpallate.length > 0 &&
      canvasRef.current &&
      canvasRef.current._objects &&
      canvasRef.current._objects.length > 0
    ) {
      const allObjects = canvasRef.current.getObjects();
      const imageGroup = allObjects[0];
  
      currentPalletImage.currentpallate.forEach((item) => {
       // 
        if (item.SegmentImagePath != null && item.segmentName != null) {
          // Check if the image with the same name already exists on the canvas
          let existingImage = false;
          for (let i = 0; i < imageGroup._objects.length; i++) {
            const img = imageGroup._objects[i];
            if (img.name === item.segmentName) {
              console.log("found", img.name);
              // Assuming you want to replace the URL of the existing image
              fabric.Image.fromURL(item.SegmentImagePath, function (newImg) {
               
                var scaleX = canvasRef.current.width / newImg.width;
                var scaleY = canvasRef.current.height / newImg.height;
                console.log("scaleX",scaleX)
                console.log("scaleY",scaleY)
                var oImg = newImg.set({ scaleX: scaleX, scaleY: scaleY });
                oImg.set({
                  name: item.segmentName,
                  swatchId: parseInt(item?.swatchId, 10),
                  visible: true,
                });
  
                imageGroup.remove(img); // Remove the existing image
                imageGroup.addWithUpdate(oImg); // Add the new image
                canvasRef.current.requestRenderAll(); // Request canvas render
              });
              existingImage = true;
              break;
            }
          }
  
          // If the image doesn't exist, add it to the canvas
          if (!existingImage) {
            fabric.Image.fromURL(item.SegmentImagePath, function (img) {
              var scaleX = canvasRef.current.width / img.width;
              var scaleY = canvasRef.current.height / img.height;
              var oImg = img.set({ scaleX: scaleX, scaleY: scaleY });
              oImg.set({
                name: item.segmentName,
                swatchId: parseInt(item?.swatchId, 10),
                visible: true,
              });
  
              imageGroup.addWithUpdate(oImg);
              canvasRef.current.requestRenderAll();
            });
          }
        }
         else if(item.SegmentImagePath=== null && item.segmentName != null){

          const existingImage = imageGroup._objects.find((obj) => obj.name === item.segmentName);
          if (existingImage) {
            imageGroup.remove(existingImage);
            canvasRef.current.requestRenderAll();
          }
         }
      });
  
      dispatch(removeAllPalletImages());
    }
  }
}, [currentPalletImage, canvasRef,isCanvas]);



  useEffect(()=>{},[])
  // save Datta to DB
  const [isAddSegment, setIsAddSegment]= useState(false)
  const getSaveDataToDBs = useSelector(getSaveDataToDB);

  useEffect(()=>{
    if(getSaveDataToDBs){
      setIsAddSegment(true)
      updatePolygon()
    }
  },[getSaveDataToDBs])

  const handleResetSegmentData=()=>{
    setIsAddSegment(true)
    dispatch(SavaDataToDb({
      isAdddataToDB: false
    }))
    dispatch(samLoading({
      samLoading:false
    }))
  }
  

  // on edit update polygon
  
    const getEditSegments= useSelector(getEditSegmentation)

  const updatePolygon=() => {
    //
    const getCanvas= canvasRef.current;
    if(getEditSegments &&
      getEditSegments?.labelName&&
      getEditSegments?.polygon&&
      canvasRef.current &&
      canvasRef.current._objects&&
      canvasRef.current._objects.length>0
      ){

        canvasRef.current._objects.map(item=>{
          if(item.name===getEditSegments?.labelName){
              if(item._objects &&
                item._objects.length>0){
                  canvasRef.current.remove(item._objects[0]);
                  
                }
          } else{

          }
        })
      }


  }
  // on click event
  const [ isClicked, setIsClicked]=useState(null)
  const handleMouseUp=(option)=>{
   
    if(option?.target?.name){
      dispatch(addIsOnMouseUp({
        isOnMouseUp:true,
        labelName:option.target.name.replace(/\d/g, ""),
        segmentName:option.target.name
      }))
      setIsClicked(option.target.name)
     // addObjectToEdit(option.target.name)
    }
   

  }
  const getAllSegmenrs= useSelector(selectAllSegments)
  
  useEffect(()=>{
    if(getAllSegmenrs&&isClicked!=null){
      addObjectToEdit(isClicked)
      setIsClicked(null)
    }
  },[getAllSegmenrs,isClicked])

 const addObjectToEdit=(segName) => {
  //
  if(getAllSegmenrs&&
    getAllSegmenrs.allSegmentsArray&&
    getAllSegmenrs.allSegmentsArray.length>0){
      getAllSegmenrs.allSegmentsArray.map(item=>{
      if(item.name===segName){

         dispatch(addEditSegment({
          editSegment:[item]

         }))
        dispatch(addSelectedSegment({
          selectedSegment:item
        }))
      }
    })
  }
 }

  // save Data to DB
  const [job_Id, setJob_id] = useState();
const saveDataToStrapi=useSelector(getFinalSave)
  useEffect (()=>{
    if(saveDataToStrapi){
    setIsAddJob(true)
    dispatch(palletLoading({ palletLoading: true }));
    }
  },[saveDataToStrapi])


  const handelReset = (jobId) => {
    setJob_id(jobId);
    setIsAddJob(false);
  };

  const handleResetJob = () => {
    setJob_id(null);
    dispatch(finallySaveToDB({
      saveData:false
    }))
  };


  // hide pallet on canvas   and Unhide  pallet on canvas or delete the segment from canvas
  const getIsHide= useSelector(getPopHover)
 
  useEffect(()=>{

         if(isCanvas){
          if(getIsHide&&
            getIsHide.ishide
            ){
              
              if(canvasRef.current &&
                canvasRef.current?._objects[0]&&
                canvasRef.current?._objects[0]?._objects.length>0){
                  
                let getFirstObject= canvasRef.current?._objects[0]?._objects;
                getFirstObject.map(item=>{
                  if(item.name===getIsHide.segmentName && item.swatchId===getIsHide.swatchId){
                    // item.set('visible',false);
                    canvasRef.current?._objects[0].remove(item);
                    
                    canvasRef.current.requestRenderAllBound();
                      dispatch(removePopHover())
                  }
                })
              }
             
            }
         else if(getIsHide&&
            getIsHide.isUnhide   
            ){
              if(canvasRef.current &&
                canvasRef.current?._objects[0]&&
                canvasRef.current?._objects[0]?._objects?.length>0){
                let getFirstObject= canvasRef.current?._objects[0]?._objects;
                getFirstObject.map(item=>{
                  if(item.name===getIsHide.segmentName && item.swatchId===getIsHide.swatchId){
                    item.set('visible',true);
                    canvasRef.current.requestRenderAllBound();
                      dispatch(removePopHover())
                  }
                })
              }
          
            }
      
            else if(getIsHide&&
              getIsHide.isDelete 
              ){

                if(canvasRef.current &&
                  canvasRef.current?._objects[0]&&
                  canvasRef.current?._objects[0]?._objects.length>0){
                  let getFirstObject= canvasRef.current._objects[0]._objects;
      
                  const filteredObjects = getFirstObject.filter((item) => item.name !== getIsHide.segmentName);
                  
                  canvasRef.current._objects[0]._objects = filteredObjects;
                  
                  
                  canvasRef.current.requestRenderAll();

                }
              
      
            }
         }
   
  },[isCanvas,getIsHide, canvasRef])

  ///update the shadow on canvas
  const getAllShadowss= useSelector(updateShadow)

  useEffect(()=>{
 
    if(getAllShadowss &&
       getAllShadowss.isUpdate &&
       getAllShadowss.updateShadowOnCanvas&&
       getAllShadowss.updateShadowOnCanvas.length>0
       
       ){
     
        getAllShadowss.updateShadowOnCanvas.map(item=>{
          let polyName=item.label;
          let allPoly = item?.flattenedPoints
           .split(",")
           .map((value) => parseInt(value.trim(), 10));
             
           let point = [];
         let i;
         for ( i = 0; i < allPoly.length; i += 2) {
           const x = allPoly[i];
           const y = allPoly[i + 1];
           point.push({ x, y });
         }
         let coordinate=item.BoundingBoxInt
         .split(",")
           .map((value) => parseInt(value.trim(), 10));

            addShadowPolygon(point,coordinate,polyName)
          })
      }
    }
  ,[getAllShadowss])

  // add shadow polygon into canvas
  const addShadowPolygon=(point,coordinate,polyName)=>{

          
          if(point &&coordinate &&polyName){
            let text = new fabric.Text(polyName, {
              left: coordinate[0] ,
              top: coordinate[1] ,
              fontFamily: 'Arial',
              fontSize: 18,
              fill: 'yellow',
              selectable: false, // Make the text unselectable
              visible: false, // Initially hide the text
             
            });

            let polygon = new fabric.Polygon(point, {
              name:polyName,
              text: text,
              left:coordinate[0],
              top:coordinate[1],
              fill:"transparent",
             
              originX: "left",
              originY: "top",
              hasRotatingPoint:false,
              hasBorders:false,
              hasControls:false,
              stroke: "rgb(7 239 253)",
              strokeWidth: 2,
              opacity:"0",
         
              lockMovementX:true,
              lockMovementY:true,
              // shadow:shadow
  
             
            });
            const checkbox= new fabric.Rect({
              left: coordinate[0]-17,
              top: coordinate[1]+5,
              width: 10,
              height: 10,
              fill: 'transparent',
             stroke: 'black',
              selectable: false, // Make the text unselectable
              visible: false, // Initially hide the text
              name: polyName
            })

            const group = new fabric.Group([polygon, text,checkbox], {
              selectable: false,
              lockMovementX: true,
              lockMovementY: true,
              hasRotatingPoint: false,
              hasBorders: false,
              hasControls: false,
              name: polyName,
              visible:true
            });
            canvasRef.current.add(group)
            canvasRef.current.requestRenderAllBound();
          }
          dispatch(removeShadowCanvas())
  }

const showShadow= useSelector(getAllShadows)



// start compare 

const getStartCompares= useSelector(getStartCompare)
 
useEffect(()=>{
  if(getStartCompares&&
    getStartCompares.isStartCompare){
      captureScreenshot()
  }
},[getStartCompares])
// download image 
const captureScreenshot = () => {
  
  // Get the div element you want to capture
  const elementToCapture = document.querySelector(".dzi-van");
  const randomNum = Math.floor(Math.random() * 1000) + 1;
  const fileName = `${projectNameValue}_${randomNum}.png`;

  html2canvas(elementToCapture).then((canvas) => {
    // Convert the canvas to an image data URL
    const imageDataURL = canvas.toDataURL({ format: 'png', multiplier: 2 });
    //const imageDataURL = canvas.toDataURL("image/png");
 
  
    const downloadLink = document.createElement("a");
    downloadLink.href = imageDataURL;
    downloadLink.download = fileName;
    downloadLink.style.display = "none"; // Hide the link

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    canvas.remove();
  });
  dispatch(startCompare({
    isStartCompare:false
  }))
};


// after deleting update the canvas
const getDeletSegments= useSelector(getDeletSegment)
 useEffect(()=>{
  if(isCanvas){
    let allObjects = canvasRef.current?.getObjects();
    if(getDeletSegments &&
      getDeletSegments.isDeleted&&
      getDeletSegments.segmentName&&
      allObjects &&
      allObjects.length>0){
        allObjects.map(item=>{
          if(item.name===getDeletSegments.segmentName){
            canvasRef.current.remove(item);
          }
        })
  
       
    }
    dispatch(remeoveDelete())
  }


 },[getDeletSegments, canvasRef,isCanvas]);
  

 // after save combination call API to get All combination
 const getAfterSaveCombination= useSelector(GetCombinationSwatch)
 const [isGetAllCombination, setIsGetAllCombination]= useState(false)

useEffect(()=>{
  if(projectNameValue!=null){
    setIsGetAllCombination(true)
  }
},[projectNameValue])

 useEffect(()=>{
  if(getAfterSaveCombination.isRenderComninationFinish){
    //
    setIsGetAllCombination(true)
  }
 },[getAfterSaveCombination])

 const handleResetData=()=>{
  setIsGetAllCombination(false)
 }

 // create project on Header 
  const getCreatedProjects=useSelector(getCreatedProject)
   const [isOpenCreateProjectModal, setIsOpenCreateProjectModal]= useState(false)
   useEffect (()=>{
    if(getCreatedProjects?.isProjectCreated){
      setIsOpenCreateProjectModal(true)

    }else{
      setIsOpenCreateProjectModal(false)
    }
   },[getCreatedProjects])

   const handleCloseCreateProjectModal = () => {
    setIsOpenCreateProjectModal(false);
    dispatch(openCreatePorjectModal({
      isProjectCreated:false,

    }))
  };

  // after selecting segment display over canvas 

  
  useEffect(()=>{
 
    if(getGridMultiSelects &&
      getGridMultiSelects.gridMultiSelect &&
      getGridMultiSelects.gridMultiSelect.length>0 &&
      canvasRef.current._objects &&
      canvasRef.current._objects.length>0
      ){
            
        getGridMultiSelects.gridMultiSelect.map(item=>{
         
          displayPolygonAfterSelection(item.name)
        })
         
    } else if(getGridMultiSelects &&
      getGridMultiSelects.gridMultiSelect &&
      getGridMultiSelects.gridMultiSelect.length===0 &&
      canvasRef.current._objects&&
      canvasRef.current._objects.length>0
    ){
      resetDisplayedSelection()
    } 
    
    if(getGridMultiSelects.updateOnCanvas &&
      getGridMultiSelects.updateSegmentName
      ){
      
      updateCanvas(getGridMultiSelects.updateSegmentName)
    }
  },[getGridMultiSelects,canvasRef,getGridMultiSelects.updateOnCanvas])

  //diisplay the polygon on layer after selecting 
  const displayPolygonAfterSelection =(labelName)=>{

      canvasRef.current._objects.map(obj=>{
        if(obj.name===labelName){
          let currentObject=obj._objects;
          if(currentObject && currentObject.length>0){
            currentObject[0].set('opacity', 1);
            currentObject[1].set('visible', true);
            currentObject[1].set('isSelected', true);
            currentObject[2].set('visible', true);
            canvasRef.current.requestRenderAllBound();
          }
    
        }
      })
    }

    const resetDisplayedSelection=()=>{
      canvasRef.current._objects.map(obj=>{
        if(obj._objects && obj._objects.length>0 && obj._objects[1]?.isSelected){
          obj._objects[0].set('opacity', 0);
          obj._objects[1].set('visible', false);
          obj._objects[1].set('isSelected', false);
          obj._objects[2].set('visible', false);
          canvasRef.current.requestRenderAllBound();
        }
      })
    }

    const updateCanvas=(segName)=>{
      
      canvasRef.current._objects.map(obj=>{
        if(obj.name===segName){
          let currentObject=obj._objects;
          if(currentObject && currentObject.length>0){
            currentObject[0].set('opacity', 0);
            currentObject[1].set('visible', false);
            currentObject[1].set('isSelected', false);
            currentObject[2].set('visible', false);
            canvasRef.current.requestRenderAllBound();
          }
    
        }
      })
    }
  

    // open share link module 
const getShareLinks= useSelector(getShareLink)
    

 const   handleCloseShareLink=()=>{
  dispatch(OpenShareLink({
    openShareLinkModal:false
  }))
 }

 // overLap 
 const getOverLap= useSelector(getOverLaps)
 console.log("getOverLap",getOverLap)
 

  return (
    <div>
   
    <div className="ai-right-wrapper-body Cursor  project-layer-images">
    
    <canvas  id="hover-layer" className="dzi-van"    ref={canvasRef}></canvas>
    </div>
    
 
   <Alert />
   {loading && <SamLoading />}

   {isAddSegment && (
        <AddSegmentToDB resetSegmentData={handleResetSegmentData} />
      )}

      {isAddJob && <AddSwatchIntoJob reSetPallet={handelReset} />}
      {job_Id && <ReloadJob job_Id={job_Id} reSetjob={handleResetJob} />} 
    
    {isGetAllCombination &&<GetAllCombination
    combinationProject={projectId}
    resetData={handleResetData}
    />}
     
     
            <UploadImagePage />

            {getCreatedProjects && <CreateProjectModal
            isOpen={isOpenCreateProjectModal}
            onClose={handleCloseCreateProjectModal}
            />}

        {  getShareLinks &&  <ShareLink
            isOpen={getShareLinks}
            onClose= {handleCloseShareLink}
            />
            }


{getOverLap &&
<Overlap/>  }  

{getThresholds && <Threshold
     isOpen={getThresholds}
  />}

<DoorHeight/>

    </div>
  )
}

export default HoverLayer
import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import "../Alert/SamLoading.css";
import "./Loading.css";
const Loading = () => {
  return (
    <div>
      <div className="select-create-project-loading">
        {/* <CircularProgress isIndeterminate color="green.300" /> */}
        <Alert  className="select-project-loading" status="success" variant="solid">
          {/* <AlertIcon /> */}
          <Spinner />
          <AlertTitle className="job-loading">
            "Please wait, we're processing your request..."
          </AlertTitle>
          <AlertDescription></AlertDescription>
        </Alert>
      </div>
    </div>
  );
};

export default Loading;

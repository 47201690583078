import React from 'react'
import { Tab,TabPanel } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import MediaSegments from "../media/MediaSegments";
import MediaSwatch from './MediaSwatch.js';
const MediaLibrary = () => {
  return (
    <div>

<TabPanel>
                    <div>

                   <div className="featured-model-box">
                           <h2>Favorite Pallets</h2>

                         <div className="featured-image-group">
                             <div className="featured-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_1_b21bb5305b.png"></img>
                             </div>
                             <div className="featured-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_1_b21bb5305b.png"></img>
                             </div>
                             <div className="featured-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_1_b21bb5305b.png"></img>
                             </div>
                             <div className="featured-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_1_b21bb5305b.png"></img>
                             </div>
                             <div className="featured-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_1_b21bb5305b.png"></img>
                             </div>
                         </div>
                            
                     </div>


                        <div className="brands-seg">
                            <h2>Brands</h2>
                            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/barnds_img_8344e4f649.png"></img>
                        </div>

                       <div>

                        <MediaSegments/>
                        

                           {/* <div className="project-segments-img">
                           <div className="project-seg-images" 
                             
                         >
                             <div className="seg-contant">
                             <h2>Door </h2>
                             </div>
                             <div className="segament-img">
                             <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_icon_c0ba19ace9.png"></img>
                             </div>
                         </div>
                             
                             <div className="project-seg-images">
                                 <div className="seg-contant">
                                 <h2>Window</h2>
                                 </div>
                                 <div className="segament-img ">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/window_8a0a08a47b.png"></img>
                                 </div>
                             </div>

                             </div>

                            <div className="project-segments-img">
                            <div className="project-seg-images">
                                <div className="seg-contant">
                                <h2>Wall</h2>
                                </div>
                                <div className="segament-img">
                                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_10a1f3a707.png"></img>
                                </div>
                            </div>
                             
                            <div className="project-seg-images">
                                <div className="seg-contant">
                                <h2>Roof</h2>
                                </div>
                                <div className="segament-img">
                                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/roof_6629efd7d9.png"></img>
                                </div>
                            </div>

                         </div>

                              <div className="project-segments-img">
                             <div className="project-seg-images">
                                 <div className="seg-contant">
                                 <h2>Trim</h2>
                                 </div>
                                 <div className="segament-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/trim_icon_8e4b3d6eb3.png"></img>
                                 </div>
                             </div>
                             
                        <div className="project-seg-images">
                            <div className="seg-contant">
                            <h2>Gutter</h2>
                            </div>
                            <div className="segament-img">
                            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/gutter_icon_2_158cfe7fa3.png"></img>
                            </div>
                        </div>

                        </div>

                        <div className="project-segments-img">
                        <div className="project-seg-images">
                            <div className="seg-contant">
                            <h2>Garage</h2>
                            </div>
                            <div className="segament-img">
                            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/garage_icon_c55b4bbc81.png"></img>
                            </div>
                        </div>

                        <div className="project-seg-images">
                            <div className="seg-contant">
                            <h2>Light</h2>
                            </div>
                            <div className="segament-img">
                            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/light_0a545de930.png"></img>
                            </div>
                        </div>

                        </div> */}

                       
                    </div>
                    {/* <div>
                           <div className="project-segments-img">
                           <div className="project-seg-images" 
                             
                         >
                             <div className="seg-contant">
                             <h2>Door</h2>
                             </div>
                             <div className="segament-img segImage">
                             <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_2_d22f5ceb86.png"></img>
                             </div>
                         </div>
                             
                             <div className="project-seg-images">
                                 <div className="seg-contant">
                                 <h2>Window</h2>
                                 </div>
                                 <div className="segament-img segImage-window">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/window_1_5cb431f99b.png"></img>
                                 </div>
                             </div>

                             </div>

                            <div className="project-segments-img">
                            <div className="project-seg-images">
                                <div className="seg-contant">
                                <h2>Wall</h2>
                                </div>
                                <div className="segament-img">
                                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_img_1_22baad481e.jpg"></img>
                                </div>
                            </div>
                             
                            <div className="project-seg-images">
                                <div className="seg-contant">
                                <h2>Roof</h2>
                                </div>
                                <div className="segament-img">
                                <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/roof_3_f0c2c0f4be.jpg"></img>
                                </div>
                            </div>

                         </div>

                              <div className="project-segments-img">
                             <div className="project-seg-images">
                                 <div className="seg-contant">
                                 <h2>Trim</h2>
                                 </div>
                                 <div className="segament-img">
                                 <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/trim_1_c471641003.jpg"></img>
                                 </div>
                             </div>
                             
                        <div className="project-seg-images">
                            <div className="seg-contant">
                            <h2>Gutter</h2>
                            </div>
                            <div className="segament-img">
                            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/gutter_icon_2_d74233d355.jpg"></img>
                            </div>
                        </div>

                        </div>

                        <div className="project-segments-img">
                        <div className="project-seg-images">
                            <div className="seg-contant">
                            <h2>Garage</h2>
                            </div>
                            <div className="segament-img">
                            <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/garage_door_2_0d14f1fe19.png"></img>
                            </div>
                        </div>
               
                 

                        </div>
                    </div> */}
                                                
                   </div>

                 </TabPanel>
    </div>
  )
}

export default MediaLibrary
import { createSlice } from "@reduxjs/toolkit";

const MultiSelectionSegement = createSlice({
  name: "gridMultiSelect",
  initialState: {
    gridMultiSelect: [],
    isSwitchCanvas  :false,
    isOutLine: false,
    labelName:null,
    isOpenSelectpallate:false,
    ishoverLayer:false,
    masterName:null,
    selectAll:false,
    updateOnCanvas:false,
    updateSegmentName:null
    
  },
  reducers: {
    addGridMultiSelect: (state, action) => {
      state.gridMultiSelect.push(action.payload.gridMultiSelect);
      state.labelName=action.payload.labelName
      state.updateOnCanvas=false
    },
    removeGridMultiSelect: (state, action) => {
      state.gridMultiSelect = []
      state.isOutLine=false
      state.labelName=null
      state.isSwitchCanvas=false
      state.isOpenSelectpallate=false
      state.ishoverLayer=false
    },
    updateGridMultiSelect: (state, action) => {
      const { segName } = action.payload;
      const updatedGridMultiSelect = state.gridMultiSelect.filter(
        (item) => item.name !== segName
      );
      if(updatedGridMultiSelect){
        state.gridMultiSelect = updatedGridMultiSelect;
        state.updateOnCanvas=true;
        state.updateSegmentName=segName
      }
      // state.gridMultiSelect = updatedGridMultiSelect;
      // state.updateOnCanvas=true;
    },
    updateMultiSelect:(state,action) => {
      state.gridMultiSelect=(action.payload.gridMultiSelect);
    },
    switchCanvas:(state,action) => {
      state.isSwitchCanvas=action.payload.isSwitchCanvas
    },

    //OutLine Mode active
    outLineMode:(state, action) => {
      state.isOutLine=action.payload.isOutLine

    },
    closeSelectPallet:(state, action) => {
      state.isOpenSelectpallate=action.payload.isOpenSelectpallate
    }, 
    switchToHoverLayer:(state, action) => {
      state.ishoverLayer=action.payload.ishoverLayer
    },
    selectAllcheckBox:(state, action) => {
      state.selectAll=action.payload.selectAll  
    }

  },
});

export const {
  addGridMultiSelect,
  removeGridMultiSelect,
  updateGridMultiSelect,
  updateMultiSelect,
  switchCanvas,
  outLineMode,
  closeSelectPallet,
  switchToHoverLayer,
  selectAllcheckBox
} = MultiSelectionSegement.actions;
export const getGridMultiSelect = (state, action) => state.gridMultiSelect;
export const getSwitchCanvas = (state, action) => state.gridMultiSelect.isSwitchCanvas;
export const GetOutLineMode = (state,) => state.gridMultiSelect.isOutLine

export const getOpenSelectPallet=(state, action) => state.gridMultiSelect.isOpenSelectpallate
export const getSwitchHoverLayer = (state, action) => state.gridMultiSelect.ishoverLayer

export const getSelectAll= (state,action)=>state.gridMultiSelect.selectAll
export default MultiSelectionSegement.reducer;

import React, { useState, useEffect } from "react";
import { selectedSwatch } from "../slices/SelectedPalletdSlice";
import { useDispatch, useSelector } from "react-redux";
import { addSegment } from "../slices/AllSegmentSlice";
import { useQuery } from "@apollo/client";
import { GET_JOBSDETAIL } from "../GraphQL/GraphQl";
import { palletLoading, onAllHover, addCheckbox } from "../slices/AlertSlice";
import {
  getTranspalletImage,
  addSelectedPalletImag,
} from "../slices/TransparentPalletImgSlice";
import { removeSelectedPallete } from "../slices/SelectedPalletdSlice";

const ReloadJob = ({ job_Id, reSetjob, uploadSwatchId }) => {
  const [jobId, setJobId] = useState();
  const dispatch = useDispatch();
  const [Swatch_id, setUploadSwatch_id] = useState(uploadSwatchId);
  const [isOpen, setIsOpen] = useState(false);
  const getTranspalletImageDetails = useSelector(getTranspalletImage);
  useEffect(() => {
    if (job_Id !== null) {
      setJobId(job_Id);
    }
  }, [job_Id]);
  const { loading, error, data } = useQuery(GET_JOBSDETAIL, {
    variables: {
      jobId: jobId,
    },
    fetchPolicy: "no-cache", // Force a network request
  });
  const [allJobs, setAllJobs] = useState();
  useEffect(() => {
    if (data && !loading) {
      reSetjob();
      dispatch(addSegment(data.jobs.data[0].attributes.Segmentation));
      dispatch(palletLoading({ palletLoading: false }));
      setAllJobs(data.jobs.data[0].attributes.Segmentation);
      setIsOpen(true);
      setUploadSwatch_id(uploadSwatchId);

      addPalletUrl(data.jobs.data[0].attributes.Segmentation);
      dispatch(
        onAllHover({
          isAllHover: false,
          segmentName: null,
          allpolygon: [],
        })
      );
      dispatch(
        addCheckbox({
          isCheckBox: true,
        })
      );
      // dispatch(removeSelectedPallete());
    }
  }, [data, loading]);

  const addPalletUrl = (allJobs) => {
    if (
      allJobs &&
      getTranspalletImageDetails.segmentName &&
      Swatch_id != null
    ) {
      if (allJobs && allJobs.length > 0) {
        allJobs.map((item) => {
          if (item.name === getTranspalletImageDetails.segmentName) {
            if (item.SelectSwatchJob && item.SelectSwatchJob.length > 0) {
              item.SelectSwatchJob.map((seg) => {
                let segId = parseInt(seg.SegmentImage?.data?.id, 10);
                if (segId === uploadSwatchId) {
                  dispatch(
                    addSelectedPalletImag({
                      segmentName: getTranspalletImageDetails.segmentName,
                      selectedPlaletUrl: seg.SegmentImage?.data?.attributes.url,
                    })
                  );
                }
              });
            }
          }
        });
      }
    }
  };

  return <div></div>;
};

export default ReloadJob;

import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import axios from "axios";
import LoadProject from "./LoadProject";
import { getCustomer } from "../login/AuthSlice";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { deleteProjectView, renameProject } from "../slices/ProjetcViewSlice";
import { useDispatch } from "react-redux";

import "./CreateProject.css";
import EditProjectName from "../HomePage/RenameProject/EditProjectName";
import { getProjectId } from "../slices/ProjectSlice";
import { deleteOption } from "../slices/AlertSlice";
import { loadingAlert } from "../slices/LoadingSlice";
const DeleteProject = ({
  projectId,
  projectName,
  closeOption,
  reSetOption,
  openAlert,
  editProjectName,
}) => {
  const [project_id, setProjectId] = useState();
  const [projectIds, setCustomerId] = useState();
  const getCustomerDetail = useSelector(getCustomer);

  const dispatch = useDispatch();
  const toast = useToast();

  const projectDetail = useSelector(getProjectId);
  const handleDeleteProject = () => {
    openAlert();
  };

  // rename project name modal open
  const [isRename, setIsRename] = useState(false);
  const handleRenamedProject = () => {
    editProjectName();
  };

  return (
    <>
      <ButtonGroup>
        <div className="project-icon" onClick={handleRenamedProject}>
          <i class="bi bi-pencil-square"></i>

          <Button onClick={handleRenamedProject}>Rename</Button>
        </div>
        <div className="trash-icon" onClick={handleDeleteProject}>
          <i class="bi bi-trash"></i>
          <Button onClick={handleDeleteProject}>Delete</Button>
        </div>
      </ButtonGroup>

      {/* after delete   reload project */}
      {/* {projectIds && (
        <LoadProject projectIds={projectIds} callLoad={handleLoading} />
      )} */}

      {/* {isRenameForm && (
        <EditProjectName
          isOpen={isRenameForm}
          onClose={handleRenameFormModal}
          rename={handleRename}
          projectName={projectName}
        />
      )} */}
    </>
  );
};

export default DeleteProject;

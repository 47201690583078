import React, { useEffect } from "react";
import { getProjectId, addProjectId } from "../slices/ProjectSlice";
import { useSelector, useDispatch } from "react-redux";
const ProjectDetail = () => {
  const getProjectDetails = useSelector(getProjectId);
  const dispatch = useDispatch();
  const project = JSON.parse(sessionStorage.getItem("ProjectDetails"));
  useEffect(() => {
    if (
      getProjectDetails &&
      getProjectDetails.projectId === null &&
      getProjectDetails.projectName === null &&
      getProjectDetails.base64Image === null &&
      getProjectDetails.jobId === null &&
      project
    ) {
      dispatch(
        addProjectId({
          projectId: project.projectId,
          base64Image: project.base64image,
          projectName: project.projectName,
          jobId: project.jobId,
          reload: true,
        })
      );
      sessionStorage.removeItem("ProjectDetails");
    }
  }, [getProjectDetails]);

  return <div></div>;
};

export default ProjectDetail;

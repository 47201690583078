import { createSlice } from "@reduxjs/toolkit";

const AddProjectSlice=createSlice({
    name:"addProject",
    initialState: { 
        projectName:null,
        projectImageUrl:null,
        job:[],
        projectId:null,

    },
    reducers:{

        addProjectName_Id:(state,action)=>{
            state.projectName=action.payload.projectName;
            state.projectId=action.payload.projectId
            
        },
        addProjectUrl:(state,action)=>{
            state.projectImageUrl=action.payload.projectImageUrl;
    
           
        }, 
        addJobDetails:(state,action)=>{
          state.job.push(action.payload)
        },
        resetAddProject:(state,action)=>{
            state.projectName=null;
            state.projectImageUrl=null;
            state.job=[];
            state.projectId=null;
            
        }
    }
    
})

export const {addProjectName_Id,addProjectUrl,addJobDetails,resetAddProject}=AddProjectSlice.actions;
export const getNewProjectDetails=state=>state.addProject
export default AddProjectSlice.reducer
import React from 'react'
import {useState, useEffect} from "react"
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,Box
    , Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Portal,
  } from '@chakra-ui/react';
  import {useDispatch, useSelector} from "react-redux";
  import {updateMasterSelectedSwatchOverAll} from "../../slices/MasterArraySlice"
   import {updateSelectedCurrentPallate} from "../../slices/CurrentSelectedPalleteSlice"
   import PopHover from "./CustomerSwatchPopHover.js"
   import {addCustomerViewPallet,updateCustomerCurrentPallete} from "../../slices/CustomerSlice/customerViewpalletSlice.js"
  const CustomerUniqueSwatch = ({uniqueSwatch,masterName,masterSeg, }) => {
  const dispatch= useDispatch()

    

    const handleoverAllSwatch=(swatchId,masterName,allSegments, isSelected)=>{
  
      // update master Array
      if(!isSelected){
        dispatch(updateMasterSelectedSwatchOverAll({
          labelName:masterName,
          swatchId:swatchId,
          selected:true
      
        })) 
      
            // update curren pallet
              if( allSegments&& allSegments.length>0){
                allSegments.forEach(seg=>{
                  let allSwatch=seg.SelectSwatchJob;
                  if(allSwatch.length>0){
                    allSwatch.forEach(swatch=>{
                      if(swatch.swatch.data.id===swatchId){
                        dispatch(updateCustomerCurrentPallete({
                          segName:seg.name,
                           swatchId:swatch.swatch.data.id,
                           palletImage:swatch?.SegmentImagePath
                         }))
                      }
                    })
                  }
                })
              }
      } else {
        dispatch(updateMasterSelectedSwatchOverAll({
          labelName:masterName,
          swatchId:swatchId,
          selected:false
      
        })) 
      
            // update curren pallet
              if( allSegments&& allSegments.length>0){
                allSegments.forEach(seg=>{
                  let allSwatch=seg.SelectSwatchJob;
                  if(allSwatch.length>0){
                    allSwatch.forEach(swatch=>{
                      if(swatch.swatch.data.id===swatchId){
                        dispatch(updateCustomerCurrentPallete({
                          segName:seg.name,
                           swatchId:swatch.swatch.data.id,
                           palletImage:null
                         }))
                      }
                    })
                  }
                })
              }

      }
  
    }
  return (
    <div>
        <Accordion className="mt-1 mb-1" defaultIndex={[0]} allowMultiple>
  <AccordionItem className='card-section'>
    
      <AccordionButton>
        <Box className="project-card-title" as="span" flex='1' textAlign='left'>
        Selected swatches
        </Box>
        <AccordionIcon />
      </AccordionButton>
    
        
      <AccordionPanel className="card-pallet-img" pb={4}>
                        <div className='select-pallet-prodect'>
                            {uniqueSwatch &&
                                uniqueSwatch.length > 0 ? (
                                    uniqueSwatch.map((seg, index) => (
                                        <div className='se-pallet-box' key={index} 
                                       
                                         >
                                            <img
                                                src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${seg.palletImage}`}
                                                alt={`Swatch ${index}`}

                                              
                                               onClick={()=>{handleoverAllSwatch(seg.swatchId,masterName,masterSeg,seg.customerSelected)}}
                                            />
                                            {seg.customerSelected &&
                                             <div className="sel-pallet-checkbox-img">
                                              <img className='check-box-img' src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Group_3_1_8943bd053e.png"></img>
                                              </div>
                                            }

                                            
                    <div className="pallet-act">
                      <Popover trigger="hover">
                        <PopoverTrigger>
                          <div className="pallet-information-icon">
                            <i class="bi bi-info">  </i>
                          </div>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent>

                            <PopoverBody>
                              <PopHover
                                //  swatch={swatch}
                                segment={seg}
                                segmentName={seg.name}
                                // uploadImage={handleuploadimage}
                                masterName={masterName}

                              />
                           
                            </PopoverBody>

                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </div>

                                        </div>
                                    ))
                                ):(<>
                                <div className=' no-pallet-img'>
                                <img
                                    key="no-data1"
                                    src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/no_image_2_c09c5031e6.png"
                                    width={100}
                                   
                                    className="home-images"
                                    alt="home icon"
                                  />
</div>
          
                                </>)
                             }
                        </div>
                    </AccordionPanel>
  </AccordionItem>

</Accordion>
    </div>
  )
}

export default CustomerUniqueSwatch

     
    {/* <div className='select-pallet-prodect'>

<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_stone_1_69fa762f6b.jpg"></img>
</div>
<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Wall_1_c3b8cd2fed.png"></img>
</div>
<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_img_1_22baad481e.jpg"></img>
</div>
<div className='se-pallet-box'>
  <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/Wall_3_636523f646.jpg"></img>
</div>


</div> */}
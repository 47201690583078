import React, { useState, useEffect } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Button } from "@chakra-ui/react";
import { Tooltip } from '@chakra-ui/react'
import { useDispatch, useSelector } from "react-redux";
import { getGridMultiSelect } from "../../../slices/gridView/MultiSelectionSegement";
import { removeGridMultiSelect } from "../../../slices/gridView/MultiSelectionSegement"
import { addTabPannel } from "../../../slices/TabPannelSlice";
import { addSelectedPallete } from "../../../slices/SelectedPalletdSlice";

import { StartAnnotation } from "../../../slices/EditSegment/EditSegment"
import { getStartAnnotation } from "../../../slices/EditSegment/EditSegment"
import { Fade, ScaleFade, Slide, SlideFade, Collapse,Box } from '@chakra-ui/react'
import {
  removeAllPalletImages,
  addSelectedPalletImag,
} from "../../../slices/TransparentPalletImgSlice";


const SelectPallet = ({ isOpen,openModalselectSwatch }) => {

  const dispatch = useDispatch();
  const getSelectedPallet = useSelector(getGridMultiSelect);

  const [isSelectPAllet, setIsSelectPallet] = useState(false);
  const [labelName, setLabelName] = useState();
  // show and hide button
  const [segments, setSegments] = useState();

  useEffect(() => {
    //console.log(getSelectedPallet.gridMultiSelect);
    if (
      getSelectedPallet &&
      getSelectedPallet.gridMultiSelect &&
      getSelectedPallet.gridMultiSelect.length > 0
    ) {
      setIsSelectPallet(true);
      setSegments(getSelectedPallet.gridMultiSelect);
      // dispatch(removeGridMultiSelect())
      setLabelName(getSelectedPallet?.labelName)
    } else {
      setIsSelectPallet(false);
      setSegments(null);
    }

  }, [getSelectedPallet]);

  const handleMultiSelectPallet = () => {

    if (segments != null) {
      dispatch(addSelectedPallete({ selectedPallete: segments }));
      dispatch(removeAllPalletImages());
      dispatch(
        addSelectedPalletImag({
          segmentName: labelName,
          selectedPlaletUrl: null,
          s3BucketImage: null,
          SwatchId: null,
        }))
      dispatch(
        addTabPannel({
          pannelTab: "Tab6",
        })
      );


      openModalselectSwatch(segments, labelName);
    }

  };


  // after selecting swatch close the di
  
  return (
    <div>

      {/* <Tooltip hasArrow label='Select Pallet' placement='bottom' bg='gray.300' color='black'

      >
        <i class="bi bi-ui-checks"
          onClick={handleMultiSelectPallet}></i>

      </Tooltip> */}

      {/* <Tooltip hasArrow label='Add Segments' placement='bottom' bg='gray.300' color='black'
      >
        <i class="bi bi-bounding-box-circles"
          onClick={handleAnotation}
        ></i>

      </Tooltip> */}

 
<Collapse in={isOpen} animateOpacity>
    <div
       className="select-pallet-btn"
       onClick={handleMultiSelectPallet}
    >
       <button type="button" class="chakra-button "><i class="bi bi-house-check"></i>  Select Pallet to Apply</button>
    </div>
   
  </Collapse>


    </div>
  )
}

export default SelectPallet
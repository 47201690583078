import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { addUserFolderData } from "../slices/FolderSlice";
const ChildStructure = ({ childId, resetChild }) => {
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (childId && isMounted.current &&token) {
      getChildStructure(childId);
      return () => {
        isMounted.current = false;
      };
    }
  }, [childId,token]);
  const getChildStructure = async (childId) => {
    const authToken = "Bearer " + token;
    try {
      const response = await axios.get(
        `https://aiadmin.dzinly.org/upload/folders/${childId}?populate=*`,
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization: authToken
            // Other headers if needed
          },
        }
      );
      //console.log("get child Structure", response);

      if (response.status === 200) {
        dispatch(
          addUserFolderData({
            userFolder: response?.data?.data?.children,
          })
        );
        resetChild(response?.data?.data?.children);
        isMounted.current = true;
        //   backToUploadSegmentation(response?.data?.data?.id);
      }
    } catch (e) {
      alert("Error in getting children structure", e.message);
      isMounted.current = true;
    }
  };
  return <div></div>;
};

export default ChildStructure;

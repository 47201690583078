import React, { useState, useEffect } from "react";
import { getCustomer } from "../login/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import masterImage from "../../assets/images/demo/home_images/no-image-.jpg";
import ProjectAlert from "../HomePage/ProjectAlert";
import { CircularProgress } from "@chakra-ui/react";
import { getAllProject, removeProjectView } from "../slices/ProjetcViewSlice";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./ListOfProject.css"
import { addProjectId } from "../slices/ProjectSlice";
import { addCurrentTab } from "../slices/CurrentTabSlice";
import LoadProject from "../HomePage/LoadProject";
import { getProjectId } from "../slices/ProjectSlice";
import DeleteProject from "./DeleteProject";
import DeleteProjectAlert from "./DeleteProjectAlert";
import { getIsDeleteOptions, deleteOption } from "../slices/AlertSlice";
import DeleteAPI from "./DeleteAPI";
import EditProjectName from "../HomePage/RenameProject/EditProjectName";
import RenameToDB from "./RenameProject/RenameToDB";
import { comboProjectId } from "../slices/combinationSwatch/CombinationSwatchSlice"
import LoadDesignerProject from "./LoadDesignerProject";
import { Link } from 'react-router-dom';
import { resetAllProjectImage } from "../slices/CreateProjectImageSlice"
const ListOfProject = () => {
  const CustomerDetail = useSelector(getCustomer);
  const [projectIds, setProjectIds] = useState();
  const getAllProjects = useSelector(getAllProject);
  const [isLoading, setIsLoading] = useState(true);
  const getDeleteOption = useSelector(getIsDeleteOptions);
  const getProjectDetails = useSelector(getProjectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobId, setJobId] = useState();
  const [isOpen, setIsopen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isDesigner, setIsDesigner] = useState(false);
  // reload the project
  useEffect(() => {
    if (getProjectDetails.reload) {
      setIsReload(true);
      setProjectIds(CustomerDetail.customerId);
      setIsLoading(true);
      removeProjectView();
      dispatch(
        addProjectId({
          projectId: getProjectDetails.projectId,
          projectName: getProjectDetails.projectName,
          jobId: getProjectDetails.jobId,
          reload: false,
          base64Image: getProjectDetails.base64Image,
        })
      );
    }
  }, [getProjectDetails]);
  useEffect(() => {
    if (CustomerDetail &&
      CustomerDetail.isAuthenticated &&
      CustomerDetail.customerId &&
      CustomerDetail.role
    ) {
      if (CustomerDetail.role === "customer") {
        setProjectIds(CustomerDetail.customerId);
        setIsCustomer(true);
        setIsDesigner(false)
      } else if (CustomerDetail.role === "designer") {
        setProjectIds(CustomerDetail.customerId);
        setIsCustomer(false);
        setIsDesigner(true)
      }

    }
  }, [CustomerDetail]);

  useEffect(() => {
    if (getAllProjects && getAllProjects.length > 0) {
      setIsLoading(false);
    } else {
      setIsReload(true);
      setProjectIds(CustomerDetail.customerId);
      setIsLoading(true);
    }
  }, [getAllProjects]);

  //handleing load
  const handleLoading = (load) => {
    setIsLoading(load);
    if (!load) {
      setIsCustomer(false)
      setIsDesigner(false)
      setProjectId(null)
    }

  };
  // handle project
  const handleProject = (projectName, projectId, JodId, Base64Image, projectImage) => {
    // call back to open folder structure
    // 
    // reset the project image and reload as false
    dispatch(resetAllProjectImage())
    dispatch(comboProjectId({
      projectId: projectId,
    }))
    setJobId(JodId);
    setIsopen(true);
    dispatch(
      addProjectId({
        projectId: projectId,
        projectName: projectName,
        jobId: JodId,
        reload: false,
        base64Image: Base64Image,
        projectImage: projectImage,
        isProjectCreated: false
      })
    );

    let ReloadData = {
      projectId: projectId,
      projectName: projectName,
      jobId: JodId,
      reload: true,
      base64Image: Base64Image,
      projectImage: projectImage
    };
    sessionStorage.setItem("ReloadData", JSON.stringify(ReloadData));
    dispatch(addCurrentTab(0));

    navigate("/projectdraft/" + projectId);
    // navigate("/projectview/" + JodId);
    // navigate("/newprojectview");
  };

  // handle resetJob Id
  const handleResetJobId = () => {
    setJobId(null);
    setIsopen(false);
  };

  // dots options
  const [isOptions, setIsOptions] = useState(false);
  const [projectName, setProjectName] = useState();
  const [projectId, setProjectId] = useState();
  const handleOptions = (name, id) => {
    setIsOptions(true);
    setProjectName(name);
    setProjectId(id);
  };

  //open delete Alert
  const handleOpenDelete = () => {
    setIsDeleteOpen(true);
  };
  const [isCallDelete, setIsCallDelete] = useState();
  const handleDeleIfYes = () => {
    setIsDeleteOpen(false);
    setIsCallDelete(true);
    setIsOptions(false);
  };
  // hide the option

  const handleCloseOption = () => {
    setIsOptions(false);
    setIsDeleteOpen(false);
  };

  // handle close Modal
  const handleCloseModal = () => {
    setIsDeleteOpen(false);
    setIsOptions(false);
  };
  const handleResetProjectId = () => {
    setProjectId(null);
    setIsCallDelete(false);
  };
  // useEffect(() => {
  //   if (getDeleteOption) {
  //     setIsOptions(false);
  //     dispatch(
  //       deleteOption({
  //         isDeleteOptions: true,
  //       })
  //     );
  //   }
  // }, [getDeleteOption]);

  // handle reset option
  const handleResetOption = () => {
    setIsOptions(!isOptions);
    setProjectId(null);
  };

  const handleRemoveOption = () => {
    setIsOptions(false);
  };

  const [isRenameForm, setIsRenameForm] = useState(false);
  // handel edit project name form
  const handleEditProjectName = () => {
    setIsRenameForm(true);
  };
  // rename form close
  const handleRenameFormModal = () => {
    setIsRenameForm(false);
    setIsOptions(false);
  };

  // callinf API to add data to DB
  const [isRename, setIsRename] = useState(false);
  const handleRename = (newName) => {
    setProjectName(newName);
    setIsRename(true);
  };

  // reset the rename data
  const handleResetRenamedata = () => {
    setProjectName(null);
    setProjectId(null);
    setIsRename(false);
    setIsRename(false);
    setIsOptions(false);
  };
  return (
    <>
   
      {isLoading ? (
        <div>
          <CircularProgress isIndeterminate color="green.300" />
          <ProjectAlert />
        </div>
      ) : (
        getAllProjects &&
        getAllProjects.length > 0 &&
        getAllProjects.map((image, index) => (
          <div className="card " key={index}>
            <div
              className="card-content"
            
            >
             
              <div className="job-content">
                <Button
                  variant="solid"
                  colorScheme="blue"
                  size="md"
                  borderRadius="md"
                  key={image?.attributes?.jobs?.data[0]?.id}
                  onMouseEnter={handleRemoveOption}
                >
                  <span
                    className="create-project-icon"
                    onClick={() =>
                      handleProject(
                        image?.attributes?.name,
                        image?.id,
                        image?.attributes?.jobs?.data[0]?.id,
                        image?.attributes?.ImageBase64,
                        image?.attributes?.image?.data?.attributes?.formats
                          ?.large?.url
                      )}
                  >
                    {image?.attributes?.name}
                  </span>
                </Button>
                <Link className="model-project" to={`/projectdraft/${image?.attributes?.jobs?.data[0]?.id}`} onClick={() =>
                  handleProject(
                    image?.attributes?.name,
                    image?.id,
                    image?.attributes?.jobs?.data[0]?.id,
                    image?.attributes?.ImageBase64,
                    image?.attributes?.image?.data?.attributes?.formats
                      ?.large?.url
                  )}>
                  <i className="bi bi-box-arrow-up-right"></i>
                </Link>




                <div className="project-details-icon">
                  <i
                    class="bi bi-three-dots"
                    onClick={() =>
                      handleOptions(image?.attributes?.name, image?.id)
                    }
                  ></i>
                </div>
              </div>

              {isOptions &&
                projectName === image?.attributes?.name &&
                projectId === image?.id && (
                  <DeleteProject

                    closeOption={handleCloseOption}
                    reSetOption={handleResetOption}
                    openAlert={handleOpenDelete}
                    editProjectName={handleEditProjectName}
                  />
                )}


            </div>

            {image &&
              image?.attributes &&
              // image?.attributes?.image &&
              // image?.attributes?.image?.data &&
              // image?.attributes?.image?.data?.attributes?.formats?.thumbnail
              //   .url &&
              image?.attributes?.jobs &&
              image?.attributes?.jobs?.data &&
              image?.attributes?.jobs?.data?.length > 0 ? (
              <div
                className="media-images"
                onClick={() =>
                  handleProject(
                    image?.attributes?.name,
                    image?.id,
                    image?.attributes?.jobs?.data[0]?.id,
                    image?.attributes?.ImageBase64,
                    image?.attributes?.image?.data?.attributes?.formats
                      ?.large?.url
                  )
                }
              >
                {
                image?.attributes?.image?.data?.attributes?.formats
                      ?.thumbnail?.url ?(
                          <img
                  class="shimmerBG media"
                  src={
                    image?.attributes?.image?.data?.attributes?.formats
                      ?.thumbnail?.url
                  }
                  alt="project-Image"
                  key={image?.attributes?.image?.data?.attributes?.id}
                /> 
                      ):
                      (
                        <img
                        class="shimmerBG media"
                        src={image?.attributes?.ProjectImageUrl
                        }
                        alt="url-project-Image"
                        key={image?.attributes?.image?.data?.attributes?.id}
                      />
                      )

              }
               
               
              </div>
            ) : (
              <img
                className="master-images-1"
                src={masterImage}
                alt="masterImage"
              />
            )}

          </div>
        ))
      )}
      
      {isCustomer && (
        <LoadProject projectIds={projectIds} callLoad={handleLoading} />
      )}
      {isDesigner && (
        <LoadDesignerProject projectIds={projectIds} callLoad={handleLoading} />
      )}
      {isDeleteOpen && (
        <DeleteProjectAlert
          isDeleteOpen={isDeleteOpen}
          onClose={handleCloseModal}
          deleteSegment={handleDeleIfYes}
        />
      )}
      {isCallDelete && (
        <DeleteAPI
          projectId={projectId}
          resetProjectId={handleResetProjectId}
        />
      )}
      {isRenameForm && (
        <EditProjectName
          isOpen={isRenameForm}
          onClose={handleRenameFormModal}
          rename={handleRename}
          projectName={projectName}
        />
      )}
      {isRename && (
        <RenameToDB
          projectId={projectId}
          projectName={projectName}
          reSetOption={handleResetRenamedata}
        />
      )}

    </>
  );
};

export default ListOfProject;

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addProjectView } from "../slices/ProjetcViewSlice";
import { GET_USER_PROJECT } from "../GraphQL/GraphQl";
const ReloadProject = ({ projectIds, resetProjectId }) => {
  const dispatch = useDispatch();
  const { loading, error, data, refetch } = useQuery(GET_USER_PROJECT, {
    variables: {
      projectId: projectIds,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (loading) {
      //   callLoad(loading);
    } else if (data) {
      if (
        data.customers &&
        data.customers.data &&
        data.customers.data[0] &&
        data.customers.data[0].attributes &&
        data.customers.data[0].attributes.projects &&
        data.customers.data[0].attributes.projects.data.length > 0
      ) {
        dispatch(
          addProjectView(data.customers.data[0].attributes.projects.data)
        );
        resetProjectId();
        // callLoad(loading);
      } else {
        alert("No Project in your account");
        // callLoad(loading);
      }
    }
  }, [data, loading]);
  return <div></div>;
};

export default ReloadProject;

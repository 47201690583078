import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { GET_PROJECT_BY_ID } from "../GraphQL/ProjectUrlGraphql";

import { useQuery,useApolloClient } from "@apollo/client";

import {addMasterArray} from "../slices/MasterArraySlice" ;
import {addProjectId} from "../slices/ProjectSlice"
const GetProjectById = ({projectId,resetProjectId}) => {
  const [project_id, setproject_id]= useState(null)


  const dispatch= useDispatch();
  useEffect(()=>{
    if(projectId!=null){
        setproject_id(projectId)  
    }
  },[projectId])
    
    const { loading, error,data,refetch } = useQuery(GET_PROJECT_BY_ID, {
        variables: {
            project_id: project_id,
        },
        fetchPolicy: 'network-only',
      });


      useEffect(()=>{
        if(data && !loading){
            console.log("data",data)
   
             if(data &&
                data.projects &&
                data.projects.data &&
                data.projects.data[0] &&
                data.projects.data[0].attributes &&
                data.projects.data[0].attributes &&
                data.projects.data[0].attributes.jobs &&
                data.projects.data[0].attributes.jobs.data &&
                data.projects.data[0].attributes.jobs.data[0] &&
                data.projects.data[0].attributes.jobs.data[0].attributes &&
                data.projects.data[0].attributes.jobs.data[0].attributes.JobJson&& 
                data.projects.data[0].attributes.jobs.data[0].attributes.JobJson.length>0 

             )

        dispatch(addMasterArray(data.projects.data[0].attributes.jobs.data[0].attributes.JobJson))
         dispatch(addProjectId({
          projectId:data.projects.data[0].id,
          projectName:data.projects.data[0].attributes.name,
          jobId: data.projects.data[0].attributes.jobs.data[0].id,
          reload:false,
          base64Image:data.projects.data[0].attributes.ImageBase64,
          projectImage:data.projects.data[0].attributes.ProjectImageUrl

         }))   
        resetProjectId()
        }
      },[data, loading])
  return (
    <div></div>
  )
}

export default GetProjectById
import React, { useEffect, useState, useRef } from "react";
import { getJobArray } from "../slices/JobArratSlice";
import {
  selectedSwatch,
  addSelectedSwatch,
} from "../slices/SelectedPalletdSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { selectAllSegments } from "../slices/AllSegmentSlice";
import { getProjectId } from "../slices/ProjectSlice";
import SegmentationTab from "../Tabs/SegmentationTab";
import ReloadJob from "../Add_Edit_Pallet/ReloadJob";
import { useToast } from "@chakra-ui/react";
import { palletLoading } from "../slices/AlertSlice";
import { getAllMasterJobs } from "../slices/MasterJobs";
import {removeEditSegment} from "../slices/EditSegment/EditSegment"
import {removeSelectedPallete} from "../slices/SelectedPalletdSlice"
import{getAllShadows} from "../slices/shadow/ShadowSlice"

import {selectAllItems} from "../slices/MasterArraySlice"
const AddSwatchIntoJob = ({ palletId, reSetPallet }) => {
  const dispatch = useDispatch();
  const [uploadSwatchId, setUploadSwatchId] = useState();
  //   const getJobArray = useSelector(getJobArray);
  const getSelectedPalletDetails = useSelector(selectedSwatch);

  const getAllSegment = useSelector(selectAllSegments);
  const getprojectDetails = useSelector(getProjectId);
  const [job_Id, setJob_id] = useState();
  const [uploadSwatchIds, setUploadSwatchIds] = useState();
  const [isJob, setISJob] = useState(false);
  const toast = useToast();

  const getMasterJobs = useSelector(getAllMasterJobs);
  const isMounted1 = useRef(true);
  const isMounted2 = useRef(true);
  const getAllShadowss = useSelector(getAllShadows)
  const getMasterArray= useSelector(selectAllItems)
  useEffect(() => {
    //
    if (
      getSelectedPalletDetails.uploadSwatchId &&
      getSelectedPalletDetails.uploadSwatchId.length > 0 &&
      isMounted1.current
    ) {
      setUploadSwatchIds(getSelectedPalletDetails.uploadSwatchId);
      return () => {
        isMounted1.current = false;
      };
    }
  }, [getSelectedPalletDetails]);

  useEffect(() => {
    if (getAllShadowss&&
      getMasterArray&&
      getMasterArray.master&&
      getMasterArray.master.length>0 &&
      isMounted2.current
      ) {
      //addSwatch(getMasterJobs.masterJob);
       // 
          let allShadows=getAllShadowss.allShadow;
          let masterJson=getMasterArray.master
          addSwatch(getMasterJobs.masterJob,masterJson,allShadows);
        
      return () => {
        isMounted2.current = false;
      };
    }
  }, [getAllShadowss,getMasterArray]);

  const addSwatch = async (allArray,masterJson,shadows) => {
    //
    let data = {
      data: {
        Segmentation: allArray,
        Shadows:shadows,
        JobJson:masterJson,
      },
    };

    try {
      if (data != null) {
        let jobId = getprojectDetails.jobId;

        const responce = await axios.put(
          process.env.REACT_APP_API_STRAPI + "jobs/" + jobId,
          data
        );
        if (responce.status === 200) {
          dispatch(removeEditSegment()) // reset the edit annottaion
          dispatch(removeSelectedPallete())
          setJob_id(responce?.data?.data?.id);
          setISJob(true);
          toast({
            title: "Swatch is updated successfully",

            status: "success",
            duration: 5000,
            isClosable: true,
            position: "center-bottom",
          });
          reSetPallet(responce?.data?.data?.id);
          isMounted2.current = true;
          isMounted1.current = true;
        }
      }
    } catch (e) {
      alert(e.message);
      // console.log(e);
      dispatch(palletLoading({ palletLoading: false }));
      isMounted2.current = true;
      isMounted1.current = true;
      reSetPallet()
    }
  };

  return <></>;
};

export default AddSwatchIntoJob;

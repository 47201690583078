import React from 'react'
import { Tab,TabPanel } from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/react'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import {useDispatch, useSelector} from "react-redux";
import { selectAllItems } from "../../../slices/MasterArraySlice";

const SelectSegment = ({labelName}) => {

    const getMasterArray = useSelector(selectAllItems);
    
    const handleSelectLabel=(segmentName)=>{
      labelName(segmentName)
    }
  return (
    <div>
            <TabPanel>
                   
                      
                        <div>
                        <h3 className="select-swatch-title ">Select Swatch</h3>
                        <div className="project-segments-img">
                        {getMasterArray &&
                        getMasterArray.master&&
                        getMasterArray.master.length>0 &&
                        getMasterArray.master.map(item=>{
                            return (
                               
                            <div className="project-seg-images"  key={item.id}
                            onClick={()=>handleSelectLabel(item.name)}
                            >
                                <div className="seg-contant"

                                >
                                <h2
                                key={item.name}>{item.name}</h2>
                                </div>
                                <div className="segament-img">
                                <img src={item.icon} width={25} alt={item.name} />
                                </div>
                            </div>
                             
                            

                          
                            )
                        })}
                            

                            </div> 
                        </div>
                                                
                   

                </TabPanel>

    </div>
  )
}

export default SelectSegment

{/* <div>
<h3 className="select-swatch-title ">Select Swatch</h3>
    <div className="project-segments-img">
    <div className="project-seg-images">
        <div className="seg-contant">
        <h2>Door</h2>
        </div>
        <div className="segament-img">
        <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_2_d22f5ceb86.png"></img>
        </div>
    </div>
     
    <div className="project-seg-images">
        <div className="seg-contant">
        <h2>Window</h2>
        </div>
        <div className="segament-img">
        <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_2_d22f5ceb86.png"></img>
        </div>
    </div>

    </div> */}

   
// </div>
import { createSlice } from "@reduxjs/toolkit";

const FolderSlice = createSlice({
  name: "folder",
  initialState: {
    folderData: [],
    projectId: null,
    userFolder: [],
    segmentFolder: [],
  },
  reducers: {
    addFolderData: (state, action) => {
      state.folderData = action.payload.folderData;
      state.projectId = action.payload.projectId;
    },

    addSegementFolderData: (state, action) => {
      state.segmentFolder = action.payload.segmentFolder;
    },
    addUserFolderData: (state, action) => {
      state.userFolder = action.payload.userFolder;
    },
    removeFolder: (state, action) => {
      state.folderData = [];
      state.projectId = null;
    },
  },
});
export const {
  addFolderData,
  addSegementFolderData,
  addUserFolderData,
  removeFolder,
} = FolderSlice.actions;
export const getFolderData = (state, action) => state.folder;
export default FolderSlice.reducer;

//Import necessary libraries
import React, { useState, useEffect } from "react";
import { Line, Circle, Group, Transformer } from "react-konva";
import { minMax, dragBoundFunc } from "../utils";

/**
PolygonAnnotation is a React component that allows users to create and edit polygons
@param {Array} props.points An array of points to render as the polygon
@param {Array} props.flattenedPoints An array of flattened points
@param {boolean} props.isFinished Indicates if the polygon is complete or not
@param {function} props.handlePointDragMove A function that handles the movement of the polygon's points
@param {function} props.handleGroupDragEnd A function that handles the end of a group's drag event
@param {function} props.handleMouseOverStartPoint A function that handles mouseover events for the polygon's starting point
@param {function} props.handleMouseOutStartPoint A function that handles mouseout events for the polygon's starting point
 @param {string} props.getTargetData The prop to conditionally handle
@returns {JSX.Element}
*/

const PolygonAnnotation = (props) => {
  //Extract props
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    shouldDisplay,
    getTargetData,
    isEditMode,
    isSelected,

    // New prop to control rendering
  } = props;
  useEffect(() => {}, [getTargetData]);
  // ;
  //Set the radius of the polygon's vertices
  const vertexRadius = 3;

  //Create state variables to hold the stage and min/max values
  const [stage, setStage] = useState();
  const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis

  //Function that handles mouseover events for the polygon's group
  const handleGroupMouseOver = (e) => {
    if (!isFinished) return;
    e.target.getStage().container().style.cursor = "move";
    setStage(e.target.getStage());
  };

  //Function that handles mouseout events for the polygon's group
  const handleGroupMouseOut = (e) => {
    e.target.getStage().container().style.cursor = "default";
  };

  //Function that handles the start of a group's drag event
  const handleGroupDragStart = (e) => {
    let arrX = points.map((p) => p[0]);
    let arrY = points.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };

  //Function that sets the bounds for the group's drag event
  const groupDragBound = (pos) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };

  // Render the polygon
  return (
    <>
      <Group
        name="polygon"
        draggable={isFinished}
        onDragStart={handleGroupDragStart}
        onDragEnd={handleGroupDragEnd}
        dragBoundFunc={groupDragBound}
        onMouseOver={handleGroupMouseOver}
        onMouseOut={handleGroupMouseOut}
      >
        <Line
          points={flattenedPoints}
          stroke="#00F1FF" // Change to a neon color, e.g., "#00FF00" for green
          strokeWidth={2} // Increase the stroke width for a bolder appearance
          opacity={0.8} // You can adjust the opacity for a softer glow
          closed={isFinished}
        />
        {points &&
          points?.map((point, index) => {
            const x = point[0] - vertexRadius / 2;
            const y = point[1] - vertexRadius / 2;
            const startPointAttr =
              index === 0
                ? {
                    hitStrokeWidth: 12,
                    onMouseOver: handleMouseOverStartPoint,
                    onMouseOut: handleMouseOutStartPoint,
                  }
                : null;
            return (
              <Circle
                key={index}
                x={x}
                y={y}
                radius={vertexRadius}
                fill="#FF019A"
                stroke="#00F1FF"
                strokeWidth={1}
                draggable
                onDragMove={handlePointDragMove}
                dragBoundFunc={(pos) =>
                  dragBoundFunc(
                    stage.width(),
                    stage.height(),
                    vertexRadius,
                    pos
                  )
                }
                {...startPointAttr}
              />
            );
          })}
      </Group>
      {isSelected && (
        <Transformer
          anchorSize={6}
          borderStroke="#00F1FF"
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

// Export the PolygonAnnotation component as the default export of the module
export default PolygonAnnotation;

import React from 'react'
import {useEffect, useState,useRef} from'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCreateProject } from "../../slices/CreateProjectImageSlice";
import { getModelVersion ,addModelVersion} from "../../slices/Model/ModelSlice.js";
import { nullSegmentValue } from "../../slices/LoadingSlice.js";
import { getSamLoading, samLoading } from "../../slices/AlertSlice.js";
import axios from "axios"

import SegredateSamData from "../../SegredateSamData/SegredateSamData.js";
import {openCreatePorjectModal} from "../../slices/CreateProjectHeaderSlice/CreateProjectHeaderSlice.js"
import { useNavigate } from 'react-router-dom';
import {resetAddProject} from "../../slices/addProject/AddProjectSlice.js"
const CallAIModel = () => {
    const getCreateProjectImage = useSelector(getCreateProject);
    const getModelVersions = useSelector(getModelVersion);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [samDataFromAPI, setSamDataFromAPI] = useState([]);
    useEffect(() => {
      if (getCreateProjectImage?.image&&
        getModelVersions?.modelApi!=null  &&
        getCreateProjectImage?.projectId
        ) {
       // handleUploadImage(getCreateProjectImage.image);
  
        handleImageToTrain(getCreateProjectImage.image,getCreateProjectImage.projectId);
      }else{
        
      }
    }, [getCreateProjectImage,getModelVersions]);

    const ismounted = useRef(true);
    const handleImageToTrain = async (uploadedImage,project_id) => {
      if (uploadedImage &&project_id) {
        let apiPath;
        if (
          getModelVersions.modelVersion &&
          getModelVersions.modelApi &&
          getModelVersions.modelId
        ) {
          apiPath = getModelVersions.modelApi;
        } else {
           apiPath = "object-detectation-api";
         // apiPath = "yolo-model-v2";
          dispatch(
            addModelVersion({
              modelVersion: "M1",
              modelApi: "yolo-model-v2",
            })
          );
        }
        dispatch(samLoading({ samLoading: true }));
        let formdataImage = new FormData();
        formdataImage.append("image", uploadedImage);
        formdataImage.append("projectId", project_id);
        if (formdataImage != null && ismounted.current) {
          ismounted.current = false;
  
          try {
            const modelData = await axios.post(
              `https://api.dzinly.org/${apiPath}`,
              formdataImage
            );
            formdataImage = null;
            if (modelData.status === 200) {

              //close create project model
              // dispatch(openCreatePorjectModal({
              //   isProjectCreated:false,
          
              // }))


              ismounted.current = true;
              //setIsSamLoading(false);
              sessionStorage.setItem("sessionStorage", JSON.stringify(modelData.data));
              setSamDataFromAPI(modelData.data);
              dispatch(
                nullSegmentValue({
                  deleteSegmentValue: false,
                })
              );
            }
          } catch (error) {
           console.log(error);

           dispatch(resetAddProject())
            alert(error.message)
          
            dispatch(samLoading({
              samLoading: false,
            }))
            navigate("/profile")

        
            
          }
        }
      }
    };


    const handleresetsamDataFromAPI = () => {
        setSamDataFromAPI(null);
      };
  return (
    <div>


      {samDataFromAPI!=null &&
        <SegredateSamData
          samDataFromAPI={samDataFromAPI}
          resetsamDataFromAPI={handleresetsamDataFromAPI}
        />
      }
    </div>
  )
}

export default CallAIModel
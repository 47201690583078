import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addSelectedSwatch } from "../slices/SelectedPalletdSlice";
import AddSwatchIntoJob from "./AddSwatchIntoJob";
import { compareProject } from "../slices/AlertSlice";
import { updateMasterJob } from "../slices/MasterJobs";
import {addCurrentPallete} from "../slices/CurrentSelectedPalleteSlice"
import { palletLoading } from "../slices/AlertSlice";
import {updateSwatchAllSeg} from '../slices/AllSegmentSlice' 
import {selectedPalletStart} from "../slices/EditSegment/EditSegment"
import {addSelectedPalletImag} from "../slices/TransparentPalletImgSlice"
import {updateSwatchMasterArray} from "../slices/MasterArraySlice"
import {getCustomer} from "../login/AuthSlice"
import { useSelector } from "react-redux";
const MultiUpload = ({ multiNewUpload, resetData2, userFolderId }) => {
  const [multiImage, setMultiImage] = useState();
  const dispatch = useDispatch();
  const [upoadedId, setUploadedId] = useState();
  const [isAddJob, setIsAddJob] = useState(false);
  const getUserDetail= useSelector(getCustomer)
  let token= sessionStorage.getItem('token');
  useEffect(() => {
    if (multiNewUpload &&token) {
      setMultiImage(multiNewUpload);
    }
  }, [multiNewUpload,token]);

  useEffect(() => {
    if (multiImage != null) {
      handleUploadMultiImage(multiImage);
    }
  }, [multiImage]);

  const handleUploadMultiImage = async (multiImage) => {

  
    try {
      if (multiImage && multiImage.multiImage != null) {
        for (const [index, item] of multiImage.multiImage.entries()) {
          const tets1 = "data:image/png;base64," + item.output;
          // Decode the base64 image
          let decodedImag1 = atob(tets1.split(",")[1]);
          // Convert the decoded data to a Uint8Array
          let uint8Array = new Uint8Array(decodedImag1.length);
          for (let i = 0; i < decodedImag1.length; i++) {
            uint8Array[i] = decodedImag1.charCodeAt(i);
          }

          // Create a Blob from the Uint8Array
          let blob = new Blob([uint8Array], { type: "image/png" });
          let fileName1 = item.class_name; // Replace with your desired file name
          const file = new File([blob], fileName1, { type: "image/png" });
          // Create a FormData object and append the File

          let formData = new FormData();
          formData.append("files", file);
          const fileInfo = { name: file.name, folder: userFolderId.toString() };
          formData.append("fileInfo", JSON.stringify(fileInfo));

          await uploadImage(formData, index);
        }

        // Additional code after all images are uploaded
      }
    } catch (e) {
      alert("Error in calling multi upload");
    }
  };

  const uploadImage = async (formData, index) => {
    const authToken = "Bearer " + token;
    try {
      const response = await axios.post(
        "https://aiadmin.dzinly.org/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authToken
          },
        }
      );

      if (response.status === 200) {
        
        let data = response?.data[0]?.id;
    const uploadData= JSON.parse(sessionStorage.getItem("uploadData"))
        dispatch(
          addSelectedSwatch({
            encodeImage: null,
            uploadSwatchId: response?.data,
            swatchId: multiImage.swatchId,
            swatchName: multiImage.multiImage,
            palletUrl: multiImage.palletUrl,
          })
        );

  dispatch(addSelectedPalletImag({
    selectedPlaletUrl:response?.data[0].url,
    segmentName:response?.data[0]?.name,
    SwatchId:multiImage.swatchId,
    s3BucketImage:uploadData?.palletUrl
  }))
       
        dispatch(addCurrentPallete({
          segmentName: response?.data[0]?.name,
          s3BucketImage: uploadData?.palletUrl,
          swatchId:  multiImage.swatchId,
          palletImage:response?.data[0].url
        }))
        dispatch(
          updateMasterJob({
            segName: multiImage.multiImage,
            swatch: multiImage.swatchId,
            segmentImage: response?.data,
            userId:getUserDetail.userId
            
          })
        );

        dispatch(updateSwatchAllSeg({
          segmentName:response?.data[0]?.name,
          swatchId: multiImage.swatchId,
          palletImage:response?.data[0].url,
          swatchImage:uploadData?.palletUrl

        }))

        dispatch(updateSwatchMasterArray({
          labelName:multiImage.swatchName,
           segmentName:response?.data[0]?.name,
           swatchId:multiImage.swatchId,
           palletImage:response?.data[0].url,
           swatchImage:uploadData?.palletUrl,
          
        }))
        dispatch(palletLoading({ palletLoading: false }));
        dispatch(
          compareProject({
            isCompare: true,
          })
        );


        // activate sava button 
        dispatch(selectedPalletStart(
          {
            isSelectPalletStart:true 
          }
        ))
        setIsAddJob(true);
        if (index + 1 === multiImage.multiImage.length) {
          resetData2();
          setUploadedId(response?.data[0].id);
          setMultiImage(null);
        }
      }
    } catch (e) {
      alert("Error in calling multi upload");
    }
  };

  return <div></div>;
};

export default MultiUpload;

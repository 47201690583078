import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { fabric } from "fabric";
import{getProjectId} from "../slices/ProjectSlice";
import "../ImageView/ImageView.css"
import $ from 'jquery';

import AddSgmentForm from "../AddSegemnt/AddSgmentForm.js";
import {getStartAnnotation,
  saveAnnotation,
  saveSegment,
  getFinishAddSegment,
  getEditSegmentation} from "../slices/EditSegment/EditSegment.js";
import SaveAnnotationModal from "./SaveAnnotationModal";

import { addNewMasterJob } from "../slices/MasterJobs.js";
import { addNestedSegemnt } from "../slices/AllSegmentSlice.js";
import { addNestedObjectToSegment } from "../slices/MasterArraySlice.js";
import {updateCurrenPallate} from "../slices/CurrentSelectedPalleteSlice.js"
import {palletLoading} from "../slices/AlertSlice.js"
import {startUndo,getStartUndo} from "../slices/EditSegment/EditSegment.js"

import {getCanvasSize} from "../slices/windowSizeSlice/WindowResolution.js"
import {addShadow,addOnHoverLAyer} from "../slices/shadow/ShadowSlice.js"
import "./Mangnifier.css"
const ReAnnotationPage = ({moveToHoverLayer}) => {
  const dispatch= useDispatch()
    const canvasRef = useRef(null);
    const magnifierLensRef = useRef(null);
const magnifierContentRef = useRef(null);

    const getProjectImage=useSelector(getProjectId);
    const [scaleX, setScalex]= useState(null);
    const [scaleY, setScaley]= useState(null);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    
    const getCanvasSizes= useSelector(getCanvasSize)
    const [isCanvas, setIsCanvas]=useState(false)
    var min = 99;
    var max = 999999;
    var polygonMode = true;
    var pointArray = new Array();
    var lineArray = new Array();
    var activeLine;
    var activeShape =false;
    const [pointArrays, setPointArrays]=useState([])
    const [lineArrays, setLineArrays]=useState([])
   
    const[segmentName, setSegmentName] =useState()
    const[labelName, setLabelName] =useState()
  
    const isMountCanvasBackground= useRef(true)
    useEffect(() => {

      
      if(
        getCanvasSizes?.canvasWidth!=null &&
        getCanvasSizes?.canvasHeight!=null&&
        getProjectImage?.base64Image){
        var canvas = new fabric.Canvas("ReAnnotaion-layer", {
          selectionBorderColor: 'green',
          width: getCanvasSizes.canvasWidth, // Set canvas width
          height:getCanvasSizes?.canvasHeight 
        });
        setIsCanvas(true)
        isMountCanvasBackground.current=true
        canvasRef.current = canvas; 
        canvasRef.current.on('mouse:down', handleMouseDown)
        canvasRef.current.on('mouse:up', handleMouseUp)
        canvasRef.current.on('mouse:move', handleMouseMove)
        canvasRef.current.on('mouse:out', handleMouseLeave);
       // canvasRef.current.on('mouse:wheel', handleZoom);
        

       
        return () => {

          canvas.off('mouse:down', handleMouseDown)
          canvas.off('mouse:up', handleMouseUp)
          canvas.off('mouse:move', handleMouseMove)
         // canvas.off('mouse:out', handleMouseLeave);
          //canvasRef.current.off('mouse:wheel', handleZoom);
          //document.querySelector('.ai-right-wrapper-body').removeChild(zoomOverlay);  
        }
        }
       }, [getCanvasSizes,getProjectImage]); 


       useEffect(()=>{
        //
        if(
          isCanvas&&
          canvasRef.current&&
          getProjectImage&&
            getProjectImage?.base64Image&&
            isMountCanvasBackground.current
            ){
               if (!canvasRef.current) {
    // Handle the case where canvasRef is null
    return;
  }

              isMountCanvasBackground.current = false;
              let image = getProjectImage?.base64Image;
              const encodedImage = "data:image/png;base64," + image;
        // Decode the base64 image
        const decodedImage = atob(encodedImage.split(",")[1]);
        // Convert the decoded data to a Uint8Array
        const uint8Array = new Uint8Array(decodedImage.length);
        for (let i = 0; i < decodedImage.length; i++) {
          uint8Array[i] = decodedImage.charCodeAt(i);
        }
        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "image/png" });
        // Create an object URL from the Blob
        let imageUrl = URL.createObjectURL(blob);
    
        var img = new window.Image();
        img.src = imageUrl;
        img.onload = () => {

          fabric.Image.fromURL(imageUrl, function (img) {
            canvasRef.current.setBackgroundImage(imageUrl,
               canvasRef.current.requestRenderAllBound.bind(canvasRef.current),{
                scaleX: canvasRef.current?.width / img?.width,
                scaleY: canvasRef.current?.height / img?.height,
              width:canvasRef.current.width,
              height:canvasRef.current.height,
              originX: "left",
              originY: "top",
            
              });
            })

            setScalex(canvasRef?.current?.width / img?.width);
            setScaley(canvasRef?.current?.height / img?.height);
          canvasRef.current.requestRenderAll();

// Update magnifier content
if (magnifierContentRef.current) {
  const context = magnifierContentRef.current.getContext('2d');
  context.drawImage(img, 0, 0, img.width, img.height, 0, 0, magnifierContentRef.current.width, magnifierContentRef.current.height);


}
        }
         }else{
          //dispatch(palletLoading({ palletLoading: true }));
         }
          
     },[canvasRef,getProjectImage,isCanvas]);

    
// add maginier kens
     const handleZoom = (options) => {
      if (!canvasRef.current) return;
  const pointer = canvasRef.current.getPointer(options.e);

  const magnifierLens = magnifierLensRef.current;
  const magnifierContent = magnifierContentRef.current;

  if (magnifierLens && magnifierContent) {
    
  
    magnifierLens.style.display = 'block';
    const lensSize = 5; // size of the magnifier lens
    const lens = 100; // size of the magnifier lens
    magnifierLens.style.left = `${lensSize}px`;
    magnifierLens.style.top = `${lensSize}px`;
   
    magnifierContent.style.left = `-${pointer.x+120}px`;
    magnifierContent.style.top = `-${pointer.y-30}px`;

    // Draw the cursor on the magnifier content
    const lensContext = magnifierContent.getContext('2d');
    console.log('magnifierContent',magnifierContentRef)

  }  
};


    

   // open add segement form
const getStartAnnotations =useSelector(getStartAnnotation)
useEffect(()=>{

  if(getStartAnnotations){
    setIsOpenEdit(true);
  }
},[getStartAnnotations])

     // close Add segement 
     const handleCloseEditModal = () => {
      setIsOpenEdit(false);
    };
    
const getSegmentDetail= useSelector(getEditSegmentation);
useEffect(()=>{
  //
  if(getSegmentDetail&&
    getSegmentDetail.segName&&
    getSegmentDetail.labelName
    ){
      setSegmentName( getSegmentDetail.segName)
      setLabelName(getSegmentDetail.labelName)
  }
},[getSegmentDetail])
   



    const handleMouseDown = (options) => {
      console.log("move down")
      debugger
      if(options.target && options.target.id === canvasRef.current._objects[1].id){
        generatePolygon(pointArray);
    }
    if(polygonMode){
        addPoint(options);
    }
      
    
    };
  

const handleMouseLeave = () => {

};
    const handleMouseMove = (options) => {
      console.log("move move")
      //console.log("option move",options.absolutePointer)
      handleZoom(options)
      // 
      if (activeLine && activeLine.class == "line") {
        var pointer = canvasRef.current.getPointer(options.e); // Potential issue here
        activeLine.set({ x2: pointer.x, y2: pointer.y });
    
        var points = activeShape.get("points");
        points[pointArray.length] = {
          x: pointer.x,
          y: pointer.y
        };
        activeShape.set({
          points: points
        });
        canvasRef.current.renderAll();
      }
      
     
    };
    
   

    const drawPolygon = () => {

      polygonMode = true;
      pointArray = new Array();
      lineArray = new Array();
     //activeLine;
     
    };

    const addPoint = (options) => {
      // ;
    
      var random = Math.floor(Math.random() * (max - min + 1)) + min;
      var id = new Date().getTime() + random;
      var circle = new fabric.Circle({
          radius: 1,
          fill: '#ffffff',
          stroke: 'rgb(255 1 154)',
          class:'point',
          strokeWidth: 3,
          left: (options.e.layerX),
          top: (options.e.layerY),
          selectable: false,
          hasBorders: false,
          hasControls: false,
          originX:'center',
          originY:'center',
          id:id
      });
      if(pointArray.length == 0){
          circle.set({
              fill:'red'
          })
      }
      var points = [(options.e.layerX),(options.e.layerY),(options.e.layerX),(options.e.layerY)];
        //console.log("points: ", points)
      let  line = new fabric.Line(points, {
          strokeWidth:1,
          fill: '#999999',
          stroke: 'rgb(7 239 253)',
          class:'line',
          originX:'center',
          originY:'center',
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false
      });

      debugger
      if(activeShape){
          var pos = canvasRef.current.getPointer(options.e);
   
          var points = activeShape.get("points");
          points.push({
              x: pos.x,
              y: pos.y
          });
          var polygon = new fabric.Polygon(points,{
            stroke:'rgb(7 239 253)',
              strokeWidth:2,
              fill: '#cccccc',
              opacity: 0.3,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false
          });
          canvasRef.current.remove(activeShape);
          canvasRef.current.add(polygon);
         
          activeShape = polygon;
          canvasRef.current.renderAll();

          // magnifierContentRef.current.remove(activeShape);
          // magnifierContentRef.current.add(polygon);
          // magnifierContentRef.current.renderAll();
      }
      else{
          var polyPoint = [{x:(options.e.layerX),y:(options.e.layerY)}];
          var polygon = new fabric.Polygon(polyPoint,{
              stroke:'#333333',
              strokeWidth:1,
              fill: '#cccccc',
              opacity: 0.3,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false,
              name:"poly"
          });
          activeShape = polygon;
          canvasRef.current.add(polygon);
        //  magnifierContentRef.current.add(polygon);
      }
      activeLine = line;

      pointArray.push(circle);
   
      let data={
        x: pointArray[0].left,
        y: pointArray[0].top

      }
      setPointArrays((prev) => [...prev, data]);
      
      lineArray.push(line);
      setLineArrays((prev) => [...prev, lineArray]);
      canvasRef.current.add(line);
      canvasRef.current.add(circle);
      canvasRef.current.selection = false;
      
    }
  
    const [isCompletedPolygon, setIsCompletedPolygon]= useState(false)
    const generatePolygon=(pointArray)=>{
      
        activeLine = null;
        activeShape = null;
        polygonMode = false;
        canvasRef.current.selection = true;
        setIsCompletedPolygon(true)
        //; 
    }

    
  
    const handleMouseUp = (options) => {
      console.log("mouse up")
      drawPolygon()
      
    };
    useEffect (()=>{
      if(isCompletedPolygon &&
         pointArrays &&
         pointArrays.length > 0){
          //
          //console.log("pointArrays:=",pointArrays)
          const duplicatedValue = { ...pointArrays[0] };

          const newPointArrays = [...pointArrays];
         // newPointArrays.pop(); 
          newPointArrays.push(duplicatedValue); 
      
          // Update the state with the modified array
          setPointArrays(newPointArrays);
      
          // Reset the flag
          setIsCompletedPolygon(false);
      
          //console.log("New point array: ", newPointArrays);
          createPolygon(newPointArrays)
      }
      
    },[pointArrays,isCompletedPolygon])

  const createPolygon = (newPointArrays)=>{
    
    canvasRef.current._objects.forEach((item, index) => {
      if (item.class === "point" || item.class === "line") {
        canvasRef.current.remove(item);
      }
    });
   
    var polygon = new fabric.Polygon(newPointArrays,{
            stroke:'rgb(7 239 253)',
            strokeWidth:2,
            fill: 'transparent',
            opacity: 1,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint:false,
            name:"new polygon"
        });
        canvasRef.current.add(polygon)
        canvasRef.current.requestRenderAll();
     
   
     dispatch(saveSegment({
      isSaveAddSegment:true,
      isAddSegmentsStart:false
     }))

  }

 // after selecting save Annotation
 const getFinishAddSegments= useSelector(getFinishAddSegment)
useEffect(()=>{
  //
  if(getFinishAddSegments){
    handleAddSegment()
  }
},[getFinishAddSegments])

  const handleAddSegment = () => {
    let objects= canvasRef.current._objects;

    objects.forEach((item, index) => {
    
      // if (item.name==="new polygon") {
      //   left = item.left
      //   top = item.top
      //   console.log("top", item.top)
      //   console.log("left", item.left)
        
      // }
    });
    const flattenedPoints = [];
    pointArrays.pop(); 
    pointArrays.forEach(point => {
      Object.entries(point).forEach(([key, value]) => {
        flattenedPoints.push(value);
      });
    })
    convertToBoundingBox(flattenedPoints, segmentName, labelName);
    
  }

  function convertToBoundingBox(flattenedPoints, segmentName, labelName) {
    const boundingBoxes = {};
    let BoundingBoxInt = [];
    let BoundingBoxFloat = [];
    let newFlattenedPoints=[]
    const points = flattenedPoints;

    // Find the minimum and maximum coordinates to form the bounding box
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
   
    for (let i = 0; i < points.length; i += 2) {
      const x = points[i]/scaleX;
      const y = points[i + 1]/scaleY;
      newFlattenedPoints.push(x,y)
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }

    // Calculate the area of the bounding box
    const width = maxX - minX;
    const height = maxY - minY;
    const area = width * height;
    BoundingBoxFloat.push(minX, minY, maxX, maxY);
    BoundingBoxInt.push(
      Math.floor(minX),
      Math.floor(minY),
      Math.floor(maxX),
      Math.floor(maxY)
    );

    let area_pixel = area;

    if (BoundingBoxFloat.length > 0 && BoundingBoxInt.length > 0 &&newFlattenedPoints.length>0) {
     
    //
    
      // add to master job
      if(segmentName!="Shadow"){
        // add to master Array slice
      dispatch(
        addNestedObjectToSegment({
          segment:segmentName ,
          label:labelName ,
          flattenedPoints: newFlattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
        })
      );


      // master jobs
        dispatch(
          addNewMasterJob({
            label: labelName,
            flattenedPoints: newFlattenedPoints.toString(),
            BoundingBoxInt: BoundingBoxInt.toString(),
            BoundingBoxFloat: BoundingBoxFloat.toString(),
            area_pixel: area_pixel.toString(),
          })
        );

        // upadate on current pallete slice
        dispatch(updateCurrenPallate({
          labelName:labelName

        }))
      }
        // all segemnt
      dispatch(
        addNestedSegemnt({
          label: labelName,
          flattenedPoints: newFlattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
        })
      );

        //update the shadow on hover layer
        dispatch(addOnHoverLAyer({
          label: labelName,
          flattenedPoints: newFlattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
          alpha_value:0.3
        }))
      
      

     
     
      // add to shadow slice
      if(segmentName==="Shadow"){
        dispatch(addShadow({
          segment:segmentName ,
          label:labelName ,
          flattenedPoints: newFlattenedPoints.toString(),
          BoundingBoxInt: BoundingBoxInt.toString(),
          BoundingBoxFloat: BoundingBoxFloat.toString(),
          area_pixel: area_pixel.toString(),
          alpha_value:0.3
  
        })
        )
      }
     
      //console.log("canvasref asfter", canvasRef.current)
     if(canvasRef.current &&
      canvasRef.current._objects&&
      canvasRef.current._objects.length > 0){
        canvasRef.current._objects.forEach((item, index) => {
          if (item.class === "point" || item.class === "line" || item.name === "new polygon") {
            canvasRef.current.remove(item);
           
          }
        })
    }
    moveToHoverLayer()
  }
}

//undo 
const getStartUndos= useSelector(getStartUndo)
useEffect(()=>{
  if(getStartUndos){
    handleUndoAnnotation();
    dispatch(
      startUndo({
        isUndoStart:false
      })
    )
  }
},[getStartUndos])
const handleUndoAnnotation = () => {
 // 
  if (pointArrays.length > 0) {
    // Remove the last point and line
    const updatedPointArrays = [...pointArrays];
    updatedPointArrays.pop();

    const updatedLineArrays = [...lineArrays];
    const lastLineArray = updatedLineArrays.pop();
    lastLineArray.forEach(line => canvasRef.current.remove(line));

    // Update state
    setPointArrays(updatedPointArrays);
    setLineArrays(updatedLineArrays);

    // Redraw the remaining points and lines
    updatedLineArrays.forEach(lineArray =>
      lineArray.forEach(line => canvasRef.current.add(line))
    );
  }
};
  
 return (
  <div>
    <div className="ai-right-wrapper-body Cursor project-layer-images  annotation-label-img ">
      <canvas id="ReAnnotaion-layer"
        width="1023"
        height="592"
        ref={canvasRef}
      ></canvas>
      <div className="magnifier-lens" ref={magnifierLensRef}>
        <canvas className="magnifier-content " ref={magnifierContentRef} width="1023" height="592"></canvas>
      </div>
    </div>
  </div>
);

}

export default ReAnnotationPage
import React, { useRef, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    FormControl,
    Select,
    Box
} from "@chakra-ui/react";
import { Input, FormLabel } from '@chakra-ui/react';
import { Textarea } from '@chakra-ui/react'
import SegFrom from "./SegmentationFrom"
import "./JobFrom.css";
import ParametersFrom from './ParametersFrom';

const JobFrom = ({ isFromOpen, onClose, submitSegment }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleCloseModal = () => {
        onClose();
    };

    const handleSubmit = () => {
        submitSegment();
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFile(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragImage = () => {
        fileInputRef.current.click();
    };

    const [isSegFromOpen, setisSegFromOpen] = useState(false);

    const handleSegFrom = () => {
        setisSegFromOpen(true);
    }
    

    const [isPraFromOpen, setisPraFromOpen] = useState(false);

    const handlePraFrom = () => {
        setisPraFromOpen(true);
    }

    return (
        <div>
            <Modal closeOnOverlayClick={false} blockScrollOnMount={false} isOpen={isFromOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className="job-from-popup">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton onClick={handleCloseModal} />
                    <ModalBody>
                        <div className='row'>
                            <div className='col-6'>
                                <FormLabel>Project</FormLabel>
                                <Select className='input-primary' placeholder='Project'>
                                    <option>1 May</option>
                                    <option>10 June</option>
                                </Select>
                            </div>

                            <div mt={4} className='col-6'>
                                <FormLabel>JobId</FormLabel>
                                <Input className='input-primary' placeholder='JobId' />
                            </div>



                            <div className='col-6 from-lable'>
                                <FormLabel>JobImage</FormLabel>
                                <Box
                                    className='box-primary'
                                    border="1px solid #cbd5e0"
                                    borderRadius="md"
                                    padding="20px"
                                    textAlign="center"
                                    onClick={handleDragImage}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <i class="bi bi-image"></i>
                                    <Text mt={2} color="gray.500" style={{ fontSize: "12px", fontWeight: "400", color: "rgb(102, 102, 135)" }}>Click to add an asset or drag and drop one in this area</Text>
                                    <Input
                                        type='file'
                                        accept='image/*'
                                        onChange={handleFileChange}
                                        ref={fileInputRef}
                                        display="none"
                                    />
                                </Box>
                            </div>

                            <div classname="col-12" style={{ marginTop: "12px" }}>
                                <FormLabel >Jobjson</FormLabel>
                                <Textarea style={{ backgroundColor: "#f6f6f9" }} />

                            </div>

                            <div className='col-6 from-lable'>
                                <FormLabel>JobView</FormLabel>
                                <Select className='input-primary' placeholder='JobView'>
                                    <option>Choose here</option>
                                    <option>Front</option>
                                    <option>back</option>
                                    <option>Left</option>
                                    <option>Right</option>
                                </Select>
                            </div>

                            <div mt={4} className='col-6 from-lable'>
                                <FormLabel>JobType</FormLabel>
                                <Select className='input-primary' placeholder='JobType'>
                                    <option>Choose here</option>
                                    <option>Design</option>
                                    <option>Arch</option>
                                </Select>
                            </div>

                            <div classname="col-12" style={{ marginTop: "12px" }}>
                                <FormLabel >Shadows</FormLabel>
                                <Textarea style={{ backgroundColor: "#f6f6f9" }} />
                            </div>


                            <div classname="col-12" style={{ marginTop: "12px" }}>

                                <FormLabel>Segmentation</FormLabel>

                                {!isSegFromOpen &&
                                    <Box
                                        className='box-primary'
                                        border="1px solid #cbd5e0"
                                        borderRadius="md"
                                        padding="20px"
                                        textAlign="center"
                                        onClick={handleSegFrom}

                                    >
                                        <i class="bi bi-plus-circle-dotted"></i>
                                        <Text mt={2} color="gray.500" style={{ fontSize: "12px", fontWeight: "400", color: "rgb(102, 102, 135)" }}>No entry yet. Click on the button below to add one.</Text>

                                    </Box>}

                            </div>
                            {isSegFromOpen &&
                                <SegFrom
                                />}


                            <div mt={4} className='col-6 mt-2'>
                                <FormLabel>PixelUnit</FormLabel>
                                <Input className='input-primary' />
                            </div>

                            <div mt={4} className='col-6 mt-2'>
                                <FormLabel>PixelValue</FormLabel>
                                <Input className='input-primary' />
                            </div>

                            <div mt={4} className='col-6 mt-2'>
                                <FormLabel>key</FormLabel>
                                <Input className='input-primary' />
                            </div>

                            <div className='col-6 mt-2'>
                                <FormLabel>Designers</FormLabel>
                                <Select className='input-primary' placeholder='Project'>
                                    <option>AAbb</option>
                                    <option>AAcc</option>
                                </Select>
                            </div>


                            <div classname="col-12" style={{ marginTop: "12px" }}>

                                <FormLabel>Parameters</FormLabel>
                                {!isPraFromOpen &&
                                    <Box
                                        className='box-primary'
                                        border="1px solid #cbd5e0"
                                        borderRadius="md"
                                        padding="20px"
                                        textAlign="center"
                                        onClick={handlePraFrom}

                                    >
                                        <i class="bi bi-plus-circle-dotted"></i>
                                        <Text mt={2} color="gray.500" style={{ fontSize: "12px", fontWeight: "400", color: "rgb(102, 102, 135)" }}>No entry yet. Click on the button below to add one.</Text>

                                    </Box>}
                            </div>
                            {isPraFromOpen &&
                                <ParametersFrom
                                />}


                                

                        </div>







                    </ModalBody>

                    <ModalFooter className="mx-4">
                        <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="ghost" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>


            </Modal>




        </div>
    );
}

export default JobFrom;

import { createSlice } from "@reduxjs/toolkit";


const OverLapSlice=createSlice({
    name:"overlap",
    initialState: { 
        isOverlap: false

    },
    reducers:{
        startOverlap:(state,action)=>{
            state.isOverlap=true
        },
        finishOverLaps:(state,action)=>{
            state.isOverlap=false
        }
    }
});

export const {startOverlap,finishOverLaps}=OverLapSlice.actions;

export const getOverLaps = state => state.overlap.isOverlap;

export default OverLapSlice.reducer;
import React from 'react'
import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux"
import {selectAllItems} from "../../slices/MasterArraySlice";
import {addCustomerCurrentPallet} from "../../slices/CustomerSlice/customerViewpalletSlice"
import {getCustomerCurrentPallet}  from "../../slices/CustomerSlice/customerViewpalletSlice"
const CustomerCurrentPallete = () => {
 const dispatch= useDispatch();
 const getMasterArray=  useSelector(selectAllItems)
   
const getCustomerMasters= useSelector(getCustomerCurrentPallet)
// check the customer current pallet array is available if not then add it
 useEffect(()=>{

    let customerCurrentPallet=[]
    if(getMasterArray &&
    getMasterArray.master &&
    getMasterArray.master.length>0 &&
    getCustomerMasters &&
    getCustomerMasters.length===0
){
        getMasterArray.master.map(item=>{
            if(item.segmentation.length>0){
                item.segmentation.map(seg=>{
                    customerCurrentPallet.push({
                        segmentName: seg.name,
                        swatchId: null,
                        SegmentImagePath:null,
                        checked:false
                    })
                })
            }
        })

        dispatch(addCustomerCurrentPallet({
            customerCurrentPallet: customerCurrentPallet
        }))
    }
 },[getMasterArray,getCustomerMasters])
  return (
    <div></div>
  )
}

export default CustomerCurrentPallete
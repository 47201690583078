import React from "react";
import { useSelector } from "react-redux";
import CreateProject from "../HomePage/CreateProject";
import Label_Segment from "../Label_Segment/Label_Segment";
import LabelAndBrand from "../All_Labels_Brands_SwatchStyle/All_Labels_Brands_SwatchStyle";
import { getCustomer } from "../login/AuthSlice";
import RefreshProfile from "./RefreshProfile";
import ModelVersion from "../Model/ModelVersion";
import CombinationSwatch from "../CombinationSwatch/GetALlCombination"
import Header from "../Header/Header";
import GetAllSwatches from "../Swatches/GetAllSwatches";
const HomePage = () => {
  const loginDetails = useSelector(getCustomer);

  return (
    <>
      <Header />
      {loginDetails.customerId ? <CreateProject /> : ""}
      {loginDetails.isAuthenticated && (
        <div>
          <Label_Segment />
          <LabelAndBrand />
          <ModelVersion />
          <CombinationSwatch/>
          <GetAllSwatches/>
        </div>
      )}
      <RefreshProfile />
    </>
  );
};

export default HomePage;

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectAllItems } from "../slices/MasterArraySlice"; // data hold all sehgments detail sexcept the swatch
import { selectAllSegments } from "../slices/AllSegmentSlice"; // data old the swatch of particular segments
import "../ImageView/ImageView.css";
import { getTranspalletImage } from "../slices/TransparentPalletImgSlice.js";
import { getJobDetail } from "../slices/JobSlice";
import { getCreateProject } from "../slices/CreateProjectImageSlice.js";
import { Stage, Layer, Image, Transformer } from "react-konva";
import Konva from "konva";
import PolygonAnnotation from "../../pages/PolygonAnnotation.js";



import { useDispatch } from "react-redux";
import { getProjectId } from "../slices/ProjectSlice.js";
import {Button} from "@chakra-ui/react";
import ShowEdit from "../Add_Edit_Pallet/ShowEdit.js";
import Swatch from "./Swatch.js";
import { getHover, getAllPolygons } from "../slices/AlertSlice.js"; // hover 
import { ClassNames } from "@emotion/react";

const ProjectLayer = () => {
  const [position, setPosition] = useState([0, 0]);
  const [size, setSize] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const imageRef = useRef(null);
  const getCreateProjectImage = useSelector(getCreateProject);
  const [polygon, setPolygon] = useState(true);
  const [multiPolygon, setmultiPolygon] = useState([]);
  const [isMultipleHover, setISMultipleHover] = useState(true);
  const [getTargetData, setGetTargetData] = useState("");
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [points, setPoints] = useState([]);
  const [isHover, setISHover] = useState(true);
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const scale = 2;
  const [showPopup, setshowPopup] = useState(false);
  const [polygonSize, setPolygonSize] = useState([]);
  const [newPolygonPoinpolygonts, setNewPolygonPoint] = useState([]);
  const [lines, setLines] = useState([]);
  const [newAnnotations, setNewAnnotations] = useState([]);
  const [numAnnotations, setNumAnnotations] = useState(0);
  const [isAnnotationMode, setIsAnnotationMode] = useState(false); // Set to true by default
  const [isDivVisible, setIsDivVisible] = useState(false);
  const dispatch = useDispatch();
  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  //Save Annotation
  const saveAnnotation = (e) => {
    setLines([...lines, flattenedPoints]);
    setNewAnnotations((prevAnnotations) => [...prevAnnotations]);
    setNumAnnotations(numAnnotations + 1);
    setIsAnnotationMode(false);

    reset();
  };
  const handleMouseDown = (e) => {
    if (!isAnnotationMode || isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && Array.isArray(points) && points.length >= 3) {
      setPolyComplete(true);
      setIsDivVisible(true);
      setNewPolygonPoint(points);
      setPolygonSize([...polygonSize]);
      setshowPopup(true);
    } else {
      const newPoints = Array.isArray(points)
        ? [...points, mousePos]
        : [mousePos];
      setPoints(newPoints);
    }
  };
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    // console.log("mousePos =>", mousePos);
    // const { x, y } = stage.getPointerPosition();
    handlestage(e);
    setMagnifierPosition(mousePos);

    setPosition(mousePos);
  };

  // Handle mouse over start point when doing polygon annotation
  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };

  // Handle mouse out from start point during polygon annotations
  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };


  const handlePointDragMove = (e) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1; // Assuming your points are stored in an array

    // Update the coordinates of the dragged point
    const pos = [e.target.x(), e.target.y()];
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);

    // Redraw the layer
    stage.batchDraw();
  };

  const handleGroupDragEnd = (e) => {
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === "polygon") {
      let result = [];
      let copyPoints = [...points];
      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      );
      e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result);

      saveAnnotation();
      setPolyComplete(false);
      setPoints([]);
    }
  };

  //Reset Annotation
  const reset = () => {
    setPoints([]);
    setPolyComplete(false);
  };


  const shapesLayer = useRef(new Konva.Layer());
  var tooltipLayer = new Konva.Layer({
    className: 'tooltip',
  });

  const getProject = useSelector(getProjectId);

  useEffect(() => {
    //
    if (getCreateProjectImage?.image!=null) {
      handleUploadImage(getCreateProjectImage?.image);
    }
  }, [getCreateProjectImage]);
  useEffect(() => {
    if (getProject.projectName) {
      handleDemoImage(getProject?.base64Image);
      // setProjectNameValue(getProject.projectName);
    } else if (getProject.base64Image == null) {
      setSelectedImage(null);
    }
  }, [getProject]);
  const handleDemoImage = (image) => {
   // 
    if (image) {
      const encodedImage = image;

      // console.log("encodedImage =>", encodedImage)
      const tets = "data:image/png;base64," + encodedImage;
      // Decode the base64 image
      // console.log("tets =>", tets)
      const decodedImage = atob(tets.split(",")[1]);
      // console.log("decodedImage =>", decodedImage)
      // Convert the decoded data to a Uint8Array
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "image/png" });

      // Create an object URL from the Blob
      const imageUrl = URL.createObjectURL(blob);
      var img = new window.Image();
      img.src = imageUrl;
       img.onload = () => {
       // console.log("onload");
        imageRef.current = img;
        setSelectedImage(img);
       
       
     };
     
     
      
     // setSelectedImage(img);
     // console.log(img)
      

    }
  };


  
  // update the project Image on image view
  

  const handleUploadImage = (img1) => {
    
    sessionStorage.removeItem("image");
    sessionStorage.removeItem("DemoJson");

    if (img1 && img1 instanceof Blob) {
      const image = img1;
      const showImage = URL?.createObjectURL(image);
      const img = new window.Image();

      img.src = showImage;
      img.onload = () => {
      
        // dispatch(removeProjectImage());
      };
      setSelectedImage(img);
      imageRef.current = img;
      sessionStorage.removeItem("samData");
      sessionStorage.removeItem("demo");
    }
  };

  const getAllSegmentation = useSelector(selectAllSegments);

  function updateTooltip(tooltip, x, y, text) {
    
    tooltip.getText().text(text);
    tooltip.position({
      x: x,
      y: y,
    });
    tooltip.show();
  }

  const [isShowEdit, setIsShowEdit] = useState(false);

  var tooltip = new Konva.Label({
    opacity: 1,
    visible: false,
    listening: false,
  });

  tooltip.add(
    new Konva.Tag({
      fill: "#FCE205	",
      pointerDirection: "down",
      pointerWidth: 8,
      pointerHeight: 8,
     
    })
  );

  tooltip.add(
    new Konva.Text({
      text: "None",
      fontFamily: "Calibri",
      fontSize: 18,
      padding: 0,
      lineHeight: 1.3,
      fill: "black",
      opacity: 1,
    })
  );

  tooltipLayer.add(tooltip);

  


  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [selectedSegment, setSelectedSegment] = useState(null);


//   useEffect(()=>{
// console.log("shapesLayer",shapesLayer.current)
  const makeShape=()=>{ if (
      getAllSegmentation &&
      getAllSegmentation.allSegmentsArray &&
      getAllSegmentation.allSegmentsArray.length > 0 &&shapesLayer.current
    ) {
      //
     
     
      getAllSegmentation.allSegmentsArray.map((item) => {
        let key = item.name;
        let pointss = item.annotationPolygon
          .split(",")
          .map((value) => parseInt(value.trim(), 10));
        
          if (pointss.length >= 6) { 
            var shape = new Konva.Line({
      
              points: pointss,
              fill: "rgb(19 78 191 / 40%)",
              opacity: 0,
              closed: true,
              stroke: "#28dfe5", // Border color
              strokeWidth: 3,
              // custom attr
              key: key,
              draggable: true,
              name:item.name,
            });
      
            shape.on("click", (e) => {
              if (selectedSegment === key) {
                setIsShowEdit(false);
                setSelectedSegment(null);
                setMouseX(null);
                setMouseY(null);
              } else {
                setSelectedSegment(key);
                // setIsShowEdit(true);
                const stage = e.target.getStage();
                const mousePos = stage.getPointerPosition();
                setMouseX(mousePos.x);
                setMouseY(mousePos.y);
               
              }
            });
            //group.add(shape)
            let track={className:"shapeLayer"}
            shapesLayer.current.attrs=track
            shapesLayer.current.add(shape);
           
          }
       
         
      
      });
      
    }}



  // const handlestage = (e) => {
  //   if (!e || !shapesLayer.current || !tooltipLayer) return; // Add this check to handle cases where the event is undefined

  //   var stage = e.target.getStage();
  //   stage.add(shapesLayer.current);
  //   stage.add(tooltipLayer);
  //   // stage.add(ImageLayer);

  //   stage.on("mouseover", function (evt) {
  //     var shape = evt.target;
  //     if (shape) {
  //       shape.opacity(1.1);
  //     }
  //   });
  //   stage.on("mouseout", function (evt) {
  //     var shape = evt.target;
  //     if (shape) {
  //       shape.opacity(0);
  //       tooltip.hide();
  //     }
  //   });
  //   stage.on("mousemove", function (evt) {
  //     var shape = evt.target;
  //     if (shape) {
  //       var mousePos = stage.getPointerPosition();
  //       var x = mousePos.x;
  //       var y = mousePos.y - 5;
  //       updateTooltip(tooltip, x, y, shape.attrs.key);
  //     }
  //   });
  //   makeShape()
  // };

  const handlestage = (e) => {
    if (!e || !shapesLayer.current || !tooltipLayer) return; // Add this check to handle cases where the event is undefined
  
    var stage = e.target.getStage();
   
     
      // console.log("stage children",stage.children)
      // console.log("shapesLayer.current",shapesLayer.current)
      // console.log("tooltipLayer",tooltipLayer)
     // Clear existing content on the stage
     if (stage.children.length > 3) {
          stage.children.map((item,index)=>{
            Object.entries(item.attrs).forEach(([key, value]) => {
              if(value==="tooltip"){
               item[index]=tooltipLayer
              }
          })})
    
  }else{
    stage.add(tooltipLayer);
  }
  
    stage.add(shapesLayer.current);
   
     

    stage.on("mouseover", function (evt) {
      var shape = evt.target;
      
      if (shape) {
        shape.opacity(1.1);
      }
     
    });
    stage.on("mouseout", function (evt) {
      var shape = evt.target;
    
      if (shape) {
        shape.opacity(0);
        tooltip.hide();
      }
     
    });
    stage.on("mousemove", function (evt) {
      var shape = evt.target;
      if (shape) {
    
        var mousePos = stage.getPointerPosition();
        var x = mousePos.x;
        var y = mousePos.y - 5;
        updateTooltip(tooltip, x, y, shape.attrs.key);
      }
    });
    makeShape()
  };
  

  
 
 

  // hover function
    //  allhover from segmented
    const getAllPolygon = useSelector(getAllPolygons);
    
    

    useEffect(() => {
      if (getAllPolygon.isAllHover && getAllPolygon.allpolygon) {
        
        handleAllSegmentHover(getAllPolygon.allpolygon);
      } else {
        handleRemoveAllHover();
      }
    }, [getAllPolygon.isAllHover]);
  
  // hover on all segments 
     const handleAllSegmentHover = (allPolygon) => {
    setISMultipleHover(true);
    setIsDivVisible(false);
    setmultiPolygon(allPolygon);
  };

   // remove all hover
   const handleRemoveAllHover = () => {
    sessionStorage.removeItem("multiPoly");
    setISMultipleHover(false); // Reset the state when hover leaves
    setPoints(null);
    setPolyComplete(false);
  };

  return (
    <>
      <div className="ai-right-wrapper-body  Cursor project-layer-images">
        hii
        <h2> Canvas Mode</h2>
        {/* <Button onClick={handleStart}>Start</Button> */}
        {polygon &&
          (selectedImage ? (
            <div>
              <Stage
                id="testStageID"
                width={size.width || 1123}
                height={size.height || 650}
                onMouseMove={handleMouseMove}
                onMouseDown={(e) => {
                  handleMouseDown(e);
                  handlestage(e); // Call handlestage with the event
                }}
                // Set the background of the Stage to transparent
                style={{ background: "transparent" }}
              >
                <Layer className="WindowLayer1">
                  <div className="canvas-images">
                    {selectedImage && (
                      <div>
                        <Image
                          ref={imageRef}
                          image={selectedImage}
                          width={size.width || 1023}
                          height={size.height || 592}
                          x={0}
                          y={0}
                          crop={{
                            x: position.x - size.width / scale / 2, // Use size.width
                            y: position.y - size.height / scale / 2, // Use size.height
                            width: size.width / scale, // Use size.width
                            height: size.height / scale, // Use size.height
                          }}
                        />
                      </div>
                    )}

                    {
                      <>
                        {/* <RoofPallet /> */}
                        {/* <Wall_pallet />
                        <GarageDoor />
                        <DoorPallet />
                        <WindowPallet />

                        <TrimPallet />

                        <Gutter />

                        <Light /> */}
                      </>
                    }
                  </div>

                  {/* Existing code for polygons and lines */}

                  {isHover && (
                    <PolygonAnnotation
                      points={points}
                      flattenedPoints={flattenedPoints}
                      handlePointDragMove={handlePointDragMove}
                      handleGroupDragEnd={handleGroupDragEnd}
                      handleMouseOverStartPoint={handleMouseOverStartPoint}
                      handleMouseOutStartPoint={handleMouseOutStartPoint}
                      isFinished={isPolyComplete}
                      getTargetData={getTargetData}
                    />
                  )}

                  {/* Iterate through multiPolygons and render each multipolygon */}

                  
                </Layer>
                <Layer className="WindowLayer2">
                  <div className="canvas-images">
                    {selectedImage && (
                      <div>
                        <Image
                          ref={imageRef}
                          image={selectedImage}
                          width={size.width || 1023}
                          height={size.height || 592}
                          x={0}
                          y={0}
                          crop={{
                            x: position.x - size.width / scale / 2, // Use size.width
                            y: position.y - size.height / scale / 2, // Use size.height
                            width: size.width / scale, // Use size.width
                            height: size.height / scale, // Use size.height
                          }}
                        />
                      </div>
                    )}

                    {
                      <>
                        {/* <RoofPallet /> */}
                        {/* <Wall_pallet />
                        <GarageDoor />
                        <DoorPallet />
                        <WindowPallet />

                        <TrimPallet />

                        <Gutter />

                        <Light /> */}
                      </>
                    }
                  </div>

                  {/* Existing code for polygons and lines */}

             

                  {/* Iterate through multiPolygons and render each multipolygon */}

                  {isMultipleHover &&
                    multiPolygon.map((polygon, index) => (
                      <PolygonAnnotation
                        key={index}
                        points={polygon}
                        flattenedPoints={polygon} // Adjust this if needed
                        handlePointDragMove={handlePointDragMove}
                        handleGroupDragEnd={handleGroupDragEnd}
                        handleMouseOverStartPoint={handleMouseOverStartPoint}
                        handleMouseOutStartPoint={handleMouseOutStartPoint}
                        shouldDisplay={true}
                        isFinished={true} // Set to true assuming all multipolygons are complete
                      />
                    ))}
                </Layer>
              </Stage>
            </div>
          ) : (
            <></>
          ))}
        <div className="newShowEdit">
          {
            <Swatch
              mouseX={mouseX}
              mouseY={mouseY}
              selectedSegment={selectedSegment}
            />
          }
         
        </div>
      </div>
    </>
  );
};

export default ProjectLayer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  reloadJobId: null,
};
const ReloadJobSlice = createSlice({
  name: "reloadJob",
  initialState,
  reducers: {
    addJobId: (state, action) => {
      state.reloadJobId = action.payload.reloadJobId;
    },

    removeJobId: (state, action) => {
      return null;
    },
  },
});

export const { addJobId, removeJobId } = ReloadJobSlice.actions;
export const getReloadJobId = (state, action) => state.reloadJob;
export default ReloadJobSlice.reducer;

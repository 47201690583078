import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import "./DeleteSwatchAlert.css";

const DeleteSwatchAlert = ({ isOpen, onClose, deleteSwatch }) => {
  const handleCloseModal = () => {
    onClose();
  };

  const handleDelete = () => {
    deleteSwatch();
  };
  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent className="delete-swatch-button">
          <ModalHeader></ModalHeader>
          <ModalCloseButton onClick={handleCloseModal} />
          <ModalBody>
            <Text fontweight="bold" mb="1rem">
              Are you sure you want to delete swatch?
            </Text>
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter className="delete-swatch-title">
            <Button  colorScheme="blue" mr={3} onClick={handleCloseModal}>
              No
            </Button>
            <Button variant="ghost" onClick={handleDelete}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DeleteSwatchAlert;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, loginSuccess } from "../login/AuthSlice";
import { getAllProject } from "../slices/ProjetcViewSlice";
import { addLabel_Brand } from "../slices/Label_Brand_Swatch";
import {getCanvasSize,updateWindowResolution} from "../slices/windowSizeSlice/WindowResolution"
const RefreshProfile = () => {
  const dispatch = useDispatch();
  const customerDetails = useSelector(getCustomer);
  const getProjectView = useSelector(getAllProject);
 const getCanvasSizes= useSelector(getCanvasSize)
  useEffect(() => {
    if (
      customerDetails.customerId === null &&
      getProjectView &&
      getProjectView.length === 0
     
    ) {
      let customer = JSON.parse(sessionStorage.getItem("customerLogin"));
      let brand_swatch = JSON.parse(sessionStorage.getItem("Brands_Swatch"));
      if(brand_swatch!=null ) {
        dispatch(addLabel_Brand(brand_swatch));
      }
      
      // ;
      if (customer && customer.customerId != null) {
        dispatch(
          loginSuccess({
            customerId: customer.customerId,
            isAuthenticated: true,
            token: customer.token,
            email: customer.email,
            customerName: customer.customerName,
            role: customer.role,
            userId:customer.userId,
            newUser:false
          })
        );
      }

      let canvasScreen= JSON.parse(sessionStorage.getItem('canvasResolution'))
      if(canvasScreen &&canvasScreen?.canvasWidth!=null && canvasScreen?.canvasHeight!=null){
        dispatch(
          updateWindowResolution({
            canvasWidth: canvasScreen.canvasWidth,
            canvasHeight: canvasScreen.canvasHeight
          })
        )
      }
    }
  }, [customerDetails, getProjectView]);
  return <div></div>;
};

export default RefreshProfile;

import React, { useEffect } from "react";
import { GET_USER_PROJECT } from "../GraphQL/GraphQl";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { addProjectView, getAllProject } from "../slices/ProjetcViewSlice";

const LoadProject = ({ projectIds, callLoad }) => {
  const dispatch = useDispatch();
  const { loading, error, data , refetch} = useQuery(GET_USER_PROJECT, {
    variables: {
      projectId: projectIds,
    },
    //fetchPolicy: "no-cache",
  });
  const getAllProjectView = useSelector(getAllProject);

  useEffect(() => {
    if (loading) {
      callLoad(loading);
    } else if (data) {
      if (
        data.customers &&
        data.customers.data &&
        data.customers.data[0] &&
        data.customers.data[0].attributes &&
        data.customers.data[0].attributes.projects &&
        data.customers.data[0].attributes.projects.data.length > 0
      ) {
        if (getAllProjectView && getAllProjectView.length > 0) {
        } else {
          dispatch(
            addProjectView(data.customers.data[0].attributes.projects.data)
          );
        }

        callLoad(loading);
      } else {
        alert("No Project in your account");
        callLoad(loading);
      }
    }
  }, [data, loading]);

  return <div></div>;
};

export default LoadProject;

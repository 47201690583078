import React, { useEffect, useState } from 'react'
import { Tooltip, Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import "../../NewDesign/rightsection/tools/Tool.css";


import { StartAnnotation, finallySaveToDB } from "../../slices/EditSegment/EditSegment"
import { saveAnnotation, getEditAnnotation } from "../../slices/EditSegment/EditSegment";
import {
  getStartAnnotation, getSaveDataToDB,
  getSelectedswatchStart, selectedPalletStart,
} from "../../slices/EditSegment/EditSegment"

import { outLineMode, GetOutLineMode } from "../../slices/gridView/MultiSelectionSegement"

import { getActiveCombo, saveCombination } from "../../slices/combinationSwatch/CombinationSwatchSlice"

import { reloadProject } from "../../slices/ProjectSlice"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { startCompare } from "../../slices/compare/CompareSlice"

const CustomerTools = ({ canvasMode,
  canvasMode2,
  noise,
  outLine,
  compare,
  annotation,
  undo,
  reset,
  FinalLayer,
  screenShot,
  report,
  hoverLayer,
  saveDataToStrapi
}) => {

  const [isProjectLayer, setIsProjectLayer] = useState(true);
  const [isFinalLayer, setIsFinalLayer] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isNoiseReduction, setIsNoiseReduction] = useState(false);
  const [isDownloadImage, setIsDownloadImage] = useState(false);


  const [isOutLine, setIsOutLine] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showicon, setshowicon] = useState(true);
  const getSaveDataToDBs = useSelector(getSaveDataToDB)
  const getSelectedswatchStarts = useSelector(getSelectedswatchStart)

  //canvas mode
  const handleProjectLayerChange = () => {
    if (isProjectLayer) {
      setIsProjectLayer(true);
      setIsOutLine(false)
      setIsCompare(false)
    } else {
      setIsProjectLayer(false);
      setIsOutLine(false)
      setIsCompare(false)
    }


    canvasMode()

  }


  // canvas mode 2  ie edit annotation
  const handleToggleEditing = () => {
    canvasMode2()
  }

  //noie reduction
  const handleNoise = () => {
    noise()
  }


  // outilne
  const getOutLine = useSelector(GetOutLineMode);
  const handleOutline = () => {
    if (!getOutLine) {
      setIsOutLine(true)
      setIsProjectLayer(false)
      setIsCompare(false)
      dispatch(outLineMode({
        isOutLine: true
      }))
    } else {
      dispatch(outLineMode({
        isOutLine: false
      }))
      setIsOutLine(false)
      setIsProjectLayer(true)
      setIsCompare(false)
    }

  }
  // compare 
  const handleSwitchChange = () => {
    if (!isCompare) {
      setIsCompare(true)
      setIsProjectLayer(false)
      setIsOutLine(false)

    } else {
      setIsCompare(false)
      setIsProjectLayer(true)
      setIsOutLine(false)


    }
    compare()
  }

  // start Annotation
  const getStartAnnotations = useSelector(getStartAnnotation)

  const handleAnotation = () => {
    if (!getStartAnnotations) {
      dispatch(StartAnnotation({
        isStartAnnotation: true
      }))
    } else {
      dispatch(StartAnnotation({
        isStartAnnotation: false
      }))
    }

  }

  // unodo
  const handleUndo = () => {
    undo()
  }

  //reset
  const handleReset = () => {
    reset()
  }

  // final Layer
  const handleFinalLayer = () => {
    FinalLayer()
  }
  //captureScreenshot
  const captureScreenshot = () => {
    dispatch(startCompare({
      isStartCompare: true
    }))
  }

  // handleReport
  const handleReport = () => {
    report()
  }

  const handleNewDesign = () => {
    navigate("/home-page");
  }


  // save Annnottaion
  const getIsEditAnnotation = useSelector(getEditAnnotation)

  useEffect(() => {

    if (getIsEditAnnotation) {
      setEditActive(true)
    }
  }, [getIsEditAnnotation])
  const handleSaveAnnotation = () => {
    dispatch(reloadProject({
      reload: true
    }))
    if (getIsEditAnnotation) {
      setEditActive(false)
      dispatch(saveAnnotation({
        isSaveAnnotation: true,
        isEditAnnotation: false
      }))
    }
  }

  //handle hover layer

  const handleHoverLayer = () => {
    hoverLayer()
  }

  const handleShow = () => {
    setshowicon(!showicon)
  }



  // save Data to DB
  const handleSaveToDB = () => {
    dispatch(reloadProject({
      reload: true
    }))
    dispatch(selectedPalletStart(
      { isSelectPalletStart: false }
    ))
    dispatch(finallySaveToDB({
      saveData: true
    }))
  }


  // check the active combination
  const getActiveCombos = useSelector(getActiveCombo)

  const handleCombination = () => {
    dispatch(reloadProject({
      reload: true
    }))
    dispatch(saveCombination({
      IscomboActive: false,
      isSaveCombination: true
    }))
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (<>

    <div className="select-pallet-toolbars customer-tools">

      <div className='tool-show-hide-icon' onClick={handleShow}>
        <i class="bi bi-eye-fill"></i>

      </div>

      {showicon && <div className='pro-tool-wrapper-icons'>
        <div className="tool-wrapper">
          <div className="select-tool-project">
            <Tooltip hasArrow label='Compare' bg='gray.300' color='black'  >
              <div className={isCompare ? "active-icon" : ""}>
                <i
                  class="bi bi-chevron-bar-contract"
                  onClick={handleSwitchChange}
                ></i>
              </div>
            </Tooltip>

            <Tooltip hasArrow label='Full Screen' bg='gray.300' color='black' >
              <div >
                <i className="bi bi-arrows-fullscreen"
                //    onClick={handleNewDesign}
                ></i>
              </div>
            </Tooltip>


          </div>

        </div>
      </div>
      }
    </div>
  </>


  )
}

export default CustomerTools
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { selectedPalletdDetails } from "../../slices/SelectedPalletdSlice";
import { getProjectId } from "../../slices/ProjectSlice";

import { useDispatch, useSelector } from "react-redux";
// import { palletLoading } from "../../AfterDeleteSegmentSliceslices/AlertSlice";
import { selectAllSegments } from "../../slices/AllSegmentSlice";
import { getTabPannel } from "../../slices/TabPannelSlice";
import { getCallApi, openSegAfterUpload } from "../../slices/AlertSlice";

 import{getAllShadows} from "../../slices/shadow/ShadowSlice"
 import {resetReGenerateSwatch} from "../../slices/popHover/PopHoverSlice"
 import {resetEditAnnotation} from "../../slices/EditSegment/EditSegment"
 
 import {palletLoading} from "../../slices/AlertSlice"
 const SelectedPalletModelAPI = ({
  palletImagePath,
 
  resetData,
  swatchData
}) => {
  const toast = useToast();
  const selectedPallateDetails = useSelector(selectedPalletdDetails);
  //console.log(selectedPallateDetails)
  const dispatch = useDispatch();
  const getBase64Image = useSelector(getProjectId);
  const [uploadData, setUploadData] = useState(null);

  const [palletIds, setpalletId] = useState();

  const allSegmentArray = useSelector(selectAllSegments);
  // const [allPolygonArrayInt, setPolygonArrayInt] = useState();
  const getCallApis = useSelector(getCallApi);
  const [shadowName, setShadowName]= useState(null)
  const [alphaValue, setAlphaValue]= useState()
  const[shadowPoly, setShadowPoly]= useState(null)
 const getAllShadowss= useSelector(getAllShadows)
  const findAllPoly = () => {
    let arrayMaster = [];
    let apha_value=[];
    let shadows=[]
    if (allSegmentArray && allSegmentArray.allSegmentsArray.length > 0) {
      allSegmentArray.allSegmentsArray.forEach((item) => {
        const keyAsString = item.name; // Use item.name as the key
        if (typeof item.annotationPolygon === "string") {
          const arrayOfNumbers = item.annotationPolygon
            .split(",")
            .map((str) => parseInt(str, 10));

          arrayMaster.push({
            [item.name]: arrayOfNumbers,
          });
        }
      });


    }
    if(getAllShadowss &&
      getAllShadowss.allShadow&&
      getAllShadowss.allShadow.length>0){
        getAllShadowss.allShadow.map(item=>{
          apha_value.push({
            [item.label]:item.alpha_value
          })
          
          const arrayOfNumbers = item.flattenedPoints
            .split(",")
            .map((str) => parseInt(str, 10));
            arrayMaster.push({
            [item.label]: arrayOfNumbers
          })
        })
       
        setAlphaValue(apha_value);
      

    }
    return {
      arrayMaster,
      apha_value,
    };
    //  setPolygonArrayInt(arrayMaster);
  };
  



  const [apiCallData, setApiCallData] = useState(null);
  const isAPICallData = useRef(true);
  const isMounted = useRef(true);
  const isMounted3 = useRef(true);
  const isMountApi= useRef(true);
  const getTabPannels = useSelector(getTabPannel);
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch1"));
  const [multiCAllApIsData, setmultiCAllApIsData]= useState()
  //Testing

  useEffect(() => {
    ;
  
    if (
      swatchData &&
      swatchData?.palletImagePath &&
      swatchData?.flagDemo &&
      swatchData?.palletId &&
      swatchData?.palletName &&
      isMounted.current &&
      selectedPallateDetails &&
      selectedPallateDetails.length > 0
    ) {
      // Check if multiCAllApIs has already been called
     
    
        const data = {
          palletImagePath: swatchData.palletImagePath,
          flagDemo: swatchData.flagDemo,
          palletId: swatchData.palletId,
          palletName: swatchData.palletName,
          palletType:swatchData.palletType==="Color"? "Color":"Image",
          style:swatchData?.palletShape,
          brand:swatchData.brand,
          label:swatchData.label,
          palletStyle:swatchData.palletStyle,

          // Add other properties as needed
        };
        isMounted.current = false;
        multiCAllApIs(data);
    
  
      return () => {
        // console.log("clean up", isMounted.current);
        isMounted.current = false;
      };
    }
  }, [swatchData, selectedPallateDetails]);
 
 
  const [newPoly, setNewPloy] = useState();

  const multiCAllApIs = (apiCallData) => {
      

    // reset resenerartion if occures function 
    dispatch(resetReGenerateSwatch())
    
    // ;
  //  const allPolygonArrayInt = findAllPoly();
    const { arrayMaster, apha_value } = findAllPoly();
    const { palletImagePath, flagDemo, palletId, palletName ,
      palletType,style,brand, label, palletStyle} = apiCallData;
   

    const urlpallet =
      flagDemo == 1
        ? process.env.REACT_APP_S3_DZINLYLIVE_PATH_DEMO + palletImagePath
        : process.env.REACT_APP_S3_DZINLYLIVE_PATH + palletImagePath;
    let palletIds = palletId;
    let palletImage = palletImagePath;
    let palletShape=style
    let brands=brand
    let labels=label
    let palletStyles=palletStyle

    let poly;
    let allAreas;
    let segmentName;
    let itemName;
    let area;
    let base64 = getBase64Image.base64Image;
    let intstringValue;
    let floatstringValue;
    let segmentationIntValue;
    let boundingboxFloatValue;
    let boundingboxIntValue;
    let allowAPI = true;
    setApiCallData(null);
   // console.log("multiCAllApIs 3");
    if (
      selectedPallateDetails &&
      selectedPallateDetails.length > 0 &&
      base64 != null
    ) {
      selectedPallateDetails.map((item) => {
        segmentName = item.name.match(/[a-zA-Z]/g).join("");

        intstringValue = item.BoundingBoxInt.split(",").map(parseFloat);
        floatstringValue = item.BoundingBoxFloat.split(",").map(parseFloat);

        area = parseFloat(item.area_pixel);
        itemName = item.name;
        poly = item.annotationPolygon
          .split(",")
          .map((str) => parseInt(str, 10));

        // classsName
        allAreas = {
          ...allAreas,
          [item.name]: area,
        };

        //segmentationIntValue
        segmentationIntValue = {
          ...segmentationIntValue,
          [item.name]: poly,
        };

        //boundingboxFloatValue
        boundingboxFloatValue = {
          ...boundingboxFloatValue,
          [item.name]: floatstringValue,
        };
        //boundingboxIntValue
        boundingboxIntValue = {
          ...boundingboxIntValue,
          [item.name]: intstringValue,
        };
      });
     ;
      
      const data = {
        masterImage: base64,
        swatchUrl: urlpallet,
        className: [allAreas],
        pattern:palletType
        
      };
      setApiCallData(null);

      let apiPath = "";

      if (segmentName === "Window") {
        apiPath = "replace-window-swatch";
       // data.boundingboxFloatValue = [boundingboxFloatValue];
      //  data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = [segmentationIntValue];
        data.shape=palletShape
      
      }
      if (segmentName === "Door") {
        apiPath = "replace-door-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = [segmentationIntValue];
    
      }
      if (segmentName === "GarageDoor" || segmentName === "Garage") {
        apiPath = "replace-garage-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = [segmentationIntValue];
      }
      if (segmentName === "Roof") {
        apiPath = "replace-roof-swatch";
        data.segmentationIntValue = arrayMaster;
        data.alpha_value=apha_value;
      }
      if (segmentName === "Wall") {
        apiPath = "replace-wall-swatch";
        data.boundingboxFloatValue = [boundingboxFloatValue];
        data.boundingboxIntValue = [boundingboxIntValue];
        data.segmentationIntValue = arrayMaster;
        data.alpha_value=apha_value;
       
      }
      if (segmentName === "Light") {
        apiPath = "replace-light-swatch";
      }
      if (segmentName === "Gutter") {
        apiPath = "replace-gutter-swatch";
        data.segmentationIntValue = [segmentationIntValue];
      }
      if (segmentName === "Trim") {
        apiPath = "replace-trim-swatch";
        data.segmentationIntValue = [segmentationIntValue];
      }

      if (
        apiPath != "" &&
        data != null &&
        segmentName &&
        palletImage &&
        palletIds
      ) {
       
        setApiCallData(null);
        //console.log("multiCAllApIs 7", isMounted.current);
        replaceSwatchAPI(apiPath, data, segmentName, palletImage, palletIds,brands,labels, palletStyles,palletName);
        
       // console.log("multiCAllApIs 9", isMounted.current);
      }
    }
  };

  const [apiCalled, setApiCalled] = useState(false);
  
  const replaceSwatchAPI = async (
    apiPath,
    data,
    segmentName,
    palletImage,
    palletIds,
    brand,label,
    palletStyle,
    palletName
  ) => {
    //
    if (apiPath !== "" && data &&isMountApi.current) {
     
      isMountApi.current= false;

      try {
        isMounted3.current = false;
        setApiCalled(true);

        const response = await axios.post(
          "https://api.dzinly.org/api/" + apiPath,
          data
        );
        apiPath = "";
        data = null;
        //console.log("multiCAllApIs 10");
        //logger Started
        // Establish a WebSocket connection to the FastAPI server
       // const socket = new WebSocket("wss://api.dzinly.org/log");

        // WebSocket connection has been established
        // socket.onopen = (event) => {
        //   socket.send(JSON.stringify(data));
        // };

        // socket.onmessage = (event) => {
        //   // Received a message from the server
        //   const loggerData = JSON.parse(event.data);
        // };

        //logger ended
        apiPath = "";
       
        const val = response?.data;
        const value = val?.encoded_image;
        // console.log(response);
        
        if (response.status === 200) {
          isMounted.current = true;
          isMountApi.current = true;

          let uploadSegmentation = {
            masterName:swatchData.labelName,
            swatchId: palletIds,
            multiImage: response.data,
            swatchName: segmentName,
            reloadJobId: null,
            palletUrl: palletImage,
            brand:brand,
            label:label,
            palletStyle:palletStyle,
            palletName:palletName
          };
          setApiCalled(false);

          dispatch(
            openSegAfterUpload({
              isOpenSegAfterUpload: true,
            })
          );
          sessionStorage.setItem(
            "uploadData",
            JSON.stringify(uploadSegmentation)
          );
//  dispatch(resetEditAnnotation())
          //call back to showEdit page
          resetData(uploadSegmentation);
        }

       // socket.onclose = (event) => {
          
       // };
      } catch (error) {
        console.log("API eror",error)
        isMounted.current = true;
        isMountApi.current=true
        toast({
          title: "Error: " + error.message,
          // description: "Please check your connection and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "center-bottom",
        });
        dispatch(
          palletLoading({
            palletLoading: false,
          })
        );
        resetData()
      } finally {
         isMounted.current = true;
       
         isMountApi.current=true
      }
    }
  };

  return (
    <div>
      {/* {uploadData != null && (
        <UploadSegmentedImage uploadData={uploadData} resetData={handleReset} />
      )} */}
    </div>
  );
};

export default SelectedPalletModelAPI;

import {createSlice} from "@reduxjs/toolkit";
const CombinationSwatchSlice =createSlice({

    name:"combinationSwatch",
    initialState:{
        combinationSwatch:[],
        IscomboActive:false,
        isSaveCombination:false,
        renderCombination:[],
        isRenderComninationStart:false,
        isRenderComninationFinish:false,
        isShowCombo:false,
        projectId:null,
        reloadCombo:false,
        masterCombination:[],
       

    }, reducers:{

        addCombinationSwatch: (state, action) => {
            state.combinationSwatch = action.payload.combinationSwatch.map(item => {
                if (item.attributes && item.attributes.Swatches && item.attributes.Swatches.length > 0) {
                    const swatchImage = item.attributes.Swatches.reduce((filtered, swatch) => {
                        const existing = filtered.find(item => item.image === swatch.swatch.data.attributes.image_s3);
                        if (!existing) {
                            filtered.push({
                                image: swatch.swatch.data.attributes.image_s3,
                                name:swatch.SwatchFor
                            });
                        }
                        return filtered;
                    }, []);
        
                    return {
                        ...item,
                        attributes: {
                            ...item.attributes,
                            filteredSwatches: swatchImage,
                        },
                    };
                }
                return item;
            });
        },
        
      
        // sortUniqueSwatch: (state, action) => {
        //     ;
        //     if (Array.isArray(state.combinationSwatch)) {
        //         state.combinationSwatch.forEach(item => {
        //             let swatchImage = [];
        //             if (item.attributes && item.attributes.Swatches && item.attributes.Swatches.length > 0) {
        //                 item.attributes.Swatches.forEach(swatch => {
        //                     if (swatchImage.length > 0) {
        //                         const existing = swatchImage.find(item =>
        //                             item.image === swatch.swatch.data.attributes.image_s3
        //                         );
        //                         if (!existing) {
        //                             swatchImage.push({
        //                                 image: swatch.swatch.data.attributes.image_s3,
        //                             });
        //                         }
        //                     } else {
        //                         swatchImage.push({
        //                             image: swatch.swatch.data.attributes.image_s3,
        //                         });
        //                     }
        //                 });
        //             }
        //             if (item.attributes) {
        //                 item.attributes.filteredSwatches = swatchImage;
        //             }
        //         });
        //     }
        // }
        
        // ,
        

        removeCombinationSwatch: (state,action) => {
           
            state.IscomboActive=false;
            state.isSaveCombination=false;
        },
        comboActive: (state, action) => {
            state.IscomboActive = action.payload.IscomboActive;
        },
        saveCombination:(state, action) => {
            state.isSaveCombination=action.payload.isSaveCombination
            state.IscomboActive = action.payload.IscomboActive;
        }, 
        renderCombinations:(state, action) => {
            state.renderCombination=action.payload.renderCombination
            state.isRenderComninationStart=action.payload.isRenderComninationStart
        },
        removeRenderCombination:(state, action)=>{
            state.isRenderComninationStart=false
            state.isRenderComninationFinish=false
        },
        aferSaveCombination:(state, action)=>{
            state.isRenderComninationFinish=action.payload.isRenderComninationFinish;
        },
        showComboTab:(state, action)=>{
            state.isShowCombo=action.payload.isShowCombo
        }, 
        comboProjectId:(state, action)=>{
            state.projectId=action.payload.projectId
        },
        resetComboValue:(state, action)=>{
            state.combinationSwatch=[]
            state.IscomboActive=false
            state.isSaveCombination=false
            state.renderCombination=[]
            state.isRenderComninationStart=false
            state.isRenderComninationFinish=false
            state.isShowCombo=false
            state.projectId=null
           
        },
        reloadAllCombo:(state,action) => {
            state.reloadCombo=action.payload.reloadCombo
        }, 
        addMasterCombo:(state,action) => {
            state.masterCombination.push(action.payload.masterCombination)
        }, 
        updateCombo:(state,action) => {
            const {nanme,SwatchFor,SegmentImagePath,}= action.payload
        }
      

    }
})
export const {addCombinationSwatch,
    removeCombinationSwatch,
    comboActive,saveCombination,
    renderCombinations,removeRenderCombination,
    aferSaveCombination,
    showComboTab,
    comboProjectId,resetComboValue,
    reloadAllCombo,
    addMasterCombo,
    openComBinationLayer,sortUniqueSwatch

}=CombinationSwatchSlice.actions;
export const GetCombinationSwatch = (state) => state.combinationSwatch;
export const getActiveCombo= (state)=>state.combinationSwatch.IscomboActive;
export const getSaveCombination= (state)=>state.combinationSwatch.isSaveCombination
export const getRenderCombination= (state)=>state.combinationSwatch

export const getShowCombo =(state)=>state.combinationSwatch.isShowCombo
export const getComboProjectId=(state)=>state.combinationSwatch.projectId
export const getReloadCombo =(state)=>state.combinationSwatch.reloadCombo
export default CombinationSwatchSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
const createProjectImageSlice = createSlice({
  name: "projectImage",
  initialState: {
    image: "",
    isCreatedProject: false,
    reload: false,
    projectId: null,
  },
  reducers: {
    addProjectImage1: (state, action) => {
      
      console.log("image 64", action.payload.image)
      state.image = action.payload.image;
      state.isCreatedProject = true;
      state.reload = true;
      state.projectId = action.payload.projectId
    },
    removeProjectImage: (state) => {
      state.image = null;
      state.isCreatedProject = true;
      state.reload = true;
    },
    resetAllProjectImage:(state)=>{
      state.isCreatedProject = false;
      state.reload = false;
      state.image=null
    },
    addProjectId:(state, action)=>{
      state.projectId = action.payload.projectId
    }
  },
});

export const { addProjectImage1,
   removeProjectImage,
   resetAllProjectImage ,
   addProjectId
  } =
  createProjectImageSlice.actions;
export const getCreateProject = (state, action) => state.projectImage;
export default createProjectImageSlice.reducer;

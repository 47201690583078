import React, { useState, useEffect, useRef } from "react";
import { Checkbox, CheckboxGroup, Stack } from "@chakra-ui/react";
import {
  selectAllItems,
  removeSegmentFromMAsterArray,
} from "../slices/MasterArraySlice";
import { onHover } from "../slices/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
import ModalToAdd from "../Add_Edit_Pallet/ModalToAdd";
import { Button } from "@chakra-ui/react";
import { addSelectedPallete } from "../slices/SelectedPalletdSlice";
import { getCloseModal, closeModals } from "../slices/AlertSlice";
import CallApi from "../Add_Edit_Pallet/CallApi";
import { onAllHover, getCheckBox, addCheckbox } from "../slices/AlertSlice";
import UploadSegmentedImage from "../Add_Edit_Pallet/UploadSegmentedImage";
import { removeAllPalletImages } from "../slices/TransparentPalletImgSlice";
import { addTabPannel } from "../slices/TabPannelSlice";
const CheckBox = ({ eachSegment, indexs }) => {
  const getMasterArray = useSelector(selectAllItems);
  const dispatch = useDispatch();
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]); // State to store checked checkboxes
  const [isOpen, setIsOpen] = useState(false);
  const getIsCheckBox = useSelector(getCheckBox);

  const [palletImagePath, setpalletImagePath] = useState();
  const [flagDemo, setflagDemo] = useState();
  const [palletId, setpalletId] = useState();
  const [palletName, setpalletName] = useState();
  const [isopen, setIsopen] = useState(false);
  const [isChecked, setIsChecked] = useState([]);

  const handleOnHoverSegment = (segmentName, polygon) => {
    dispatch(
      onHover({
        ishover: true,
        segmentName: segmentName,
        polygon: polygon,
      })
    );
  };

  const handleCloseHoverSegment = () => {
    dispatch(onHover({ ishover: false, segmentName: null }));
  };

  const handleCheckboxChange = (segment, check) => {
    dispatch(
      onAllHover({
        isAllHover: false,
        allpolygon: null,
      })
    );

    if (segment && check) {
      // If checked, add the value to the checkedCheckboxes array
      setCheckedCheckboxes((prev) => [...prev, segment]);
    }
  };
  // uncheck  checkedCheckboxes
  const handleUncheckCheckbox = (seg) => {
    if (seg) {
      // Uncheck the checkbox (remove it from the checkedCheckboxes array)
      setCheckedCheckboxes((prev) =>
        prev.filter((item) => item.name !== seg.name)
      );
    }
  };
  useEffect(() => {
    let allPoly = [];
    let names = [];

    //  console.log(checkedCheckboxes)
    if (checkedCheckboxes && checkedCheckboxes.length > 0) {
      checkedCheckboxes.map((items) => {
        let polygon = items.annotationPolygon
          .split(",")
          .map((value) => parseInt(value.trim(), 10));
        allPoly.push(polygon);
        names.push(items.name);
      });
      dispatch(
        onAllHover({
          isAllHover: true,
          segmentName: names,
          allpolygon: allPoly,
        })
      );
      // console.log("AllPol", allPoly);
    }
  }, [checkedCheckboxes]);

  const handleOpenModal = () => {
    dispatch(addSelectedPallete({ selectedPallete: checkedCheckboxes }));
    setIsOpen(true);
    dispatch(removeAllPalletImages());

    dispatch(
      addTabPannel({
        pannelTab: "Tab2",
      })
    );
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const close_modal = useSelector(getCloseModal);
  useEffect(() => {
    if (close_modal) {
      handleCloseModal();

      dispatch(
        closeModals({
          closeModal: false,
        })
      );
    }
  }, [close_modal]);

  useEffect(() => {
    dispatch(
      onAllHover({
        isAllHover: false,
        // segmentName: names,
        allpolygon: null,
      })
    );
  }, []);
  const isMounted = useRef(true);
  const [isCallAPIopen, setIsCallAPIopen] = useState(false);
  const [uploadData, setUploadData] = useState(null);
  const handleSwatch = JSON.parse(sessionStorage.getItem("handleSwatch2"));
  useEffect(() => {
    if (
      handleSwatch &&
      handleSwatch?.palletImagePath &&
      handleSwatch?.flagDemo &&
      handleSwatch?.palletId &&
      handleSwatch?.palletName &&
      isMounted.current
    ) {
      //console.log("ShowEdit called");
      setpalletImagePath(handleSwatch.palletImagePath);
      // setflagDemo(handleSwatch.flagDemo);
      // setpalletId(handleSwatch.palletId);
      // setpalletName(handleSwatch.palletName);
      sessionStorage.setItem("handleSwatch", JSON.stringify(handleSwatch));
      setIsCallAPIopen(true);
      sessionStorage.removeItem("handleSwatch2");
      isMounted.current = false;
    }
  }, [handleSwatch]);
  // reset data
  const handleResetData = (data) => {
    setUploadData(data);

    setpalletImagePath(null);
    setflagDemo(null);
    setpalletId(null);
    setpalletName(null);
    setIsCallAPIopen(false);
    isMounted.current = true;
  };
  const handleReset = () => {
    setUploadData(null);
  };

  // unckeck check boc after addog pallate
  useEffect(() => {
    if (getIsCheckBox) {
      setCheckedCheckboxes([]);
      setIsChecked([]);
      dispatch(
        addCheckbox({
          isCheckBox: false,
        })
      );
    }
  }, [getIsCheckBox]);
  return (
    <div className="check-box-seg">
      {getMasterArray &&
        getMasterArray?.master?.length > 0 &&
        getMasterArray.master.map(
          (items, index) =>
            items.segmentation &&
            items.segmentation.length > 0 &&
            items.name === eachSegment &&
            items.segmentation.map((seg, checkboxIndex) => (
              <CheckboxGroup colorScheme="green" key={seg.id}>
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    checked={isChecked[checkboxIndex] || false}
                    onMouseLeave={handleCloseHoverSegment}
                    onMouseEnter={() =>
                      handleOnHoverSegment(seg.name, seg.annotationPolygon)
                    }
                    onChange={(e) => {
                      // Update the corresponding element in the isChecked array
                      const updatedIsChecked = [...isChecked];
                      updatedIsChecked[checkboxIndex] = e.target.checked;
                      setIsChecked(updatedIsChecked);

                      if (e.target.checked) {
                        handleCheckboxChange(seg, true);
                      } else {
                        handleUncheckCheckbox(seg);
                      }
                    }}
                  >
                    {seg.name}
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
            ))
        )}

      {checkedCheckboxes && checkedCheckboxes.length > 0 && (
        <Button className="select-pallet-checkbox" onClick={handleOpenModal}>
          Select Pallets
        </Button>
      )}
      {isOpen && (
        <ModalToAdd
          isOpen={isOpen}
          closeModal={handleCloseModal}
          indexs={indexs}
        />
      )}
      {isCallAPIopen && (
        <CallApi
          palletImagePath={palletImagePath}
          // flagDemo={flagDemo}
          // palletId={palletId}
          // palletName={palletName}
          resetData={handleResetData}
        />
      )}
      {uploadData != null && (
        <UploadSegmentedImage
          uploadDatas={uploadData}
          resetData={handleReset}
        />
      )}
    </div>
  );
};

export default CheckBox;


import React, { useEffect, useState } from 'react'
import "./SelectCombo.css";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Tooltip
} from '@chakra-ui/react'

import { GetCombinationSwatch, reloadAllCombo, getReloadCombo } from "../../../slices/combinationSwatch/CombinationSwatchSlice"
import { useSelector, useDispatch } from 'react-redux';
import { renderCombinations } from "../../../slices/combinationSwatch/CombinationSwatchSlice"
import GetAllCombination from "../Combination/AfterSaveGetCombination"
import { getProjectId } from "../../../slices/ProjectSlice"
import AfterSaveGetCombination from '../Combination/AfterSaveGetCombination';
import {sortUniqueSwatch,addCombinationSwatch} from "../../../slices/combinationSwatch/CombinationSwatchSlice"

const SelectCombo = () => {
  const dispatch = useDispatch()
  const [comboSwatch, setcomboSwatch] = useState(true);
  const getAllCombination = useSelector(GetCombinationSwatch)
  const [selectedName, setSelectedName] = useState()
  const [projectids, setProjectids] = useState(null)

  useEffect(()=>{},[])
  const handlecomboSwatch = (name) => {

    //
    setSelectedName(name)
    setcomboSwatch(!comboSwatch)
  }

  const handleApplyCombo = (combo) => {
    dispatch(renderCombinations({
      renderCombination: combo,
      isRenderComninationStart: true
    }))
  }

  // reload combo 
  const gerIsReloadCombo = useSelector(getReloadCombo)
  const getProjectDetails = useSelector(getProjectId)
  useEffect(() => {
    if (gerIsReloadCombo && getProjectDetails.projectId) {
      dispatch(reloadAllCombo({
        reloadCombo: false
      }))

      setProjectids(getProjectDetails.projectId)
    }
  }, [gerIsReloadCombo, getProjectDetails])

  

  // sort the swatch image
  // useEffect(()=>{
  //   if(getAllCombination &&
  //     getAllCombination.combinationSwatch &&
  //     getAllCombination.combinationSwatch.length > 0){
  //     dispatch(sortUniqueSwatch())
       
  //     }
  // },[getAllCombination])
  return (
    <>
      <div className='select-combo-section'>
        {getAllCombination &&
          getAllCombination.combinationSwatch &&
          getAllCombination.combinationSwatch.length > 0 &&
          getAllCombination.combinationSwatch.map((item, index) => {
            return (
              <div className='combo-section'
                key={item.id}>
                <div class="select-combo"
                  key={item.attributes.name}
                >
                  
                  {/* <div className='combo-icon'>
                  <Tooltip label='Hide/Show ' placement='right' bg='gray.300' color='black'>
                     <i class="bi bi-eye-slash"></i>
                     </Tooltip>
                     </div> */}
                   
                     <div className='combo-project-title'>
                  <h2>{item.attributes.Name}</h2>
                  </div>
   
                  <div class="dropdown-icon-outline">
                    <svg onClick={() => handlecomboSwatch(item.attributes.Name)} viewBox="0 0 24 24" focusable="false" class="chakra-icon chakra-accordion__icon css-186l2rg" aria-hidden="true"><path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                    </svg>
                  </div>
                </div>
                <div className='combo-button'>
                <Tooltip hasArrow label='Apply Swatch' bg='gray.300' color='black'>
                  <button type="button" class="chakra-button apply-btn"

                    onClick={() => handleApplyCombo(item)}
                  ><i class="bi bi-aspect-ratio"></i>Apply</button>
      </Tooltip>

               {!item.hide &&<Tooltip hasArrow label='Hide' bg='gray.300' color='black'>
                  <div className='delete-button'>
                  <i class="bi bi-eye-slash"></i>
                  </div>
                </Tooltip>}
               {item.hide &&<Tooltip hasArrow label='Show' bg='gray.300' color='black'>
                  <div className='delete-button'>
                  <i class="bi bi-eye"></i>
                  </div>
                </Tooltip>}
               

                <Tooltip hasArrow label='Delete' bg='gray.300' color='black'>
                  <div className='delete-button'>
                    <i class="bi bi-trash3"></i>
                  </div>
                  
                </Tooltip>
                </div>

                <div className='combo-swatch-images'>

                    {
                      comboSwatch &&
                      item.attributes.Swatches && item.attributes.Swatches.length > 0 ? (
                      item.attributes.filteredSwatches.map((swatch, index) => (
                  
                          <div key={index} class="select-pallet-image"  >
                            <div class="pallet-img">
                              <Tooltip className='tooltip-swatch' label={swatch.name} bg='#6d455a' color='#fff'>
                                {/* <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_4_dcd9443ee3.png" alt="Entrance" /> */}
                                <img src={`${process.env.REACT_APP_S3_DZINLYLIVE_PATH_PALLET}${swatch.image}`} alt="no image" />
                              </Tooltip>
                            </div>
                            <div className="pallet-action">
                              <Popover trigger="hover">
                                <PopoverTrigger>
                                  <div className="pallet-information-icon">
                                    <i class="bi bi-info"></i>
                                  </div>
                                </PopoverTrigger>
                                <Portal>
                                  <PopoverContent>
                                    <PopoverBody>
                                      <div className="pallet-img-inf-popup">
                                        <div className="pallet-images-inf">
                                          <img src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_4_dcd9443ee3.png" alt="Entrance" />
                                        </div>
                                        <div className="pallet-images-details">
                                        <h3>Entrance</h3>
                                            <h4>Brand : <span>Demo Brand Wall</span></h4>
                                            <h4>Label : <span>Demo Lable Wall</span></h4>
                                            <h4>Style : <span>Demo Wall Style </span></h4>
                                            <p>Model Size: 4'4" x 0'10" x 7'0"</p>
                                        </div>
                                      </div>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Portal>
                              </Popover>
                              <div className="pallet-fav">
                                <i class="bi bi-star"></i>
                              </div>
                            </div>
                            <div class="pallet-fav">
                              <i class="bi bi-star"></i>
                            </div>
                          </div>
                   
   
                      ))
                    ) : (
                      <p></p>
                    )}


     


                </div>


              </div>

            )
          })
        }
       {getAllCombination &&
  getAllCombination.combinationSwatch &&
  getAllCombination.combinationSwatch.length === 0 ? (
    <>
<div className='no-combo-section'>
    <img  className="no-combo-image"  src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/image_gallery_1_ab2f499bd3.png'></img>
    <h2 className='no-combination-title'>
      No Combination Available
      </h2>
      </div>
    </>
  ) : null
}
      </div>


      {/* <AfterSaveGetCombination
        combinationProject={projectids}
        resetProject={handleResetData}
      /> */}
    </>

  )
}

export default SelectCombo